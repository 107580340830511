import AppButton, { ButtonColor } from "components/common/AppButton";
import { useNavigate } from "react-router-dom";
import { closeModal, ModalProps } from "reducers/modalSlice";
import { useAppDispatch } from "stores/hook";
import { DocuBizPlnd } from "model/cp/DocuBizPlnd";
import ArcDownload, { dataSourceProps } from "components/arc/ArcDownload";
import { FileInfoModel } from "model/common/fileInfoModel";
import { showAndHideSpinner } from "reducers/spinnerSlice";
import { MhdlgService } from "services/cp/MhdlgService";

export default function MultiEmissionModal(props: Readonly<ModalProps>) {
  const dispatch = useAppDispatch();

  const mhdlgFile = props?.data?.file ? props?.data?.file : null;
  const mhdlgFileList: dataSourceProps[] = mhdlgFile ? (mhdlgFile.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  const bizFile = props?.data?.bizFile ? props?.data?.bizFile : null;
  const bizFileList: dataSourceProps[] = bizFile ? (bizFile.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  const moniFile = props?.data?.moniFile ? props?.data?.moniFile : null;
  const moniFileList: dataSourceProps[] = moniFile ? (moniFile.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  const handleClickClose = (e: any) => {
    dispatch(closeModal());
  };

  const navigate = useNavigate();

  const onClickSubmit = () => {
    dispatch(closeModal());
    const id = props.data.mhdlgId;
    const name = props.data.mhdlgNm;
    const param: DocuBizPlnd = {
      ...new DocuBizPlnd(),
      mhdlgId: id,
      mhdlgNm: name,
    };
    navigate("/cp/reduce/history/business/insert", { state: JSON.stringify(param) });
  };

  async function handleClickMhdlgDownload(selectFile: dataSourceProps) {
    if (mhdlgFile) {
      const selectedFile = (mhdlgFile.files ?? []).find((f: { fileNm: any; }) => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await MhdlgService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  async function handleClickBizDownload(selectFile: dataSourceProps) {
    if (bizFile) {
      const selectedFile = (bizFile.files ?? []).find((f: { fileNm: any; }) => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await MhdlgService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  async function handleClickMoniDownload(selectFile: dataSourceProps) {
    if (moniFile) {
      const selectedFile = (moniFile.files ?? []).find((f: { fileNm: any; }) => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await MhdlgService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  return (
    <div className="tbl-st2" style={{ zIndex: "1", backgroundColor: "rgba(255,255,255,0)", width: "99%" }}>
      <h3 className="titT1">방법론 상세조회</h3>
      <div className="layer-cont" style={{ overflowY: "auto" }}>
        <div className="tbl-st2 mgt30">
          <table>
            <caption>
              방법론 상세조회 : 방법론명, 방법론분야, 세부분류, 방법론 고유번호, 방법론 고유번호 설명, 방법론
              유효시작일, 설명파일
            </caption>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "auto" }} />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row" className="al">방법론명</th>
                <td className="al">{props.data.mhdlgNm ? props.data.mhdlgNm : ""}</td>
              </tr>
              <tr>
                <th scope="row" className="al">방법론분야</th>
                <td className="al">{props.data.fareaNm ? props.data.fareaNm : ""}</td>
              </tr>
              <tr>
                <th scope="row" className="al">세부분류</th>
                <td className="al">{props.data.scgNm ? props.data.scgNm : ""}</td>
              </tr>
              <tr>
                <th scope="row" className="al">방법론 고유번호</th>
                <td className="al">{props.data.unqNo ? props.data.unqNo : ""}</td>
              </tr>
              <tr>
                <th scope="row" className="al">방법론 고유번호 설명</th>
                <td className="al">{props.data.explCtt ? props.data.explCtt : ""}</td>
              </tr>
              <tr>
                <th scope="row" className="al">방법론 유효시작일</th>
                <td className="al">
                  {props.data.vldStrtYmd
                    ? `${props.data.vldStrtYmd.slice(0, 4)}-${props.data.vldStrtYmd.slice(
                      4,
                      6
                    )}-${props.data.vldStrtYmd.slice(6, 8)}`
                    : ""}
                </td>
              </tr>
              <tr>
                <th scope="row" className="al">방법론 설명 파일</th>
                <td className="al">
                  <ArcDownload dataSource={mhdlgFileList} onDownload={handleClickMhdlgDownload} />
                </td>
              </tr>
              <tr>
                <th scope="row" className="al">사업계획서 양식 파일</th>
                <td className="al">
                  <ArcDownload dataSource={bizFileList} onDownload={handleClickBizDownload} />
                </td>
              </tr>
              <tr>
                <th scope="row" className="al">모니터링 양식 파일</th>
                <td className="al">
                  <ArcDownload dataSource={moniFileList} onDownload={handleClickMoniDownload} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="btns ac">
        <AppButton
          type={"custom"}
          color={ButtonColor.btn2}
          text="신청"
          onClick={onClickSubmit} />

        <AppButton
          color={ButtonColor.btn1}
          text="닫기"
          onClick={handleClickClose} />
      </div>
    </div>
  );
}