import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ArcInput from "components/arc/ArcInput";
import { ResponseResultCode } from "model/response-api";
import ArcGrid, {
  ArcGridCheckResult,
  CustomBackGround,
  CustomBackGround2,
  CustomBackGround3,
  GridColumn
} from "components/arc/ArcGrid";
import { ActvScopService } from "services/cp/ActvScopService";
import { ActvMgnl } from "model/cp/actvMgnl";
import { ReduceGoalPageProps } from "pages/cp/roadmap/YyRdcGoalBase";
import ArcButton, { ButtonColor, ButtonType } from "components/arc/ArcButton";
import { CommonUtil } from "utils/commonUtil";
import { RoadMapService } from "services/cp/RoadMapService";
import { ReduceGoalContext } from "pages/cp/roadmap/YyRdcGoalContext";

export default function YyRdcGoalYear(props: ReduceGoalPageProps) {
  const { t } = useTranslation();
  const gridRef: any = useRef<typeof ArcGrid>();
  const reduceGoalContext = useContext(ReduceGoalContext);

  const [usingScop, setUsingScop] = useState<ActvMgnl>();
  const [selectedActvYear, setSelectedActvYear] = useState<number[]>([]);
  const [selectedActvTotalEmsn, setSelectedActvTotalEmsn] = useState<number[]>([]);
  const [canDrawGrid, setCanDrawGrid] = useState<boolean>(false);
  const [actvGrid, setActvGrid] = useState<ReactElement | null>();

  // useEffect(() => {
  //   window.location.reload();
  // }, []);

  useEffect(() => {

    async function callInitialUserScope() {
      const response = await ActvScopService.getInstance().getCheckScope();
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          setUsingScop(response.data as ActvMgnl);
        } else {
          toast.error("시스템 오류가 발생하였습니다.");
        }
      }
    }

    const today = new Date();

    async function callInitialUserData() {
      const response = await RoadMapService.getInstance().getUserLastYyRdcGoal();
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          // if (response.data.frstRegrId) {
          const years = CommonUtil.makeYearArray(response.data.strtYy ?? today.getFullYear(), response.data.endYy ?? today.getFullYear());
          reduceGoalContext.setTotal({
            emsn: 0,
            years: years,
            goalType: Number(response.data.rdcGoalCdId ?? 1),
            midYear: Number(response.data.mtrmYy ?? 0),
            midPercent: Number(response.data.midGoalRt ?? 0),
            goalYear: Number(response.data.goalYy ?? 0),
            goalPercent: Number(response.data.goalRt ?? 0),
            userId: response.data.frstRegrId,
          });
          setSelectedActvYear(years);
          // }
        } else {
          toast.error("시스템 오류가 발생하였습니다.")
        }
      }
    }

    if (!usingScop) {
      callInitialUserScope();
    }
    if (reduceGoalContext.total.years.length === 0) {
      callInitialUserData();
    }
    if (usingScop && reduceGoalContext.total.years.length > 0) {
      setCanDrawGrid(true);
    }
  }, [usingScop, reduceGoalContext.total.userId]);

  useEffect(() => {
    if (canDrawGrid) {
      const columns: GridColumn[] = [
        { header: t("cp.roadMap.reduceGoal.header.fuflYy"), name: "fuflYy", align: "center" },
        {
          header: t("cp.roadMap.reduceGoal.header.totalEmsnQnt"),
          name: "totalEmsnQnt",
          align: "right",
          formatter: (e: any) => Number(e.value.toFixed(2)).toLocaleString(),
        },
      ];
      if ("Y" === usingScop?.scopN1UseYn) {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop1EmsnQnt"),
          name: "totalScop1EmsnQnt",
          align: "right",
          formatter: (e: any) => {
            return Number(e.value.toFixed(2)).toLocaleString()
          },
        });
      } else {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop1EmsnQnt"),
          name: "totalScop1EmsnQnt",
          align: "right",
          renderer: { type: CustomBackGround },
        });
      }


      if ("Y" === usingScop?.scopN2UseYn) {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop2EmsnQnt"),
          name: "totalScop2EmsnQnt",
          align: "right",
          formatter: (e: any) => {
            return Number(e.value.toFixed(2)).toLocaleString()
          },
        });
      } else {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop2EmsnQnt"),
          name: "totalScop2EmsnQnt",
          align: "right",
          renderer: { type: CustomBackGround2 },
        });
      }

      if ("Y" === usingScop?.scopN3UseYn) {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop3EmsnQnt"),
          name: "totalScop3EmsnQnt",
          align: "right",
          formatter: (e: any) => {
            return Number(e.value.toFixed(2)).toLocaleString()
          },
        });
      } else {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop3EmsnQnt"),
          name: "totalScop3EmsnQnt",
          align: "right",
          renderer: { type: CustomBackGround3 },
        });
      }

      setActvGrid(
        <ArcGrid
          columns={columns}
          gridRef={gridRef}
          datasource={{ readData: { url: "/cp/roadMap/activeData", method: "GET" } }}
          usePagination={false}
          rowHeaders={["checkbox"]}
          onDataLoaded={(e) => onGridDataLoaded(e)}
          onCheckRow={(e) => onCheckedRow(e)}
        />
      );
    }
  }, [canDrawGrid, reduceGoalContext.total.years]);

  const checkIsSeriesYear = (arr: number[]) => {

    if (arr.length > 0) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i] + 1 !== arr[i + 1] && arr[i] - 1 !== arr[i + 1]) {
          return false;
        }
      }
    }
    return true;
  };

  const onCheckedRow = (e: ArcGridCheckResult) => {
    let selectedYears: number[] = e.checkedRows.map((m) => Number(m.fuflYy)).reverse();
    let selectedEmsn: number[] = e.checkedRows.map((m) => Number(m.totalEmsnQnt)).reverse();

    if (e.isChecked) {
      if (e.checkedRows.length > 3) {
        toast.error(t("cp.roadMap.reduceGoal.message.2"));
        gridRef.current?.getInstance().uncheck(e.checkedRowData.rowKey);
      } else {
        if (checkIsSeriesYear(selectedYears)) {
          setSelectedActvYear(selectedYears);
          setSelectedActvTotalEmsn(selectedEmsn);
        } else {
          toast.error(t("연속된 연도만 선택하세요."));
          if (e.isChecked) {
            gridRef.current?.getInstance().uncheck(e.checkedRowData.rowKey);
          }
        }
      }
    } else {
      if (checkIsSeriesYear(selectedYears)) {
        setSelectedActvYear(selectedYears);
        setSelectedActvTotalEmsn(selectedEmsn);
      } else {
        toast.error(t("cp.roadMap.reduceGoal.message.4"));
        gridRef.current?.getInstance().check(e.checkedRowData.rowKey);
      }
    }
  };

  function onGridDataLoaded(data: any[]) {
    const contextYears = reduceGoalContext.total.years;
    if (contextYears.length > 0) {
      const rowKeys = contextYears.map((m) => {
        return data.find((d) => d.fuflYy === m.toString())?.rowKey;
      });

      if (rowKeys) {
        if (rowKeys.length === 1) {
          gridRef.current?.getInstance().check(rowKeys);
        } else if (rowKeys.length > 1) {
          gridRef.current?.getInstance().checkBetween(rowKeys[0], rowKeys[rowKeys.length - 1]);
        }
      }
    }
  }

  const handleClickNextPage = () => {
    if (selectedActvYear.length > 0 && selectedActvTotalEmsn.length > 0) {
      reduceGoalContext.setTotal({
        ...reduceGoalContext.total,
        emsn: selectedActvTotalEmsn.reduce((acc, cur) => acc + cur, 0) / selectedActvTotalEmsn.length,
        years: selectedActvYear ?? [],
      });
      props.onNext();
    } else {
      toast.error(t("cp.roadMap.reduceGoal.message.1"));
    }
  };

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12 min-h100-type1">
          <div className="cont">
            <div className="titFlex">
              <ul className="tab-order-st1">
                <li className="on">
                  <div>
                    <strong>01</strong>
                    <span>{t("cp.roadMap.reduceGoal.index.1")}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <strong>02</strong>
                    <span>{t("cp.roadMap.reduceGoal.index.2")}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <strong>03</strong>
                    <span>{t("cp.roadMap.reduceGoal.index.3")}</span>
                  </div>
                </li>
              </ul>
              <div className="btns mgb0 ar">
                <ArcButton
                  type={ButtonType.custom}
                  text={t("cp.roadMap.reduceGoal.after")}
                  color={ButtonColor.btn2}
                  onClick={handleClickNextPage}
                />
              </div>
            </div>
            <hr className="line3" />
            <h3 className="titT1">온실가스 감축목표 설정</h3>
            <div className="col-wrap">
              <div className="col-md-12">
                <div className="box-style1">
                  <div className="box-text-style4">
                    <p>
                      온실가스 감축 목표 설정은 기업의 탄소중립 대응의 최종 단계로, 앞서 구축한{" "}
                      <strong>온실가스 인벤토리를 기반</strong>으로 감축량과 목표 강도 및 수준을 설정하고 감축 수행
                      방법을 도출하여 기업이 합리적인 목표를 수립하고 현실적으로 목표를 이행할 수 있도록 하는 것을
                      목적으로 합니다.
                    </p>
                    <span className="hl-text">
                      ※ 온실가스 인벤토리가 구축되어 있지 않으면 기준연도를 설정할 수 없습니다.
                    </span>
                  </div>
                  <div>
                    <hr className="line2" />
                    <div>
                      <h4 className="titT3">기준연도 선정 가이드</h4>
                      <ol className="list-ol1">
                        <li>
                          <span className="num">01</span>사업자는 업체의 온실가스 배출 현황을 반영할 수 있도록 적어도
                          2015년 이후로 기준 연도를 선택하는 것이 좋습니다.
                        </li>
                        <li>
                          <span className="num">02</span>조직경계 내에서 Scope 1, 2 배출량을 산정할 수 있는 신뢰성 있고
                          검증 가능한 모든 데이터가 존재하는 가장 최근 연도를 기준연도로 선택하는 것이 좋습니다.
                        </li>
                        <li>
                          <span className="num">03</span>기준연도는 해당 사업자의 일반적인 온실가스 배출 경향을 대표할
                          수 있는 연도로 설정하는 것이 좋습니다. 만약 대표성을 가진 단일연도를 선택하기 어려운 경우
                          사업자는 최근 연속된 3년간의 온실가스 배출량 평균을 기준연도 배출량으로 활용합니다
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="srh-filter2 mgt10">
              <h3 className="titT3 mgb0">{t("cp.roadMap.reduceGoal.index.1")}</h3>
              <ul>
                <li className="mgl15">
                  <div className="srh-cont">
                    <ArcInput
                      value={
                        selectedActvYear.length === 1
                          ? `${selectedActvYear[0]}${t("common.unit.year")}`
                          : selectedActvYear.length > 1
                            ? `${Math.min(...selectedActvYear)} ~ ${Math.max(...selectedActvYear)}${t("common.unit.year")}`
                            : ""
                      }
                      inputType="text"
                      isRequired={true}
                      readonly={true}
                      placeholder={t("cp.common.crtrYy")}
                      className={"InpSel-150"}
                      id="fuflYyInput"
                      onChange={() => ""}
                    />
                  </div>
                </li>
                <li>
                  <div className="srh-cont">
                    <ArcInput
                      value={
                        selectedActvTotalEmsn.length === 1
                          ? `${Number(selectedActvTotalEmsn[0].toFixed(2)).toLocaleString()}`
                          : selectedActvTotalEmsn.length > 1
                            ? `${Number(
                              (selectedActvTotalEmsn.reduce((acc, cur) => acc + cur, 0) / selectedActvTotalEmsn.length).toFixed(2)
                            ).toLocaleString()
                            }`
                            : ""
                      }
                      inputType={"text"}
                      isRequired={true}
                      readonly={true}
                      placeholder={t("cp.common.totalEmsnQnt")}
                      prefix={""}
                      id="totalEmsnAvg"
                      className="InpSel-150"
                      onChange={() => ""}
                    />
                    <span className="input-util">{t("cp.common.co2eq")}</span>
                  </div>
                </li>
                <li>
                  <p className="bu-atte">연속된 연도 선택 시 평균 값으로 계산됩니다.</p>
                </li>
              </ul>
            </div>
            <div className="mgt15">
              <h3 className="titT3">{t("cp.roadMap.reduceGoal.title")}</h3>
              {actvGrid}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
