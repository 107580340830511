import { ApiUrlUtil } from "utils/apiUrlUtil";
import axios from "axios";
import { HeadersUtil } from "utils/headersUtil";
import { ActvScop } from "model/cp/actvScop";
import Api from "services/common/Api";
import { ActvMgnl } from "model/cp/actvMgnl";

export class ActvScopService extends Api {

  private static DELETE_LIST_URL: string = process.env['REACT_APP_URL_CP'] + '/actv/actvScopListDelete';
  private static DELETE_DETAIL_URL: string = process.env['REACT_APP_URL_CP'] + '/actv/actvScopDelete';
  private static SELECT_USER_COMPANY_URL: string = process.env['REACT_APP_URL_CO'] + '/user/company/name';
  private static GET_REPORT_URL: string = process.env['REACT_APP_URL_CP'] + '/report/actv'


  private static _boardService: ActvScopService;
  static readonly deleteRow: any;

  // instance 생성
  public static getInstance(): ActvScopService {
    if (!ActvScopService._boardService) {
      ActvScopService._boardService = new ActvScopService();
    }
    return ActvScopService._boardService;
  }

  // 사용자 년도별 매출액 조회
  public getSalesAmt(fuflYy: string) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/actv/select?fuflYy=" + fuflYy
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // 사용자 년도별 매출액 저장
  public async saveSalesAmt(data: any) {
    return await super.post<ActvScop>(process.env.REACT_APP_URL_CP + "/actv/save", data);
  }

  // 사용자 scope 체크 값 조회
  public async getCheckScope() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/actv/mgnl/select");
    return await super.get<ActvMgnl>(url);
  }

  public async saveCheckScope(data: any) {
    return await super.post<ActvScop>(process.env.REACT_APP_URL_CP + "/actv/mgnl/save", data);
  }

  public async deleteActvScop(actvScop: ActvScop[]) {
    return await super.post<ActvScop[]>(ActvScopService.DELETE_LIST_URL, { deleteRows: actvScop })
  }

  public async deleteActvScopList(actvScop: any[]) {
    return await super.post<any[]>(process.env['REACT_APP_URL_CP'] + '/actv/scopMmQntDel', { deleteRows: actvScop })
  }

  public async deleteActvScopDetail(fuflYy: string) {
    return await super.delete<ActvScop>(ActvScopService.DELETE_DETAIL_URL, { fuflYy: fuflYy })
  }

  // 로그인 한 회원 회사명 조회
  public getUserCompany() {
    const url = ApiUrlUtil.buildQueryString(ActvScopService.SELECT_USER_COMPANY_URL);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public async getScop1(fuflYy: any, scopId: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/actvScop/scop1", { fuflYy: fuflYy, scopId: scopId });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // Hàm gọi API POST /scop1
  public async insertScop1(data: any) {
    return await super.post<ActvScop>(process.env.REACT_APP_URL_CP + "/actvScop/scop1", data);
  }

  // Hàm gọi API PUT /scop1
  public async updateScop1(data: any) {
    return await super.put<ActvScop>(process.env.REACT_APP_URL_CP + "/actvScop/scop1", data);
  }

  public async getScop2(fuflYy: any, scopId: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/actvScop/scop2", { fuflYy: fuflYy, scopId: scopId });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public async insertScop2(data: any) {
    return await super.post<ActvScop>(process.env.REACT_APP_URL_CP + "/actvScop/scop2", data);
  }

  public async updateScop2(data: any) {
    return await super.put<ActvScop>(process.env.REACT_APP_URL_CP + "/actvScop/scop2", data);
  }

  public async getScop3(fuflYy: any, scopId: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/actvScop/scop3", { fuflYy: fuflYy, scopId: scopId });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public async insertScop3(data: any) {
    return await super.post<ActvScop>(process.env.REACT_APP_URL_CP + "/actvScop/scop3", data);
  }

  public async updateScop3(data: any) {
    return await super.put<ActvScop>(process.env.REACT_APP_URL_CP + "/actvScop/scop3", data);
  }

  public async getReportData(data: any) {
    return await super.get<any>(ActvScopService.GET_REPORT_URL, data);
  }
}


