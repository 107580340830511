import ArcInput from "components/arc/ArcInput";
import ArcButton from "components/arc/ArcButton";
import {useLocation, useNavigate} from "react-router-dom";
import ArcFileUploader, {dbFileInfo} from "components/arc/ArcFileUploader";
import React, {useEffect, useState} from "react";
import {useAppDispatch} from "stores/hook";
import {toast} from "react-toastify";
import {showAndHideSpinner} from "reducers/spinnerSlice";
import {useTranslation} from "react-i18next";
import {Mhdlg} from "model/cp/Mhdlg";
import ArcSelectbox, {SelectboxSize} from "components/arc/ArcSelectbox";
import {FileInfoModel} from "model/common/fileInfoModel";
import {CrudConstant} from "constants/crudConstant";
import ArcDatePicker, {ArcDatePickerResult} from "components/arc/ArcDatePicker";
import {closeModal, openModal} from "reducers/modalSlice";
import AppAlert from "components/common/AppAlert";
import {MhdlgService} from "services/cp/MhdlgService";
import {ResponseResultCode} from "model/response-api";

const scope = [
  { label: "선택하세요", value: "" },
  { label: "SCOPE1", value: "SCOPE1" },
  { label: "SCOPE2", value: "SCOPE2" },
  { label: "SCOPE3", value: "SCOPE3" },
];

const keyword = [
  { label: "선택하세요", value: "" },
  { label: "연료", value: "연료" },
  { label: "발전", value: "발전" },
  { label: "교체", value: "교체" },
  { label: "절감", value: "절감" },
  { label: "생산", value: "생산" },
  { label: "열", value: "열" },
  { label: "풍력", value: "풍력" },
  { label: "효율", value: "효율" },
];

export default function insert() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const locationState = useLocation().state;
  const [state,] = useState(locationState);
  const [form, setForm] = useState<Mhdlg>(locationState ?? new Mhdlg());

  type FileData = {
    name: any;
    size: any;
    fileId: any;
    crud: any;
  };

  const [mhdlgDataFiles, setMhdlgDataFiles] = useState<FileData[]>([]);
  const [bizDataFiles, setBizDataFiles] = useState<FileData[]>([]);
  const [moniDataFiles, setMoniDataFiles] = useState<FileData[]>([]);
  const [newMhdlgFiles, setNewMhdlgFiles] = useState<File[]>([]);
  const [newBizFiles, setNewBizFiles] = useState<File[]>([]);
  const [newMoniFiles, setNewMoniFiles] = useState<File[]>([]);


  function handleExistMhdlgFile(files: any[]) {
    const deletedFiles: any[] = mhdlgDataFiles.filter((f: any) => !files.includes(f));
    let formFiles = form.file?.files?.map((m: FileInfoModel): FileInfoModel => {
      if (deletedFiles.findIndex((f: any) => f.fileId === m.fileId) >= 0) {
        return { ...m, crud: CrudConstant.D };
      }
      return { ...m };
    });
    setNewMhdlgFiles((prevFiles) =>
      prevFiles.filter((file) => !deletedFiles.some((f) => file.name === f.name && file.size === f.size))
    );
    setForm({
      ...form,
      file: {
        ...form.file,
        files: formFiles,
      },
    });
  }

  function handleExistBizFile(files: any[]) {
    const deletedFiles: any[] = bizDataFiles.filter((f: any) => !files.includes(f));
    let formFiles = form.bizFile?.files?.map((m: FileInfoModel): FileInfoModel => {
      if (deletedFiles.findIndex((f: any) => f.fileId === m.fileId) >= 0) {
        return { ...m, crud: CrudConstant.D };
      }
      return { ...m };
    });
    setNewBizFiles((prevFiles) =>
      prevFiles.filter((file) => !deletedFiles.some((f) => file.name === f.name && file.size === f.size))
    );
    setForm({
      ...form,
      bizFile: {
        ...form.bizFile,
        files: formFiles,
      },
    });
  }

  function handleExistMoniFile(files: any[]) {
    const deletedFiles: any[] = moniDataFiles.filter((f: any) => !files.includes(f));
    let formFiles = form.moniFile?.files?.map((m: FileInfoModel): FileInfoModel => {
      if (deletedFiles.findIndex((f: any) => f.fileId === m.fileId) >= 0) {
        return { ...m, crud: CrudConstant.D };
      }
      return { ...m };
    });
    setNewMoniFiles((prevFiles) =>
      prevFiles.filter((file) => !deletedFiles.some((f) => file.name === f.name && file.size === f.size))
    );
    setForm({
      ...form,
      moniFile: {
        ...form.moniFile,
        files: formFiles,
      },
    });
  }

  function handleClickMhdlgFileAdd(files: File[]) {
    setNewMhdlgFiles((prevFiles) => [...prevFiles, ...files]);

    const existingFiles = form.file?.files || [];
    setForm({
      ...form,
      file: {
        ...form.file,
        files: [...existingFiles, ...files],
      },
    });
  }

  function handleClickBizFileAdd(bizFiles: File[]) {
    setNewBizFiles((prevFiles) => [...prevFiles, ...bizFiles]);

    const existingFiles = form.bizFile?.files || [];
    setForm({
      ...form,
      bizFile: {
        ...form.bizFile,
        files: [...existingFiles, ...bizFiles],
      },
    });
  }

  function handleClickMoniFileAdd(moniFiles: File[]) {
    setNewMoniFiles(moniFiles);
  }

  useEffect(() => {
    let updatedForm = new Mhdlg();
    if (state) {
      updatedForm = JSON.parse(state);
      let mhdlgFile: dbFileInfo[] = [];
      let bizFile: dbFileInfo[] = [];
      let moniFile: dbFileInfo[] = [];

      setForm({
        ...form,
        ["regrId"]: updatedForm.regrId,
      });
      setForm({
        ...form,
        ["regDate"]: updatedForm.regDate,
      });

      if (updatedForm.fileRespons) {
        updatedForm.fileRespons.map((value: any) => {
          let fileInfo: any = {};
          fileInfo = value.fileInfoList;
          mhdlgFile.push({
            name: fileInfo.rlFileNm,
            size: fileInfo.fileCpct,
            fileId: fileInfo.atflId,
          });
        });
      }

      if (updatedForm.bizFileRespons) {
        updatedForm.bizFileRespons.map((value: any) => {
          let fileInfo: any = {};
          fileInfo = value.fileInfoList;
          bizFile.push({
            name: fileInfo.rlFileNm,
            size: fileInfo.fileCpct,
            fileId: fileInfo.atflId,
          });
        });
      }

      if (updatedForm.moniFileRespons) {
        updatedForm.moniFileRespons.map((value: any) => {
          let fileInfo: any = {};
          fileInfo = value.fileInfoList;
          moniFile.push({
            name: fileInfo.rlFileNm,
            size: fileInfo.fileCpct,
            fileId: fileInfo.atflId,
          });
        });
      }
    }
    setForm(() => {
      return updatedForm;
    });
  }, [state]);


  useEffect(() => {
    if (form?.file?.files) {
      const files = form.file.files.map((file: any) => ({
        name: file.realNm ?? file.name,
        size: file.size,
        fileId: file.fileId,
        crud: file.crud
      }));
      setMhdlgDataFiles(files);
    }
  }, [form?.file?.files]);

  useEffect(() => {
    if (form?.bizFile?.files) {
      const files = form.bizFile.files.map((file: any) => ({
        name: file.realNm ?? file.name,
        size: file.size,
        fileId: file.fileId,
        crud: file.crud
      }));
      setBizDataFiles(files);
    }
  }, [form?.bizFile?.files]);

  useEffect(() => {
    if (form?.moniFile?.files && form?.moniFile.files.length > 0) {
      const files = form.moniFile.files.map((file: any) => ({
        name: file.realNm ?? file.name,
        size: file.size,
        fileId: file.fileId,
        crud: file.crud
      }));
      setMoniDataFiles(files);
    }
  }, [form?.moniFile?.files]);

  function handleChangeInput(name: string, value: any) {
    if (["mhdlgNm", "fareaNm", "scgNm", "unqNo"].includes(name)) {
      setForm({
        ...form,
        [name]: value,
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  }

  const handleChangeDatePicker = (result: ArcDatePickerResult) => {
    setForm((prevState: any) => {
      return {
        ...prevState,
        vldStrtYmd: result.fromDateString
      };
    });
  }

  function handleChangeSelect(name: string, select: any) {
    if (["itmNm", "kwrdNm"].includes(name)) {
      setForm({
        ...form,
        [name]: select.target.value,
      });
    } else {
      setForm({
        ...form,
        [name]: select.target.value,
      });
    }
  }

  const handleSubmit = () => {
    dispatch(openModal({
      path: 'cp/methodology/confirm/insert',
      target: AppAlert,
      confirmProps: {
        type: "confirm",
        title: '방법론 저장',
        message: '방법론을 저장하시겠습니까?',
        onConfirm: saveForm,
        onClose: () => dispatch(closeModal())
      }
    }));
  };

  function validationForm(form: any) {
    let check: boolean = true;

    // 객체 중 crud값이 D인걸 다 지웠을때 길이가 0 이거나, 애초에 객체의 file이 없으면(기존파일) false
    const validateFiles = (fileList: any[]) => {
      if (!fileList) return false;
      const remainingFiles = fileList.filter(file => file.crud !== "D");
      return remainingFiles.length > 0;
    };

    if (!form.mhdlgNm ||
      (newMhdlgFiles.length === 0 && (!form.file || !validateFiles(form.file.files))) ||
      (newBizFiles.length === 0 && (!form.bizFile || !validateFiles(form.bizFile.files))) ||
      (newMoniFiles.length === 0 && (!form.moniFile || !validateFiles(form.moniFile.files)))
    ) {
      check = false;
    }

    if (check) {
      setForm((prevState: any) => {
        return {
          ...prevState,
          mhdlgNm: prevState.mhdlgNm ?? null,
          fareaNm: prevState.fareaNm ?? null,
          scgNm: prevState.scgNm ?? null,
          unqNo: prevState.unqNo ?? null,
          vldStrtYmd: prevState.vldStrtYmd ?? null,
          itmNm: prevState.itmNm ?? null,
          kwrdNm: prevState.kwrdNm ?? null,
        };
      });
    } else {
      toast.error("필수값을 모두 작성해주시기 바랍니다.")
    }
    return check;
  }

  const saveForm = async () => {
    if (validationForm(form)) {
      dispatch(showAndHideSpinner(true));

      console.log(form)
      const formData: FormData = new FormData();
      const data = JSON.stringify(form);
      formData.append("data", data);
      if (newMhdlgFiles.length > 0) {
        for (var i = 0; i < newMhdlgFiles.length; i++) {
          if (newMhdlgFiles[i]) {
            formData.append("files", newMhdlgFiles[i]);
          }
        }
      }

      if (newBizFiles.length > 0) {
        for (var i = 0; i < newBizFiles.length; i++) {
          if (newBizFiles[i]) {
            formData.append("bizFiles", newBizFiles[i]);
          }
        }
      }

      if (newMoniFiles.length > 0) {
        for (var i = 0; i < newMoniFiles.length; i++) {
          if (newMoniFiles[i]) {
            formData.append("moniFiles", newMoniFiles[i]);
          }
        }
      }
      const response = await MhdlgService.getInstance().saveMhdlg(formData);
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          toast.success(t("common.message.success.save", { title: "" }));
          const id = response.data.mhdlgId;
          navigate(`/cp/reduce/methodology/detail/${id}`);
        }
      }
      dispatch(showAndHideSpinner(false));
    }
  };


  return (
    <div className="col-wrap">
      <div className="col-md-12">
        <div className="cont">
          <div className="flexWrap mg0">
            <p className="desc"></p>
            <span className="bu-est"><strong>은 필수 항목입니다.</strong></span>
          </div>
          <div className="tbl-st2 mgt20">
            <table>
              <caption>방법론 현황</caption>
              <colgroup>
                <col style={{width: "15%"}}/>
                <col style={{width: "auto"}}/>
              </colgroup>
              <tbody className="al">
              <tr>
                <td className="tbl-bg-g tbl-L-T2 bu-est"><label htmlFor={"title"} title={"*필수입력 방법론 명"}>방법론 명</label></td>
                <td className="tbl-L-T2">
                    <span>
                      <ArcInput
                          inputType={"text"}
                          isRequired={true}
                          value={form.mhdlgNm ?? ""}
                          id={"title"}
                          title={"방법론 명 입력"}
                          onChange={(text) => handleChangeInput("mhdlgNm", text)}
                          placeholder={"방법론 명을 입력해주세요"}
                      />
                    </span>
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g"><label htmlFor={"content1"}>방법론 분야</label></td>
                <td>
                <ArcInput
                      inputType={"text"}
                      isRequired={false}
                      value={form.fareaNm ?? ""}
                      id={"content1"}
                      title={"방법론 분야 입력"}
                      onChange={(text) => handleChangeInput("fareaNm", text)}
                      placeholder={"방법론 분야를 입력해주세요"}
                  />
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g "><label htmlFor={"content2"}>세부분류</label></td>
                <td className="">
                    <span>
                      <ArcInput
                          inputType={"text"}
                          isRequired={false}
                          value={form.scgNm ?? ""}
                          id={"content2"}
                          title={"세부분류 입력"}
                          onChange={(text) => handleChangeInput("scgNm", text)}
                          placeholder={"세부분류를 입력해주세요"}
                      />
                    </span>
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g"><label htmlFor={"content3"}>방법론 고유번호</label></td>
                <td>
                <ArcInput
                      inputType={"text"}
                      isRequired={false}
                      value={form.unqNo ?? ""}
                      id={"content3"}
                      title={"방법론 고유번호 입력"}
                      onChange={(text) => handleChangeInput("unqNo", text)}
                      placeholder={"방법론 고유번호를 입력해주세요"}
                  />
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g"><label htmlFor={"input-20"}>방법론 유효시작일</label></td>
                <td className="">
                    <span>
                      <ArcDatePicker
                          onChange={handleChangeDatePicker}
                          id={"input-20"}
                          name={"input-20"}
                          viewType={"date"}
                          fromDate={form.vldStrtYmd ?? ""}
                          title={"방법론 유효시작일"}
                      />
                    </span>
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g"><label htmlFor={"content5"}>적용 SCOPE</label></td>
                <td className="">
                    <span>
                      <ArcSelectbox
                          id={"content5"}
                          name={"content5"}
                          title={"적용 SCOPE 선택"}
                          options={scope}
                          isRequired={false}
                          selectedValue={form.itmNm ?? ""}
                          onChange={(select) => handleChangeSelect("itmNm", select)}
                          size={SelectboxSize.px200}
                      />
                    </span>
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g"><label htmlFor={"content6"}>적용 KEYWORD</label></td>
                <td className="">
                    <span>
                      <ArcSelectbox
                          id={"content6"}
                          name={"content6"}
                          title={"적용 KEYWORD 선택"}
                          options={keyword}
                          isRequired={false}
                          selectedValue={form.kwrdNm ?? ""}
                          onChange={(select) => handleChangeSelect("kwrdNm", select)}
                          size={SelectboxSize.px200}
                      />
                    </span>
                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g bu-est" title={"*필수입력 방법론 첨부파일"}>방법론 첨부파일</td>
                <td>
                  <ArcFileUploader
                      maxFileNum={5}
                      maxFileSizeMb={90}
                      dbFileList={mhdlgDataFiles.filter(file => file.crud !== "D")}
                      dbUpload={handleExistMhdlgFile}
                      onUpload={handleClickMhdlgFileAdd}
                      key={mhdlgDataFiles.filter(file => file.crud !== "D").length}
                  />

                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g bu-est" title={"*필수입력 사업계획서 첨부파일"}>사업계획서 첨부파일</td>
                <td>
                  <ArcFileUploader
                      maxFileNum={1}
                      maxFileSizeMb={90}
                      dbFileList={bizDataFiles.filter(file => file.crud !== "D")}
                      dbUpload={handleExistBizFile}
                      onUpload={handleClickBizFileAdd}
                      key={bizDataFiles.filter(file => file.crud !== "D").length}
                  />

                </td>
              </tr>
              <tr>
                <td className="tbl-bg-g bu-est" title={"*필수입력 모니터링 첨부파일"}>모니터링 첨부파일</td>
                <td>
                  <ArcFileUploader
                      maxFileNum={1}
                      maxFileSizeMb={90}
                      dbFileList={moniDataFiles.filter(file => file.crud !== "D")}
                      dbUpload={handleExistMoniFile}
                      onUpload={handleClickMoniFileAdd}
                      key={moniDataFiles.filter(file => file.crud !== "D").length}
                  />방
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="btns ar">
            <ArcButton type={"cancel"} onClick={() => navigate(-1)}/>
            <ArcButton type={"save"} hasIcon={true} onClick={handleSubmit}/>
          </div>
        </div>
      </div>
    </div>
  );
}
