import React from 'react';
import i18n from "config/translation/i18n";
import {DefaultTFuncReturn} from "i18next";

interface Type {
  text: TypeDetail;
  search: TypeDetail;
  reset: TypeDetail;

  regist: TypeDetail;
  save: TypeDetail;
  submit: TypeDetail;
  detail: TypeDetail;
  modify: TypeDetail;
  delete: TypeDetail;
  cancel: TypeDetail;

  confirm: TypeDetail;
  download: TypeDetail;
  print: TypeDetail;
  calendar: TypeDetail;
  setting: TypeDetail;
  custom: TypeDetail;
}

interface TypeDetail {
  color: string | null,
  text: string | null,
  icon: string | null,
}

type ButtonType = keyof Type;

type ButtonClass = {
  [key in ButtonType]: TypeDetail;
}

export const ButtonColor = {
  btn1: 'btn-style1',
  btn2: 'btn-style2',
  btn3: 'btn-style3',
  btn4: 'btn-style4',
  btn5: 'btn-style5',
  btn6: 'btn-style6',
  btn7: 'btn-style7',
  btn8: 'btn-style8',
  btn9: 'btn-style9',
  btn10: 'btn-style10',
  btn11: 'btn-style11',
} as const;

export const enumButtonType: ButtonClass = {
  text: {
    color: ButtonColor.btn1,
    text: null,
    icon: 'las la-share',
  },
  search: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.search'),
    icon: 'las la-search',
  },
  reset: {
    color: ButtonColor.btn1,
    text: i18n.t('common.button.reset'),
    icon: 'las la-reset',
  },

  regist: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.regist'),
    icon: 'las la-pen',
  },
  save: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.save'),
    icon: 'las la-save',
  },
  submit: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.save'),
    icon: null,
  },
  detail: {
    color: ButtonColor.btn1,
    text: i18n.t('common.button.detail'),
    icon: 'las la-share',
  },
  modify: {
    color: ButtonColor.btn3,
    text: i18n.t('common.button.modify'),
    icon: 'las la-edit',
  },
  delete: {
    color: ButtonColor.btn4,
    text: i18n.t('common.button.delete'),
    icon: 'las la-trash-alt',
  },
  cancel: {
    color: ButtonColor.btn5,
    text: i18n.t('common.button.cancel'),
    icon: 'las la-trash-alt',
  },

  confirm: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.confirm'),
    icon: null,
  },
  download: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.download'),
    icon: null,
  },
  print: {
    color: ButtonColor.btn6,
    text: i18n.t('common.button.print'),
    icon: 'las la-print',
  },
  calendar: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.calendar'),
    icon: null,
  },
  setting: {
    color: ButtonColor.btn6,
    text: i18n.t('common.button.setting'),
    icon: 'las la-cog',
  },
  custom: {
    color: null, text: null, icon: null,
  }
} as const;

export const ButtonShape = {
  normal: '',
  round: 'btn-round',
} as const;

export const ButtonSize = {
  small: 'btn-small',
  regular: '',
  large: 'btn-lage'
} as const;

type ButtonColor = typeof ButtonColor[keyof typeof ButtonColor];
type ButtonShape = typeof ButtonShape[keyof typeof ButtonShape];
type ButtonSize = typeof ButtonSize[keyof typeof ButtonSize];

export interface AppButtonProps {
  type?: ButtonType;
  onClick?: (data?: any) => void;
  color?: ButtonColor;
  className?: string;
  text?: string | DefaultTFuncReturn;
  shape?: ButtonShape;
  size?: ButtonSize;
  hasIcon?: boolean | string;
}

export default function AppButton(props: AppButtonProps) {
  const {
    type= "custom",
    onClick,
    color,
    className,
    text,
    shape = ButtonShape.normal,
    size = ButtonSize.regular,
    hasIcon,
  } = props;

  const buttonColor = color??enumButtonType[type]['color'];
  const buttonClassNames: string = makeButtonClassName();

  function makeButtonClassName() {
    let buttonClass = '';
    buttonClass += buttonColor ? buttonColor + ' ' : '';
    buttonClass += className ? className + ' ' : '';
    buttonClass += shape ? shape + ' ' : '';
    buttonClass += size ? size + ' ' : '';

    return buttonClass;
  }

  let buttonIcon = '';
  if(hasIcon) {
    if('boolean' === typeof hasIcon) {
      buttonIcon = enumButtonType[type]['icon']??'';
    } else {
      buttonIcon = hasIcon;
    }
  }

  const handleClickButton = (data?: any) => {
    data.preventDefault();
    if(onClick) {
      onClick();
    }
  }

  if('submit' === type) {
    return (
      <button type="submit" className={buttonClassNames}>
        {hasIcon ? (<i className={buttonIcon}></i>) : <></>}
        <span>{text??enumButtonType[type]['text']??''}</span>
      </button>
    )
  } else {
    return (
      <a href="#" className={buttonClassNames} onClick={handleClickButton}>
        <span>
          {hasIcon ? (<i className={buttonIcon} aria-hidden={true}></i>) : <></>}
          {text??enumButtonType[type]['text']??''}
        </span>
      </a>
    )
  }

}