import Scope1EmissionGrid from "./Scope1EmissionGrid";
import Scope1WasteGrid from "./Scope1WasteGrid";
import Scope1FuelGrid from "./Scope1FuelGrid";
import ArcCheckbox from "components/arc/ArcCheckbox";
import { BoardScopeProps } from "./ActiveDataDetail";
import { useEffect, useState } from "react";

export default function Scope1(props: Readonly<BoardScopeProps>) {

  const [scop1Data, setScop1Data] = useState({
    scop1Fuel: props.data.scop1Fuel || {},
    scop1Waste: props.data.scop1Waste || {},
    scop1Emiss: props.data.scop1Emiss || {},
  });

  useEffect(() => {
    props.onData(scop1Data);
  }, [scop1Data]);

  const handleDataFromChild1 = (data: any) => {
    setScop1Data(prevData => ({
      ...prevData,
      scop1Fuel: data,
    }));
  };

  const handleDataFromChild2 = (data: any) => {
    setScop1Data(prevData => ({
      ...prevData,
      scop1Waste: data,
    }));
  };

  const handleDataFromChild3 = (data: any) => {
    setScop1Data(prevData => ({
      ...prevData,
      scop1Emiss: data,
    }));
  };

  const handleIsGroup = (isChecked: any) => {
  };

  return (
    <>
      <div className="tit-check-btns">
        <h4 className="titT3">구분1. 연료사용</h4>
        <span className="checkbox-radio-type02">
          <label htmlFor="checkbox1-2">
            <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
              checked={props?.scopUse === "N" ? true : false} isAlt={true} disabled></ArcCheckbox>
            입력사항 없음
          </label>
        </span>
      </div>
      <Scope1FuelGrid fuflYy={props.fuflYy} scopId={2} scopUse={props.scopUse} onData={handleDataFromChild1} data={props.data.scop1Fuel}></Scope1FuelGrid>
      <div className="tit-check-btns mgt40">
        <h4 className="titT3">구분2. 폐기물 소각</h4>
        <span className="checkbox-radio-type02">
          <label htmlFor="checkbox1-2">
            <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
              checked={props?.scopUse === "N" ? true : false} isAlt={true} disabled></ArcCheckbox>
            입력사항 없음
          </label>
        </span>
      </div>
      <Scope1WasteGrid fuflYy={props.fuflYy} scopId={3} scopUse={props.scopUse} onData={handleDataFromChild2} data={props.data.scop1Waste}></Scope1WasteGrid>
      <div className="tit-check-btns mgt40">
        <h4 className="titT3">구분3. 공정배출</h4>
        <span className="checkbox-radio-type02">
          <label htmlFor="checkbox1-2">
            <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
              checked={props?.scopUse === "N" ? true : false} isAlt={true} disabled></ArcCheckbox>
            입력사항 없음
          </label>
        </span>
      </div>
      <Scope1EmissionGrid fuflYy={props.fuflYy} scopId={4} scopUse={props.scopUse} onData={handleDataFromChild3} data={props.data.scop1Emiss}></Scope1EmissionGrid>
    </>
  );
}
