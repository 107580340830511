import React, {useContext, useEffect, useState} from "react";
import {
  BusinessFormContext
} from "pages/cp/reductionProject/history/BusinessForm/BusinessFormContext";
import ArcButton, {ButtonType} from "components/arc/ArcButton";
import ArcSpreadJS from "components/arc/ArcSpreadJS";
import {useAppDispatch} from "stores/hook";
import {openModal} from "reducers/modalSlice";
import AppAlert from "components/common/AppAlert";
import {showAndHideSpinner} from "reducers/spinnerSlice";
import {MhdlgService} from "services/cp/MhdlgService";
import {ResponseResultCode} from "model/response-api";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FileService} from "services/common/FileService";
import {CommonUtil} from "utils/commonUtil";

export default function BusinessFormExcel(props: {onBefore: () => void}) {
  const context = useContext(BusinessFormContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [selectFile, setSelectFile] = useState<File|undefined>();

  useEffect(() => {
    const businessFile = context.data.businessFile;
    if(businessFile) {
      if(businessFile instanceof File) {
        setSelectFile(businessFile);
      } else {
        const filePath = process.env.REACT_APP_URL_CP??"";
        FileService.getInstance().fetchBlobFromServer(filePath, businessFile).then(response => {
          if(response) {
            const serverBlobToFile: File|undefined = CommonUtil.blobToFile(response, businessFile.realNm??"");
            setSelectFile(serverBlobToFile);
          }
        });
      }
    } else {
      MhdlgService.getInstance().getMhdlgFileInfoBySearch(context.data.form?.mhdlgId??"", "1").then(response => {
        if(response) {
          const serverBlobToFile: File|undefined = CommonUtil.blobToFile(response, (context.data.form?.mhdlgNm??"") + "_사업계획서.xlsx");
          setSelectFile(serverBlobToFile);
        }
      });
    }
  }, [context.data.businessFile]);

  const spreadCustomButton = (<ArcButton type={ButtonType.prev} onClick={props.onBefore}/>);

  function handleClickExportFileAndSave(excelFile: File) {
    dispatch(openModal({
      path: "/cp/reduce/history/business/save",
      target: AppAlert,
      confirmProps: {
        type: "save",
        onConfirm: () => saveForm(excelFile),
      }
    }));
  }

  function saveForm(excelFile: File) {
    if(excelFile) {
      dispatch(showAndHideSpinner(true));

      const formData: FormData = new FormData();
      const data = JSON.stringify(context.data.form);
      formData.append("data", data);
      formData.append("files", excelFile);

      MhdlgService.getInstance().saveDocBusiness(formData).then(response => {
        dispatch(showAndHideSpinner(false));
        if(response && ResponseResultCode.Y === response.resultCode) {
          toast.success(t('common.message.success.save', {title: ""}));
          const id = response.data.docId;
          navigate(`/cp/reduce/history/business/detail/${id}`);
        }
      });
    } else {
      toast.error('사업계획서 파일이 존재하지 않습니다.');
    }
  }

  return (<>
    <div className="container">
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">사업계획서 등록</h3>
              <hr className="line3" />

              <ArcSpreadJS importFile={selectFile}
                           customButton={spreadCustomButton}
                           saveFileName={selectFile?.name??""}
                           onSaveAsFile={handleClickExportFileAndSave}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </>);
}