import React, {useEffect, useState} from 'react';
import ArcButton, {ButtonShape, ButtonType} from "./ArcButton";

export interface dataSourceProps{
  realName:string;
  saveName:string;
}

export interface ArcDownloadProps {
  dataSource: dataSourceProps[];
  onDownload: (res: dataSourceProps) => void
  isCntr?: boolean
}

export default function ArcDownload(props: ArcDownloadProps) {

  const [uploadedFiles, setUploadedFiles] = useState(props.dataSource);
  useEffect(() => {
    setUploadedFiles(props.dataSource);
  }, [props.dataSource]);

  const downloadFile = (filename: dataSourceProps) => {
    props.onDownload(filename);
  };

  return (
    <div>
      {uploadedFiles.length > 0 && (
        <div>
          {!props.isCntr && (
            <ul>
              {uploadedFiles.map((file: dataSourceProps, index: any) => (
                <li key={index}>
                  {decodeURIComponent(file.realName)}
                  <ArcButton type={"download"}
                             className="mgl10"
                             hasIcon={true}
                             shape={ButtonShape.round}
                             onClick={() => downloadFile(file)}/>
                </li>
              ))}
            </ul>)}

          {props.isCntr && (
            <ul className="box-graph3 box-graph3-2" style={{width: "100%", display: "block", content: "none"}}>
              {uploadedFiles.map((file: dataSourceProps, index: any) => (
                <li key={index} className="mgb10" style={{width: "100% !important"}}>
                  <div style={{padding: "0.5rem 2.5rem"}}>
                    <em>{index + 1}</em>
                    <strong>{decodeURIComponent(file.realName)}</strong>
                    <span className="ar">
                      <ArcButton type={ButtonType.download}
                                 hasIcon={true}
                                 onClick={() => downloadFile(file)} />
                    </span>
                  </div>
                </li>
              ))}
            </ul>)}
        </div>
      )}
    </div>
  );
};