import {useEffect} from "react"
import ArcTable, {TableProps} from "../../components/arc/ArcTable";

export default function ArcTableExample() {
  const spData: any = {
      olClass: "",
      ulClass: "rd-tbl-bul1",
      label: "계산방법", sourceData: [{
        value: "1. 재생에너지 사용요금"
      }, {
        value: "2. 망이용료 : 기본료 + 사용료"
      }, {
        value: "3. 거래수수료 (0.1034원/kWh)"
      }]
    };

  const handleChecked = (isChecked: any) => {
  }

  const spDataOl: any = [
    {
      ulClass: "mgt5",
      label: "계산방법", sourceData: [{
        value: "1. 재생에너지 사용요금"
      }, {
        value: "2. 망이용료 : 기본료 + 사용료"
      }, {
        value: "3. 거래수수료 (0.1034원/kWh)"
      }]
    },
    {
      label: "계산방법 2", sourceData: [{
        value: "1. 재생에너지 사용요금"
      }, {
        value: "2. 망이용료 : 기본료 + 사용료"
      }, {
        value: "3. 거래수수료 (0.1034원/kWh)"
      }]
    }
  ];

  const sampleData: TableProps = {
    title: 'Sample Table',
    width: '100%',
    height: 300,
    tHeader: [
      {
        row: [
          {type: "th", content: 'ID', width: '20%', classStr: 'header-cell'},
          {type: "th", content: 'Name', width: '40%', classStr: 'header-cell'},
          {type: "th", content: 'Age', width: '20%', classStr: 'header-cell'},
        ],
      },
    ],
    tBody: [
      {
        row: [
          {type: "td", content: '101', width: '20%'},
          {type: "td", content: 'John Doe', width: '40%'},
          {type: "td", content: '28', width: '20%'},
        ],
      },
      {
        row: [
          {type: "td", content: '102', width: '20%'},
          {type: "td", content: 'Jane Smith', width: '40%'},
          {type: "td", content: '35', width: '20%'},
        ],
      },
      {
        row: [
          {type: "td", content: '103', width: '20%'},
          {type: "td", content: 'Bob Johnson', width: '40%'},
          {type: "td", content: '40', width: '20%'},
        ],
      },
    ],
  };

  const sampleDataRound: TableProps = {
    showCheck: true,
    title: 'Sample Table',
    width: '100%',
    height: 300,
    tHeader: [
      {
        row: [
          {
            type: "th",
            content: '',
            classStr: 'rd-tbl-c1',
            showCheck: true,
            onChecked: handleChecked
          },
          {
            type: "th",
            content: '자가발전',
            classStr: 'rd-tbl-c1',
            showCheck: true,
            onChecked: handleChecked
          },
          {
            type: "th",
            content: '전력 + REC',
            classStr: 'rd-tbl-c1',
            showCheck: true,
            onChecked: handleChecked
          },
          {
            type: "th",
            content: '제3자 PPA',
            classStr: 'rd-tbl-c1',
            showCheck: true,
            onChecked: handleChecked
          },
          {
            type: "th",
            content: '직접 PPA',
            classStr: 'rd-tbl-c3 badge',
            showCheck: true,
            onChecked: handleChecked
          },
        ],
      },
    ],
    tBody: [
      {
        row: [
          {type: "th", content: '개요', classStr: 'rd-tbl-c5'},
          {type: "ul", classStr: 'rd-tbl-b1', sampleData: spData},
          {type: "ul", classStr: 'rd-tbl-b1', sampleData: spData},
          {type: "ul", classStr: 'rd-tbl-b1', sampleData: spData},
          {type: "ul", classStr: 'rd-tbl-b1', sampleData: spData},

        ],
      },
      {
        row: [
          {type: "th", content: '개요', classStr: 'rd-tbl-c5'},
          {
            type: "ol",
            classStr: "rst-td rd-tbl-b1",
            olClass: "list-ol1",
            sampleData: spDataOl,
            olDivClass: "rst rd-tbl-c2",
            olSpanValue: "189원/MWh"
          },
          {
            type: "ol",
            classStr: "rst-td rd-tbl-b1",
            olClass: "list-ol1",
            sampleData: spDataOl,
            olDivClass: "rst rd-tbl-c2",
            olSpanValue: "189원/MWh"
          },
          {
            type: "ol",
            classStr: "rst-td rd-tbl-b1",
            olClass: "list-ol1",
            sampleData: spDataOl,
            olDivClass: "rst rd-tbl-c2",
            olSpanValue: "189원/MWh"
          },
          {
            type: "ol",
            classStr: "rst-td rd-tbl-b1",
            olClass: "list-ol1",
            sampleData: spDataOl,
            olDivClass: "rst rd-tbl-c2",
            olSpanValue: "189원/MWh"
          }
        ],
      },
    ],
  };

  const sampleData2: TableProps = {
    title: 'Sample Table',
    width: '100%',
    height: 300,
    tHeader: [],
    tBody: [
      {
        row: [
          {type: "th", content: '내용 제목', width: '50%', scope: "row"},
          {type: "td", content: '내용', width: '50%'},
          {type: "td", content: '내용', width: '15'},
          {type: "td", content: '1123213213213', width: '70%'},
        ],
      },
      {
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "row"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40px'},
        ],
      },
      {
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "row"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40px'},
        ],
      },
    ],
    tFooter: [
      {
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "row"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
    ]
  };

  const sampleData3: TableProps = {
    title: 'Sample Table',
    width: '100%',
    height: 300,
    tHeader: [
      {
        row: [
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '40%', scope: "col"},
        ],
      },

    ],
    tBody: [
      {
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
      {
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
      {
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
    ],
    tFooter: []
  };

  const sampleData4: TableProps = {
    title: 'Sample Table',
    width: '100%',
    height: 300,
    tHeader: [
      {
        row: [
          {type: "th", content: '기본테이블 tbl-st1', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '15%', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', width: '40%', scope: "col"},
        ],
      },

    ],
    tBody: [
      {
        trClassName: "tbl-bg-bl",
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
      {
        trClassName: "tbl-bg-ye",
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
      {
        trClassName: "tbl-bg-re",
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
      {
        trClassName: "tbl-bg-re",
        row: [
          {type: "th", content: '내용 제목', width: '15%', scope: "col", classStr: "tbl-bg-ye"},
          {type: "td", content: '내용', width: '15%', classStr: "tbl-bg-bl"},
          {type: "td", content: '내용', width: '15%', classStr: "tbl-bg-gr"},
          {type: "td", content: '내용', width: '15%'},
          {type: "td", content: '1123213213213', width: '40%'},
        ],
      },
    ],
    tFooter: []
  };

  const sampleData5: TableProps = {
    title: 'Sample Table',
    width: '100%',
    height: 300,
    tHeader: [
      {
        row: [
          {type: "th", content: '기본테이블 tbl-st1', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', scope: "col"},
          {type: "th", content: '기본테이블 tbl-st', scope: "col"},
        ],
      },

    ],
    tBody: [
      {
        row: [
          {type: "td", content: '4면 tbl-L', classStr: 'tbl-L'},
          {type: "td", content: '4면 tbl-Lb', classStr: 'tbl-Lb'},
          {type: "td", content: '4면 tbl-Lbl', classStr: 'tbl-Lbl'},
          {type: "td", content: '4면 tbl-Lre', classStr: 'tbl-Lre'},
          {type: "td", content: '4면 tbl-Lgr', classStr: 'tbl-Lgr'},
          {type: "td", content: '4면 tbl-Lye', classStr: 'tbl-Lye'},
        ],
      },
      {
        row: [
          {type: "td", content: '우측선 tbl-L-R', classStr: 'tbl-L-R'},
          {type: "td", content: '우측선 tbl-Lb-R', classStr: 'tbl-Lb-R'},
          {type: "td", content: '우측선 tbl-Lbl-R', classStr: 'tbl-Lbl-R'},
          {type: "td", content: '우측선 tbl-Lre-R', classStr: 'tbl-Lre-R'},
          {type: "td", content: '우측선 tbl-Lgr-R', classStr: 'tbl-Lgr-R'},
          {type: "td", content: '우측선 tbl-Lye-R', classStr: 'tbl-Lye-R'},
        ],
      },
      {
        row: [
          {type: "td", content: '상단선 tbl-L-T', classStr: 'tbl-L-T'},
          {type: "td", content: '상단선 tbl-Lb-T', classStr: 'tbl-Lb-T'},
          {type: "td", content: '상단선 tbl-Lbl-T', classStr: 'tbl-Lbl-T'},
          {type: "td", content: '상단선 tbl-Lre-T', classStr: 'tbl-Lre-T'},
          {type: "td", content: '상단선 tbl-Lgr-T', classStr: 'tbl-Lgr-T'},
          {type: "td", content: '상단선 tbl-Lye-T', classStr: 'tbl-Lye-T'},
        ],
      },
      {
        row: [
          {type: "td", content: '좌측선 tbl-L-T', classStr: 'tbl-L-L'},
          {type: "td", content: '좌측선 tbl-Lb-T', classStr: 'tbl-Lb-L'},
          {type: "td", content: '좌측선 tbl-Lbl-T', classStr: 'tbl-Lbl-L'},
          {type: "td", content: '좌측선 tbl-Lre-T', classStr: 'tbl-Lre-L'},
          {type: "td", content: '좌측선 tbl-Lgr-T', classStr: 'tbl-Lgr-L'},
          {type: "td", content: '좌측선 tbl-Lye-T', classStr: 'tbl-Lye-L'},
        ],
      },
      {
        row: [
          {type: "td", content: '하단선 tbl-L-T', classStr: 'tbl-L-B'},
          {type: "td", content: '하단선 tbl-Lb-T', classStr: 'tbl-Lb-B'},
          {type: "td", content: '하단선 tbl-Lbl-T', classStr: 'tbl-Lbl-B'},
          {type: "td", content: '하단선 tbl-Lre-T', classStr: 'tbl-Lre-B'},
          {type: "td", content: '하단선 tbl-Lgr-T', classStr: 'tbl-Lgr-B'},
          {type: "td", content: '하단선 tbl-Lye-T', classStr: 'tbl-Lye-B'},
        ],
      },


    ],
    tFooter: []
  };


  useEffect(() => {

  }, [])


  return (
      <>


        <ArcTable tableClass={"tbl-st"} tBody={sampleData.tBody} tHeader={sampleData.tHeader}
                  title={"Demo"}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>

        <h2>테이블 : 테이블 세로 타입 tbl-st-row</h2>
        <ArcTable tableClass={"tbl-st-row"} tBody={sampleData2.tBody} tHeader={sampleData2.tHeader}
                  tFooter={sampleData2.tFooter}
                  title={"Demo2"}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>

        <h2>테이블 : 테이블 4면 라인 tbl-st2</h2>
        <ArcTable tableClass={"tbl-st2"} tBody={sampleData3.tBody} tHeader={sampleData3.tHeader}
                  title={"Demo3"}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>


        <h2>테이블 : 테이블 스크롤</h2>
        <div>세로 스크롤이 필요할 시, 테이블 inline-style에 height정의 후, 테이블 스타일 클래스(tbl-st, tbl-st2, tbl-st-row)에
          'scroll-gr-y'
          클래스 추가
        </div>

        <div>가로 스크롤이 필요할 시, 테이블 inline-style에 width 후, 테이블 스타일 클래스(tbl-st, tbl-st2, tbl-st-row)에
          'scroll-gr' 클래스
          추가
        </div>
        <ArcTable tableClass={"tbl-st scroll-gr"} tBody={sampleData2.tBody}
                  tHeader={sampleData2.tHeader}
                  tFooter={sampleData2.tFooter}
                  title={"Demo2"}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>


        <h2>테이블 : 배경 색상 클래스 추가</h2>
        <ArcTable tableClass={"tbl-st"} tBody={sampleData4.tBody} tHeader={sampleData4.tHeader}
                  title={"Demo4"}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>

        <h2>테이블 : 라인 색상 클래스 추가</h2>
        <ArcTable tBody={sampleData5.tBody} tHeader={sampleData5.tHeader}
                  title={"Demo4"}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>


        <ArcTable showCheck={true} tableClass={"round-table"} tBody={sampleDataRound.tBody}
                  tHeader={sampleDataRound.tHeader}
                  title={"Demo"} onChecked={handleChecked}></ArcTable>
        <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}></div>

      </>
  )
}