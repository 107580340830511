import React, {useEffect, useRef, useState} from 'react';

import ArcGrid, {GridColumn} from "components/arc/ArcGrid";
import ArcSelectbox, {SelectboxSize, SelectDataModel} from "components/arc/ArcSelectbox";
import ArcInput from "components/arc/ArcInput";
import ArcButton, {ButtonIcon, ButtonType} from "components/arc/ArcButton";
import {RdmpCondService} from "services/rp/RdmpCondService";
import CountUp from "react-countup";
import {DbAdminService} from "services/db/DbAdminService";
import Re100ParticipationStatusChart1 from "./Re100ParticipationStatusChart1";
import moment from "moment";
import Re100ParticipationStatusChart2 from "./Re100ParticipationStatusChart2";

export default function DbAdminRe100ParticipationStatus() {

  const gridRef: any = useRef<typeof ArcGrid>();
  const currentYear = new Date().getFullYear().toString();
  const date = moment().format('YYYY.MM.DD');

  const [rdmpCount, setRdmpCount] = useState(0);
  const [rdmpCountIncrease, setRdmpCountIncrease] = useState(0);
  const [userCount, setUserCount] = useState<any>({});
  const [re100FuflCount, setRe100FuflCount] = useState<any>({});
  const [bzkndList, setBzkndList] = useState<any>();
  const [search, setSearch] = useState<any>({});

  const [searchOption1, setSearchOption1] = useState([
    {label: "전체", value: ""},
    {label: "대기업", value: "ECOM001"},
    {label: "중견기업", value: "ECOM002"},
    {label: "중소기업", value: "ECOM003"}
  ]);

  function selectOption(name: any, data: any) {
    setSearch({...search, [name]: data.target.value});
  }

  const addComma = (value: any) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const responseCount = RdmpCondService.getInstance().getRdmpCount();
    const respUserCount = DbAdminService.getInstance().getUserCount();
    const respRe100Fufl = DbAdminService.getInstance().getRe100Fufl();
    const respBzkndList = DbAdminService.getInstance().getBzkndList();

    Promise.all([responseCount.catch((error: any) => error),
      respUserCount.catch((error: any) => error), respRe100Fufl.catch((error: any) => error), respBzkndList.catch((error: any) => error)]).then(
        (values) => {
          setRdmpCount(values[0].data);
          setUserCount(values[1].data);
          setRe100FuflCount(values[2].data ?? {});
          let bzkndListRes: SelectDataModel[] = [{label: "전체", value: ""}];
          if (values[3]) {
            values[3].data.forEach((item: any) => {
              bzkndListRes.push({label: item.bzkndNm, value: item.bzkndId});
            });
          }
          setBzkndList(bzkndListRes);
          gridRef?.current?.getInstance().setColumns([
            {
              header: '단지',
              name: 'emksIndsCmplxClCd',
              align: "center",
              width: 100,
              whiteSpace: 'normal',
              formatter: function (e: any) {
                return e.value
              },
            },
            {
              header: '기업명',
              name: 'bizrNm',
              align: "center",
              whiteSpace: 'normal',
              formatter: function (e: any) {
                return e.value
              },
            },
            {
              header: '산업 분류',
              name: 'bzkndNm',
              align: "center",
              whiteSpace: 'normal',
              formatter: function (e: any) {
                return e.value
              },
            },
            {
              header: '기업 규모',
              name: 'emksEntClNm',
              align: "center",
              whiteSpace: 'normal',
              formatter: function (e: any) {
                return e.value
              },
            },
            {
              header: '총 소비전력(한전+재생e)(kWh)',
              name: 'totalQnt',
              align: "right",
              whiteSpace: 'normal',
              formatter: function (e: any) {
                return addComma(Math.floor(e.value))
              },
            },
            {
              header: '재생에너지(kWh)',
              name: 'genQnt',
              align: "right",
              width: 150,
              whiteSpace: 'normal',
              formatter: function (e: any) {

                return addComma(Math.floor(e.value));
              },
            },
            {
              header: 'RE(%)',
              name: 're100Qnt',
              align: "right",
              width: 80,
              whiteSpace: 'normal',
              formatter: function (e: any) {
                return Math.floor(e.value);
              },
            }
          ]);
        },
    );
  }, []);

  function handleSearch() {
    gridRef.current.getInstance().readData(1, search);
  }

  function duration(data: any) {
    if (data < 10) {
      return 4;
    } else {
      return 2;
    }
    // return 0.5;
  }

  function handleChangeInputSearch(result: any) {
    setSearch({...search, bizrNm: result.value});
  }

  return (
      <>
        <div className="col-wrap">
          <div className="col-md-6">
            <div className="cont">
              <h3 className="titT1">RE100 참여 현황<em className="update">최근 업데이트 {date}</em>
              </h3>
              <div className="icon-box-wrap7">
                <ul className="icon-box-flex">
                  <li>
                    <div className="c1">
                      <strong>총 산단기업</strong>
                      <div>
                      <span>
                        <CountUp end={Number(userCount.totalCount) ?? 0}
                                 duration={0.5}
                                 decimals={0}>
                          {({countUpRef}) => (
                              <em data-count="2000" className="change" ref={countUpRef}/>
                          )}
                        </CountUp>
                        개
                      </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>RE100 이행</strong>
                      <div>
                      <span>
                        <CountUp end={rdmpCount ?? 0}
                                 duration={0.5}
                                 decimals={0}>
                        {({countUpRef}) => (
                            <em data-count="2000" className="change" ref={countUpRef}/>
                        )}
                      </CountUp>
                        개</span>
                        <em className="up-down up" style={{display: "block"}}>
                          <CountUp
                              end={Number(rdmpCountIncrease) ?? 0}
                              duration={duration(rdmpCountIncrease)}
                              formattingFn={(value) => `${value}개`}
                          >
                            {({countUpRef}) => <em ref={countUpRef}/>}
                          </CountUp> 증가</em>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>RE100 달성</strong>
                      <div>
                      <span>
                        <CountUp end={re100FuflCount?.count ?? 0}
                                 duration={0.5}
                                 decimals={0}>
                        {({countUpRef}) => (
                            <em data-count="2000" className="change" ref={countUpRef}/>
                        )}
                      </CountUp>
                        개</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>RE100 관심</strong>
                      <div>
                      <span>
                        <CountUp end={userCount.re100InterestCount ?? 0}
                                 duration={0.5}
                                 decimals={0}>
                        {({countUpRef}) => (
                            <em data-count="2000" className="change" ref={countUpRef}/>
                        )}
                      </CountUp>
                        개</span>
                        <em className="up-down up">
                          <CountUp
                              end={Number(userCount.re100InterestTodayCount) ?? 0}
                              duration={duration(userCount.re100InterestTodayCount)}
                              formattingFn={(value) => `${value}개 `}
                          >
                            {({countUpRef}) => <em ref={countUpRef}/>}
                          </CountUp> 증가</em>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cont">
              <h3 className="titT1">참여기업 구성<em className="update">최근 업데이트 {date}</em></h3>
              <div className="icon-box-wrap7">
                <ul className="icon-box-flex">
                  <li>
                    <div className="c1">
                      <strong>대기업</strong>
                      <div>
                      <span>
                       <CountUp end={userCount.largeCount ?? 0}
                                duration={0.5}
                                decimals={0}>
                        {({countUpRef}) => (
                            <em data-count="2000" className="change" ref={countUpRef}/>
                        )}
                      </CountUp>
                        개
                      </span>

                        <em className="up-down up">
                          <CountUp
                              end={Number(userCount.largeTodayCount) ?? 0}
                              duration={duration(userCount.largeTodayCount)}
                              formattingFn={(value) => `${value}개 `}
                          >
                            {({countUpRef}) => <em ref={countUpRef}/>}
                          </CountUp> 증가
                        </em>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>중견기업</strong>
                      <div>
                      <span>
                        <CountUp end={userCount.mediumCount ?? 0}
                                 duration={0.5}
                                 decimals={0}>
                        {({countUpRef}) => (
                            <em data-count="2000" className="change" ref={countUpRef}/>
                        )}
                      </CountUp>
                        개
                      </span>

                        <em className="up-down up">
                          <CountUp
                              end={Number(userCount.mediumTodayCount) ?? 0}
                              duration={duration(userCount.re100InterestTodayCount)}
                              formattingFn={(value) => `${value}개 `}
                          >
                            {({countUpRef}) => <em ref={countUpRef}/>}
                          </CountUp> 증가
                        </em>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>중소기업</strong>
                      <div>
                      <span>
                        <CountUp end={Number(userCount.smallCount) ?? 0}
                                 duration={duration(userCount.smallCount)}>
                          {({countUpRef}) => <em ref={countUpRef}/>}
                        </CountUp>
                        개
                      </span>

                        <em className="up-down up">
                          <CountUp
                              end={Number(userCount.smallTodayCount) ?? 0}
                              duration={duration(userCount.smallTodayCount)}
                              formattingFn={(value) => `${value}개 `}
                          >
                            {({countUpRef}) => <em ref={countUpRef}/>}
                          </CountUp> 증가
                        </em>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="titFlex">
                <h3 className="titT1">RE100 참여 기업 목록</h3>
                <div className="srh-filter2 ac mgt5">
                  <ul>
                    <li>
                      <ArcSelectbox
                          className="mgr10"
                          name="emksEntClCd"
                          label="기업구분"
                          onChange={data => selectOption("emksEntClCd", data)}
                          options={searchOption1}
                          size={SelectboxSize.px150}
                      ></ArcSelectbox>
                    </li>
                    <li>
                      <ArcSelectbox
                          className="mgr10"
                          name="bzkndId"
                          label="산업구분"
                          onChange={data => selectOption("bzkndId", data)}
                          options={bzkndList}
                          size={SelectboxSize.px150}
                      ></ArcSelectbox>
                    </li>
                    <li>
                      <ArcInput
                          value={search.titlNm ?? ""}
                          id="titlNm"
                          label="기업명"
                          placeholder="기업명을 입력해주세요."
                          className="disIb"
                          type="w300"
                          onChange={handleChangeInputSearch}
                      />
                    </li>
                    <li>
                      <div className="InpSel disIb">
                        <ArcButton
                            type={ButtonType.search}
                            hasIcon={true}
                            onClick={handleSearch}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <ArcGrid gridRef={gridRef}
                       datasource={{
                         readData: {
                           url: '/co/company?emksRe100JoinClCd=0&sortColumn=userId&sortAscending=true',
                           method: 'GET',
                         }
                       }}
                       rowHeaders={["rowNum"]}
                       columns={[]}
                       useGridInfoHeader={true}
                       isTest={true}
              />
            </div>
          </div>
        </div>
        <div className="col-wrap">
          <div className="col-md-6">
            <div className="cont">
              <h3 className="titT1">기업 규모별 이행 현황 ({currentYear})</h3>
              <div id="graph-typeA" className="chart">
                <Re100ParticipationStatusChart1></Re100ParticipationStatusChart1>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="cont">
              <h3 className="titT1">가입 기업의 업종 형태(산업군)<em className="update">최근 업데이트 {date}</em></h3>
              <div id="graph-typeB" className="chart">
                <Re100ParticipationStatusChart2></Re100ParticipationStatusChart2>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
