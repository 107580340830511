import React, { useState } from 'react'
import DbUserRe100PartCompServiceTab1
  from 'pages/db/DbUserRe100PartCompService/DbUserRe100PartCompServiceTab1'
import DbUserRe100PartCompServiceTab2
  from 'pages/db/DbUserRe100PartCompService/DbUserRe100PartCompServiceTab2'
import { Link } from "react-router-dom";

export const colorsTab2 = ["#B132FF", "#7432FF", "#2F2185", "#3240FF", "#3C91FF", "#48E3FF", "#1EDDC1", "#3EDC6A", "#B1D940", "#F6C463", "#E58A5E", "#DB6F6F"];
export default function DbUserRe100PartCompService() {
  const [tabIndex, setTabIndex] = useState<number>(1);

  return (
    <section className="section re100-dashboard-service-wrap">
      <div className="tab-st-box1 mgb0">
        <ul className="tab-st1">
          <li className={`${tabIndex === 1 ? 'on' : ''}`}><Link to={''} onClick={() => setTabIndex(1)} title="RE100 탭으로 이동"><span>RE100</span></Link></li>
          <li className={`${tabIndex === 2 ? 'on' : ''}`}><Link to={''} onClick={() => setTabIndex(2)} title="온실가스 탭으로 이동"><span>온실가스</span></Link></li>
        </ul>
      </div>
      {tabIndex == 1 ? <DbUserRe100PartCompServiceTab1 /> : <DbUserRe100PartCompServiceTab2 />}
    </section>
  )
}
