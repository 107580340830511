import AuthGuard from "guard/authGuard";
import Layout from "components/layout/Layout";
import { etRouter, otRouter } from "routers/otRouter";
import { cpRouter } from "routers/cpRouter";
import { rpRouter } from "./rpRouter";
import { dbRouter } from "./dbRouter";
import { spRouter } from "./sampleRouter";
import { cbRouter } from "routers/cbRouter";
import { infoRouter } from "routers/infoRouter";
import { mpRouter } from "routers/mpRouter";

export const privateRouter: any = {
  path: "/",
  element: (
    <AuthGuard>
      <Layout />
    </AuthGuard>
  ),
  children: [
    ...dbRouter,
    ...otRouter,
    ...etRouter,
    ...cpRouter,
    ...rpRouter,
    ...spRouter,
    ...cbRouter,
    ...infoRouter,
    ...mpRouter,
  ],
};
