import React, {useEffect} from 'react';

export interface TableUIProps {
  type: string;
  ulClass?: string;
  olClass?: string;
  dataSource: any;
}

export default function TableUI(props: TableUIProps) {
  const [tableUI, setTableUI] = React.useState(props);

  useEffect(() => {
    setTableUI(props);
  }, [props]);

  const column = (item: any, type: string) => {
    if (tableUI.type == "ol") {
      return (
        <li className={item.classStr}></li>
      )
    } else {
      return (
        <li>{item.value}</li>
      )
    }
  };

  const liItem = (item: any, index: any) => {
    return (
      <li><span className="num">{index}</span>
        {item.label}
        <ul className={item.ulClass}>
          {
            item.sourceData.map((item: any, index: any) => {
              return <li>{item.value}</li>
            })
          }
        </ul>
      </li>
    )
  };

  if (tableUI.type == "ul") {
    return (
      <ul className={tableUI.ulClass}>
        {tableUI.dataSource.map((item: any) => column(item, tableUI.type))}
      </ul>
    );
  } else {
    return (
      <ol className={tableUI.olClass}>
        {tableUI.dataSource.map((item: any, index: any) => liItem(item, index))}
      </ol>
    );
  }
}