// export const firebaseConfig = {
//     apiKey: "AIzaSyDVI3VEl4PluXzOUqylYk-IDhwCUe9WtdY",
//     authDomain: "test-project-cefb5.firebaseapp.com",
//     projectId: "test-project-cefb5",
//     storageBucket: "test-project-cefb5.appspot.com",
//     messagingSenderId: "163231538022",
//     appId: "1:163231538022:web:23af401a41618f065446a7",
//     measurementId: "G-L9RKVNKSQ6"
//   };
// export const vapidKey = "BMJfXy8LUA7iIqZY9GoIzQaY2kyd55Hoyn49R3mBH_u50qNjAR1CesjbC7fvHp3wlCsMO-fEYsjNG_N0huPYGHY"

export const firebaseConfig = {
  apiKey: "AIzaSyAVgK0JwXQuQndSmymydyjHhjw6qfVI0Bs",
  authDomain: "a2m-emarket.firebaseapp.com",
  projectId: "a2m-emarket",
  storageBucket: "a2m-emarket.appspot.com",
  messagingSenderId: "903427076160",
  appId: "1:903427076160:web:61fdbdef98913837432fa5",
  measurementId: "G-7NSTD3EXH8"
};

export const vapidKey = "BKJawWgJWogjBbEdAxub0JoPv7vaCg2TTkdy_BcaDVpdQ5Wqj64NF5uGJYewUy3rqAQuxaW-5eiGqnRWhtO2f-E";

