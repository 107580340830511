import {FileModel2} from "../common/fileModel";

export class CustRegRqsModul{

  custRegRqsId?: string;
  emksRegRqsCd?: string; // 고객정보 상태값: C | U | D | F
  emksIndsCmplxClCd?: string;  //단지
  emksCustTpClCd?: string;     //고객유형
  csthsNm?: string;            //고객명
  kepcoCustNo?: string;        //고객번호
  cntrPwr?: string;            //계약전력
  telNo?: any;              //전화번호
  addr?: string;               //고객주소
  repAtflId?: string;          //대표 첨부 파일 ID
  custRegRqsRsrcsRequests?:any;  // 고객자원정보

  // 고객정보 등록/수정용
  telNoFi?: any;
  telNoSe?: string;
  telNoTh?: string;
  dtlsAddrOne?: string
  dtlsAddrTwo?: string
  kepcoCustNo2?: string;

  softRsrcInfos?: any; // 고객자원정보2 (절대로 너무 필요함)

  file?: FileModel2; // 신규 파일 정보
  fileInfos?: any[]; // DB 파일정보
  deletedFiles?: any[]; // 삭제된 파일들

  // MyPage 용 MG 데이터
  mainMetrClNm?: string;
  mrDd?: string;
  cntrKndClNm?: string;
  chrgAplyPwr?: number;
  chrgAplyPwrAplyYmd?: string;
  lhvTpNm?: string;

  newData?: boolean;
  formKey?: any;

  constructor() {
    this.newData = true;
    this.emksRegRqsCd = "C";
  }
}

