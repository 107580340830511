import { lazy } from "react";

// 장외 시장
const TradeStatistics = lazy(() => import("pages/ot/tradeStatistics/TradeStatistics"))
const TradeMarketPriceMain = lazy(() => import("pages/ot/tradeStatistics/TradeMarketPriceMain"))
const TradeBoard = lazy(() => import("pages/ot/tradeBoard/TradeBoard"));

const TradeConfirm = lazy(() => import("pages/ot/tradeConfirm/TradeConfirm"));
const TradeConfirmBoardDetail = lazy(() => import("pages/ot/tradeConfirm/TradeConfirmBoardDetail"));
const TradeConfirmApplyDetail = lazy(() => import("pages/ot/tradeConfirm/TradeConfirmApplyDetail"));

const Bill = lazy(() => import("pages/cb/bill/billList/BillListBase"));
const BillDetail = lazy(() => import("pages/cb/bill/billDetail/BillDetailBase"));
const BillResult = lazy(() => import("pages/cb/bill/billDetail/BillResult"));
const BillForm = lazy(() => import("pages/cb/bill/billForm/BillFormBase"));

const TradeContractForm = lazy(() => import("pages/cb/contractForm/ContractFormBase"));
const TradeContractDetail = lazy(() => import("pages/cb/contractDetail/ContractDetailBase"));

const TradeRpdc = lazy(() => import("pages/ot/tradeRpdc/TradeRpdc"));
const TradeRpdcForm = lazy(() => import("pages/ot/tradeRpdc/TradeRpdcForm"));
const TradeRpdcDetail = lazy(() => import("pages/ot/tradeRpdc/TradeRpdcDetail"))


export const otRouter: any = [
  {path: "/ot/trade/statistics", element: <TradeStatistics />},
  {path: "/ot/trade/marketInfos", element: <TradeMarketPriceMain />},
  {path: "/ot/trade/trades", element: <TradeBoard />, },

  {path: "/ot/trade/confirms", element: <TradeConfirm />, },
  {path: "/ot/trade/confirms/board/detail", element: <TradeConfirmBoardDetail />, },
  {path: "/ot/trade/confirms/apply/detail", element: <TradeConfirmApplyDetail />, },

  {path: "/ot/trade/bills", element: <Bill emksReqdCd={3} />},
  {path: "/ot/trade/bills/detail", element: <BillDetail emksReqdCd={3} />},
  {path: "/ot/trade/bills/detail/result", element: <BillResult />},

  {path: "/ot/trade/contracts/form", element: <TradeContractForm emksCntrCd={3} />},
  {path: "/ot/trade/contracts/detail", element: <TradeContractDetail emksCntrCd={3} />},

  { path: "/ot/trade/rpdcs", element: <TradeRpdc /> },
  { path: "/ot/trade/rpdcs/form", element: <TradeRpdcForm /> },
  { path: "/ot/trade/rpdcs/detail", element: <TradeRpdcDetail /> },
];

// ####### 급전 시장
const StatusDC = lazy(() => import("pages/ot/money/dc/StatusBase"));
const HistoryDC = lazy(() => import("pages/ot/money/dc/HistoryBase"));

const StatusHD = lazy(() => import("pages/ot/money/hd/StatusBase"));
const HistoryHD = lazy(() => import("pages/ot/money/hd/HistoryBase"));

const MoneyRpdc = lazy(() => import("pages/ot/moneyRpdc/MoneyRpdc"));
const MoneyRpdcForm = lazy(() => import("pages/ot/moneyRpdc/MoneyRpdcForm"));
const MoneyRpdcDetail = lazy(() => import("pages/ot/moneyRpdc/MoneyRpdcDetail"));

const MoneyRsrc = lazy(() => import("pages/ot/moneyRsrc/MoneyRsrcBase"));

export const etRouter: any = [
  { path: "/ot/money/status/dc", element: <StatusDC />},
  { path: "/ot/money/history/dc", element: <HistoryDC />},

  { path: "/ot/money/status/hd", element: <StatusHD /> },
  { path: "/ot/money/history/hd", element: <HistoryHD /> },

  {path: "/ot/money/bills/dc", element: <Bill emksReqdCd={2} />},
  {path: "/ot/money/bills/dc/form", element: <BillForm emksReqdCd={2} />},
  {path: "/ot/money/bills/dc/detail", element: <BillDetail emksReqdCd={2} />},

  { path: "/ot/money/rpdcs", element: <MoneyRpdc /> },
  { path: "/ot/money/rpdcs/form", element: <MoneyRpdcForm /> },
  { path: "/ot/money/rpdcs/detail", element: <MoneyRpdcDetail /> },

  {path: "/ot/money/bills/hd", element: <Bill emksReqdCd={4} />},
  {path: "/ot/money/bills/hd/form", element: <BillForm emksReqdCd={4} />},
  {path: "/ot/money/bills/hd/detail", element: <BillDetail emksReqdCd={4} />},

  {path: "/ot/money/rsrc", element: <MoneyRsrc />},
];
