import { ApiUrlUtil } from "utils/apiUrlUtil";
import axios from "axios";
import { HeadersUtil } from "utils/headersUtil";
import Api from "services/common/Api";

export class EmissionService extends Api {
  private static service: EmissionService;
  static readonly deleteRow: any;

  public static getInstance(): EmissionService {
    if (!EmissionService.service) {
      EmissionService.service = new EmissionService();
    }
    return EmissionService.service;
  }

  //배출권전부 전부호출
  public getEmission() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getEmissionLatestByItmGrpNm(itmGrpNm: string) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd/latest", { itmGrpNm: itmGrpNm });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getEmissionOverseaLatestByItmGrpNm(itmGrpNm: string) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd/oversea/latest", { sortColumn: "itmNm", sortAscending: 1 });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  //배출권 이름으로 검색
  public getEmissionItmNm(itmNm: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd" + itmNm);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  //배출권 고유번호 검색
  public getEmissionByIsinCd(isinCd: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd", { isinCd: isinCd });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getEmissionOverseaByIsinCd(isinCd: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd/oversea", { isinCd: isinCd });
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // 해외배출권 고유번호 검색
  public getEmissionOverseaIsinCd(isinCd: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd/oversea?isinCd=" + isinCd);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getEmissionCecicc() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/cecicc");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  //배출권 현황 (년도별 스코프 정보 검색)
  public getEmissiopnStatus(year: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/EmissionsStatus/StatusSelect/" + year);
    return super.get(url);
  }

  // 배출권 ai 의사결정 현황
  public getEmissionAiStatus(isinCd: string) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/emcrd/compare?isinCd=" + isinCd);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getEmissionFacilityStatus(year: any) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/EmissionsStatus/FacilitySelect/" + year);
    return super.get(url);
  }

  public getEmissionOptionName(year: any, optionNm: string) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/EmissionsStatus/OptionSelect/" + year + "/" + optionNm
    );
    return super.get(url);
  }

  public getBoardScope() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/EmissionsStatus/getBoardScope");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getGoalEmissions() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/roadMap/goalEmissions");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getScope(scope: string, startYear: string, endYear: string) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/multiEmissionSource/" + scope + "/" + startYear + "/" + endYear
    );
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getOtTradeReport() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_OT + "/report/dashboard/user");
    return super.get(url);
  }

  public getYearEmissiopnStatus() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/EmissionsStatus/yearSelect");
    return super.get(url);
  }
}
