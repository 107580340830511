import ObjectUtil from "utils/objectUtil";
import moment from "moment";

export interface monthlyScope {
  scopeNm: string;
  mm1: number;
  mm2: number;
  mm3: number;
  mm4: number;
  mm5: number;
  mm6: number;
  mm7: number;
  mm8: number;
  mm9: number;
  mm10: number;
  mm11: number;
  mm12: number;
  total: number;
  chgDate: string;
  [key: string]: string | number;
}

export class DurpcFunction {
  static readonly defaultMonthlyScope: monthlyScope = {
    scopeNm: "", total: 0, chgDate: "",
    mm1: 0, mm2: 0, mm3: 0, mm4: 0,
    mm5: 0, mm6: 0, mm7: 0, mm8: 0,
    mm9: 0, mm10: 0, mm11: 0, mm12: 0,
  };

  static createEmissionObject(data: any) {
    let resultValue: any = {};
    const myList = Array.from(data);
    myList.forEach((data: any) => {
      resultValue[data] = {...this.defaultMonthlyScope, scopeNm: data};
    });
    return resultValue;
  }

  static roundToFourDecimals = (num: any) => {
    return parseFloat(num.toFixed(4));
  };

  static monthlyEmsnByScope(datas: any[]) {
    const defaultArgs = this.createEmissionObject(['scope1', 'scope2', 'scope3']);
    if(datas && datas.length > 0) {
      return datas.reduce((acc: any, data: any) => {
        let { scopId, mm1EmsnQnt, mm2EmsnQnt, mm3EmsnQnt, mm4EmsnQnt,
          mm5EmsnQnt, mm6EmsnQnt, mm7EmsnQnt, mm8EmsnQnt, mm9EmsnQnt,
          mm10EmsnQnt, mm11EmsnQnt, mm12EmsnQnt, chgDate } = data;

        let scope;
        if(["연료사용", "폐기물소각", "공정배출", "scope1"].includes(scopId)) {
          scope = "scope1";
        } else if (["전력 사용시설", "열/스팀 사용시설", "scope2"].includes(scopId)) {
          scope = "scope2";
        } else {
          scope = "scope3";
        }

        const mmEmsnQntSum = mm1EmsnQnt + mm2EmsnQnt + mm3EmsnQnt + mm4EmsnQnt +
                  mm5EmsnQnt + mm6EmsnQnt + mm7EmsnQnt + mm8EmsnQnt + mm9EmsnQnt +
                  mm10EmsnQnt + mm11EmsnQnt + mm12EmsnQnt;
        acc[scope].mm1 = this.roundToFourDecimals(acc[scope].mm1 + mm1EmsnQnt);
        acc[scope].mm2 = this.roundToFourDecimals(acc[scope].mm2 + mm2EmsnQnt);
        acc[scope].mm3 = this.roundToFourDecimals(acc[scope].mm3 + mm3EmsnQnt);
        acc[scope].mm4 = this.roundToFourDecimals(acc[scope].mm4 + mm4EmsnQnt);
        acc[scope].mm5 = this.roundToFourDecimals(acc[scope].mm5 + mm5EmsnQnt);
        acc[scope].mm6 = this.roundToFourDecimals(acc[scope].mm6 + mm6EmsnQnt);
        acc[scope].mm7 = this.roundToFourDecimals(acc[scope].mm7 + mm7EmsnQnt);
        acc[scope].mm8 = this.roundToFourDecimals(acc[scope].mm8 + mm8EmsnQnt);
        acc[scope].mm9 = this.roundToFourDecimals(acc[scope].mm9 + mm9EmsnQnt);
        acc[scope].mm10 = this.roundToFourDecimals(acc[scope].mm10 + mm10EmsnQnt);
        acc[scope].mm11 = this.roundToFourDecimals(acc[scope].mm11 + mm11EmsnQnt);
        acc[scope].mm12 = this.roundToFourDecimals(acc[scope].mm12 + mm12EmsnQnt);
        acc[scope].total = this.roundToFourDecimals(acc[scope].total + mmEmsnQntSum);
        acc[scope].chgDate = moment(acc[scope].chgDate) > moment(chgDate) ? acc[scope].chgDate : chgDate;
        return acc;
      }, { ...defaultArgs });
    }
    return defaultArgs;
  }

  static monthlyEmsnByFclt(datas: any[]) {
    const groupByFcltNm = ObjectUtil.groupBy(datas, i => i.emsnFcltNm);
    let result: any = {};
    Object.entries(groupByFcltNm).forEach(([key, value]: any) => {
      result[key] = this.monthlyEmsnByScope(value);
    });
    return result;
  }

  static getObjectMaxDate(arr: any[]) {
    if(arr.length === 0) return null;

    return arr.reduce((maxObj, obj) => {
      const currentDate = moment(obj.chgDate);
      const maxDate = moment(maxObj.chgDate);
      return currentDate > maxDate ? obj : maxObj;
    }, arr[0]);
  }

}

export { DurpcFunction as durpc };