import React, { Suspense, useEffect, useState } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { addLocale, locale, updateLocaleOptions } from 'primereact/api';
import TuiGrid from 'tui-grid'

import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/lib/toast/tui-grid.css'
import 'assets/css/lib/toast/tui-pagination.css'
import 'assets/css/lib/jquery-ui-timepicker.css'
import 'assets/css/lib/jquery-ui.min.css'
import 'assets/css/lib/jquery-ui.theme.css'
import 'assets/css/lib/jquery.mCustomScrollbar.css'
import 'assets/css/lib/ui.fancytree.css'
import "assets/css/animate.css"
import "assets/css/basic.css"
import "assets/css/con_com.css"
import "assets/css/layout.css"
import "assets/css/sub.css"
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'assets/css/ozreport/ui.dynatree.css';
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css';

import { useAppDispatch, useAppSelector } from "stores/hook";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { AuthConstant } from "constants/authConstant";
import { UserInfoUtil } from "utils/userInfoUtil";
import AuthGuard from "guard/authGuard";
import { publicRouter } from "routers/publicRouter";
import { privateRouter } from "routers/privateRouter";
import { HolidayService } from "services/common/holidayService";
import { ResponseResultCode } from "model/response-api";
import { updateHolidays } from "reducers/holidaySlice";

export const spinner = (
  <div className="progress-spinner text-center">
    <div className="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)

function App() {
  const dispatch = useAppDispatch();
  const userInfo: UserInfoModel = useAppSelector((state) => state.userInfo.userInfo);
  const holidays = useAppSelector((state) => state.holidays.holidays);
  const [dbPath, setDbPath] = useState({ path: "/db", element: <AuthGuard /> });

  useEffect(() => {
    let newDbPath: any;
    if (UserInfoUtil.isAdmin(userInfo)) {
      newDbPath = { path: "/db", element: <Navigate to="/db/re100Status" replace /> };
    } else if (UserInfoUtil.hasRole(userInfo, AuthConstant.R001)) {
      newDbPath = { path: "/db", element: <Navigate to="/db/re100/part-comp-service" replace /> };
    } else if (UserInfoUtil.hasRole(userInfo, AuthConstant.R002)) {
      newDbPath = {path: "/db", element: <Navigate to="/bill/total" replace/>};
    } else if (UserInfoUtil.hasRole(userInfo, AuthConstant.R004)) {
      newDbPath = {path: "/db", element: <Navigate to="/ot/money/status/dc" replace />};
    } else {
      newDbPath = { path: "/db", element: <AuthGuard /> };
    }
    setDbPath(newDbPath);
  }, [userInfo])

  let router = useRoutes([
    { path: "/", element: <Navigate to="/db" replace /> },
    dbPath,
    publicRouter,
    privateRouter
  ]);

  useEffect(() => {
    addLocale('ko', {
      firstDayOfWeek: 0,
      'dayNames': ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
      'dayNamesShort': ['일', '월', '화', '수', '목', '금', '토'],
      'dayNamesMin': ['일', '월', '화', '수', '목', '금', '토'],
      'monthNames': ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
      'monthNamesShort': ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
      'today': '오늘',
      'clear': '초기화'
    });

    updateLocaleOptions({ showMonthAfterYear: true }, 'ko');
    locale('ko');
    TuiGrid.setLanguage('ko');

    if (holidays == null || holidays.length === 0) {
      HolidayService.getInstance().getHolidays().then(response => {
        if (response?.data && ResponseResultCode.Y === response.resultCode) {
          dispatch(updateHolidays(response.data));
        }
      });
    }
  }, []);

  return (
    <div>
      <ToastContainer position="top-right"
        autoClose={3000}
        pauseOnFocusLoss={false}
        hideProgressBar={false}
        closeOnClick
        limit={3} />
      <Suspense fallback={spinner}>{router}</Suspense>
    </div>
  );
}

export default App;