import { useNavigate, useParams } from "react-router-dom";
import PriceAnalysisDomestic from "./PriceAnalysisDomestic";
import PriceAnalysisOversea from "./PriceAnalysisOversea";
import {JsxElementConstant} from "../../../constants/jsxElementConstant";

export interface Emcrd {
  baseYmd: string;
  isinCd: string;
  srtnCd: string;
  itmNm: string;
  clsPrc: string;
  vs: string;
  vsRt: string;
  mktPrc: string;
  hgPrc: string;
  lwPrc: string;
  trdVol: string;
  trdAmt: string;
}

export interface PriceAnalysisProps {
  type?: PriceAnalysisTypes;
  grpTabIndex?: string;
  isinCd?: string;
}

export const priceAnalysisTypes = {
  domestic: "domestic",
  oversea: "oversea",
} as const;

export type PriceAnalysisTypes = (typeof priceAnalysisTypes)[keyof typeof priceAnalysisTypes];

export type PriceAnalysisGrpTab = {
  name: string;
  currency?: string;
};

export interface PriceAnalysisGrpTabs {
  tabs: readonly PriceAnalysisGrpTab[];
}

export const priceAnalysisDomesticGrpTabs: PriceAnalysisGrpTabs = {
  tabs: [{ name: "KAU" }, { name: "KCU" }, { name: "KOC" }, { name: "i-KCU" }, { name: "i-KOC" }],
} as const;

export const priceAnalysisOverseaGrpTabs: PriceAnalysisGrpTabs = {
  tabs: [{ name: "유럽", currency: 'USD' }],
} as const;

export default function PriceAnalysis(props: Readonly<PriceAnalysisProps>) {
  const navigate = useNavigate();
  const { type = priceAnalysisTypes.domestic, grpTabIndex = "1" } = useParams();
  const handleClick = (newType: string) => {
    if (type !== newType) {
      navigate(`/cp/emiss/price/${newType}/1`);
    }
  };
  const getTabComponent = () => {
    if (type === priceAnalysisTypes.oversea) {
      return <PriceAnalysisOversea grpTabIndex={grpTabIndex} />;
    } else {
      return <PriceAnalysisDomestic grpTabIndex={grpTabIndex} />;
    }
  };

  return (
    <div>
      <div className="container">
        <section className="section active">
          <div className="tab-st-box1">
            <ul className="tab-st1">
              <li className={type !== priceAnalysisTypes.oversea ? "on" : ""}>
                <a href={JsxElementConstant.hrefIsNull} onClick={() => handleClick(priceAnalysisTypes.domestic)}>
                  <span>국내 배출권</span>
                </a>
              </li>
              <li className={type === priceAnalysisTypes.oversea ? "on" : ""}>
                <a href={JsxElementConstant.hrefIsNull} onClick={() => handleClick(priceAnalysisTypes.oversea)}>
                  <span>해외 배출권</span>
                </a>
              </li>
            </ul>
          </div>
          {getTabComponent()}
        </section>
      </div>
    </div>
  );
}
