import React, {useCallback, useEffect, useRef, useState} from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Grid from "@toast-ui/react-grid";
import ArcGrid, {GridApi} from "../../../components/arc/ArcGrid";
import ArcButton, {ButtonIcon, ButtonType} from "../../../components/arc/ArcButton";
import ArcSelectbox, {SelectboxSize} from "../../../components/arc/ArcSelectbox";
import ArcDatePicker, {ArcDatePickerResult} from "../../../components/arc/ArcDatePicker";
import moment from "moment";
import {HighchartsOptionsUtil} from "utils/highchartsOptionsUtil";
import ArcSearchPicker from "../../../components/arc/ArcSearchPicker";
import {DbAdminService} from "../../../services/db/DbAdminService";
import {ResponseResultCode} from "../../../model/response-api";

HighchartsMore(Highcharts);

Highcharts.setOptions({
  ...HighchartsOptionsUtil.locale.kr.globalOptions,
  exporting: {enabled: false},
  credits: {enabled: false},
  tooltip: {shared: true, headerFormat: ''},
})

export default function DbAdminRe100ParticipationeEnergyUsed() {

  const gridRef: any = useRef<typeof ArcGrid>();
  const currentYear = new Date().getFullYear();
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const callback = useCallback((HighchartsChart: Highcharts.Chart | null) => {
    setChart(HighchartsChart);
  }, []);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [search, setSearch] = useState<any>({
    searchTarget: "ALL",
    searchType: "DATE",
    fromDt: moment().format('YYYYMMDD') + '000000',
    toDt: moment().format('YYYYMMDD') + '235959',
    groupByUserYn: "Y",
    searchIndsCmplxClCd: ""
  });
  const [powerUsage, setPowerUsage] = useState<any>([]);
  const [generation, setGeneration] = useState<any>([]);

  const [searchOption2, setSearchOption2] = useState([
    {label: "전체", value: ""},
    {label: "고압", value: "고압"},
    {label: "저압", value: "저압"},
  ])

  function selectCpOption(label: any, value: any) {
    setSearch({
      ...search,
      [label]: value.target.value
    });
  }

  function selectOption(name: any, data: any) {
    setSearch({
      ...search,
      [name]: data.target.value
    });
  }

  useEffect(() => {
    getPowerUsage();
    getGeneration();
  }, []);

  async function getPowerUsage() {
    const response = await DbAdminService.getInstance().getPowerUsage({
      searchTarget: "ALL",
      searchType: "YEAR",
      fromDt: currentYear,
      toDt: currentYear,
      groupByUserYn: "Y"
    });

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setPowerUsage(response.data);
      } else {
        setPowerUsage({});
      }
    }
  }

  async function getGeneration() {
    const response = await DbAdminService.getInstance().getGeneration({
      searchTarget: "ALL",
      searchType: "YEAR",
      fromDt: currentYear,
      toDt: currentYear,
      groupByUserYn: "Y"
    });

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setGeneration(response.data);
      } else {
        setGeneration({});
      }
    }
  }

  const addComma = (value: any) => {
    return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

// 조정전력배출계수: 나중에 바꿔야 함
  const adjPwrEmsnCoef = 0.4747;
  const adjPwrEmsnCoefSolar = 0.4403;

  useEffect(() => {
    gridRef?.current?.getInstance().setColumns([
      {
        header: '기업명',
        name: 'bizrNm',
        align: "center",
        whiteSpace: 'normal',
        formatter: function (e: any) {
          return e.value
        },
      },
      {
        header: '단지',
        name: 'emksIndsCmplxClCd',
        align: "center",
        whiteSpace: 'normal',
        formatter: function (e: any) {
          return e.value
        },
      },
      {
        header: "한전 전력량(kWh)",
        name: "useQnt",
        align: "right",
        whiteSpace: "normal",
        formatter: function (e: any) {
          return addComma(Math.floor(e.value));
        },
      },
      {
        header: "발전량(kWh)",
        name: "genQnt",
        align: "right",
        whiteSpace: "normal",
        formatter: function (e: any) {
          return addComma(Math.floor(e.value));
        },
      },
      {
        header: "온실가스 배출량(tCO₂)",
        name: "value6",
        align: "right",
        whiteSpace: "normal",
        formatter: function (e: any) {
          return Number(e.row.useQnt * 0.001 * adjPwrEmsnCoef).toFixed(2);
        },
      },
      {
        header: 'RE(%)',
        name: 're100Qnt',
        align: "right",
        width: 80,
        whiteSpace: 'normal',
        formatter: function (e: any) {
          return Math.floor(e.value);
        },
      }
    ]);
  }, []);

  const options = {
    chart: {
      type: "column",
      height: 277,
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    exporting: {enabled: false},
    credits: {enabled: false},
    plotOptions: {
      column: {
        grouping: false,
      },
      series: {
        pointWidth: 20,
        borderWidth: 0,
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        // borderRadius: 25,
      },
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'horizontal',
      borderRadius: 20,
      symbolHeight: 10,
      x: -7,
      padding: 10,
      backgroundColor: '#F1F2F7',
      itemStyle: {
        fontSize: '0.7rem',
        color: '#444444',
      },
    },
    xAxis: {
      categories: powerUsage.map((item: any) => item.bizrNm),
      min: 0,
      lineColor: "#E1E1E1",
      title: "",
      labels: {
        style: {
          color: "#444",
          fontSize: "15px",
        },
      },
    },
    yAxis:
        {
          title: {
            text: "(kWh)",
            align: 'high',
            rotation: 0,
            offset: 0,
            y: -25,
            style: {
              color: '#444',
              fontSize: '0.65rem',
            },
          },
          min: 0,
          softMax: 10,
          lineColor: '#E1E1E1',
          labels: {
            x: -5,
            y: 4,
            style: {
              color: '#444',
              fontSize: '0.7rem',
            },
          },
        },
    series: [
      {
        name: "한전 전력",
        data: powerUsage.map((item: any) => item.pwrUsage),
        color: "#4C96F9",
        pointPadding: 0.3,
        groupPadding: 0,
        pointPlacement: 0.08,
        zIndex: 2,
      },
      {
        name: "발전량",
        data: generation.map((item: any) => item.pwrGeneration),
        color: "#43C9D1",
        pointPadding: 0.3,
        groupPadding: 0,
        pointPlacement: -0.08,
        zIndex: 1
      }
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            chart: {
              spacingTop: 20,
            },
            xAxis: {
              labels: {
                style: {
                  fontSize: '0.5rem',
                },
              },
            },
            yAxis: [
              {
                title: {
                  y: -10,
                  style: {
                    fontSize: '0.5rem',
                  },
                },
                labels: {
                  style: {
                    fontSize: '0.5rem',
                  },
                },
              },
            ],
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
              itemStyle: {
                fontSize: '0.6rem',
              },
            },
            tooltip: {
              style: {
                fontSize: '0.6rem',
              }
            },
            plotOptions: {
              line: {
                lineWidth: 1,
                marker: {
                  lineWidth: 1,
                  width: 2,
                  height: 2,
                  radius: 2
                },
              },
            },
          },
        },
      ],
    },
  };


  function handleChangeDateInput(cates: any[], result?: ArcDatePickerResult) {
    if (result) {
      if (result.fromDateString && result.toDateString) {
        setSearch({
          ...search,
          fromDt: result.fromDateString,
          toDt: result.toDateString,
        });
      }
    }
  }

  function handleChangeSelectInput(result?: String) {
    if (result) {

      setSearch({
        ...search,
        searchType: result
      });
    }
  }

  function handleSearch() {
    DbAdminService.getInstance().getPowerUsage(
        {
          searchIndsCmplxClCd: search.searchIndsCmplxClCd,
          searchLhvTpNm: search.searchLhvTpNm,
          fromDt: search.fromDt,
          toDt: search.toDt,
          searchType: search.searchType,
          searchTarget: search.searchIndsCmplxClCd !== '' ? "COMPLEX" : "ALL",
          groupByUserYn: "Y"
        }
    ).then(resp => {

      if (resp) {
        setPowerUsage(resp.data);
      }
    });

    DbAdminService.getInstance().getGeneration(
        {
          searchIndsCmplxClCd: search.searchIndsCmplxClCd,
          searchLhvTpNm: search.searchLhvTpNm,
          fromDt: search.fromDt,
          toDt: search.toDt,
          searchType: search.searchType,
          searchTarget: search.searchIndsCmplxClCd !== '' ? "COMPLEX" : "ALL",
          groupByUserYn: "Y"
        }
    ).then(resp => {

      if (resp) {
        setGeneration(resp.data);
      }
    });
  }

  return (
      <>
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="srh-filter">
                <ul>
                  <li>
                    <div className="ac-src-frm-wrap w100 ac">
                      <ArcSelectbox
                          className="mgr10"
                          name="searchIndsCmplxClCd"
                          label="산업단지"
                          onChange={data => selectCpOption("searchIndsCmplxClCd", data)}
                          options={[
                            {label: "전체", value: ''},
                            {label: "1단지", value: 'INDS001'},
                            {label: "2단지, 3단지", value: 'INDS002'},
                            {label: "4단지", value: 'INDS004'},
                            {label: "5단지", value: 'INDS005'},
                          ]}
                          size={SelectboxSize.px150}
                      ></ArcSelectbox>
                      <ArcSelectbox
                          className="mgr10"
                          name="searchLhvTpNm"
                          label="전압구분"
                          onChange={data => selectOption("searchLhvTpNm", data)}
                          options={searchOption2}
                          size={SelectboxSize.px150}
                      ></ArcSelectbox>

                      {/* <span className="srh-tit">기간설정</span> */}
                      <div className="calendar-picker mgr10">
                        <ArcSearchPicker onChange={(category, result) => {
                          handleChangeDateInput(category, result)

                        }} onChangeSelect={selectedOption => {
                          handleChangeSelectInput(selectedOption)
                        }}
                                         fromDate={search.fromDt}
                                         toDate={search.toDt}
                                         label="검색기간"/>
                      </div>

                      <div className="InpSel disIb">
                        <ArcButton
                            type={ButtonType.search}
                            hasIcon={true}
                            onClick={handleSearch}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">전력 사용량 현황</h3>
              <div id="graph-typeA" className="chart">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    callback={callback}
                    ref={chartRef}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="tit-btns-wrap">
                <h3 className="titT1">전력 사용량 목록</h3>
              </div>
              <ArcGrid gridRef={gridRef}
                  // data={data}
                       datasource={{
                         readData: {
                           url: '/co/company?emksRe100JoinClCd=0',
                           method: 'GET',
                         }
                       }}
                       rowHeaders={[]}
                       columns={[]}
                       useGridInfoHeader={true}
                       isTest={true}
              />
            </div>
          </div>
        </div>
      </>
  );
}
