import { JsxElementConstant } from "constants/jsxElementConstant";
import NationalFeverQnt from "pages/cp/inventory/reference/NationalFeverQnt";
import SupplyEnergy from "pages/cp/inventory/reference/SupplyEnergy";
import WasteEmissionFactor from "pages/cp/inventory/reference/WasteEmissionFactor";
import { useNavigate, useParams } from "react-router-dom";

export const referenceTypes = {
  nationalFeverQnt: "nationalFeverQnt",
  supplyEnergy: "supplyEnergy",
  wasteEmissionFactor: "wasteEmissionFactor",
} as const;

export type ReferenceTypes = typeof referenceTypes[keyof typeof referenceTypes];

export default function Board() {
  const navigate = useNavigate();
  const { type = referenceTypes.nationalFeverQnt } = useParams();
  const handleClick = (tab: string) => {
    if (type !== tab) {
      navigate(`/cp/inventory/reference/${tab}`);
    }
  };

  const tabComponent = () => {
    switch (type) {
      case referenceTypes.nationalFeverQnt:
        return <NationalFeverQnt />;
      case referenceTypes.supplyEnergy:
        return <SupplyEnergy />;
      case referenceTypes.wasteEmissionFactor:
        return <WasteEmissionFactor />;
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <section className="section active">
        <div className="tab-st-box1">
          <ul className="tab-st1">
            <li className={type === referenceTypes.nationalFeverQnt ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => handleClick(referenceTypes.nationalFeverQnt)}>
                <span>연료별 국가 고유 발열량</span>
              </a>
            </li>
            <li className={type === referenceTypes.supplyEnergy ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => { handleClick(referenceTypes.supplyEnergy) }}>
                <span>외부에서 공급받는 에너지</span>
              </a>
            </li>
            <li className={type === referenceTypes.wasteEmissionFactor ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => { handleClick(referenceTypes.wasteEmissionFactor) }}>
                <span>폐기물 소각 배출계수</span>
              </a>
            </li>
          </ul>
        </div>
        {tabComponent()}
      </section>
    </div>
  );
}
