import {createSlice} from "@reduxjs/toolkit";
import {UserMemberModel} from "model/ua/UserMemberModel";

const initialState: any = {
  signUpInfo: UserMemberModel,
  signUpComCd: {
    ComUnit: [],
    EmksCustTpClCd: [],
    EmksEntClCd: [],
    EmksEqpKndCd: [],
    EmksIndsCmplxClCd: [],
    EmksMbrsClCd: [],
    EmksPrtclKndCd: [],
    EmksRe100JoinClCd: [],
    EmksRsrcChgClCd: [],
    MobileNumberPrefix: []
  }
}

const SignUpSlice = createSlice({
  name: 'signUpSlice',
  initialState,
  reducers: {
    setSignUpInfo: (state, action) => {
      state.signUpInfo = action.payload;
    },
    setSignUpComCd: (state, action) => {
      state.signUpComCd = action.payload;
    },
    clearSignUpInfo: (state) => {
      state.signUpInfo = null;
    },
  },
});

export default SignUpSlice.reducer;
export const {setSignUpInfo, clearSignUpInfo , setSignUpComCd} = SignUpSlice.actions;