import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import ArcDatePicker from "components/arc/ArcDatePicker";
import ArcButton, { ButtonColor, ButtonIcon, ButtonType } from "components/arc/ArcButton";
import { useEffect, useState } from "react";
import { EmissionService } from "../../../services/cp/EmissionService";
import Board from "./Board";
import { HighchartsOptionsUtil } from "../../../utils/highchartsOptionsUtil";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";
import { toast } from "react-toastify";

export default function FacilityEmissions() {

  const today: Date = new Date();

  //Search
  const [scopeEmisYear, setScopeEmisYear] = useState(today.getFullYear());
  const [checkEmisYear, setCheckEmisYear] = useState(today.getFullYear());
  const [timeScope, setTimeScope] = useState(today);
  const [timeCheck, setTimeCheck] = useState(today);
  const [year, setYear] = useState(today.getFullYear());

  //Data highchart
  const [emisRateValue, setEmisRateValue] = useState<any>();

  const [scopeEmisValue, setScopeEmisValue] = useState<any>();

  const [checkEmisValue, setCheckEmisValue] = useState<any>();

  // Highchart option
  const [emisRateOption, setEmisRateOption] = useState<any>();
  const [scopeEmisOption, setScopeEmisOption] = useState<any>();
  const [checkEmisOption, setCheckEmisOption] = useState<any>();

  const [chartNmList, setChartNmList] = useState<any>();

  const [chartTotal, setChartTotal] = useState<any>();
  const [chartDateTotal, setChartDateTotal] = useState<any>();


  function getSubtitle() {
    return `<span class="donut-tit" style="color:#444;font-size:1.2rem;font-weight:700;">총 탄소배출량</span>
        <br>
        <span class="donut-stit" style="display:block;text-align:center;color:#666;font-size:.8rem;font-weight:400;"><strong style="margin-right:.25rem;color:#444;font-size:1.5rem;font-weight:700;">${chartTotal ? Number(chartTotal).toLocaleString("en-US") : "0"
      }</br></strong>tCO2eq</span>`;
  }

  const [
    isDetail, setIsDetail] = useState(false);

  const clickBackBtn = () => {
    setIsDetail(true);
  };

  const roundToFourDecimals = (num: any) => {
    return parseFloat(num.toFixed(2));
  };

  useEffect(() => {
    EmissionService.getInstance()
      .getEmissionFacilityStatus(today.getFullYear().toString())
      .then(function (res: any) {
        var arr;
        if (res.data) {
          arr = res.data.reduce(() => {
            let a = [];
            for (let i = 0; i < res.data.length; i++) {
              a.push(res.data[i].emsnFcltNm);
            }
            return new Set(a);
          }, []);
        } else {
          arr = null;
        }

        function createObjectKV(data: any) {
          let resultValue: any = {};
          const myList = Array.from(data);
          myList.forEach((data: any) => {
            resultValue[data] = {
              scopeNm: data,
              mm1EmsnQnt: 0,
              mm2EmsnQnt: 0,
              mm3EmsnQnt: 0,
              mm4EmsnQnt: 0,
              mm5EmsnQnt: 0,
              mm6EmsnQnt: 0,
              mm7EmsnQnt: 0,
              mm8EmsnQnt: 0,
              mm9EmsnQnt: 0,
              mm10EmsnQnt: 0,
              mm11EmsnQnt: 0,
              mm12EmsnQnt: 0,
              total: 0,
            };
          });
          return resultValue;
        }

        const resultMap = res.data.reduce((acc: any, item: any) => {
          let {
            mm1EmsnQnt,
            mm2EmsnQnt,
            mm3EmsnQnt,
            mm4EmsnQnt,
            mm5EmsnQnt,
            mm6EmsnQnt,
            mm7EmsnQnt,
            mm8EmsnQnt,
            mm9EmsnQnt,
            mm10EmsnQnt,
            mm11EmsnQnt,
            mm12EmsnQnt,
            emsnFcltNm,
          } = item;

          acc[emsnFcltNm].mm1EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm1EmsnQnt + mm1EmsnQnt);
          acc[emsnFcltNm].mm2EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm2EmsnQnt + mm2EmsnQnt);
          acc[emsnFcltNm].mm3EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm3EmsnQnt + mm3EmsnQnt);
          acc[emsnFcltNm].mm4EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm4EmsnQnt + mm4EmsnQnt);
          acc[emsnFcltNm].mm5EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm5EmsnQnt + mm5EmsnQnt);
          acc[emsnFcltNm].mm6EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm6EmsnQnt + mm6EmsnQnt);
          acc[emsnFcltNm].mm7EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm7EmsnQnt + mm7EmsnQnt);
          acc[emsnFcltNm].mm8EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm8EmsnQnt + mm8EmsnQnt);
          acc[emsnFcltNm].mm9EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm9EmsnQnt + mm9EmsnQnt);
          acc[emsnFcltNm].mm10EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm10EmsnQnt + mm10EmsnQnt);
          acc[emsnFcltNm].mm11EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm11EmsnQnt + mm11EmsnQnt);
          acc[emsnFcltNm].mm12EmsnQnt = roundToFourDecimals(acc[emsnFcltNm].mm12EmsnQnt + mm12EmsnQnt);
          acc[emsnFcltNm].total = roundToFourDecimals(acc[emsnFcltNm].total +
            mm1EmsnQnt +
            mm2EmsnQnt +
            mm3EmsnQnt +
            mm4EmsnQnt +
            mm5EmsnQnt +
            mm6EmsnQnt +
            mm7EmsnQnt +
            mm8EmsnQnt +
            mm9EmsnQnt +
            mm10EmsnQnt +
            mm11EmsnQnt +
            mm12EmsnQnt);
          return acc;
        }, createObjectKV(arr));
        let itemSum: any = 0;
        Object.values(resultMap).forEach((item: any) => {
          itemSum += item.total;
        });
        const transformedResult = Object.values(resultMap).map((item: any) => ({
          name: item.scopeNm,
          y: Number(item.total.toFixed(2)),
        })).sort((a: any, b: any) => b.y - a.y);
        setChartTotal(itemSum.toFixed(2));
        setEmisRateValue(transformedResult);
      })
      .catch((error) => {
        toast.error("common.message.error.system");
      });
  }, []);

  //두번째 차트
  useEffect(() => {
    EmissionService.getInstance()
      .getEmissionFacilityStatus(scopeEmisYear)
      .then(function (res: any) {
        function createObjectKV2(data: any) {
          let resultValue: any = {};
          const myList = Array.from(data);
          myList.forEach((data: any) => {
            resultValue[data] = {
              scope1: {
                scopeNm: data,
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
                total: 0,
              },
              scope2: {
                scopeNm: data,
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
                total: 0,
              },
              scope3: {
                scopeNm: data,
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
                total: 0,
              },
            };
          });
          return resultValue;
        }

        let arr: any = [];
        if (res.data) {
          arr = res.data.reduce(() => {
            let a = [];
            for (let i = 0; i < res.data.length; i++) {
              a.push(res.data[i].emsnFcltNm);
            }
            return new Set(a);
          }, []);
        } else {
          arr = null;
        }

        const resultMap = res.data.reduce((acc: any, item: any) => {
          let {
            scopId,
            mm1EmsnQnt,
            mm2EmsnQnt,
            mm3EmsnQnt,
            mm4EmsnQnt,
            mm5EmsnQnt,
            mm6EmsnQnt,
            mm7EmsnQnt,
            mm8EmsnQnt,
            mm9EmsnQnt,
            mm10EmsnQnt,
            mm11EmsnQnt,
            mm12EmsnQnt,
            emsnFcltNm,
          } = item;

          if (scopId === "연료사용" || scopId === "폐기물소각" || scopId === "공정배출") {
            scopId = "scope1";
          } else if (scopId === "전력 사용시설" || scopId === "열/스팀 사용시설") {
            scopId = "scope2";
          } else {
            scopId = "scope3";
          }

          acc[emsnFcltNm][scopId].mm1EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm1EmsnQnt + mm1EmsnQnt);
          acc[emsnFcltNm][scopId].mm2EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm2EmsnQnt + mm2EmsnQnt);
          acc[emsnFcltNm][scopId].mm3EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm3EmsnQnt + mm3EmsnQnt);
          acc[emsnFcltNm][scopId].mm4EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm4EmsnQnt + mm4EmsnQnt);
          acc[emsnFcltNm][scopId].mm5EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm5EmsnQnt + mm5EmsnQnt);
          acc[emsnFcltNm][scopId].mm6EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm6EmsnQnt + mm6EmsnQnt);
          acc[emsnFcltNm][scopId].mm7EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm7EmsnQnt + mm7EmsnQnt);
          acc[emsnFcltNm][scopId].mm8EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm8EmsnQnt + mm8EmsnQnt);
          acc[emsnFcltNm][scopId].mm9EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm9EmsnQnt + mm9EmsnQnt);
          acc[emsnFcltNm][scopId].mm10EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm10EmsnQnt + mm10EmsnQnt);
          acc[emsnFcltNm][scopId].mm11EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm11EmsnQnt + mm11EmsnQnt);
          acc[emsnFcltNm][scopId].mm12EmsnQnt = roundToFourDecimals(acc[emsnFcltNm][scopId].mm12EmsnQnt + mm12EmsnQnt);
          acc[emsnFcltNm][scopId].total = roundToFourDecimals(acc[emsnFcltNm][scopId].total +
            mm1EmsnQnt +
            mm2EmsnQnt +
            mm3EmsnQnt +
            mm4EmsnQnt +
            mm5EmsnQnt +
            mm6EmsnQnt +
            mm7EmsnQnt +
            mm8EmsnQnt +
            mm9EmsnQnt +
            mm10EmsnQnt +
            mm11EmsnQnt +
            mm12EmsnQnt);
          return acc;
        }, createObjectKV2(arr));
        const transformedResult = Object.values(resultMap).map((item: any) => ({
          name: item.scope1.scopeNm,
          Scope1: item.scope1.total,
          Scope2: item.scope2.total,
          Scope3: item.scope3.total,
        })).sort((a: any, b: any) => (b.Scope1 + b.Scope2 + b.Scope3) - (a.Scope1 + a.Scope2 + a.Scope3));
        setChartNmList(transformedResult.map((item: any) => item.name));
        const scopeData = ["Scope2", "Scope1"].map((scopeName) => ({
          name: scopeName,
          data: transformedResult.map((result: any) => result[scopeName]),
        }));
        setScopeEmisValue(scopeData);
      })
      .catch((error) => {
        toast.error("common.message.error.system");
      });
  }, [timeScope]);

  //세번째 차트
  useEffect(() => {
    EmissionService.getInstance()
      .getEmissionFacilityStatus(checkEmisYear)
      .then(function (res: any) {
        var arr;
        if (res.data) {
          arr = res.data.reduce(() => {
            let a = [];
            for (let i = 0; i < res.data.length; i++) {
              a.push(res.data[i].emsnFcltNm);
            }
            return new Set(a);
          }, []);
        } else {
          arr = null;
        }

        function createObjectKV(data: any) {
          let resultValue: any = {};
          const myList = Array.from(data);
          myList.forEach((data: any) => {
            resultValue[data ? data : ""] = {
              scopeNm: data ? data : "",
              mm1EmsnQnt: 0,
              mm2EmsnQnt: 0,
              mm3EmsnQnt: 0,
              mm4EmsnQnt: 0,
              mm5EmsnQnt: 0,
              mm6EmsnQnt: 0,
              mm7EmsnQnt: 0,
              mm8EmsnQnt: 0,
              mm9EmsnQnt: 0,
              mm10EmsnQnt: 0,
              mm11EmsnQnt: 0,
              mm12EmsnQnt: 0,
              total: 0,
            };
          });
          return resultValue;
        }

        const resultMap = res.data.reduce((acc: any, item: any) => {
          let {
            mm1EmsnQnt,
            mm2EmsnQnt,
            mm3EmsnQnt,
            mm4EmsnQnt,
            mm5EmsnQnt,
            mm6EmsnQnt,
            mm7EmsnQnt,
            mm8EmsnQnt,
            mm9EmsnQnt,
            mm10EmsnQnt,
            mm11EmsnQnt,
            mm12EmsnQnt,
            emsnFcltNm,
          } = item;

          acc[emsnFcltNm ? emsnFcltNm : ""].mm1EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm1EmsnQnt + mm1EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm2EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm2EmsnQnt + mm2EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm3EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm3EmsnQnt + mm3EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm4EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm4EmsnQnt + mm4EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm5EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm5EmsnQnt + mm5EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm6EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm6EmsnQnt + mm6EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm7EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm7EmsnQnt + mm7EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm8EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm8EmsnQnt + mm8EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm9EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm9EmsnQnt + mm9EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm10EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm10EmsnQnt + mm10EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm11EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm11EmsnQnt + mm11EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].mm12EmsnQnt = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].mm12EmsnQnt + mm12EmsnQnt);
          acc[emsnFcltNm ? emsnFcltNm : ""].total = roundToFourDecimals(acc[emsnFcltNm ? emsnFcltNm : ""].total +
            mm1EmsnQnt +
            mm2EmsnQnt +
            mm3EmsnQnt +
            mm4EmsnQnt +
            mm5EmsnQnt +
            mm6EmsnQnt +
            mm7EmsnQnt +
            mm8EmsnQnt +
            mm9EmsnQnt +
            mm10EmsnQnt +
            mm11EmsnQnt +
            mm12EmsnQnt);
          return acc;
        }, createObjectKV(arr));

        const transformedResult = Object.values(resultMap).map((item: any) => ({
          name: item.scopeNm,
          data: [
            item.mm1EmsnQnt,
            item.mm2EmsnQnt,
            item.mm3EmsnQnt,
            item.mm4EmsnQnt,
            item.mm5EmsnQnt,
            item.mm6EmsnQnt,
            item.mm7EmsnQnt,
            item.mm8EmsnQnt,
            item.mm9EmsnQnt,
            item.mm10EmsnQnt,
            item.mm11EmsnQnt,
            item.mm12EmsnQnt,
          ],
        })).sort((a: any, b: any) => b.data.reduce((sum: number, i: number) => sum + i, 0) - a.data.reduce((sum: number, i: number) => sum + i, 0));
        let itemSum: any = 0;
        Object.values(resultMap).forEach((item: any) => {
          itemSum += item.total;
        });
        setChartDateTotal(itemSum.toFixed(2));
        setCheckEmisValue(transformedResult);
      }).catch((error) => {
        toast.error("common.message.error.system");
      });
  }, [timeCheck]);

  useEffect(() => {
    const drawEmisRateOption: any = {
      chart: {
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 300,
        spacingBottom: 0,
      },
      tooltip: {
        useHTML: true,
        formatter: function (this: any) {
          return `<div>
            <span style="color: ${this.point.color}">● </span>
            <span style="font-size: 14px;">${this.point.name}: </span>
            <span style="font-size: 14px; font-weight: 700;">${this.point.y.toLocaleString()}</span>
            <span style="font-size: 12px; color: #555;">tCO2eq</span>
          </div>`;
        }
      },
      title: { text: '' },
      subtitle: {
        useHTML: true,
        text: getSubtitle(),
        verticalAlign: "middle",
        floating: true,
        x: -150,
      },
      legend: {
        enabled: true,
        verticalAlign: "middle",
        align: "right",
        symbolHeight: 8,
        borderWidth: 0,
        itemMarginTop: 12,
        useHTML: true,
        labelFormatter: function (this: any) {
          return (
            `<div class="legend-srl" style="width:260px;">
              <span style="color: #555; font-size: 0.7rem;">${this.name}
                <strong>
                  <em class="pie-cont" style="color: ${this.color}; font-size: 0.7rem;">
                    ${(this.y ? (Number(this.y.toFixed(2))).toLocaleString() : "0")}
                  </em>
                  <span style="color: #555; font-size: 0.6rem;"> tCO2eq</span>
                </strong>
              </span>
            </div>`
          );
        },
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          showInLegend: true,
          borderWidth: 0,
          borderRadius: 0,
          colorByPoint: true,
          type: "pie",
          size: "100%",
          innerSize: "65%",
          dataLabels: {
            enabled: true,
            distance: "-1%",
            allowOverlap: false,
            useHTML: true,
            formatter: function (this: any) {
              return `<div class="pie-cont-bg"><strong class="pie-cont">${Math.round(this.point.percentage)} %</strong></div>`;
            }
          },
        },
      },
      colors: HighchartsOptionsUtil.colors,
      series: [
        {
          name: "시설별 배출량 비율",
          type: "pie",
          data: emisRateValue,
        },
      ],
    };

    setEmisRateOption(drawEmisRateOption);
  }, [emisRateValue]);

  useEffect(() => {
    const drawScopeEmisOption: any = {
      colors: ["#24ACC5", "#2970CD"],
      chart: {
        type: "column",
        height: 290,
        spacingTop: 10,
        spacingBottom: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: {
        text: "",
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          stacking: 'normal',
          pointWidth: 60,
          borderWidth: 0,
          borderRadius: 0,
          dataLabels: {
            inside: true,
            enabled: true,
            shadow: true,
            style: {
              color: '#fff',
              textOutline: 'none',
              fontSize: '10px'
            },
            formatter: function (this: any) {
              return (
                `<span><strong>${this.y ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`
              );
            },
          },
        },
      },
      tooltip: {
        shared: true,
        split: false,
        headerFormat: '{point.key}<br/>',
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        reversed: true,
        enabled: true,
        symbolHeight: 10,
        symbolRadius: 0,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: chartNmList,
        crosshair: true,
        lineColor: "#E1E1E1",
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        showLastLabel: true,
        offset: 0,
        min: 0,
        softMax: 10,
        lineColor: "#E1E1E1",
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      series: scopeEmisValue,
    };

    setScopeEmisOption(drawScopeEmisOption);
  }, [scopeEmisValue]);

  useEffect(() => {
    const drawCheckEmisOption: any = {
      colors: HighchartsOptionsUtil.colors,
      chart: {
        type: "column",
        height: 400,
        spacingTop: 0,
        spacingBottom: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: {
        text: "",
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          pointWidth: 90,
          borderWidth: 0,
          borderRadius: 0,
          stacking: 'normal',
          dataLabels: {
            inside: true,
            enabled: true,
            shadow: true,
            style: {
              color: '#fff',
              textOutline: 'none'
            },
            formatter: function (this: any) {
              return (
                `<span><strong>${this.y ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`
              );
            },
          },
        },
      },
      tooltip: {
        headerFormat: "{point.key}<br/>",
        split: false,
        style: {
          fontSize: '14px'
        },
        formatter: function (this: any) {
          let tooltip = `${this.x}`;
          [...this.points].reverse().forEach((point: any) => {
            tooltip += `<br/><span style="color: ${point.color};">\u25CF </span>`
              + `${point.series.name}: <b>${parseNumberWithCommaDecimal(point.y.toFixed(2))}</b>`;
          });
          return tooltip;
        },
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        symbolHeight: 10,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: HighchartsOptionsUtil.locale.kr.categoryType.month,
        crosshair: true,
        lineColor: "#E1E1E1",
        title: { text: '' },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        showLastLabel: true,
        reversedStacks: false,
        offset: 0,
        min: 0,
        lineColor: "#E1E1E1",
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      series: checkEmisValue,
    };

    setCheckEmisOption(drawCheckEmisOption);
  }, [checkEmisValue]);

  function onchangeScopeEmisYear(event: any) {
    setScopeEmisYear(event.fromDateString.substring(0, 4));
  }

  function onchangeCheckEmisYear(event: any) {
    setCheckEmisYear(event.fromDateString.substring(0, 4));
  }

  function handleScopeEmisSearch() {
    setTimeScope(today);
  }

  function handleCheckEmisSearch() {
    setYear(checkEmisYear);
    setTimeCheck(today);
  }

  return (
    <div>
      {!isDetail ? (
        <>
          <div style={{ display: "block", float: "right" }}>
            <ArcButton
              type={ButtonType.custom}
              text={"뒤로가기"}
              hasIcon={true}
              color={ButtonColor.btn1}
              icon={ButtonIcon.reset}
              onClick={clickBackBtn}
            />
          </div>
          <br />
          <br />
          <div className="col-wrap">
            <div className="col-md-6">
              <div className="cont">
                <h3 className="titT1">시설별 배출량 비율</h3>
                <div id="graph-typeA" className="chart">
                  <HighchartsReact highcharts={Highcharts} options={emisRateOption} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="cont">
                <div className="titFlex">
                  <h3 className="titT1">시설별 Scope구분 배출량</h3>
                  <div className="btns mg0">
                    <div className="calendar-input disIb">
                      <ArcDatePicker
                        onChange={onchangeScopeEmisYear}
                        id={"조회년도"}
                        viewType={"year"}
                        name={"yearOne"}
                        fromDate={String(scopeEmisYear)}
                        placeholders={{ fromDate: today.toString() }}
                      />
                    </div>
                    <ArcButton onClick={handleScopeEmisSearch} type={"search"} hasIcon={true} />
                  </div>
                </div>
                <div id="graph-typeB" className="chart">
                  <HighchartsReact highcharts={Highcharts}
                    options={scopeEmisOption}></HighchartsReact>
                </div>
              </div>
            </div>
          </div>

          <div className="col-wrap">
            <div className="col-md-12">
              <div className="cont">
                <div className="tit-btns-wrap mgb10">
                  <h3 className="titT1">시설별 날짜구분 배출량 조회</h3>
                  <div className="btns">
                    <div className="calendar-input disIb">
                      <ArcDatePicker
                        onChange={onchangeCheckEmisYear}
                        id={"조회년도"}
                        viewType={"year"}
                        name={"yearOne"}
                        fromDate={String(checkEmisYear)}
                        placeholders={{ fromDate: today.toString() }}
                      />
                    </div>
                    <ArcButton onClick={handleCheckEmisSearch} type={"search"} hasIcon={true} />
                  </div>
                </div>
                <div className="box-graph5 no-scroll pdb20">
                  <ul>
                    <li>
                      <div className="scope" style={{ width: "200px" }}>
                        <span>총 배출량</span>
                        <div className="cnt">
                          <strong>
                            <em data-count="320" className="change">
                              {chartDateTotal ? Number(chartDateTotal).toLocaleString("en-US") : "0"}
                            </em>
                            tCO2eq
                          </strong>
                          <span>{year}년</span>
                        </div>
                      </div>
                      <div id="graph-typeC" className="chart">
                        <HighchartsReact highcharts={Highcharts}
                          options={checkEmisOption}></HighchartsReact>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>) : (
        <Board />
      )}
    </div>
  );
}
