import Scope2Grid from "./Scope2Grid";
import ArcCheckbox from "components/arc/ArcCheckbox";
import { BoardScopeProps } from "./ActiveDataDetail";
import { useEffect, useState } from "react";

export default function Scope2(props: Readonly<BoardScopeProps>) {

  const [scop2Data, setScop2Data] = useState({
    scop2Elec: props.data.scop2Elec || {},
    scop2Water: props.data.scop2Water || {},
  });

  useEffect(() => {
    props.onData(scop2Data);
  }, [scop2Data]);

  const handleDataFromChild1 = (data: any) => {
    setScop2Data(prevData => ({
      ...prevData,
      scop2Elec: data,
    }));
  };

  const handleDataFromChild2 = (data: any) => {
    setScop2Data(prevData => ({
      ...prevData,
      scop2Water: data,
    }));
  };

  const handleIsGroup = (isChecked: any) => {
  }

  return (
    <>
      <div className="tit-check-btns">
        <h4 className="titT3">구분1. 전력 사용시설</h4>
        <span className="checkbox-radio-type02">
          <label htmlFor="checkbox1-2">
            <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
              checked={props?.scopUse === "N" ? true : false} isAlt={true} disabled></ArcCheckbox>
            입력사항 없음
          </label>
        </span>
      </div>
      <Scope2Grid fuflYy={props.fuflYy} scopId={6} scopUse={props.scopUse} onData={handleDataFromChild1} data={props.data.scop2Elec}> </Scope2Grid>
      <div className="tit-check-btns mgt40">
        <h4 className="titT3">구분2. 열/스팀 사용시설</h4>
        <span className="checkbox-radio-type02">
          <label htmlFor="checkbox1-2">
            <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
              checked={props?.scopUse === "N" ? true : false} isAlt={true} disabled></ArcCheckbox>
            입력사항 없음
          </label>
        </span>
      </div>
      <Scope2Grid fuflYy={props.fuflYy} scopId={7} scopUse={props.scopUse} onData={handleDataFromChild2} data={props.data.scop2Water}></Scope2Grid>

    </>
  );
}
