

export class UserMemberPostModel {
  userId?: string;
  userPersnCorpClCd?: string;
  emksRe100JoinClCd?: string;
  emksEntClCd?: string;
  emksMbrsClCd?: string;

  bizrRegNo?: string
  bizrNm?: string;
  reprNm?: string;
  coRepNo?: string;
  bizrAddr?: string;
  bizrDtlsAddr?: string;

  tchgrNm?: string;
  tchgrCplcNo?: string;
  tchgrEmailAddr?: string;

  userTmpSavYn?: string;
  mktgJoinAgreYn?: string;
  opbizYmd?: string;
  repAtflId?: string;
  userPwdEncVal?: string;
  isUpdate?: boolean;
  aprYn?: string;
  useYn?: string;
}