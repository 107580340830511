import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import { RoadMapService } from "services/cp/RoadMapService";
import { toast } from "react-toastify";
import { t } from "i18next";
import { HighchartsOptionsUtil } from "utils/highchartsOptionsUtil";
import { useEffect, useState } from "react";
import { parseNumberWithCommaDecimal } from "utils/stringUtil";

Highcharts.setOptions(HighchartsOptionsUtil.locale.kr.globalOptions);
highchartsMore(Highcharts);
SolidGauge(Highcharts);

export default function ComprehensiveStatus() {
  const [roadmapData, setRoadmapData] = useState<any>();
  const [rpData, setRpData] = useState<any>();
  class RdcGoalDataType {
    goalYy?: string | null;
    mtrmYy?: string | null;
    midGoalRt?: number | null;
    goalRt?: number | null;
  }

  let scopePieChartData: any;
  let data1: any;
  let data2: any;
  let standardYear: any;
  let standardYearEmsnQnt: any = 0;
  let category: any;
  let curTotalEmsnQnt: any;
  let lstTotalEmsnQnt: any;
  let totalEmsnQnt = 0;
  let rdcGoalData: RdcGoalDataType | undefined;
  let curRpQnt: any;
  let lstRpQnt: any;
  let curEng = 0;
  let lstEng = 0;

  useEffect(() => {
    RoadMapService.getInstance().getRoadmap().then((response: any) => {
      if (response.statusCode) {
        setRoadmapData(response.data);
      }
    })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });

    RoadMapService.getInstance().getRpEmsnQnt().then((response: any) => {
      if (response.statusCode) {
        setRpData(response.data);
      }
    })
      .catch(() => {
        toast.error(t("common.message.error.system"));
      });
  }, []);

  if (roadmapData && rpData && Object.keys(roadmapData).length > 0) {
    data1 = roadmapData.current;
    data2 = roadmapData.projected;

    if (roadmapData.standardYear && Array.isArray(roadmapData.standardYear) && roadmapData.standardYear.length === 2) {
      standardYear = roadmapData.standardYear[0];
      standardYearEmsnQnt = roadmapData.standardYear[1];
    }

    category = roadmapData.category;
    const goalYy = roadmapData.goalYy;
    const mtrmYy = roadmapData.mtrmYy;
    const midGoalRt = roadmapData.midGoalRt;
    const goalRt = roadmapData.goalRt;
    rdcGoalData = { goalYy, mtrmYy, midGoalRt, goalRt };
    curTotalEmsnQnt = roadmapData.currentTotalQnt;
    lstTotalEmsnQnt = roadmapData.lastTotalQnt;
    curRpQnt = rpData.curYearGenQnt;
    lstRpQnt = rpData.lstYearGenQnt;

    // rp 호출
    if (curTotalEmsnQnt)
      if (curTotalEmsnQnt !== 0 && curRpQnt !== 0) {
        const emsnQnt = curTotalEmsnQnt / 3.09; // 에너지 환산 공식. tco2eq -> toe
        curEng = (curRpQnt / emsnQnt) * 100;
      }
    if (lstTotalEmsnQnt) {
      if (lstTotalEmsnQnt !== 0 && lstRpQnt !== 0) {
        const emsnQnt = lstTotalEmsnQnt / 3.09; // 에너지 환산 공식. tco2eq -> toe
        lstEng = (lstRpQnt / emsnQnt) * 100;
      }
    }

    scopePieChartData = roadmapData.useScopeCategory.map((scope: any, index: any) => {
      return {
        name: scope,
        y: parseFloat(roadmapData.currentEmsnQnt[index].toFixed(2)),
      };
    });

    scopePieChartData.map((a: any) => totalEmsnQnt += a.y);
  }

  const mainChartOptions: Highcharts.Options = {
    chart: {
      type: "line",
      zooming: {
        type: undefined,
        mouseWheel: {
          enabled: false
        }
      }
    },
    title: { text: "" },
    exporting: { enabled: false },
    credits: { enabled: false },
    xAxis: {
      categories: category,
      crosshair: true,
      title: { text: '' },
      labels: {
        style: {
          color: '#555',
          fontSize: '14px'
        }
      }
    },
    yAxis: {
      offset: 0,
      min: 0,
      softMax: 10,
      lineColor: "#E1E1E1",
      title: {
        text: '',
      },
      labels: {
        y: 3,
        distance: 7,
        style: {
          color: '#555',
          fontSize: '14px'
        },
        formatter: function (this: any) {
          return parseNumberWithCommaDecimal(this.value);
        },
      },
    },
    tooltip: {
      shared: true,
      split: false,
      headerFormat: '{point.key}<br/>',
      style: {
        fontSize: '14px'
      }
    },
    plotOptions: {
      series: {
        borderWidth: 0,
      }
    },
    legend: {
      symbolWidth: 10,
      symbolHeight: 10,
      symbolRadius: 0,
      align: "right",
      verticalAlign: "top",
      itemStyle: {
        color: "#505050",
        fontSize: "14px",
      },
      itemHoverStyle: {
        color: "#000",
        fontSize: "14px",
      },
    },
    series: [
      {
        name: "현재 감축 경로",
        type: 'line',
        data: data1,
        color: {
          linearGradient: {
            x1: 0,
            x2: 0,
            y1: 0,
            y2: 1,
          },
          stops: [
            [0, "#7EC7AF"],
            [1, "#68A4D4"],
          ],
        },
        lineWidth: 2,
        marker: {
          radius: 5,
          lineWidth: 3,
          fillColor: "#fff",
          lineColor: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, "#7EC7AF"],
              [1, "#68A4D4"],
            ],
          },
        },
        dataLabels: {
          enabled: true,
          y: -10,
          allowOverlap: false,
          useHTML: true,
          format: '<div class="pie-cont-bg1"><strong class="pie-cont">{point.y:,.0f}</strong></div>'.replace(
            /(\d)(?=(\d{3})+$)/g,
            "$1,"
          ),
        },
      },
      {
        name: "예상 감축 경로",
        type: 'line',
        data: data2,
        color: "#2970CD",
        marker: {
          enabled: false,
        },
        lineWidth: 2,
      },
    ],
  };

  const scopePieChartOptions: Highcharts.Options = {
    chart: {
      type: "pie",
      height: 250,
    },
    colors: ["#2970CD", "#24ACC5", "#00BB9F"],
    title: { text: "" },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      symbolRadius: 0,
      symbolWidth: 10,
      symbolHeight: 10,
      borderWidth: 0,
      itemMarginTop: 15,
      useHTML: true,
      labelFormat:
        `<span style="color: #666; font-weight: 600;">{name}</span>
        <br/>
        <span style="color: {color}; font-size: 1.2rem; font-weight: 800;">{y:,.f}</span>
        <span style="color: #767676; font-size: 0.65rem; font-weight: 500;"> tCO2eq</span>`,
    },
    subtitle: {
      x: -80,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
      text:
        `<div style="text-align: center;">
          <span style="color: #444; font-size: 1.4rem; font-weight: 600;">${parseNumberWithCommaDecimal(totalEmsnQnt.toFixed(2))}</span>
          <br/>
          <span style="color: #828282; font-size: 0.8rem;">tCO2eq</span>
        </div>`,
    },
    tooltip: {
      useHTML: true,
      formatter: function (this: any) {
        return `<div>
          <span style="color: ${this.point.color}">● </span>
          <span style="font-size: 14px;">${this.point.name}: </span>
          <span style="font-size: 14px; font-weight: 700;">${this.point.y.toLocaleString()}</span>
          <span style="font-size: 12px; color: #555;">tCO2eq</span>
        </div>`;
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: "72%",
        borderRadius: 0,
      },
      series: {
        showInLegend: true,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: 'scope',
        data: scopePieChartData,
      },
    ],
  };

  const reGaugeOptions: Highcharts.Options = {
    chart: {
      type: "solidgauge",
      height: "250px",
    },
    title: { text: "" },
    exporting: { enabled: false },
    credits: { enabled: false },
    pane: {
      size: "125%",
      center: ["50%", "80%"],
      startAngle: -90,
      endAngle: 90,
      background: [
        {
          backgroundColor: "#F4F4F4",
          innerRadius: "68%",
          outerRadius: "93%",
          shape: "arc",
          borderWidth: 0,
        },
      ],
    },
    yAxis: {
      min: 0,
      max: 100,
      offset: 10,
      lineWidth: 0,
      minorTickInterval: null || 0,
      tickInterval: 25,
      tickWidth: 1,
      tickColor: "#ccc",
      tickLength: 7,
      labels: {
        style: {
          fontSize: "13px",
          color: "#777",
        },
        distance: 12,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      solidgauge: {
        innerRadius: "68%",
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#7ec7af"],
            [1, "#73B6C1"],
          ],
        },
        colorByPoint: false,
      },
    },
    series: [
      {
        type: "solidgauge",
        data: [Number(curEng.toFixed(2))],
        dataLabels: {
          y: -75,
          borderWidth: 0,
          useHTML: true,
          formatter: function () {
            return (
              `<div style="text-align: center; display: flex; flex-direction: column; align-items: center; gap: .1rem; max-width: 5rem;">
                <span style="font-size: 1.6rem; font-family: Pretendard, sans-serif; color: #444;">${this.y}%</span>
                
                <div class="v-badge">
                  <span>
                     <em class="${Number(lstEng.toFixed(2)) > 0 ? 'up' : (Number(lstEng.toFixed(2)) < 0 ? 'down' : '')}"></em>
                     ${Number(lstEng.toFixed(2)) === 0 ? '0' : lstEng.toFixed(2)}% 
                  </span>
                </div>

              </div>`
            );
          },
        },
      },
    ],
  };

  return (
    <div className="container">
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">로드맵 (tCO2eq)</h3>
              <div id="graph-typeA" className="chart twoline">
                <HighchartsReact highcharts={Highcharts} options={mainChartOptions}></HighchartsReact>
              </div>
            </div>
          </div>
        </div>
        <div className="col-wrap">
          <div className="col-md-4">
            <div className="cont">
              <h3 className="titT1">감축목표</h3>
              <div className="box-graph8 type1">
                <span>
                  <strong>2050</strong>탄소중립
                </span>
                <div className="rd-goal">
                  <span>
                    <strong>{rdcGoalData?.mtrmYy}</strong>년까지 <strong>{rdcGoalData?.midGoalRt}</strong>% 감축
                  </span>
                </div>
                <div className="rd-list">
                  <ul>
                    <li>
                      <span>
                        기준연도<strong>{category ? category[0] : ''}</strong>
                      </span>
                    </li>
                    <li>
                      <span>
                        중간목표 년도<strong>{rdcGoalData?.mtrmYy}</strong>
                      </span>
                      <em>
                        {rdcGoalData?.midGoalRt}%<i className="xi-long-arrow-down" aria-hidden="true"></i>
                      </em>
                    </li>
                    <li>
                      <span>
                        목표 년도<strong>{rdcGoalData?.goalYy}</strong>
                      </span>
                      <em>
                        {rdcGoalData?.goalRt}%<i className="xi-long-arrow-down" aria-hidden="true"></i>
                      </em>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cont">
              <h3 className="titT1">전체 탄소 배출량</h3>
              <div id="graph-typeA" className="chart">
                <HighchartsReact highcharts={Highcharts} options={scopePieChartOptions} />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cont">
              <h3 className="titT1">신재생에너지</h3>
              <div className="box-graph8 type3">
                <div id="solid-gauge" style={{ width: "80%", margin: "auto", height: "100%" }}>
                  <HighchartsReact highcharts={Highcharts} options={reGaugeOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
