import React, {useEffect, useState} from 'react'

export type RadioButtonDataModel = {
  label?: string,
  value: string
}

type RadioButtonProps = {
  type?: string
  isAlt?: boolean
  dataSource: RadioButtonDataModel[]
  selectedValue?: string
  nameGroup: string
  onChange: (data?: any) => void
  disabled?: boolean
  readOnly?:boolean
}

export default function ArcRadioButton(props: RadioButtonProps) {

  const {dataSource,
    selectedValue,
    nameGroup,
    onChange,
    disabled=false,
    isAlt=false,
    readOnly=false,
  } = props;

  const [selected, setSelected] = useState(selectedValue);

  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue])

  const selectedValueFunc = (event: any) => {
    if(!readOnly){
      setSelected(event.target.value);
      onChange(event);
    }
  }


  return (
    <span className={`checkbox-radio-${isAlt? "type02" : "type01"}`}>
      {
        dataSource.map((ele: any, index: any) => (
          <div key={index} className={`form-check ${index > 0 ? 'mgl10' : ''} `}>
            <label className="form-check-label ps-2"
                   htmlFor={`${nameGroup}-${index}`}>
              <input className="form-check-input" type="radio"
                     name={nameGroup}
                     checked={selected === ele.value}
                     id={`${nameGroup}-${index}`}
                     value={ele.value}
                     onChange={selectedValueFunc}
                     disabled={disabled}
                     readOnly={readOnly}
              />
              {ele.label}
            </label>
          </div>
        ))
      }
    </span>
  )
}
