
export class RpsModel {
    trdYmd?: string;
    areaCd?: string;
    trdCnt?: number;
    trdVol?: number;
    avgPrc?: number;
    hgPrc?: number;
    lwPrc?: number;
    upLmtPrc?: number;
    lwLmtPrc?: number;

    constructor(data?: Partial<RpsModel>) {
        Object.assign(this, data);
    }

    toMap(): Record<string, any> {
        return {
            trdYmd: this.trdYmd,
            areaCd: this.areaCd,
            trdCnt: this.trdCnt,
            trdVol: this.trdVol,
            avgPrc: this.avgPrc,
            hgPrc: this.hgPrc,
            lwPrc: this.lwPrc,
            upLmtPrc: this.upLmtPrc,
            lwLmtPrc: this.lwLmtPrc
        };
    }

    static fromMap(data: Record<string, any>): RpsModel {
        return new RpsModel(data);
    }
}


