import {useEffect} from "react"
import ArcButton, {ButtonColor, ButtonIcon, ButtonShape, ButtonSize} from "../../components/arc/ArcButton";
import ArcImageEditor from "../../components/arc/ArcImageEditor";


export default function ArcButtonExample() {


  useEffect(() => {


  }, [])

  return (
      <>
        <div>
          <h3>Button</h3>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.save}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.search}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.check}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.close}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.plus}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.regist}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.delete}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleIcon}
                     hasIcon={true}
                     icon={ButtonIcon.modify}/>
          <span> </span>
          <ArcButton shape={ButtonShape.circleText}
                     text={"버튼"}/>
          <span> </span>
          <ArcButton type={"custom"} hasIcon={true} color={ButtonColor.btn11} text={"커스텀"} shape={ButtonShape.round}/>
          <ArcButton type={"confirm"} hasIcon={true}/>
          <ArcButton type={"cancel"} hasIcon={true}/>
          <ArcButton type={"search"} hasIcon={true}/>
          <ArcButton type={"reset"} hasIcon={true}/>
          <ArcButton type={"regist"} hasIcon={true}/>
          <ArcButton type={"submit"} isDisabled={true} hasIcon={true}/>
          <ArcButton type={"detail"} hasIcon={true}/>
          <ArcButton type={"modify"} hasIcon={true}/>
          <ArcButton type={"delete"} hasIcon={true}/>
          <ArcButton type={"download"} hasIcon={true}/>
          <ArcButton type={"print"} hasIcon={true}/>
          <ArcButton type={"calendar"} hasIcon={true}/>
          <ArcButton type={"setting"} isDisabled={true}/>
          <ArcButton type={"text"} text={"text"}/>
          <ArcImageEditor/>
        </div>
      </>
  )
}