import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import CountUp from "react-countup";
import { AnlsInqService } from "services/cp/AnlsInqService";

HighchartsMore(Highcharts);
export default function DbCarbonPlanner() {

  const [ghgsDate, setGhgsDate] = useState<any>()

  // Greenhouse Gas Inventory Registrations
  const [ghgsIrThisMonth, setGhgsIrThisMonth] = useState(0);
  const [ghgsIrLastMonth, setGhgsIrLastMonth] = useState(0);

  // Greenhouse Gas Analysis Service Utilizations
  const [ghgsAsuThisMonth, setGhgsAsuThisMonth] = useState(0);
  const [ghgsAsuLastMonth, setGhgsAsuLastMonth] = useState(0);

  // Greenhouse Gas Reduction Project Applications
  const [ghgRpaThisMonth, setGhgRpaThisMonth] = useState(0);
  const [ghgRpaLastMonth, setGhgRpaLastMonth] = useState(0);

  // Energy Analysis Report Issuances
  const [earThisMonth, setEarThisMonth] = useState(0);
  const [earLastMonth, setEarLastMonth] = useState(0);

  //월별 온실가스 분석 서비스 이용 현황
  const [monGhgAsUsaStaOption, setMonGhgAsUsaStaOption] = useState<any>(); //월별 온실가스 분석 서비스 이용 현황

  // 업종별 에너지 분석 보고서 발급 현황
  const [indEneReStaOption, setIndEneReStaOption] = useState<any>();

  useEffect(() => {
    AnlsInqService.getInstance()
    .getSelectAll()
    .then(function (res: any) {
      setGhgsAsuThisMonth(res.data.data.ghgsAsuThisMonth)
      setGhgsAsuLastMonth(res.data.data.ghgsAsuLastMonth)
      setGhgsIrThisMonth(res.data.data.ghgsIrThisMonth)
      setGhgsIrLastMonth(res.data.data.ghgsIrLastMonth)
    });

    setGhgRpaThisMonth(5)
    setGhgRpaLastMonth(2)
    setEarThisMonth(30)
    setEarLastMonth(12)
  }, [])

  useEffect(() => {
    AnlsInqService.getInstance()
    .getGhgsChart()
    .then(function (res: any) {

      const analysisData: any[] = [];
      const simulationData: any[] = [];

      let latestDate: any = null;

      res.data.data.forEach((e: any) => {
        analysisData.push(e.analysis);
        simulationData.push(e.simulation);

        let currentChgDt = new Date(e.chgDt);
        if (latestDate === null || currentChgDt > latestDate) {
          latestDate = currentChgDt;
        }
      })
      if (latestDate) {
        const formattedLatestDate = latestDate.toISOString().split('T')[0];
        setGhgsDate(formattedLatestDate);
      }

      const drawGhgChart = {
        colors: ['#3c92ff', '#2bd7f6'],
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          height: 450
        },
        title: {
          text: ''
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        plotOptions: {
          series: {
            borderRadius: 0,
            borderWidth: 0,
            dataLabels: {
              useHTML: true,
              inside: true,
              enabled: true,
              crop: false,
              y: -25,
              verticalAlign: 'top',
              formatter(this: any) {
                return '<span class="bar-graph-color-num">' + this.y + '</span>';
              }
            }
          }
        },
        tooltip: {
          headerFormat: ''
        },
        legend: {
          symbolRadius: 0,
          symbolHeight: 10,
          align: 'right',
          verticalAlign: 'top',
          itemStyle: { 'color': '#fff' }
        },
        xAxis: {
          categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'], min: 0, title: { text: '' }, gridLineColor: '#666b78', tickColor: '#666b78', labels: { style: { color: '#d1d7e4' } }
        },
        yAxis: {
          title: { text: '(MWh)', style: { color: '#d1d7e4' } }, gridLineColor: '#666b78', tickColor: '#666b78', lineColor: '#666b78', labels: {
            style: { color: '#d1d7e4' }
          }
        },
        series: [
          {
            name: '다배출원 분석',
            data: analysisData
          }, {
            name: '탄소중립 시뮬레이션',
            data: simulationData
          }
        ]
      }

      const drawEneChart = {
        chart: {
          type: 'bar',
          backgroundColor: null,
          height: 450
        },
        colors: ['#3c92ff'],
        title: { text: '', align: 'right', style: { color: '#d1d7e4', fontWeight: 'normal', fontSize: '12px' } },
        exporting: { enabled: false },
        credits: { enabled: false },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: ['기계', '전기전자', '석유화학', '섬유의복', '비제조', '목재종이', '비금속', '기타', '운송장비'], min: 0, title: { text: '' }, gridLineColor: '#666b78', tickColor: '#666b78', labels: { style: { color: '#d1d7e4' } }
        },
        yAxis: {
          title: { text: '(건)', style: { color: '#d1d7e4' } }, gridLineColor: '#666b78', tickColor: '#666b78', lineColor: '#666b78', labels: {
            style: { color: '#d1d7e4' }
          }
        },
        plotOptions: {
          type: 'bar',
          series: {
            borderRadius: 0,
            pointWidth: 20,
            groupPadding: 0,
            pointPadding: 0.1,
            borderWidth: 0,
            dataLabels: {
              style: {
                textOutline: 0,
              },
              y: 2,
              enabled: true,
              crop: false,
              overflow: 'none',
              format: '<span class="bar-graph-color-num">{y}건</span>'
            },
          }
        },
        series: [
          {
            name: '에너지 분석 보고서 발급 현황',
            data: [33, 21, 14, 10, 6, 5, 4, 3, 2],
          }
        ],
      }
      setMonGhgAsUsaStaOption(drawGhgChart);
      setIndEneReStaOption(drawEneChart);
    });
  }, [])

  useEffect(() => {
    document.body.classList.add("admin");

    return () => {
      document.body.classList.remove('admin');
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/sub.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const sampleData = [
    {
      num: '1',
      vaule1: '1단지',
      vaule2: '(주)지오데코',
      vaule3: '제조',
      vaule4: '중소',
      vaule5: '지오데코 고효욜 공기압축기 교체를 통한 온실가스 감축사업',
      vaule6: '2023-07-14 2033-07-13',
      vaule7: '고효율 설비 교체 사업의 방법론',
      vaule8: '51 tCO2/년',
    }, {
      num: '2',
      vaule1: '1단지',
      vaule2: '(주)지오데코',
      vaule3: '제조',
      vaule4: '중소',
      vaule5: '지오데코 고효욜 공기압축기 교체를 통한 온실가스 감축사업',
      vaule6: '2023-07-14 2033-07-13',
      vaule7: '고효율 설비 교체 사업의 방법론',
      vaule8: '51 tCO2/년',
    }, {
      num: '3',
      vaule1: '1단지',
      vaule2: '(주)지오데코',
      vaule3: '제조',
      vaule4: '중소',
      vaule5: '지오데코 고효욜 공기압축기 교체를 통한 온실가스 감축사업',
      vaule6: '2023-07-14 2033-07-13',
      vaule7: '고효율 설비 교체 사업의 방법론',
      vaule8: '51 tCO2/년',
    }
  ];

  const columns: any = [
    {
      header: 'No',
      name: 'num',
      align: "center",
      width: 50,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '단지',
      name: 'vaule1',
      align: "center",
      width: 100,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '기업명',
      name: 'vaule2',
      width: 130,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '산업 분류',
      name: 'vaule3',
      width: 100,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '기업 규모',
      name: 'vaule4',
      width: 100,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '사업명',
      name: 'vaule5',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '유효기간',
      name: 'vaule6',
      width: 130,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '방법론',
      name: 'vaule7',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '연간 예상 온실가스 감축량',
      name: 'vaule8',
      width: 180,
      align: "center",
      whiteSpace: 'normal',
      // formatter: function (e: any) {
      //     return e.value
      // },
    }
  ]

  function duration(data: any) {
    return data < 10 ? 4 : 2;
  }

  return (
    <section className="section">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont pdb5">
            <div className="icon-box-wrap7">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>이번달 감축관리 목표 등록 건수</strong>
                    <div className="ac">
                      <span>
                        <CountUp end={Number(ghgsIrThisMonth) ?? 0} duration={duration(ghgsIrThisMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>건
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 감축관리 목표 등록 건수</strong>
                    <div className="ac">
                      <span>
                        <CountUp end={Number(ghgsIrLastMonth) ?? 0} duration={duration(ghgsIrLastMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>건
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>이번달 온실가스 분석 서비스 이용 건수</strong>
                    <div className="ac">
                      <span>
                        <CountUp end={Number(ghgsAsuThisMonth) ?? 0} duration={duration(ghgsAsuThisMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>건
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 온실가스 분석 서비스 이용 건수</strong>
                    <div className="ac">
                      <span>
                        <CountUp end={Number(ghgsAsuLastMonth) ?? 0} duration={duration(ghgsAsuLastMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>건
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="icon-box-wrap7">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>이번달 온실가스 감축 사업 신청 건수</strong>
                    <div className="ac">
                      <span><em data-count="5" className="change l-length">
                        <CountUp end={Number(ghgRpaThisMonth) ?? 0} duration={duration(ghgRpaThisMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 온실가스 감축 사업 신청 건수</strong>
                    <div className="ac">
                      <span><em data-count="2" className="change l-length">
                        <CountUp end={Number(ghgRpaLastMonth) ?? 0} duration={duration(ghgRpaLastMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>이번달 에너지 분석 보고서 발급 건수</strong>
                    <div className="ac">
                      <span><em data-count="30" className="change l-length">
                        <CountUp end={Number(earThisMonth) ?? 0} duration={duration(earThisMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 에너지 분석 보고서 발급 건수</strong>
                    <div className="ac">
                      <span><em data-count="12" className="change l-length">
                        <CountUp end={Number(earLastMonth) ?? 0} duration={duration(earLastMonth)} >
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <div className="icon-box-wrap7">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>월별 온실가스 분석 서비스 이용 현황 <em className="update">최근 업데이트 {ghgsDate}</em></strong>
                    <div className="graph">
                      <div id="graph-typeA" className="chart">
                        <HighchartsReact highcharts={Highcharts} options={monGhgAsUsaStaOption} />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>업종별 에너지 분석 보고서 발급 현황 <em className="update">최근 업데이트 23.12.20 12:00:00</em></strong>
                    <div className="graph">
                      <div id="graph-typeB" className="chart">
                        <HighchartsReact highcharts={Highcharts} options={indEneReStaOption} />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
