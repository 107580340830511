import Api from "./Api";
import { ResponseApi, ResponseResultCode } from "model/response-api";
import { FcmErrorCode, ResponseFcm } from "model/response-fcm";
import { toast } from "react-toastify";
import i18n from "i18next";

export class FirebaseService extends Api {
  private static firebaseService: FirebaseService;
  private static SAVE_DEVICE_TOKEN_URL: string = process.env['REACT_APP_URL_FCM'] + '/uid';
  // private static GET_DEVICE_TOKEN_URL: string = process.env['REACT_APP_URL_RP'] + '/uid';
  private static GET_MESSAGE_URL: string = process.env['REACT_APP_URL_FCM'] + '/message';
  private static READ_MESSAGE_URL: string = process.env['REACT_APP_URL_FCM'] + '/message/read';
  private static READ_ALL_MESSAGE_URL: string = process.env['REACT_APP_URL_FCM'] + '/message/messages/read';
  private static GET_MESSAGE_TYPE_URL: string = process.env['REACT_APP_URL_FCM'] + '/message/type';
  private static PUT_MESSAGE_TYPE_URL: string = process.env['REACT_APP_URL_FCM'] + '/message/type';
  private static PUT_MESSAGE_TYPE_ALL_URL: string = process.env['REACT_APP_URL_FCM'] + '/message/type/types';
  private static POST_MESSAGE_BROADCAST: string = process.env['REACT_APP_URL_FCM'] + '/message/broadcast';

  public static getInstance(): FirebaseService {
    if (!FirebaseService.firebaseService) {
      FirebaseService.firebaseService = new FirebaseService();
    }
    return FirebaseService.firebaseService;
  }


  public async saveDeviceToken(token: any) {
    return await super.post<any>(FirebaseService.SAVE_DEVICE_TOKEN_URL, token);
  }

  public async getDeviceToken(userUid: string) {
    return await super.get<any>(FirebaseService.SAVE_DEVICE_TOKEN_URL + "/" + userUid);
  }

  public async getMessage(request: any) {
    return await super.get<any>(FirebaseService.GET_MESSAGE_URL, request);
  }

  public async readMessage(request: any) {
    return await super.put<any>(FirebaseService.READ_MESSAGE_URL, request);
  }

  public async readAllMessage(request: any[]) {
    return await super.put<any>(FirebaseService.READ_ALL_MESSAGE_URL, request);
  }

  public async getMessageType(request: any) {
    return await super.get<any>(FirebaseService.GET_MESSAGE_TYPE_URL);
  }

  public async putMessageType(request: any) {
    return await super.put<any>(FirebaseService.PUT_MESSAGE_TYPE_URL, request);
  }

  public async putMessageTypes(request: any[]) {
    return await super.put<any>(FirebaseService.PUT_MESSAGE_TYPE_ALL_URL, request);
  }


  /*
  * READ ME
  * request for send alarm
  * {
    "typeId": "COMMENT",
    "parameter": {
        "name": "TEST",
        "comment": "comment"
    },
    "uid": "test_user1",
    "data": {
        "_link": "https://www.google.com/",
        "a": "1",
        "b": "2"
    },
    "title": "title",
    "body": "body"
  * }
  *
  *
  * */
  public sendMessage(request: any) {
    super.post<ResponseFcm>(FirebaseService.GET_MESSAGE_URL, request).then(response => {
      this.sendMessageResponse(response);
    }).catch(() => toast.error(i18n.t('common.fm.error.send')));
  }

  public sendMessageBroadcast(request: any) {
    super.post<ResponseFcm>(FirebaseService.POST_MESSAGE_BROADCAST, request).then(response => {
      this.sendMessageResponse(response);
    }).catch(() => toast.error(i18n.t('common.fm.error.send')));
  }

  private sendMessageResponse(response: void | ResponseApi<ResponseFcm> | null) {
    if(response && ResponseResultCode.Y === response.resultCode) {
      if(response.data) {
        if(response.data.result) {
          toast.success(i18n.t('common.fm.success.send'));
        } else {
          if(response.data.errorCode) {
            switch (response.data.errorCode) {
              case FcmErrorCode.UNKNOWN_UID:
                toast.error(i18n.t('common.fm.error.unknownUid'));
                break;
              case FcmErrorCode.UNREGISTERED:
                toast.error(i18n.t('common.fm.error.unregistered'));
                break;
            }
          }
        }
      } else {
        toast.error(i18n.t('common.fm.error.send'));
      }
    } else {
      toast.error(i18n.t('common.fm.error.send'));
    }
  }
}