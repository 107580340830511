import Formula from "./Formula";
import BasicEmission from "./BasicEmission";
import GlobalWarming from "./GlobalWarming";
import ProcessEmission from "./ProcessEmission";
import Industry from "./Industry";
import LoadTime from "./LoadTime";
import { useNavigate, useParams } from "react-router-dom";
import { JsxElementConstant } from "constants/jsxElementConstant";

export const basicInformationsTypes = {
  basicEmission: "basicEmission",
  globalWarming: "globalWarming",
  processEmission: "processEmission",
  industry: "industry",
  loadTime: "loadTime",
  formula: "formula",
} as const;

export type BasicInformationsTypes = (typeof basicInformationsTypes)[keyof typeof basicInformationsTypes];

export default function Board() {
  const navigate = useNavigate();
  const { type = basicInformationsTypes.basicEmission } = useParams();

  const handleClick = (tabNumber: string) => {
    if (type !== tabNumber) {
      navigate(`/cp/inventory/basic/${tabNumber}`);
    }
  };

  const tabComponent = () => {
    switch (type) {
      case "basicEmission":
        return <BasicEmission />;
      case "globalWarming":
        return <GlobalWarming />;
      case "processEmission":
        return <ProcessEmission />;
      case "industry":
        return <Industry />;
      case "loadTime":
        return <LoadTime />;
      case "formula":
        return <Formula />;
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <section className="section active">
        <div className="tab-st-box1">
          <ul className="tab-st1">
            <li className={type === basicInformationsTypes.basicEmission ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(basicInformationsTypes.basicEmission);
                }}
              >
                <span>연료별 온실가스별 기본 배출계수</span>
              </a>
            </li>
            <li className={type === basicInformationsTypes.globalWarming ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(basicInformationsTypes.globalWarming);
                }}
              >
                <span>온실가스별 지구온난화 계수</span>
              </a>
            </li>
            <li className={type === basicInformationsTypes.processEmission ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(basicInformationsTypes.processEmission);
                }}
              >
                <span>공정배출원 배출계수</span>
              </a>
            </li>
            <li className={type === basicInformationsTypes.industry ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(basicInformationsTypes.industry);
                }}
              >
                <span>표준산업분류 중분류</span>
              </a>
            </li>
            <li className={type === basicInformationsTypes.loadTime ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(basicInformationsTypes.loadTime);
                }}
              >
                <span>계절별 부하시간대</span>
              </a>
            </li>
            <li className={type === basicInformationsTypes.formula ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(basicInformationsTypes.formula);
                }}
              >
                <span>산정식 관리</span>
              </a>
            </li>
          </ul>
        </div>
        {tabComponent()}
      </section>
    </div>
  );
}
