import { useEffect, useState } from 'react'
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import CountUp from "react-countup";
import {ResponseChart} from "model/response-chart";
import {OtDashboardService} from "services/ot/OtDashboardService";
import {ResponseResultCode} from "model/response-api";
import moment from "moment";
import {ChartUtil} from "utils/chartUtil";
import {HighchartsOptionsUtil} from "utils/highchartsOptionsUtil";

HighchartsMore(Highcharts);
Highcharts.setOptions({
  ...HighchartsOptionsUtil.locale.kr.globalOptions,
  exporting: {enabled: false},
  credits: {enabled: false},
  tooltip: {shared: true, headerFormat: ''},
})

export class DbEnergyMarketModel {
  etChart: ResponseChart[] = [];
  otChart: ResponseChart[] = [];
  etTmCnt: number = 0;
  etLmCnt: number = 0;
  etTmCpct: number = 0;
  etLmCpct: number = 0;
  otTmCnt: number = 0;
  otLmCnt: number = 0;
  otTmPfrc: number = 0;
  otLmPfrc: number = 0;
}

export default function DbEnergyMarket() {

  useEffect(() => {
    document.body.classList.add("admin");
    return () => {
      document.body.classList.remove('admin');
    };
  }, []);

  const [currentDate,] = useState(moment().format('YYYY.MM.DD 12:00:00'));
  const initialCategoryMonth = () => {
    const to = moment().format('YYYYMMDD');
    const from = moment().add(-1, "year").add(1, "month").format('YYYYMMDD');
    return ChartUtil.getMonthRangeForCategory(from, to);
  }
  const [categoryMonth, ] = useState(initialCategoryMonth);

  const [etTmCnt, setEtTmCnt] = useState<number>(0); // 이번달 급전거래 건수
  const [etLmCnt, setEtLmCnt] = useState<number>(0); // 지난달 급전거래 건수
  const [etTmCpct, setEtTmCpct] = useState<number>(0); // 이번달 급전거래 용량
  const [etLmCpct, setEtLmCpct] = useState<number>(0); // 지난달 급전거래 용량

  const [otTmCnt, setOtTmCnt] = useState<number>(0); // 이번달 장외거래 건수
  const [otLmCnt, setOtLmCnt] = useState<number>(0); // 지난달 장외거래 건수
  const [otTmPfrc, setOtTmPfrc] = useState<number>(0); // 이번달 장외거래 실적
  const [otLmPfrc, setOtLmPfrc] = useState<number>(0); // 지난달 장외거래 실적

  const [etChart, setEtChart] = useState<any>(etOptionDefault); // 월별 급전거래 현황
  const [otChart, setOtChart] = useState<any>(otOptionDefault); // 월별 장외거래 현황

  function otOptionDefault() {
    return {
      chart: {
        type: 'line',
        backgroundColor: null,
        height: 450,
      },
      title: {text: ''},
      xAxis: {
        categories: categoryMonth,
        min: 0,
        title: {text: ''},
        gridLineColor: '#666b78',
        tickColor: '#666b78',
        labels: {style: {color: '#d1d7e4'}}
      },
      yAxis: [{
        title: {text: '(건)', style: {color: '#d1d7e4'}},
        gridLineColor: '#666b78',
        tickColor: '#666b78',
        lineColor: '#666b78',
        labels: {style: {color: '#d1d7e4'}}
      }, {
        title: {text: '(천원)', style: {color: '#d1d7e4'}},
        gridLineColor: '#666b78',
        tickColor: '#666b78',
        lineColor: '#666b78',
        labels: {
          style: {color: '#d1d7e4'},
          formatter: function (this: any) { return Highcharts.numberFormat(this.value, 0, '.', ','); },
        },
        opposite: true
      }],
      tooltip: {
        headerFormat: '',
        outside: true
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          pointPadding: 0,
          borderRadius: 0,
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        symbolHeight: 10,
        borderWidth: 0,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '13px',
          color: '#A2A2A2'
        }
      },
    };
  }

  function etOptionDefault() {
    return {
      chart: {
        type: 'line',
        backgroundColor: null,
        height: 450
      },
      title: {text: ''},
      xAxis: {
        categories: categoryMonth,
        min: 0,
        title: {text: ''},
        gridLineColor: '#666b78',
        tickColor: '#666b78',
        labels: {style: {color: '#d1d7e4'}}
      },
      yAxis: [{
        title: {text: '(건)', style: {color: '#d1d7e4'}},
        gridLineColor: '#666b78',
        tickColor: '#666b78',
        lineColor: '#666b78',
        labels: {style: {color: '#d1d7e4'}},
      }, {
        title: {text: '(용량)', style: {color: '#d1d7e4'}},
        gridLineColor: '#666b78',
        tickColor: '#666b78',
        lineColor: '#666b78',
        labels: {style: {color: '#d1d7e4'}},
        opposite: true
      }],
      tooltip: {
        headerFormat: '',
        outside: true
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          pointPadding: 0,
          borderRadius: 0,
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        symbolHeight: 10,
        borderWidth: 0,
        symbolRadius: 0,
        itemStyle: {
          fontSize: '13px',
          color: '#A2A2A2'
        }
      },
    };
  }

  useEffect(() => {
    OtDashboardService.getInstance().getEmarketAdminDb()
    .then(response => {
      if(response && ResponseResultCode.Y === response.resultCode) {
        convertOtChartSeries(response.data.otChart);
        convertEtChartSeries(response.data.etChart);
        setOtTmCnt(response.data.otTmCnt);
        setOtLmCnt(response.data.otLmCnt);
        setOtTmPfrc(response.data.otTmPfrc);
        setOtLmPfrc(response.data.otLmPfrc);
        setEtTmCnt(response.data.etTmCnt);
        setEtLmCnt(response.data.etLmCnt);
        setEtTmCpct(response.data.etTmCpct);
        setEtLmCpct(response.data.etLmCpct);
      }
    })
  }, []);

  function convertOtChartSeries(charts: ResponseChart[]) {
    const series = charts.map((item: ResponseChart) => {
      const data = ChartUtil.setMissingMonthlyData(item.data, categoryMonth);
      if("cnt" === item.legend) {
        return {
          type: 'column',
          name: '건수',
          data: data,
          color: '#3c92ff',
          yAxis: 0,
          dataLabels: {
            style: {textOutline: 0},
            x: 1, y: -2.5,
            inside: true, enabled: true,
            crop: false, overflow: 'none',
            format: '<span class="bar-graph-color-num" style="color: #fff;font-size:.75rem;">{y:,.0f}건</span>'
          }
        };
      } else if("pfrc" === item.legend) {
        return {
          name: '실적',
          data: data,
          color: '#16e4c6',
          marker: {lineWidth: 0, fillColor: '#16e4c6'},
          yAxis: 1,
          dataLabels: {
            enabled: true,
            y: -10,
            allowOverlap: false,
            useHTML: true,
            format: '<div class="pie-cont-bg1">' +
                '<strong class="pie-cont" style="background:#23ac98;padding:.15rem .5rem;border-radius:1rem;">' +
                  '{point.y:,.0f}' +
                '</strong>' +
              '</div>'
          }
        };
      }
    });

    if(series) {
      setOtChart({
        ...otOptionDefault(),
        series: series,
      });
    }
  }

  function convertEtChartSeries(charts: ResponseChart[]) {
    const series = charts.map((item: ResponseChart) => {
      const data = ChartUtil.setMissingMonthlyData(item.data, categoryMonth);
      if("cnt" === item.legend) {
        return {
          type: 'column',
          name: '건수',
          data: data,
          color: '#3c92ff',
          yAxis: 0,
          dataLabels: {
            style: {textOutline: 0},
            x: 1, y: -2.5,
            inside: true, enabled: true,
            crop: false, overflow: 'none',
            format: '<span class="bar-graph-color-num" style="color: #fff;font-size:.75rem;">{y:,.0f}건</span>'
          }
        };
      } else if("cpct" === item.legend) {
        return {
          name: '용량',
          data: data,
          color: '#16e4c6',
          marker: {lineWidth: 0, fillColor: '#16e4c6'},
          yAxis: 1,
          dataLabels: {
            enabled: true,
            y: -10,
            allowOverlap: false,
            useHTML: true,
            format: '<div class="pie-cont-bg1">' +
              '<strong class="pie-cont" style="background:#23ac98;padding:.15rem .5rem;border-radius:1rem;">' +
              '{point.y:,.0f}' +
              '</strong>' +
              '</div>'
          }
        };
      }
    });

    if(series) {
      setEtChart({
        ...etOptionDefault(),
        series: series,
      });
    }
  }

  return (
    <section className="section">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont pdb5">
            <div className="icon-box-wrap7">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>이번달 급전거래 건수</strong>
                    <div className="ac">
                      <span><em data-count={etTmCnt} className="l-length">
                        <CountUp end={Number(etTmCnt)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 급전거래 건수</strong>
                    <div className="ac">
                      <span><em data-count={etLmCnt} className="l-length">
                        <CountUp end={Number(etLmCnt)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>이번달 급전거래 용량</strong>
                    <div className="ac">
                      <span><em data-count={etTmCpct} className="l-length">
                        <CountUp end={Number(etTmCpct)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>MWh</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 급전거래 용량</strong>
                    <div className="ac">
                      <span><em data-count={etLmCpct} className="l-length">
                        <CountUp end={Number(etLmCpct)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>MWh</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="icon-box-wrap7">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>이번달 장외거래 건수</strong>
                    <div className="ac">
                      <span><em data-count={otTmCnt} className="l-length">
                        <CountUp end={Number(otTmCnt)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 장외거래 건수</strong>
                    <div className="ac">
                      <span><em data-count={otLmCnt} className="l-length">
                        <CountUp end={Number(otLmCnt)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>건</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>이번달 장외거래 실적</strong>
                    <div className="ac">
                      <span><em data-count={otTmPfrc} className="l-length">
                        <CountUp end={Number(otTmPfrc)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>천원</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>지난달 장외거래 실적</strong>
                    <div className="ac">
                      <span><em data-count={otLmPfrc} className="l-length">
                        <CountUp end={Number(otLmPfrc)} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>천원</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <div className="icon-box-wrap7">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>월별 급전거래 현황<em className="update">최근 업데이트 {currentDate}</em></strong>
                    <div className="graph">
                      <div id="graph-typeA" className="chart">
                        <HighchartsReact highcharts={Highcharts} options={etChart} />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <strong>월별 장외거래 현황<em className="update">최근 업데이트 {currentDate}</em></strong>
                    <div className="graph">
                      <div id="graph-typeB" className="chart">
                        <HighchartsReact highcharts={Highcharts} options={otChart} />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
