import ArcGrid, {GridColumn} from "components/arc/ArcGrid";
import i18n from "i18next";
import {parseNumberWithComma} from "utils/stringUtil";
import React from "react";
import {createRoot} from "react-dom/client";
import ArcButton, {ButtonShape, ButtonType} from "components/arc/ArcButton";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "stores/hook";
import {closeModal} from "reducers/modalSlice";
import {BillFormModel} from "pages/cb/bill/billForm/BillFormBase";
import {BillModel} from "model/cb/billModel";
import {BillService} from "services/cb/BillService";
import {ResponseResultCode} from "model/response-api";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import ContractModel from "model/cb/contractModel";
import moment from "moment";
import {useDispatch} from "react-redux";
import {CodeUtil} from "utils/codeUtil";

export default function BillSelectTarget(props: any) {
  return (<>
    {1 === props.data.emksReqdCd && (<PPASelectGrid />)}
    {2 === props.data.emksReqdCd && (<OTDCSelect />)}
    {4 === props.data.emksReqdCd && (<OTHDSelect />)}
  </>);
}

function OTHDSelect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const gridColumns: GridColumn[] = [
    {
      header: '거래구분',
      align: 'center',
      name: 'ldspTrdCdNm',
    },
    {
      header: '',
      align: 'center',
      renderer: {
        type: CustomGridSelectRenderer,
        options: {
          onClick: handleClickSelect,
        }
      }
    }
  ];

  const gridData: any[] = [
    {ldspTrdCd: 1, ldspTrdCdNm: '출력제한',},
    {ldspTrdCd: 2, ldspTrdCdNm: 'DR',}
  ];

  function handleClickSelect(data: any) {
    const param = {
      tchgrId: '200006',
      ldspTrdCd: data.ldspTrdCd,
    };
    BillService.getInstance().checkDuplicateTchgrHD(param)
    .then(response => {
      if(response && ResponseResultCode.Y === response.resultCode) {
        if(response.data) {
          toast.info('등록된 청구서가 존재합니다.');
        } else {
          dispatch(closeModal());
          const billModel = new BillModel();
          let param: BillFormModel = {
            bill: {
              ...billModel,
              tchgrId: '200006',
              reqdInfo: {
                ...billModel.reqdInfo,
                ldspTrdCd: data.ldspTrdCd
              }
            },
          };
          navigate('/ot/money/bills/hd/form', {state: JSON.stringify(param)});
        }
      }
    }).catch(() => toast.error(t('common.message.error.system')));
  }
  return (<>
    <h3 className="titT1">거래 구분 선택</h3>
    <ArcGrid columns={gridColumns}
             data={gridData}
             rowHeaders={['noNum']}
             usePagination={false}
             useStaticData={true} />
  </>);
}

function OTDCSelect() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  function handleClickSelect(data: any) {
    BillService.getInstance().checkDuplicateTchgr(data.rsrcId)
    .then(response => {
      if(response && ResponseResultCode.Y === response.resultCode) {
        if(response.data) {
          toast.info('이미 청구서가 등록된 대상입니다.');
        } else {
          dispatch(closeModal());
          let param: BillFormModel = {
            bill: {...new BillModel(), tchgrId: data.rsrcId,},
          };
          navigate('/ot/money/bills/dc/form', {state: JSON.stringify(param)});
        }
      }
    }).catch(() => toast.error(t('common.message.error.system')));
  }

  const gridColumns: GridColumn[] = [
    {
      header: '대상명',
      align: 'center',
      name: 'rsrcNm',
    },
    {
      header: '',
      align: 'center',
      renderer: {
        type: CustomGridSelectRenderer,
        options: {
          onClick: handleClickSelect,
        }
      }
    }
  ];

  const gridData: any[] = [
    {rsrcId: '200004', rsrcNm: '궁도장',},
    {rsrcId: '200005', rsrcNm: '과학관',}
  ];

  return (<>
    <h3 className="titT1">청구 대상 선택</h3>
    <ArcGrid columns={gridColumns}
             data={gridData}
             rowHeaders={['noNum']}
             isAlignHeader={true}
             usePagination={false}
             useStaticData={true} />
  </>);
}

function PPASelectGrid() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const gridColumns: GridColumn[] = [
    {
      header: i18n.t('ot.common.sort'),
      width: 100,
      align: 'center',
      name: 'sort',
      formatter: () => '직접'
    },
    {
      header: i18n.t('cb.cntr.prgsSts'),
      width: 150,
      align: 'center',
      name: 'cntrPrgsStsCd',
      renderer: {
        type: CustomGridContractStatusRenderer,
      }
    },
    {
      header: i18n.t('cb.cntr.cntrNm'),
      width: 200,
      align: 'left',
      name: 'cntrRsrcNm',
      formatter: (e: any) => `${e.value} ${i18n.t('ot.board.trade')} - ${e.row.dgr}${i18n.t('common.unit.seqno')}`
    },
    {
      header: i18n.t('cb.cntr.cntrTgt'),
      align: 'center',
      name: 'cntrTgtNm',
    },
    {
      header: '공급량(kWh)',
      align: 'center',
      name: 'cntrNqnt',
      formatter: (e: any) => parseNumberWithComma(e.value)
    },
    {
      header: '단가(원/kWh)',
      align: 'center',
      name: 'cntrUnitc',
      formatter: (e: any) => parseNumberWithComma(e.value)
    },
    {
      header: '사용 기간',
      align: 'center',
      name: 'useRange',
      formatter: () => {
        const monthAgo = moment().subtract(1, 'M');
        const stdt = monthAgo.startOf('M').format('MM-DD');
        const endt = monthAgo.endOf('M').format('MM-DD');
        return `${stdt} ~ ${endt}`;
      }
    },
    {
      header: '계약기간',
      align: 'center',
      name: '',
      formatter: () => '10년',
    },
    {
      header: '',
      align: 'center',
      renderer: {
        type: CustomGridSelectRenderer,
        options: {
          onClick: handleClickSelect,
        }
      }
    }
  ];

  function handleClickSelect(data: ContractModel) {
    if(data) {
      BillService.getInstance().checkDuplicateTchgrPPA(data)
      .then(response => {
        if(response && ResponseResultCode.Y === response.resultCode) {
          if(response.data) {
            toast.info('이미 청구서가 등록된 대상입니다.');
          } else {
            dispatch(closeModal());
            let param: BillFormModel = {
              cntr: data,
              bill: new BillModel(),
            }
            navigate('/rp/ppa/bills/form', {state: JSON.stringify(param)});
          }
        }
      }).catch(() => toast.error(t('common.message.error.system')));
    }
  }

  return (<>
    <h3 className="titT1">청구 대상 선택</h3>
    <ArcGrid columns={gridColumns}
                   datasource={{
                     readData: {url: '/cb/cntr/cntrs', method: 'GET', initParams: {emksCntrCd: 1, popupType: 'POPUP'}}
                   }}
                   isAlignHeader={true} />
    </>);
}

class CustomGridSelectRenderer {
  base: HTMLSpanElement;
  constructor(props: any) {
    this.base = document.createElement('span');
    this.render(props);
  }

  getElement = () => this.base;

  render(props: any) {
    let { onClick } = props.columnInfo.renderer.options;
    const rowData = props.grid.getRow(props.rowKey);
    createRoot(this.base).render(
      <ArcButton type={ButtonType.detail}
                 shape={ButtonShape.round}
                 text={i18n.t('common.label.select')}
                 onClick={() => onClick(rowData)}
                  />);
  }
}

class CustomGridContractStatusRenderer {
  base: HTMLDivElement;
  constructor(props: any) {
    this.base = document.createElement('div');
    this.render(props);
  }
  getElement = () => this.base;
  render(props: any) {
    const {value} = props;
    let btnColor;
    switch (value) {
      case 1: btnColor = 'btn-style1'; break;
      case 2: btnColor = 'btn-style3'; break;
      case 3: btnColor = 'btn-style2'; break;
      default: btnColor = 'btn-style1'; break;
    }
    createRoot(this.base).render(
      <div className={`${btnColor} btn-round`}>
        <span className="ac">
          <strong>{CodeUtil.cntrPrgsStsCodeConvert(value)}</strong>
        </span>
      </div>);

  }
}
