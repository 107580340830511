import ArcButton from "components/arc/ArcButton";
import ArcDatePicker, { ArcDatePickerResult } from "components/arc/ArcDatePicker";
import { t } from "i18next";
import { DocuActv } from "model/cp/DocuActv";
import { ResponseResultCode } from "model/response-api";
import { useState } from "react";
import { toast } from "react-toastify";
import { closeModal, reloadGrid } from "reducers/modalSlice";
import { showAndHideSpinner } from "reducers/spinnerSlice";
import { DocuEvdnService } from "services/cp/DocuEvdnService";
import { useAppDispatch } from "stores/hook";

export default function ActvInsertModal() {

  const dispatch = useAppDispatch();
  const [startDate,] = useState("");
  const [endDate,] = useState("");
  const [form, setForm] = useState<DocuActv>(new DocuActv());

  const handleChangeDate = (result: ArcDatePickerResult) => {
    setForm({
      ...form,
      strtYm: result.fromDateString?.substring(0, 6) ?? "",
      endYm: result.toDateString?.substring(0, 6) ?? ""
    });
  };

  function validationForm(param: any) {
    let check: boolean = true;

    if (!param.strtYm || !param.endYm) {
      check = false;
    }

    if (!check) {
      setForm((prevState: any) => {
        return {
          ...prevState,
          strtYm: prevState.strtYm ?? null,
          endYm: prevState.endYm ?? null,
        };
      });
    }
    return check;
  }

  const handleSubmit = () => {
    saveForm();
  };

  const saveForm = async () => {
    if (validationForm(form)) {
      dispatch(showAndHideSpinner(true));

      const response = await DocuEvdnService.getInstance().saveDocuActv(form);
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          toast.success(t("common.message.success.save", { title: "" }));
          cancelBtn();
          dispatch(reloadGrid());
        }
      }
      dispatch(showAndHideSpinner(false));
    } else {
      toast.error("필수 항목을 모두 입력해주시기 바랍니다.")
    }
  };

  function cancelBtn() {
    dispatch(closeModal());
  }

  return (
    <>
      <h3 className="titT1">활동자료 등록</h3>
      <div className="tbl-st2 mgt20">
        <table>
          <tbody>
            <tr>
              <th className="al tbl-L-R2 bu-est" style={{ width: "200px" }}>적용 년 월</th>
              <td>
                <ArcDatePicker fromDate={startDate}
                  toDate={endDate}
                  selectionMode={"range"}
                  viewType="month"
                  onChange={handleChangeDate}
                  id={""}
                  name={""} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btns ac">
        <ArcButton type={"cancel"} onClick={cancelBtn} />
        <ArcButton type={"regist"} onClick={handleSubmit} />
      </div>
    </>
  )
}