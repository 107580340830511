import Api from "./Api";

export class HolidayService extends Api {
  private static service: HolidayService;
  private CRUD: string = process.env.REACT_APP_URL_WP + "/common";

  public static getInstance(): HolidayService {
    if (!HolidayService.service) {
      HolidayService.service = new HolidayService();
    }
    return HolidayService.service;
  }

  public async getHolidays() {
    return await super.get<any>(process.env.REACT_APP_URL_CO + "/holiday");
  }
}