import React from 'react'

export default function NotFound() {
  const moveToBack = () => history.back();

  return (
    <div id="wrap">
      <div className="error">
        <div className="error-type1">
          <img src={require('assets/images/sub/error400.png')} alt="error400" />
          <div className="error-tit">
            <strong>해당 페이지를 찾을 수 없습니다.</strong>
            <span><a href={'javascript:;'} onClick={moveToBack}>이전 페이지로 되돌아가기</a></span>
          </div>
        </div>
      </div>
    </div>
  )
}
