
export class JsxElementConstant {
  public static readonly hrefIsNull = undefined;

  public static OnEnterKeyUp(e: KeyboardEvent, searchFunction: Function) {
    if (JsxElementConstant.isOnEnterKeyUp(e) && searchFunction) {
      searchFunction();
    }
  }

  public static isOnEnterKeyUp(e: KeyboardEvent) {
    return e?.key === 'Enter';
  }
}