import ArcButton from "components/arc/ArcButton";
import ArcGrid, {CustomTitleRenderer, GridColumn} from "components/arc/ArcGrid";
import AppAlert from "components/common/AppAlert";
import { ResponseResultCode } from "model/response-api";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openModal } from "reducers/modalSlice";
import { MhdlgService } from "services/cp/MhdlgService";

export default function MonitoringList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef: any = useRef<typeof ArcGrid>();

  const columns: GridColumn[] = [
    {
      header: "docId",
      name: "docId",
      hidden: true
    },
    {
      header: "사업계획서명",
      name: "rlvnDataNm",
      align: "left",
      whiteSpace: "normal",
      renderer:{
        type:CustomTitleRenderer
      },
    },
    {
      header: "적용 방법론명",
      name: "mhdlgNm",
      align: "left",
      width: 800,
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value;
      },
    },
    {
      header: "마지막 작성 일시",
      name: "chgDate",
      align: "center",
      width: 200,
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value) {
          return e.value.slice(0, 10)
        }
      },
    },
  ];

  function handleClickDetail(event: any) {
    const rowData = gridRef.current.getInstance().getRow(event.rowKey);
    if (rowData) {
      const id = rowData.docId;
      navigate(`/cp/reduce/history/monitoring/detail/${id}`);
    }
  }

  async function handleDel() {
    let chkArray = gridRef.current.getInstance().getCheckedRows();
    if (chkArray.length > 0) {
      gridRef.current.getInstance().removeCheckedRows();
      try {
        const response = await MhdlgService.getInstance().deleteMonis(chkArray);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t("common.message.success.delete", { title: `${chkArray.length}개의 사업계획서가` }));
          } else {
            toast.error(t("common.message.error.delete", { title: '사업계획서' }));
          }
        } else {
          toast.error(t('common.message.error.duringProcessing'));
        }
      } catch (error) {
        toast.error(t("common.message.error.system"));
      }
    }
  }

  const handleClickDelete = () => {
    const checkedNum = gridRef.current.getInstance().getCheckedRows().length;
    if (checkedNum > 0) {
      dispatch(
        openModal({
          path: "ot/confirm/apply/delete/confirm",
          target: AppAlert,
          wrapStyle: { width: "20rem" },
          confirmProps: {
            type: "delete",
            title: `선택된 ${checkedNum}개의 사업계획서를 ${t("confirm.label.message.delete")}`,
            onConfirm: handleDel,
          },
        })
      );
    } else {
      toast.warning(t('common.message.warn.noDeleteTarget'));
    }
  };


  // 기본값
  const [datasource,] = useState({
    readData: {
      url: "/cp/moni/list",
      method: "GET",
    },
  });

  const clickInsert = () => {
    navigate("/cp/reduce/history/monitoring/insert");
  };

  return (
    <>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <ArcGrid
              gridRef={gridRef}
              datasource={datasource}
              columns={columns}
              rowHeaders={['checkbox', 'rowNum']}
              useGridInfoHeader={true}
              usePagination={true}
              onCheckRow={() => { }}
              onClickRow={handleClickDetail}
            />

            <div className="ar">
              <ArcButton type={'delete'} hasIcon={true} onClick={handleClickDelete} />
              <ArcButton type={'regist'} hasIcon={true} onClick={clickInsert} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}