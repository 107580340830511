import EmissionsManagementGrid from "./EmissionsManagementGrid";
import EmissionsManagementStatistics from "./EmissionsManagementStatistics";

export default function EmissionsManagement() {
  return (
      <div className="container">
        <section className="section active">
          <EmissionsManagementStatistics/>
          <EmissionsManagementGrid/>
        </section>
      </div>
  );
}
