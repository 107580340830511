import {createSlice} from "@reduxjs/toolkit";
import {UserInfoModel} from "model/ua/UserInfoModel";
import {UserMemberModel} from "model/ua/UserMemberModel";

const initialState: any = {
  userInfo: UserInfoModel,
  tempUserMemberInfo: UserMemberModel,
  tempMyPageKepcoInfo: UserMemberModel,
  userMemberInfo: UserMemberModel,
  gridData: [],
  formData: [],
  signUpComCd: {
    ComUnit: [],
    EmksCustTpClCd: [],
    EmksEntClCd: [],
    EmksEqpKndCd: [],
    EmksIndsCmplxClCd: [],
    EmksMbrsClCd: [],
    EmksPrtclKndCd: [],
    EmksRe100JoinClCd: [],
    EmksRsrcChgClCd: [],
    MobileNumberPrefix: []
  }
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
    },
    setTempUserMemberInfo: (state, action) => {
      state.tempUserMemberInfo = action.payload;
    },
    setUserMemberInfo: (state, action) => {
      state.userMemberInfo = action.payload;
    },
    setMyPageKepcoInfo: (state, action) => {
      state.tempMyPageKepcoInfo = action.payload;
    },
    setGridData: (state, action) => {
      state.gridData = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setSignUpComCd: (state, action) => {
      state.signUpComCd = action.payload;
    },
  },
});

export default userInfoSlice.reducer;
export const {
  setUserInfo,
  clearUserInfo,
  setTempUserMemberInfo,
  setUserMemberInfo,
  setGridData,
  setFormData,
  setSignUpComCd,
  setMyPageKepcoInfo,
} = userInfoSlice.actions;