import {parseNumberWithCommaDecimal} from "./stringUtil";
import moment from "moment";

export class GridUtil {
  public static numberFormatter(e: any, decimalPoint?: number, isAbs: boolean = false) {
    if (e && !isNaN(e.value)) {
      let value = e.value;
      if (decimalPoint) {
        const s = Math.pow(10, decimalPoint);
        value = Math.round( e.value * s) / s;
      }
      return parseNumberWithCommaDecimal(isAbs ? Math.abs(value) : value);
    } else {
      return '-';
    }
  }

  public static dateToStringFormatterAuto(e: any) {
    let toDateFormat = 'YYYY-MM-DD';
    let fromDateFormat = 'YYYYMMDD';
    if (e?.value) {
      if (e.value.length === 10) {
        fromDateFormat = 'YYYYMMDDHH';
        toDateFormat = 'YYYY년 M월 D일 H시';
      } else if (e.value.length === 8) {
        fromDateFormat = 'YYYYMMDD';
        toDateFormat = 'YYYY년 M월 D일';
      } else if (e.value.length === 6) {
        fromDateFormat = 'YYYYMM';
        toDateFormat = 'YYYY년 M월';
      } else if (e.value.length === 4) {
        fromDateFormat = 'YYYY';
        toDateFormat = 'YYYY년';
      }
    }
    return GridUtil.dateToStringFormatter(e, toDateFormat, fromDateFormat);
  }

  public static dateToStringFormatter(e: any, toFormat: string = 'YYYY-MM-DD', fromFormat: string = 'YYYYMMDD') {
    if (e?.value) {
      return moment(e.value, fromFormat).format(toFormat);
    } else {
      return '';
    }
  }

  public static upDownArrow(e:any) {
    if (e < 0) {
      return `<i class='up-down-arrow-down xi-arrow-down'></i>${Math.abs(e)}`;
    }  else {
      return `<i class='up-down-arrow-up xi-arrow-up'></i> ${e}`;
    }
  }
  public static gridUpDownArrow(e:any) {
    if (e < 0) {
      return `<i class='up-down-count up-down-count-mark down'>${Math.abs(e)}%</i>`;
    }  else {
      return `<i class='up-down-count up-down-count-mark up'>${e}%</i> `;
    }
  }
}