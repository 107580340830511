import {createSlice} from "@reduxjs/toolkit";

const initialState: any = {
  holidays: [],
}

const holidaySlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    updateHolidays: (state, action) => {
      state.holidays = action.payload;
    }
  },
});

export default holidaySlice.reducer;
export const {updateHolidays} = holidaySlice.actions;