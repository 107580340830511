import i18n from "i18next";
import {getLocaleMonthName} from "utils/stringUtil";
import {SelectDataModel} from "../components/arc/ArcSelectbox";

export class VariablesConstant {
  public static readonly YES_NO = [
    {label: i18n.t('confirm.button.yes.text'), value: 'Y'},
    {label: i18n.t('confirm.button.no.text'), value: 'N'}
  ]

  public static readonly PAGE_PER_LIST = [
    {label: `10${i18n.t('common.unit.piece')}`, value: 10},
    {label: `20${i18n.t('common.unit.piece')}`, value: 20},
    {label: `30${i18n.t('common.unit.piece')}`, value: 30}
  ];

  public static readonly OT_CATEGORY = [
    {label: i18n.t('ot.board.searchOptionBasic'), value: '1'},
    {label: i18n.t('ot.board.searchOptionRequest'), value: '2'}
  ]

  public static readonly OT_REPORT_SEARCH = [
    {label: i18n.t('common.label.title'), value: 'titlNm'},
    {label: i18n.t('common.label.content'), value: 'ctt'},
    // {label: i18n.t('common.label.insName'), value: 'insNm'},
  ];

  public static readonly OT_REPORT_CODE = [
    {label: i18n.t('common.code.rpdcCd.1'), value: "1"},
    {label: i18n.t('common.code.rpdcCd.2'), value: "2"},
    {label: i18n.t('common.code.rpdcCd.3'), value: "3"},
  ]

  public static readonly CNTR_SORT = [
    {label: i18n.t('common.label.total'), value: '0'},
    {label: i18n.t('common.code.trdKndCd.1'), value: '1'},
    {label: i18n.t('common.code.trdKndCd.2'), value: '2'}
  ]

  public static readonly CTR_STATE = [
    {label: i18n.t('cb.state.complete'), value: 1},
    {label: i18n.t('cb.state.unpaid'), value: 2},
    {label: i18n.t('cb.state.paid'), value: 3},
  ];

  public static readonly BILL_SETL = [
    {label: i18n.t('cb.bill.status.1'), value: 1},
    {label: i18n.t('cb.bill.status.2'), value: 2}
  ]

  public static readonly BILL_PBLS_YN = [
    {label: i18n.t('cb.bill.reqdPbls.n'), value: 'N'},
    {label: i18n.t('cb.bill.reqdPbls.y'), value: 'Y'}
  ];

  public static readonly BILL_TXBL_YN = [
    {label: i18n.t('cb.bill.txblYn.n'), value: 'N'},
    {label: i18n.t('cb.bill.txblYn.y'), value: 'Y'}
  ];

  public static readonly Y_N = [
    {label: "Y", value: "Y"},
    {label: "N", value: "N"},
  ];

  public static readonly BILL_TRD_SORT = [
    {label: i18n.t('common.label.total'), value: '0'},
    {label: i18n.t('common.code.emksReqdCd.1'), value: '1'},
    {label: i18n.t('common.code.emksReqdCd.2'), value: '2'},
    {label: i18n.t('common.code.emksReqdCd.3'), value: '3'},
    {label: i18n.t('common.code.emksReqdCd.4'), value: '4'},
  ];

  public static readonly BILL_HD_CD = [
    {label: i18n.t('common.code.emksLdspTrdClCd.1'), value: '1'},
    {label: i18n.t('common.code.emksLdspTrdClCd.2'), value: '2'},
  ];

  public static readonly GENDER = [
    {label: "Active", value: "Y", labelKr: "활동적인"},
    {label: "Inactive", value: "N", labelKr: "비활성"}
  ]

  public static readonly SAMPLE = [
    {label: "All", value: "null", labelKr: "모두"},
    {label: "Java", value: "JAVA", labelKr: "자바"},
    {label: "Python", value: "PYTHON", labelKr: "파이썬"},
    {label: ".Net", value: "NET", labelKr: ".그물"},
    {label: "C++", value: "C", labelKr: "C++"}
  ]

  public static readonly MENU_STATUS = [
    {label: "Active", value: "Y", labelKr: "활동적인"},
    {label: "Inactive", value: "N", labelKr: "멈추다"}
  ]

  public static readonly DEFAULT_MENU_STATUS = "N"

  public static readonly DEFAULT_COMM_CODE_STATUS = "N"

  public static readonly COMM_CD_STATUS = [
    {label: "Active", value: 'Y', labelKr: "활동적인"},
    {label: "Inactive", value: 'N', labelKr: "멈추다"}
  ]

  public static readonly GEN = [
    {label: "Male", value: "1", labelKr: "남성"},
    {label: "Female", value: "0", labelKr: "여성"}
  ]

  public static readonly MONTH = [
    {label: getLocaleMonthName(1), value: "1"},
    {label: getLocaleMonthName(2), value: "2"},
    {label: getLocaleMonthName(3), value: "3"},
    {label: getLocaleMonthName(4), value: "4"},
    {label: getLocaleMonthName(5), value: "5"},
    {label: getLocaleMonthName(6), value: "6"},
    {label: getLocaleMonthName(7), value: "7"},
    {label: getLocaleMonthName(8), value: "8"},
    {label: getLocaleMonthName(9), value: "9"},
    {label: getLocaleMonthName(10), value: "10"},
    {label: getLocaleMonthName(11), value: "11"},
    {label: getLocaleMonthName(12), value: "12"},
  ]

  public static readonly TGT = [
    {label: "태양광", value: "태양광"},
    {label: "풍력", value: "풍력"},
    {label: "수력", value: "수력"},
    {label: "바이오", value: "바이오"},
    {label: "지열", value: "지열"},
    {label: "해양", value: "해양"},
  ]

  public static readonly MB = [
    {label: "참여", value: "0"},
    {label: "미참여", value: "1"},
    {label: "관심", value: "2"},
  ]

  public static readonly SCOPE = [
    { label: "전체", value: "all" },
    { label: "Scope1", value: "SCOPE1" },
    { label: "Scope2", value: "SCOPE2" },
    { label: "Scope3", value: "SCOPE3" }
  ]

  public static readonly SELECT_OPTIONS_ALL_DATE: SelectDataModel[] = [
    {label: '연도별', value: 'year'},
    {label: '연간', value: 'month'},
    {label: '월간', value: 'day'},
    {label: '일간', value: 'date'},
  ];
}