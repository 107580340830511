import {useEffect, useState} from "react"
import ArcTextArea from "../../components/arc/ArcTextArea";

export default function ArcTextAreaExample() {
  const [value, setValue] = useState();

  useEffect(() => {
  }, [value])

  const handleChange = (event: any, isValid :any) => {
    setValue(event);
  };
  return (
    <>

      <ArcTextArea value={"12"} rows={4} cols={40} type="w100p" isRequired={true}
                   validateMessage={"Required"} placeholder={"Place Holder"} prefix={""} maxLength={100}
                   id={"txtArea-1"} name={"txtArea-1"} onChange={handleChange}></ArcTextArea>


      <hr style={{margin: '1rem 0', borderTop: '1px solid #d1d1d1'}}/>
      <h5 className="titT3">textarea width %</h5>

      <textarea typeof="text" placeholder="textarea width 5%" className="InpSel-w5"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 10%" className="InpSel-w10"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 15%" className="InpSel-w15"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 20%" className="InpSel-w20"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 30%" className="InpSel-w30"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 40%" className="InpSel-w40"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 50%" className="InpSel-w50"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 60%" className="InpSel-w60"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 70%" className="InpSel-w70"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 80%" className="InpSel-w80"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 90%" className="InpSel-w90"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 100%" className="InpSel-w100"></textarea>


      <hr style={{margin: '1rem 0', borderTop: '1px solid #d1d1d1'}}/>
      <h5 className="titT3">textarea width px</h5>
      <textarea typeof="text" placeholder="textarea width 200px" className="InpSel-200"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 250px" className="InpSel-250"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 300px" className="InpSel-300"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 400px" className="InpSel-400"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 450px" className="InpSel-450"></textarea>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <textarea typeof="text" placeholder="textarea width 500px" className="InpSel-500"></textarea>


    </>
  )
}