import Cookies from "universal-cookie"
import {AuthConstant} from "constants/authConstant";

const getHeaders = () => {
    return {
        'Content-Type': 'application/json'
    }
}

const getHeadersAuth = () => {
    const cookie = new Cookies();
    return {
        'Content-Type': 'application/json',
    }
}

const getHeadersAuthFormData = () => {
    const cookie = new Cookies();
    return {
        'Content-Type': 'multipart/form-data',
    }
}

export const HeadersUtil = {
    getHeaders: getHeaders,
    getHeadersAuth: getHeadersAuth,
    getHeadersAuthFormData: getHeadersAuthFormData,
}