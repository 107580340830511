import { useEffect, useRef, useState } from "react";
import ArcGrid, {
  CustomTitleRenderer,
  GridColumn,
  UpDownNumberRenderer
} from "components/arc/ArcGrid";
import { EmissionService } from "services/cp/EmissionService";
import moment from "moment/moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { HighchartsOptionsUtil } from "utils/highchartsOptionsUtil";
import { Emcrd, priceAnalysisOverseaGrpTabs, PriceAnalysisProps, priceAnalysisTypes } from "./PriceAnalysis";
import { useNavigate, useParams } from "react-router-dom";
import { GridUtil } from "utils/gridUtil";
import { JsxElementConstant } from "constants/jsxElementConstant";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";

export default function PriceAnalysisOversea(props: Readonly<PriceAnalysisProps>) {
  const { type = priceAnalysisTypes.oversea, grpTabIndex = "1", isinCd } = useParams();
  const navigate = useNavigate();
  const latestGridRef: any = useRef<typeof ArcGrid>();
  const isinCdGridRef: any = useRef<typeof ArcGrid>();
  const itmGrpNm = priceAnalysisOverseaGrpTabs.tabs[Number(grpTabIndex) - 1].name;
  const [latestListByItmGrpNm, setLatestListByItmGrpNm] = useState<Emcrd[]>();
  const [listByIsinCd, setListByIsinCd] = useState<Emcrd[]>();

  useEffect(() => {
    EmissionService.getInstance()
      .getEmissionOverseaLatestByItmGrpNm(itmGrpNm)
      .then((res) => {
        if (res.data.data) {
          setLatestListByItmGrpNm(res.data.data);
        } else {
          setLatestListByItmGrpNm([]);
        }
      })
      .catch(() => setLatestListByItmGrpNm([]));
  }, [itmGrpNm]);

  useEffect(() => {
    if (isinCd) {
      EmissionService.getInstance()
        .getEmissionOverseaByIsinCd(isinCd)
        .then((res) => {
          if (res.data.data) {
            setListByIsinCd(res.data.data);
          } else {
            setListByIsinCd([]);
          }
        })
        .catch(() => setListByIsinCd([]));
    } else {
      setListByIsinCd([]);
    }
  }, [isinCd]);

  const handleTabOnClick = (newGrpTabIndex: number) => {
    navigate(`/cp/emiss/price/${type}/${newGrpTabIndex}`);
  };

  const handleGridRowOnClick = (e: any) => {
    let isinCd = "";
    const rowData = e.columnName;

    if (rowData && rowData === "itmNm") {
      isinCd = e.instance.store.data.rawData[e.rowKey].isinCd;
    }

    if (isinCd) {
      navigate(`/cp/emiss/price/${type}/${grpTabIndex}/${isinCd}`);
    }
  };

  const ohlc: any = [];
  const volume: any = [];
  let itmNm = "";
  let stPrc;
  let hgPrc;
  let lwPrc;
  let csPrc;

  if (listByIsinCd) {
    for (let emcrd of [...listByIsinCd].sort((a: Emcrd, b: Emcrd) => Number(a.baseYmd) - Number(b.baseYmd))) {
      if (!itmNm) {
        itmNm = emcrd.itmNm;
      }
      stPrc = Number(emcrd.mktPrc) === 0 ? emcrd.clsPrc : emcrd.mktPrc;
      hgPrc = Number(emcrd.hgPrc) === 0 ? emcrd.clsPrc : emcrd.hgPrc;
      lwPrc = Number(emcrd.lwPrc) === 0 ? emcrd.clsPrc : emcrd.lwPrc;
      csPrc = emcrd.clsPrc;
      ohlc.push([moment(emcrd.baseYmd, "YYYYMMDD").unix() * 1000, stPrc, hgPrc, lwPrc, csPrc]);
      volume.push([moment(emcrd.baseYmd, "YYYYMMDD").unix() * 1000, emcrd.trdVol]);
    }
  }

  Highcharts.setOptions(HighchartsOptionsUtil.locale.kr.globalOptions);
  const options: Highcharts.Options = {
    chart: {
      height: 420,
    },
    title: { text: "" },
    time: HighchartsOptionsUtil.locale.kr.time,
    exporting: { enabled: false },
    credits: { enabled: false },
    rangeSelector: {
      x: 15,
      buttons: [
        {
          type: "day",
          count: 7,
          text: "7일",
        },
        {
          type: "month",
          count: 1,
          text: "1개월",
        },
        {
          type: "month",
          count: 3,
          text: "3개월",
        },
        {
          type: "year",
          count: 1,
          text: "1년",
        },
        {
          type: "ytd",
          text: " 올해",
        },
        {
          type: "all",
          text: "전체",
        },
      ],
      inputEnabled: false,
      // selected: 1,
    },
    tooltip: HighchartsOptionsUtil.locale.kr.stockChart.tooltip,
    legend: {
      enabled: true,
      floating: true,
      symbolHeight: 10,
      symbolRadius: 0,
      align: "right",
      verticalAlign: "top",
      y: -42,
      itemStyle: {
        color: "#505050",
        fontSize: "14px",
      },
      itemHoverStyle: {
        color: "#000",
        fontSize: "14px",
      },
    },
    plotOptions: {
      candlestick: {
        color: "#2970cd",
        lineColor: "#2970cd",
        lineWidth: 1,
        upColor: "#E0564E",
        upLineColor: "#E0564E",
      },
      column: {
        color: "#b8b8bb",
        borderRadius: 0,
      },
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: HighchartsOptionsUtil.locale.kr.dateTimeLabelFormats,
      showLastLabel: true,
      labels: {
        style: {
          color: '#555',
          fontSize: '14px'
        }
      }
    },
    yAxis: [
      {
        opposite: false,
        showLastLabel: true,
        height: "70%",
        lineWidth: 1,
        offset: 0,
        title: {
          text: '(USD)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      {
        opposite: false,
        showLastLabel: true,
        top: "78%",
        height: "22%",
        lineWidth: 1,
        offset: 0,
        title: {
          text: '(톤)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: 85,
          x: -5
        },
        labels: {
          y: 6,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
    ],
    navigator: {
      xAxis: {
        dateTimeLabelFormats: HighchartsOptionsUtil.locale.kr.dateTimeLabelFormats,
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      }
    },
    series: [
      {
        type: "candlestick",
        name: itmNm,
        data: ohlc,
      },
      {
        type: "column",
        name: "거래량",
        data: volume,
        yAxis: 1,
      },
    ],
  };

  const gridColum: GridColumn[] = [
    {
      header: "기준일",
      align: "center",
      name: "baseYmd",
      formatter: GridUtil.dateToStringFormatter,
      width: 140
    },
    {
      header: "종목명",
      align: "center",
      name: "itmNm",
      width: 250,
      renderer:{
        type:CustomTitleRenderer
      },
    },
    {
      header: "종가",
      align: "right",
      name: "clsPrc",
      formatter: GridUtil.numberFormatter,
      width: 160
    },
    {
      header: "등락률(%)",
      align: "right",
      name: "vsRt",
      renderer: {
        type: UpDownNumberRenderer
      },
      width: 140
    },
    {
      header: "시가",
      align: "right",
      name: "mktPrc",
      formatter: GridUtil.numberFormatter,
      width: 160
    },
    {
      header: "고가",
      align: "right",
      name: "hgPrc",
      formatter: GridUtil.numberFormatter,
      width: 160
    },
    {
      header: "저가",
      align: "right",
      name: "lwPrc",
      formatter: GridUtil.numberFormatter,
      width: 160
    },
    {
      header: "거래량(톤)",
      align: "right",
      name: "trdVol",
      formatter: GridUtil.numberFormatter
    },
    {
      header: `거래대금(${priceAnalysisOverseaGrpTabs.tabs[Number(grpTabIndex) - 1]?.currency})`,
      align: "right",
      name: "trdAmt",
      formatter: GridUtil.numberFormatter
    }
  ];

  return (
    <>
      <div className="tab-st-box2 mgb0">
        <ul className="tab-st2">
          {priceAnalysisOverseaGrpTabs.tabs.map((tab, index) => (
            <li key={tab.name} className={Number(grpTabIndex) === index + 1 ? "on" : ""}>
              <a href={JsxElementConstant.hrefIsNull} onClick={() => handleTabOnClick(index + 1)} title={tab.name}>
                <span>{tab.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-wrap mg0">
        <div className="col-md-12">
          <div className="cont">
            <div className="mgt20">
              {isinCd == null || undefined ? (
                <>
                  <h3 className="titT1">종목별 시세 정보</h3>
                  <p className="font-FM">종목명을 클릭하면 일자별 시세 정보를 확인할 수 있습니다.</p>
                  <ArcGrid
                    onClickRow={handleGridRowOnClick}
                    gridRef={latestGridRef}
                    useStaticData={true}
                    data={latestListByItmGrpNm}
                    rowHeaders={["noNum"]}
                    columns={gridColum}
                    usePagination={true}
                    useGridInfoHeader={true} />
                </>
              ) : (
                <>
                  <h3 className="titT1">{`${itmNm} 일자별 시세 정보`}</h3>
                  <div id={"chartData"} className="chart mgt20">
                    <HighchartsReact highcharts={Highcharts} options={options} constructorType={"stockChart"} />
                  </div>
                  <ArcGrid
                    gridRef={isinCdGridRef}
                    useStaticData={true}
                    data={listByIsinCd}
                    rowHeaders={["noNum"]}
                    columns={gridColum}
                    usePagination={true}
                    useGridInfoHeader={true} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
