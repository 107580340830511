import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";

import {firebaseConfig, vapidKey} from "config/firebaseConfig";

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestPermission = async (): Promise<string | null> => {
  console.log("Requesting User Permission......");

  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    console.log("Notification User Permission Granted.");

    try {
      const currentToken = await getToken(messaging, {vapidKey: vapidKey});
      if (currentToken) {
        console.log('Client Token has received');
        return currentToken;
      } else {
        console.log('Failed to generate the app registration token.');
        return null;
      }
    } catch (err) {
      console.log('An error occurred when requesting to receive the token.', err);
      return null;
    }
  } else {
    console.log("User Permission Denied.");
    return null;
  }
};


export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log('listing...', payload)
        resolve(payload);
      });
    });

