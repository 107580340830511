import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "stores/hook";
import {formatDistanceToNow} from "date-fns";
import {ko} from 'date-fns/locale';
import {FirebaseService} from "services/common/FirebaseService";
import {getMessages} from "guard/authGuard";
import {UserInfoModel} from "model/ua/UserInfoModel";
import ArcButton, {ButtonColor} from "../../arc/ArcButton";
import moment from "moment";

export default function AccountAlarm() {
  const dispatch = useAppDispatch();
  const userInfo: UserInfoModel = useAppSelector((state) => state.userInfo.userInfo);
  const alarmMessages: any = useAppSelector((state) => state.messagesAlarm.messagesAlarm);
  const utilOverBoxRef = useRef<HTMLDivElement | null>(null);
  const alarmRef = useRef<HTMLLIElement | null>(null);
  const aTagRef = useRef<any | null>(null);
  const handleClickAlarmBadge = (event: any) => {
    event.preventDefault();
  }

  const handleClickAlarmClose = (event: any) => {
    event.preventDefault();
  }

  const handleClickReadAllAlarm = () => {
    FirebaseService.getInstance().readAllMessage(alarmMessages.filter((item: any) => item.readYn === 'N')).then(resp => {
      if (resp) {
        if (resp.resultCode == "Y") {
          getMessages(dispatch, userInfo);

        }
      }
    })
  }

  const handleClickAlarmNavigate = (event: any, item: any) => {
    event.preventDefault();
    if (item.readYn == "N") {
      FirebaseService.getInstance().readMessage(item).then(resp => {
        if (resp) {
          if (resp.resultCode == "Y") {
            getMessages(dispatch, userInfo);
            if (item.data._link) {
              window.open(item.data._link);
            }
          }
        }
      })
    }
  }

  function timeAgo(dateString: string): string {
    // need to convert utc to local
    const date = moment.utc(dateString).toDate();
    return formatDistanceToNow(date, {addSuffix: true, locale: ko});
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/layout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (alarmRef.current && !alarmRef.current.contains(event.target as Node)) {
        if (alarmRef.current && alarmRef.current.classList.contains('active')) {
          aTagRef.current.click();
        }
        // Perform your action here, for example, close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [utilOverBoxRef]);

  return (


      <li className="alarm" ref={alarmRef}>
        <a href="#" onClick={handleClickAlarmBadge}><span className="hid">알림</span><em
            className="badge">{alarmMessages.filter((item: any) => item.readYn === 'N').length}</em></a>

        <div className="util-over-box">
          <div className="tit">
            <strong className="tit-txt">알림 <ArcButton type={"text"}
                                                      size={"btn-small"}
                                                      shape={"btn-round"}
                                                      color={ButtonColor.btn1}
                                                      text={"전제읽음"}
                                                      onClick={() => handleClickReadAllAlarm()}
            ></ArcButton>
            </strong>

            <a href="#" onClick={handleClickAlarmClose} ref={aTagRef} className="close-btn">
              <i className="las la-times"></i><span className="hid">닫기</span>
            </a>
          </div>
          <div className="cont">
            <ul id="notify-area">
              {alarmMessages?.length === 0
                ?
                  <li className="ac">알림이 없습니다.</li>
                :
                  alarmMessages.map((item: any) => {
                    return <li key={item.messageId} className={item.readYn == "Y" ? "bgY" : "bgN"}>
                      <a href="#" onClick={event => handleClickAlarmNavigate(event, item)} title={item.title}>
                        <div className="img no-profile"></div>
                        <div className="txt">
                          <strong style={{whiteSpace: 'pre-line'}}>{item.body}</strong>
                          <span>{timeAgo((item.regDt))}</span>
                        </div>
                      </a>
                    </li>;
                  })
              }
            </ul>
          </div>
        </div>
      </li>
  )
}