import { useNavigate, useParams } from "react-router-dom";
import EvdnList from "./EvdnList";
import ActvList from "./ActvList";
import { JsxElementConstant } from "constants/jsxElementConstant";

export const docuTab = {
    evdnList: "evdnList",
    actvList: "actvList"
} as const;

export type BocuTab = (typeof docuTab)[keyof typeof docuTab];

export default function Board() {
    const navigate = useNavigate();
    const { type = docuTab.evdnList } = useParams();

    const handleClick = (tabNumber: string) => {
        if (type !== tabNumber) {
            navigate(`/cp/reduce/docu/${tabNumber}`);
        }
    };

    const tabComponent = () => {
        switch (type) {
            case "evdnList":
                return <EvdnList />;
            case "actvList":
                return <ActvList />;
            default:
                return null;
        }
    };

    return (
        <div className="container">
            <section className="section active">
                <div className="tab-st-box1">
                    <ul className="tab-st1">
                        <li className={type === docuTab.evdnList ? "on" : ""}>
                            <a
                                href={JsxElementConstant.hrefIsNull}
                                onClick={() => {
                                    handleClick(docuTab.evdnList);
                                }}
                            >
                                <span>증빙자료</span>
                            </a>
                        </li>
                        <li className={type === docuTab.actvList ? "on" : ""}>
                            <a
                                href={JsxElementConstant.hrefIsNull}
                                onClick={() => {
                                    handleClick(docuTab.actvList);
                                }}
                            >
                                <span>활동자료</span>
                            </a>
                        </li>
                    </ul>
                </div>
                {tabComponent()}
            </section>
        </div>
    );
}
