import React, { lazy, useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { parseNumberWithComma } from "utils/stringUtil";
import { EmissionService } from "services/cp/EmissionService";
import CountUp from "react-countup";
import { ResponseResultCode } from "model/response-api";
import { durpc } from "pages/db/DbUserRe100PartCompService/DurpcFunction";
import ChartGhgsByMonth from "pages/db/DbUserRe100PartCompService/ChartGhgsByMonth";
import ArcTooltip from "components/arc/ArcTooltip";

const ChartGhgsByFacility = lazy(() => import("pages/db/DbUserRe100PartCompService/ChartGhgsByFacility"));

export default function DbUserRe100PartCompServiceTab2() {

  const toDayYear = new Date().getFullYear();
  const [resultOne, setResultOne] = useState<any>({
    scope1: { scopeNm: "", total: 0 },
    scope2: { scopeNm: "", total: 0 },
    scope3: { scopeNm: "", total: 0 },
  });

  const [goalEmissions, setGoalEmissions] = useState<any>(0);
  const [goalPercent, setGoalPercent] = useState<any>(0);
  const [lastUpdateDate, setLastUpdateDate] = useState<string>("");
  // 연간 누적 온실가스 배출량
  const [total, setTotal] = useState<any>(0);

  const [chartBData, setChartBData] = useState<any>();
  const [chartCData, setChartCData] = useState<any[]>([]);

  useEffect(() => {
    EmissionService.getInstance().getGoalEmissions().then(function (res: any) {
      if (res.data.data) {
        setGoalPercent(res.data.data.persent)
        setGoalEmissions(res.data.data.total)
      }
    })
  }, []);

  useEffect(() => {
    EmissionService.getInstance().getEmissiopnStatus(toDayYear.toString()).then(function (res: any) {
      const monthlyEmsnByScope = durpc.monthlyEmsnByScope(res.data);
      if(monthlyEmsnByScope) {
        const lastUpdateDate = monthlyEmsnByScope.scope1.chgDate ||
          monthlyEmsnByScope.scope2.chgDate || monthlyEmsnByScope.scope3.chgDate;
        setLastUpdateDate(lastUpdateDate ? lastUpdateDate.substring(0,10) : "");
        const total = Object.keys(monthlyEmsnByScope).reduce((acc: number, cur: string) => {
          return durpc.roundToFourDecimals(acc + (monthlyEmsnByScope[cur].total??0));
        }, 0);
        setTotal(total);
      } else {
        setLastUpdateDate("");
        setTotal(0);
      }


      if (monthlyEmsnByScope.scope1.total == 0) {
        delete monthlyEmsnByScope.scope1;
      }
      if (monthlyEmsnByScope.scope2.total == 0) {
        delete monthlyEmsnByScope.scope2;
      }
      if (monthlyEmsnByScope.scope3.total == 0) {
        delete monthlyEmsnByScope.scope3;
      }
      setResultOne(monthlyEmsnByScope);
    });
  }, []);

  useEffect(() => {
    getEmissionFacilityStatusByYear(toDayYear).then(response => {
      const monthlyEmsnThisYear = durpc.monthlyEmsnByFclt(response.now);
      const monthlyEmsnLastYear = durpc.monthlyEmsnByFclt(response.last);
      setChartBData({now: monthlyEmsnThisYear, last: monthlyEmsnLastYear});
      setChartCData(monthlyEmsnThisYear);
    });
  }, []);

  async function fetchEmissionFacilityStatus(year: number) {
    const response = await EmissionService.getInstance().getEmissionFacilityStatus(year.toString());
    if(response && ResponseResultCode.Y === response.resultCode) {
      return response.data??[];
    }
    return [];
  }

  async function getEmissionFacilityStatusByYear(year: number) {
    const thisYearData = await fetchEmissionFacilityStatus(year);
    const lastYearData = await fetchEmissionFacilityStatus(year-1);
    return {now: thisYearData as any[], last: lastYearData as any[]};
  }

  const data: any = [];  //Scope별 배출량(tCO2)
  const categories: any = [];
  if (resultOne.scope1) {
    data.push(Number(resultOne.scope1.total.toFixed(2)));
    categories.push(resultOne.scope1.scopeNm);
  }
  if (resultOne.scope2) {
    data.push(Number(resultOne.scope2.total.toFixed(2)));
    categories.push(resultOne.scope2.scopeNm);
  }
  if (resultOne.scope3) {
    data.push(Number(resultOne.scope3.total.toFixed(2)));
    categories.push(resultOne.scope3.scopeNm);

  }
  const colors = ["#24ACC5", "#00BB9F", "#53C07E"];
  const graphTypeA = {
    chart: {
      type: "column",
      backgroundColor: undefined,
      borderWidth: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 0,
      margin: 0,
      height: 80,
    },
    colors: colors,
    title: { text: "" },
    exporting: { enabled: false },
    credits: { enabled: false },
    legend: {
      enabled: false,
    },
    xAxis: {
      title: "",
      categories: categories,
      opposite: true,
      lineWidth: 0,
      labels: {
        align: "center",
        y: 40,
        style: {
          color: "#444",
          fontSize: ".85rem",
          fontWeight: "bold",
          textOutline: "0px",
        },
      },
    },
    yAxis: {
      title: "",
      visible: false,
    },
    plotOptions: {
      type: "column",
      series: {
        borderRadius: 0,
        animation: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        colorByPoint: true,
        crisp: false,
        pointWidth: 100,
        dataSorting: {
          enabled: false,
          matchByName: true,
        },
        dataLabels: {
          useHTML: true,
          inside: true,
          enabled: true,
          formatter: function () {
            let obj: any = this;
            let formattedY = obj.y ? obj.y.toLocaleString() : "";
            return `<span class="bar-graph-color-num" style="display:block;${obj.point.graphic.height <= 20 ? "margin-top:-15px" : ""
            };font-size:.85rem;font-weight:bolder;text-shadow:1.25px 1.25px 0 ${obj.color}, -1.25px -1.25px 0 ${obj.color
            }, 1.25px -1.25px 0 ${obj.color}, -1.25px 1.25px 0 ${obj.color}, 0px 0px 0px ${obj.color
            };color:#fff">${formattedY}</span></div>`;
          },
        },
      },
    },
    tooltip: {
      outside: true,
      headerFormat: "",
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        let formattedY = this.y ? this.y.toLocaleString() : "";
        return `<span style="color:${this.color}">●</span> 
              ${this.x}: <b>${formattedY}</b><br/>`;
      },
    },
    series: [
      {
        data: data,
      },
    ],
  };

  const tooltipContent = (
    <ul className="list-st1">
      <li>SCOPE1 : 기업이 소유〮관리하는 사업장에서 직접 배출되는 온실가스</li>
      <li>SCOPE2 : 기업이 전기, 스팀 등 에너지를 사용함으로써 간접 배출되는 온실가스</li>
      <li>SCOPE3 : 가치 사슬 전체에서 기업의 활동과 관련된 모든 간접적인 배출량</li>
    </ul>
  );

  const [otReport, setOtReport] = useState({ count: 0, sell: 0, pur: 0, amt: 0, upDown: '' });
  useEffect(() => {
    EmissionService.getInstance().getOtTradeReport()
    .then((response: any) => {
      if (response && ResponseResultCode.Y === response.resultCode && response.data) {
        if (response.data.upDown === 0) {
          setOtReport({ ...otReport, upDown: '' });
        } else if (response.data.upDown === 1) {
          setOtReport({ ...otReport, upDown: 'up' });
        } else {
          setOtReport({ ...otReport, upDown: 'down' });
        }

        setOtReport({
          ...otReport,
          count: response.data.tradeCount ?? 0,
          sell: response.data.sellCount ?? 0,
          pur: response.data.purCount ?? 0,
          amt: response.data.tradeAmt ?? 0,
        });
      }
    })
  }, []);


  return (
    <>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="icon-box-wrap7 mgt5">
            <ul>
              <li className="col-md-4">
                <div className="c1">
                  <strong>
                    연간 누적 온실가스 배출량<em
                    className="update">{lastUpdateDate} 기준</em>
                  </strong>
                  <div className="w100">
                    <span className="disB w100 ac">
                      <CountUp end={Number(total) ?? 0} duration={0.5} decimals={2}>
                        {({ countUpRef }) => <em ref={countUpRef} />}
                      </CountUp>
                      tCO2eq
                    </span>
                  </div>
                </div>
              </li>
              <li className="col-md-4">
                <div className="c2">
                  <strong>
                    SCOPE별 배출량(tCO2eq)
                    <ArcTooltip tooltipContent={tooltipContent}></ArcTooltip>
                  </strong>
                  <div>
                    <HighchartsReact highcharts={Highcharts} options={graphTypeA} />
                  </div>
                </div>
              </li>
              <li className="col-md-4">
                <div className="c2">
                  <strong>목표대비 배출량(tCO2eq)</strong>
                  <div className="img">
                    <span>
                      <span>목표</span>
                      <CountUp end={Number(goalEmissions) ?? 0} duration={0.5} decimals={2}>
                        {({ countUpRef }) => <em ref={countUpRef} />}
                      </CountUp>
                      <strong className="down">
                        <CountUp end={Number(goalPercent) ?? 0} duration={0.5}>
                          {({ countUpRef }) => <em ref={countUpRef} />}
                        </CountUp>%
                      </strong>
                    </span>
                    <img src={require("assets/images/sub/count_icon_img4.png")} alt="" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-wrap">
        <div className="col-md-6">
          <div className="cont">
            <h3 className="titT1">
              온실가스 감축사업 현황<em className="update">전일기준</em>
            </h3>
            <div className="round-table round-table3">
              <table>
                <caption></caption>
                <colgroup>
                  <col span={4} style={{ width: "33.33%" }} />
                </colgroup>
                <thead>
                <tr>
                  <th scope="col" className="rd-tbl-c1">
                    감축사업 신청
                  </th>
                  <th scope="col" className="rd-tbl-c1">
                    승인심사
                  </th>
                  <th scope="col" className="rd-tbl-c1">
                    등록 완료
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="rd-tbl-b1 ac">
                    <strong>
                      <em className="font-FB fs18 mgr5">
                        <span className="fs26">0</span>
                      </em>
                      건
                    </strong>
                  </td>
                  <td className="rd-tbl-b1 ac">
                    <strong>
                      <em className="font-FB fs18 mgr5">
                        <span className="fs26">0</span>
                      </em>
                      건
                    </strong>
                  </td>
                  <td className="rd-tbl-b1 ac">
                    <strong>
                      <em className="font-FB fs18 mgr5">
                        <span className="fs26">0</span>
                      </em>
                      건
                    </strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="cont">
            <h3 className="titT1">
              장외거래 현황<em className="update">전일기준</em>
            </h3>
            <div className="round-table round-table3">
              <table>
                <caption></caption>
                <colgroup>
                  <col span={4} style={{ width: "25%" }} />
                </colgroup>
                <thead>
                <tr>
                  <th scope="col" className="rd-tbl-c1">
                    총 거래건수
                  </th>
                  <th scope="col" className="rd-tbl-c1">
                    판매 건수
                  </th>
                  <th scope="col" className="rd-tbl-c1">
                    구매 건수
                  </th>
                  <th scope="col" className="rd-tbl-c1">
                    거래 금액
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="rd-tbl-b1 ac">
                    <strong>
                      <em className="font-FB fs18 mgr5">
                        <span className="fs26">{otReport.count}</span>
                      </em>건
                    </strong>
                  </td>
                  <td className="rd-tbl-b1 ac">
                    <strong>
                      <em className="font-FB fs18 mgr5">
                        <span className="fs26">{otReport.sell}</span>
                      </em>건
                    </strong>
                  </td>
                  <td className="rd-tbl-b1 ac">
                    <strong>
                      <em className="font-FB fs18 mgr5">
                        <span className="fs26">{otReport.pur}</span>
                      </em>건
                    </strong>
                  </td>
                  <td className="rd-tbl-b1 ac">
                    <strong className={otReport.upDown}>
                      <em className={`font-FB fs18 mgr5 up-down-count up-down-count-mark ${otReport.upDown}`}>
                        <span className="fs26">{parseNumberWithComma(otReport.amt)}</span>
                      </em>원
                    </strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="col-wrap">
        <div className="col-md-6">
          <ChartGhgsByMonth data={chartBData} />
        </div>
        <div className="col-md-6">
          <ChartGhgsByFacility data={chartCData} />
        </div>
      </div>
    </>
  );
}
