import {CrudConstant} from "constants/crudConstant";

export class ExchangeRateModel {
    baseYmd?: string;
    curcyCd?: string;
    ttb?: number;
    tts?: number;
    cntryNm?: string;
    curcyUnit?: string;
    curcySymb?: string;
    ttbBfr?: number;
    ttsBfr?: number;
    ttbVs?: number;
    ttsVs?: number;
    ttbVsRt?: number;
    ttsVsRt?: number;

    constructor(data?: Partial<ExchangeRateModel>) {
        Object.assign(this, data);
    }

    toMap(): Record<string, any> {
        return {
            baseYmd: this.baseYmd,
            curcyCd: this.curcyCd,
            ttb: this.ttb,
            tts: this.tts,
            cntryNm: this.cntryNm,
            curcyUnit: this.curcyUnit,
            curcySymb: this.curcySymb,
            ttbBfr: this.ttbBfr,
            ttsBfr: this.ttsBfr,
            ttbVs: this.ttbVs,
            ttsVs: this.ttsVs,
            ttbVsRt: this.ttbVsRt,
            ttsVsRt: this.ttsVsRt
        };
    }

    static fromMap(data: Record<string, any>): ExchangeRateModel {
        return new ExchangeRateModel(data);
    }
}

