import React, {useState} from "react";
import {DocuBizPlnd} from "model/cp/DocuBizPlnd";
import {FileInfoModel} from "model/common/fileInfoModel";

export const BusinessFormPageType = {
  INFO: "INFO",
  EXCEL: "EXCEL",
} as const;
export type BusinessFormPageType = (typeof BusinessFormPageType)[keyof typeof BusinessFormPageType];

export interface BusinessFormContextProps {
  form?: DocuBizPlnd;
  businessFile?: File | FileInfoModel;
}

const businessFormDefaultValue: BusinessFormContextProps = {
  form: undefined,
  businessFile: undefined,
};

export const BusinessFormContext = React.createContext({
  data: businessFormDefaultValue,
  setData: (value: BusinessFormContextProps) => {}
});

export function BusinessFormProvider(props: any) {
  const setData = (value: BusinessFormContextProps) => {
    setState({...state, data: value})
  }

  const initState = {data: businessFormDefaultValue, setData: setData};
  const [state, setState] = useState(initState);

  return (<BusinessFormContext.Provider value={state}>
    {props.children}
  </BusinessFormContext.Provider>);
}