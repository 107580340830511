import React from "react";
import {Helmet} from "react-helmet-async";
import {useAppSelector} from "../../../stores/hook";

export default function PageTitle(props: any) {
  const currentPage = useAppSelector(state => state.userMenu.currentPage);
  return (
    <>
      <Helmet>
        <title>{currentPage ?? 'E-Market 플랫폼'}</title>
      </Helmet>
      <h2 className="ht-tit">{currentPage}</h2>
    </>
  );
}