import ArcButton from "components/arc/ArcButton";
import ArcDownload, { dataSourceProps } from "components/arc/ArcDownload";
import AppAlert from "components/common/AppAlert";
import { JsxElementConstant } from "constants/jsxElementConstant";
import { FileInfoModel } from "model/common/fileInfoModel";
import { DocuMoni } from "model/cp/DocuMoni";
import { ResponseResultCode } from "model/response-api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { openModal } from "reducers/modalSlice";
import { showAndHideSpinner } from "reducers/spinnerSlice";
import { DocuEvdnService } from "services/cp/DocuEvdnService";
import { MhdlgService } from "services/cp/MhdlgService";

export default function MonitoringDetail() {

    const [docuMoniDetail, setDocuMoniDetail] = useState<DocuMoni | null>(null);
    const { docId } = useParams<{ docId: string }>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const file = docuMoniDetail?.file ? docuMoniDetail.file : null;
    const fileList: dataSourceProps[] = file ? (file.files ?? []).map((m: FileInfoModel) => {
        return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
    }) : [];


    useEffect(() => {
        async function fetchDocuMoniDetail() {
            try {
                if (docId) {
                    const response = await MhdlgService.getInstance().getDocuMoniDetail(docId);
                    if (response && response.resultCode === ResponseResultCode.Y && response.data) {
                        setDocuMoniDetail(response.data);
                    }
                }
            } catch (error) {
            }
        }

        fetchDocuMoniDetail();

        return () => { };
    }, [docId]);

    function handleClickDelete() {
        dispatch(
            openModal({
                path: "ot/confirm/apply/delete/confirm",
                target: AppAlert,
                wrapStyle: { width: "20rem" },
                confirmProps: {
                    type: "delete",
                    title: t("confirm.label.message.delete"),
                    onConfirm: deleteDocuMoni,
                },
            })
        );
    }

    const deleteDocuMoni = async () => {
        try {
            const response = await MhdlgService.getInstance().deleteMoni(docuMoniDetail!);
            if (response) {
                if (ResponseResultCode.Y === response.resultCode) {
                    navigate(`/cp/reduce/history/monitoring`);
                    toast.success(t("common.message.success.delete", { title: t("완료") }));
                } else {
                    toast.error(t("common.message.error.data"));
                }
            } else {
                toast.error(t("common.message.error.system"));
            }
        } catch (error) {
            toast.error(t("common.message.error.system"));
        }
    };

    async function handleClickDownload(selectFile: dataSourceProps) {
        if (file) {
            const selectedFile = (file.files ?? []).find(f => f.fileNm === selectFile.saveName);
            if (selectedFile) {
                dispatch(showAndHideSpinner(true));
                await DocuEvdnService.getInstance().downloadFile(selectedFile);
                dispatch(showAndHideSpinner(false));
            }
        }
    }

    const handleCancel = () => {
        navigate(`/cp/reduce/history/monitoring`);
    };

    return (
        <>
            <div className="container">
                <section className="section active">
                    <div className="tab-st-box1">
                        <ul className="tab-st1">
                            <li>
                                <a
                                    href={JsxElementConstant.hrefIsNull}
                                    onClick={() => {
                                        navigate(`/cp/reduce/history/business`);
                                    }}
                                >
                                    <span>사업계획서</span>
                                </a>
                            </li>
                            <li className="on">
                                <a
                                    href={JsxElementConstant.hrefIsNull}
                                    onClick={() => {
                                        navigate(`/cp/reduce/history/monitoring`);
                                    }}
                                >
                                    <span>모니터링</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-wrap">
                        <div className="col-md-12">
                            <div className="cont">
                                <h3 className="titT1">자료 등록</h3>
                                <div className="tbl-st2">
                                    <table>
                                        <caption>방법론 등록</caption>
                                        <colgroup>
                                            <col style={{ width: "15%" }} className="tbl-bg-gray" />
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "15%" }} className="tbl-bg-gray" />
                                            <col style={{ width: "15%" }} />
                                            <col style={{ width: "15%" }} className="tbl-bg-gray" />
                                            <col style={{ width: "15%" }} />
                                        </colgroup>
                                        <tbody className="al">
                                            <tr>
                                                <td>사업계획서 명	</td>
                                                <td colSpan={5} className="al">
                                                    {docuMoniDetail?.rlvnDataNm}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>적용 사업계획서</td>
                                                <td colSpan={5} className="al">

                                                    {docuMoniDetail?.bizNm}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>설명</td>
                                                <td colSpan={5} className="al">
                                                    {docuMoniDetail?.explCtt}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>첨부파일</td>
                                                <td colSpan={5} className="al">
                                                    <ArcDownload dataSource={fileList} onDownload={handleClickDownload} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="btns ar">
                                    <ArcButton type="delete" hasIcon={true} onClick={handleClickDelete} />
                                    <ArcButton
                                        type="modify"
                                        hasIcon={true}
                                        onClick={() => {
                                            navigate(`/cp/reduce/history/monitoring/insert`, { state: JSON.stringify(docuMoniDetail) });
                                        }}
                                    />
                                    <ArcButton type="text" text={"목록"} onClick={handleCancel} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}