import ArcGrid, {
  ArcGridCheckResult,
  CustomBackGround,
  CustomBackGround2,
  CustomBackGround3,
  CustomTitleRenderer,
  GridApi,
  GridColumn
} from 'components/arc/ArcGrid'
import AppAlert from 'components/common/AppAlert';
import { t } from 'i18next';
import { ActvMgnl } from 'model/cp/actvMgnl';
import { ResponseResultCode } from 'model/response-api';
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { openModal } from 'reducers/modalSlice';
import { ActvScopService } from 'services/cp/ActvScopService';
import ArcButton from "../../../../components/arc/ArcButton";
import { parseNumberWithCommaDecimal } from 'utils/stringUtil';

export default function ActiveDataList() {

  const checkedRow = (data: ArcGridCheckResult) => { };
  const gridRef: any = useRef<typeof ArcGrid>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [canDrawGrid, setCanDrawGrid] = useState<boolean>(false);
  const [actvGrid, setActvGrid] = useState<ReactElement | null>();
  const [usingScop, setUsingScop] = useState<ActvMgnl>();

  const gridDatasource: GridApi = {
    readData: {
      url: "/cp/actv/activeData",
      method: "GET",
      initParams: {
      },
    },
  };

  function handleClickDetail(event: any) {
    const rowData = gridRef.current.getInstance().getRow(event.rowKey);
    if (rowData) {
      navigate(`/cp/inventory/activity/scope1/${rowData.fuflYy}`);
    }
  }

  function createData() {
    navigate(`/cp/inventory/activity/scope1/${"create"}`);
  }

  async function handleDel() {
    const chkArray = gridRef.current.getInstance().getCheckedRows();
    if (chkArray.length > 0) {
      gridRef.current.getInstance().removeCheckedRows();
      try {
        const response = await ActvScopService.getInstance().deleteActvScop(chkArray);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t('common.message.success.delete', { title: t('') }));
          } else {
            toast.error(t('common.message.error.data'));
          }
        } else {
          toast.error(t('common.message.error.system'));
        }
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  }

  const deleteData = () => {
    const checkedNum = gridRef.current.getInstance().getCheckedRows().length;
    if (checkedNum > 0) {
      dispatch(openModal({
        path: 'cp/confirm/apply/delete/confirm',
        target: AppAlert,
        wrapStyle: { width: "20rem" },
        confirmProps: {
          type: 'delete',
          title: `선택된 ${checkedNum}개의 활동자료를 ${t('confirm.label.message.delete')}`,
          onConfirm: handleDel,
        }
      }));
    } else {
      toast.warning(t('common.message.warn.noDeleteTarget'));
    }
  }

  useEffect(() => {
    async function callInitialUserScope() {
      const response = await ActvScopService.getInstance().getCheckScope();
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          setUsingScop(response.data as ActvMgnl);
        }
      }
    }

    if (!usingScop) {
      callInitialUserScope();
    }

    if (usingScop) {
      setCanDrawGrid(true);
    }
  }, [usingScop])

  useEffect(() => {
    if (canDrawGrid) {
      const columns: GridColumn[] = [
        {
          header: "연도",
          name: "fuflYy",
          align: "center",
          whiteSpace: "normal",
          renderer:{
            type:CustomTitleRenderer
          },
        },
        {
          header: t("cp.roadMap.reduceGoal.header.totalEmsnQnt"),
          name: "totalEmsnQnt",
          align: "right",
          formatter: (e: any) => e.value != null ? Number(e.value.toFixed(2)).toLocaleString() : "",
        },
      ];

      if ("Y" === usingScop?.scopN1UseYn) {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop1EmsnQnt"),
          name: "totalScop1EmsnQnt",
          align: "right",
          formatter: (e: any) => {
            return e.value != null ? Number(e.value.toFixed(2)).toLocaleString() : ""
          },
        });
      } else {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop1EmsnQnt"),
          name: "totalScop1EmsnQnt",
          align: "right",
          renderer: { type: CustomBackGround },
        });
      }

      if ("Y" === usingScop?.scopN2UseYn) {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop2EmsnQnt"),
          name: "totalScop2EmsnQnt",
          align: "right",
          formatter: (e: any) => {
            return e.value != null ? Number(e.value.toFixed(2)).toLocaleString() : ""
          },
        });
      } else {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop2EmsnQnt"),
          name: "totalScop2EmsnQnt",
          align: "right",
          renderer: { type: CustomBackGround2 },
        });
      }

      if ("Y" === usingScop?.scopN3UseYn) {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop3EmsnQnt"),
          name: "totalScop3EmsnQnt",
          align: "right",
          formatter: (e: any) => {
            return e.value != null ? Number(e.value.toFixed(2)).toLocaleString() : ""
          },
        });
      } else {
        columns.push({
          header: t("cp.roadMap.reduceGoal.header.totalScop3EmsnQnt"),
          name: "totalScop3EmsnQnt",
          align: "right",
          renderer: { type: CustomBackGround3 },
        });
      }

      columns.push(
        {
          header: "매출액(억원)",
          name: "totSalesAmt",
          align: "right",
          whiteSpace: "normal",
          formatter: function (e: { value: any }) {
            if (e.value !== null && e.value !== undefined) {
              return parseNumberWithCommaDecimal(e.value);
            }
            return "";
          },
        }
      )

      setActvGrid(
        <ArcGrid
          gridRef={gridRef}
          datasource={gridDatasource}
          columns={columns}
          rowHeaders={["checkbox", "rowNum"]}
          usePagination={false}
          useGridInfoHeader={true}
          onCheckRow={checkedRow}
          onClickRow={handleClickDetail}
          isTest={true}
        />
      );
    }
  }, [canDrawGrid]);

  return (
    <section>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <div className="tit-btns-wrap">
              <h3 className="titT1">활동자료 목록</h3>
            </div>
            {actvGrid}
            <div className="btns ar">
              <ArcButton type="delete" hasIcon={true} onClick={deleteData} />
              <ArcButton type="regist" hasIcon={true} onClick={createData} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
