import {UserAuthModel} from "./UserAuthModel";
import {UserMenuModel} from "./UserMenuModel";

export class UserInfoModel {
  accessTokenExpiresAt?: Date;
  userId?: string;
  userNm?: string;
  userEmailAddr?: string;
  aprYn?: string;
  useYn?: string;
  auths?: UserAuthModel[];
  menus?: UserMenuModel[];

  constructor(userResponse: any) {
    if (userResponse != null) {
      this.accessTokenExpiresAt = userResponse.accessTokenExpiresAt;
      this.userId = userResponse.userId;
      this.userNm = userResponse.userNm
      this.aprYn = userResponse.aprYn;
      this.useYn = userResponse.useYn;
      this.userEmailAddr = userResponse.userEmailAddr;
      this.auths = userResponse.auths.map((auth: any) => new UserAuthModel(auth.authId, auth.authNm));
      this.menus = [];
    }
  }
}