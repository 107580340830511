import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {useState} from "react";
export default function ArcImageEditor () {

  const [content, setContent] = useState('');

  const handleChange = (value : any) => {
    setContent(value);
  };

  return <>
    <ReactQuill
        theme="snow" // 테마 설정 (snow, bubble 중 선택 가능)
        value={content}
        onChange={handleChange}
        style={{ height: '300px'}}
      />
  </>
}