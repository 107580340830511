import {useTranslation} from "react-i18next";
import {billPageType} from "pages/cb/bill/billList/BillListBase";
import React, {useEffect, useState} from "react";
import ArcButton from "components/arc/ArcButton";
import BillListSaler from "pages/cb/bill/billList/BillListSaler";
import BillListHeader from "pages/cb/bill/billList/BillListHeader";
import BillListPchsr from "pages/cb/bill/billList/BillListPchsr";

const T_ACTIVE = 'billListTotalActiveTab';
export default function BillListTotal() {
  const {t} = useTranslation();

  const initActive = (): billPageType => {
    return (localStorage.getItem(T_ACTIVE) as billPageType|null)??'s';};
  const [active, setActive] = useState<billPageType>(initActive);

  useEffect(() => {
    localStorage.setItem(T_ACTIVE, active);
  }, [active]);

  return (<section className="section active">
    <div className="col-wrap">
      <div className="col-md-12">

        <div className="tab-st-box1">
          <ul className="tab-st1">
            <li className={active === 's' ? "on" : ""}>
              <ArcButton onClick={() => setActive('s')}
                         text={t('cb.bill.sale')} />
            </li>
            <li className={active === 'p' ? "on" : ""}>
              <ArcButton onClick={() => setActive('p')}
                         text={t('cb.bill.purchase')} />
            </li>
          </ul>
        </div>

        {'s' === active && (
          <BillListSaler emksReqdCd={0}>
            <BillListHeader type='s' emksReqdCd={0} />
          </BillListSaler>)}
        {'p' === active && (
          <BillListPchsr emksReqdCd={0}>
            <BillListHeader type='p' emksReqdCd={0} />
          </BillListPchsr>)}
      </div>
    </div>
  </section>);
}