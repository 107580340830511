import Api from "services/common/Api";
import ContractModel from "model/cb/contractModel";
import {FileInfoModel} from "model/common/fileInfoModel";
import ContractPPAModel from "model/cb/contractPPAModel";

export class ContractService extends Api {
  private static service: ContractService;
  private static CRUD_URL: string = process.env.REACT_APP_URL_CB + "/cntr/cntr";
  private static STATUS: string = process.env.REACT_APP_URL_CB + "/cntr/status";
  private static OTRAN: string = process.env.REACT_APP_URL_CB + "/cntr/otran";
  private static HIS: string = process.env.REACT_APP_URL_CB + "/cntr/history";
  private static FILE: string = process.env.REACT_APP_URL_CB + "/files/file";
  private BROKER: string = process.env.REACT_APP_URL_CB + "/cntr/trd";
  private CORP: string = process.env.REACT_APP_URL_CB + "/cntr/corp";
  private REJECT: string = process.env.REACT_APP_URL_CB + "/cntr/ppa/reject"
  private COMPLETE: string = process.env.REACT_APP_URL_CB + "/cntr/ppa/complete";

  public static getInstance(): ContractService {
    if(!ContractService.service) {
      ContractService.service = new ContractService();
    }
    return ContractService.service;
  }

  public async detail(cntrId: string) {
    return await super.get<ContractModel>(ContractService.CRUD_URL, {cntrNo: cntrId});
  }

  public async save(form: FormData) {
    return await super.post<ContractModel>(ContractService.CRUD_URL, form);
  }

  public async updateState(data: FormData) {
    return await super.post<ContractModel>(ContractService.STATUS, data);
  }

  public async updateOtran(data: ContractModel) {
    return await super.put<ContractModel>(ContractService.OTRAN, data);
  }

  public async history(data: ContractModel) {
    return await super.get<ContractModel[]>(ContractService.HIS, {cntrNo: data.cntrNo});
  }

  public async downloadFile(file: FileInfoModel) {
    const param = {
      filePath: file.pthNm,
      fileName: file.fileNm,
      originalFileName: file.realNm
    }
    return await super.download(ContractService.FILE, param);
  }

  public async saveBroker(form: FormData) {
    return await super.post<ContractModel>(this.BROKER, form);
  }

  public async saveCorp(form: FormData) {
    return await super.post<ContractModel>(this.CORP, form);
  }

  public async updateReject(form: ContractModel) {
    return await super.put<ContractModel>(this.REJECT, form);
  }

  public async updateCompletePpa(cntrNo: string) {
    return await super.put<ContractModel>(this.COMPLETE, cntrNo);
  }

  public async brokerInstt(data: ContractPPAModel) {
    return await super.put<ContractPPAModel>(this.BROKER + "/instt", data);
  }

  public async corpInstt(data: ContractPPAModel) {
    return await super.put<ContractPPAModel>(this.CORP + "/instt", data);
  }

  public async saveBrokerSign(form: FormData) {
    return await super.post<ContractPPAModel>(this.BROKER + "/sign", form);
  }

  public async saveCorpSign(form: FormData) {
    return await super.post<ContractPPAModel>(this.CORP + "/sign", form);
  }
}