import React, {CSSProperties} from 'react';
import {createPortal} from "react-dom";
import {closeModal, ModalType} from "reducers/modalSlice";
import {useAppDispatch, useAppSelector} from "stores/hook";
import {JsxElementConstant} from "../../constants/jsxElementConstant";

export default function AppModalContainer() {
  const dispatch = useAppDispatch();
  const allModals = useAppSelector((state) => state.modal.modals);

  const handleClickClose = () => {
    dispatch(closeModal());
  }

  const renderModal = allModals?.map((modal: ModalType, modalIndex: number) => {
    const backgroundStyle: CSSProperties | undefined = (allModals.length-1) === modalIndex ? undefined : {background: 'rgba(0,0,0,0)'};
    const ModalComponent = modal.target;
    if('AppAlert' === ModalComponent.name || 'c' === ModalComponent.name) {
      return (
        <div key={modal.path} className="layer-bg fade in" style={backgroundStyle}>
          <div className="layer-wrap" style={modal.wrapStyle}>
            <ModalComponent {...modal.confirmProps} />
          </div>
        </div>
      );
    } else if('ArcInicisModal' === ModalComponent.name) {
      return <ModalComponent key={modal.path} {...modal.modalProps} />
    } else {
      return (
        <div key={modal.path} className="layer-bg fade in" style={backgroundStyle}>
          <div className="layer-wrap" style={modal.wrapStyle}>
            <ModalComponent {...modal.modalProps}/>
            <a className="btn-close-layer" href={JsxElementConstant.hrefIsNull} onClick={handleClickClose}>
              <i className="xi-close" aria-hidden="true"></i>
              <span className="hid">닫기</span>
            </a>
          </div>
        </div>
      );
    }
  });

  const modalRoot = document.getElementById('modal') as HTMLElement;
  return createPortal(<>{renderModal}</>, modalRoot);
}