import {Link, useNavigate} from "react-router-dom";
import Icon1 from "../../../assets/images/sub/icon_sign1.png";
import Icon2 from "../../../assets/images/sub/icon_sign2.png";
import Icon3 from "../../../assets/images/sub/icon_sign3.png";
import ArcButton, { ButtonColor, ButtonShape, ButtonSize } from "components/arc/ArcButton";
import { useDispatch } from "react-redux";
import { setSignUpInfo } from "reducers/signUpSlice";
import { UserMemberModel } from "model/ua/UserMemberModel";

export default function SignUpType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function click(emksMbrsClCd: number) {
    const userInfo = new UserMemberModel();
    userInfo.emksMbrsClCd = emksMbrsClCd.toString();
    dispatch(setSignUpInfo(userInfo));
    navigate(`/sign-up/1`);
  }

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont member-h">
            <div className="tit-step">
              <h1 className="titT2">회원가입</h1>
            </div>
            <div className="select-box">
              <ul className="li-w30">
                <li>
                  <Link to="" onClick={(e) => { e.preventDefault(); click(1); }}>
                    <div className="box-style1 ac">
                      <div className="icon-bg">
                        <img src={Icon1} alt="" />
                      </div>
                      <p className="tit">일반 회원</p>
                      <p>산단 내 입주기업 혹은 모니터링/제어가능한<br />전력 자원을 보유중인 고객은 선택해주세요</p>
                      <div className="btns">
                        <ArcButton type={"custom"} color={ButtonColor.btn2} text="가입하기" title="일반 회원 가입" shape={ButtonShape.normal} size={ButtonSize.large} />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={(e) => { e.preventDefault(); click(2); }}>
                    <div className="box-style1 ac">
                      <div className="icon-bg">
                        <img src={Icon2} alt="" />
                      </div>
                      <p className="tit">중개사업 회원</p>
                      <p>산단 내 유연자원을 바탕으로 분산에너지 관련<br />사업을 추진하고자 하는 고객은 선택해주세요</p>
                      <div className="btns">
                        {/* <p className="btn-style2 btn-lage">가입하기</p> */}
                        <ArcButton type={"custom"} color={ButtonColor.btn2} text="가입하기" title="중개사업 회원 가입" shape={ButtonShape.normal} size={ButtonSize.large} />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="" onClick={(e) => { e.preventDefault(); click(3); }}>
                    <div className="box-style1 ac">
                      <div className="icon-bg">
                        <img src={Icon3} alt="" />
                      </div>
                      <p className="tit">장외거래 회원</p>
                      <p>탄소배출권, RE100 관련 장외거래를<br />희망하는 고객은 선택해주세요.</p>
                      <div className="btns">
                        <ArcButton type={"custom"} color={ButtonColor.btn2} text="가입하기" title="장외거래 회원 가입" shape={ButtonShape.normal} size={ButtonSize.large} />
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}