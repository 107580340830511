import { AuditModel } from "model/common/auditModel";
import { FileModel } from "model/common/fileModel";

export class Mhdlg extends AuditModel {
  mhdlgId?: string;
  regDate?: Date;
  regrId?: string;
  chgDate?: Date;
  chgrId?: string;

  mhdlgNm?: string;
  fareaNm?: string | null;
  scgNm?: string | null;
  unqNo?: string | null;
  vldStrtYmd?: string | null;
  explCtt?: string | null;
  repAtflId?: string | null;
  itmNm?: string | null;
  kwrdNm?: string | null;

  fileRespons: any;
  file?: FileModel;

  bizFile?: FileModel;
  bizFileRespons: any;

  moniFile?: FileModel;
  moniFileRespons: any;
}
