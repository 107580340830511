import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {ApiUrlUtil} from "utils/apiUrlUtil";
import {ResponseApi, ResponseResultCode} from "model/response-api";

const initialState: any = {
  currentPage: "",
  currentMenu: undefined,
  userMenu: [],
};

interface UserMenuModel {
  menuId: string;
  uppoMenuId: string;
  menuNm: string;
  menuUrlAddr: string;
  subMenus: UserMenuModel[]
}

export interface EmarketMenuModel {
  menuId: string;
  upperMenuId: string;
  menuName: string;
  url: string;
  childMenus: EmarketMenuModel[]
}

export const getUserMenu = createAsyncThunk("menu/userMenu", async() => {
  const url = process.env.REACT_APP_URL_CO + '/menu/access';
  const response = await axios.get(ApiUrlUtil.buildQueryString(url, {emksSysClCd: 1}));
  if(response && "OK" === response.statusText) {
    return response.data;
  }
});

const userMenuSlice = createSlice({
  name: "userMenu",
  initialState,
  reducers: {
    updateUserMenu: (state, action) => {
      state.userMenu = new Set(state.concat(action.payload));
    },
    setCustomPageTitle: (state, action) => {
      state.currentPage = action.payload;
    },
    setCurrentMenu: (state, action) => {
      state.currentMenu = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserMenu.pending, () => {})
    .addCase(getUserMenu.fulfilled, (state, action: any) => {
      const response = action.payload as ResponseApi<UserMenuModel[]>;
      if(response && ResponseResultCode.Y === response.resultCode) {
        state.userMenu = generateMenu(response.data);
      }
    })
    .addCase(getUserMenu.rejected, (state: any) => {
      state.userMenu = [];
    });
  },
});

function generateMenu(userMenu: UserMenuModel[]): EmarketMenuModel[] {
  return userMenu.map((m: UserMenuModel) => {
    return {
      menuId: m.menuId,
      upperMenuId: m.uppoMenuId,
      menuName: m.menuNm,
      url: m.menuUrlAddr,
      childMenus: generateMenu(m.subMenus)
    } as EmarketMenuModel
  });
}

export default userMenuSlice.reducer;
export const { updateUserMenu, setCustomPageTitle, setCurrentMenu } = userMenuSlice.actions;
