import Api from "../common/Api";
import {RdmpCondModel} from "../../model/rp/RdmpCondModel";
import * as process from "process";
import {ScnroModel} from "../../model/rp/ScnroModel";
import Rdmp from "../../pages/rp/rdmp/Rdmp";

export class RdmpCondService extends Api {

  private static service: RdmpCondService;
  private static GET_URL: string = process.env['REACT_APP_URL_RP'] + '/rdmp/detail';
  private static GET_QNT_URL: string = process.env['REACT_APP_URL_RP'] + '/qnt/qntDetail'
  private static INSERT_URL: string = process.env['REACT_APP_URL_RP'] + '/rdmp/insert';

  private static GET_LATEST_SCNRO_URL: string = process.env['REACT_APP_URL_RP'] + '/scnro/detailLatest';
  private static GET_STATUS: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/conSumStatus';

  private static COUNT_URL: string = process.env['REACT_APP_URL_RP'] + '/rdmp/rdmpCount';
  private static COUNT_UP_URL: string = process.env['REACT_APP_URL_RP'] + '/rdmp/rdmpCountUp';

  private static YEARLY_QNT_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/yearlyQnt';
  private static GET_PERCENT_URL: string = process.env['REACT_APP_URL_RP'] + '/rdmp/percent';

  public static getInstance(): RdmpCondService {
    if(!RdmpCondService.service) {
      RdmpCondService.service = new RdmpCondService();
    }
    return RdmpCondService.service;
  }

  public async getRdmpCond() {
    return await super.get<RdmpCondModel[]>(RdmpCondService.GET_URL);
  }

  public async getQntDetail() {
    return await super.get<any>(RdmpCondService.GET_QNT_URL);
  }

  public async insertRdmpCond(rdmpCond: RdmpCondModel) {
    return await super.post<RdmpCondModel>(RdmpCondService.INSERT_URL, rdmpCond);
  }

  // 로드맵 메인페이지 최근시나리오
  public async getLatestScnro() {
    return await super.get<ScnroModel>(RdmpCondService.GET_LATEST_SCNRO_URL);
  }

  public async getConsumStatus() {
    return await super.get<any>(RdmpCondService.GET_STATUS);
  }

  // admin 대시보드: RE100 참여 현황
  public async getRdmpCount() {
    return await super.get<any>(RdmpCondService.COUNT_URL);
  }

  // 증가 수
  public async getRdmpCountUp() {
    return await super.get<any>(RdmpCondService.COUNT_UP_URL);
  }

  public async getYearlyQnt(year: string) {
    return await super.get<any>(RdmpCondService.YEARLY_QNT_URL, {year: year});
  }

  // 퍼센트
  public async getPercent(year: string) {
    return await super.get<any>(RdmpCondService.GET_PERCENT_URL, {year: year});
  }
}