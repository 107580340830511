import { Constant } from "constants/constant"
import moment from "moment";

export class CommonUtil {

  public static translate(jobs: any[], value: any, lang: string) {
    const element = jobs.find((e: any) => e.value === value)
    if(!element) return ""
    if (Constant.SOUTH_KOREA === lang) {
      return element.labelKr
    } else if (Constant.ENGLISH === lang) {
      return element.label
    }
  }

  public static setMaxLengthContent(content: string, maxLength: number) {
    if (!content) return ""
    if (maxLength < 0) return ""
    return content.length >= maxLength ? content.substring(0, maxLength) + "..." : content
  }

  public static makeYearArray(strtYear: number, endYear: number) {
    if(strtYear && endYear) {
      return Array.from({length: Number(endYear) - Number(strtYear) + 1}, (_, index) => Number(strtYear) + index);
    }
    return [];
  }

  public static makeDayArray(yearMonth: string) {
    const daysInMonth = moment(yearMonth).daysInMonth();
    return Array.from({length: daysInMonth - 1 + 1}, (_, index) => 1 + index);
  }

  public static getKorNumber(number: number) {
    const koreanNumber = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    const tenUnit = ['', '십', '백', '천'];
    const tenThousandUnit = ['조', '억', '만', ''];
    const unit: number = 10000;

    let answer = '';

    while (number > 0) {
      const mod = number % unit;
      const modToArray = mod.toString().split('');
      const length = modToArray.length - 1;

      const modToKorean = modToArray.reduce((acc, value, index) => {
        const valueToNumber = +value;
        if (!valueToNumber) return acc;
        const numberToKorean = index < length && valueToNumber === 1 ? '' : koreanNumber[valueToNumber];
        return `${acc}${numberToKorean}${tenUnit[length - index]}`;
      }, '');

      answer = `${modToKorean}${tenThousandUnit.pop()}${answer}`;
      number = Math.floor(number / unit);
    }

    return answer;
  }

  public static base64ToFile(base: string, filename: string) {
    let arr = base.split(',');
    if(arr.length > 0) {
      let mimeArray = arr[0].match(/:(.*?);/);
      if(mimeArray && mimeArray.length > 1) {
        let mime = mimeArray[1];
        let bstr = atob(arr[arr.length - 1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while(n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
      }
    }
  }

  public static stringToBase64(string: string) {
    return btoa(string);
  }

  public static blobToFile(blob: Blob, filename: string) {
    if(blob) {
      return new File([blob], filename, {type: blob.type});
    }
  }
}