import EmissionsManagement from "pages/cp/emissionRights/EmissionsManagement";
import MultiEmissionBoard from "pages/cp/analysis/MultiEmissionBoard";
import YyRdcGoalBase from "pages/cp/roadmap/YyRdcGoalBase";
import InventoryBoard from "pages/cp/inventory/basicInfo/Board";
import EmissionsStatus from "../pages/cp/monitoring/Board";
import ComprehensiveStatus from "pages/cp/roadmap/ComprehensiveStatus";
import BoundarySetting from "pages/cp/inventory/BoundarySetting";
import Reference from "pages/cp/inventory/reference/Board";
import Simulation from "pages/cp/analysis/Simulation";
import PriceAnalysis from "pages/cp/emissionRights/PriceAnalysis";
import MethodologyList from "pages/cp/reductionProject/mhdlg/list";
import MhethodologyInsert from "pages/cp/reductionProject/mhdlg/insert";
import MethodologyDetail from "pages/cp/reductionProject/mhdlg/detail";
import ActiveDataList from "pages/cp/inventory/activityData/ActiveDataList";
import ActivityDataBoard from "pages/cp/inventory/activityData/ActiveDataDetail";
import EvdnDocuBoard from "pages/cp/reductionProject/docu/Board";
import EvdnInsert from "pages/cp/reductionProject/docu/EvdnInsert";
import HistoryBoard from "pages/cp/reductionProject/history/Board";
import EvdnDetail from "pages/cp/reductionProject/docu/EvdnDetail";
import MonitoringInsert from "pages/cp/reductionProject/history/MonitoringInsert";
import BusinessDetail from "pages/cp/reductionProject/history/BusinessDetail";
import MonitoringDetail from "pages/cp/reductionProject/history/MonitoringDetail";
import BusinessFormBase from "pages/cp/reductionProject/history/BusinessForm/BusinessFormBase";

export const cpRouter: any = [
  // 온실가스 모니터링
  { path: "/cp/monitoring", element: <EmissionsStatus /> },

  // 인벤토리 관리
  { path: "/cp/inventory/setting", element: <BoundarySetting /> },
  { path: "/cp/inventory/basic", element: <InventoryBoard /> },
  { path: "/cp/inventory/basic/:type?", element: <InventoryBoard /> },
  { path: "/cp/inventory/activity", element: <ActiveDataList /> },
  { path: "/cp/inventory/activity/:type/:scopYear", element: <ActivityDataBoard /> },
  { path: "/cp/inventory/reference/:type?", element: <Reference /> },

  // 로드맵
  { path: "/cp/roadmap/reduceGoal", element: <YyRdcGoalBase /> },
  { path: "/cp/roadmap/status", element: <ComprehensiveStatus /> },

  // 배출권
  { path: "/cp/emiss/management", element: <EmissionsManagement /> },
  { path: "/cp/emiss/price/:type?/:grpTabIndex?/:isinCd?", element: <PriceAnalysis /> },

  // 분석
  { path: "/cp/analysis/multiEmission", element: <MultiEmissionBoard /> },
  { path: "/cp/analysis/simulation", element: <Simulation /> },

  // 온실가스 감축사업
  { path: "/cp/reduce/methodology", element: <MethodologyList /> },
  { path: "/cp/reduce/methodology/detail/:mhdlgId", element: <MethodologyDetail /> },
  { path: "/cp/reduce/methodology/insert", element: <MhethodologyInsert /> },
  { path: "/cp/reduce/docu/:type?", element: <EvdnDocuBoard /> },
  { path: "/cp/reduce/docu/evdnList/insert", element: <EvdnInsert /> },
  { path: "/cp/reduce/docu/evdnList/detail/:docId", element: <EvdnDetail /> },

  // 온실가스 감축사업
  { path: "/cp/reduce/history/:type?", element: <HistoryBoard /> },
  { path: "/cp/reduce/history/business/insert", element: <BusinessFormBase /> },
  { path: "/cp/reduce/history/business/detail/:docId", element: <BusinessDetail /> },
  { path: "/cp/reduce/history/monitoring/insert", element: <MonitoringInsert /> },
  { path: "/cp/reduce/history/monitoring/detail/:docId", element: <MonitoringDetail /> },
];