import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import Grid from "@toast-ui/react-grid";
import TuiGrid from "tui-grid";
import {
  OptRowHeader,
  OptColumn,
  OptColumnHeaderInfo,
  OptComplexColumnInfo,
  OptHeader
} from "tui-grid/types/options";
import { PageOptions } from "tui-grid/types/store/data";
import { CellRendererClass } from "tui-grid/types/renderer"
import { API, APIInfo, ContentType } from "tui-grid/types/dataSource";
import { CellRendererOptions, FormatterProps } from "tui-grid/types/store/column";
import { OptExport } from "tui-grid/types/store/export";
import { useTranslation } from "react-i18next";
import { VariablesConstant } from "constants/variables";
import ArcSelectbox, { SelectboxSize } from "components/arc/ArcSelectbox";
import { HeadersUtil } from "utils/headersUtil";
import ObjectUtil from "utils/objectUtil";
import ArcButton, { ButtonColor, ButtonIcon, ButtonType } from "components/arc/ArcButton";
import TypeUtil from "utils/typeUtil";
import { toast } from "react-toastify";
import i18n, { DefaultTFuncReturn } from "i18next";
import { useAppSelector } from "stores/hook";
import ArcDatePicker, { ArcDatePickerResult } from "components/arc/ArcDatePicker";
import { createRoot } from "react-dom/client";
import moment from "moment";
import InventoryGridComCd, { InventoryGridComCdResult } from "pages/cp/inventory/InventoryGridComCd";
import { isStringNullOrEmpty, parseNumberWithCommaDecimal } from "utils/stringUtil";
import { CodeUtil } from "utils/codeUtil";
import {GridUtil} from "../../utils/gridUtil";


TuiGrid.applyTheme('clean', {
  area: {
    header: {
      background: '#fff',
      border: '#e1e1e1'
    },
    body: {
      background: '#fff'
    }
  },
  row: {
    hover: {
      background: '#f7fcfc'
    }
  },
  cell: {
    header: {
      background: '#fff',
      border: '#e1e1e1',
      showHorizontalBorder: true,
      showVerticalBorder: false
    },
    rowHeader: {
      background: '#fff',
      border: '#e1e1e1',
      showHorizontalBorder: true,
      showVerticalBorder: false
    },
    normal: {
      border: '#e1e1e1',
      showHorizontalBorder: true,
      showVerticalBorder: false
    },
    editable: {
      background: '#fff'
    },
    required: {
      background: '#fff'
    },
    focused: {
      border: undefined
    },
    focusedInactive: {
      border: undefined
    }
  }
});

export interface ArcGridProps {
  gridRef?: MutableRefObject<Grid>;
  id?: string;
  useStaticData?: boolean;
  data?: any[];
  datasource?: GridApi;
  columns: GridColumn[];
  editor?: GridEditor;
  complexHeader?: GridComplexHeader;
  isAlignHeader?: boolean;
  rowHeaders?: rowHeaderType[];
  usePagination?: boolean;
  useGridInfoHeader?: boolean;
  downloadType?: gridExportFormat | GridDownloadOption;
  onClickRow?: (data?: any) => void;
  onCheckRow?: (result: ArcGridCheckResult) => void;
  onMouseOver?: (e: any) => void;
  onMouseOut?: (e: any) => void;
  onDataLoaded?: (event?: any) => void;
  oneTimeBindingProps?: Array<'data' | 'columns' | 'bodyHeight' | 'frozenColumnCount'>;
  isTest?: boolean;
  save?: boolean;
  bodyHeight?: number;
  onDelete?: any;
  scrollY?: boolean;
  scrollX?: boolean;
  onAfterEdit?: (modifiedRows: any) => void;
}

export interface GridApi extends API {
  readData: APIInfo;
  createData?: APIInfo;
  updateData?: APIInfo;
  deleteData?: APIInfo;
  modifyData?: APIInfo;
  rootUrl?: string;
}

export interface GridColumn {
  header: string | DefaultTFuncReturn;
  name?: string;
  width?: number;
  align?: "center" | "left" | "right";
  whiteSpace?: "normal" | "pre" | "nowrap" | "pre-wrap" | "pre-line";
  formatter?: ((props: FormatterProps) => string) | string;
  renderer?: CellRendererOptions;
  editor?: GridEditor;
  onAfterChange?: Function;
  hidden?: boolean;
}

type gridEditorRequestButton = 'SAVE' | 'ADD' | 'DELETE';

export interface GridEditor {
  editableColumns: GridEditorInfo[];
  defaultRowDataSet?: Object;
  requestBtn?: gridEditorRequestButton[];
  isDataHotSwap?: boolean;
}

export interface GridEditorInfo {
  columnName: string;
  editorType: "text" | "number" | "select" | "checkbox" | "radio" | "password" | "time" | 'date' | CellRendererClass;
  options?: GridEditorTextOptions | GridEditorSelectOptions | any;
}

interface GridEditorTextOptions {
  onChange?: (event: any) => void;
  type?: "text" | "number" | "password" | "time" | 'date';
  validation?: GridEditorValidations;
}

interface GridEditorSelectOptions {
  onChange?: (event: any) => void;
  listItems: { text: string; value: any }[];
  validation?: GridEditorValidations;
}

interface GridEditorValidations {
  unique?: boolean;
  dataType?: "string" | "number";
  required?: boolean;
  min?: number;
  max?: number;
  regExp?: RegExp;
  regExpMessage?: string | DefaultTFuncReturn;
  validatorFn?: (value: any, row: any, columnName: any) => void;
}

export type rowHeaderType = "rowNum" | "checkbox" | "noNum" | "radio";

export interface GridButtonControl {
  createButton?: (data: any) => void;
  deleteBtn?: (data: any) => void;
}

export interface GridComplexHeader {
  complexColumns: GridComplexHeaderInfo[];
  height: number;
}

interface GridComplexHeaderInfo {
  header: string | DefaultTFuncReturn;
  name: string;
  childNames: string[];
}

type gridExportFormat = "xlsx" | "csv" | "txt";

export interface GridDownloadOption {
  exportType: gridExportFormat;
  fileName?: string;
}

export interface ArcGridCheckResult {
  checkedRows: any[];
  isChecked: boolean;
  checkedRowData?: any;
}

const AUTH_HEADER = HeadersUtil.getHeadersAuth();
const ROOT_URL = process.env["REACT_APP_MAIN_URL"] ?? "";
const concatDatasourceToRoot = (datasource: GridApi): GridApi => {
  const rootUrl = datasource?.rootUrl ?? ROOT_URL;
  let readReq = {
    readData: {
      url: `${rootUrl}${datasource?.readData.url}`,
      method: 'GET',
      initParams: datasource?.readData.initParams ?? '',
    }
  } as API;

  if (undefined !== datasource?.modifyData) {
    readReq = Object.assign(readReq, {
      modifyData: {
        ...datasource.modifyData,
        url: `${rootUrl}${datasource.modifyData.url}`,
      },
    });
  } else {
    if (undefined !== datasource?.createData) {
      readReq = Object.assign(readReq, {
        createData: {
          ...datasource.createData,
          url: `${rootUrl}${datasource.createData.url}`,
        },
      });
    }
    if (undefined !== datasource?.updateData) {
      readReq = Object.assign(readReq, {
        updateData: {
          ...datasource.updateData,
          url: `${rootUrl}${datasource.updateData.url}`,
        },
      });
    }
    if (undefined !== datasource?.deleteData) {
      readReq = Object.assign(readReq, {
        deleteData: {
          ...datasource.deleteData,
          url: `${rootUrl}${datasource.deleteData.url}`,
        },
      });
    }
  }
  return readReq;
};

function getCustomGridEditorByCellType(cellType: string | CellRendererClass) {
  if (TypeUtil.isString(cellType)) {


    switch (cellType) {
      case "text":
      case "number":
      case "password":
        return CustomGridTextEditor;
      case "time":
        return CustomGridTimeEditor;
      case 'date':
        return CustomGridDateEditor;
      case "select":
      case "radio":
      case "checkbox":
        return cellType;
    }
  } else {
    return cellType;
  }
}

class CustomGridEditor {
  div: HTMLDivElement;
  rowKey: number;
  columnInfo: any;
  grid: TuiGrid;
  value: any;

  constructor(props: any) {
    this.rowKey = props.rowKey;
    this.columnInfo = props.columnInfo;
    this.grid = props.grid;
    this.value = props.value;

    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    div.style.whiteSpace = "normal";
    div.style.position = "relative";
    div.style.top = "50%";
    div.style.left = "50%";
    div.style.transform = "translate(-50%, -50%)";
    if (props.columnInfo.editor.options.cellType === "time" || props.columnInfo.editor.options.cellType === "date") {
      div.style.textAlign = "center";

      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
          .calendar-input .calendar-picker-btn {
            top: 0.8rem;
          }
        `;
      document.head.insertAdjacentElement("beforeend", styleElement);
    }

    this.div = div;
  }

  setElement(el: HTMLInputElement | HTMLSelectElement) {
    this.div.appendChild(el);
  }

  getElement() {
    return this.div;
  }

  validateMessage(errors: any[]) {
    if (errors) {
      for (const error of errors) {
        if (this.columnInfo.name === error.columnName) {
          this.convertErrorCodeToMessage(error.errorInfo);
        }
      }
    }
  }

  convertErrorCodeToMessage(errorInfo: any[]) {
    for (const error of errorInfo) {
      switch (error.code) {
        case "UNIQUE":
          toast.error(i18n.t("common.message.valid.input.unique"));
          break;
        case "REQUIRED":
          toast.error(i18n.t("common.message.valid.input.required"));
          break;
        case "TYPE_NUMBER":
          toast.error(i18n.t("common.message.valid.input.number"));
          break;
        case "REGEXP":
          toast.error(
            this.columnInfo.editor.options.validation.regExpMessage ?? i18n.t("common.message.invalid", { title: "" }));
          break;
        case "MAX":
          toast.error(i18n.t("common.message.valid.input.max", { max: error.max }));
      }
      this.grid.setValue(this.rowKey, this.columnInfo.name, undefined);
    }
  }
}

class CustomGridTimeEditor extends CustomGridEditor {
  calendar: HTMLDivElement;

  constructor(props: any) {
    super(props);
    const calendar = document.createElement("div");
    calendar.classList.add("custom-calendar");

    const fromMoment = moment(moment.now()).add(-1, "year").add(1, "month").startOf("month");
    const endMoment = moment(moment.now()).endOf("month");

    let fromDate: Date | undefined | string;
    let toDate: Date | undefined | string;
    let fromTime: string;
    let toTime: string;

    if (props.value !== undefined && props.value !== null) {
      props.fromDate = props.value.substr(0, 4);
      props.endDate = props.value.substr(4, 8);
      var firstPart = props.value.substr(0, 2);
      var secondPart = props.value.substr(2, 2);
      var thirdPart = props.value.substr(4, 2);
      var fourthPart = props.value.substr(6, 2);
    }

    fromTime = firstPart !== undefined && secondPart !== undefined ? `${firstPart}:${secondPart}` : "00:00";
    toTime = thirdPart !== undefined && fourthPart !== undefined ? `${thirdPart}:${fourthPart}` : "00:00";

    const fromHours = props.fromDate ? parseInt(props.fromDate.substr(0, 2), 10) : parseInt("00", 10);
    const fromMinutes = props.fromDate ? parseInt(props.fromDate.substr(2, 2), 10) : parseInt("00", 10);
    const endHours = props.endDate ? parseInt(props.endDate.substr(0, 2), 10) : parseInt("00", 10);
    const endMinutes = props.endDate ? parseInt(props.endDate.substr(2, 2), 10) : parseInt("00", 10);

    fromMoment.set("hour", fromHours);
    fromMoment.set("minute", fromMinutes);
    endMoment.set("hour", endHours);
    endMoment.set("minute", endMinutes);

    fromDate = fromMoment.format("YYYYMMDDHHmm");
    toDate = endMoment.format("YYYYMMDDHHmm");

    createRoot(this.div).render(
      <ArcDatePicker
        onChange={this.handleChange.bind(this)}
        fromDate={fromDate}
        toDate={toDate}
        selectionMode="range"
        id="searchDate"
        viewType="timeOnly"
        name="searchDate"
        minMaxDateMode="force"
        placeholders={{
          fromDate: "",
          toDate: "",
          fromTime: fromTime,
          toTime: toTime,
        }}
      />
    );
    this.calendar = calendar;
  }

  handleChange(result: ArcDatePickerResult) {
    const { fromTimeString, toTimeString } = result;
    if (fromTimeString && toTimeString) {
      this.value = fromTimeString + toTimeString;
    }
  }

  getValue() {
    return this.value;
  }
}

class CustomGridDateEditor extends CustomGridEditor {
  calendar: HTMLDivElement;

  constructor(props: any) {
    super(props);
    const calendar = document.createElement("div");
    calendar.classList.add("custom-calendar");

    const fromMoment = moment().startOf('month');

    let fromDate: Date | undefined | string;

    if (props.value !== undefined && props.value !== null) {
      props.fromDate = props.value
    }


    const today = new Date();

    const year = props.fromDate ? parseInt(props.fromDate.substring(0, 4)) : today.getFullYear();
    const month = props.fromDate ? parseInt(props.fromDate.substring(4, 6)) : String(today.getMonth() + 1).padStart(2, '0');
    const date = props.fromDate ? parseInt(props.fromDate.substring(6, 8)) : String(today.getDate()).padStart(2, '0');

    fromMoment.set("year", year);
    fromMoment.set("month", Number(month) - 1);
    fromMoment.set("date", Number(date));

    fromDate = fromMoment.format("YYYYMMDDHHmm");

    createRoot(this.div).render(
      <ArcDatePicker
        onChange={this.handleChange.bind(this)}
        fromDate={fromDate}
        id="date"
        name="date"
        minMaxDateMode="force"
      />
    );
    this.calendar = calendar;
  }

  handleChange(result: ArcDatePickerResult) {

    const fromDateString = result.fromDateString;
    if (fromDateString) {

      // let year = fromDateString.substring(0, 4);
      // let month = fromDateString.substring(4, 6);
      // let date = fromDateString.substring(6, 8);
      this.value = fromDateString;
    }
  }

  getValue() {
    return this.value;
  }
}

class CustomGridTextEditor extends CustomGridEditor {
  input: HTMLInputElement;

  constructor(props: any) {
    super(props);
    const input = document.createElement("input");

    const { onChange, type, validation } = props.columnInfo.editor.options;
    if (type) {
      input.type = type;
      if (type === "number") {
        input.type = "number";
        input.value = String(props.value ?? '');

      } else {
        input.value = String(props.value ?? "");
      }
    } else {
      input.type = "text";
      input.value = String(props.value ?? "");
    }

    if (validation?.max) {
      input.maxLength = validation.max;
      input.max = validation.max;
    }

    const viewData = props.grid.store.data.viewData

    // Disable the input only for the specific rowKey
    if (props.columnInfo.name === "mjUnit" || props.columnInfo.name === "tjUnit") {
      const currentRowData = viewData.find((row: any) => row.rowKey === props.rowKey);
      if (currentRowData && !currentRowData.valueMap?.fuelUnitCd?.value?.isNewCode) {
        input.disabled = true;
        if (props.columnInfo.name === "mjUnit") {
          input.value = currentRowData.valueMap?.mjUnit?.formattedValue
        } else if (props.columnInfo.name === "tjUnit") {
          input.value = currentRowData.valueMap?.tjUnit?.formattedValue
        }
      }
    }

    input.addEventListener("change", (e) => {
      e.preventDefault();
      const validateResult = props.grid.validate([props.rowKey])[0];
      if (validateResult) {
        if (validateResult.errors.length > 0) {
          super.validateMessage(validateResult.errors);
        }
      }
      if (onChange) {
        onChange(e);
      }
    });

    this.input = input;
    super.setElement(this.input);
  }

  getValue() {
    return this.input.value;
  }
}

class CustomRowHeaderRowNumRenderer {
  div: HTMLDivElement;

  constructor(props: any) {
    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    this.div = div;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  render(props: any) {
    const { grid, value, } = props;
    const totalCount = grid.store.data.pageOptions.totalCount ?? grid.store.data.rawData.length;
    const currentPage = grid.store.data.pageOptions.page ?? 1;
    const perPage = grid.store.data.pageOptions.perPage ? Number(grid.store.data.pageOptions.perPage) : totalCount;
    this.div.innerText = String(totalCount - ((grid.store.data.pageOptions.useClient ? 0 : (currentPage - 1)) * perPage + value) + 1);
  }
}

class CustomRowHeaderRadioRenderer {
  div: HTMLDivElement;

  constructor(props: any) {
    const div = document.createElement('div');
    div.className = "tui-grid-cell-content";
    this.div = div;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  render(props: any) {
    const { grid, rowKey } = props;

    const input = document.createElement('input');
    input.type = "radio";
    input.className = "form-check-input";
    input.id = `grid-radio-${String(rowKey)}`;
    input.name = 'custom-grid-radio';

    const label = document.createElement('label');
    label.className = "form-check-label ps-2";
    label.htmlFor = `grid-radio-${String(rowKey)}`;
    label.addEventListener('click', () => {
      grid[input.checked ? 'check' : 'uncheck'](rowKey);
    })
    label.append(input);

    const div = document.createElement('div');
    div.className = "form-check";
    div.append(label);

    const span = document.createElement('span');
    span.className = "checkbox-radio-type01";
    span.append(div);

    this.div.append(span);
  }
}

export class UpDownNumberRenderer {
  div: HTMLDivElement;

  constructor(props: any) {
    this.div = document.createElement('div');
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  render(props: any) {
    const decimalPoint = props.columnInfo.renderer?.options?.decimalPoint ?? 2;
    const isAbs = props.columnInfo.renderer?.options?.isAbs ?? false;
    let className = "tui-grid-cell-content";
    let upDownHiddenLabelText = '증감 없음';
    if (props.value && !isNaN(props.value)) {
      if (Number(props.value) > 0) {
        className += " up-down-count up-down-count-mark up";
        upDownHiddenLabelText = '증가';
      } else if  (Number(props.value) < 0) {
        className += " up-down-count up-down-count-mark down";
        upDownHiddenLabelText = '감소';
      }
    }
    this.div.className = className;
    let formattedValue = (props.value ? GridUtil.numberFormatter(props, decimalPoint, isAbs) : '-');
    this.div.innerHTML = `<span>${formattedValue}</span><span class="hid">${upDownHiddenLabelText}</span>`;
  }
}

export class CustomCommonCodeRenderer extends CustomGridEditor {
  div: HTMLDivElement;

  isNewCode: boolean = false;

  comCdExpl: any = '';
  comCdId: any;

  constructor(props: any) {
    super(props);

    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    this.div = div;

    this.comCdExpl = props.value?.comCdExpl;
    this.comCdId = props.value?.comCdId;
    this.isNewCode = props.value?.isNewCode ?? false;

    this.render(props)
  }

  getElement() {
    return this.div;
  }

  getValue() {
    return {
      isNewCode: this.isNewCode,
      comCdExpl: this.comCdExpl,
      comCdId: this.comCdId,
    }
  }

  render(props: any) {
    const { name } = props.columnInfo;
    let { comCd } = props.columnInfo.editor.options;

    const viewData = props.grid.store.data.viewData

    // Disable the input only for the specific rowKey
    const currentRowData = viewData.find((row: any) => row.rowKey === props.rowKey);

    if (currentRowData && props.columnInfo.name === "wstKndCd"
      && !currentRowData.valueMap?.wstStsClCd?.value?.isNewCode
      && (currentRowData.valueMap.wstStsClCd.value === null || currentRowData.valueMap.wstStsClCd.value?.comCdId === undefined || currentRowData.valueMap.wstStsClCd.value?.comCdId === "")) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "wstLwpoKndCd"
      && !currentRowData.valueMap?.wstKndCd?.value?.isNewCode
      && (currentRowData.valueMap.wstKndCd.value === null || currentRowData.valueMap.wstKndCd.value?.comCdId === undefined || currentRowData.valueMap.wstKndCd.value?.comCdId === "")) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "emsnLwpoKndName"
      && !currentRowData.valueMap?.emsnKndName?.value?.isNewCode
      && (currentRowData.valueMap.emsnKndName.value === null || currentRowData.valueMap.emsnKndName.value?.comCdId === undefined || currentRowData.valueMap.emsnKndName.value?.comCdId === "")) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "wstKndCd"
      && !currentRowData.valueMap?.wstStsClCd?.value?.isNewCode
      && currentRowData.valueMap.wstStsClCd.value) {

      comCd = comCd.filter((item: any) => item.uppoCdId === currentRowData.valueMap.wstStsClCd.value.comCdId)
    }

    if (currentRowData && props.columnInfo.name === "wstLwpoKndCd"
      && !currentRowData.valueMap?.wstKndCd?.value?.isNewCode
      && currentRowData.valueMap.wstKndCd.value) {

      comCd = comCd.filter((item: any) => item.uppoCdId === currentRowData.valueMap.wstKndCd.value.comCdId)
    }

    if (currentRowData && props.columnInfo.name === "emsnLwpoKndName"
      && !currentRowData.valueMap?.emsnKndName?.value?.isNewCode
      && currentRowData.valueMap.emsnKndName.value) {

      comCd = comCd.filter((item: any) => item.uppoCdId === currentRowData.valueMap.emsnKndName.value.comCdId)
    }

    createRoot(this.div).render(
      <InventoryGridComCd name={name}
        comCds={comCd}
        isNewCode={this.isNewCode}
        selectedValue={this.isNewCode ? this.comCdExpl : ""}
        onChange={(data: InventoryGridComCdResult) => {
          this.isNewCode = data.isNewCode
          this.comCdExpl = data.value
          this.comCdId = data.id
        }}
      />
    )
  }
}

export class CustomSelectCommonCodeRenderer {
  div: HTMLDivElement;
  comCdExpl: any = '';
  comCdId: any;

  constructor(props: any) {
    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    this.div = div;
    this.comCdExpl = props.value?.comCdExpl;
    this.comCdId = props.value?.comCdId;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  getValue() {
    return {
      comCdExpl: this.comCdExpl,
      comCdId: this.comCdId,
    }
  }

  render(props: any) {
    const { name } = props.columnInfo;
    const { comCd } = props.columnInfo.editor.options;
    createRoot(this.div).render(
      <ArcSelectbox name={name}
        selectedValue={this.comCdId}
        options={comCd}
        size={SelectboxSize.w100}
        useDefaultOption={true}
        isRequired={true}
        onChange={(event: any) => {
          this.comCdExpl = event.target.selectedOptions[0].text
          this.comCdId = event.target.selectedOptions[0].value
        }} />
    )
  }
}

export class CustomSelectScopRenderer {
  div: HTMLDivElement;

  comCdExpl: any = '';
  comCdId: any;

  constructor(props: any) {
    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    this.div = div;
    this.comCdExpl = props.value?.comCdExpl;
    this.comCdId = props.value?.comCdId;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  getValue() {
    return {
      comCdExpl: this.comCdExpl,
      comCdId: this.comCdId,
    }
  }

  render(props: any) {
    const { name } = props.columnInfo;
    let { comCd } = props.columnInfo.editor.options;

    const viewData = props.grid.store.data.viewData

    // Disable the input only for the specific rowKey
    const currentRowData = viewData.find((row: any) => row.rowKey === props.rowKey);

    if (currentRowData && props.columnInfo.name === "fuelCd" &&
      currentRowData.valueMap.fixFuelYn.value === null) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "fuelCd" && currentRowData.valueMap.fixFuelYn.value !== null) {
      const fixFuelYn = currentRowData.valueMap.fixFuelYn.value?.comCdId;
      if (fixFuelYn === "고정연소") {
        comCd = comCd.filter((item: any) => item.groupCd === "EMKS_FVR_FUEL_CL_CD")
      } else {
        comCd = comCd.filter((item: any) => item.groupCd === "EMKS_FUEL_KND_CD")
      }
    }

    if (currentRowData && props.columnInfo.name === "wstKndCd"
      && (currentRowData.valueMap.wstStsClCd.value === null || currentRowData.valueMap.wstStsClCd.value?.comCdId === undefined || currentRowData.valueMap.wstStsClCd.value?.comCdId === "")) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "wstLwpoKndCd"
      && (currentRowData.valueMap.wstKndCd.value === null || currentRowData.valueMap.wstKndCd.value?.comCdId === undefined || currentRowData.valueMap.wstKndCd.value?.comCdId === "")) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "wstKndCd"
      && currentRowData.valueMap.wstStsClCd.value) {

      comCd = comCd.filter((item: any) => item.uppoCdId === currentRowData.valueMap.wstStsClCd.value.comCdId)
    }

    if (currentRowData && props.columnInfo.name === "wstLwpoKndCd"
      && currentRowData.valueMap.wstKndCd.value) {

      comCd = comCd.filter((item: any) => item.uppoCdId === currentRowData.valueMap.wstKndCd.value.comCdId)
    }


    if (currentRowData && props.columnInfo.name === "emsnLwpoKndCd"
      && (currentRowData.valueMap.emsnKndCd.value === null || currentRowData.valueMap.emsnKndCd.value?.comCdId === undefined || currentRowData.valueMap.emsnKndCd.value?.comCdId === "")) {
      comCd = []
    }

    if (currentRowData && props.columnInfo.name === "emsnLwpoKndCd"
      && currentRowData.valueMap.emsnKndCd.value) {

      comCd = comCd.filter((item: any) => item.uppoCdId === currentRowData.valueMap.emsnKndCd.value.comCdId)
    }

    createRoot(this.div).render(
      <ArcSelectbox name={name}
        selectedValue={this.comCdExpl}
        options={comCd}
        size={SelectboxSize.w100}
        useDefaultOption={true}
        isRequired={true}
        onChange={(event: any) => {
          this.comCdExpl = event.target.selectedOptions[0].text
          this.comCdId = event.target.selectedOptions[0].value
        }} />
    )
  }
}

export class CustomSelectEmsNameRenderer {
  div: HTMLDivElement;

  value: any = '';
  comCdExpl: any = '';
  comCdId: any;

  constructor(props: any) {
    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    this.div = div;

    this.value = props?.value;

    this.comCdExpl = props.value?.comCdExpl;
    this.comCdId = props.value?.comCdId;

    this.render(props);
  }

  getElement() {
    return this.div;
  }

  getValue() {
    return this.value;
  }

  render(props: any) {
    const { name } = props.columnInfo;
    let { comCd } = props.columnInfo.editor.options;

    const viewData = props.grid.store.data.viewData

    // Disable the input only for the specific rowKey
    const currentRowData = viewData.find((row: any) => row.rowKey === props.rowKey);

    if (currentRowData && (currentRowData.valueMap?.kndCd?.value === null || currentRowData.valueMap?.kndCd?.value.comCdId === undefined || currentRowData.valueMap?.kndCd?.value.comCdId === "")) {
      comCd = [];
    }

    if (currentRowData && currentRowData.valueMap.kndCd.value) {
      const kndCd = currentRowData.valueMap.kndCd.value;

      let name = '';
      switch (kndCd.comCdId) {
        case "EMCKND0001":
          name = 'KOC';
          break;
        case "EMCKND0002":
          name = 'KAU';
          break;
        case "EMCKND0003":
          name = 'KCU';
          break;
        default:
          name = '';
      }

      comCd = comCd.filter((item: any) => item.label.toUpperCase().includes(name.toUpperCase()));
    }

    createRoot(this.div).render(
      <ArcSelectbox name={name}
        selectedValue={this.comCdExpl}
        options={comCd}
        size={SelectboxSize.w100}
        useDefaultOption={true}
        isRequired={true}
        onChange={(event: any) => {
          this.value = event.target.selectedOptions[0].value
          this.comCdExpl = event.target.selectedOptions[0].text
          this.comCdId = event.target.selectedOptions[0].value
        }} />
    )
  }
}

export class CustomBackGround {
  div: HTMLDivElement;

  constructor() {
    const div = document.createElement("div");
    div.className = "";

    const tdElements = document.querySelectorAll('td[data-column-name="totalScop1EmsnQnt"]');
    tdElements.forEach(tdElement => {
      tdElement.classList.add("grayBg");
    });
    this.div = div;

    this.render();
  }

  getElement() {
    return this.div;
  }

  render() {
    return "";
  }
}

export class CustomBackGround2 {
  div: HTMLDivElement;

  constructor() {
    const div = document.createElement("div");
    div.className = "";

    const tdElements = document.querySelectorAll('td[data-column-name="totalScop2EmsnQnt"]');
    tdElements.forEach(tdElement => {
      tdElement.classList.add("grayBg");
    });
    this.div = div;

    this.render();
  }

  getElement() {
    return this.div;
  }

  render() {
    return "";
  }
}

export class CustomBackGround3 {
  div: HTMLDivElement;

  constructor() {
    const div = document.createElement("div");
    div.className = "";

    const tdElements = document.querySelectorAll('td[data-column-name="totalScop3EmsnQnt"]');
    tdElements.forEach(tdElement => {
      tdElement.classList.add("grayBg");
    });
    this.div = div;

    this.render();
  }

  getElement() {
    return this.div;
  }

  render() {
    return "";
  }
}

export class CustomSelectUnitCodeRenderer {
  div: HTMLDivElement;

  unitNm: any = '';
  unitId: any;
  cnvrUnitNm: any;
  enrgUnitNm: any;

  constructor(props: any) {
    const div = document.createElement("div");
    div.className = "tui-grid-cell-content";
    this.div = div;
    this.unitNm = props.value?.unitNm;
    this.unitId = props.value?.unitId;
    this.cnvrUnitNm = props.value?.cnvrUnitNm;
    this.enrgUnitNm = props.value?.enrgUnitNm;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  getValue() {
    return {
      unitNm: this.unitNm,
      unitId: this.unitId,
      cnvrUnitNm: this.cnvrUnitNm,
      enrgUnitNm: this.enrgUnitNm,
    }
  }

  render(props: any) {
    const { name } = props.columnInfo;
    const { comCd } = props.columnInfo.editor.options;

    createRoot(this.div).render(
      <ArcSelectbox name={name}
        selectedValue={this.unitNm}
        options={comCd}
        size={SelectboxSize.w100}
        useDefaultOption={true}
        isRequired={true}
        onChange={(event: any) => {

          this.unitNm = event.target.selectedOptions[0].text
          this.unitId = event.target.selectedOptions[0].value
          const row = comCd.find((item: any) => item.value === this.unitId);
          if (row) {
            this.cnvrUnitNm = row.cnvrUnitNm;
            this.enrgUnitNm = row.enrgUnitNm;
          }

        }} />
    )
  }
}

export class CustomGridTradeTypeRenderer {
  div: HTMLDivElement
  el: HTMLSpanElement
  reversed?: boolean

  constructor(props: any) {
    this.div = document.createElement('div');
    this.div.className = 'tui-grid-cell-content';
    this.div.style.whiteSpace = 'normal';
    this.el = document.createElement('span');
    this.reversed = props.columnInfo.renderer.options?.reversed ?? false;
    this.render(props);
    this.div.appendChild(this.el);
  }

  getElement() {
    return this.div;
  }

  render(props: any) {
    this.div.classList.add('status');
    let value = Number(props.value);
    if (this.reversed) {
      value = value === 1 ? 2 : 1;
    }
    switch (value) {
      case 1: this.div.classList.add('sell'); break;
      case 2: this.div.classList.add('buy'); break;
    }

    this.el.innerText = CodeUtil.trdKndCdConvert(value);
  }
}

export class CustomTitleRenderer {
  div: HTMLDivElement;
  constructor(props: any) {
    const div = document.createElement('div');
    div.className = 'tui-grid-cell-content';
    this.div = div;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  render(props: any) {
    createRoot(this.div).render(
        <button>{props.value}</button>
    )
  }
}

export class CustomAttachFileIconRenderer {
  div: HTMLDivElement;
  constructor(props: any) {
    const div = document.createElement('div');
    div.className = 'tui-grid-cell-content';
    this.div = div;
    this.render(props);
  }

  getElement() {
    return this.div;
  }

  render(props: any) {
    if (props.value && props.value.files && props.value.files.length > 0) {
      createRoot(this.div).render(
        <ArcButton type={ButtonType.custom}
          hasIcon={true}
          icon={ButtonIcon.paperclip}
          className="btn-icon" />
      );
    }
  }
}

export class CustomButtonRenderer {
  div: HTMLDivElement;

  constructor(props: any) {
    const div = document.createElement('div');
    div.className = 'tui-grid-cell-content';
    this.div = div;
    this.render(props);
  }

  getElement() { return this.div }

  render(props: any) {
    const btn = props.columnInfo.renderer.options.buttonProps;
    if (btn) {
      createRoot(this.div).render(<ArcButton {...btn} />);
    }
  }
}

function mergeEditorValidations(validations: GridEditorValidations) {
  if (validations.validatorFn) {
    let options = {
      validatorFn: validations.validatorFn,
      ...validations
    }
    return { validation: options };
  } else {
    let options = {
      dataType: validations.dataType ?? "string",
      required: validations.required ?? false,
      unique: validations.unique ?? false,
    };
    if (validations) {
      if ("string" === options.dataType) {
        if (validations.regExp) {
          options = Object.assign(options, {
            regExp: validations.regExp,
            regExpMessage: validations.regExpMessage,
          });
        }
        if (validations.max && !validations.regExp) {
          const maxRegExp = {
            regExp: new RegExp("^.{0," + validations.max + "}$"),
            regExpMessage: i18n.t("common.message.valid.input.max", { max: validations.max }),
          };
          Object.assign(validations, maxRegExp);
          options = Object.assign(options, maxRegExp);
        }
      } else {
        if (validations.min) {
          options = Object.assign(options, { min: validations.min });
        }
        if (validations.max) {
          options = Object.assign(options, { max: validations.max });
        }
        if (validations.regExp) {
          options = Object.assign(options, {
            regExp: validations.regExp,
            regExpMessage: validations.regExpMessage,
          });
        }
      }
    }
    return { validation: options };
  }
}

/**
 * Row data control without Grid
 * etc. create modify delete modal
 */
export class ArcGridFunc {
  static addRemoveRowData(ref: MutableRefObject<Grid>) {
    ref.current?.getInstance().reloadData();
  }

  static prependRowData(ref: MutableRefObject<Grid>, data: any, focus?: boolean) {
    ref.current?.getInstance().prependRow(data, { focus: focus ?? false });
  }

  static appendRowData(ref: MutableRefObject<Grid>, data: any, rowKey?: number) {
    if (rowKey) {
      if (0 === rowKey) {
        this.prependRowData(ref, data);
      } else {
        ref.current?.getInstance().appendRow(data, { at: rowKey });
      }
    } else {
      ref.current?.getInstance().appendRow(data);
    }
  }

  static removeRowData(ref: MutableRefObject<Grid>, data: any, idName: string) {
    const originDatas = ref.current?.getInstance().getData() as any[];
    let toUpdateGridRow = originDatas.find((f: any) => f[idName] === data[idName]);
    ref.current?.getInstance().removeRow(toUpdateGridRow.rowKey);
  }

  static updateRowData(ref: MutableRefObject<Grid>, data: any, idName: string) {
    const originDatas = ref.current?.getInstance().getData() as any[];
    let toUpdateGridRow = originDatas.find((f: any) => f[idName] === data[idName]);

    const updatedData = this.removeGridPropsFromData(data);
    ref.current?.getInstance().setRow(toUpdateGridRow.rowKey, updatedData);
  }

  static removeGridPropsFromData(data: any) {
    const origin = { ...data };
    delete origin.rowKey;
    delete origin.rowSpanMap;
    delete origin.sortKey;
    delete origin.undefined;
    delete origin.uniqueKey;
    delete origin._attributes;
    delete origin._disabledPriority;
    delete origin._relationListItemMap;
    return origin;
  }
}

export default function ArcGrid(props: Readonly<ArcGridProps>) {
  const {
    gridRef,
    useStaticData = false,
    data,
    datasource,
    columns,
    editor,
    complexHeader,
    rowHeaders = ["rowNum"],
    usePagination = true,
    useGridInfoHeader = false,
    downloadType,
    save,
    onClickRow,
    onCheckRow,
    onDataLoaded,
    id = props.id ?? crypto.randomUUID()
  } = props;

  const { t } = useTranslation();
  let defaultGridRef: any = useRef<typeof ArcGrid>();
  const arcGridRef: any = gridRef ?? defaultGridRef;
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const [error, setError] = useState<boolean>(false);
  const [requestState, setRequestState] = useState<string>("");
  const [dataCount, setDataCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPageCount, setPerPage] = useState<number>(10);
  const [fullPageCount, setFullPage] = useState<number>(0);
  const [hasGridInfoHeader, setGridInfoHeader] = useState<boolean>(useGridInfoHeader);

  useEffect(() => {
    if (downloadType) {
      setGridInfoHeader(true);
    }
    if (editor) {
      setGridInfoHeader(true);
    }
    if (!editor && datasource?.deleteData) {
      setGridInfoHeader(true);
    }
  }, []);

  useEffect(() => {
    if (arcGridRef?.current) {
      arcGridRef.current.getInstance().refreshLayout();
    }
  }, [arcGridRef]);

  /**
   * Grid Header options setting
   */
  let headerProps: OptHeader = {};
  if (complexHeader) {
    headerProps = {
      complexColumns: complexHeader.complexColumns as OptComplexColumnInfo[],
      height: complexHeader.height,
    };
  }

  if (props.isAlignHeader) {
    const headerColumns: OptColumnHeaderInfo[] | undefined = columns.reduce((acc: any, cur: GridColumn) => {
      if (Object.hasOwn(cur, "name") && Object.hasOwn(cur, "align")) {
        acc.push({ name: cur.name, align: cur.align } as OptColumnHeaderInfo);
      }
      return acc;
    }, [] as OptColumnHeaderInfo[])

    if (headerColumns) {
      Object.assign(headerProps, {
        align: 'center',
        valign: "middle",
        columns: headerColumns as OptColumnHeaderInfo[]
      })
    }
  }

  /**
   * Row Header &
   * Checkbox options setting
   */
  useEffect(() => {
    if (rowHeaders.includes("checkbox") && !editor) {
      if (!onCheckRow) {
        toast.error("onCheckRow 필수임");
        if (!props.isTest) {
          setError(true);
        }
      }
    }
  }, [rowHeaders]);

  let customRowHeader: OptRowHeader[] = [];
  if (rowHeaders.includes("checkbox")) {
    customRowHeader.push({
      type: "checkbox",
    });
  }
  if (rowHeaders.includes("rowNum")) {
    customRowHeader.push({
      type: "rowNum",
      renderer: {
        type: CustomRowHeaderRowNumRenderer,
      },
    });
  }
  if (rowHeaders.includes("radio")) {
    customRowHeader.push({
      type: "checkbox",
      header: t('common.label.select').toString(),
      renderer: {
        type: CustomRowHeaderRadioRenderer
      }
    })
  }

  function handleReturnCheckedRows(event: any) {
    const gridInstance = (gridRef ?? defaultGridRef).current?.getInstance();
    const checkedRows = gridInstance.getCheckedRows();
    if (undefined !== event.rowKey) {
      // if checked one row
      const checkedRowInfo = gridInstance.getRow(event.rowKey);
      onCheckRow?.({
        checkedRows: checkedRows,
        isChecked: checkedRowInfo?._attributes.checked,
        checkedRowData: checkedRowInfo,
      });
    } else {
      // if checked all row
      onCheckRow?.({
        checkedRows: checkedRows,
        isChecked: ObjectUtil.isNotEmpty(checkedRows),
      });
    }
  }

  let rowHeaderCheckboxProps = {};
  if (rowHeaders.includes("checkbox") || rowHeaders.includes("radio")) {
    rowHeaderCheckboxProps = {
      onCheck: handleReturnCheckedRows,
      onUncheck: handleReturnCheckedRows,
      onCheckAll: handleReturnCheckedRows,
      onUncheckAll: handleReturnCheckedRows,
    };
  }

  /**
   * Cell editor setting
   */
  useEffect(() => {
    if (editor && datasource) {
      if (!datasource.modifyData && !(datasource.createData && datasource.updateData && datasource.deleteData)) {
        toast.error("add modifyData or createData/updateData/deleteData in datasource");
        if (!props.isTest) {
          setError(true);
        }
      }
    }
  }, []);

  let editableColumns: GridColumn[] = [...columns];
  if (editor) {
    if (!rowHeaders.includes("checkbox")) {
      customRowHeader.unshift({ type: "checkbox" });
    }
    for (const cell of editor.editableColumns) {
      const cellType = cell.editorType;
      const index = editableColumns.findIndex((f) => f.name === cell.columnName);
      const editableColumnInfo = {
        editor: {
          type: getCustomGridEditorByCellType(cellType),
          options: {
            ...cell.options,
            cellType: cellType,
          },
        },
      };

      let mergeColumnInfo = Object.assign(editableColumns[index], editableColumnInfo);

      const validations = cell.options?.validation;
      if (validations) {
        const validationColumnInfo = mergeEditorValidations(validations);
        mergeColumnInfo = Object.assign(mergeColumnInfo, validationColumnInfo);
      }
      editableColumns.splice(index, 1, mergeColumnInfo);
    }
  }

  /**
   * Download setting
   */
  function handleClickDownload() {
    const downloadInfo = downloadType;

    if (undefined !== downloadInfo) {
      let format: string = "";
      let expOpt: OptExport = {};
      if (TypeUtil.isString(downloadInfo)) {
        format = downloadInfo as string;
      } else if (TypeUtil.isObject(downloadInfo)) {
        const { exportType, fileName } = downloadInfo as GridDownloadOption;
        format = exportType;

        if (fileName) {
          expOpt.fileName = fileName;
        }
      }
      arcGridRef.current?.getInstance().export(format, expOpt);
    }
  }

  /**
   * Pagination / PerPage options setting
   */
  const pagePerListOptions = VariablesConstant.PAGE_PER_LIST;
  const handleChangePagePerListOption = (event: any) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    const pagination = arcGridRef.current?.getInstance().getPagination();
    pagination?.setItemsPerPage(perPageCount);
    arcGridRef.current?.getInstance().setPerPage(perPageCount);
    if (useStaticData) {
      arcGridRef.current?.getInstance().getPagination()?.reset(data?.length ?? 0);
    } else {
      arcGridRef.current?.getInstance().getPagination()?.reset(dataCount);
    }
  }, [perPageCount]);

  /**
   * Datasource settings
   */
  useEffect(() => {
    if (datasource) {
      arcGridRef.current?.getInstance().readData(1, datasource.readData.initParams);
      arcGridRef.current?.getInstance().getPagination()?.reset(dataCount);
    }
  }, [datasource?.readData.initParams, dataCount]);

  if (!editor && datasource?.deleteData) {
    if (customRowHeader.findIndex((f: any) => f.type === "checkbox") < 0) {
      customRowHeader.push({ type: "checkbox" });
    }
  }

  const memoGridDatasourceProps: any = useMemo(() => {
    return {
      data: {
        withCredentials: false,
        initialRequest: false,
        contentType: AUTH_HEADER["Content-Type"] as ContentType,
        headers: { Authorization: "" },
        api: concatDatasourceToRoot(datasource!)
      },
    }
  }, []);

  function handleOnGridUpdated(event: any) {
    if (onDataLoaded) {
      if (event.instance.store !== undefined) {
        onDataLoaded(event.instance.store.data.rawData ?? []);
      }
    }
    arcGridRef?.current?.getInstance()?.refreshLayout();
  }

  function handleOnResponseData(onResponseData: any, perPageCount: number) {
    const jsonResponse = onResponseData.xhr.response;
    let defaultErrorMsg = i18n.t('common.message.error.duringProcessing');

    if (jsonResponse satisfies JSON) {
      const response = JSON.parse(jsonResponse);
      if (response.result) {
        const pagination = response.data.pagination;
        setDataCount(pagination.totalCount);
        setFullPage(pagination.totalCount === 0 ? 1 : Math.ceil(pagination.totalCount / perPageCount));
        setCurrentPage(pagination.page);
      } else {
        if (200 !== response.statusCode) {
          if (!toast.isActive('ArcGridResponseError')) {
            toast.error(
              isStringNullOrEmpty(response.errorMessage) ? defaultErrorMsg : response.errorMessage,
              { toastId: 'ArcGridResponseError' });
          }
        }
      }

      const modifyResponse = response.resultCode ?? "N";

      if (modifyResponse === "E") {
        if ("" !== requestState) {
          if ("SAVE" === requestState) {
            toast.error(t('common.message.valid.input.required', { title: '' }));
          }
          setRequestState("");
        }
      }

      if (modifyResponse === "Y") {
        if (requestState !== "") {
          if ("SAVE" === requestState) {
            if (save === undefined) {
              toast.success(t('common.message.success.save', { title: '' }));
            }
            arcGridRef.current?.getInstance().reloadData();
          } else if ("DELETE" === requestState) {
            toast.success(t('common.message.success.delete', { title: '' }));
            arcGridRef.current?.getInstance().reloadData();
          }
          setRequestState("");
        }
      }
    }
  }

  /**
   * etc setting
   */
  function onClickGridRow(event: any) {
    if (event.columnName !== '_checked' && event.targetType !== "columnHeader" && event.rowKey >= 0) {
      if (onClickRow) {
        onClickRow(event);
      }
    }
  }

  function handleMouseOver(e: any) {
    if (props.onMouseOver) {
      props.onMouseOver(e.instance.getRow(e.rowKey));
    }
  }

  function handleMouseOut(e: any) {
    if (props.onMouseOut) {
      props.onMouseOut(e);
    }
  }

  function handleClickPrependRow() {

    let defaultData = { rowCreater: userInfo?.userUid ?? "" };
    if (editor?.defaultRowDataSet) {
      defaultData = Object.assign(defaultData, editor.defaultRowDataSet);
    }
    ArcGridFunc.prependRowData(arcGridRef, defaultData, true);
  }

  function handleClickDeleteRows() {
    const checkedRows = arcGridRef.current?.getInstance().getCheckedRowKeys();
    if (checkedRows.length > 0) {
      const newDataDeleted = checkedRows.map((f: any) => {
        return arcGridRef.current?.getInstance().getRow(f)?.rowKey;
      });

      if (arcGridRef.current?.getInstance().removeCheckedRows(true)) {
        if (props.onAfterEdit) {
          const editRow = arcGridRef.current?.getInstance().getModifiedRows();
          if (editRow?.deletedRows && editRow.deletedRows.length > 0) {
            props.onAfterEdit(editRow);
          } else {
            const remainC = editRow?.createdRows.filter((f: any) => !newDataDeleted.includes(f.rowKey));
            props.onAfterEdit({
              createdRows: remainC,
              updatedRows: editRow.updatedRows ?? [],
              deletedRows: editRow?.deletedRows ?? [],
            });
          }
        }

        const modifiedRowKeys = arcGridRef.current?.getInstance().getModifiedRows({ rowKeyOnly: true });
        if (modifiedRowKeys.deletedRows.length > 0 && !useStaticData) {
          setRequestState("DELETE");
          if (!props.useStaticData) {
            arcGridRef.current?.getInstance().request(datasource?.modifyData ? "modifyData" : "deleteData");
          }
        } else if (modifiedRowKeys.deletedRows.length > 0 && useStaticData) {
          if (props.onDelete) {
            props?.onDelete(true);
          }
        }
      }
    } else {
      toast.error(t("common.message.valid.delete.list.checked"));
    }
  }

  useEffect(() => {
    if (save) {
      handleClickSaveRows();
    }
  }, [save])

  function handleClickSaveRows() {
    if (arcGridRef.current?.getInstance().isModified()) {
      const modifiedRowKeys = arcGridRef.current?.getInstance().getModifiedRows({ rowKeyOnly: true });
      const rowKeys = [...modifiedRowKeys.createdRows, ...modifiedRowKeys.updatedRows];
      if (arcGridRef.current?.getInstance().validate(rowKeys).length > 0) {
        toast.error(t('common.message.valid.grid.cellValue'));
      } else {
        const modifiedRows = arcGridRef.current?.getInstance().getModifiedRows();
        if (datasource?.modifyData) {
          if (modifiedRows.createdRows.length > 0 || modifiedRows.modifiedRows.length > 0 || modifiedRows.deletedRows.length > 0) {
            arcGridRef.current?.getInstance().request("modifyData");
            setRequestState("SAVE");
          }
        } else {
          if (modifiedRows.createdRows.length > 0) {
            arcGridRef.current?.getInstance().request("createData");
            setRequestState("SAVE");
          }
          if (modifiedRows?.updatedRows.length > 0) {
            arcGridRef.current?.getInstance().request("updateData");
            setRequestState("SAVE");
          }
        }
      }
    }
  }

  useEffect(() => {
    if (arcGridRef.current) {
      const gridInstance = arcGridRef.current.getInstance();
      const handleKeyDown = (ev: any) => {
        if (ev.keyboardEvent.key === 'Tab') {
          let pagination;
          if(usePagination){
            pagination = $(arcGridRef.current.getInstance().el).find('.tui-pagination')[0];
          } else{
            pagination = $(arcGridRef.current.getInstance().el).find('.tui-grid-clipboard')[0];
          }
          if (pagination) {
            pagination.setAttribute('tabindex', '-1');
            pagination.focus();
          }
        }
      };
      gridInstance?.on('keydown', handleKeyDown);
    }
  }, [arcGridRef]);

  return (
    <>
      {hasGridInfoHeader && (
        <div className="grid-sort">
          <div className="paging">
            <span>
              {t("common.label.totalPst")}
              {useStaticData
                ? (<strong>{data?.length ?? 0}</strong>)
                : (<strong>{dataCount}</strong>)
              }
              {t("common.unit.case")}
            </span>
            {usePagination && (
              <span>
                {t("common.label.currentPage")}
                {useStaticData
                  ? (<>
                    <strong>{arcGridRef.current?.getInstance()?.getPagination()?.getCurrentPage() ?? '-'}</strong>/{Math.ceil((data?.length ?? 1) / perPageCount)}</>)
                  : (<><strong>{currentPage}</strong>/{fullPageCount}</>)
                }
              </span>
            )}
          </div>
          <div className="page-count">
            {usePagination && (
              <>
                <label htmlFor="pagePerList" className="fs14 mgr10"><span>{t("common.label.pagePerList")}</span></label>
                <ArcSelectbox
                    name="pagePerList"
                    className="InpSel mgl5"
                  options={pagePerListOptions}
                  onChange={handleChangePagePerListOption}
                />
              </>
            )}
            {downloadType && <ArcButton type={ButtonType.download} hasIcon={true}
              onClick={handleClickDownload} />}
            {editor && (<>
              {((editor.requestBtn?.includes('ADD')) || (!editor.requestBtn)) && (
                <ArcButton
                  type={ButtonType.custom}
                  text={t("common.button.addRow")}
                  color={ButtonColor.btn1}
                  onClick={handleClickPrependRow}
                />
              )}
              {((editor.requestBtn?.includes('DELETE')) || (!editor.requestBtn)) && (
                <ArcButton
                  type={ButtonType.delete}
                  text={t("common.button.deleteRow")}
                  onClick={handleClickDeleteRows}
                />
              )}
              {((editor.requestBtn?.includes('SAVE')) || (!editor.requestBtn)) && (
                <ArcButton type={ButtonType.regist} text={t("common.button.save")}
                  onClick={handleClickSaveRows} />
              )}
            </>)}
            {!editor && datasource?.deleteData && (
              <ArcButton type={ButtonType.delete} text={t("common.button.delete")}
                onClick={handleClickDeleteRows} />
            )}
          </div>
        </div>
      )}
      {error && <>Error!!!</>}
      {!error && useStaticData && (
        <div id="gridTbl">
          <Grid ref={arcGridRef}
            minBodyHeight={65}
            data={data}
            columns={editableColumns as OptColumn[]}
            rowHeaders={customRowHeader}
            header={headerProps}
            pageOptions={usePagination ? {
              useClient: true,
              perPage: perPageCount
            } as PageOptions : undefined}
            {...rowHeaderCheckboxProps}
            onResponse={(response: any) => handleOnResponseData(response, perPageCount)}
            onGridUpdated={handleOnGridUpdated}
            onMouseover={handleMouseOver}
            onMouseout={handleMouseOut}
            onClick={onClickGridRow}
            minRowHeight={65}
            rowHeight={65}
            bodyHeight={props.bodyHeight}
            scrollX={props.scrollX ?? false}
            scrollY={props.scrollY ?? false}
            draggable={false}
            oneTimeBindingProps={["columns"]}
            contextMenu={null as any}
          />
        </div>
      )}
      {!error && !useStaticData && (
        <div id="gridTbl">
          <Grid ref={arcGridRef}
            {...memoGridDatasourceProps}
            minBodyHeight={65}
            columns={editableColumns as OptColumn[]}
            rowHeaders={customRowHeader}
            header={headerProps}
            pageOptions={usePagination ? {
              type: "pagination",
              perPage: perPageCount
            } as PageOptions : { type: undefined }}
            {...rowHeaderCheckboxProps}
            onResponse={(response: any) => handleOnResponseData(response, perPageCount)}
            onGridUpdated={handleOnGridUpdated}
            onClick={onClickGridRow}
            onMouseover={handleMouseOver}
            onMouseout={handleMouseOut}
            rowHeight={65}
            scrollX={props.scrollX ?? false}
            scrollY={props.scrollY ?? false}
            draggable={false}
            oneTimeBindingProps={props.oneTimeBindingProps ?? ["columns", "data"]}
            contextMenu={null as any}
          />
        </div>
      )}
    </>
  );
}
