import ArcGrid, {
  ArcGridCheckResult,
  CustomCommonCodeRenderer,
  CustomSelectCommonCodeRenderer,
  GridApi,
  GridColumn,
  GridEditor,
} from "components/arc/ArcGrid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ArcDatePicker from "../../../../components/arc/ArcDatePicker";
import { toast } from "react-toastify";
import { ComCdService } from "../../../../services/cp/ComCdService";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useAppSelector } from "stores/hook";
import { AuthConstant } from "constants/authConstant";
import { UserInfoUtil } from "utils/userInfoUtil";

export default function ProcessEmission() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();

  const toYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(toYear);
  const [kndCd, setKndCd] = useState<any[]>([]);
  const [lwpoKndCd, setLwpoKndCd] = useState<any[]>([]);
  const [unitCd, setUnitCd] = useState<any[]>([]);

  // 그리드. 체크박스
  const gridRef: any = useRef<typeof ArcGrid>();

  useEffect(() => {
    getKndCd();
    getLwpoKndCd();
  }, [gridRef.current]);

  useEffect(() => {
    getUnitCd();
  }, []);

  const checkedRow = (data: ArcGridCheckResult) => { };

  function getKndCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_PRCS_EMSN_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setKndCd(cdOptions);
        } else {
          setKndCd([]);
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  function getLwpoKndCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_PRCS_EMSN_LWPO_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setLwpoKndCd(cdOptions);
        } else {
          setLwpoKndCd([]);
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  function getUnitCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_EMSRC_COEF_UNIT_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setUnitCd(cdOptions);
        } else {
          setUnitCd([]);
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  function makeCommonCodeOptions(data: any[]) {
    return data.map((item: any) => ({
      label: item.comCdExpl,
      value: item.comCdId,
      uppoCdId: item.uppoCdId,
    }));
  }

  // 그리드 컬럼
  const gridColumns: GridColumn[] = [
    {
      header: "emsnCoefId",
      name: "emsnCoefId",
      hidden: true,
    },
    {
      header: "공정배출원",
      name: "emsnKndName",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "세부항목",
      name: "emsnLwpoKndName",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "단위",
      name: "emksEmsrcCoefUnitName",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "CO₂ 단위",
      name: "cbdxEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
    {
      header: "CH₄ 단위",
      name: "methnEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
  ];

  //그리드 수정
  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: {
      aplyYy: (year ?? "").toString(),
    },
    editableColumns: [
      {
        columnName: "emsnKndName",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: kndCd,
          onChange: handleChangeEditor,
          validation: {
            required: true,
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            }
          },
        },
      },
      {
        columnName: "emsnLwpoKndName",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: lwpoKndCd,
          onChange: handleChangeEditor,
          validation: {
            required: true,
            validatorFn: (value: any, row: any, columnNm: any) => {

              if (value === null || value === undefined) return false;
              if (value?.comCdExpl === undefined) return false;
              const gridInstance = gridRef.current?.getInstance();
              if (!gridInstance) return false;
              const data = gridInstance.getData();
              if (!Array.isArray(data) || data.length === 0) return false;
              return data.filter((e: any) => e?.emsnLwpoKndName?.comCdExpl === value.comCdExpl).length === 1;
            },
          },
        },
      },
      {
        columnName: "emksEmsrcCoefUnitName",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitCd,
          onChange: handleChangeEditor,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            },
          },
        },
      },
      {
        columnName: "cbdxEmsnCoef",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,9})?$/,
            regExpMessage: "전체 길이는 20자리, 소수점은 9자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: "methnEmsnCoef",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,9})?$/,
            regExpMessage: "전체 길이는 20자리, 소수점은 9자리까지 가능합니다.",
          },
        },
      },
    ],
  };

  // 그리드 수정함수
  function handleChangeEditor(e: any) { }

  //  데이터 전송
  const adminGridApi: GridApi = {
    readData: { url: "/cp/refProcessEmission", method: "GET", initParams: { aplyYy: year } },
    createData: { url: "/cp/refProcessEmission", method: "POST" },
    updateData: { url: "/cp/refProcessEmission", method: "PUT" },
    deleteData: { url: "/cp/refProcessEmission", method: "DELETE", serializer(params) {
        const ids: any = params.deletedRows?.map((row: any) => ({ emsnCoefId: row.emsnCoefId }));
        return encodeURI(`deletedRows=${JSON.stringify(ids)}`);
      }, },
  };

  const gridApi: GridApi = {
    readData: { url: "/cp/refProcessEmission", method: "GET", initParams: { aplyYy: year } },
  };

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">공정배출원 배출계수</h3>
            <ArcDatePicker
              onChange={(e) => {
                setYear("" + e.fromDateString?.slice(0, 4));
              }}
              fromDate={year}
              id={"year"}
              name={"year"}
              viewType={"year"}
              placeholders={{ fromDate: "YYYY" }} />
          </div>

          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                key={`${kndCd.length}-${lwpoKndCd.length}-${unitCd.length}`}
                gridRef={gridRef}
                datasource={adminGridApi}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                useGridInfoHeader={true}
                usePagination={false}
                editor={gridEditor}
                onCheckRow={checkedRow}
                isTest={true} />
              :
              <ArcGrid
                gridRef={gridRef}
                datasource={gridApi}
                columns={gridColumns}
                useGridInfoHeader={true}
                usePagination={false}
                onCheckRow={checkedRow} />
            }
          </div>
          <p className="bu-atte">
            출처: 온실가스 배출권의 할당 및 거래에 관한 법률 시행령 [별표 2] 온실가스별 지구온난화 계수
          </p>
        </div>
      </div>
    </div>
  );
}
