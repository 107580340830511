import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false
}

const fullMenuSlice = createSlice({
  name: 'fullMenu',
  initialState,
  reducers: {
    showAndHideFullMenu: (state, action) => {
      const html = document.getElementsByTagName('html')[0];
      if(action.payload) {
        html.classList.add('fullOpen');
      } else {
        html.classList.remove('fullOpen');
      }
      state.open = action.payload
    }
  }
})

export default fullMenuSlice.reducer
export const { showAndHideFullMenu } = fullMenuSlice.actions
