import ArcSelectbox, {SelectDataModel} from "../arc/ArcSelectbox";
import {useEffect, useState} from "react";
import ArcDatePicker, {ArcDatePickerResult} from "../arc/ArcDatePicker";
import moment from "moment/moment";
import {Constant} from "../../constants/constant";
import {VariablesConstant} from "../../constants/variables";
import {ChartUtil} from "../../utils/chartUtil";

export type ArcSearchPickType = 'date' | 'day' | 'month' | 'year';
export type ArcRangePickType = 'range' | 'normal';

export interface ArcSearchPickerProps {
  id?: string,
  name?: string,
  label?: string,
  onChange: (category: any[], result?: ArcDatePickerResult) => void;
  onChangeSelect?: (selectedOption?: string) => void;
  selectMode?: ArcRangePickType;
  selectOption?: SelectDataModel[];
  fromDate?: string;
  toDate?: string;
}

const todayMoment = moment();
const today = todayMoment.format('YYYYMMDD') + '235959';

export default function ArcSearchPicker(props: Readonly<ArcSearchPickerProps>) {
  const [fromDate, setFromDate] = useState<string>(props.fromDate ?? today);
  const [toDate, setToDate] = useState<string | undefined>(props.toDate);
  const [selectedOption, setSelectedOption] = useState<ArcSearchPickType>();
  const id = props.id ?? crypto.randomUUID();
  const name = props.name ?? id;

  useEffect(() => {
    if(props.selectOption){
      setSelectedOption(props.selectOption[0].value);
      handleChangeSelect(props.selectOption[0].value);
    }else{
      setSelectedOption('date');
      handleChangeSelect('date');
    }
  }, []);

  function handleChangePicker(result: ArcDatePickerResult, sort: ArcSearchPickType) {
    setFromDate(result.fromDateString!);
    setToDate(result.toDateString);
    let tmpFromDate = moment(result.fromDate);
    let toDate;

    if (selectedOption === 'year') {
      toDate = result.toDateString;
    } else if (selectedOption === 'month') {
      toDate = tmpFromDate.format('YYYY') + '1231235959';
    } else if (selectedOption === 'day') {
      toDate = tmpFromDate.format('YYYYMM') + tmpFromDate.daysInMonth() + '235959';
    } else {
      toDate = tmpFromDate.format('YYYYMMDD') + '235959';
    }
    props.onChange(makeCategory(sort, result.fromDateString, toDate), result);
  }

  function handleChangeSelect(newSelectedOption: ArcSearchPickType) {
    const oldSelectedOption = selectedOption;
    let tmpFromDate;
    let tmpFromDateMoment;
    let tmpToDate;

    if (newSelectedOption === 'year') {
      tmpToDate = fromDate ?? today;
      tmpFromDate = moment(tmpToDate, Constant.FORMAT_YMDHMS).subtract(1, 'y').format('YYYY') + '0101000000';
    } else if (newSelectedOption === 'month') {
      if (oldSelectedOption === 'year') {
        tmpFromDate = toDate ?? today;
      } else {
        tmpFromDate = (fromDate && fromDate !== '') ? fromDate : today;
      }
      tmpFromDateMoment = moment(tmpFromDate, Constant.FORMAT_YMDHMS);
      tmpFromDate = tmpFromDateMoment.format('YYYY') + '0101000000';
      tmpToDate = tmpFromDateMoment.format('YYYY') + '1231235959';
    } else if (newSelectedOption === 'day') {
      if (oldSelectedOption === 'year') {
        tmpFromDate = toDate ?? today;
      } else {
        tmpFromDate = (fromDate && fromDate !== '') ? fromDate : today;
      }

      if ((oldSelectedOption === 'date' && tmpFromDate.substring(0, 6) === today.substring(0, 6)) || (oldSelectedOption !== 'date' && tmpFromDate.substring(0, 4) === today.substring(0, 4))) {
        tmpFromDate = today;
      }

      tmpFromDateMoment = moment(tmpFromDate, Constant.FORMAT_YMDHMS);
      tmpFromDate = tmpFromDateMoment.format('YYYYMM') + '01000000';
      tmpToDate = tmpFromDateMoment.format('YYYYMM') + tmpFromDateMoment.daysInMonth() + '235959';
    } else {
      if (oldSelectedOption === 'year') {
        tmpFromDate = toDate ?? today;
      } else {
        tmpFromDate = (fromDate && fromDate !== '') ? fromDate : today;
      }

      if (((oldSelectedOption === 'year' || oldSelectedOption === 'month') && tmpFromDate.substring(0, 4) === today.substring(0, 4)) || tmpFromDate.substring(0, 6) === today.substring(0, 6)) {
        tmpFromDate = today;
      }

      tmpFromDateMoment = moment(tmpFromDate, Constant.FORMAT_YMDHMS);
      tmpFromDate = tmpFromDateMoment.format('YYYYMMDD') + '000000';
      tmpToDate = tmpFromDateMoment.format('YYYYMMDD') + '235959';
    }

    setFromDate(tmpFromDate);
    setToDate(tmpToDate);
    setSelectedOption(newSelectedOption);

    const pickerResult: any = {
      fromDateString: tmpFromDate,
      toDateString: tmpToDate
    }

    if (props.onChangeSelect) {
      props.onChangeSelect(newSelectedOption);
    }
    props.onChange(makeCategory(newSelectedOption, tmpFromDate, tmpToDate), pickerResult);
  }

  function makeCategory(sort: ArcSearchPickType, from?: string, to?: string) {
    if (from && to) {
      if ('year' === sort) {
        return ChartUtil.getYearRangeForCategory(from, to);
      } else if ('month' === sort) {
        return ChartUtil.getMonthRangeForCategory(from, to);
      } else if ('day' === sort) {
        return ChartUtil.getDateRangeForCategory(from, to);
      } else {
        return ChartUtil.hourOfDayNumberArray.map(m => `${m}시`);
      }
    } else {
      return [];
    }
  }

  return (<>
    <ArcSelectbox key={id + '_type_select'}
                  id={id + '_type_select'}
                  label={props.label}
                  useLabelFor={false}
                  name={name}
                  title="검색기간 종류 선택"
                  onChange={(e) => handleChangeSelect(e.target.value)}
                  selectedValue={selectedOption}
                  className="mgr5"
                  options={props.selectOption ?? VariablesConstant.SELECT_OPTIONS_ALL_DATE}
                  callFromSearchPicker={true}
    />
    {selectedOption === 'year' && (
        <ArcDatePicker onChange={(e: any) => handleChangePicker(e, 'year')}
                       key={id + 'year_picker'}
                       id={id + '_year_picker'}
                       name={id + '_year_picker'}
                       viewType="year"
                       selectionMode={"range"}
                       fromDate={fromDate}
                       toDate={toDate}
                       maxDate={today}
                       minMaxDateMode="force"
                       showClearButton={false} />)}
    {selectedOption === 'month' && (
        <ArcDatePicker onChange={(e: any) => handleChangePicker(e, 'month')}
                       viewType={"year"}
                       key={id + '_month_picker'}
                       id={id + '_month_picker'}
                       name={id + '_month_picker'}
                       fromDate={fromDate}
                       toDate={toDate}
                       maxDate={today}
                       minMaxDateMode="force"
                       showClearButton={false} />)}
    {selectedOption === 'day' && (
        <ArcDatePicker onChange={(e: any) => handleChangePicker(e, 'day')}
                       key={id + '_date_picker'}
                       id={id + '_date_picker'}
                       name={id + '_date_picker'}
                       viewType="month"
                       fromDate={fromDate}
                       toDate={toDate}
                       maxDate={today}
                       minMaxDateMode="force"
                       showClearButton={false} />)}
    {selectedOption === 'date' && (
        <ArcDatePicker onChange={(e: any) => handleChangePicker(e, 'date')}
                       key={id + '_day_picker'}
                       id={id + '_day_picker'}
                       name={id + '_day_picker'}
                       viewType="date"
                       fromDate={fromDate}
                       maxDate={today}
                       minMaxDateMode="force"
                       showClearButton={false} />)}
  </>);
}