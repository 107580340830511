import React from 'react';
import {useAppDispatch} from "stores/hook";
import {showAndHideFullMenu} from "reducers/fullMenuSlice";

export default function Sitemap() {
  const dispatch = useAppDispatch();
  function handleClickFullMenu(e: any) {
    e.preventDefault();
    dispatch(showAndHideFullMenu(true));
  }

  return (
    <li className="">
      <a href="#"
         onClick={handleClickFullMenu}
         className="fullmenu fullmenuBtn"
         id="fullmenuOpen">
        <em><em><span className="hid">전체메뉴</span></em></em>
      </a>
    </li>
  )
}