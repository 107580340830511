import ArcGrid, {
  ArcGridCheckResult,
  CustomSelectCommonCodeRenderer,
  CustomSelectEmsNameRenderer,
  GridApi,
  GridEditor,
} from "components/arc/ArcGrid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores/hook";
import { setEmissionsList, setEmissionsRightList } from "../../../reducers/cpSlice";
import { parseNumberWithComma } from "utils/stringUtil";
import { ComCdService } from "services/cp/ComCdService";
import { CodeUtil } from "utils/codeUtil";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { EmissionService } from "../../../services/cp/EmissionService";

export default function EmissionsManagementGrid() {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo?.userInfo);
  const [emsList, setEmsList] = useState<any>();
  const [userUuid, setUserUuid] = useState(userInfo.userId);
  const [kndCd, setKndCd] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    EmissionService.getInstance()
      .getEmission()
      .then(function (res) {
        const itemList = Array.from(
          new Set(
            res.data.data.map((item: any) =>
              JSON.stringify({
                itmNm: item.itmNm,
                isinCd: item.isinCd,
              })
            )
          )
        );
        const result: any = [];
        itemList.forEach((value: any) => {
          result.push(JSON.parse(value.toString()));
        });
        dispatch(setEmissionsList(result));
        setEmsList(result);
      })
      .catch(() => toast.error(t('common.message.error.system')));
  }, []);


  const transformEmsList = (result: any) => {
    if (!result) {
      return null;
    }

    return result.map((item: any) => ({
      label: item.itmNm,
      value: item.isinCd
    }));
  }

  useEffect(() => {
    setUserUuid(userInfo.userId);
  }, [userInfo.userId]);

  useEffect(() => {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_EMCRD_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setKndCd(cdOptions);
        } else {
          setKndCd([]);
        }
      })
      .catch(() => toast.error(t("common.message.error.system")));
  }, []);

  const gridNumberParse = (e: any) => {
    if (e.value || 0 === e.value) {
      return parseNumberWithComma(e.value);
    }
    return "";
  };

  const today = new Date();

  function formatRegDt(dateTimeString: any) {
    if (dateTimeString) {
      const date = new Date(dateTimeString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    }

    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  }

  // 그리드 컬럼
  const gridColumns: any[] = [
    {
      header: "emcrdId",
      name: "emcrdId",
      hidden: true,
    },
    {
      header: "등록일자",
      name: "regDate",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return formatRegDt(e.value);
        }
        return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}-${String(
          today.getDate()
        ).padStart(2, "0")}`;
      },
    },
    {
      header: "계획기간",
      name: "plnPrd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value + "차";
        }
        return "";
      },
    },
    {
      header: "이행연도",
      name: "fuflYy",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value;
        }
        return "";
      },
    },
    {
      header: "배출권 종류",
      name: "kndCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "배출권 명",
      name: "emcrdNm",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return findLabelByValue(e?.value)
        }
        return "";
      },
    },
    {
      header: "배출권 수량",
      name: "itmNqnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return gridNumberParse(e);
        }
        return "";
      },
    },
    {
      header: "유효기간",
      name: "vldPrd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          const year = e.value?.slice(0, 4) ?? "";
          const month = e.value?.slice(4, 6) ?? "";
          const date = e.value?.slice(6, 8) ?? "";
          return `${year}-${month}-${date}`;
        }
        return "";
      },
    }

  ];

  const [year, setYear] = useState<any>([]);

  useEffect(() => {
    const baseYear = 2020;
    const years = Array.from({ length: 2050 - baseYear + 1 }, (_, index) => baseYear + index);
    setYear(
      years.map((m) => {
        return { text: m + t("common.unit.year"), value: m };
      })
    );
  }, []);

  const findLabelByValue = (value: any) => {
    let label = '';
    const changedEmsList: any = transformEmsList(emsList);
    if (changedEmsList) {
      const item = changedEmsList.find((d: any) => d.value === value);
      label = item?.label ?? '';
    }
    return label;
  };

  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: {
      userUuid: userUuid,
    },
    editableColumns: [
      {
        columnName: "plnPrd",
        editorType: "select",
        options: {
          listItems: [
            // { text: "1차", value: "1" },
            // { text: "2차", value: "2" },
            { text: "3차", value: "3" },
          ],
          validation: {},
        },
      },
      {
        columnName: "fuflYy",
        editorType: "select",
        options: {
          listItems: year,
          validation: {
            dataType: "string",
          },
        },
      },
      {
        columnName: "kndCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: kndCd,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            },
          },
        },
      },
      {
        columnName: "emcrdNm",
        editorType: CustomSelectEmsNameRenderer,
        options: {
          comCd: transformEmsList(emsList),
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value !== undefined;
            },
          },
        },
      },
      {
        columnName: "itmNqnt",
        editorType: "text",
        options: {
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,4})?$/,
            regExpMessage: "전체 길이는 10자리, 소수점은 4자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: "vldPrd",
        editorType: "date",
        options: {
          validation: {
            dataType: "string",
          },
        },
      },
    ],
  };

  // 그리드. 체크박스
  const gridRef: any = useRef<typeof ArcGrid>();

  const checkedRow = (data: ArcGridCheckResult) => {
  };

  const gridDatasource: GridApi = {
    readData: { url: "/cp/pssnEmcrdPrcnd", method: "GET" },
    createData: { url: "/cp/pssnEmcrdPrcnd", method: "POST" },
    updateData: { url: "/cp/pssnEmcrdPrcnd", method: "PUT" },
    deleteData: { url: "/cp/pssnEmcrdPrcnd", method: "DELETE" },
  };

  const [kauValue, setKauValue] = useState(0);
  const [kcuValue, setKcuValue] = useState(0);
  const [kocValue, setKocValue] = useState(0);
  const [iKcuValue, setIKcuValue] = useState(0);
  const [iKouValue, setIKouValue] = useState(0);

  const calculateTotal = (arr: any, prefix: any) => {
    if (arr && arr.length > 0) {
      return arr
        .filter((item: any) => findLabelByValue(item.emcrdNm).toUpperCase().startsWith(prefix.toUpperCase()))
        .reduce((total: any, item: any) => total + item.itmNqnt, 0);
    }
  };

  const onDataLoaded = (data: any) => {
    if (data) {
      localStorage.setItem("gridData", JSON.stringify(data));
      updateValues(data);
    }
    const storedData = localStorage.getItem("gridData");
    if (storedData) {
      data = JSON.parse(storedData);
    }
    dispatch(setEmissionsRightList(data));
  };

  const updateValues = (data: any) => {
    setKauValue(calculateTotal(data, "KAU"));
    setKcuValue(calculateTotal(data, "KCU"));
    setKocValue(calculateTotal(data, "KOC"));
    setIKcuValue(calculateTotal(data, "i-KCU"));
    setIKouValue(calculateTotal(data, "i-KOC"));
  };

  const makeGrid = useCallback(() => {
    if (emsList) {
      return (
        <ArcGrid
          key={`${kndCd.length}}`}
          gridRef={gridRef}
          datasource={gridDatasource}
          columns={gridColumns}
          rowHeaders={["checkbox", "rowNum"]}
          usePagination={true}
          useGridInfoHeader={true}
          editor={gridEditor}
          onCheckRow={checkedRow}
          onDataLoaded={onDataLoaded}
          isTest={true} />
      );
    }
  }, [emsList]);

  return (
    <div className="col-wrap">
      <div className="col-md-12">
        <div className="cont pd0">
          <div className="col-h-3">
            <div className="cont">
              <h3 className="titT1">배출권 보유 현황</h3>
              <div>
                <ul className="div-t-wrap">
                  <li>
                    <div className="div-table">
                      <h5 className="div-t-tit tbl-bg-dbl1">국내</h5>
                      <div className="div-tb">
                        <div className="div-tr">
                          <span>KAU</span>
                          <span>
                            <strong>{parseNumberWithComma(kauValue) || 0}</strong>tCO2eq
                          </span>
                        </div>
                        <div className="div-tr">
                          <span>KCU</span>
                          <span>
                            <strong>{parseNumberWithComma(kcuValue) || 0}</strong>tCO2eq
                          </span>
                        </div>
                        <div className="div-tr">
                          <span>KOC</span>
                          <span>
                            <strong>{parseNumberWithComma(kocValue) || 0}</strong>tCO2eq
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="div-table">
                      <h5 className="div-t-tit tbl-bg-dbl2">국내</h5>
                      <div className="div-tb">
                        <div className="div-tr">
                          <span>i-KCU</span>
                          <span>
                            <strong>{parseNumberWithComma(iKcuValue) || 0}</strong>tCO2eq
                          </span>
                        </div>
                        <div className="div-tr">
                          <span>i-KOC</span>
                          <span>
                            <strong>{parseNumberWithComma(iKouValue) || 0}</strong>tCO2eq
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tit-btns-wrap">
                <h3 className="titT1">배출권 보유 현황</h3>
              </div>
              <div id="gridTbl">
                {makeGrid()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
