import {createSlice} from "@reduxjs/toolkit";

const initialState: any = {
  messagesAlarm: [],

}

const alarmSlice = createSlice({
  name: 'messagesAlarm',
  initialState,
  reducers: {
    updateAlarmMessages: (state, action) => {
      state.messagesAlarm = action.payload;
    }
  },
});

export default alarmSlice.reducer;
export const {
  updateAlarmMessages
} = alarmSlice.actions;