import React, {useEffect, useRef, useState} from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import Grid from "@toast-ui/react-grid";
import {useAppSelector} from "stores/hook";
import {UserInfoUtil} from "utils/userInfoUtil";
import {AuthConstant} from "constants/authConstant";
import {BillService} from "services/cb/BillService";
import {BillSearchModel} from "pages/cb/bill/billList/BillListBase";
import {ResponseResultCode} from "model/response-api";
import CountUp from "react-countup";
import {ResponseChart} from "model/response-chart";
import {HighchartsOptionsUtil} from "utils/highchartsOptionsUtil";
import {ChartUtil} from "utils/chartUtil";
import moment from "moment";

Highcharts.setOptions({
  ...HighchartsOptionsUtil.locale.kr.globalOptions,
  exporting: {enabled: false},
  credits: {enabled: false},
  tooltip: {shared: true, headerFormat: ''},
})
HighchartsMore(Highcharts);

export class BillDashboardDataModel {
  reqCnt: number = 0;
  reqSum: number = 0;
  nonSetlCnt: number = 0;
  nonSetlSum: number = 0;
  monthReqSum: number = 0;
  reqList: ResponseChart[] = [];
}

export default function DbUserSettlementStatus() {
  const userInfo = useAppSelector(state => state.userInfo.userInfo);
  const gridRef: any = useRef<typeof Grid>();

  const [reqSearch, ] = useState<BillSearchModel>(new BillSearchModel(1, 's'));
  const [reqHeaderData, setReqHeaderData] = useState<BillDashboardDataModel>(new BillDashboardDataModel());
  const [graphTypeA, setGraphTypeA] = useState<any>(() => initialGraphA([]));
  const [graphTypeB, setGraphTypeB] = useState<any>({});
  const [graphTypeC, setGraphTypeC] = useState<any>({});

  useEffect(() => {
    if (gridRef?.current) {
      gridRef.current.getInstance().refreshLayout();
    }
  }, [gridRef]);

  useEffect(() => {
    document.body.classList.add("admin");

    return () => {
      document.body.classList.remove("admin");
    };
  }, []);

  function initialGraphA(monthList: string[]) {
    return {
      colors: ["#3c92ff", "#2bd7f6"],
      chart: {
        type: "area",
        backgroundColor: "transparent",
        zoomType: 'x',
        height: 380,
      },
      title: { text: "" },
      exporting: { enabled: false },
      credits: { enabled: false },
      yAxis: {
        title: { text: "(천원)", style: { color: "#fff" } },
        gridLineColor: "#666b78",
        tickColor: "#666b78",
        lineColor: "#666b78",
        labels: {
          style: { color: "#fff" },
          formatter: function (this: any) {return this.value},
        },
      },
      xAxis: {
        min: 0,
        categories: monthList,
        title: { text: "", style: { color: "#fff" } },
        gridLineColor: "#666b78",
        tickColor: "#666b78",
        lineColor: "#666b78",
        labels: { style: { color: "#fff" } },
      },
      tooltip: {
        headerFormat: "",
      },
      plotOptions: {
        area: {
          stacking: "normal",
        },
      },
      legend: {
        align: "right",
        verticalAlign: "top",
        symbolHeight: 10,
        itemStyle: {
          fontSize: "13px",
          color: "#A2A2A2",
        },
      },
    }
  }

  useEffect(() => {
    const graphTypeB = {
      chart: {
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 250,
        margin: [0, 0, 0, 300],
        spacingTop: 0,
        spacingBottom: 10,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        text: "",
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "middle",
        x: 0,
        y: 0,
        symbolHeight: 10,
        borderWidth: 0,
        itemMarginTop: 10,
        useHTML: true,
        labelFormatter: function (this: any) {
          return (
            '<div class="legend-srl" style="width:240px;"><span>' +
            this.name +
            '<strong><em class="pie-cont" style="color:' +
            this.color +
            '">' +
            this.y +
            "</em>%</strong></span></div>"
          );
        },
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        valueSuffix: "%",
      },
      plotOptions: {
        series: {
          showInLegend: true,
          borderWidth: 0,
          borderRadius: 0,
          colorByPoint: true,
          size: "100%",
          dataLabels: {
            enabled: true,
            distance: "-35%",
            allowOverlap: false,
            useHTML: true,
            format:
              '<div class="pie-cont-bg"><strong class="pie-cont">{point.percentage:.1f} %</strong></div>',
          },
        },
      },
      colors: ["#545cc2", "#4b96f9", "#44c5dc"],
      series: [
        {
          name: "전력거래 유형",
          type: "pie",
          data: [
            {
              name: "직접PPA on-site(50MWh)",
              y: 60,
            },
            {
              name: "직접PPA off-site(20MWh)",
              y: 25,
            },
            {
              name: "제3자 PPA(10MWh)",
              y: 15,
            },
          ],
        },
      ],
    };

    const graphTypeC = {
      chart: {
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 250,
        margin: [0, 0, 0, 300],
        spacingTop: 0,
        spacingBottom: 10,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        text: "",
      },
      subtitle: {
        useHTML: true,
        text: getSubtitle(),
        verticalAlign: "middle",
        floating: true,
        y: 10,
        x: 150,
      },
      legend: {
        layout: "vertical",
        align: "left",
        verticalAlign: "middle",
        x: 0,
        y: 0,
        symbolHeight: 10,
        borderWidth: 0,
        itemMarginTop: 10,
        useHTML: true,
        labelFormatter: function (this: any) {
          return (
            '<div class="legend-srl" style="width:260px;"><span>' +
            this.name +
            '<strong><em class="pie-cont" style="color:' +
            this.color +
            '">' +
            this.y +
            "</em>건</strong></span></div>"
          );
        },
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        valueSuffix: "건",
      },
      plotOptions: {
        series: {
          showInLegend: true,
          borderWidth: 0,
          borderRadius: 0,
          colorByPoint: true,
          size: "100%",
          innerSize: "50%",
          dataLabels: {
            enabled: true,
            distance: "-1%",
            allowOverlap: false,
            useHTML: true,
            format:
              '<div class="pie-cont-bg"><strong class="pie-cont">{point.y} 건</strong></div>',
          },
        },
      },
      colors: ["#545cc2", "#4b96f9"],
      series: [
        {
          name: "참여 기업 수",
          type: "pie",
          data: [
            {
              name: "매칭",
              y: 8,
            },
            {
              name: "비매칭",
              y: 2,
            },
          ],
        },
      ],
    };

    setGraphTypeB(graphTypeB);
    setGraphTypeC(graphTypeC);
  }, []);

  const getSubtitle = () => {
    return `<span class="donut-stit">총 <em class="" style="display:inline-block;vertical-align:middle;margin:0 .15rem 0 .05rem;font-size:2rem;">10</em>건</span>`;
  };

  const sampleData = [
    {
      name: '시스템 점검',
      date: '2023-07-28',
    },
    {
      name: '23년 제 2차 녹색 프리미엄 입찰….',
      date: '2023-07-28',
    },
    {
      name: '발전사업자 RE100 거래 유의사항',
      date: '2023-07-28',
    },
    {
      name: '발전사업자 RE100 거래 유의사항2',
      date: '2023-07-28',
    },
    {
      name: '발전사업자 RE100 거래 유의사항3',
      date: '2023-07-28',
    },
    {
      name: '발전사업자 RE100 거래 유의사항4',
      date: '2023-07-28',
    }
  ];

  const columns: any = [
    {
      header: '제목',
      name: 'name',
      align: "center",
      whiteSpace: 'normal',
      formatter: (e: any) => e.value,
    },
    {
      header: '등록일',
      name: 'date',
      width: 120,
      align: "center",
      whiteSpace: 'normal',
      formatter: (e: any) => e.value,
    }
  ]

  useEffect(() => {
    BillService.getInstance().findPPADashboardData(reqSearch)
    .then((response) => {
      if(response && ResponseResultCode.Y === response.resultCode) {
        setReqHeaderData(response.data)
        convertReqDataToChart(response.data.reqList);
      }
    })
  }, []);

  function convertReqDataToChart(list: ResponseChart[]) {
    const bsdt = moment(reqSearch.selectMonth + '01')
    const stdt = moment(reqSearch.selectMonth + '01').format('YYYYMMDD');
    const endt = bsdt.subtract(1, 'y').add(1, 'month').format('YYYYMMDD');
    const monthList = ChartUtil.getMonthRangeForCategory(endt, stdt);
    setGraphTypeA({
      ...initialGraphA(monthList),
      series: list.map(item => {
        return {
          name: ('req' === item.legend) ? '정산금' : '수령정산금',
          data: ChartUtil.setMissingMonthlyData(item.data, monthList),
          lineWidth: 2,
          marker: {
            symbol: 'circle',
            lineWidth: 2,
            lineColor: ('req' === item.legend) ? "rgba(60,146,255,1)" : "rgba(43,215,246,1)",
            fillColor: ('req' === item.legend) ? "rgba(60,146,255,1)" : "rgba(43,215,246,1)",
          },
          fillColor: {
            linearGradient: {
              x1: 0, y1: 0, x2: 0, y2: 1,
            },
            stops: [
              [0, ('req' === item.legend) ? "rgba(60,146,255,.7)" : "rgba(43,215,246,.7)"],
              [1, ('req' === item.legend) ? "rgba(60,146,255,.4)" : "rgba(43,215,246,.4)"]
            ]
          }
        };
      })
    });
  }

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <div className="box-style5">
              <ul className="graph-list-type1">
                <li className="ac">
                  <h5 className="titT3">누적 청구 금액</h5>
                  <strong>
                    <CountUp end={Number(reqHeaderData.reqSum/1000)} duration={0.5} />
                  </strong>
                  <em>천원</em>
                </li>
                <li className="ac">
                  <h5 className="titT3">이번달 청구 금액</h5>
                  <strong>
                    <CountUp end={Number(reqHeaderData.monthReqSum)} duration={0.5} />
                  </strong>
                  <em>원</em>
                </li>
                <li className="ac">
                  <h5 className="titT3">총 청구 건수</h5>
                  <strong>
                    <CountUp end={Number(reqHeaderData.reqCnt)} duration={0.5} />
                  </strong>
                  <em>건</em>
                </li>
                <li className="ac">
                  <h5 className="titT3">미수 건수</h5>
                  <strong>
                    <CountUp end={Number(reqHeaderData.nonSetlCnt)} duration={0.5} />
                  </strong>
                  <em>건</em>
                </li>
                <li className="ac">
                  <h5 className="titT3">총 미수 금액</h5>
                  <strong>
                    <CountUp end={Number(reqHeaderData.nonSetlSum)} duration={0.5} />
                  </strong>
                  <em>원</em>
                </li>
              </ul>
            </div>

            <div id="graph-typeA" className="chart">
              <HighchartsReact
                highcharts={Highcharts}
                options={graphTypeA}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-wrap">
        <div className="col-md-4">
          <div className="cont">
            <h3 className="titT1">전력거래 유형</h3>
            <div id="graph-typeB" className="chart">
              <HighchartsReact
                highcharts={Highcharts}
                options={graphTypeB}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cont">
            <h3 className="titT1">전력거래 매칭 현황</h3>
            <div id="graph-typeC" className="chart">
              <HighchartsReact
                highcharts={Highcharts}
                options={graphTypeC}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cont">
            <h3 className="titT1">공지사항</h3>
            {/*<ArcGrid columns={columns}*/}
            {/*         data={sampleData}*/}
            {/*         useStaticData={true}*/}
            {/*         rowHeaders={['noNum']}*/}
            {/*         rowHeight={30}*/}
            {/*         bodyHeight={200}*/}
            {/*         usePagination={false}*/}
            {/*/>*/}
            <Grid ref={gridRef}
              data={sampleData}
              columns={columns}
              rowHeight={'auto'}
              bodyHeight={200}
              scrollX={false}
              scrollY={true}
              draggable={false}
              onGridUpdated={() => gridRef.current.getInstance().refreshLayout()}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
