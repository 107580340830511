import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ResponseResultCode } from "model/response-api";
import { openModal } from "reducers/modalSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Mhdlg } from "model/cp/Mhdlg";
import { MhdlgService } from "services/cp/MhdlgService";
import ArcDownload, { dataSourceProps } from "components/arc/ArcDownload";
import { FileInfoModel } from "model/common/fileInfoModel";
import { showAndHideSpinner } from "reducers/spinnerSlice";
import { UserInfoUtil } from "utils/userInfoUtil";
import { useAppSelector } from "stores/hook";
import { UserInfoModel } from "model/ua/UserInfoModel";
import ArcButton from "components/arc/ArcButton";
import AppAlert from "components/common/AppAlert";

export default function detail() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();
  const [mhdlgDetail, setMhdlgDetail] = useState<Mhdlg | null>(null);
  const { mhdlgId } = useParams<{ mhdlgId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 첨부파일
  const mhdlgFile = mhdlgDetail?.file ? mhdlgDetail.file : null;
  const mhdlgFileList: dataSourceProps[] = mhdlgFile ? (mhdlgFile.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  const bizFile = mhdlgDetail?.bizFile ? mhdlgDetail.bizFile : null;
  const bizFileList: dataSourceProps[] = bizFile ? (bizFile.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  const moniFile = mhdlgDetail?.moniFile ? mhdlgDetail.moniFile : null;
  const moniFileList: dataSourceProps[] = moniFile ? (moniFile.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  useEffect(() => {
    async function fetchMhdlgDetail() {
      try {
        if (mhdlgId) {
          const response = await MhdlgService.getInstance().getMhdlgDetail(mhdlgId);
          if (response && response.resultCode === ResponseResultCode.Y && response.data) {
            setMhdlgDetail(response.data);
          } else {
          }
        }
      } catch (error) {
      }
    }

    fetchMhdlgDetail();

    return () => { };
  }, [mhdlgId]);

  const handleCancel = () => {
    navigate(`/cp/reduce/methodology`);
  };

  const templateBottomButtonByUser = () => {
    return (
      <>
        <ArcButton type="delete" hasIcon={true} onClick={handleClickDelete} />
        <ArcButton
          type="modify"
          hasIcon={true}
          onClick={() => {
            navigate(`/cp/reduce/methodology/insert`, { state: JSON.stringify(mhdlgDetail) });
          }}
        />
      </>
    );
  };

  async function handleClickMhdlgDownload(selectFile: dataSourceProps) {
    if (mhdlgFile) {
      const selectedFile = (mhdlgFile.files ?? []).find(f => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await MhdlgService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  async function handleClickBizDownload(selectFile: dataSourceProps) {
    if (bizFile) {
      const selectedFile = (bizFile.files ?? []).find(f => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await MhdlgService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }


  async function handleClickMoniDownload(selectFile: dataSourceProps) {
    if (moniFile) {
      const selectedFile = (moniFile.files ?? []).find(f => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await MhdlgService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  function handleClickDelete() {
    dispatch(
      openModal({
        path: "ot/confirm/apply/delete/confirm",
        target: AppAlert,
        wrapStyle: { width: "20rem" },
        confirmProps: {
          type: "delete",
          title: t("confirm.label.message.delete"),
          onConfirm: deleteMhdlg,
        },
      })
    );
  }

  const deleteMhdlg = async () => {
    try {
      const response = await MhdlgService.getInstance().deleteMhdlg(mhdlgDetail!);
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          navigate(`/cp/reduce/methodology`);
          toast.success(t("common.message.success.delete", { title: t("완료") }));
        } else {
          toast.error(t("common.message.error.data"));
        }
      } else {
        toast.error(t("common.message.error.system"));
      }
    } catch (error) {
      toast.error(t("common.message.error.system"));
    }
  };

  return (
    <div className="col-wrap">
      <div className="col-md-12">
        <div className="cont">
          <div className="tbl-st2 mgt20">
            <table>
              <caption>신고에 대한 문의사항</caption>
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "auto" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "auto" }} />
              </colgroup>
              <tbody className="al">
                <tr>
                  <td scope="col" colSpan={4} className="ac tbl-bg-g tbl-L-T2">
                    <span className="font-F fs16">{mhdlgDetail?.mhdlgNm}</span>
                  </td>
                </tr>
                <tr>
                  <td>방법론 분야</td>
                  <td>{mhdlgDetail?.fareaNm}</td>
                  <td>세부 분류</td>
                  <td>{mhdlgDetail?.scgNm}</td>
                </tr>
                <tr>
                  <td>방법론 고유 번호</td>
                  <td>{mhdlgDetail?.unqNo}</td>
                  <td>방법론 유효 시작일</td>
                  <td>
                    {mhdlgDetail?.vldStrtYmd
                      ? `${mhdlgDetail?.vldStrtYmd.slice(0, 4)}-${mhdlgDetail?.vldStrtYmd.slice(
                        4,
                        6
                      )}-${mhdlgDetail?.vldStrtYmd.slice(6, 8)}`
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>키워드</td>
                  <td>{mhdlgDetail?.kwrdNm}</td>
                  <td>적용 방법론</td>
                  <td>{mhdlgDetail?.itmNm}</td>
                </tr>
                <tr>
                  <td>방법론 첨부파일</td>
                  <td colSpan={3}>
                    <ArcDownload dataSource={mhdlgFileList} onDownload={handleClickMhdlgDownload} />
                  </td>
                </tr>
                <tr>
                  <td>사업계획서 첨부파일</td>
                  <td colSpan={3}>
                    <ArcDownload dataSource={bizFileList} onDownload={handleClickBizDownload} />
                  </td>
                </tr>
                <tr>
                  <td>모니터링 첨부파일</td>
                  <td colSpan={3}>
                    <ArcDownload dataSource={moniFileList} onDownload={handleClickMoniDownload} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btns ar">
            {UserInfoUtil.isAdmin(userInfo)
              ? <>{templateBottomButtonByUser()}</>
              : <></>
            }
            <ArcButton type="text" text={"목록"} onClick={handleCancel} />
          </div>
        </div>
      </div>
    </div>
  );
}
