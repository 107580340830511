import ArcGrid, {
  CustomCommonCodeRenderer,
  CustomSelectCommonCodeRenderer,
  GridColumn,
  GridEditor,
  GridEditorInfo,
} from "components/arc/ArcGrid";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ComCdService } from "services/cp/ComCdService";
import ArcDatePicker from "components/arc/ArcDatePicker";
import moment from "moment";
import { parseNumberWithCommaDecimal } from "utils/stringUtil";
import { CodeUtil } from "utils/codeUtil";
import { useAppSelector } from "stores/hook";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { UserInfoUtil } from "utils/userInfoUtil";
import { AuthConstant } from "constants/authConstant";

export default function SupplyEnergy() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();
  const gridRef: any = useRef<typeof ArcGrid>();
  const [fuelCd, setFuelCd] = useState<any[]>([]);
  const [unitCd, setUnitCd] = useState<any[]>([]);
  const [year, setYear] = useState<number>(moment().year());

  useEffect(() => {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_OTSD_SPLY_ENRG_CL_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setFuelCd(cdOptions);
        } else {
          setFuelCd([]);
        }
      })
      .catch(() => toast.error(t("common.message.error.data")));
  }, [gridRef.current]);

  useEffect(() => {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_ENRG_SPLY_UNIT_CD")
      .then((response) => {
        if (response) {
          if (response.data.data.length > 0) {
            const unitOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
            setUnitCd(unitOptions);
          }
        }
      })
      .catch(() => toast.error(t("common.message.error.data")));
  }, [gridRef.current]);

  const gridNumberParse = (e: any) => {
    if (e.value || 0 === e.value) {
      return parseNumberWithCommaDecimal(e.value);
    }
  };

  // 그리드 컬럼
  const gridColumns: GridColumn[] = [
    {
      header: "emsnCoefId",
      name: "emsnCoefId",
      hidden: true,
    },
    {
      header: t("cp.inventory.reference.supplyEnergy.header.fuelCd"),
      name: "fuelCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: t("common.unit.unit"),
      name: "unitCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: t("cp.inventory.reference.supplyEnergy.header.cbdxQnt"),
      name: "cbdxQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.supplyEnergy.header.methnQnt"),
      name: "methnQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
    {
      header: t("cp.inventory.reference.supplyEnergy.header.dnmxEmsnQnt"),
      name: "dnmxEmsnQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: gridNumberParse,
    },
  ];

  const numberEditorEmission = (length: number, decimal: number): Partial<GridEditorInfo> => {

    return {
      options: {
        type: "number",
        validation: {
          dataType: "number",
          required: true,
          regExp:
            new RegExp(`^\\d{0,${length - decimal}}(\\.\\d{0,${decimal}})?$`),
          regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
            len: length,
            dec: decimal
          })
        }
      },
    }
  }

  //그리드 수정
  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: { aplyYy: year },
    editableColumns: [
      {
        columnName: "fuelCd",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: fuelCd,
          validation: {
            validatorFn: (value: any) => {

              if (value === null || value === undefined) return false;
              if (value?.comCdExpl === undefined) return false;
              const gridInstance = gridRef.current?.getInstance();
              if (!gridInstance) return false;
              const data = gridInstance.getData();
              if (!Array.isArray(data) || data.length === 0) return false;
              return data.filter((e: any) => e?.fuelCd?.comCdExpl === value.comCdExpl).length === 1;
            },
          },
        },
      },
      {
        columnName: "unitCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitCd,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            },
          },
        },
      },
      {
        columnName: "cbdxQnt",
        editorType: "text",
        ...numberEditorEmission(15, 4)
      },
      {
        columnName: "methnQnt",
        editorType: "text",
        ...numberEditorEmission(15, 4)
      },
      {
        columnName: "dnmxEmsnQnt",
        editorType: "text",
        ...numberEditorEmission(15, 4)
      },
    ],
  };

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">{t("cp.inventory.reference.supplyEnergy.title")}</h3>
            <ArcDatePicker
              onChange={(data) => setYear((data.fromDate ?? new Date()).getFullYear())}
              viewType="year"
              fromDate={String(year)}
              showButtonBar={false}
              id="selectedYear"
              name="selectedYear" />
          </div>
          <div className="fs14">

            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                gridRef={gridRef}
                key={`${fuelCd.length}-${unitCd.length}`}
                datasource={{
                  readData: { url: "/cp/refSplyEnrg", method: "GET", initParams: { year: year } },
                  createData: { url: "/cp/refSplyEnrg", method: "POST" },
                  updateData: { url: "/cp/refSplyEnrg", method: "PUT" },
                  deleteData: {
                    url: "/cp/refSplyEnrg",
                    method: "DELETE",
                    serializer(params) {
                      const ids: any = params.deletedRows?.map((row: any) => ({ emsnCoefId: row.emsnCoefId }));
                      return encodeURI(`deletedRows=${JSON.stringify(ids)}`);
                    },
                  },
                }}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                usePagination={false}
                useGridInfoHeader={true}
                editor={gridEditor}
                complexHeader={{ complexColumns: [], height: 80 }} />
              :
              <ArcGrid
                gridRef={gridRef}
                datasource={{
                  readData: { url: "/cp/refSplyEnrg", method: "GET", initParams: { year: year } },
                }}
                columns={gridColumns}
                usePagination={false}
                useGridInfoHeader={true}
                complexHeader={{ complexColumns: [], height: 80 }}
              />
            }
          </div>
          <p className="bu-atte">{t("cp.inventory.reference.supplyEnergy.gridRmk")}</p>
        </div>
      </div>
    </div>
  );
}
