import Highcharts from "highcharts/highstock";
import {parseNumberWithCommaDecimal} from "./stringUtil";

const colors = ["#B132FF", "#7432FF", "#2F2185", "#3240FF", "#3C91FF", "#48E3FF", "#1EDDC1", "#3EDC6A", "#B1D940", "#F6C463", "#E58A5E", "#DB6F6F"];
const colorsRevered = [...colors].reverse();
const locale = {
  kr: {
    categoryType: {
      month: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"]
    },
    globalOptions: {
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
        loading: '불러오는 중...',
        months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        weekdays: ['일', '월', '화', '수', '목', '금', '토'],
        shortMonths: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        rangeSelectorFrom: "시작",
        rangeSelectorTo: "종료",
        rangeSelectorZoom: "확대",
        downloadCSV: 'CSV 다운로드',
        downloadJPEG: 'JPEG 다운로드',
        downloadPDF: 'PDF 다운로드',
        downloadPNG: 'PNG 다운로드',
        downloadSVG: 'SVG 다운로드',
        exportInProgress: '다운로드 중...',
        noData: '조회된 데이터가 없습니다',
        printChart: '출력',
        resetZoom: '돌아가기',
        resetZoomTitle: '돌아가기',
        viewDate: '데이터 표로 보기',
        viewFullscreen: '전체 화면으로 보기'
      }
    },
    time: {
      timezone: 'Asia/Seoul',
      useUTC: false
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%b.%e',
      week: '%b.%e',
      month: '%y.%b',
      year: '%Y'
    },
    stockChart: {
      tooltip : {
        xDateFormat: "%Y년 %b월 %e일",
        headerFormat: '<span style="font-size: 0.6rem; color: {point.color};">●</span><span style="font-size: 0.6rem"> {point.key}</span><br/>',
        pointFormatter: function(this: any) {
          const point = this;
          let tooltipContent = '';
          if (point.series.type === 'candlestick') {
            tooltipContent += `<span>시가: ${Highcharts.numberFormat(point.open, 0, ".", ",")}<br/>`
                + `고가: ${parseNumberWithCommaDecimal(point.high)}<br/>`
                + `저가: ${parseNumberWithCommaDecimal(point.low)}<br/>`
                + `종가: ${parseNumberWithCommaDecimal(point.close)}</span>`;
          } else if (point.series.type === 'column') {
            tooltipContent += `<br/><span>거래량: ${parseNumberWithCommaDecimal(point.y)}`
          }
          return tooltipContent;
        },
        split: false,
        shared: true,
      }
    }
  }
}

export const HighchartsOptionsUtil = {
  locale: locale,
  colors: colors,
  colorsRevered: colorsRevered
}