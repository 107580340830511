import ArcButton, {ButtonColor, ButtonShape, ButtonSize} from "components/arc/ArcButton";
import ArcCheckbox from "components/arc/ArcCheckbox";
import ArcInput from "components/arc/ArcInput";
import ArcSelectbox, {SelectboxSize} from "components/arc/ArcSelectbox";
import {t} from "i18next";
import {ResponseResultCode} from "model/response-api";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {closeModal, openModal} from "reducers/modalSlice";
import {setSignUpComCd, setSignUpInfo} from "reducers/signUpSlice";
import {SignUpService} from "services/signUp/SignUpService";
import {RootState} from "stores/store";
import SignUpTab1Modal from "./SignUpTab1Modal";
import {UserService} from "services/cp/UserService";

export default function SignUpTab1() {
  const navigate = useNavigate();
  const signUp = useSelector((state: RootState) => state.signUp);
  const dispatch = useDispatch();

  const [befEmail, setBefEmail] = useState("");
  const [aftEmail, setAftEmail] = useState("");
  const [select, setSelect] = useState("self");
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [checkEmail, setCheckEmail] = useState(false);    // 이메일 인증 요청 보냄 여부
  const [fullEmail, setFullEmail] = useState<string>();


  const [openIndex, setOpenIndex] = useState(null);
  const toggleOpen = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    UserService.getInstance().getComcd()
    .then((res: any) => {
      dispatch(setSignUpComCd(res.data.data));
    })
  }, []);


  async function clickNextBtn() {
    if (!check1 || !check2) {
      toast.error(t('필수 약관을 체크해주시기 바랍니다.'));
      return;
    }

    if (!checkEmail) {
      toast.error(t('이메일 인증을 완료하시기 바랍니다.'));
      return;
    }

    let mktgJoinAgreYn = check3 ? "Y" : "N";

    try {
      // 이메일 인증 성공
      const response = await SignUpService.getInstance().getMailCheck(fullEmail);
      const data: any = response?.data;

      if (!response || response.resultCode !== ResponseResultCode.Y || data.verify !== "Y") {
        toast.error(t('이메일 인증을 완료해주시기 바랍니다.'));
        return;
      }

      // 임시저장 데이터 확인
      const existDataResponse: any = await SignUpService.getInstance().getExistData(data);
      const existData = existDataResponse?.data;

      if (!existDataResponse || existDataResponse.resultCode !== ResponseResultCode.Y) {
        toast.error(t("기존 임시저장 데이터를 가져오는데 오류가 발생하였습니다."));
        return;
      }

      let newSignUpInfo = {
        ...signUp.signUpInfo,
        mktgJoinAgreYn: mktgJoinAgreYn, // mktgJoinAgreYn 변경
        tchgrEmailAddr: fullEmail
      };
      if (existData != null) { // 임시저장 데이터가 있다면
        let data = [existData, newSignUpInfo];
        await selectModal(data); // modal은 modal 컴포넌트에서 navigate 실행
      } else {
        dispatch(setSignUpInfo(newSignUpInfo));
        navigate(`/sign-up/2`);
      }


    } catch (error) {
      toast.error(t("시스템 오류가 발생하였습니다. 다시 시도해 주세요."));
    }
  }


  const emailSelectbox = ([
    {label: "직접 입력", value: "self"},
    {label: "daum.net", value: "daum.net"},
    {label: "gmail.com", value: "gmail.com"},
    {label: "kakao.com ", value: "kakao.com "},
    {label: "nate.com ", value: "nate.com "},
    {label: "naver.com", value: "naver.com"},
  ]);

  const handleChangeSelect = (event: any) => {
    setSelect(event.target.value);
    if (event.target.value == "self") {
      setAftEmail("");
    } else {
      setAftEmail(event.target.value);
    }

  }

  const inputBefEmail = (event: any) => {
    setBefEmail(event);
    setCheckEmail(false); // 이메일 인증 완료 후 이메일 수정하면 다시 check 결과 false
  }

  const inputAftEmail = (event: any) => {
    setAftEmail(event);
    setCheckEmail(false);
  }

  const clickEmailSubmit = async () => {

    if (befEmail != "" && aftEmail != "") {
      let concatEmail = befEmail + "@" + aftEmail;
      setFullEmail(concatEmail);
      const data = {
        "address": concatEmail
      }
      // 인증요청 보내기 전 가입 여부 확인
      const checkResponse = await SignUpService.getInstance().getSignUpCheck(data); // 가입 이력 존재하면 모달 띄워 예 아니요 선택
      if (checkResponse && checkResponse.resultCode === ResponseResultCode.Y) {
        if (checkResponse.data === "N") { // 기존 가입 이력이 없다면 (임시저장한 유저도 포함)

          const response = await SignUpService.getInstance().postMailConfirm(data);
          if (response && response.resultCode === ResponseResultCode.Y) {
            toast.success(t('이메일 인증을 요청보냈습니다.'))
            setCheckEmail(true); // 이메일 인증 요청 완료
          } else {
            toast.error(t('이메일 인증 요청을 실패하였습니다.'));
          }
        } else {  // 기존 가입 이력이 있다면
          toast.error(t("기존 가입 이력이 있는 이메일입니다."));
        }
      } else {
        toast.error(t('가입 여부 확인에 실패하였습니다.'));
      }
    } else {
      toast.error(t('이메일을 정확히 작성해주시기 바랍니다.'));
    }
  }

  function selectModal(data: any): Promise<void> {
    return new Promise((resolve) => {
      const closeFormDialog = () => {
        dispatch(closeModal());
        resolve();
      };

      dispatch(
          openModal({
            path: "/sign-up/1/modal",
            target: SignUpTab1Modal,
            modalProps: {
              title: t("선택"),
              data: data,
              onClose: closeFormDialog,
            },
          })
      );
    });
  }


  const clickCheck1 = (isChecked: any) => {
    setCheck1(isChecked)
  }
  const clickCheck2 = (isChecked: any) => {
    setCheck2(isChecked)
  }
  const clickCheck3 = (isChecked: any) => {
    setCheck3(isChecked)
  }

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont member-h">
            <div className="tit-step">
              <h1 className="titT2">회원가입</h1>
              {/* 산단관리는 탭 4개 */}
              {signUp.signUpInfo.emksMbrsClCd == "1" ?
                <ul className="tab-order-st1">
                  <li className="on">
                    <div><strong>01</strong><span>본인인증</span></div>
                  </li>
                  <li>
                    <div><strong>02</strong><span>정보입력</span></div>
                  </li>
                  <li>
                    <div><strong>03</strong><span>고객정보</span></div>
                  </li>
                  <li>
                    <div><strong>04</strong><span>가입완료</span></div>
                  </li>
                </ul>
                :
                <ul className="tab-order-st1">
                  <li className="on">
                    <div><strong>01</strong><span>본인인증</span></div>
                  </li>
                  <li>
                    <div><strong>02</strong><span>정보입력</span></div>
                  </li>
                  <li>
                    <div><strong>03</strong><span>가입완료</span></div>
                  </li>
                </ul>
              }

            </div>
            <div className="join-box">
              <h2>본인인증</h2>
              <p className="desc">
                익명 사용자로 인한 피해를 방지하기 위해 메일을 통한 본인 인증을 시행하고 있습니다.<br/>
                인증메일이 전송될 정확한 이메일 주소를 입력해 주세요.
              </p>
              <div>
                <div className="mail-input">
                  <div className="w200">
                    <ArcInput
                      id="input-1"
                      name="input-1"
                      inputType="text"
                      value=""
                      placeholder="이메일 계정"
                      title="필수입력 이메일 계정 입력"
                      displayTitle={false}
                      isRequired={true}
                      prefix=""
                      validateMessage="이메일 계정을 입력하세요."
                      onChange={inputBefEmail}
                      className={"inpSel disIb"}
                      type={"w200"}
                      maxLength={50}
                      readonly={false}
                      disabled={false}
                    />
                  </div>
                  <span className="input-util">@</span>
                  <div>
                    <ArcInput
                      key={select}
                      id={"input-2"}
                      name={"input-2"}
                      value={select === "self" ? "" : select}
                      inputType={"text"}
                      isRequired={true}
                      title={"필수입력 이메일 주소(도메인)"}
                      placeholder={"이메일 주소(도메인)"}
                      validateMessage={"이메일 주소(도메인)을 입력하세요."}
                      onChange={inputAftEmail}
                      className={"InpSel disIb mgl0"}
                      maxLength={200}
                      type={"w200"}
                      readonly={false} // 가입이력 x라면 입력 가능
                      disabled={(select != "self")}
                    />
                  </div>
                  <ArcSelectbox name={"selectEmail"}
                                options={emailSelectbox}
                                onChange={handleChangeSelect}
                                size={SelectboxSize.px200}
                                isRequired={true}
                                title={"이메일 주소(도메인) 선택"}

                  />
                  <ArcButton type={"custom"} color={ButtonColor.btn3} text={"인증요청"}
                             shape={ButtonShape.normal} onClick={clickEmailSubmit}/>
                </div>
                <p className="bu-atte">이메일 인증 완료 후 다음 단계를 진행하실 수 있습니다.</p>
                <p className="bu-atte">인증 메일을 받지 못하셨다면 스팸 메일함을 확인해 주세요.</p>
              </div>
            </div>

            <div className="join-box">
              <h2 className="titT1">약관동의</h2>
              <p className="desc">회원가입을 위해 이용약관 및 개인정보 수집 및 이용에 관한 안내를 읽고 동의해 주세요.</p>
              <div className="accodian">
                <ul>
                  <li>
                    <div className="acc-tit">
                      <span className="checkbox-radio-type02">
                        <ArcCheckbox
                          onChecked={clickCheck1}
                          text={"(필수) 서비스 이용약관"}
                          id={"1"} name={"1"}
                          checked={check1}/>
                      </span>
                      <button className={`tog-btn ${openIndex === 0 ? 'open' : ''}`} onClick={() => toggleOpen(0)}></button>
                    </div>
                    <div className="claus"
                         style={{display: openIndex === 0 ? 'block' : 'none'}}>
                      <div>
                        <span>서비스 이용약관</span>
                        <ul>
                          <li>1. 제1조 (목적)</li>
                          <li>2. 본 약관은 한국전력공사(이하 ‘회사’라 한다.)가 운영하는 에너지클라우드 웹사이트의 서비스를
                            이용함에 있어 발생하는 회사와 회원의 서비스 이용조건 및 절차, 권리와 의무, 기타 필요한 제반사항의 규정을
                            목적으로 합니다.
                          </li>
                          <li>3. 제2조 (이용약관의 효력 및 변경)
                            <ul>
                              <li>a. 1&#41;이 약관은 에너지클라우드 웹사이트에 게시하거나 전자우편의 방법으로 회원에게
                                공지됨으로써 그 효력이 발생됩니다.
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul>
                          <li>1. 제1조 (목적)</li>
                          <li>2. 본 약관은 한국전력공사(이하 ‘회사’라 한다.)가 운영하는 에너지클라우드 웹사이트의 서비스를
                            이용함에 있어 발생하는 회사와 회원의 서비스 이용조건 및 절차, 권리와 의무, 기타 필요한 제반사항의 규정을
                            목적으로 합니다.
                          </li>
                          <li>3. 제2조 (이용약관의 효력 및 변경)
                            <ul>
                              <li>a. 1&#41;이 약관은 에너지클라우드 웹사이트에 게시하거나 전자우편의 방법으로 회원에게
                                공지됨으로써 그 효력이 발생됩니다.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="acc-tit mgt0">
                      <span
                        className="checkbox-radio-type02">
                        <ArcCheckbox
                          onChecked={clickCheck2}
                          text={"(필수) 개인정보 처리방침"}
                          id={"2"} name={"2"}
                          checked={check2}/>
                      </span>
                      <button className={`tog-btn ${openIndex === 1 ? 'open' : ''}`} onClick={() => toggleOpen(1)}></button>
                    </div>
                    <div className="claus"
                         style={{display: openIndex === 1 ? 'block' : 'none'}}>
                      <div>
                        <span>서비스 이용약관</span>
                        <ul>
                          <li>1. 제1조 (목적)</li>
                          <li>2. 본 약관은 한국전력공사(이하 ‘회사’라 한다.)가 운영하는 에너지클라우드 웹사이트의 서비스를
                            이용함에 있어 발생하는 회사와 회원의 서비스 이용조건 및 절차, 권리와 의무, 기타 필요한 제반사항의 규정을
                            목적으로 합니다.
                          </li>
                          <li>3. 제2조 (이용약관의 효력 및 변경)
                            <ul>
                              <li>a. 1&#41;이 약관은 에너지클라우드 웹사이트에 게시하거나 전자우편의 방법으로 회원에게
                                공지됨으로써 그 효력이 발생됩니다.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="acc-tit mgt0">
                      <span
                        className="checkbox-radio-type02">
                        <ArcCheckbox
                          onChecked={clickCheck3}
                          text={"(선택) 마케팅 수신동의"}
                          id={"3"} name={"3"}
                          checked={check3}/>
                      </span>
                      <button className={`tog-btn ${openIndex === 3 ? 'open' : ''}`} onClick={() => toggleOpen(3)}></button>
                    </div>
                    <div className="claus" style={{display: openIndex === 3 ? 'block' : 'none'}}>
                      <div>
                        <span>서비스 이용약관</span>
                        <ul>
                          <li>1. 제1조 (목적)</li>
                          <li>2. 본 약관은 한국전력공사(이하 ‘회사’라 한다.)가 운영하는 에너지클라우드 웹사이트의 서비스를
                            이용함에 있어 발생하는 회사와 회원의 서비스 이용조건 및 절차, 권리와 의무, 기타 필요한 제반사항의 규정을
                            목적으로 합니다.
                          </li>
                          <li>3. 제2조 (이용약관의 효력 및 변경)
                            <ul>
                              <li>a. 1&#41;이 약관은 에너지클라우드 웹사이트에 게시하거나 전자우편의 방법으로 회원에게
                                공지됨으로써 그 효력이 발생됩니다.
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <ArcButton type={"custom"} color={ButtonColor.btn2} text={"다음"}
                       shape={ButtonShape.normal} size={ButtonSize.large}
                       onClick={clickNextBtn}/>
          </div>
        </div>
      </div>
    </section>
  )
}