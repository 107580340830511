import { useEffect } from "react";

export default function ActvModal(props: any) {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          function SetOZParamters_OZViewer() {
            let oz;
            oz = document.getElementById("OZViewer");
            oz.sendToActionScript("connection.servlet", "${process.env.REACT_APP_URL_REPORT}/server");
            oz.sendToActionScript("connection.reportname", "/cp_actv.ozr");
            oz.sendToActionScript("connection.pcount", "1");
            oz.sendToActionScript("connection.args1", 'data=` + JSON.stringify(props.data) + `');
            return true;
          }
          start_ozjs("OZViewer", "${process.env.REACT_APP_URL_REPORT}/ozhviewer/");
        `;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (

        <div style={{ margin: "20px" }}>
            <div className="inp-flex">
                <div id="OZViewer" className="OZViewer" style={{ overflowY: "auto", height: "650px" }}></div>
            </div>
        </div>

    )
}