export default class TypeUtil {
  static getType(obj: any) {
    return Object.prototype.toString.call(obj).slice(8, -1)
  }

  static isFunction(obj: any) {
    return 'Function' === this.getType(obj)
  }

  static isArray(obj: any): obj is any[] {
    return 'Array' === this.getType(obj) && Array.isArray(obj)
  }

  static isObject(obj: any) {
    return 'Object' === this.getType(obj)
  }

  static isNumber(obj: any) {
    return 'Number' === this.getType(obj)
  }

  static isString(obj: any) {
    return 'String' === this.getType(obj)
  }

  static isBoolean(obj: any) {
    return 'Boolean' === this.getType(obj)
  }

  static isNull(obj: any) {
    return 'Null' === this.getType(obj)
  }

  static isUndefined(obj: any) {
    return 'Undefined' === this.getType(obj)
  }
}