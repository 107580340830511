import React, { useEffect, useState } from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import NavSlider from "components/layout/comp/NavSlider";
import Account from "components/layout/comp/Account";
import { useAppDispatch, useAppSelector } from "stores/hook";
import { setMenus } from "reducers/sidebarSlice";
import {EmarketMenuModel, setCurrentMenu, setCustomPageTitle} from "reducers/userMenuSlice";
import { AuthConstant } from 'constants/authConstant';
import { dbName } from 'routers/dbRouter';
import { ObjectUtils } from "primereact/utils";
import { UserInfoUtil } from "utils/userInfoUtil";
import SideMenu from "components/layout/comp/SideMenu";
import MyPageSideMenu from "components/layout/comp/MyPageSideMenu";
import {myPageName} from "routers/mpRouter";
import {Helmet} from "react-helmet-async";
import PageTitle from "./comp/PageTitle";

export default function Header() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userMenu = useAppSelector(state => state.userMenu.userMenu);
  const userInfo = useAppSelector(state => state.userInfo.userInfo);

  useEffect(() => {
    dispatch(setMenus(userMenu));
    findAndSetPageTitle();
  }, [userMenu])

  useEffect(() => {
    findAndSetPageTitle();
  }, [location, userMenu]);

  useEffect(() => {
    if(UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)) {
      if(location.pathname.startsWith('/db')) {
        document.body.classList.add('admin');
      } else {
        document.body.classList.remove('admin');
      }
    } else {
      document.body.classList.remove('admin');
    }
  }, [location, userInfo]);

  function findAndSetPageTitle() {
    if (location.pathname.startsWith('/db')) {
      dbName.forEach((item: any) => {
        if (item.path === location.pathname) {
          dispatch(setCustomPageTitle(item.pageNm));
        }
      });
    } else if(location.pathname.startsWith(('/mypage'))) {
      myPageName.forEach((item: any) => {
        if (item.path === location.pathname) {
          dispatch(setCustomPageTitle(item.pageNm));
        }
      });
    } else {
      findMenuNameFromMenu(userMenu, location.pathname);
    }
  }

  function findMenuNameFromMenu(menus: EmarketMenuModel[], pathname: string) {
    menus.forEach((menu: EmarketMenuModel) => {
      if(ObjectUtils.isNotEmpty(menu.url) && pathname.includes(menu.url)) {
        dispatch(setCustomPageTitle(menu.menuName));
        dispatch(setCurrentMenu(menu));
      } else {
        if(menu.childMenus && menu.childMenus.length > 0) {
          findMenuNameFromMenu(menu.childMenus, pathname);
        }
      }
    });
  }

  const [showSlideBar, setShowSlideBar] = useState(false);
  const [showBottomSlider, setShowBottomSlider] = useState(false);
  const [showMyPageSlideBar, setShowMyPageSlideBar] = useState(false);

  useEffect(() => {
    setShowSlideBar(false);
    setShowBottomSlider(false);
    if (UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
      ||  UserInfoUtil.hasRole(userInfo, AuthConstant.R001)
      || UserInfoUtil.hasRole(userInfo, AuthConstant.R002)
      || UserInfoUtil.hasRole(userInfo, AuthConstant.R004)) {
      setShowSlideBar(true);
      setShowBottomSlider(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if(location.pathname.startsWith('/mypage')) {
      setShowMyPageSlideBar(true);
    } else {
      setShowMyPageSlideBar(false);
    }
  }, [location]);

  return (
    <>
      {location.pathname.includes('/sign-up')
        ?
          <>
            <Helmet>
              <title>회원가입</title>
            </Helmet>
            <header id="header">
              <div className="header-wrap">
                <div className="hdt-link-member">
                  <Link to="/" title="홈 화면으로 이동">
                    <h1 className="hdt-link-logo">
                      <span className="hid">이마켓 로고</span>
                    </h1>
                  </Link>
                  <div className="util">
                    <Account />
                  </div>
                </div>
              </div>
            </header >
          </>
        :
          <header id="header">
            <div className="header-wrap">
              {showSlideBar && (
                <div className="side-wrap">
                  <Link to="/" title="홈 화면으로 이동">
                    <h1 className="logo">
                      <span className="hid">이마켓 로고</span>
                    </h1>
                  </Link>
                  {showMyPageSlideBar
                    ? (
                      <div className="gnb-wrap">
                        <div id="gnb">
                          <MyPageSideMenu />
                        </div>
                      </div>
                      )
                    : (
                      <>
                        <div className="gnb-wrap">
                          <div id="gnb">
                            <SideMenu />
                          </div>
                        </div>
                        <div className="side-b">
                          {showBottomSlider && (<NavSlider />)}
                        </div>
                      </>
                    )
                  }
                </div>
              )}
            </div>
            <div className={`hdt-link ${!showSlideBar ? 'width100 margin-left-0' : ''}`}>
              {!showSlideBar && (
                <Link to="/" title="홈 화면으로 이동">
                  <h1 className="logo">
                    <span className="hid">이마켓 로고</span>
                  </h1>
                </Link>
              )}
              <PageTitle />
              <div className="util">
                <Account />
              </div>
            </div>
          </header>
      }
    </>
  )
}

