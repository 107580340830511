import i18n from "config/translation/i18n";
import {ResponseChartData} from "model/response-chart";
import moment from "moment";
import {Constant} from "../constants/constant";

export class ChartUtil {

  static readonly monthToNumberArray = Array.from({length: 12}, (_, i) => i + 1);
  static readonly hourOfDayNumberArray = Array.from(Array(24).keys());
  static readonly hourOfDayInZeroNumberArray = this.hourOfDayNumberArray.map(_ => 0);
  static readonly dayOfMonthArray = (date: string) => {
    const y = moment(date).endOf('month').format('D');
    return Array.from({length: Number(y)}, (_, i) => i + 1);
  }


  static readonly getMonthForCategories = () => {
    return this.monthToNumberArray.map((v) => v + i18n.t('common.unit.month'))
  }

  static readonly getDayOfMonthCategories = (date: string) => {
    return this.dayOfMonthArray(date).map((v) => v + '일');
  }

  static readonly getHourOfDayForCategory = () => {
    return this.hourOfDayNumberArray.map((v) => v.toString().padStart(2, "0") + ':00');
  }

  static readonly getMonthRangeForCategory = (fromMonth: string, toMonth: string) => {
    let monthArray = [];
    let from = moment(fromMonth, 'YYYYMMDDHHmmss');
    let to = moment(toMonth, 'YYYYMMDDHHmmss');

    while(from.year() !== to.year() || from.month() !== to.month()) {
      monthArray.push(from.format('YYYY-M'));
      from.add(1, 'month');
    }
    monthArray.push(from.format('YYYY-M'));
    return monthArray;
  }

  static readonly getYearRangeForCategory = (fromYear: string, toYear: string) => {
    let yearArray = [];
    let from = moment(fromYear, Constant.FORMAT_YMDHMS);
    let to = moment(toYear, Constant.FORMAT_YMDHMS);

    if(from.isValid() && to.isValid()) {
      while(from.year() !== to.year()) {
        yearArray.push(from.format('YYYY년'));
        from.add(1, 'year');
      }

      yearArray.push(from.format('YYYY년'));
    }
    return yearArray;
  }

  static readonly getDateRangeForCategory = (fromDate: string, toDate: string) => {
    let dateArray = [];
    let from = moment(fromDate, Constant.FORMAT_YMDHMS);
    let to = moment(toDate, Constant.FORMAT_YMDHMS);

    if(from.isValid() && to.isValid()) {
      while(from.year() !== to.year() || from.month() !== to.month() || from.date() !== to.date()) {
        // const lastArrayValue = dateArray[dateArray.length -1];
        // if(lastArrayValue) {
        //   const tempFrom = from.clone().add(-1, 'day');
        //   if(tempFrom.year() === from.year() && tempFrom.month() === from.month()) {
        //     dateArray.push(from.format('D일'));
        //   } else if(tempFrom.year() === from.year() && tempFrom.month() !== from.month()) {
        //     dateArray.push(from.format('M월 D일'));
        //   } else {
        //     dateArray.push(from.format('M월 D일'));
        //   }
        // } else {
        //   dateArray.push(from.format('M월 D일'));
        // }
        dateArray.push(from.format('D일'));
        from.add(1, 'day');
      }

      dateArray.push(from.format('D일'));
      // const tempFrom = from.clone().add(-1, 'day');
      // if(tempFrom.year() === from.year() && tempFrom.month() === from.month()) {
      //   dateArray.push(from.format('D일'));
      // } else if(tempFrom.year() === from.year() && tempFrom.month() !== from.month()) {
      //   dateArray.push(from.from('M월 D일'));
      // } else {
      //   dateArray.push(from.format('YYYY년 M월 D일'));
      // }
    }
    return dateArray;
  }

  static readonly setMissingMonthlyData = (itemData: ResponseChartData[], category?: string[]) => {
    let result: number[] = []
    if(itemData) {
      if(category) {
        result = category.map((v) => {
          const data = itemData.find(f => `${f.year}-${f.month}` === v)
          return data?.value??0
        })
      } else {
        result =this.monthToNumberArray.map((v) => {
          const data = itemData.find(f => f.month === v)
          return data?.value??0
        })
      }
    }
    return result
  }

  static readonly setDataToCategory = (itemData: ResponseChartData[], category: string[]) => {
    let result: number[] = []
    if(itemData && category) {
      result = category.map((v) => {
        const data = itemData.find(f => f.name === v)
        return data?.value??0
      })
    }
    return result
  }
}