import ArcButton, {ButtonColor} from "components/arc/ArcButton";
import {useNavigate} from "react-router-dom";
import {closeModal, ModalProps} from "reducers/modalSlice";
import {setSignUpInfo} from "reducers/signUpSlice";
import {useAppDispatch} from "stores/hook";

export default function SignUpTab1Modal(props: Readonly<ModalProps>) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getMemberType = (emksMbrsClCd: string) => {
        if (emksMbrsClCd === "1") {
            return "일반회원";
        } else if (emksMbrsClCd === "2") {
            return "중개사업회원";
        } else if (emksMbrsClCd === "3") {
            return "장외거래회원";
        } else {
            return "";
        }
    };



    function useSavedData() { // 기존 임시저장 데이터 사용시

        // 담당자번호
        let [conPerChar1, conPerChar2, conPerChar3] = [null, null, null];
        if (props.data[0].tchgrCplcNo) {
            const tchgrCplcNo = props.data[0].tchgrCplcNo;
            if (tchgrCplcNo) {
                [conPerChar1, conPerChar2, conPerChar3] = tchgrCplcNo.split("-");
            }
        }
        let [repNum1, repNum2, repNum3] = [null, null, null];
        if (props.data[0].coRepNo) {
            const coRepNo = props.data[0].coRepNo;
            if (coRepNo) {
                [repNum1, repNum2, repNum3] = coRepNo.split("-");
            }
        }
        let staIndClaCerId = null;
        let staIndClaCer1 = null;
        let staIndClaCer2 = null;
        staIndClaCerId = props.data?.[0]?.bzknds?.[0]?.bzkndId;
        staIndClaCer1 = props.data?.[0]?.bzknds?.[0]?.emksBzkndCd;
        staIndClaCer2 = props.data?.[0]?.bzknds?.[0]?.bzkndNm;

        let comRegNumFi = null;
        let comRegNumSe = null;
        let comRegNumTh = null;
        if (props.data[0].bizrRegNo) {
            const bizrRegNo = props.data[0].bizrRegNo;
            comRegNumFi = bizrRegNo.substring(0, 3);
            comRegNumSe = bizrRegNo.substring(3, 5);
            comRegNumTh = bizrRegNo.substring(5);
        }

        const newSignUpInfo = { // reduce 셋팅
            ...props.data[0],
            conPerChar1,
            conPerChar2,
            conPerChar3,
            repNum1,
            repNum2,
            repNum3,
            staIndClaCerId,
            staIndClaCer1,
            staIndClaCer2,
            comRegNumFi,
            comRegNumSe,
            comRegNumTh
        };
        dispatch(setSignUpInfo(newSignUpInfo));
        dispatch(closeModal());
        navigate(`/sign-up/2`);
    }



    function ignoreSavedData() {
        // 임시저장 데이터 이용안할거라면
        const newSignUpInfo = { // reduce 셋팅
            ...props.data[1]
        };
        dispatch(setSignUpInfo(newSignUpInfo));
        dispatch(closeModal());
        navigate(`/sign-up/2`);
    }

    return (
        <>
            <div style={{ margin: "20px" }}>
                <p style={{ fontSize: "18px" }}>기존 <strong style={{ fontWeight: "bold" }}>{getMemberType(props?.data?.emksMbrsClCd)}</strong> 유형으로 가입하신 이력이 존재합니다. <br />
                    기존 <strong style={{ fontWeight: "bold" }}>{getMemberType(props?.data?.emksMbrsClCd)}</strong> 유형으로 작성하신 정보를 이어 작성하시겠습니까?
                </p>
            </div>
            <div style={{ margin: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <ArcButton type={"custom"} hasIcon={true} color={ButtonColor.btn2} text={"예"} onClick={useSavedData} />
                <ArcButton type={"custom"} hasIcon={true} color={ButtonColor.btn3} text={"아니오"} onClick={ignoreSavedData} />
            </div>
        </>
    )
}
