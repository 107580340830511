export class Constant {
  public static readonly LANGUAGE = "lang";
  public static readonly ENGLISH = "en";
  public static readonly SOUTH_KOREA = "kr";

  public static readonly STATUS_USER = "02";
  public static readonly LOCKED = "02-01";
  public static readonly DISABLED = "02-02";
  public static readonly ACTIVED = "02-03";

  public static readonly FORMAT_YMD = "YYYY-MM-DD";
  public static readonly FORMAT_YMD_KR = "YYYY년 MM월 DD일";
  public static readonly FORMAT_YMD_ALT = "YYYYMMDD";
  public static readonly FORMAT_YMDHMS = "YYYYMMDDHHmmss";

  public static readonly ROWS_OF_PAGE = 10;

  public static readonly GET_METHOD = 'GET';
  public static readonly POST_METHOD = 'POST';
  public static readonly PUT_METHOD = 'PUT';
  public static readonly DELETE_METHOD = 'DELETE';

  public static formatDCHDId(id: string) {
    switch(id) {
      case '200001': return '공용PV';
      case '200002': return 'Fresh_ESS';
      case '200003': return 'Reuse_ESS';
      case '200004': return '궁도장';
      case '200005': return '과학관';
      case '200006': return '한국전력공사';
      default: return '-';
    }
  }
}

export const {formatDCHDId} = Constant;