import React, { useEffect, useRef, useState } from "react";
import { Emcrd } from "pages/cp/emissionRights/PriceAnalysis";
import { EmissionService } from "services/cp/EmissionService";
import moment from "moment";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { HighchartsOptionsUtil } from "utils/highchartsOptionsUtil";
import CountUp from "react-countup";
import { useAppDispatch, useAppSelector } from "stores/hook";
import { setEmissionsList } from "../../../reducers/cpSlice";
import ArcSelectbox, { SelectDataModel } from "../../../components/arc/ArcSelectbox";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";
import { toast } from "react-toastify";

export default function EmissionsManagementStatistics() {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<Emcrd[]>([]);
  const [emsList, setEmsList] = useState<any>([]);
  const [itmList, setItmList] = useState<any>([]);
  const [lastData, setLastData] = useState<Emcrd>();
  const [isinCd, setIsinCd] = useState<Emcrd["isinCd"]>('');
  const chartComponentRef = useRef<any>(null);
  const [latestMonthObj, setLatestMonthObj] = useState<any>({});
  const [aiGaugeValue, setAiGaugeValue] = useState<aiGaugeValueMapKeys>(1);
  const aiGaugeValueMap = {
    1: "적극판매",
    2: "판매",
    3: "중립",
    4: "구입",
    5: "적극구입",
  };
  type aiGaugeValueMapKeys = keyof typeof aiGaugeValueMap;
  const emissionsRightList = useAppSelector((state) => state.cp.emissionsRightList);

  useEffect(() => {
    if (!isinCd || isinCd === '') {
      return;
    }

    EmissionService.getInstance()
      .getEmissionByIsinCd(isinCd)
      .then((res) => {
        if (res.data == null || !Array.isArray(res.data.data)) {
          return;
        }
        const tempData = res.data.data;

        if (tempData != null && Array.isArray(tempData) && tempData.length > 0) {
          const lastData = tempData[0];
          const lastDataBaseDate = moment(lastData.baseYmd, "YYYYMMDD");
          const latestMonthDataList = tempData.filter(
            (d: Emcrd) => moment(d.baseYmd, "YYYYMMDD").diff(lastDataBaseDate, "days") <= 30
          );

          let currentCls = 0;
          const latestMonthObj = latestMonthDataList.reduce(
            (monthData: any, item: Emcrd, idx: number) => {
              currentCls = Number.isNaN(item.clsPrc) ? 0 : Number(item.clsPrc);
              monthData.totCls += currentCls;

              if (currentCls > monthData.hi) {
                monthData.hi = currentCls;
              }

              if (monthData.lo === 0 || currentCls <= monthData.lo) {
                monthData.lo = currentCls;
              }

              if (idx === 0) {
                monthData.firstData = item;
              }

              return monthData;
            },
            {
              totCls: 0,
              avg: 0,
              hi: 0,
              lo: 0,
              vs: 0,
              firstData: {} as Emcrd,
            }
          );

          if (latestMonthDataList.length > 0) {
            latestMonthObj.avg = latestMonthObj.totCls / latestMonthDataList.length;
            latestMonthObj.vs =
              ((Number(lastData.clsPrc) - Number(latestMonthObj.firstData.clsPrc)) / Number(lastData.clsPrc)) * 100;
          }

          setData(tempData);
          setLastData(lastData);
          setLatestMonthObj(latestMonthObj);
        }
      })
      .catch(() => toast.error('common.message.error.system'));
  }, [isinCd, localStorage]);

  // ai 의사결정
  useEffect(() => {
    if (!isinCd) {
      return;
    }

    EmissionService.getInstance()
      .getEmissionAiStatus(isinCd)
      .then((response) => {
        if (response.data) {
          if (response.data.data.status === -2) {
            setAiGaugeValue(1);
          }
          if (response.data.data.status === -1) {
            setAiGaugeValue(2);
          }
          if (response.data.data.status === 0) {
            setAiGaugeValue(3);
          }
          if (response.data.data.status === 1) {
            setAiGaugeValue(4);
          }
          if (response.data.data.status === 2) {
            setAiGaugeValue(5);
          }
        }
      })
      .catch(() => toast.error('common.message.error.system'));
  }, [isinCd]);

  const findLabelByValue = (value: any) => {
    if (emsList) {
      const item = emsList.find((d: any) => d.isinCd === value);
      return item ? item.itmNm : "";
    }
  };

  useEffect(() => {
    let itmList = [];
    let itmNmList: string[] = [];
    if (emissionsRightList != null && emissionsRightList.length > 0) {
      itmList = emissionsRightList.reduce((isinCdList: any[], a: any) => {
        if (a.emcrdNm !== "" && !itmNmList.includes(findLabelByValue(a.emcrdNm))) {
          isinCdList.push({
            itmNm: findLabelByValue(a.emcrdNm),
            isinCd: emsList.find((e: any) => e.itmNm == findLabelByValue(a.emcrdNm))?.isinCd,
          });
          itmNmList.push(findLabelByValue(a.emcrdNm));
        }
        return isinCdList;
      }, []);
      itmList.sort((a: any, b: any) => (a.itmNm > b.itmNm ? 1 : -1));
    }

    setItmList(itmList);
    setIsinCd(itmList.length > 0 ? itmList[0].isinCd : "");
  }, [emissionsRightList, emsList]);

  useEffect(() => {
    EmissionService.getInstance()
      .getEmission()
      .then(function (res) {
        const itemList = Array.from(
          new Set(
            res.data.data.map((item: any) =>
              JSON.stringify({
                itmNm: item.itmNm,
                isinCd: item.isinCd,
              })
            )
          )
        );
        const result: any = [];
        itemList.forEach((value: any) => {
          result.push(JSON.parse(value.toString()));
        });
        dispatch(setEmissionsList(result));
        setEmsList(result);
      })
      .catch(() => toast.error('common.message.error.system'));
  }, []);

  const ohlc = [];
  const volume = [];

  if (data && data.length > 0) {
    let stPrc;
    let hgPrc;
    let lwPrc;
    let csPrc;
    let datetime;

    for (let emcrd of data.sort((a, b) => Number(a.baseYmd) - Number(b.baseYmd))) {
      stPrc = Number(emcrd.mktPrc) === 0 ? emcrd.clsPrc : emcrd.mktPrc;
      hgPrc = Number(emcrd.hgPrc) === 0 ? emcrd.clsPrc : emcrd.hgPrc;
      lwPrc = Number(emcrd.lwPrc) === 0 ? emcrd.clsPrc : emcrd.lwPrc;
      csPrc = emcrd.clsPrc;
      datetime = moment(emcrd.baseYmd, "YYYYMMDD").unix() * 1000;
      ohlc.push([datetime, stPrc, hgPrc, lwPrc, csPrc]);
      volume.push([datetime, emcrd.trdVol]);
    }
  }

  Highcharts.setOptions(HighchartsOptionsUtil.locale.kr.globalOptions);
  const options: Highcharts.Options = {
    chart: {
      height: 485,
    },
    title: { text: "" },
    time: HighchartsOptionsUtil.locale.kr.time,
    exporting: { enabled: false },
    credits: { enabled: false },
    rangeSelector: {
      x: 15,
      buttons: [
        {
          type: "day",
          count: 7,
          text: "7일",
        },
        {
          type: "month",
          count: 1,
          text: "1개월",
        },
        {
          type: "month",
          count: 3,
          text: "3개월",
        },
        {
          type: "year",
          count: 1,
          text: "1년",
        },
        {
          type: "ytd",
          text: " 올해",
        },
        {
          type: "all",
          text: "전체",
        },
      ],
      inputEnabled: false,
    },
    tooltip: HighchartsOptionsUtil.locale.kr.stockChart.tooltip,
    legend: {
      enabled: true,
      floating: true,
      symbolHeight: 10,
      align: "right",
      verticalAlign: "top",
      y: -42,
      itemStyle: {
        color: "#505050",
        fontSize: "14px",
      },
      itemHoverStyle: {
        color: "#000",
        fontSize: "14px",
      },
    },
    plotOptions: {
      candlestick: {
        color: "#2970cd",
        lineColor: "#2970cd",
        lineWidth: 1,
        upColor: "#E0564E",
        upLineColor: "#E0564E",
      },
      column: {
        color: "#b8b8bb",
        borderRadius: 0,
      },
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: HighchartsOptionsUtil.locale.kr.dateTimeLabelFormats,
      showLastLabel: true,
      labels: {
        style: {
          color: '#555',
          fontSize: '14px'
        }
      }
    },
    yAxis: [
      {
        opposite: false,
        showLastLabel: true,
        height: "70%",
        lineWidth: 1,
        offset: 0,
        title: {
          text: '(원)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      {
        opposite: false,
        showLastLabel: true,
        top: "78%",
        height: "22%",
        lineWidth: 1,
        offset: 0,
        title: {
          text: '(톤)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: 98,
          x: -5
        },
        labels: {
          y: 6,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
    ],
    navigator: {
      xAxis: {
        dateTimeLabelFormats: HighchartsOptionsUtil.locale.kr.dateTimeLabelFormats,
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      }
    },
    series: [
      {
        type: "candlestick",
        name: lastData?.itmNm,
        data: ohlc,
      },
      {
        type: "column",
        name: "거래량",
        data: volume,
        yAxis: 1,
      },
    ],
  };

  const emsListOption = (): SelectDataModel[] => {
    return itmList.map((val: any) => ({
      label: val.itmNm,
      value: val.isinCd
    }));
  };


  return (
    <div className="col-wrap">
      <div className="col-md-8">
        <div className="cont">
          <h3 className="titT1">배출권 관리</h3>
          <div className="col-wrap">
            <div className="col-md-3">
              <h4 className="titT3">종목선택</h4>
              <div className="select-group InpSel-w100">
                <ArcSelectbox className={"select-group InpSel-w100"} name={"분류"}
                  selectedValue={isinCd}
                  onChange={(event) => setIsinCd(event.target.value)}
                  options={emsListOption()} />
              </div>
            </div>
            <div className="col-md-9">
              <div className="round-table2">
                <table>
                  <caption>탄소중립 모의예측 : 배출권 사업 지원 서비스, 배출권 구매</caption>
                  <colgroup>
                    <col style={{ width: "20%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">종가(원)</th>
                      <th scope="col">전일대비(원)</th>
                      <th scope="col">시가(원)</th>
                      <th scope="col">고가(원)</th>
                      <th scope="col">저가(원)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <CountUp end={Number(lastData?.clsPrc) ?? 0} duration={0.5}>
                          {({ countUpRef }) => <strong ref={countUpRef} />}
                        </CountUp>
                      </td>
                      <td>
                        <CountUp end={Number(lastData?.vs) ?? 0} duration={0.5}>
                          {({ countUpRef }) => <strong ref={countUpRef} />}
                        </CountUp>
                      </td>
                      <td>
                        <CountUp end={Number(lastData?.mktPrc) ?? 0} duration={0.5}>
                          {({ countUpRef }) => <strong ref={countUpRef} />}
                        </CountUp>
                      </td>
                      <td>
                        <CountUp end={Number(lastData?.hgPrc) ?? 0} duration={0.5}>
                          {({ countUpRef }) => <strong ref={countUpRef} />}
                        </CountUp>
                      </td>
                      <td>
                        <CountUp end={Number(lastData?.lwPrc) ?? 0} duration={0.5}>
                          {({ countUpRef }) => <strong ref={countUpRef} />}
                        </CountUp>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div id="graph-typeA" className="chart">
            <HighchartsReact
              ref={chartComponentRef}
              highcharts={Highcharts}
              options={options}
              constructorType={"stockChart"}
            />
          </div>
        </div>
      </div>


      <div className="col-md-4">
        <div className="cont">
          <h3 className="titT1">
            최근 주요 지표
            <span className="bu-atte mgl10">
              {moment(lastData?.baseYmd, "YYYYMMDD").format("YYYY.MM.DD")} KRX 배출권 시장 시세정보 기준
            </span>
          </h3>
          <ul className="count-info-lst1">
            <li>
              <span>종가</span>
              <em>
                <CountUp end={Number(lastData?.clsPrc) ?? 0} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                원
              </em>
            </li>
            <li>
              <span>거래량</span>
              <em>
                <CountUp end={Number(lastData?.trdVol) ?? 0} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                톤
              </em>
            </li>
            <li>
              <span>거래대금</span>
              <em>
                <CountUp end={Number(lastData?.trdAmt) ?? 0} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                원
              </em>
            </li>
          </ul>
          <h4 className="titT3 mgt20">최근 1개월 평균</h4>
          <ul className="count-info-lst1">
            <li>
              <span>평균가</span>
              <em>
                <CountUp end={latestMonthObj.avg} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                원
              </em>
            </li>
            <li>
              <span>최고가</span>
              <em>
                <CountUp end={latestMonthObj.hi} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                원
              </em>
            </li>
            <li>
              <span>최저가</span>
              <em>
                <CountUp end={latestMonthObj.lo} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                원
              </em>
            </li>
            <li>
              <span>등락률</span>
              <em>
                <CountUp end={latestMonthObj.vs} duration={0.5}>
                  {({ countUpRef }) => <strong ref={countUpRef} />}
                </CountUp>
                %
              </em>
            </li>
          </ul>
          <h3 className="titT1 mgt15">AI 의사결정 지원</h3>
          <div id="gaugeWrap" className={`guage-wrap gauge-level${aiGaugeValue}`}>
            <svg viewBox="0 0 297.79 143.98" id="guage" className="guage-chart1">
              <g id="level1">
                <g>
                  <path
                    className="st0"
                    d="M13.45,143.98H0c0-13.92,2.15-27.76,6.37-41.03s10.46-25.81,18.5-37.17l10.74,7.6
                                                              c-6.77,10.32-12.12,21.51-15.86,33.29C15.9,118.76,13.8,131.32,13.45,143.98z"
                  />
                  <path
                    className="st1"
                    d="M19.75,106.67c3.74-11.78,9.09-22.97,15.86-33.29l35.77,25.3c-9.24,13-15.19,28.51-16.64,45.3H13.45
                                                              C13.8,131.32,15.9,118.76,19.75,106.67z"
                  />
                  <path
                    className="st2"
                    d="M19.75,106.67c3.74-11.78,9.09-22.97,15.86-33.29l35.77,25.3c-9.24,13-15.19,28.51-16.64,45.3H13.45
                                                              C13.8,131.32,15.9,118.76,19.75,106.67z"
                  />
                </g>
                <g>
                  <path
                    className="st3"
                    d="M32.81,106.09h-2.07c0.01,1.22,0.75,2.49,2.39,3.03l-0.72,1.12c-1.15-0.36-1.95-1.12-2.4-2.05
                                                              c-0.46,1.04-1.29,1.87-2.52,2.27l-0.74-1.16c1.72-0.54,2.5-1.92,2.51-3.22h-2.09v-1.16h5.65V106.09z M28.42,111.02h7.15v3.75
                                                              h-1.45v-2.59h-5.7V111.02z M32.51,106.86h1.61v-2.51h1.45v6.2h-1.45v-2.51h-1.61V106.86z"
                  />
                  <path
                    className="st3"
                    d="M45.34,105.55c0.01,0.76,0.01,1.74-0.21,3.04h1.34v1.17h-9.53v-1.17h6.72c0.18-1.09,0.22-1.93,0.22-2.62H38.1
                                                              v-1.16h7.23V105.55z M37.94,110.98h7.43v3.8h-1.46v-2.66h-5.96V110.98z"
                  />
                  <path
                    className="st3"
                    d="M32.64,120.47h-0.85v2.89c0.42-0.03,0.82-0.07,1.21-0.12l0.1,1.08c-2.08,0.36-4.46,0.39-6.2,0.39l-0.16-1.17
                                                              c0.35,0,0.74-0.01,1.13-0.01v-3.06h-0.86v-1.17h5.62V120.47z M28.31,125.38h1.45v2.12h5.68v1.17h-7.13V125.38z M30.38,123.45
                                                              v-2.98h-1.1v3.02C29.64,123.48,30.01,123.47,30.38,123.45z M33.6,118.44h1.46v2.93h1.41v1.21h-1.41v3.52H33.6V118.44z"
                  />
                  <path
                    className="st3"
                    d="M37.23,119.51h4.14v6.72h-4.14V119.51z M40.02,125.08v-4.42H38.6v4.42H40.02z M45.97,128.85h-1.4v-5.25h-0.94
                                                              v4.77h-1.38v-9.79h1.38v3.82h0.94v-3.97h1.4V128.85z"
                  />
                </g>
              </g>
              <g id="level2">
                <g>
                  <path
                    className="st4"
                    d="M42.21,64.6l-10.74-8.1c8.39-11.11,18.44-20.87,29.8-28.92s23.89-14.3,37.16-18.53l4,12.54
                                                              c-11.62,4.15-22.63,9.87-32.71,17.02C59.37,45.94,50.12,54.7,42.21,64.6z"
                  />
                  <path
                    className="st5"
                    d="M69.71,38.61c10.08-7.14,21.09-12.86,32.71-17.02l13.31,41.74c-15.21,4.81-29.3,13.61-40.57,26.13L42.21,64.6
                                                              C50.12,54.7,59.37,45.94,69.71,38.61z"
                  />
                  <path
                    className="st6"
                    d="M69.71,38.61c10.08-7.14,21.09-12.86,32.71-17.02l13.31,41.74c-15.21,4.81-29.3,13.61-40.57,26.13L42.21,64.6
                                                              C50.12,54.7,59.37,45.94,69.71,38.61z"
                  />
                </g>
                <g>
                  <path
                    className="st7"
                    d="M73,53.91h-0.85v2.89c0.42-0.03,0.82-0.07,1.21-0.12l0.1,1.08c-2.08,0.36-4.46,0.39-6.2,0.39l-0.16-1.17
                                                              c0.35,0,0.74-0.01,1.13-0.01v-3.06h-0.86v-1.17H73V53.91z M68.68,58.82h1.45v2.12h5.68v1.17h-7.13V58.82z M70.75,56.89v-2.98h-1.1
                                                              v3.02C70.01,56.92,70.38,56.91,70.75,56.89z M73.96,51.88h1.46v2.93h1.41v1.21h-1.41v3.52h-1.46V51.88z"
                  />
                  <path
                    className="st7"
                    d="M77.6,52.95h4.14v6.72H77.6V52.95z M80.38,58.52V54.1h-1.42v4.42H80.38z M86.33,62.29h-1.4v-5.25H84v4.77
                                                              h-1.38v-9.79H84v3.82h0.94v-3.97h1.4V62.29z"
                  />
                </g>
              </g>
              <g id="level3">
                <g>
                  <path
                    className="st8"
                    d="M110.94,19.43l-4.16-12.79c13.24-4.31,27.06-6.54,40.99-6.64c13.92-0.09,27.77,1.96,41.07,6.09l-3.9,12.57
                                                              c-11.91-3.25-24.21-4.87-36.56-4.79C135.69,13.96,123.09,15.85,110.94,19.43z"
                  />
                  <path
                    className="st9"
                    d="M148.37,13.88c12.36-0.08,24.66,1.54,36.56,4.79l-13,41.84c-15.22-4.77-31.81-5.63-48.22-1.82l-12.77-39.25
                                                              C123.09,15.85,135.69,13.96,148.37,13.88z"
                  />
                  <path
                    className="st6"
                    d="M148.37,13.88c12.36-0.08,24.66,1.54,36.56,4.79l-13,41.84c-15.22-4.77-31.81-5.63-48.22-1.82l-12.77-39.25
                                                              C123.09,15.85,135.69,13.96,148.37,13.88z"
                  />
                </g>
                <g>
                  <path
                    className="st10"
                    d="M149.05,36.8h-4.81v0.92c2.16,0.16,3.45,0.94,3.46,2.22c-0.01,1.44-1.65,2.25-4.33,2.24
                                                              c-2.73,0.01-4.36-0.8-4.36-2.24c0-1.29,1.29-2.07,3.5-2.23V36.8h-4.8v-1.41h11.35V36.8z M142.1,31.65h-3.36v-1.37h9.28v1.37h-3.38
                                                              c0.3,0.87,1.57,1.71,3.93,1.91l-0.62,1.37c-2.35-0.23-3.93-1.11-4.59-2.32c-0.66,1.2-2.22,2.1-4.58,2.32l-0.57-1.37
                                                              C140.52,33.36,141.79,32.51,142.1,31.65z M143.37,40.88c1.69,0,2.58-0.33,2.58-0.93c-0.01-0.63-0.9-0.93-2.58-0.94
                                                              c-1.72,0.01-2.59,0.31-2.58,0.94C140.78,40.55,141.64,40.88,143.37,40.88z"
                  />
                  <path
                    className="st10"
                    d="M150.45,30.31h5.77v3.71h-4.05v1.04c2.08-0.01,3.55-0.05,5.22-0.34l0.22,1.39
                                                              c-1.91,0.32-3.59,0.37-6.06,0.37h-1.09v-3.79h4.02V31.7h-4.03V30.31z M151.9,37.21h1.74v1.07h4.85v-1.07h1.74v4.84h-8.33V37.21z
                                                               M158.49,40.66v-1.03h-4.85v1.03H158.49z M160.22,36.69h-1.75v-6.93h1.75V36.69z"
                  />
                </g>
              </g>
              <g id="level4">
                <g>
                  <path
                    className="st11"
                    d="M253.56,64.6l10.74-8.1c-8.39-11.11-18.44-20.87-29.8-28.92s-23.89-14.3-37.16-18.53l-4,12.54
                                                              c11.62,4.15,22.63,9.87,32.71,17.02C236.41,45.94,245.65,54.7,253.56,64.6z"
                  />
                  <path
                    className="st1"
                    d="M226.06,38.61c-10.08-7.14-21.09-12.86-32.71-17.02l-13.31,41.74c15.21,4.81,29.3,13.61,40.57,26.13
                                                              l32.95-24.87C245.65,54.7,236.41,45.94,226.06,38.61z"
                  />
                  <path
                    className="st12"
                    d="M226.06,38.61c-10.08-7.14-21.09-12.86-32.71-17.02l-13.31,41.74c15.21,4.81,29.3,13.61,40.57,26.13
                                                              l32.95-24.87C245.65,54.7,236.41,45.94,226.06,38.61z"
                  />
                </g>
                <g>
                  <path
                    className="st13"
                    d="M214.24,51.9c-0.01,1.16-0.01,2.43-0.32,4.17h1.65v1.41h-4.85v5.03h-1.75v-5.03h-4.74v-1.41h7.93
                                                              c0.32-1.6,0.35-2.82,0.36-3.9h-6.99v-1.39h8.72V51.9z"
                  />
                  <path
                    className="st13"
                    d="M219.91,50.57c1.93-0.01,3.4,1.2,3.4,2.95c-0.01,1.7-1.47,2.91-3.4,2.91c-1.94,0-3.4-1.22-3.4-2.91
                                                              C216.51,51.77,217.97,50.56,219.91,50.57z M219.91,55c0.97,0,1.67-0.55,1.68-1.48c-0.01-0.94-0.71-1.51-1.68-1.5
                                                              c-1-0.01-1.71,0.57-1.7,1.5C218.2,54.45,218.91,55,219.91,55z M218.41,57.28h1.72v1.16H225v-1.16h1.74v5.07h-8.33V57.28z
                                                               M225,60.95V59.8h-4.87v1.15H225z M226.73,56.74h-1.75v-6.69h1.75V56.74z"
                  />
                </g>
              </g>
              <g id="level5">
                <g>
                  <path
                    className="st14"
                    d="M284.33,143.98h13.45c0-13.92-2.15-27.76-6.37-41.03s-10.46-25.81-18.5-37.17l-10.74,7.6
                                                              c6.77,10.32,12.12,21.51,15.86,33.29C281.88,118.76,283.99,131.32,284.33,143.98z"
                  />
                  <path
                    className="st1"
                    d="M278.04,106.67c-3.74-11.78-9.09-22.97-15.86-33.29l-35.77,25.3c9.24,13,15.19,28.51,16.64,45.3h41.28
                                                              C283.99,131.32,281.88,118.76,278.04,106.67z"
                  />
                  <path
                    className="st15"
                    d="M278.04,106.67c-3.74-11.78-9.09-22.97-15.86-33.29l-35.77,25.3c9.24,13,15.19,28.51,16.64,45.3h41.28
                                                              C283.99,131.32,281.88,118.76,278.04,106.67z"
                  />
                </g>
                <g>
                  <path
                    className="st16"
                    d="M254.4,104.61h-2.47c0.01,1.46,0.9,2.97,2.86,3.62l-0.86,1.34c-1.37-0.43-2.32-1.33-2.86-2.45
                                                              c-0.55,1.24-1.54,2.24-3.01,2.71l-0.89-1.38c2.05-0.65,2.98-2.3,2.99-3.84h-2.5v-1.38h6.75V104.61z M249.16,110.49h8.54v4.48
                                                              h-1.74v-3.09h-6.81V110.49z M254.04,105.53h1.93v-2.99h1.74v7.41h-1.74v-2.99h-1.93V105.53z"
                  />
                  <path
                    className="st16"
                    d="M269.35,103.95c0.01,0.91,0.01,2.08-0.25,3.64h1.6v1.39h-11.39v-1.39h8.03c0.22-1.3,0.26-2.31,0.27-3.13
                                                              h-6.9v-1.38h8.64V103.95z M260.52,110.45h8.87v4.54h-1.75v-3.17h-7.12V110.45z"
                  />
                  <path
                    className="st16"
                    d="M257.24,121.18c-0.01,1.16-0.01,2.43-0.32,4.17h1.65v1.41h-4.85v5.03h-1.75v-5.03h-4.74v-1.41h7.93
                                                              c0.32-1.6,0.35-2.82,0.36-3.9h-6.99v-1.39h8.72V121.18z"
                  />
                  <path
                    className="st16"
                    d="M262.92,119.85c1.93-0.01,3.4,1.2,3.4,2.95c-0.01,1.7-1.47,2.91-3.4,2.91c-1.94,0-3.4-1.22-3.4-2.91
                                                              C259.51,121.06,260.97,119.84,262.92,119.85z M262.92,124.28c0.97,0,1.67-0.55,1.68-1.48c-0.01-0.94-0.71-1.51-1.68-1.5
                                                              c-1-0.01-1.71,0.57-1.7,1.5C261.21,123.73,261.92,124.28,262.92,124.28z M261.41,126.57h1.72v1.16H268v-1.16h1.74v5.07h-8.33
                                                              V126.57z M268,130.23v-1.15h-4.87v1.15H268z M269.74,126.02h-1.75v-6.69h1.75V126.02z"
                  />
                </g>
              </g>
              <g id="center">
                <g>
                  <g>
                    <defs>
                      <rect id="SVGID_1_" x="17.8" y="13.62" width="312.8" height="130.36" />
                    </defs>
                    <clipPath id="SVGID_00000083074327676640579860000005285995534879651242_">
                      <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                    </clipPath>
                  </g>
                  <g>
                    <defs>
                      <rect
                        id="SVGID_00000054244178552660494830000001244306564458931629_"
                        x="17.8"
                        y="13.62"
                        width="312.8"
                        height="130.36"
                      />
                    </defs>
                    <clipPath id="SVGID_00000072256328174434762740000003322936598725786768_">
                      <use
                        xlinkHref="#SVGID_00000054244178552660494830000001244306564458931629_"
                        style={{ overflow: "visible" }}
                      />
                    </clipPath>
                    <path
                      style={{
                        clipPath: "url(#SVGID_00000072256328174434762740000003322936598725786768_)",
                        fill: "#EBEAEA",
                      }}
                      d="M228.22,127.85c1.63,6.46,2.5,13.21,2.5,20.17H66.24c0-45.42,36.82-82.24,82.24-82.24c33.18,0,61.74,19.65,74.75,47.94C223.23,113.73,226.76,120.77,228.22,127.85z"
                    />
                  </g>
                  <g>
                    <defs>
                      <rect
                        id="SVGID_00000103233800181911190180000009339524023943660447_"
                        x="17.8"
                        y="13.62"
                        width="312.8"
                        height="130.36"
                      />
                    </defs>
                    <clipPath id="SVGID_00000046310102817000268670000014005071955761394050_">
                      <use
                        xlinkHref="#SVGID_00000103233800181911190180000009339524023943660447_"
                        style={{ overflow: "visible" }}
                      />
                    </clipPath>
                  </g>
                </g>
              </g>
              <g id="pin">
                <polygon id="pin1" className="st18"
                  points="69.95,128.42 60.14,117 74.75,114.19 84.6,125.35" />
                <polygon id="pin2" className="st18"
                  points="94.6,86.51 93.21,71.53 106.75,77.69 108.33,92.49" />
                <polygon id="pin3" className="st18"
                  points="140.43,66.73 147.74,53.58 155.44,66.3 148.39,79.41" />
                <polygon id="pin4" className="st18"
                  points="190.6,77.86 204.21,71.45 203.04,86.28 189.66,92.8" />
                <polygon
                  id="pin5"
                  className="st18"
                  points="223.46,113.78 238.12,117.17 228.02,128.09 213.47,124.94"
                />
              </g>
            </svg>
            <strong id="gaugetxt" className="guage-state">{aiGaugeValueMap[aiGaugeValue]}</strong>
          </div>
          <p className="pc-red fs14 mgt10">※ 본 정보는 의사 결정하는데 참고 정보일 뿐 결정은 반드시 본인의 판단과 책임 하에 해야 합니다.</p>
        </div>
      </div>
    </div>
  );
}
