import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Constant} from "constants/constant";
import {useAppSelector} from "stores/hook";
import {AuthConstant} from "constants/authConstant";
import {UserInfoUtil} from "utils/userInfoUtil";
import {EmarketMenuModel} from "reducers/userMenuSlice";
import {ObjectUtils} from "primereact/utils";

export default function Breadcrumbs() {
  const navigate = useNavigate();
  const userMenu: EmarketMenuModel[] = useAppSelector(state => state.userMenu.userMenu);
  const currentMenu: EmarketMenuModel|undefined = useAppSelector(state => state.userMenu.currentMenu);
  const userInfo = useAppSelector(state => state.userInfo.userInfo);
  const lang = localStorage.getItem(Constant.LANGUAGE) || Constant.SOUTH_KOREA

  const [breadcrumbs, setBreadcrumbs] = useState<EmarketMenuModel[]>([]);
  useEffect(() => {
    if(currentMenu) {
      setBreadcrumbs([currentMenu]);
    }
  }, [currentMenu]);

  useEffect(() => {
    if(currentMenu) {
      if(null !== currentMenu.upperMenuId) {
        if(breadcrumbs[0]?.upperMenuId) {
          findMenuByMenuIdAndSet(userMenu, breadcrumbs[0].upperMenuId);
        }
      }
    }
  }, [breadcrumbs]);

  function findMenuByMenuIdAndSet(menus: EmarketMenuModel[], menuId: string) {
    for(const menu of menus) {
      if(ObjectUtils.isNotEmpty(menu.menuId) && menuId === menu.menuId) {
        setBreadcrumbs([menu, ...breadcrumbs]);
        break;
      } else {
        if(menu.childMenus && menu.childMenus.length > 0) {
          findMenuByMenuIdAndSet(menu.childMenus, menuId);
        }
      }
    }
  }

  const translateMenuNm = useCallback((item: any) => {
    if (Constant.SOUTH_KOREA === lang) {
      return item.menuName
    } else if (Constant.ENGLISH === lang) {
      return item.menuNmEn
    } else {
      return item.menuNmVi
    }
  }, [])

  const [homeName, setHomeName] = useState('홈');
  useEffect(() => {
    setHomeName('E-Market 플랫폼');
  }, [userInfo]);

  return (
    <div className="location" id="location">
      <span>{homeName}</span>
      {
        breadcrumbs.map((e) => {
          if (e === currentMenu) {
            return <strong key={e.menuId}>{translateMenuNm(e)}</strong>
          } else {
            return <span key={e.menuId}>{translateMenuNm(e)}</span>
          }
        })
      }
    </div>
  )
}
