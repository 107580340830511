import { useEffect, useState } from "react";
import DbAdminRe100ParticipationStatus from "./DbAdminRe100ParticipationStatus";
import DbAdminRe100ParticipationeEnergyUsed from "./DbAdminRe100ParticipationeEnergyUsed";

export default function DbAdminRe100Participation() {
  const [tabId, setTabId] = useState<string>("RE100_STATUS");

  useEffect(() => {
    document.body.classList.add("admin");
    return () => {
      document.body.classList.remove("admin");
    };
  }, []);

  const changeTab = (tabId: any) => {
    setTabId(tabId);
  };

  return (
    <>
      <section className="section active">
        <ul className="tab-st1 Tab_w50 mgb5">
          <li className={tabId === 'RE100_STATUS' ? "on": "''"}>
            <a onClick={() => changeTab("RE100_STATUS")} title="선택된페이지">
              <span>산단 기업 현황</span>
            </a>{" "}
          </li>
          <li className={tabId === 'ENERGY_USED' ? "on": "''"}>
            <a onClick={() => changeTab("ENERGY_USED")}>
              <span>전력사용량통계</span>
            </a>{" "}
          </li>
        </ul>
        {tabId === "RE100_STATUS" && <DbAdminRe100ParticipationStatus />}

        {tabId === "ENERGY_USED" && <DbAdminRe100ParticipationeEnergyUsed />}
      </section>
    </>
  );
}
