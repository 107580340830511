import {UserInfoModel} from "../model/ua/UserInfoModel";
import {AuthConstant} from "../constants/authConstant";

export class UserInfoUtil {
  public static hasRole(userInfo: UserInfoModel, role: string) {
    if (!userInfo) {
      return false;
    }

    if (!role || role === '') {
      return true;
    }

    return (userInfo.auths?.filter(auth => auth.authNm === role).length ?? 0) > 0;
  }

  public static isAdmin(userInfo: UserInfoModel) {
    if (!userInfo) {
      return false;
    }

    return (userInfo.auths?.filter(auth => auth.authNm === AuthConstant.ADMIN).length ?? 0) > 0;
  }
}