import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import Slider from 'react-slick';
import {ExchangeRateModel} from "model/common/exchangeRateModel";
import {EmissionRightsModel} from "model/common/emissionRightsModel";
import {SmpModel} from "model/common/smpModel";
import {Re100Model} from "model/common/re100Model";
import {RpsModel} from "model/common/rpsModel";
import {SideMenuService} from "services/SideMenuService";


export default function NavSlider() {
  const [sliderIndex, setSliderIndex] = useState<number>(0);
  const [excRates, setExchangeRates] = useState<ExchangeRateModel[]>([]);
  const [emsRights, setEmissionRights] = useState<EmissionRightsModel[]>([]);
  const [smp, setSmp] = useState<SmpModel[]>([]);
  const [smpJeju, setSmpJeju] = useState<SmpModel>();
  const [re100, setRe100] = useState<Re100Model>();
  const [rps, setRps] = useState<RpsModel>();
  const [rpsJeju, setRpsJeju] = useState<RpsModel>();

  const slickRef = useRef<Slider | null>(null);

  const moveTo = useCallback((index: number) => {
    if (slickRef) {
      setSliderIndex(index);
      slickRef.current?.slickGoTo(index);
    }
  }, []);

  function fetchExchangeRates() {
    SideMenuService.getInstance().getExchangeRates().then((result: any) => {
      let excList: ExchangeRateModel[] = [];
      result.data.forEach((value: any) => {
        excList.push(ExchangeRateModel.fromMap(value));
      });
      setExchangeRates(excList);
    }).catch(() => {})
  }

  function fetchEmissionRights() {
    let emsList: EmissionRightsModel[] = [];
    SideMenuService.getInstance().getEmissionRights().then((result: any) => {
      if (result.data != null) {
        let kauList: EmissionRightsModel[] = [];
        let kcuList: EmissionRightsModel[] = [];
        let kocList: EmissionRightsModel[] = [];
        for (let ems of result.data) {
          if (ems.itmNm?.startsWith('KAU') && !Number.isNaN(ems.itmNm?.slice(-2))) {
            kauList.push(ems);
          } else if (ems.itmNm?.startsWith('KCU') && !Number.isNaN(ems.itmNm?.slice(-2))) {
            kcuList.push(ems);
          } else if (ems.itmNm?.startsWith('KOC') && !Number.isNaN(ems.itmNm?.slice(-2))) {
            kocList.push(ems);
          }
        }
        const curKau = getCurrentlyEmsRightFromArray(kauList);
        const curKcu = getCurrentlyEmsRightFromArray(kcuList);
        const curKoc = getCurrentlyEmsRightFromArray(kocList);

        if (curKau != null) {
          emsList.push(curKau);
        }

        if (curKcu != null) {
          emsList.push(curKcu);
        }

        if (curKoc != null) {
          emsList.push(curKoc);
        }
      }

      setEmissionRights(emsList);

    }).catch(() => {
      setEmissionRights(emsList);
    })
  }

  function getCurrentlyEmsRightFromArray(emsList: EmissionRightsModel[]) {
    if (emsList == null || emsList.length == 0) {
      return null;
    }

    return emsList.reduce((a, b) => {
      if(a.itmNm == null || a.itmNm?.length < 2) {
        return b;
      }

      if(b.itmNm == null || b.itmNm?.length < 2) {
        return a;
      }

      if(Number(a.itmNm.slice(-2)) > Number(b.itmNm.slice(-2))) {
        return a;
      } else {
        return b;
      }
    });
  }

  function fetchSMPs() {
    SideMenuService.getInstance().getSmp().then((result: any) => {
      let smpList: SmpModel[] = [];
      result.data.forEach((value: any) => {
        smpList.push(SmpModel.fromMap(value));
      });
      setSmp(smpList);
      setSmpJeju(smpList.find(f => '9' == f.areaCd));
    }).catch(() => {})
  }

  function fetchRE100s() {
    SideMenuService.getInstance().getRe100().then((result: any) => {
      let recList: Re100Model[] = [];
      result.data.forEach((value: any) => {
        recList.push(Re100Model.fromMap(value));
      });
      if(recList.length > 0) {
        setRe100(recList[0]);
      }
    }).catch(() => {})
  }

  function fetchRPSs() {
    SideMenuService.getInstance().getRps().then((result: any) => {
      let rpsList: RpsModel[] = [];
      result.data.forEach((value: any) => {
        rpsList.push(RpsModel.fromMap(value));
      });
      setRps(rpsList.find(f => '1' == f.areaCd));
      setRpsJeju(rpsList.find(f => '9' == f.areaCd));
    }).catch(() => {})
  }


  useEffect(() => {
    fetchExchangeRates();
    fetchEmissionRights();
    fetchSMPs();
    fetchRE100s();
    fetchRPSs();
  }, []);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    dots: false,
    arrows: false,
    autoplay: true,
    useCSS: true,
    autoplaySpeed: 3500,
    speed: 500,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    beforeChange(currentSlide: number, nextSlide: number) {
      setSliderIndex(nextSlide);
    }
  };


  const today = new Date();
  const formattedDate = today.toLocaleDateString('ko-KR');

  return (
      <>
        <div id="sideSlider">
          <Slider ref={slickRef} {...settings}>

            <div className="slider-wrap slider-emission">
              <h3 className="side-b-tit">{formattedDate}<strong>UPDATED</strong></h3>
              <span className="tit">배출권</span>
              <ul> {
                emsRights.map((value) => {
                  let classNameUpDown, upDownArrow;
                  if (parseFloat(value.vsRt ? value.vsRt.toString() : "0") > 0) {
                    classNameUpDown = 'up';
                    upDownArrow = '▲';
                  } else if (parseFloat(value.vsRt ? value.vsRt.toString() : "0") < 0) {
                    classNameUpDown = 'down';
                    upDownArrow = '▼';
                  } else {
                    classNameUpDown = 'up';
                    upDownArrow = '';
                  }
                  return (
                    <li key={`${value.baseYmd}-${value.isinCd}`}>
                      <span>{value.itmNm}</span>
                      <strong>{value.clsPrc ? value.clsPrc.toLocaleString() : "-"}원 (<em
                          className={classNameUpDown}>{upDownArrow}{value.vs}원</em>, {value.vsRt} %) <br/>
                        {value.trdVol ? value.trdVol.toLocaleString() : "-"}톤</strong>
                    </li>);
                })
              }
              </ul>
            </div>


            <div className="slider-wrap slider-exchange">
              <h3 className="side-b-tit">{formattedDate}<strong>UPDATED</strong></h3>
              <span className="tit">환율</span>
              <ul>
                {
                  excRates.map((value) => {
                    let classNameUpDown, upDownArrow;
                    if (parseFloat(value.ttsVs ? value.ttsVs.toString() : "0") > 0) {
                      classNameUpDown = 'up';
                      upDownArrow = '▲';
                    } else if (parseFloat(value.ttsVs ? value.ttsVs.toString() : "0") < 0) {
                      classNameUpDown = 'down';
                      upDownArrow = '▼';
                    } else {
                      classNameUpDown = 'up';
                      upDownArrow = '';
                    }

                    if (value.curcyCd == 'USD' || value.curcyCd == 'CNH' || value.curcyCd == 'EUR') {
                      return (
                        <li key={`${value.baseYmd}-${value.curcyCd}`}>
                          <span>원/{value.curcyUnit}</span>
                          <strong>{value.tts ? value.tts.toLocaleString() : "-"} (<em
                            className={classNameUpDown}>{upDownArrow}{value.ttsVs ? value.ttsVs.toLocaleString() : "-"} {value.curcyUnit}</em> , {value.ttsVsRt ? value.ttsVsRt.toLocaleString() : "-"} %)</strong>
                        </li>);
                    }
                  })
                }
              </ul>
            </div>

            <div className="slider-wrap slider-smp">
              <h3 className="side-b-tit">{formattedDate}<strong>UPDATED</strong></h3>
              <span className="tit">SMP</span>
              <span className="tit">육지</span>
              <ul>
                <li>
                  {
                    smp.map((value) => {
                      if (value.areaCd == '1') {
                        return <Fragment key={`${value.trdYmd}-${value.areaCd}`}>
                          <span><em>최고가</em><strong
                              className="up">{value.hgSmp ? value.hgSmp.toLocaleString() : "-"}원/kWh</strong></span>
                          <span><em>최소가</em><strong
                              className="down">{value.lwSmp ? value.lwSmp.toLocaleString() : "-"}원/kWh</strong></span>
                          <span><em>평균가</em><strong>{value.avgSmp ? value.avgSmp.toLocaleString(undefined, {maximumFractionDigits: 2}) : "-"}원/kWh</strong></span>
                        </Fragment>
                      }
                    })
                  }
                </li>
              </ul>
              <span className="tit">제주</span>
              <ul>
                <li>
                  {
                    smpJeju && (<>
                      <span>
                        <em>최고가</em>
                        <strong className="up">{smpJeju.hgSmp ? smpJeju.hgSmp.toLocaleString() : "-"}원/kWh</strong>
                      </span>
                      <span>
                        <em>최소가</em>
                        <strong className="down">{smpJeju.lwSmp ? smpJeju.lwSmp.toLocaleString() : "-"}원/kWh</strong>
                      </span>
                      <span>
                        <em>평균가</em>
                        <strong>{smpJeju.avgSmp ? smpJeju.avgSmp.toLocaleString(undefined, {maximumFractionDigits: 2}) : "-"}원/kWh</strong>
                      </span>
                      </>)
                  }
                </li>
              </ul>

            </div>

            <div className="slider-wrap slider-rec">
              <h3 className="side-b-tit">{formattedDate}<strong>UPDATED</strong></h3>
              <span className="tit">REC(RPS시장)</span>
              <span className="tit">육지</span>
              <ul>
                <li>
                  {rps && (<>
                    <span><em>최고가</em><strong
                      className="up">{rps.hgPrc ? rps.hgPrc.toLocaleString() : "-"}원/REC</strong></span>
                    <span><em>최저가</em><strong
                      className="down">{rps.lwPrc ? rps.lwPrc.toLocaleString() : "-"}원/REC</strong></span>
                    <span><em>평균가</em><strong>{rps.avgPrc ? rps.avgPrc.toLocaleString() : "-"}원/REC</strong></span>
                    </>)
                  }
                </li>
              </ul>
              <span className="tit">제주</span>
              <ul>
                <li>
                  {rpsJeju && (<>
                    <span><em>최고가</em><strong
                      className="up">{rpsJeju.hgPrc ? rpsJeju.hgPrc.toLocaleString() : "-"}원/REC</strong></span>
                    <span><em>최저가</em><strong
                      className="down">{rpsJeju.lwPrc ? rpsJeju.lwPrc.toLocaleString() : "-"}원/REC</strong></span>
                    <span><em>평균가</em><strong>{rpsJeju.avgPrc ? rpsJeju.avgPrc.toLocaleString() : "-"}원/REC</strong></span>

                    </>)}
                </li>
              </ul>
            </div>

            <div className="slider-wrap slider-rec">
              <h3 className="side-b-tit">{formattedDate}<strong>UPDATED</strong></h3>
              <span className="tit">REC(RE100시장)</span>
              <ul>
                <li>
                  {re100 && (<>
                    <span><em>거래일자</em><strong>{re100.trdYmd?.substring(0, 4)}.{re100.trdYmd?.substring(4, 6)}.{re100.trdYmd?.substring(6, 8)}</strong></span>
                    <span><em>거래수</em><strong>{re100.trdCnt ? re100.trdCnt.toLocaleString() : "-"}건</strong></span>
                    <span><em>거래량</em><strong>{re100.trdVol ? re100.trdVol.toLocaleString() : "-"}MWh</strong></span>
                    <span><em>평균가</em><strong>{re100.avgPrc ? re100.avgPrc.toLocaleString() : "-"}원/REC</strong></span>
                    </>)}
                </li>
              </ul>

            </div>
          </Slider>
        </div>
        <div className="sideSliderDot">
          <ul className="slick-dots" role="tablist">
            <li role="presentation" className={0 === sliderIndex ? "slick-active" : ""}>
              <button onClick={() => moveTo(0)}>1</button>
            </li>
            <li role="presentation" className={1 === sliderIndex ? "slick-active" : ""}>
              <button onClick={() => moveTo(1)}>2</button>
            </li>
            <li role="presentation" className={2 === sliderIndex ? "slick-active" : ""}>
              <button onClick={() => moveTo(2)}>3</button>
            </li>
            <li role="presentation" className={3 === sliderIndex ? "slick-active" : ""}>
              <button onClick={() => moveTo(3)}>4</button>
            </li>
            <li role="presentation" className={4 === sliderIndex ? "slick-active" : ""}>
              <button onClick={() => moveTo(4)}>5</button>
            </li>
          </ul>
        </div>
      </>
  )
}