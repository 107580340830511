import React, {useEffect, useState} from "react";
import * as GC from "@mescius/spread-sheets";
import * as ExcelIO from "@mescius/spread-excelio";
import {SpreadSheets, Worksheet} from "@mescius/spread-sheets-react";
import moment from "moment";
import {Constant} from "constants/constant";
import {saveAs} from "file-saver";
import ArcButton, {ButtonType} from "components/arc/ArcButton";

GC.Spread.Common.CultureManager.culture('ko-kr');

export const SpreadFileType = {
  SJS: 'sjs',
  Excel: 'xlsx',
  SSJson: 'ssjson',
  Csv: 'csv',
} as const;

type exportType = "DOWNLOAD" | "EXPORT";
export interface ArcSpreadJSProps {
  importFile?: File;
  onDownload?: Function;
  onSaveAsFile?: Function;
  saveFileName?: string;
  customButton?: JSX.Element;
}

export default function ArcSpreadJS(props: ArcSpreadJSProps) {
  const [spread, setSpread] = useState<GC.Spread.Sheets.Workbook|null>(null);

  useEffect(() => {
    if(spread && props.importFile) {
      const fileType = getFileType(props.importFile);
      if(fileType === SpreadFileType.Excel) {
        const excelIO = new ExcelIO.IO();
        excelIO.open(props.importFile,
          (json: any) => {
            spread.fromJSON(json).then();
          },
          (e: any) => {
            console.log(e);
          });
      }
    }
  }, [props.importFile]);

  function getFileType(file: File) {
    if (!file) return;

    const fileName = file.name;
    const extensionName = fileName.substring(fileName.lastIndexOf(".") + 1);

    if (extensionName === 'sjs') {
      return SpreadFileType.SJS;
    } else if (extensionName === 'xlsx' || extensionName === 'xlsm') {
      return SpreadFileType.Excel;
    } else if (extensionName === 'ssjson' || extensionName === 'json') {
      return SpreadFileType.SSJson;
    } else if (extensionName === 'csv') {
      return SpreadFileType.Csv;
    }
  }

  function initSpread(spread: GC.Spread.Sheets.Workbook) {
    setSpread(spread);
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(document.getElementById('statusBar')!);
    statusBar.bind(spread);
  }

  function onExport(exportType: exportType) {
    if(spread) {
      let excelIO = new ExcelIO.IO();
      let fileName = props.saveFileName??`${moment().format(Constant.FORMAT_YMD_ALT)}_excel`;
      if(fileName.slice(-5) !== '.xlsx') {
        fileName += '.xlsx';
      }

      const json = JSON.stringify(spread.toJSON());
      excelIO.save(json, (blob: Blob) => {
        if("EXPORT" === exportType) {
          if(props.onSaveAsFile) {
            const newFile = new File([blob], fileName, {
              type: blob.type,
            });
            props.onSaveAsFile(newFile);
          }
        }
        else if("DOWNLOAD" === exportType) {
          saveAs(blob, fileName);
          if(props.onDownload) {
            props.onDownload();
          }
        }
      }, (e: any) => {
        console.log(e);
      });
    }
  }

  return (<>
    <div className="col-wrap">
      <div className="col-md-12">
        <div className="cont">
          <SpreadSheets workbookInitialized={spread => initSpread(spread!)}
                        hostStyle={{width: "100%", height: "600px"}}>
            <Worksheet></Worksheet>
          </SpreadSheets>
          <div id="statusBar"
               style={{bottom: "0", height: "25px", width: "100%", position: "relative"}}></div>
        </div>
      </div>
    </div>

    <div className="btns ar">
      {props.customButton && (props.customButton)}
      {props.onDownload && (
        <ArcButton type={ButtonType.download} hasIcon={true} onClick={() => onExport("DOWNLOAD")}/>)}
      <ArcButton type={ButtonType.save} hasIcon={true} onClick={() => onExport("EXPORT")}/>
    </div>
  </>);
}