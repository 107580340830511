class StringUtil {
  public static isStringEmpty(str: string) {
    return str === "";
  }

  public static isStringNullOrEmpty(str: string) {
    return !str;
  }

  public static isNumber(obj: string) {
    return !isNaN(Number(obj));
  }

  public static parseStringWithOutWhitespace(obj: string) {
    return obj.replace(" ", "");
  }

  public static parseNumberWithMaxValue(obj: string, maxValue: number) {
    return Math.min(parseInt(obj), maxValue).toString();
  }

  public static parseNumberWithMinValue(obj: string, minValue: number) {
    return Math.max(parseInt(obj), minValue).toString();
  }

  public static removeNonNumeric(obj: string) {
    return obj.toString().replace(/\D/gi, "");
  }

  public static parseNumberWithComma(obj: any) {
    return obj == null || isNaN(obj) ? obj : obj.toLocaleString();
  }

  public static parseNumberWithCommaDecimal(obj: any) {
    if (obj == null || isNaN(obj)) {
      return obj;
    } else {
      const numberSplit = obj.toString().split(".");
      const integer = Number(numberSplit[0]);
      const decimal = numberSplit[1];
      if (Number(decimal) > 0) {
        return integer.toLocaleString() + "." + decimal;
      } else {
        return Number(obj).toLocaleString();
      }
    }
  }

  public static joinStringArrayToString(obj: string[], joinString: string) {
    return obj.join(joinString);
  }

  public static getLocaleMonthName(month: number) {
    const objDate = new Date();
    objDate.setDate(1);
    objDate.setMonth(month - 1);
    return objDate.toLocaleString("default", { month: "long" });
  }
}

export const {
  isStringEmpty,
  isStringNullOrEmpty,
  isNumber,
  parseStringWithOutWhitespace,
  parseNumberWithMaxValue,
  parseNumberWithMinValue,
  removeNonNumeric,
  parseNumberWithComma,
  parseNumberWithCommaDecimal,
  joinStringArrayToString,
  getLocaleMonthName,
} = StringUtil;
