import ArcButton, {ButtonColor, ButtonType} from "components/arc/ArcButton";
import ArcGrid, {ArcGridCheckResult} from "components/arc/ArcGrid";
import ArcInput from "components/arc/ArcInput";
import ArcSelectbox, {SelectboxSize} from "components/arc/ArcSelectbox";
import {t} from "i18next";
import {UserMemberModel} from "model/ua/UserMemberModel";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {closeModal, ModalProps} from "reducers/modalSlice";
import {setSignUpInfo} from "reducers/signUpSlice";
import {SignUpService} from "services/signUp/SignUpService";
import {useAppDispatch} from "stores/hook";

export default function SignUpTab2Modal(props: Readonly<ModalProps>) {
    const dispatch = useAppDispatch();
    const [keyword, setKeyword] = useState();
    const [dgr, setDgr] = useState("");

    const firstFocusRef = useRef<HTMLDivElement | null>(null);
    const saveButtonRef = useRef<HTMLDivElement | null>(null);
    const cancelButtonRef = useRef<HTMLDivElement | null>(null);
    const lastFocusElement = useRef<HTMLElement | null>(null);

    function clickSearchBtn() {
        getBzkndDgr();
    }

    useEffect(() => {
        lastFocusElement.current =  document.activeElement as HTMLElement;
        if (firstFocusRef.current) {
            firstFocusRef.current.focus();
        }

        return () => {
            if (lastFocusElement.current) {
                lastFocusElement.current.focus();
            }
        };
    }, []);


    function saveData(){
        if (selectRowData != null || selectRowData != undefined) {
            // reduce에 선택한 업종의 값을 저장해준 후 모달 닫기
            const newSignUpInfo: UserMemberModel = { // reduce 셋팅
                ...props.data,
                staIndClaCer1: selectRowData?.emksBzkndCd,
                staIndClaCer2: selectRowData?.bzkndNm,
                staIndClaCerId: selectRowData?.bzkndId
            };
            dispatch(setSignUpInfo(newSignUpInfo));
            dispatch(closeModal());

        } else {
            toast.error(t("한가지의 업종을 선택해주시기 바랍니다."));
        }
        // 만약 선택한 업종코드가 없으면 오류메세지
    }

    function useSavedData() { // modal 적용버튼
        saveData()
    }
    function useTabSaveData(e: React.KeyboardEvent){
        if(e.key==="Enter"){
            saveData()
        }
    }

    function ignoreSavedData() {
        dispatch(closeModal());
    }

    const changeInput = (event: any) => {
        setKeyword(event);
    };

    function changeSelect(e: React.ChangeEvent<HTMLSelectElement>) {
        setDgr(e.target.value);
    }

    const dgrList = Array.from({ length: 20 }, (_, i) => {
        const value = String(i + 1);
        return { label: value, value: value };
    });

    // 그리드
    const gridRef: any = useRef<typeof ArcGrid>();
    const [gridKey, setGridKey] = useState(0);
    const [selectRowData, setSelectRowData] = useState<any>(null);
    const [data, setData] = useState<dataType[] | []>([]);
    type dataType = {
        bzkndId: any;
        bzkndNm: any;
        emksBzkndCd: any;
        crbnEmsnCoefIndsClCds: any;
        dgr: any;
    };

    useEffect(() => {
        getBzkndDgr();
    }, []);

    async function getBzkndDgr() {
        try {
            const response: any = await SignUpService.getInstance().getBzknd(dgr, keyword);
            if (response.data) {
                setData(response.data);
                setGridKey(prevKey => prevKey + 1); // grid key 변경으로 다시 grid 렌더링
            } else {
                toast.error(`${t("common.message.error.system")}`);
            }
        } catch (error) {
            toast.error(`${t("common.message.error.system")}`);
        }
    }

    // 그리드 컬럼
    const gridColumns: any[] = [
        {
            header: "bzkndId",
            name: "bzkndId",
            hidden: true,
        },
        {
            header: "차수",
            name: "dgr",
            align: "center",
            whiteSpace: "normal",
            width: 20
        },
        {
            header: "구분",
            name: "bzkndNm",
            align: "center",
            whiteSpace: "normal",
            width: 500
        },
        {
            header: "코드",
            name: "emksBzkndCd",
            align: "center",
            whiteSpace: "normal",
        }
    ];

    const checkedRow = (data: ArcGridCheckResult) => {
        if (data?.checkedRowData && data?.checkedRowData?.bzkndId != selectRowData?.bzkndId) {  // dataCheckedRowData가 존재하면서 기존의 id값과 다르다면
            setSelectRowData(data?.checkedRowData)
        } else { // dataCheckedRowData가 존재하지않거나 기존의 id값과 일치하다면
            setSelectRowData(null);
        }
    };

    const handleSave = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            if (saveButtonRef.current) {
                saveButtonRef.current.focus();
            }
        }
    };

    const handleCancelBlur = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            if (firstFocusRef.current) {
                firstFocusRef.current.focus();
            }
        }
        if(event.key==="Enter"){
            dispatch(closeModal());
        }
    };

    return (
        <>
            <div style={{ margin: "20px" }}>
                <div className="inp-flex">
                    <div
                        ref={firstFocusRef}
                        tabIndex={-1}
                        style={{marginBottom: "20px", fontSize: "20px", fontWeight: "bold"}}>
                        <span>표준산업분류중분류</span>
                    </div>

                    <div style={{margin: "30px", display: "flex", alignItems: "center"}}>
                        <ArcSelectbox
                            name={"이름"}
                            options={dgrList}
                            onChange={changeSelect}
                            useDefaultOption={true}
                            size={SelectboxSize.px100}
                            useDefaultOptionName="차수"
                            className="mgr5"
                        />
                        <ArcInput
                            value={keyword ?? ""}
                            isRequired={true}
                            type={"w500"}
                            disabled={false}
                            readonly={false}
                            placeholder="키워드"
                            prefix={""}
                            id={"input-16"}
                            name={"input-16"}
                            className="disIb mgr5"
                            onChange={changeInput}
                        />
                        <ArcButton
                            type={ButtonType.custom}
                            hasIcon={false}
                            color="btn-style2"
                            text={"검색"}
                            onClick={clickSearchBtn}
                        />
                    </div>
                    <div style={{height: "460px", overflow: "auto"}}  onKeyDown={handleSave}>
                        <ArcGrid
                            key={gridKey}
                            gridRef={gridRef}
                            data={data}
                            rowHeaders={["radio"]}
                            columns={gridColumns}
                            usePagination={false}
                            useStaticData={true}
                            onCheckRow={checkedRow}
                        />
                    </div>
                </div>
            </div>
            <div style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div tabIndex={5} ref={saveButtonRef} onKeyDown={useTabSaveData}>
                    <ArcButton type={"custom"} color={ButtonColor.btn2} text={"적용"}
                               onClick={useSavedData}
                    />
                </div>
                <div tabIndex={6} ref={cancelButtonRef} onKeyDown={handleCancelBlur}>
                    <ArcButton type={"custom"} color={ButtonColor.btn3} text={"취소"}
                               onClick={ignoreSavedData}/>
                </div>
            </div>
        </>
    );
}
