import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, HttpStatusCode} from "axios";
import {toast} from "react-toastify";
import {ResponseApi, ResponseResultCode} from "model/response-api";
import {ApiUrlUtil} from "utils/apiUrlUtil";
import {HeadersUtil} from "utils/headersUtil";
import i18n from "i18next";
import {isStringNullOrEmpty} from "utils/stringUtil";
import store from "../../stores/store";
import {clearUserInfo} from "reducers/userInfoSlice";
import {AuthenticationService} from "../ua/AuthenticationService";

export default class Api {
  private instance: AxiosInstance;
  private static readonly defaultErrorMsg = i18n.t('common.message.error.duringProcessing');

  constructor(baseURL = '', timeout = 30000) {
    this.instance = axios.create({
      baseURL,
      timeout,
      headers: HeadersUtil.getHeadersAuth()
    })
  }

  protected async get<T>(url: string, params?: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url)
    if (params) {
      apiUrl = ApiUrlUtil.buildQueryString(url, params)
    }

    return this.convertResult<T>(this.instance.get(apiUrl, {
      headers: HeadersUtil.getHeadersAuth()
    }))
  }

  protected async post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    let apiUrl = ApiUrlUtil.buildQueryString(url)
    if (data instanceof FormData) {
      const defaultConfig = { headers: HeadersUtil.getHeadersAuthFormData() };
      return this.convertResult<T>(this.instance.post(apiUrl, data, config ? config : defaultConfig));
    } else {
      const defaultConfig = { headers: HeadersUtil.getHeadersAuth() };
      return this.convertResult<T>(this.instance.post(apiUrl, data, config ? config : defaultConfig));
    }
  }

  protected async put<T>(url: string, data: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url)
    if (data instanceof FormData) {
      return this.convertResult<T>(this.instance.put(apiUrl, data, {
        headers: HeadersUtil.getHeadersAuthFormData(),
      }))
    } else {
      return this.convertResult<T>(this.instance.put(apiUrl, data, {
        headers: HeadersUtil.getHeadersAuth()
      }))
    }
  }

  protected async delete<T>(url: string, params?: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url)
    if (params) {
      apiUrl = ApiUrlUtil.buildQueryString(url, params)
    }
    return this.convertResult<T>(this.instance.delete(apiUrl, {
      headers: HeadersUtil.getHeadersAuth()
    }))
  }

  protected async deleteOnBody<T>(url: string, params?: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url)
    return this.convertResult<T>(this.instance.delete(apiUrl, {
      data: params,
      headers: HeadersUtil.getHeadersAuth()
    }))
  }

  protected async download(url: string, data: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url, data)
    await this.convertResult<Blob>(this.instance.get(encodeURI(apiUrl), {
      headers: HeadersUtil.getHeadersAuth(),
      responseType: 'blob'
    }))
  }

  protected async downloadSub(url: string, data: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url);
    await this.convertResult<Blob>(this.instance.post(apiUrl, data, {
      headers: HeadersUtil.getHeadersAuth(),
      responseType: 'blob'
    }));
  }

  protected async fetchBlob(url: string, data: any) {
    let apiUrl = ApiUrlUtil.buildQueryString(url, data);
    return await this.convertBlobResult(this.instance.get(encodeURI(apiUrl), {
      headers: HeadersUtil.getHeadersAuth(),
      responseType: 'blob',
    }));
  }

  private async convertBlobResult(response: Promise<AxiosResponse>) {
    try {
      const response_1 = await response;
      const resp: Blob = response_1.data;
      if(resp instanceof Blob) {
        if(HttpStatusCode.Ok === response_1.status) {
          return resp;
        }
      }
    } catch (error: any) {
      return this.convertResultError(error);
    }
  }

  private async convertResult<T>(response: Promise<AxiosResponse>) {
    try {
      const response_1 = await response;
      const resp: ResponseApi<T> = response_1.data;
      if (resp instanceof Blob) {
        if (HttpStatusCode.Ok === response_1.status) {
          return this.downloadResponseFile(resp, response_1.config.url);
        }
      } else {
        if (HttpStatusCode.Ok === resp.statusCode) {
          if (ResponseResultCode.E === resp.resultCode) {
            toast.error(isStringNullOrEmpty(resp.errorMessage) ? Api.defaultErrorMsg : resp.errorMessage);
            return null;
          } else {
            return resp;
          }
        } else {
          toast.error(isStringNullOrEmpty(resp.errorMessage) ? Api.defaultErrorMsg : resp.errorMessage);
          return null;
        }
      }
    } catch (error: any) {
      return this.convertResultError(error);
    }
  }

  private convertResultError(error: any) {
    const code = error.code;
    const status = error.response?.status;

    if (status === 401) {
      store.dispatch(clearUserInfo());
      AuthenticationService.redirectToSignIn();
    } else if ('ECONNABORTED' === code || 408 === status) {
      toast.error(isStringNullOrEmpty(error.message) ? Api.defaultErrorMsg : error.message);
    } else {
      const resp: ResponseApi<null> = error.response?.data;
      toast.error(isStringNullOrEmpty(resp?.errorMessage) ? Api.defaultErrorMsg : resp.errorMessage);
    }

    return null;
  }

  private downloadResponseFile(blob: Blob, requestedUrl?: string) {
    let realNm = '';
    if (requestedUrl) {
      requestedUrl = process.env.REACT_APP_URL_GA + requestedUrl;
      const url = new URL(requestedUrl);
      const name = url.searchParams.get("originalFileName");
      if (name) {
        realNm = decodeURIComponent(name);
      }
    }
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', realNm)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}