import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ArcGrid, {
  ArcGridCheckResult,
  CustomCommonCodeRenderer,
  CustomSelectCommonCodeRenderer,
  GridApi,
  GridColumn,
  GridComplexHeader,
  GridEditor,
} from "components/arc/ArcGrid";
import { ComCdService } from "services/cp/ComCdService";
import ArcDatePicker from "../../../../components/arc/ArcDatePicker";
import { CodeUtil } from "utils/codeUtil";
import { AuthConstant } from "constants/authConstant";
import { UserInfoUtil } from "utils/userInfoUtil";
import { useAppSelector } from "stores/hook";
import { UserInfoModel } from "model/ua/UserInfoModel";


export default function BasicEmission() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();
  const [unitCd, setUnitCd] = useState<any[]>([]);
  const [fuelCd, setFuelCd] = useState<any[]>([]);

  const toYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(toYear);

  // select로 보여질 단위 조회
  useEffect(() => {
    ComCdService.getInstance().getComCdExpl("EMKS_FUEL_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setFuelCd(cdOptions);
        } else {
          setFuelCd([]);
        }
      })
      .catch(() => toast.error(t('common.message.error.system')));

    ComCdService.getInstance().getComCdExpl("EMKS_FUEL_UNIT_CL_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setUnitCd(cdOptions);
        } else {
          setUnitCd([]);
        }
      })
      .catch(() => toast.error(t('common.message.error.system')));
  }, []);

  // 그리드. 체크박스
  const gridRef: any = useRef<typeof ArcGrid>();

  const checkedRow = (data: ArcGridCheckResult) => {
  };

  // 그리드 헤더
  const gridHeader: GridComplexHeader = {
    height: 90,
    complexColumns: [
      {
        header: "단위",
        name: "unit",
        childNames: ["unitName", "mjUnitName"],
      },
      {
        header: "kgGHG/TJ",
        name: "kgGHG",
        childNames: ["cbdxEmsnCoef", "methnEmsnCoef", "dnmxEmsnCoef"],
      },
    ],
  };

  // 그리드 컬럼
  const gridColumns: GridColumn[] = [
    {
      header: "emsnCoefId",
      name: "emsnCoefId",
      hidden: true,
    },
    {
      header: "연료 종류",
      name: "fuelClCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "연료 단위",
      name: "fuelUnitCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "에너지법 시행규칙 상",
      name: "mjUnit",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        const row = e.row;
        if (row.fuelUnitCd?.comCdExpl) {
          return "MJ/" + row.fuelUnitCd?.comCdExpl;
        } else {
          return "-";
        }
      },
    },
    {
      header: "TJ로 환산시",
      name: "tjUnit",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {

        const row = e.row;
        switch (row.fuelUnitCd?.comCdExpl) {
          case "Nm³":
            return "1,000,000Nm³";
          case "L":
            return "TJ/1,000m³";
          case "kg":
            return "TJ/Gg";
          default:
            return "-";
        }
      },
    },
    {
      header: "총발열량",
      name: "totFvrQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
    {
      header: "순발열량",
      name: "qureFvrQnt",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
    {
      header: "CO₂",
      name: "cbdxEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
    {
      header: "CH₄",
      name: "methnEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
    {
      header: "N₂O",
      name: "dnmxEmsnCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
  ];

  //그리드 수정
  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: {
      aplyYy: (year ?? "").toString(),
    },
    editableColumns: [
      {
        columnName: "fuelClCd",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: fuelCd,
          validation: {
            validatorFn: (value: any) => {

              if (value === null || value === undefined) return false;
              if (value?.comCdExpl === undefined) return false;
              const gridInstance = gridRef.current?.getInstance();
              if (!gridInstance) return false;
              const data = gridInstance.getData();
              if (!Array.isArray(data) || data.length === 0) return false;
              return data.filter((e: any) => e?.fuelClCd?.comCdExpl === value.comCdExpl).length === 1;
            }
          }
        },
      },
      {
        columnName: "fuelUnitCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: unitCd,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            }
          }
        },
      },
      {
        columnName: "totFvrQnt",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,4})?$/,
            regExpMessage: "전체 길이는 15자리, 소수점은 4자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: "qureFvrQnt",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,4})?$/,
            regExpMessage: "전체 길이는 15자리, 소수점은 4자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: "cbdxEmsnCoef",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,9})?$/,
            regExpMessage: "전체 길이는 20자리, 소수점은 9자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: "methnEmsnCoef",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,9})?$/,
            regExpMessage: "전체 길이는 20자리, 소수점은 9자리까지 가능합니다.",
          },
        },
      },
      {
        columnName: "dnmxEmsnCoef",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,9})?$/,
            regExpMessage: "전체 길이는 20자리, 소수점은 9자리까지 가능합니다.",
          },
        },
      },
    ],
  };

  // 그리드 수정함수
  function handleChangeEditor(e: any) {
  }

  //  데이터 전송
  const adminGridApi: GridApi = {
    readData: { url: "/cp/mngFuelGhgsEmissCoef", method: "GET", initParams: { aplyYy: year } },
    createData: { url: "/cp/mngFuelGhgsEmissCoef", method: "POST" },
    updateData: { url: "/cp/mngFuelGhgsEmissCoef", method: "PUT" },
    deleteData: {
      url: "/cp/mngFuelGhgsEmissCoef", method: "DELETE", serializer(params) {
        const ids: any = params.deletedRows?.map((row: any) => ({ emsnCoefId: row.emsnCoefId }))
        return encodeURI(`deletedRows=${JSON.stringify(ids)}`)
      }
    },
  };

  const gridApi: GridApi = {
    readData: { url: "/cp/mngFuelGhgsEmissCoef", method: "GET", initParams: { aplyYy: year } },
  };

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">연료별 온실가스별 기본 배출계수</h3>
            <ArcDatePicker
              onChange={(e) => {
                setYear("" + e.fromDateString?.slice(0, 4));
              }}
              fromDate={year}
              id={"year"}
              name={"year"}
              viewType={"year"}
              placeholders={{ fromDate: "YYYY" }} />
          </div>
          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                gridRef={gridRef}
                key={`${fuelCd.length}-${unitCd.length}`} // unitCd 상태가 변경될 때마다 key가 변경되어 그리드가 다시 렌더링
                datasource={adminGridApi}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                usePagination={false}
                useGridInfoHeader={true}
                complexHeader={gridHeader}
                editor={gridEditor}
                onCheckRow={checkedRow} />
              :
              <ArcGrid
                gridRef={gridRef}
                datasource={gridApi}
                columns={gridColumns}
                usePagination={false}
                useGridInfoHeader={true}
                complexHeader={gridHeader}
                onCheckRow={checkedRow} />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
