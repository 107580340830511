import React from "react";
import {useNavigate} from "react-router-dom";
import ArcButton, {ButtonType} from "components/arc/ArcButton";
import {JsxElementConstant} from "constants/jsxElementConstant";

export default function MyPageSideMenu() {
  const navigate = useNavigate();

  function handleClickSlide(event: any) {
    let target = $(event.target);
    if(event.target.parentElement.tagName === 'A') {
      target = $(event.target.parentElement);
    }
    if (target.parent().hasClass('open')) {
      target.parent().removeClass('open');
      target.parent().children('[class^="depth"]').slideUp(300);
    } else {
      target.parent().siblings().removeClass('open');
      target.parent().siblings().children('[class^="depth"]').slideUp(300);
      target.parent().addClass('open');
      target.parent().children('[class^="depth"]').slideDown(300);
    }
  }

  function handleClickMenu(pathname?: string) {
    if(pathname) {
      navigate(pathname);
    }
  }

  return (<div className="depth01" style={{display: 'block'}}>
      <ul>
        <li key="E-Market-My-Page" className={`ov open dep`}>
          <a href={JsxElementConstant.hrefIsNull}
             onClick={handleClickSlide}
             role="button"
             style={{cursor: 'pointer'}}>
            <span>마이 페이지</span>
          </a>
          <div className="depth02" style={{display: 'block'}}>
            <ul>
              <li key="E-Market-My-Info" className="ov">
                <ArcButton type={ButtonType.custom}
                           onClick={() => handleClickMenu('/mypage')}
                           text="내 정보 관리" />
              </li>
              <li key="E-Market-My-Service" className="ov">
                <ArcButton type={ButtonType.custom}
                           onClick={() => handleClickMenu('/mypage/service')}
                           text="서비스 가입 내역" />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>);
}