import axios from "axios";
import {HeadersUtil} from "utils/headersUtil";
import Api from "services/common/Api";
import {UserMemberModel} from "model/ua/UserMemberModel";
import {CustRegRqsModul} from "../../model/ua/CustRegRqsModul";
import {UserMemberPostModel} from "model/ua/UserMemberPostModel";

export class SignUpService extends Api {

  private static _boardService: SignUpService;
  static deleteRow: any;
  private static MAIL_SEND_URL: string = process.env['REACT_APP_URL_CO'] + '/mail/auth/request';
  private static MAIL_CHECK_URL: string = process.env['REACT_APP_URL_CO'] + '/mail/auth/verify';
  private static INSERT_URL: string = process.env['REACT_APP_URL_CO'] + '/user';
  private static SIGN_UP_CEHCK_URL: string = process.env['REACT_APP_URL_CO'] + '/user/emailCheck/';
  private static EXIST_DATA_URL: string = process.env['REACT_APP_URL_CO'] + '/user/draft';
  private static BUSINESS_URL: string = process.env['REACT_APP_URL_CO'] + '/user/validation/business'
  private static GET_GZKND_URL: string = process.env['REACT_APP_URL_CO'] + '/bzknd/list'


  // instance 생성
  public static getInstance(): SignUpService {
    if (!SignUpService._boardService) {
      SignUpService._boardService = new SignUpService();
    }
    return SignUpService._boardService;
  }

  public checkUser(userId: string) {
    const url = `${process.env.REACT_APP_URL_CO}/user/userIdCheck/${userId}`;
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // 가입여부 확인 (사업자등록증)
  public checkCompanyNo(bizrRegNo: string) {
    const url = `${process.env.REACT_APP_URL_CO}/user/bizrRegNoCheck/${bizrRegNo}`;
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public async postMailConfirm(mail: any) {
    return await super.post(SignUpService.MAIL_SEND_URL, mail)
  }

  // 가입 여부 체크
  public async getSignUpCheck(mail: any) {
    return await super.get(SignUpService.SIGN_UP_CEHCK_URL + mail.address);
  }

  // 기존 데이터 불러오기
  public async getExistData(mail: any) {
    return await super.get(SignUpService.EXIST_DATA_URL + "/" + mail.address);
  }

  // 기존 데이터 불러오기
  public async checkExistData(mail: any) {
    return await super.get(SignUpService.EXIST_DATA_URL + "/" + mail);
  }

  // 이메일 인증결과 확인
  public async getMailCheck(mail: any) {
    return await super.get(SignUpService.MAIL_CHECK_URL + "?address=" + mail);
  }

  // 업종코드 조회
  public async getBzknd(selectboxArr: any, keyword: any) {
    if (keyword == undefined || keyword == null || keyword == "") {
      return await super.get(SignUpService.GET_GZKND_URL + "?dgr=" + selectboxArr + "&keyword=");
    }
    return await super.get(SignUpService.GET_GZKND_URL + "?dgr=" + selectboxArr + "&keyword=" + keyword);
  }

  // 사업자등록정보 진위확인
  public async checkBusiness(userMemberModel: UserMemberModel) {
    var data = {
      "businesses": [
        {
          "b_no": userMemberModel.bizrRegNo,
          "start_dt": userMemberModel.opbizYmd,
          "p_nm": userMemberModel.reprNm
        }
      ]
    };
    return await super.post<any>(SignUpService.BUSINESS_URL, data);
  }

  // 임시저장 삭제 
  public async deleteDraft(userId: any) {
    return await super.delete(SignUpService.EXIST_DATA_URL + "?userId=" + userId);
  }

  // 회원가입 (임시저장 포함)
  public async insertSignUp(userMemberModel: UserMemberModel) {

    var postData = new UserMemberPostModel();

    // userMemberModel의 속성을 postData에 복사
    Object.keys(userMemberModel).forEach(key => {
      const value = userMemberModel[key as keyof UserMemberModel];
      if (value !== null && value !== undefined && key in postData) {
        (postData as any)[key] = value;
      }
    });

    const formData = new FormData();
    postData.isUpdate = false;

    // // 파일이 있다면 추가
    // if (userMemberModel?.file?.files[0] && userMemberModel.userId) {
    //   formData.append(userMemberModel.userId, userMemberModel.file.files[0]);
    // }
    // else if (userMemberModel.file?.fileInfos?.length > 0) {
    //   var repAtflId = userMemberModel.file?.fileInfos[0]?.atflId;
    //   postData.repAtflId = repAtflId;
    // }

    // // 파일삭제
    // if (userMemberModel?.isExistFileDelete) {
    //   formData.append('delete', userMemberModel.fileInfos[0]?.atflId);
    // }

    // null, undefined 제거
    Object.keys(postData).forEach(key => {
      if (postData[key as keyof UserMemberPostModel] === undefined) {
        delete postData[key as keyof UserMemberPostModel];
      }
    });

    // 업종코드 
    if (userMemberModel?.staIndClaCerId) {
      const bzkndData = [{"bzkndId": userMemberModel.staIndClaCerId}];
      postData.aprYn = "N";
      postData.useYn = "Y";
      const requestBlob = new Blob([JSON.stringify({
        userRequest: postData,
        userBzkndCdRequest: bzkndData
      })], {type: 'application/json'});
      formData.append('request', requestBlob);
    } else {
      // 'request' 키에 대한 값을 Blob으로 추가
      postData.aprYn = "N";
      postData.useYn = "Y";
      const requestBlob = new Blob([JSON.stringify({userRequest: postData})], {type: 'application/json'});
      formData.append('request', requestBlob);
    }

    return await super.post<any>(SignUpService.INSERT_URL, formData);
  }

  public async insertSignUp2(userMemberModel: UserMemberModel, custRegRqsModul: CustRegRqsModul[]) {
    const formData = new FormData();

    if (userMemberModel.deleteFile) {
      userMemberModel.deleteFile.map((item: any) =>
          (formData.append('delete', item))
      )
    }

    userMemberModel.aprYn = "N";
    userMemberModel.useYn = "Y";

    if (userMemberModel?.staIndClaCerId) {
      const bzkndData = [{"bzkndId": userMemberModel.staIndClaCerId}];
      const requestBlob = new Blob([JSON.stringify({
        userRequest: userMemberModel,
        custRegRqsRequests: custRegRqsModul,
        userBzkndCdRequest: bzkndData,
      })], {type: 'application/json'});
      formData.append('request', requestBlob);
    } else {
      // 'request' 키에 대한 값을 Blob으로 추가
      const requestBlob = new Blob([JSON.stringify({
        userRequest: userMemberModel,
        custRegRqsRequests: custRegRqsModul
      })], {type: 'application/json'});
      formData.append('request', requestBlob);
    }


    custRegRqsModul?.map((item: any) => {
      item?.file?.files?.forEach((item2: any) => {
        if (item.kepcoCustNo && item2) {
          formData.append(item?.kepcoCustNo ?? "", item2);
        }
      });
    })
    return await super.post<any>(SignUpService.INSERT_URL, formData)
  }


}
