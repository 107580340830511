import React, {ChangeEvent, useEffect, useState} from 'react';

interface AppCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  id?: string;
  name?: string;
  readOnly?: boolean;
  isAlt?: boolean
  onChecked: (data: boolean) => void;
  text?:string;
}

export default function ArcCheckbox(props: AppCheckboxProps) {
  const {
    isAlt=false,
    checked,
    disabled=false,
    value = "",
    id = "",
    name = "",
    readOnly=false,
    text="",
    onChecked,
  } = props;

  const [isCheck, setIsCheck] = useState(checked);

  useEffect(() => {
    setIsCheck(checked ?? false)
  }, [checked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(!readOnly){
      if(checked) {
        setIsCheck(checked && e.target.checked);
      } else {
        setIsCheck(e.target.checked);
      }
      onChecked(e.target.checked);
    }
  }

  return (
    <span key={`checkbox-${id}`} className={`checkbox-radio-${isAlt? "type02" : "type01"}`}>
      <label>
        <input
          type="checkbox"
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          checked={isCheck}
          readOnly={readOnly}
          disabled={disabled}
        />
        {props.text}
      </label>
    </span>
  )
}