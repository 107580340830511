import Scope3Grid from "./Scope3Grid";
import ArcCheckbox from "components/arc/ArcCheckbox";
import { BoardScopeProps } from "./ActiveDataDetail";

export default function Scope3(props: Readonly<BoardScopeProps>) {

  const handleDataFromChild1 = (data: any) => {
    props.onData(data);
  };

  const handleIsGroup = (isChecked: any) => {
  }

  return (
    <>
      <div className="tit-check-btns">
        <h4 className="titT3">구분1.통근/출장</h4>
        <span className="checkbox-radio-type02">
          <label htmlFor="checkbox1-2">
            <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
              checked={props?.scopUse === "N" ? true : false} isAlt={true} disabled></ArcCheckbox>
            입력사항 없음
          </label>
        </span>
      </div>
      <Scope3Grid fuflYy={props.fuflYy} scopId={9} scopUse={props.scopUse} onData={handleDataFromChild1} data={props.data}></Scope3Grid>

    </>
  );
}
