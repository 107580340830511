import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from 'redux-persist'
import layoutReducer from 'reducers/layoutSlice'
import menuReducer from 'reducers/sidebarSlice'
import spinnerSlice from 'reducers/spinnerSlice'
import userInfoSlice from "reducers/userInfoSlice";
import userMenuReducer from 'reducers/userMenuSlice'
import modalReducer from 'reducers/modalSlice'
import cpReducer from "reducers/cpSlice";
import rpReducer from "reducers/rpSlice";
import codeReducer from "reducers/codeSlice";
import sessionStorage from "redux-persist/lib/storage/session";
import signUpSlice from "reducers/signUpSlice";
import fullMenuSlice from "reducers/fullMenuSlice";
import alarmSlice from "../reducers/alarmSlice";
import holidaySlice from "../reducers/holidaySlice";


const persistConfig: any = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['userInfo', 'holidays']
}

const rootReducer = combineReducers({
  layout: layoutReducer,
  menus: menuReducer,
  spinner: spinnerSlice,
  fullMenu: fullMenuSlice,
  userInfo: userInfoSlice,
  userMenu: userMenuReducer,
  modal: modalReducer,
  cp: cpReducer,
  rp: rpReducer,
  code: codeReducer,
  signUp: signUpSlice,
  messagesAlarm: alarmSlice,
  holidays: holidaySlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({serializableCheck: false});
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
export const persistor = persistStore(store);