import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import ArcButton, {ButtonColor, ButtonIcon, ButtonSize, ButtonType} from "./ArcButton";

import cntrImgBackground from "assets/images/sub/attach_file_img.png";
import {useTranslation} from "react-i18next";
import {DefaultTFuncReturn} from "i18next";


export interface dbFileInfo {
  name: string;
  size: number;
  fileId: string;
}

interface FileUploaderProps {
  onUpload: (files: File[]) => void;
  maxFileNum: number;
  maxFileSizeMb: number;
  dbFileList: dbFileInfo[];
  localFileList?: File[];
  dbUpload: (dbFiles: dbFileInfo[]) => void;
  isCntr?: boolean;
  hasIcon?: boolean;
  additionalInfo?: string[]|DefaultTFuncReturn[];
}

export default function ArcFileUploader(props: FileUploaderProps) {
  const {t} = useTranslation();
  const {onUpload, maxFileNum, maxFileSizeMb, dbFileList, localFileList, dbUpload, hasIcon=true,} = props;
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(localFileList??[]);
  const [uploadedDbFiles, setUploadedDbFiles] = useState(dbFileList);
  const initialHasUpload = () => (localFileList??[]).length > 0 || (dbFileList??[]).length > 0;
  const [hasUpload, setHasUpload] = useState<boolean>(initialHasUpload);
  let initialBoxPadding = 7.5;

  const bytesToMegabytes = (bytes: number) => {
    if (bytes >= 1000 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
    return (bytes / 1024).toFixed(2) + ' KB';
  };

  const getTotalFileSize = () => {
    return uploadedFiles.reduce((totalSize, file) => totalSize + file.size, 0);
  };

  const handleUpload = useCallback((files: File[]) => {
    const totalFiles = uploadedFiles.length + files.length + dbFileList.length;
    const dbFlSize = dbFileList.reduce((total, file) => (total + parseInt(file.size.toString())), 0);
    const flSize = files.reduce((total, file) => total + file.size, 0);
    const totalFlSize = getTotalFileSize();
    const totalSize = dbFlSize + flSize + totalFlSize;

    if (totalFiles > maxFileNum) {
      alert('파일은 최대 ' + maxFileNum + '개 까지만 업로드할 수 있습니다.');
      return;
    }
    if (totalSize > maxFileSizeMb * 1024 * 1024) {
      alert('총 파일 크기는  ' + maxFileSizeMb + 'Mb를 넘을 수 없습니다.');
      return;
    }
    setUploadedFiles(prevFiles => [...prevFiles, ...files]);

    // 업로드된 파일을 부모 컴포넌트에 전달
    onUpload([...uploadedFiles, ...files]);

  }, [onUpload, uploadedFiles]);

  const changeIconBoxPadding = useCallback(() => {
    const fileLength = (uploadedFiles??[]).length + (uploadedDbFiles??[]).length;
    const padding = initialBoxPadding - ((initialBoxPadding / maxFileNum) * fileLength);
    return padding + 1;
  }, [uploadedFiles, uploadedDbFiles, initialBoxPadding, maxFileNum]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    handleUpload(acceptedFiles);
  }, [handleUpload]);

  const {getRootProps, getInputProps} = useDropzone({onDrop, noClick: true});

  const handleButtonClick = () => {
    // 파일 선택 창 열기
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;
    fileInput.click();
    fileInput.onchange = (e: any) => {
      const target = e.target as HTMLInputElement;
      if (target.files) {
        handleUpload(Array.from(target.files));
      }
    };
  };

  const onCancelDbClick = (index: number) => {
    const updatedDbFiles = uploadedDbFiles.filter((file, i) => i !== index);
    setUploadedDbFiles(updatedDbFiles);
    dbUpload(updatedDbFiles);
  }
  const onCancelClick = (index: number) => {
    const updatedFiles = uploadedFiles.filter((file, i) => i !== index);
    setUploadedFiles(updatedFiles);
    onUpload(updatedFiles);
  };

  useEffect(() => {
    setUploadedDbFiles(props.dbFileList);
  }, [props.dbFileList]);

  useEffect(() => {
    setUploadedFiles(props.localFileList??[]);
  }, [props.localFileList]);

  useEffect(() => {
    setHasUpload((localFileList??[]).length > 0 || (dbFileList??[]).length > 0);
  }, [uploadedFiles, uploadedDbFiles]);

  function fileExistTemplate(localFiles: File[], dbFiles: dbFileInfo[]) {
    return (
      <div>
        <ul>
          {dbFiles.length > 0 && (dbFiles.map((e, i) => uploadFileLiTemplate(e, i)))}
          {localFiles.length > 0 && (localFiles.map((e, i) => uploadFileLiTemplate(e, i)))}
        </ul>
      </div>);
  }

  function uploadFileLiTemplate(file: dbFileInfo | File, index: number) {
    if('lastModified' in file) {
      return (
        <li key={index}>
          {file.name} ({bytesToMegabytes(file.size)}){' '}
          <ArcButton onClick={() => onCancelClick(index)} hasIcon={true}
                     icon={ButtonIcon.delete}/>
        </li>);
    } else {
      return (
        <li key={index}>
          {file.name} ({bytesToMegabytes(file.size)}){' '}
          <ArcButton onClick={() => onCancelDbClick(index)}
                     hasIcon={true} icon={ButtonIcon.delete} />
        </li>);
    }
  }

  function uploadFileLiTemplateCntr(file: dbFileInfo | File, index: number) {
    return (
      <li key={index} style={{width: "100%"}}>
        <div className="box-style8 mgl10 mgr10" style={{padding: "0.7rem 0.7rem"}}>
          <strong className="al">
            {file.name}{' '}
            <span className="fs12 pc--black">
              ({bytesToMegabytes(file.size)})
            </span>
          </strong>
          <span className="ar">
            <ArcButton hasIcon={true} icon={ButtonIcon.delete}
                       size={ButtonSize.large}
                       onClick={() => 'lastModified' in file ? onCancelClick(index) : onCancelDbClick(index)}
            />
          </span>
        </div>
      </li>
    )

  }

  if(!props.isCntr) {
    return (<div>
        <div {...getRootProps()} className={'attach-file expanded'}>
          <input {...getInputProps()} />
          {uploadedFiles?.length > 0 || uploadedDbFiles?.length > 0
            ? (fileExistTemplate(uploadedFiles, uploadedDbFiles))
            : (
              <div>
                <p style={{textAlign: 'center'}}>파일을 여기에 끌어다 놓거나 추가 버튼을 클릭하여 업로드하세요</p>
              </div>
            )}
          <ArcButton onClick={handleButtonClick}
                     type={ButtonType.regist}
                     hasIcon={true}
                     icon={ButtonIcon.upload}
                     title={"새창"}
                     text="파일 선택"/>
        </div>
        <div className="al">
          <div className="n-list-flex">
            <div>
              <p className="bu-atte mgt0 mgr10">파일은 최대 {maxFileNum}개 까지만 업로드할 수 있습니다.</p>
              <p className="bu-atte mgt0">총 파일 크기는 {maxFileSizeMb}Mb를 넘을 수 없습니다.</p>
            </div>
            <div>
              <p className="bu-atte mgt0 mgr10">첨부 가능 문서 파일: .hwp, .pdf, .xlsx(.xls), .pptx(.ppt), .docx(.doc)</p>
              <p className="bu-atte mgt0">첨부 가능 이미지 파일: .jpg, .png, .gif, .svg</p>
            </div>
            {(props.additionalInfo && props.additionalInfo.length > 0) && props.additionalInfo.map((m, index) => {
              if(index % 2 === 0) {
                return (<Fragment key={`additional-file-upload-info-${index}`}>
                  <p className="bu-atte mgt0 mgr10">{m}</p>
                  {props.additionalInfo?.length === index + 2
                    ? <p className="bu-atte mgt0">{props.additionalInfo[index + 1]}</p>
                    : <></>
                  }
                </Fragment>);
              }
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="veiwer-box">
        <div className="icon-box-wrap1" style={{height: "100%", padding: `${changeIconBoxPadding()}rem 0`}} {...getRootProps()}>
          <input {...getInputProps()} />
          <span className="img mgb5"><img src={cntrImgBackground} alt="" /></span>
          <p className="txt1">계약 체결에 필요한 관련 서류가 있으면 첨부하세요.</p>
          <ArcButton type={ButtonType.custom}
                     color={ButtonColor.btn2}
                     size={ButtonSize.large}
                     hasIcon={hasIcon}
                     icon={ButtonIcon.save}
                     text={t('common.button.attach')}
                     onClick={handleButtonClick} />
          {hasUpload && (<>
            <div className="mgb5">
              <ul className="box-graph3 box-graph3-2" style={{display: 'block'}}>
                {uploadedDbFiles.length > 0 && (uploadedDbFiles.map((e, i) => uploadFileLiTemplateCntr(e, i)))}
                {uploadedFiles.length > 0 && (uploadedFiles.map((e, i) => uploadFileLiTemplateCntr(e, i)))}
              </ul>
            </div>
          </>)}
        </div>
      </div>);

  }
};