// import { ApiUrlUtil } from "./utils/apiUrlUtil";
import axios from "axios";
import Api from "../common/Api";
import {ApiUrlUtil} from "../../utils/apiUrlUtil";
import {HeadersUtil} from "../../utils/headersUtil";
import {UserMemberModel} from "../../model/ua/UserMemberModel";
import {UserMemberPostAdminModel} from "../../model/ua/UserMemberPostAdminModel";
import {CustRegRqsModul} from "../../model/ua/CustRegRqsModul";


export class UserService extends Api {
  private static service: UserService;
  private static PASSWORD_URL: string = process.env["REACT_APP_URL_CO"] + "/user/password";
  private static INIT_PASSWORD_URL: string = process.env['REACT_APP_URL_CO'] + '/user/password/init'
  private static FILE_DOWNLOAD_URL: string = process.env['REACT_APP_URL_CO'] + "/files/file"
  private static GET_GZKND_URL: string = process.env['REACT_APP_URL_CO'] + '/bzknd/list'
  private static BUSINESS_URL: string = process.env['REACT_APP_URL_CO'] + '/user/validation/business'
  private static UPDATE_URL: string = process.env['REACT_APP_URL_CO'] + '/user';
  private static ACCEPT_USER_URL: string = process.env['REACT_APP_URL_CO'] + '/user/approval'
  private static LOCK_USER_URL: string = process.env['REACT_APP_URL_CO'] + '/user/use'
  private static SIGN_UP_CEHCK_URL: string = process.env['REACT_APP_URL_CO'] + '/user/emailCheck/'
  private static MAIL_SEND_URL: string = process.env['REACT_APP_URL_CO'] + '/mail/auth/request'
  private static MAIL_CHECK_URL: string = process.env['REACT_APP_URL_CO'] + '/mail/auth/verify'
  private static IMAGE_SRC_URL: string = process.env['REACT_APP_URL_CO'] + '/files/image'
  private static CREATE_SIGNATURE_URL: string = process.env['REACT_APP_URL_CO'] + '/user/signature';
  private static GET_SIGNATURE_URL: string = process.env['REACT_APP_URL_CO'] + '/user/signature';



  public static getInstance(): UserService {
    if (!UserService.service) {
      UserService.service = new UserService();
    }
    return UserService.service;
  }

  public async saveKepcoInfo(formData: FormData) {
    return await super.post<any>(UserService.UPDATE_URL, formData);
  }



  public getUserId(userId: string) {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/user/" + userId);
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getComcd() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CO + "/com/code");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  // 비밀번호변경 api
  public async putPassword(form: any) {
    return await super.put(UserService.PASSWORD_URL, form);
  }



  // 정보 수정 - user
  public async saveUserInfo(tempUserMemberInfoModel: UserMemberModel, userMemberInfoModel: UserMemberModel) {
    const formData = new FormData();
    var postData = new UserMemberPostAdminModel();

    // userMemberModel의 속성을 postData에 복사
    Object.keys(tempUserMemberInfoModel).forEach(key => {
      const value = tempUserMemberInfoModel[key as keyof UserMemberModel];
      if (value !== null && value !== undefined && key in postData) {
        (postData as any)[key] = value;
      }
    });

    // null, undefined 제거
    Object.keys(postData).forEach(key => {
      if (postData[key as keyof UserMemberPostAdminModel] === undefined) {
        delete postData[key as keyof UserMemberPostAdminModel];
      }
    });

    postData.userTmpSavYn = "N";

    // 로고 파일 추가
    if (tempUserMemberInfoModel?.logo) {
      // 로고 파일이 있고, 이전 파일 id가 같다면 (아무것도 삭제 안됨)
      if (tempUserMemberInfoModel?.logo?.atflId && tempUserMemberInfoModel?.logo?.atflId == tempUserMemberInfoModel?.coLogoAtflId) {
      } else {
        // 새 파일 추가
        formData.append("logo", tempUserMemberInfoModel?.logo);
      }
    }

    // 로고 파일 삭제
    if (tempUserMemberInfoModel?.isExistLogoFileDelete && userMemberInfoModel?.coLogoAtflId) {
      formData.append('delete', userMemberInfoModel?.coLogoAtflId);
    }

    // 인감 파일 추가
    if (tempUserMemberInfoModel?.ofcsl) {
      if (tempUserMemberInfoModel?.ofcsl?.atflId && tempUserMemberInfoModel?.ofcsl?.atflId == tempUserMemberInfoModel?.coOfcslAtflId) {
      } else {
        formData.append("ofcsl", tempUserMemberInfoModel?.ofcsl);
      }
    }

    // 인감 파일 삭제
    if (tempUserMemberInfoModel?.isExistOfcslFileDelete && userMemberInfoModel?.coOfcslAtflId) {
      formData.append('delete', userMemberInfoModel?.coOfcslAtflId);
    }

    // 업종코드
    if (tempUserMemberInfoModel.bzknds && tempUserMemberInfoModel?.bzknds?.length > 0) {
      const bzkndData = [{ "bzkndId": tempUserMemberInfoModel?.bzknds[0].bzkndId }];
      const requestBlob = new Blob([JSON.stringify({ userRequest: postData, userBzkndCdRequest: bzkndData, isUpdate: true })], { type: 'application/json' });
      formData.append('request', requestBlob);
    } else {
      const requestBlob = new Blob([JSON.stringify({ userRequest: postData, isUpdate: true })], { type: 'application/json' });
      formData.append('request', requestBlob);
    }
    return await super.put<any>(UserService.UPDATE_URL, formData);
  }

  // 인증 이메일 발송 - user
  public async postMailConfirm(mail: any) {
    var data = {
      "address": mail
    }
    return await super.post(UserService.MAIL_SEND_URL, data)
  }


  // 이미지 src 프리뷰 - user
  public async getImgSrc(fileName: string, filePath: string): Promise<Blob> {
    const url = `${UserService.IMAGE_SRC_URL}?fileName=${fileName}&filePath=${filePath}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('네트워크 응답이 실패했습니다');
    }

    return await response.blob();
  }

  // 전자서명 생성
  public async createSignatureInfo(userId: any) {
    var data = {
      "userId": userId
    }
    return await super.post(UserService.CREATE_SIGNATURE_URL, data)
  }

  // 전자서명 정보 조회
  public async getSignatureInfo(userId: any) {
    return await super.get(UserService.GET_SIGNATURE_URL + "?userId=" + userId)
  }
}
