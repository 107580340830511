import ArcInput from "components/arc/ArcInput"

export default function ArcInputExample() {
  const handleChange = (event: any, isValid: any) => {
  };

  return (
    <>
      <ArcInput value={""} type={"custom"} className={"inpSel"} inputType={"text"} maxLength={5}
                minValue={-2} maxValue={1000} isRequired={true}
                disabled={false} readonly={false}
                placeholder={"input default"} prefix={""} id={"input-1"} name={"input-1"}
                onChange={handleChange}></ArcInput>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <ArcInput value={""} displayTitle={false} title={"title"} inputType={"tel"}
                isRequired={false} validateMessage={"Input"} disabled={false} readonly={false}
                placeholder={"input default"} prefix={""} id={"input-2"} name={"input-2"}
                onChange={handleChange}></ArcInput>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <ArcInput value={""} inputType={"text"} isRequired={true} disabled={false} readonly={false}
                placeholder={"input default"} prefix={""} id={"input-3"} name={"input-3"}
                onChange={handleChange}></ArcInput>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <ArcInput value={""} inputType={"email"} isRequired={true} disabled={false} readonly={false}
                placeholder={"input default"} pattern={"^[0-9]*$"} prefix={""}
                validateMessage={"Empty input"}
                id={"input-4"} name={"input-4"}
                onChange={handleChange}></ArcInput>

      <hr style={{margin: '1rem 0', borderTop: '1px solid #d1d1d1'}}/>
      <h5 className="titT3">input width %</h5>

      <input type="text" title="타이틀n" placeholder="width 5%" className="InpSel-w5"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 10%" className="InpSel-w10"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 15%" className="InpSel-w15"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 20%" className="InpSel-w20"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 30%" className="InpSel-w30"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 40%" className="InpSel-w40"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 50%" className="InpSel-w50"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 60%" className="InpSel-w60"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 70%" className="InpSel-w70"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 80%" className="InpSel-w80"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 90%" className="InpSel-w90"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 100%" className="InpSel-w100"/>


      <hr style={{margin: '1rem 0', borderTop: '1px solid #d1d1d1'}}/>
      <h5 className="titT3">input width px</h5>

      <input type="text" title="타이틀" placeholder="width 50px" className="InpSel-50"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 80px" className="InpSel-80"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 100px" className="InpSel-100"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 150px" className="InpSel-150"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 200px" className="InpSel-200"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 250px" className="InpSel-250"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 300px" className="InpSel-300"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 350px" className="InpSel-350"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 400px" className="InpSel-400"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 450px" className="InpSel-450"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <input type="text" title="타이틀" placeholder="width 500px" className="InpSel-500"/>
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

    </>
  )
}