import ArcDatePicker, {ArcDatePickerResult} from "components/arc/ArcDatePicker";
import ArcSelectbox from "components/arc/ArcSelectbox";
import {VariablesConstant} from "constants/variables";
import ArcButton, {ButtonType} from "components/arc/ArcButton";
import React, {useEffect, useState} from "react";
import {BillListSearchProps, BillSearchModel} from "pages/cb/bill/billList/BillListBase";
import {useTranslation} from "react-i18next";

export default function BillListSearch(props: BillListSearchProps) {
  const {t} = useTranslation();

  const initSearch = new BillSearchModel(props.emksReqdCd, props.type);
  const [search, setSearch] = useState<BillSearchModel>(initSearch);

  function handleChangeSearchReqYmd(result: ArcDatePickerResult) {
    setSearch({
      ...search,
      reqYmdStdt: result.fromDateString,
      reqYmdEndt: result.toDateString
    });
  }

  function handleChangeSelect(event: any) {
    setSearch({
      ...search,
      [event.target.name]: event.target.value
    });
  }

  function handleClickReset() {
    setSearch(initSearch);
  }

  useEffect(() => {
    setSearch(initSearch)
  }, [props.emksReqdCd, props.type]);

  useEffect(() => {
    props.search(search);
  }, [search]);

  return (
    <div className="srh-filter">
      <ul>
        {0 === props.emksReqdCd && (
          <li className="mgr20">
            <span className="srh-tit">거래구분</span>
            <ArcSelectbox name="emksReqdCd"
                          onChange={handleChangeSelect}
                          options={VariablesConstant.BILL_TRD_SORT}
                          selectedValue={search.emksReqdCd}
            />
          </li>
        )}
        <li>
          <span className="srh-tit">{t('cb.bill.search.billDate')}</span>
          <ArcDatePicker onChange={handleChangeSearchReqYmd}
                         selectionMode="range"
                         fromDate={search.reqYmdStdt}
                         toDate={search.reqYmdEndt}
                         id="reqYmdSearch" name="reqYmdSearch" />
        </li>
        <li className="mgl20">
          <span className="srh-tit">{t('cb.bill.search.setlStatus')}</span>
          <ArcSelectbox name="setlSortCd"
                        onChange={handleChangeSelect}
                        selectedValue={search.setlSortCd}
                        useDefaultOption={true}
                        useDefaultOptionName={t('common.label.total').toString()}
                        options={VariablesConstant.BILL_SETL} />
        </li>
        {'s' === props.type && (
          <li className="mgl20">
            <span className="srh-tit">{t('cb.bill.search.pblsStatus')}</span>
            <ArcSelectbox name="reqdPblsYn"
                          onChange={handleChangeSelect}
                          selectedValue={search.reqdPblsYn}
                          useDefaultOption={true}
                          useDefaultOptionName={t('common.label.total').toString()}
                          options={VariablesConstant.BILL_PBLS_YN} />
          </li>)}
      </ul>
      <ArcButton type={ButtonType.reset} hasIcon={true} onClick={handleClickReset} />
    </div>
  );
}