import { AuditModel } from "model/common/auditModel";

export class DocuActv extends AuditModel {
  docId?: string;
  regDate?: Date;
  regrId?: string;
  chgDate?: Date;
  chgrId?: string;

  usePrdStrtYmd?: string;
  usePrdEndYmd?: string;

  strtYm?: string;
  endYm?: string;
}
