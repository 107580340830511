import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {UserInfoUtil} from "utils/userInfoUtil";
import {AuthConstant} from "constants/authConstant";
import {UserInfoModel} from "model/ua/UserInfoModel";
import {useAppSelector} from "stores/hook";
import {EmarketMenuModel} from "reducers/userMenuSlice";
import {JsxElementConstant} from "constants/jsxElementConstant";

export default function SideMenu() {
  const navigate = useNavigate()
  const userMenu: EmarketMenuModel[] = useAppSelector(state => state.userMenu.userMenu);
  const userInfo: UserInfoModel = useAppSelector((state) => state.userInfo.userInfo);
  const [selectedMenuUpperMenuIds, setSelectedMenuUpperMenuIds] = useState<string[]>([]);
  const [selectedMenuId, setSelectedMenuId] = useState<string | null>(null);

  useEffect(() => {
    const selectedMenuUpperMenus = localStorage.getItem('selectedMenuUpperMenuIds');
    const selectedMenuId = localStorage.getItem('selectedMenuId');
    setSelectedMenuUpperMenuIds(selectedMenuUpperMenus ? selectedMenuUpperMenus.split(',') : []);
    setSelectedMenuId(selectedMenuId);
  }, []);

  const menuWrapper = (list: EmarketMenuModel[], depth: number, menuBlockVisible: boolean = false, upperMenuIds: string | undefined = undefined) => {
    let className: string = 'depth0' + depth.toString();
    return (
        <div className={className} style={menuBlockVisible ? {display: 'block'} : {}}>
          <ul>
            {list.map((item: EmarketMenuModel) => menu(item, depth, upperMenuIds))}
          </ul>
        </div>
    );
  };

  const menu = (item: EmarketMenuModel, depth: number, upperMenuIds: string | undefined) => {
    let disabledStyle = {
      cursor: 'default',
      color: '#666666'
    };

    const currentUpperMenuIds = upperMenuIds ? `${upperMenuIds},${item.menuId}`: item.menuId;
    const isSelected = item.menuId === selectedMenuId;
    const hasChildren = item.childMenus && item.childMenus.length > 0;
    const itemClass = (isSelected ? 'ov ' : '') + (hasChildren ? 'dep ' : '');
    const style = (!hasChildren && !item.url) ? disabledStyle : {};
    const isSelectedMenuUpperMenuId = selectedMenuUpperMenuIds.includes(item.menuId);

    return (
      (UserInfoUtil.hasRole(userInfo, AuthConstant.R002) && item.menuId === "10")
      ? <></>
      : <li key={item.menuId} id={item.menuId} className={`${itemClass} ${isSelectedMenuUpperMenuId ? 'open': ''}`}>
          <a href={item.url ? item.url : '/'}
             onClick={e => handleClickMenu(e, item, currentUpperMenuIds)}
             role="button"
             style={{cursor: 'pointer'}}>
            <span style={style}>{item.menuName}</span>
          </a>
          {hasChildren && menuWrapper(item.childMenus, depth + 1, isSelectedMenuUpperMenuId, currentUpperMenuIds)}
        </li>
    );
  };

  const handleClickMenu = (event: any, item: EmarketMenuModel, upperMenuIds: string | undefined) => {
    event.preventDefault();
    const selectedMenuUpperMenuIds = upperMenuIds ? upperMenuIds.split(',') : [];
    setSelectedMenuUpperMenuIds(selectedMenuUpperMenuIds);
    localStorage.setItem('selectedMenuUpperMenuIds', upperMenuIds??'');
    setSelectedMenuId(item.menuId);
    localStorage.setItem('selectedMenuId', item.menuId);
    if ((item.childMenus === undefined || item.childMenus.length === 0) && item.url) {
      if (UserInfoUtil.hasRole(userInfo, AuthConstant.R002) && item.menuId === "11") {
        navigate("/db")
      } else {
        navigate(item.url)
      }
    } else {
      let target = $(event.target);
      if(event.target.tagName === 'SPAN') {
        target = $(event.target.parentElement);
      } else if(event.target.tagName === 'A') {
        target = $(event.target);
      }
      if (target.parent().hasClass('open')) {
        target.parent().removeClass('open');
        target.parent().children('[class^="depth"]').slideUp(300);
      } else {
        target.parent().siblings().removeClass('open');
        target.parent().siblings().children('[class^="depth"]').slideUp(300);
        target.parent().addClass('open');
        target.parent().children('[class^="depth"]').slideDown(300);
      }
    }
  };

  return (<>{menuWrapper(userMenu, 1)}</>);
}