import ContractModel from "model/cb/contractModel";
import { billPageType } from "pages/cb/bill/billList/BillListBase";
import { ReqdInfoModel } from "model/cb/reqdInfoModel";
import { CoUserModel } from "model/common/coUserModel";
import { ReqdAdjsModel } from "model/cb/reqdAdjsModel";

export class BillModel {

  reqId?: string;
  cntrNo?: string;
  dgr?: string;
  contract?: ContractModel;
  reqdInfo?: ReqdInfoModel;
  reqdAdjs?: ReqdAdjsModel;
  reqdCd?: number;
  tchgrId?: string;
  tchgrCplc?: string;
  tchgrEmail?: string;
  reqYmd?: string;
  payYmd?: string;
  reqAmt?: number;
  pblsYn?: string;
  txblYn?: string;
  encEltrSgntCtt?: string; // 암호화 전자서명내용

  setlMhdCd?: number; // 결제방식구분코드 -> 1: 계좌이체(무통장), 2: 카드결제
  setlYmd?: string; // 결제일자
  setlAmt?: number; // 결제금액

  trdKndCd?: number;
  pageType?: billPageType;

  salerInfo?: CoUserModel;
  pchsrInfo?: CoUserModel;

  regrId?: string;

  // 결제 후 정보
  // 거래번호,


  constructor(data?: Partial<BillModel>) {
    Object.assign(this, data)
  }

  static fromMap(data: Record<string, any>): BillModel {
    return new BillModel(data)
  }
}
