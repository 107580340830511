import { ApiUrlUtil } from "utils/apiUrlUtil";
import axios from "axios";
import { HeadersUtil } from "utils/headersUtil";
import Api from "services/common/Api";

export class AnlsInqService extends Api {
  private static service: AnlsInqService;
  static readonly deleteRow: any;

  public static getInstance(): AnlsInqService {
    if (!AnlsInqService.service) {
      AnlsInqService.service = new AnlsInqService();
    }
    return AnlsInqService.service;
  }


  public getSelectAll() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/AnlsInq/StatusSelect");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }

  public getGhgsChart() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/AnlsInq/ghgsChart");
    return axios.get(url, {
      headers: HeadersUtil.getHeaders(),
    });
  }


}
