import {ApiUrlUtil} from "utils/apiUrlUtil";
import Api from "services/common/Api";
import {Mhdlg} from "model/cp/Mhdlg";
import {FileInfoModel} from "model/common/fileInfoModel";
import {DocuBizPlnd} from "model/cp/DocuBizPlnd";
import {DocuMoni} from "model/cp/DocuMoni";

export class MhdlgService extends Api {
  private static service: MhdlgService;
  private static DELETE_MANY_URL: string =
    process.env["REACT_APP_URL_CP"] + "/mhdlg/deleteList";
  private static GET_URL: string =
    process.env["REACT_APP_URL_CP"] + "/mhdlg/detail";
  private static DELETE_URL: string =
    process.env["REACT_APP_URL_CP"] + "/mhdlg/deleteRow";
  private static POST_URL: string =
    process.env["REACT_APP_URL_CP"] + "/mhdlg/save";

  private static FILE_URL: string =
    process.env["REACT_APP_URL_CP"] + "/files/file";
  private static FILE_BLOB_URL: string =
    process.env["REACT_APP_URL_CP"] + "/mhdlg/file";

  private static DELETE_BUSINESS_URL: string =
    process.env["REACT_APP_URL_CP"] + "/bizplnd/deleteRow";
  private static GET_BUSINESS_URL: string =
    process.env["REACT_APP_URL_CP"] + "/bizplnd/detail";
  private static DELETE_MANY_BUSINESS_URL: string =
    process.env["REACT_APP_URL_CP"] + "/bizplnd/deleteList";
  private static POST_BUSINESS_URL: string =
    process.env["REACT_APP_URL_CP"] + "/bizplnd/save";

  private static POST_MONITORING_URL: string =
    process.env["REACT_APP_URL_CP"] + "/moni/save";
  private static DELETE_MONITORING_URL: string =
    process.env["REACT_APP_URL_CP"] + "/moni/deleteRow";
  private static GET_MONITORING_URL: string =
    process.env["REACT_APP_URL_CP"] + "/moni/detail";
  private static DELETE_MANY_MONITORING_URL: string =
    process.env["REACT_APP_URL_CP"] + "/moni/deleteList";

  public static getInstance(): MhdlgService {
    if (!MhdlgService.service) {
      MhdlgService.service = new MhdlgService();
    }
    return MhdlgService.service;
  }

  public async downloadFile(file: FileInfoModel) {
    const param = {
      filePath: file.pthNm,
      fileName: file.fileNm,
      originalFileName: file.realNm,
    };
    await super.download(MhdlgService.FILE_URL, param);
  }

  // 다배출원 분석
  public getScopeMhdlgList(scopNm: string) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/mhdlg/select/scopeList?scopNm=" + scopNm
    );
    return super.get(url);
  }

  public getMhdlgListAll() {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/mhdlg/select"
    );
    return super.get<Mhdlg[]>(url);
  }

  // 방법론
  public getMhdlgListSearch(contents: string) {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/mhdlg/search"
    );
    return super.post(url, contents);
  }

  public async deleteMhdlgs(mhdlgs: Mhdlg[]) {
    return await super.post<Mhdlg[]>(MhdlgService.DELETE_MANY_URL, {
      deletedRows: mhdlgs,
    });
  }

  public async deleteMhdlg(mhdlg: Mhdlg) {
    var mhdlgId = mhdlg.mhdlgId;
    var mainFileId = null;
    var bizMainFileId = null;
    var moniMainFileId = null;

    if (mhdlg.file?.mainFileId != undefined || mhdlg.file?.mainFileId != null) {
      mainFileId = mhdlg.file?.mainFileId;
    }
    if (mhdlg.bizFile?.mainFileId != undefined || mhdlg.bizFile?.mainFileId != null) {
      bizMainFileId = mhdlg.bizFile?.mainFileId;
    }
    if (mhdlg.moniFile?.mainFileId != undefined || mhdlg.moniFile?.mainFileId != null) {
      moniMainFileId = mhdlg.moniFile?.mainFileId;
    }

    return await super.delete<Mhdlg>(MhdlgService.DELETE_URL, {
      mhdlgId: mhdlgId,
      mainFileId: mainFileId,
      bizMainFileId: bizMainFileId,
      moniMainFileId: moniMainFileId
    });
  }

  public async getMhdlgDetail(mhdlgId: string) {
    return await super.get<Mhdlg>(MhdlgService.GET_URL, { mhdlgId: mhdlgId });
  }

  public async saveMhdlg(form: FormData) {
    return await super.post<Mhdlg>(MhdlgService.POST_URL, form);
  }

  // 사업계획서
  public getBizListAll() {
    const url = ApiUrlUtil.buildQueryString(
      process.env.REACT_APP_URL_CP + "/bizplnd/select"
    );
    return super.get(url);
  }

  public async deleteDocuBizPlnd(docuBizPlnd: DocuBizPlnd) {
    var docId = docuBizPlnd.docId;
    var mainFileId = null;
    if (
      docuBizPlnd.file?.mainFileId != undefined ||
      docuBizPlnd.file?.mainFileId != null
    ) {
      mainFileId = docuBizPlnd.file?.mainFileId;
    }
    return await super.delete<DocuBizPlnd>(MhdlgService.DELETE_BUSINESS_URL, {
      docId: docId,
      mainFileId: mainFileId,
    });
  }

  public async deleteDocuBizPlnds(docuBizPlnds: DocuBizPlnd[]) {
    return await super.post<DocuBizPlnd[]>(
      MhdlgService.DELETE_MANY_BUSINESS_URL,
      {
        deletedRows: docuBizPlnds,
      }
    );
  }

  public async getDocuBizPlndDetail(docId: string) {
    return await super.get<DocuBizPlnd>(MhdlgService.GET_BUSINESS_URL, {
      docId: docId,
    });
  }

  public async saveDocBusiness(form: FormData) {
    return await super.post<DocuBizPlnd>(MhdlgService.POST_BUSINESS_URL, form);
  }

  // 모니터링
  public async deleteMoni(docuMoni: DocuMoni) {
    var docId = docuMoni.docId;
    var mainFileId = null;
    if (
      docuMoni.file?.mainFileId != undefined ||
      docuMoni.file?.mainFileId != null
    ) {
      mainFileId = docuMoni.file?.mainFileId;
    }

    return await super.delete<DocuMoni>(MhdlgService.DELETE_MONITORING_URL, {
      docId: docId,
      mainFileId: mainFileId,
    });
  }

  public async getDocuMoniDetail(docId: string) {
    return await super.get<DocuMoni>(MhdlgService.GET_MONITORING_URL, {
      docId: docId,
    });
  }

  public async deleteMonis(docuMonis: DocuMoni[]) {
    return await super.post<DocuMoni[]>(
      MhdlgService.DELETE_MANY_MONITORING_URL,
      {
        deletedRows: docuMonis,
      }
    );
  }

  public async saveDocMonitoring(form: FormData) {
    return await super.post<DocuBizPlnd>(
      MhdlgService.POST_MONITORING_URL, form);
  }

  public async getMhdlgFileInfoBySearch(mhdlgId: string, type: "1" | "2") {
    const param: any = { mhdlgId: mhdlgId, type: type }
    return await super.fetchBlob(MhdlgService.FILE_BLOB_URL, param);
  }
}