import ArcButton, { ButtonIcon } from "components/arc/ArcButton";
import ArcCheckbox from "components/arc/ArcCheckbox";
import ArcInput from "components/arc/ArcInput";
import { t } from "i18next";
import { ResponseResultCode } from "model/response-api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ActvScopService } from "services/cp/ActvScopService";

export default function BoundarySetting() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [check1, setCheck1] = useState("N");
  const [check2, setCheck2] = useState("N");
  const [check3, setCheck3] = useState("N");
  const [mngId, setMngId] = useState("");
  const [companyNm, setCompanyNm] = useState("");

  useEffect(() => {
    getCheckScope();
  }, []);

  function getCheckScope() {
    ActvScopService.getInstance()
      .getCheckScope()
      .then((response: any) => {
        if (response.data) {
          setMngId(response.data.mngId);
          setCheck1(response.data.scopN1UseYn);
          setCheck2(response.data.scopN2UseYn);
          setCheck3(response.data.scopN3UseYn);
        } else {
          setCheck1("N");
          setCheck2("N");
          setCheck3("N");
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });

    ActvScopService.getInstance().getUserCompany()
      .then((response: any) => {
        if (response.data) {
          setCompanyNm(response.data.data);
        }
      })
      .catch(() => toast.error(t('common.message.error.system')));
  }

  function handleSettingClick() {
    setIsDisabled(!isDisabled);
    setShowSaveBtn(true);
  }

  function handleChange() { }

  function clickScop1Btn() {
    if (check1 === "Y") {
      setCheck1("N");
    } else {
      setCheck1("Y");
    }
  }

  function clickScop2Btn() {
    if (check2 === "Y") {
      setCheck2("N");
    } else {
      setCheck2("Y");
    }
  }

  function clickScop3Btn() {
    if (check3 === "Y") {
      setCheck3("N");
    } else {
      setCheck3("Y");
    }
  }

  const clickSaveBtn = async () => {
    const data = {
      mngId: mngId,
      scopN1UseYn: check1,
      scopN2UseYn: check2,
      scopN3UseYn: check3,
    };

    const response = await ActvScopService.getInstance().saveCheckScope(data);
    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        toast.success(t("common.message.success.save", { title: "" }));
        if (typeof response.data.mngId === "string") {
          data.mngId = response.data.mngId;
          setMngId(response.data.mngId); // mngId 값 업데이트
          setShowSaveBtn(false);
          setIsDisabled(true);
        }
      } else {
        toast.error(`${t("오류가 발생하였습니다.")}`);
      }
    }
  };

  function clickCancelBtn() {
    setShowSaveBtn(false);
    setIsDisabled(true);
  }

  return (
    <>
      <div className="container">
        <section className="section active">
          <div className="col-wrap">
            <div className="col-md-12 min-h100-type1">
              <div className="cont">
                <div className="tit-btns-wrap">
                  <h3 className="titT1" style={{ minHeight: "50px" }}>경계설정</h3>
                  <div className="btns ar">
                    {showSaveBtn ? <></> : <>
                      <ArcButton type={"setting"} onClick={handleSettingClick} hasIcon={true} icon={ButtonIcon.setting} />
                    </>
                    }

                  </div>
                </div>
                <div className="flexCon">
                  <div>
                    <h4>조직경계</h4>
                    <ArcInput
                      value={companyNm}
                      inputType={"text"}
                      disabled={true}
                      readonly={true}
                      placeholder={"사업장 자동표출(회원가입시 작성된 내용)"}
                      prefix={""}
                      id={""}
                      name={""}
                      type="w100"
                      onChange={handleChange}
                      displayTitle={false}
                    />
                  </div>
                  <div>
                    <h4>운영경계</h4>
                    <div>
                      <ul className="check-disable">
                        <li>
                          <span className="checkbox-disable-type02">
                            <label htmlFor="checkbox1-2">
                              <ArcCheckbox
                                onChecked={clickScop1Btn}
                                text={"Scop1(직접배출)"}
                                disabled={isDisabled}
                                value={check1}
                                checked={check1 === "Y"}
                              />
                            </label>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox-disable-type02">
                            <label htmlFor="checkbox1-2">
                              <ArcCheckbox
                                onChecked={clickScop2Btn}
                                text={"Scop2(간접배출)"}
                                disabled={isDisabled}
                                value={check2}
                                checked={check2 === "Y"}
                              />
                            </label>
                          </span>
                        </li>
                        <li>
                          <span className="checkbox-disable-type02">
                            <label htmlFor="checkbox1-2">
                              <ArcCheckbox
                                onChecked={clickScop3Btn}
                                text={"Scop3(기타 간접배출)"}
                                disabled={isDisabled}
                                value={check3}
                                checked={check3 === "Y"}
                              />
                            </label>
                          </span>
                        </li>
                      </ul>
                      <ul className="list-st1">
                        <li>
                          SCOPE1:기업이 소&middot;유관리하는 사업장에서 직접 배출되는 온실가스 (ex : 고정연소
                          배출원(보일러, 비상발전기 등), 이동연소 배출원(차량 및 운송수단)
                        </li>
                        <li>SCOPE2:기업이 전기, 스팀 등 에너지를 사용함으로써 간접 배출되는 온실가스</li>
                        <li>
                          SCOPE3:가치 사슬 전체에서 기업의 활동과 관련된 모든 간접적인 배출량(ex:출·퇴근, 출장 등)
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <h4>대상 온실가스</h4>
                    <ArcInput
                      value={""}
                      inputType={"text"}
                      disabled={true}
                      readonly={true}
                      placeholder={"이산화탄소(CO2), 메탄(CH4), 아산화질소(N2O)"}
                      prefix={""}
                      id={"input-3"}
                      name={"input-3"}
                      onChange={handleChange}
                      displayTitle={false}
                      type={"w100p"}
                    />
                  </div>
                </div>
                <div className="btns ar">
                  {showSaveBtn === false ? (
                    ""
                  ) : (
                    <>
                      <ArcButton type={"cancel"} onClick={clickCancelBtn} />
                      <ArcButton
                        type={"regist"}
                        onClick={clickSaveBtn}
                        hasIcon={true}
                        icon={ButtonIcon.save}
                        text={"저장"}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
