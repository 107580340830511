export class Re100Model {
    trdYmd?: string;
    trdCnt?: number;
    trdVol?: number;
    avgPrc?: number;

    constructor(data?: Partial<Re100Model>) {
        Object.assign(this, data);
    }

    toMap(): Record<string, any> {
        return {
            trdYmd: this.trdYmd,
            trdCnt: this.trdCnt,
            trdVol: this.trdVol,
            avgPrc: this.avgPrc,
        };
    }

    static fromMap(data: Record<string, any>): Re100Model {
        return new Re100Model(data);
    }
}


