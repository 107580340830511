import moment from "moment";
import {CommonUtil} from "utils/commonUtil";
import {FileInfoModel} from "model/common/fileInfoModel";
import ContractModel from "model/cb/contractModel";
import {FullInfo} from "model/rp/ContractInfoModel";
import ContractPPAModel from "model/cb/contractPPAModel";
import {isStringNullOrEmpty} from "utils/stringUtil";

type signType = 'S' | 'P';

export class ContractUtil {

  public static makeReportFormData(data: ContractModel) {
    return {
      cntr: {
        cntrNo: data.cntrNo ?? "",
        dgr: data.dgr ?? "",
        cntrRsrcNm: data.cntrRsrcNm,
        salerId: data.salerId,
        salerName: data.salerNm,
        pchsrId: data.pchsrId,
        pchsrName: data.pchsrNm,
        cntrUnitc: data.cntrUnitc,
        cntrNqnt: data.cntrNqnt,
        cntrAmt: data.cntrAmt,
        cntrDate: data.contractOt?.cntrDate,
        depositDate: data.contractOt?.depositDate,
        depositRate: data.contractOt?.depositRate,
        deposit: data.contractOt?.deposit,
        balance: data.contractOt?.balance,
        loginUserId: data.loginUserId,
        isHistory: false
      },
      saler: {
        imgUrl: ""
      },
      pchsr: {
        imgUrl: ""
      }
    }
  }

  public static makeReportDetailData(data: ContractModel) {
    const signFiles = data.contractOt?.signFile?.files ?? [];
    const salerSignFile = signFiles.find(f => f.realNm?.includes('saler'));
    const pchsrSignFile = signFiles.find(f => f.realNm?.includes('pchsr'));
    return {
      cntr: {
        cntrNo: data.cntrNo,
        dgr: data.dgr,
        cntrRsrcNm: data.cntrRsrcNm,
        salerId: data.salerId,
        salerName: data.salerNm,
        pchsrId: data.pchsrId,
        pchsrName: data.pchsrNm,
        cntrUnitc: data.cntrUnitc,
        cntrNqnt: data.cntrNqnt,
        cntrAmt: data.cntrAmt,
        cntrDate: data.contractOt?.cntrDate,
        depositDate: data.contractOt?.depositDate,
        depositRate: data.contractOt?.depositRate,
        deposit: data.contractOt?.deposit,
        balance: data.contractOt?.balance,
        loginUserId: data.loginUserId,
        isHistory: data.isHistory ?? false,
      },
      saler: {
        imgUrl: this.makeSignUrl(salerSignFile)
      },
      pchsr: {
        imgUrl: this.makeSignUrl(pchsrSignFile)
      }
    }
  }

  public static getReportSign(signs: { salerSign?: string, pchsrSign?: string }) {
    let files: File[] = [];
    if (signs.salerSign) {
      const salerSignFile = this.makeSignFile(signs.salerSign, "S");
      if (salerSignFile) {
        files.push(salerSignFile)
      }
    }

    if (signs.pchsrSign) {
      const pchsrSignFile = this.makeSignFile(signs.pchsrSign, "P");
      if (pchsrSignFile) {
        files.push(pchsrSignFile);
      }
    }
    return files;
  }

  public static getPpaReportSign(signs: { brokerSignpad?: string, pchsrSignpad?: string }) {
    let files: File[] = [];
    if (signs.brokerSignpad) {
      const salerSignFile = this.makePpaSignFile(signs.brokerSignpad, "S");
      if (salerSignFile) {
        files.push(salerSignFile)
      }
    }

    if (signs.pchsrSignpad) {
      const pchsrSignFile = this.makePpaSignFile(signs.pchsrSignpad, "P");
      if (pchsrSignFile) {
        files.push(pchsrSignFile);
      }
    }
    return files;
  }

  public static makeSignFile(signData: string, type: signType) {
    let now = moment().format("yyyyMMDDHHmmss");
    now += '_cntr_ot';
    now += type === 'S' ? '_saler' : '_pchsr';
    now += '.png';
    return CommonUtil.base64ToFile(signData, now);
  }

  public static makePpaSignFile(signData: string, type: signType) {
    let now = moment().format("yyyyMMDDHHmmss");
    now += '_cntr_ppa';
    now += type === 'S' ? '_saler' : '_pchsr';
    now += '.png';
    return CommonUtil.base64ToFile(signData, now);
  }

  public static makeCntrPPASaveData(data: FullInfo, form?: ContractModel) {
    const cntrPpa: ContractPPAModel = {
      cntrNo: form?.cntrNo,
      dgr: form?.dgr,
      cntrCtt: JSON.stringify(data),
      trdCntrdWrtYn: "N", // 중개업자: 임시저장 : "N" / 저장: "Y"
      trdCntrdInsttRegYn: "N", // 중개업자: 기관등록 여부
      corpCntrdWrtYn: "N", // 기업: 임시저장: "N" / 저장: "Y"
      corpCntrdInsttRegYn: "N", // 기업: 기관등록 여부
    };

    const cntr: ContractModel = {
      cntrNo: form?.cntrNo,
      dgr: form?.dgr,
      refId: '', // 요청 ID
      cntrPrgsStsCd: 1, // 계약 상태값
      cntrCd: 1, // 1:직접계약 2:제3자계약
      cntrRsrcNm: data.generatorInfo.source,
      salerId: data.contractInfo.broker.userId,
      salerNm: data.contractInfo.broker.name,
      pchsrId: data.contractInfo.purchaser.userId,
      pchsrNm: data.contractInfo.purchaser.name,
      cntrAmt: 0, //
      cntrUnitc: isStringNullOrEmpty(data.contractInfo.unit) ? 0 : Number(data.contractInfo.unit),  // 계약단가
      cntrNqnt: isStringNullOrEmpty(data.epUsageInfo.cntrEp) ? 0 : Number(data.epUsageInfo.cntrEp),  // 계약전력
      otranYn: 'N', // 기관등록 여부
      contractPPA: cntrPpa,
      cntrFile: form?.cntrFile,
    };

    return cntr;
  }

  public static makeSignUrl(signFile: FileInfoModel | undefined) {
    if (signFile) {
      let root = process.env.REACT_APP_URL_GA!;
      let url = root + process.env.REACT_APP_URL_CB + '/files/image';
      url += `?filePath=${signFile.pthNm}&fileName=${signFile.fileNm}&originalFileName=${signFile.realNm}`;
      return encodeURI(url);
    }
    return "";
  }
}