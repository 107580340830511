import ArcSelectbox, { SelectDataModel } from "components/arc/ArcSelectbox";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React, { useEffect, useState } from "react";
import { HighchartsOptionsUtil } from "utils/highchartsOptionsUtil";
import { durpc, monthlyScope } from "pages/db/DbUserRe100PartCompService/DurpcFunction";
import moment from "moment";
import { ChartUtil } from "utils/chartUtil";

Highcharts.setOptions({
  ...HighchartsOptionsUtil.locale.kr.globalOptions,
  exporting: {enabled: false},
  credits: {enabled: false},
  tooltip: {shared: true, headerFormat: ''},
  accessibility: {enabled: false},
});

const defaultOption = {
  chart: {
    type: "column",
    height: 350,
    zoomType: "xy",
  },
  title: { text: "" },
  yAxis: { min: 0, title: { text: "(tCO2eq)" } },
  xAxis: {
    categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
    min: 0,
    title: { text: "" },
  },
  plotOptions: {
    series: {
      grouping: false,
      pointPadding: 0,
      borderRadius: 0,
      borderWidth: 0,
      pointWidth: 10,
    },
  },
  legend: {
    align: "right",
    verticalAlign: "top",
    symbolHeight: 10,
    borderWidth: 0,
    symbolRadius: 0,
    itemStyle: {
      fontSize: "13px",
      color: "#A2A2A2",
    },
  },
};

interface ChartGhgsByMonthDataProps {
  now: monthlyScope[];
  last: monthlyScope[];
}
interface ChartGhgsByMonthProps {
  data: any;
}
export default function ChartGhgsByMonth(props: ChartGhgsByMonthProps) {
  const [selectedFclt, setSelectedFclt] = useState<string|undefined>(undefined);
  const [selectOption, setSelectOption] = useState<SelectDataModel[]>([]);
  const [updaterDateString, setUpdaterDateSting] = useState<string>();
  const [option, setOption] = useState<any>(defaultOption);
  const [fullData, setFullData] = useState<ChartGhgsByMonthDataProps>({ now: [], last: [] });

  const thisYearDefaultSeries: any = {
    name: moment().format('YYYY'),
    data: ChartUtil.monthToNumberArray.map(_ => 0),
    color: {
      linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
      stops: [[0, "#7EC7AF"],[1, "#68A4D4"]]
    }
  };

  const lastYearDefaultSeries: any = {
    name: moment().subtract(1, 'year').format('YYYY'),
    data: ChartUtil.monthToNumberArray.map(_ => 0),
    pointPlacement: 0.14,
    color: "#B8B8BB"
  };

  useEffect(() => {
    let defaultFullData = {now: [], last: []} as ChartGhgsByMonthDataProps;
    if(props.data && props.data.now) {
      const thisYearData = sumMonthlyScopeData(props.data.now);
      if(thisYearData && thisYearData.length > 0) {
        makeSelectOption(thisYearData);
        const maxDateObj = durpc.getObjectMaxDate(thisYearData);
        if(maxDateObj) {
          setUpdaterDateSting(maxDateObj.chgDate.toString().substring(0, 10));
        } else {
          setUpdaterDateSting("");
        }
        defaultFullData.now = thisYearData;
      }
    }
    if(props.data && props.data.last) {
      const lastYearData = sumMonthlyScopeData(props.data.last);
      if(lastYearData && lastYearData.length > 0) {
        defaultFullData.last = lastYearData;
      }
    }

    setFullData(defaultFullData)
  }, [props.data]);

  useEffect(() => {
    if(!selectedFclt && selectOption && selectOption.length > 0) {
      setSelectedFclt(selectOption[0].value);
    }
  }, [selectOption]);

  useEffect(() => {
    let thisYearSeries = thisYearDefaultSeries;
    let lastYearSeries = lastYearDefaultSeries;
    if(fullData) {
      if(fullData.now && fullData.now.length > 0) {
        const fcltMonthData = fullData.now.find(f => f.scopeNm === selectedFclt);
        thisYearSeries.data = makeSeriesData(fcltMonthData);
      }
      if(fullData.last && fullData.last.length > 0) {
        const fcltMonthData = fullData.last.find(f => f.scopeNm === selectedFclt);
        lastYearSeries.data = makeSeriesData(fcltMonthData);
      }
    }

    setOption({
      ...defaultOption,
      series: [lastYearSeries, thisYearSeries]
    });
  }, [selectedFclt]);

  function makeSeriesData(obj: monthlyScope|undefined) {
    const monthArray = ChartUtil.monthToNumberArray;
    if(obj) {
      return monthArray.map(month => {
        const value = obj[`mm${month}`];
        return value ? Number(Number(value).toFixed(2)) : 0
      });
    }
    return monthArray.map(_ => 0);
  }

  function sumMonthlyScopeData(obj: any) {
    if(obj) {
      return Object.entries(obj).map(([ key, value ]: any) => {
        return {
          scopeNm: key,
          mm1: (value.scope1.mm1??0) + (value.scope2.mm1??0) + (value.scope3.mm1??0),
          mm2: (value.scope1.mm2??0) + (value.scope2.mm2??0) + (value.scope3.mm2??0),
          mm3: (value.scope1.mm3??0) + (value.scope2.mm3??0) + (value.scope3.mm3??0),
          mm4: (value.scope1.mm4??0) + (value.scope2.mm4??0) + (value.scope3.mm4??0),
          mm5: (value.scope1.mm5??0) + (value.scope2.mm5??0) + (value.scope3.mm5??0),
          mm6: (value.scope1.mm6??0) + (value.scope2.mm6??0) + (value.scope3.mm6??0),
          mm7: (value.scope1.mm7??0) + (value.scope2.mm7??0) + (value.scope3.mm7??0),
          mm8: (value.scope1.mm8??0) + (value.scope2.mm8??0) + (value.scope3.mm8??0),
          mm9: (value.scope1.mm9??0) + (value.scope2.mm9??0) + (value.scope3.mm9??0),
          mm10: (value.scope1.mm10??0) + (value.scope2.mm10??0) + (value.scope3.mm10??0),
          mm11: (value.scope1.mm11??0) + (value.scope2.mm11??0) + (value.scope3.mm11??0),
          mm12: (value.scope1.mm12??0) + (value.scope2.mm12??0) + (value.scope3.mm12??0),
          total: (value.scope1.total??0) + (value.scope2.total??0) + (value.scope3.total??0),
          chgDate: value.scope1.chgDate || value.scope2.chgDate || value.scope3.chgDate,
        };
      });
    }
    return null;
  }

  function makeSelectOption(data: any[]) {
    const selectOptions: SelectDataModel[] = data.map(key => {
      return {label: key.scopeNm, value: key.scopeNm};
    });
    setSelectOption(selectOptions);
  }

  function handleSelectbox(e: any) {
    setSelectedFclt(e.target.value);
  }

  return (
    <div className="cont">
      <div className="titFlex">
        <h3 className="titT1">
          월별 온실가스 배출량<em className="update">{updaterDateString} 기준</em>
        </h3>
        <ArcSelectbox
          name="fcltNM"
          selectedValue={selectedFclt}
          onChange={handleSelectbox}
          options={selectOption}
        />
      </div>
      <div id="graph-typeB" className="chart">
        {option && (<HighchartsReact highcharts={Highcharts} options={option}/>)}
      </div>
    </div>
  );
}