import React from "react";
import BusinessList from "./BusinessList";
import MonitoringList from "./MonitoringList";
import { useNavigate, useParams } from "react-router-dom";
import { JsxElementConstant } from "constants/jsxElementConstant";

export const docuTab = {
  business: "business",
  monitoring: "monitoring"
} as const;

export default function Board() {

  const navigate = useNavigate();
  const { type = docuTab.business } = useParams();

  const handleClick = (tabNumber: string) => {
    if (type !== tabNumber) {
      navigate(`/cp/reduce/history/${tabNumber}`);
    }
  };

  const tabComponent = () => {
    switch (type) {
      case "business":
        return <BusinessList />;
      case "monitoring":
        return <MonitoringList />;
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <section className="section active">
        <div className="tab-st-box1">
          <ul className="tab-st1">
            <li className={type === docuTab.business ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(docuTab.business);
                }}
              >
                <span>사업계획서</span>
              </a>
            </li>
            <li className={type === docuTab.monitoring ? "on" : ""}>
              <a
                href={JsxElementConstant.hrefIsNull}
                onClick={() => {
                  handleClick(docuTab.monitoring);
                }}
              >
                <span>모니터링</span>
              </a>
            </li>
          </ul>
        </div>
        {tabComponent()}
      </section>
    </div>
  );
}
