import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "stores/hook";
import {AuthConstant} from "constants/authConstant";
import AccountAlarm from "components/layout/comp/AccountAlarm";
import Sitemap from './Sitemap';
import {UserInfoModel} from "model/ua/UserInfoModel";
import {UserInfoUtil} from "utils/userInfoUtil";
import {clearUserInfo} from "reducers/userInfoSlice";
import {Link, useNavigate} from "react-router-dom";
import ArcButton, {ButtonType} from "components/arc/ArcButton";


export default function Account() {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const [isUserLogin, setIsUserLogin] = useState<any>(false);

  useEffect(() => {
    if (userInfo?.userId) {
      setIsUserLogin(true);
    } else {
      setIsUserLogin(false);
    }
  }, [userInfo?.userId]);

  const handleLogout = async () => {
    localStorage.clear();
    dispatch(clearUserInfo());
    formRef?.current.submit();
  };

  const handleClickGoMyPage = (e: any) => {
    e.preventDefault();
    navigate('/mypage');
  };

  return (
    <ul>
      {isUserLogin && (UserInfoUtil.hasRole(userInfo, AuthConstant.R001) || UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)) && (
        <li className="shortcut-btn">
          <ArcButton type={ButtonType.custom}
                     text="웹포탈 바로가기"
                     onClick={() => window.location.href = '/portal'}
                     title="이마켓 웹포탈 사이트로 이동"
          />
        </li>
      )}
      <li className="login">
        {
          isUserLogin
          ? (<Link to="" className="out" onClick={handleClickGoMyPage} title="내 정보 화면으로 이동"><span>안녕하세요. <strong>{userInfo?.userNm}</strong>님</span></Link>)
          : (<Link to="" onClick={() => window.location.href = '/portal'} title="로그인 화면으로 이동"><span>로그인 해주세요</span></Link>)
        }
      </li>
      {
        isUserLogin && (<AccountAlarm/>)
      }
      {
        isUserLogin && (
          <li className="logout">
            <form ref={formRef} method={'post'} action={process.env.REACT_APP_URL_SIGN_OUT}></form>
            <Link to="" onClick={handleLogout} title="로그아웃"><span className="hid">로그아웃</span></Link>
          </li>
        )
      }
      {
        isUserLogin && (UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN))
        ? <Sitemap />
        : ''
      }
    </ul>
  )
}