import Api from "services/common/Api";
import {AtomFileModel, FileInfoModel} from "model/common/fileInfoModel";

export class FileService extends Api {
  private static service: FileService;


  public static getInstance(): FileService {
    if (!FileService.service) {
      FileService.service = new FileService();
    }
    return FileService.service;
  }

  public async upload(path: string, formData: FormData) {
    return await super.post<AtomFileModel[]>(path + '/files/partitial', formData);
  }

  public async downloadFile(path: string, file: FileInfoModel) {
    const param = {
      filePath: file.pthNm,
      fileName: file.fileNm,
      originalFileName: file.realNm,
    };
    await super.download(path + '/files/file', param)
  }

  public async deleteAtomFile(path: string, files: AtomFileModel[]) {
    return super.deleteOnBody(path + '/files', files);
  }

  public async fetchBlobFromServer(path: string, file: FileInfoModel) {
    const param = {
      filePath: file.pthNm,
      fileName: file.fileNm,
      originalFileName: file.realNm,
    };
    return await super.fetchBlob(path + '/files/file', param);
  }
}