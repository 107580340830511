import { AuditModel } from "model/common/auditModel";
import { FileModel } from "model/common/fileModel";

export class DocuBizPlnd extends AuditModel {
    docId?: string;
    regDate?: Date;
    regrId?: string;
    chgDate?: Date;
    chgrId?: string;

    mhdlgId?: string; // 방법론 id
    mhdlgNm?: string; // 방법론명
    rlvnDataNm?: string; // 사업계획서명 (제목)
    explCtt?: string | null; // 설명
    repAtflId?: string | null;

    file?: FileModel;
}