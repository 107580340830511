import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import CountUp from "react-countup";

HighchartsMore(Highcharts);

export interface indusSupSta {
  total: number | 0;
  SaMoPrY: number | 0;
  comSaMoLaY: number | 0;
}

export interface resMan {

  toRegPow: number | 0,
  facCap: number | 0;
  todEleGen: number | 0;
  PreDaPowGen: number | 0;
  comPreDaPowGen: number | 0;
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

export default function DbAdminResources() {

  const [graphTypeA, setGraphTypeA] = useState<any>({});

  const today = new Date();

  const [regPowPlant, setRegPowPlant] = useState<indusSupSta>();
  const [facCap, setFacCap] = useState<indusSupSta>();
  const [todEleGen, setTodEleGen] = useState<indusSupSta>();

  // 산단 내 공급 현황
  const [indComSoPow, setIndComSopow] = useState<resMan>();
  const [indComWiPow, setIndComWipow] = useState<resMan>();
  const [indComHyPow, setIndComHypow] = useState<resMan>();
  const [indComBio, setIndComBio] = useState<resMan>();

  // 배후단지 공급 현황
  const [hinComSoPow, setHinComSoPow] = useState<resMan>();
  const [hinComWiPow, setHinComWiPow] = useState<resMan>();
  const [hinComHyPow, setHinComHyPow] = useState<resMan>();
  const [hinComBio, setHinComBio] = useState<resMan>();

  const [rdmpCount, setRdmpCount] = useState(0);
  const [currentData, setCurrentDate] = useState('');

  useEffect(() => {
    setCurrentDate(formatDate(new Date()));
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/sub.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("admin");

    return () => {
      document.body.classList.remove("admin");
    };
  }, []);

  // 산단 공급 자원 현황최근 업데이트 
  useEffect(() => {

    setRegPowPlant({
      "total": 16,
      "SaMoPrY": 15,
      "comSaMoLaY": 6.66,
    })

    setFacCap({
      "total": 255,
      "SaMoPrY": 204,
      "comSaMoLaY": 25,
    })

    setTodEleGen({
      "total": 666,
      "SaMoPrY": 600,
      "comSaMoLaY": 11,
    })

  }, [])

  // 자원관리
  useEffect(() => {

    // 산단 내 공급 현황 
    setIndComSopow({
      "toRegPow": 6,
      "facCap": 20,
      "todEleGen": 70,
      "PreDaPowGen": 68,
      "comPreDaPowGen": 2,
    })

    setIndComWipow({
      "toRegPow": 1,
      "facCap": 10,
      "todEleGen": 72,
      "PreDaPowGen": 74,
      "comPreDaPowGen": 2,
    })

    setIndComHypow({
      "toRegPow": 0,
      "facCap": 0,
      "todEleGen": 0,
      "PreDaPowGen": 0,
      "comPreDaPowGen": 0,
    })

    setIndComBio({
      "toRegPow": 0,
      "facCap": 0,
      "todEleGen": 0,
      "PreDaPowGen": 0,
      "comPreDaPowGen": 0,
    })

    // 배후단지 공급 현황

    setHinComSoPow({
      "toRegPow": 5,
      "facCap": 100,
      "todEleGen": 350,
      "PreDaPowGen": 333,
      "comPreDaPowGen": 12,
    })

    setHinComWiPow({
      "toRegPow": 2,
      "facCap": 20,
      "todEleGen": 144,
      "PreDaPowGen": 152,
      "comPreDaPowGen": 8,
    })

    setHinComHyPow({
      "toRegPow": 1,
      "facCap": 100,
      "todEleGen": 20,
      "PreDaPowGen": 22,
      "comPreDaPowGen": 2,
    })

    setHinComBio({
      "toRegPow": 1,
      "facCap": 5,
      "todEleGen": 10,
      "PreDaPowGen": 8,
      "comPreDaPowGen": 2,
    })

  }, [])

  useEffect(() => {
    const graphTypeA = {
      chart: {
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        height: 140,
        margin: [0, 150, 0, 0],
        spacingTop: 0,
        spacingBottom: 10,
        spacingLeft: 0,
        spacingRight: 0,
      },
      title: {
        text: "",
      },
      subtitle: {
        useHTML: true,
        text: getSubtitle(),
        verticalAlign: "middle",
        floating: true,
        y: 15,
        x: -75,
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        x: 0,
        y: 0,
        symbolHeight: 0,
        borderWidth: 0,
        itemMarginTop: 10,
        useHTML: true,
        labelFormatter: function (this: any) {
          return (
            '<div class="legend-srl" style="width:150px;"><span>' +
            this.name +
            '<strong><em class="pie-cont" style="color:' +
            this.color +
            '">' +
            this.val +
            "</em>MW</strong></span></div>"
          );
        },
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        valueSuffix: "%",
      },
      plotOptions: {
        series: {
          showInLegend: true,
          borderWidth: 0,
          borderRadius: 0,
          colorByPoint: true,
          size: "100%",
          innerSize: "50%",
          dataLabels: {
            enabled: true,
            distance: "-2%",
            allowOverlap: false,
            useHTML: true,
            format:
              '<div class="pie-cont-bg"><strong class="pie-cont" style="font-size:.5rem;">{point.y} %</strong></div>',
          },
        },
      },
      colors: ["#545cc2", "#4b96f9", "#44c5dc", "#9effdf"],
      series: [
        {
          name: "에너지원별 설비용량",
          type: "pie",
          data: [
            {
              name: "태양광",
              y: 47,
              val: 120,
            },
            {
              name: "풍력",
              y: 11,
              val: 30,
            },
            {
              name: "소수력",
              y: 40,
              val: 100,
            },
            {
              name: "바이오",
              y: 2,
              val: 5,
            },
          ],
        },
      ],
    };
    setGraphTypeA(graphTypeA);
  }, []);

  const getSubtitle = () => {
    return `<span class="donut-stit"><em class="" style="display:block;margin:0 .15rem 0 .05rem;font-size:.85rem;">255</em>MW</span>`;
  };

  // 최근 업데이트 날짜
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}.${month}.${day}` + ' 12:00:00';
  }

  const calculateClass = (todEleGen: any, PreDaPowGen: any) => {
    const difference = (todEleGen ?? 0) - (PreDaPowGen ?? 0);
    if (difference > 0) {
      return 'up up-down';
    } else if (difference < 0) {
      return 'down up-down';
    } else {
      return '';
    }
  };

  const calculateSum = (a: any, b: any, c: any, d: any) => {
    return a + b + c + d;
  }

  return (
    <>
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">
                산단 공급 자원 현황
                <em className="update">최근 업데이트 {currentData}</em>
              </h3>
              <div className="icon-box-wrap10">
                <ul>
                  <li>
                    <strong>총 등록 발전소</strong>
                    <div className="cnt1">
                      <div className="num">
                        <span>
                          <CountUp end={Number(regPowPlant?.total) ?? 0} duration={0.5} >
                            {({ countUpRef }) => <strong ref={countUpRef} />}
                          </CountUp>
                          개소
                        </span>
                      </div>
                      <ul>
                        <li>
                          <span>전년 동월 개소</span>
                          <strong> <CountUp end={Number(regPowPlant?.SaMoPrY) ?? 0} duration={0.5} >
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>개소</strong>

                        </li>
                        <li>
                          <span>전년 동월 대비</span>
                          <strong className={calculateClass(regPowPlant?.total, regPowPlant?.SaMoPrY)}>
                            <CountUp end={Number(regPowPlant?.comSaMoLaY) ?? 0} duration={0.5} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            %
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <strong>설비 용량</strong>
                    <div className="cnt2">
                      <div className="num">
                        <span>
                          <CountUp end={Number(facCap?.total) ?? 0} duration={0.5} >
                            {({ countUpRef }) => <strong ref={countUpRef} />}
                          </CountUp>
                          MW
                        </span>
                      </div>
                      <ul>
                        <li>
                          <span>전년 동월 설비용량</span>
                          <strong>
                            <CountUp end={Number(facCap?.SaMoPrY) ?? 0} duration={0.5} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li>
                          <span>전년 동월 대비</span>
                          <strong className={calculateClass(facCap?.total, facCap?.SaMoPrY)}>
                            <CountUp end={Number(facCap?.comSaMoLaY) ?? 0} duration={0.5} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            %
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <strong>금일 발전량</strong>
                    <div className="cnt3">
                      <div className="num">
                        <span>
                          <CountUp end={Number(todEleGen?.total) ?? 0} duration={0.5} >
                            {({ countUpRef }) => <strong ref={countUpRef} />}
                          </CountUp>
                          MWh
                        </span>
                      </div>
                      <ul>
                        <li>
                          <span>전년 동월 발전량</span>
                          <strong>
                            <CountUp end={Number(todEleGen?.SaMoPrY) ?? 0} duration={0.5} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MWh
                          </strong>
                        </li>
                        <li>
                          <span>전년 동월 대비</span>
                          <strong className={calculateClass(todEleGen?.total, todEleGen?.SaMoPrY)}>
                            <CountUp end={Number(todEleGen?.comSaMoLaY) ?? 0} duration={0.5} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            %
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <strong className="mgb0">에너지원별 설비용량</strong>
                    <div id="graph-typeA" className="chart">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={graphTypeA}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">자원관리<em className="update">최근 업데이트 23.12.20 12:00:00</em></h3>
              <div className="col-wrap">
                <div className="col-md-6">
                  <div className="icon-box-wrap8 icon-box-wrap8-2 mgt10">
                    <div className="titFlex">
                      <span>
                        산단 내 공급 현황 <em>{calculateSum(indComSoPow?.toRegPow, indComWiPow?.toRegPow, indComHyPow?.toRegPow, indComBio?.toRegPow)}개소</em>
                      </span>
                      <ul>
                        <li>
                          <span>설비 용량</span>
                          <em>
                            {/* <strong data-count="30" className="change">
                              30
                            </strong> */}
                            <strong>
                              <CountUp end={Number(calculateSum(indComSoPow?.facCap, indComWiPow?.facCap, indComHyPow?.facCap, indComBio?.facCap)) ?? 0} duration={0.5} >
                                {({ countUpRef }) => <em ref={countUpRef} />}
                              </CountUp>
                            </strong>
                            MW
                          </em>
                        </li>
                        <li>
                          <span>금일 발전량</span>
                          <em>
                            {/* <strong data-count="142" className="change">
                              142
                            </strong> */}

                            <strong>
                              <CountUp end={Number(calculateSum(indComSoPow?.todEleGen, indComWiPow?.todEleGen, indComHyPow?.todEleGen, indComBio?.todEleGen)) ?? 0} duration={0.5} >
                                {({ countUpRef }) => <em ref={countUpRef} />}
                              </CountUp>
                            </strong>
                            MWh
                          </em>
                        </li>
                      </ul>
                    </div>
                    <ul>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon19.png")}
                              alt=""
                            />
                            <span className="txt">
                              태양광<em>{indComSoPow?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>
                                  <CountUp end={Number(indComSoPow?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>
                                  <CountUp end={Number(indComSoPow?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(indComSoPow?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>

                                <strong className={calculateClass(indComSoPow?.todEleGen, indComSoPow?.PreDaPowGen)}>
                                  <CountUp end={Number(indComSoPow?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon20.png")}
                              alt=""
                            />
                            <span className="txt">
                              풍력<em>{indComWiPow?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>
                                  <CountUp end={Number(indComWiPow?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>
                                  <CountUp end={Number(indComWiPow?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(indComWiPow?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>
                                <strong className={calculateClass(indComWiPow?.todEleGen, indComWiPow?.PreDaPowGen)}>
                                  <CountUp end={Number(indComWiPow?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon21.png")}
                              alt=""
                            />
                            <span className="txt">
                              소수력<em>{indComHyPow?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>

                                  <CountUp end={Number(indComHyPow?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>

                                  <CountUp end={Number(indComHyPow?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(indComHyPow?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>
                                <strong className={calculateClass(indComHyPow?.todEleGen, indComHyPow?.PreDaPowGen)}>
                                  <CountUp end={Number(indComHyPow?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon22.png")}
                              alt=""
                            />
                            <span className="txt">
                              바이오<em>{indComBio?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>

                                  <em>
                                    <CountUp end={Number(indComBio?.facCap) ?? 0} duration={0.5} >
                                      {({ countUpRef }) => <em ref={countUpRef} />}
                                    </CountUp>
                                  </em>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>

                                  <CountUp end={Number(indComBio?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>

                                  <CountUp end={Number(indComBio?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>

                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>
                                <strong className={calculateClass(indComBio?.todEleGen, indComBio?.PreDaPowGen)}>
                                  <CountUp end={Number(indComBio?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="icon-box-wrap8 icon-box-wrap8-2 mgt10">
                    <div className="titFlex">
                      <span>
                        배후단지 공급 현황 <em>{calculateSum(hinComSoPow?.toRegPow, hinComWiPow?.toRegPow, hinComHyPow?.toRegPow, hinComBio?.toRegPow)}개소</em>
                      </span>
                      <ul>
                        <li>
                          <span>설비 용량</span>
                          <em>
                            <strong>
                              <CountUp end={Number(calculateSum(hinComSoPow?.facCap, hinComWiPow?.facCap, hinComHyPow?.facCap, hinComBio?.facCap)) ?? 0} duration={0.5} >
                                {({ countUpRef }) => <em ref={countUpRef} />}
                              </CountUp>
                            </strong>
                            MW
                          </em>
                        </li>
                        <li>
                          <span>금일 발전량</span>
                          <em>
                            <strong>
                              <CountUp end={Number(calculateSum(hinComSoPow?.todEleGen, hinComWiPow?.todEleGen, hinComHyPow?.todEleGen, hinComBio?.todEleGen)) ?? 0} duration={0.5} >
                                {({ countUpRef }) => <em ref={countUpRef} />}
                              </CountUp>
                            </strong>
                            MWh
                          </em>
                        </li>
                      </ul>
                    </div>
                    <ul>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon19.png")}
                              alt=""
                            />
                            <span className="txt">
                              태양광<em>{hinComSoPow?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>
                                  <CountUp end={Number(hinComSoPow?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>

                                  <CountUp end={Number(hinComSoPow?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComSoPow?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>
                                <strong className={calculateClass(hinComSoPow?.todEleGen, hinComSoPow?.PreDaPowGen)}>
                                  <CountUp end={Number(hinComSoPow?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon20.png")}
                              alt=""
                            />
                            <span className="txt">
                              풍력<em>{hinComWiPow?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>
                                  <CountUp end={Number(hinComWiPow?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>

                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComWiPow?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComWiPow?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>
                                <strong className={calculateClass(hinComWiPow?.todEleGen, hinComWiPow?.PreDaPowGen)}>
                                  <CountUp end={Number(hinComWiPow?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon21.png")}
                              alt=""
                            />
                            <span className="txt">
                              소수력<em>{hinComHyPow?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>
                                  <CountUp end={Number(hinComHyPow?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComHyPow?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComHyPow?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>

                                <strong className={calculateClass(hinComHyPow?.todEleGen, hinComHyPow?.PreDaPowGen)}>
                                  <CountUp end={Number(hinComHyPow?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cnt">
                          <div className="l-box titFlex">
                            <img
                              src={require("../../assets/images/sub/icon_box_wrap_icon22.png")}
                              alt=""
                            />
                            <span className="txt">
                              바이오<em>{hinComBio?.toRegPow} 개소</em>
                            </span>
                          </div>
                          <div className="r-box">
                            <ul>
                              <li>
                                <span>설비 용량</span>
                                <strong>
                                  <CountUp end={Number(hinComBio?.facCap) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MW
                                </strong>
                              </li>
                              <li>
                                <span>금일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComBio?.todEleGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 발전량</span>
                                <strong>
                                  <CountUp end={Number(hinComBio?.PreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                              <li>
                                <span>전일 대비 발전량</span>
                                <strong className={calculateClass(hinComBio?.todEleGen, hinComBio?.PreDaPowGen)}>
                                  <CountUp end={Number(hinComBio?.comPreDaPowGen) ?? 0} duration={0.5} >
                                    {({ countUpRef }) => <em ref={countUpRef} />}
                                  </CountUp>
                                  MWh
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
