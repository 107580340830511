import Api from "./common/Api";

import axios from "axios";
import {HeadersUtil} from "../utils/headersUtil";
import * as process from "process";

export class SideMenuService extends Api {
    private static _sideMenuService: SideMenuService;
    private static EXCH_URL: string = process.env.REACT_APP_URL_CO + '/exch/latest';
    private static EMISSION_URL: string = process.env.REACT_APP_URL_CO + '/emcrd/latest';
    private static REC_URL: string = process.env.REACT_APP_URL_CO + '/rec/re100/latest';
    private static RPS_URL: string = process.env.REACT_APP_URL_CO + '/rec/rps/latest';
    private static SMP_URL: string = process.env.REACT_APP_URL_CO + '/smp/latest';

    public static getInstance(): SideMenuService {
        if (!SideMenuService._sideMenuService) {
            SideMenuService._sideMenuService = new SideMenuService();
        }
        return SideMenuService._sideMenuService;
    }

    public async getExchangeRates() {
        return (await axios.get(SideMenuService.EXCH_URL, {
            headers: HeadersUtil.getHeaders()
        })).data;
        //return await super.get<any[]>(SideMenuService.EXCH_URL);
    }

    public async getEmissionRights() {
        return (await axios.get(SideMenuService.EMISSION_URL, {
            headers: HeadersUtil.getHeaders()
        })).data;
        //return await super.get<any[]>(SideMenuService.EMISSION_URL)
    }

    public async getRe100() {
        return (await axios.get(SideMenuService.REC_URL, {
            headers: HeadersUtil.getHeaders()
        })).data;
        //return await super.get<any[]>(SideMenuService.REC_URL)
    }

    public async getRps() {
        return (await axios.get(SideMenuService.RPS_URL, {
            headers: HeadersUtil.getHeaders()
        })).data;
        //return await super.get<any[]>(SideMenuService.RPS_URL)
    }

    public async getSmp() {
        return (await axios.get(SideMenuService.SMP_URL, {
            headers: HeadersUtil.getHeaders()
        })).data;
        // return await super.get<any[]>(SideMenuService.SMP_URL)
    }

}