import Api from "../common/Api";
import * as process from "process";

export class MatchingTargetService extends Api {

  private static service: MatchingTargetService;

  private static PPA_RQS_COUNT_URL: string = process.env['REACT_APP_URL_RP'] + '/matchingTarget/sumPpaRqs';
  private static PPA_RQS_COUNT_UP_URL: string = process.env['REACT_APP_URL_RP'] + '/matchingTarget/ppaRqsCountUp';
  private static PPA_RQS_MONTHLY_COUNT_URL: string = process.env['REACT_APP_URL_RP'] + '/matchingTarget/monthlyPpaRqs';
  private static MONTHLY_CNT_CPCT_URL: string = process.env['REACT_APP_URL_RP'] + '/matchingTarget/monthlyPpaRqsCountCpct';
  private static TRANSITION_URL: string = process.env['REACT_APP_URL_RP'] + '/matchingTarget/ppaRqsTransition';
  private static CALC_SIMUL_URL: string = process.env['REACT_APP_URL_RP'] + '/matchingTarget/calcSimul';

  public static getInstance(): MatchingTargetService {
    if (!MatchingTargetService.service) {
      MatchingTargetService.service = new MatchingTargetService();
    }
    return MatchingTargetService.service;
  }

  public async getPpaRqsCount() {
    return await super.get<any>(MatchingTargetService.PPA_RQS_COUNT_URL);
  }

  public async getPpaRqsCountUp() {
    return await super.get<any>(MatchingTargetService.PPA_RQS_COUNT_UP_URL);
  }

  public async getPpaRqsMonthlyCount() {
    return await super.get<any>(MatchingTargetService.PPA_RQS_MONTHLY_COUNT_URL);
  }

  public async getMonthlyPpaRqsCntCpct() {
    return await super.get<any>(MatchingTargetService.MONTHLY_CNT_CPCT_URL);
  }

  public async getPpaRqsTransition() {
    return await super.get<any>(MatchingTargetService.TRANSITION_URL);
  }

  public async getCalcSimul(pwplId: string) {
    return await super.get<any>(MatchingTargetService.CALC_SIMUL_URL, {pwplId: pwplId});
  }
}