import React, {CSSProperties, useEffect} from 'react'
import {DefaultTFuncReturn} from "i18next";
import ObjectUtil from "utils/objectUtil";

export interface ArcInputProps {
  value: string;// defined in Google Sheet
  type?: InputTextType;
  inputType?: "number" | "email" | "text" | "tel" | "password";
  title?: string | DefaultTFuncReturn;
  label?: string;
  header?: string;
  suffix?: string;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  isRequired?: boolean;
  decimalLength?: number;
  validateMessage?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  pattern?: string;
  prefix?: string; // optional
  minFraction?: number;
  maxFraction?: number;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  id: string;
  name?: string;
  onChange: (text: any, isValid: any) => void
  onKeyUp?: (e: any) => void
  displayTitle?: boolean;
  onBlur?: (text: any) => void
  onFocus?: (text: any) => void
}

export interface ArcInputChangeResult {
  value: any;
  name: string;
  isValid: any;
}

interface Type {
  w5p: TypeDetail;
  w10p: TypeDetail;
  w15p: TypeDetail;
  w20p: TypeDetail;
  w30p: TypeDetail;
  w40p: TypeDetail;
  w50p: TypeDetail;
  w60p: TypeDetail;
  w70p: TypeDetail;
  w80p: TypeDetail;
  w90p: TypeDetail;
  w100p: TypeDetail;
  w50: TypeDetail;
  w80: TypeDetail;
  w100: TypeDetail;
  w150: TypeDetail;
  w200: TypeDetail;
  w250: TypeDetail;
  w300: TypeDetail;
  w400: TypeDetail;
  w450: TypeDetail;
  w500: TypeDetail;
  custom: TypeDetail
}

interface TypeDetail {
  className: string | null,
}

type InputTextType = keyof Type;

type InputClass = {
  [key in InputTextType]: TypeDetail;
}

export const TextAreaWidth = {
  w5p: 'InpSel-w5',
  w10p: 'InpSel-w10',
  w15p: 'InpSel-w15',
  w20p: 'InpSel-w20',
  w30p: 'InpSel-w30',
  w40p: 'InpSel-w40',
  w50p: 'InpSel-w50',
  w60p: 'InpSel-w60',
  w70p: 'InpSel-w70',
  w80p: 'InpSel-w80',
  w90p: 'InpSel-w90',
  w100p: 'InpSel-w100',
  w50: 'InpSel-50',
  w80: 'InpSel-80',
  w100: 'InpSel-100',
  w150: 'InpSel-150',
  w200: 'InpSel-200',
  w250: 'InpSel-250',
  w300: 'InpSel-300',
  w400: 'InpSel-400',
  w450: 'InpSel-450',
  w500: 'InpSel-500',
} as const;

export const enumInputTextType: InputClass = {
  w5p: {
    className: TextAreaWidth.w5p,
  },
  w10p: {
    className: TextAreaWidth.w10p,
  },
  w15p: {
    className: TextAreaWidth.w15p,
  },
  w20p: {
    className: TextAreaWidth.w20p,
  },
  w30p: {
    className: TextAreaWidth.w30p,
  },
  w40p: {
    className: TextAreaWidth.w40p,
  },
  w50p: {
    className: TextAreaWidth.w50p,
  },
  w60p: {
    className: TextAreaWidth.w60p,
  },
  w70p: {
    className: TextAreaWidth.w70p,
  },
  w80p: {
    className: TextAreaWidth.w80p,
  },
  w90p: {
    className: TextAreaWidth.w90p,
  },
  w100p: {
    className: TextAreaWidth.w100p,
  },
  w50: {
    className: TextAreaWidth.w50,
  },
  w80: {
    className: TextAreaWidth.w80,
  },
  w100: {
    className: TextAreaWidth.w100,
  },
  w150: {
    className: TextAreaWidth.w150,
  },
  w200: {
    className: TextAreaWidth.w200,
  },
  w250: {
    className: TextAreaWidth.w250,
  },
  w300: {
    className: TextAreaWidth.w300,
  },
  w400: {
    className: TextAreaWidth.w400,
  },
  w450: {
    className: TextAreaWidth.w450,
  },
  w500: {
    className: TextAreaWidth.w500,
  },
  custom: {
    className: ""
  }
} as const;

export default function ArcInput(props: ArcInputProps) {
  const {
    type = "w100p",
    className = "",
    disabled = false,
    readonly = false,
    placeholder = "내용을 입력하세요",
    id = "",
    name = "",
    displayTitle = !!props.title,
  } = props;
  const [validateMess, setValidateMessage] = React.useState<any>(props.validateMessage);
  const [valueTxt, setValue] = React.useState<any>(undefined);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const textAreaAttr = type != "custom" ? enumInputTextType[type]['className']!.toString() : "";
  const textClassNames: string = makeTextAreaClassName();


  function makeTextAreaClassName(): string {
    return `${className} ${textAreaAttr}`;
  }

  const handleOnKeyup = (e: any) => {
    if (props.onKeyUp) {
      props.onKeyUp(e);
    }
  }

  useEffect(() => {
    if (!props.value) {
      setValue(undefined);
    } else {
      setValue(props.value);
    }
  }, [props.value]);

  const getOnChangeHandler = () => {
    switch (props.inputType) {
      case 'tel':
        return handleNumericChange;
      case 'text':
      default:
        return handleTextChange;
    }
  };

  const handleChange = (e: any) => {
    let value = e.target.value;
    let trimValue = e.target.value.replaceAll(' ', '');
    if (ObjectUtil.isEmpty(trimValue)) {
      value = trimValue;
    }
    setValue(value);

    if (!value) {
      setIsValid(false);
      setValidateMessage(props.validateMessage);
    } else {
      if (props.pattern != null && !new RegExp(props.pattern).test(value)) {
        setIsValid(false);
        setValidateMessage("Invalid pattern");
      } else {
        const isValidInput = value.trim() !== "";
        setIsValid(isValidInput);
        setValidateMessage(""); // Clear the error message if the value is valid
      }
    }
    props.onChange(value, isValid);
  };

  const handleNumericChange = (e: any) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setValue(value);

    const isValidInput = value.trim() !== "";
    setIsValid(isValidInput);
    setValidateMessage(isValidInput ? "" : props.validateMessage);

    props.onChange(value, isValidInput);
  };

  const handleBlur = (e: any) => {
    let newValue: number = parseFloat(e.target.value);
    if (!newValue) {
      setIsValid(false);
      setValidateMessage(props.validateMessage);
    } else {

      if (props.minValue !== undefined && newValue < props.minValue) {
        newValue = props.minValue;
      } else if (props.maxValue !== undefined && newValue > props.maxValue) {
        newValue = props.maxValue;
      }
      setIsValid(true);
    }

    setValue(newValue);
    props.onChange(newValue, isValid);
  };

  const handleTextChange = (e: any) => {
    handleChange(e);
  };

  if (props.inputType === "number") {
    return (
        // <>
        //   {displayTitle ? <span className="srh-tit fs16">{props.title}</span> : <></>}
        //   <InputNumber
        //     className={textClassNames}
        //     placeholder={placeholder?.toString()}
        //     style={props.style}
        //     inputClassName={`${isValid ? textClassNames : `${textAreaAttr} ${props.isRequired ? `InpSel-wrong` : ''}`}`}
        //     value={valueTxt}
        //     minFractionDigits={props.minFraction}
        //     maxFractionDigits={props.maxFraction}
        //     onChange={(e) => handleInputChange(e)}
        //     onValueChange={(e) => handleValueChange(e)}
        //     disabled={disabled}
        //     readOnly={readonly}
        //     pattern={props.pattern}
        //     id={id}
        //     allowEmpty={true}
        //     name={name}
        //     maxLength={props.maxLength}
        //     min={props.minValue}
        //     max={props.maxValue}/>
        //   {props.isRequired && !isValid && (<em style={{color: "#3027b1", fontSize: "0.7rem"}}>{validateMess}</em>)}
        // </>
        <>
          {displayTitle && props.label
              ? <label htmlFor={id} style={{marginRight: 0}}><span className="srh-tit">{props.label}</span></label>
              : <></>
          }
          <input
              type="number"
              value={valueTxt || ''}
              placeholder={placeholder?.toString()}
              style={props.style}
              min={props.minValue}
              max={props.maxValue}
              className={`${isValid ? textClassNames : `${textClassNames} ${props.isRequired ? 'InpSel-wrong' : ''}`}`}
              onChange={getOnChangeHandler()}
              onKeyUp={handleOnKeyup}
              onBlur={handleBlur}
              disabled={disabled}
              readOnly={readonly}
              pattern={props.pattern}
              id={id}
              name={name}
              maxLength={props.maxLength}
              title={props.title ?? ''}
          />
          {props.isRequired && !isValid && (<em className="input-wrong-txt">{validateMess}</em>)}
        </>
    );
  } else {
    return (
        <>
          {displayTitle && props.label
              ? <label htmlFor={id} style={{marginRight: 0}}><span className="srh-tit">{props.label}</span></label>
              : <></>
          }
          <input
              type={props.inputType}
              value={valueTxt ?? ""}
              placeholder={placeholder?.toString()}
              style={props.style}
              className={`${isValid ? textClassNames : `${textClassNames} ${props.isRequired ? 'InpSel-wrong' : ''}`}`}
              onChange={getOnChangeHandler()}
              onKeyUp={handleOnKeyup}
              disabled={disabled}
              readOnly={readonly}
              pattern={props.pattern}
              id={id}
              name={name}
              maxLength={props.maxLength}
              title={props.title ?? ''}
          />
          {props.isRequired && !isValid && (<em className="input-wrong-txt">{validateMess}</em>)}
        </>
    );
  }
};
