import React from "react";
import i18n from "i18next";
import moment from "moment/moment";
import {ButtonColor} from "components/arc/ArcButton";
import {BillModel} from "model/cb/billModel";
import BillListOt from "pages/cb/bill/billList/BillListOt";
import BillListPPA from "pages/cb/bill/billList/BillListPPA";
import BillListDC from "pages/cb/bill/billList/BillListDC";
import BillListHD from "pages/cb/bill/billList/BillListHD";
import BillListTotal from "pages/cb/bill/billList/BillListTotal";

export interface BillBaseProps {
  emksReqdCd: number;
}

export type billPageType = 's' | 'p';
export interface BillListProps {
  emksReqdCd: number;
  children: React.ReactNode;
}

export interface BillListHeaderProps {
  type: billPageType;
  emksReqdCd: number;
}

export interface BillListSearchProps {
  type: billPageType
  emksReqdCd: number;
  search: (data: any) => void;
}

export class BillSearchModel {
  constructor(reqdCd: number, pageType: billPageType) {
    this.emksReqdCd = reqdCd;
    this.pageType = pageType;
  }

  emksReqdCd?: number;
  pageType?: billPageType;

  selectMonth: string = moment().format("YYYYMM");

  reqYmdStdt?: string;
  reqYmdEndt?: string;
  setlSortCd?: number = undefined;
  reqdPblsYn?: string;
  txblYn?: string;
}

// props.emksReqdCd = 0 : 전체, 1: PPA, 2: ET, 3: OT
export default function BillListBase(props: BillBaseProps) {

  return (<>
    {0 === props.emksReqdCd && (<BillListTotal />)}
    {1 === props.emksReqdCd && (<BillListPPA />)}
    {2 === props.emksReqdCd && (<BillListDC />)}
    {3 === props.emksReqdCd && (<BillListOt />)}
    {4 === props.emksReqdCd && (<BillListHD />)}
  </>);
}

export class CustomGridRenderer {
  base: HTMLSpanElement;

  constructor(props: any) {
    this.base = document.createElement('span');
    this.render(props);
  }

  getElement = () => this.base;

  render(props: any) {
    let {type, onClick, pageType} = props.columnInfo.renderer.options;
    const rowData: BillModel = props.grid.getRow(props.rowKey);
    if('hometax' === type) {
      this.renderHomeTax();
    } else if('status' === type) {
      this.renderStatusBadge(rowData.setlAmt!, rowData.payYmd!, rowData.txblYn!);
    } else if('detail' === type) {
      this.renderDetail(onClick, rowData, pageType);
    }
  }

  renderDetail(onClick: (data: any) => void, data: any, pageType: string) {
    data.pageType = pageType;
    this.base.innerHTML = `
      <a href=""> <i class="las la-search" aria-hidden="true"></i> </a> `;
    this.base.addEventListener('click', (e: any) => {
      e.preventDefault();
      onClick(data);
    });
  }

  renderHomeTax() {
    this.base.innerHTML = `
      <a class="btn-style1 btn-round btn-small"> ${i18n.t('cb.bill.taxCheck')} </a> `;
    this.base.addEventListener('click', (e: any) => {
      e.preventDefault();
      window.open('https://hometax.go.kr')
    });
  }

  renderStatusBadge(setlAmt: number, payYmd: string, txblYn: string) {
    if("Y" === txblYn) {
      const today = moment();
      const deadline = moment(payYmd);
      const isPayed = 0 !== setlAmt;
      let text;
      let color;
      if(isPayed) {
        text = i18n.t('cb.bill.status.2');
        color = ButtonColor.btn2;
      } else {
        if (today > deadline) {
          text = i18n.t('cb.bill.status.3');
          color = ButtonColor.btn11;
        } else {
          text = i18n.t('cb.bill.status.1');
          color = ButtonColor.btn1;
        }
      }
      this.base.innerHTML = ` <em class="${color} btn-round btn-small" style="width: 4rem;"> ${text} </em> `;
    } else {
      this.base.innerHTML = '-';
    }
  }
}