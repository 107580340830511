import {useCallback, useRef, useState} from "react";
import {
  isStringNullOrEmpty,
  parseNumberWithMaxValue,
  removeNonNumeric
} from "utils/stringUtil";

interface Options {
  initialValue?: string;
  maxValue?: number;
  minValue?: number;
  maxLength?: number;
  minLength?: number;
  autoFix?: boolean;
  type: 'number' | 'text' | 'select';
  isSearch?: boolean;
}

type returnType = [string, (e: any) => void, boolean, string];

function useAppInput(options?: Options): returnType {

  const {
    initialValue,
    maxValue,
    minValue,
    maxLength = 50,
    minLength = 1,
    autoFix = true,
    type = 'text',
    isSearch = false,
  } = options || {};

  const [value, setValue] = useState<string>(initialValue || '');
  const isValid = useRef<boolean>(true);
  const msg = useRef('');

  const handleNumber = useCallback((receiveValue: string) => {
    let result: string = receiveValue;

    if(maxLength) {
      result = result.substr(0, maxLength);
    }

    if(maxValue) {
      result = parseNumberWithMaxValue(result, maxValue);
    }

    const returnValue: string = autoFix ? result : receiveValue;
    isValid.current = result === receiveValue
      && result !== 'NaN'
      && returnValue.length >= minLength
      && (minValue ? minValue <= parseInt(returnValue, 0) : true);


    setValue(returnValue);

    if('NaN' === result) {
      msg.current = '필수 입력 항목입니다.'
      return
    }

    if(maxLength <= returnValue.length) {
      msg.current = '최대 입력 가능 길이: ' + maxLength
      return
    }

    if(maxValue) {
      if(maxValue <= parseInt(returnValue, 0)) {
        msg.current = '최대 입력 가능 값: ' + maxValue
        return
      }
    }
  }, [maxLength, minLength, maxValue, minValue, autoFix]);

  const handleString = useCallback((receivedValue: string) => {
    let result: string = receivedValue;

    if(maxLength) {
      result = result.substr(0, maxLength);
    }

    const returnValue: string = autoFix ? result : receivedValue;
    isValid.current = result === receivedValue
      && returnValue.length >= minLength
      && returnValue.length <= maxLength;

    if(returnValue.length < minLength) {
      msg.current = '필수 입력 항목입니다.';
    } else if(receivedValue.length > maxLength) {
      msg.current = '최대 입력 가능 길이: ' + maxLength;
    }

    setValue(returnValue);
  }, [maxLength, minLength, autoFix]);

  const handleSelect = useCallback((receivedValue: string) => {
    let result: string = receivedValue;

    isValid.current = !isStringNullOrEmpty(result);

    if(isStringNullOrEmpty(result)) {
      msg.current = '필수 입력 항목입니다.';
    }
    setValue(result);
  }, []);

  const onChangeInput = useCallback((e: any) => {
    const targetValue: string = e.target.value || '';
    if(isSearch) {
      setValue(targetValue);
    } else {
      if(type === 'number') {
        handleNumber(removeNonNumeric(targetValue));
      } else if(type === 'text') {
        handleString(targetValue);
      } else {
        handleSelect(targetValue.toString());
      }
    }
  }, [type, value, handleNumber, handleString, handleSelect]);

  return [value, onChangeInput, isValid.current, msg.current];
}

export default useAppInput;