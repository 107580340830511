import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  emissionsRightList: [],
  emissionsList: []
}

const cpSlice = createSlice({
  name: 'cp',
  initialState,
  reducers: {
    setEmissionsRightList: (state, action) => {
      state.emissionsRightList = action.payload;
    },
    setEmissionsList: (state, action) => {
      state.emissionsList = action.payload;
    }
  },
});

export default cpSlice.reducer;
export const { setEmissionsRightList, setEmissionsList } = cpSlice.actions;