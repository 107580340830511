import ArcButton, {
  ButtonColor,
  ButtonIcon,
  ButtonShape,
  ButtonSize,
  ButtonType
} from "components/arc/ArcButton";
import ArcDatePicker, { ArcDatePickerResult } from "components/arc/ArcDatePicker";
import ArcInput from "components/arc/ArcInput";
import ArcRadioButton, { RadioButtonDataModel } from "components/arc/ArcRadioButton";
import ArcSelectbox, { SelectboxSize } from "components/arc/ArcSelectbox";
import { VariablesConstant } from "constants/variables";
import { t } from "i18next";
import { UserMemberModel } from "model/ua/UserMemberModel";
import { useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { closeModal, openModal } from "reducers/modalSlice";
import { setSignUpInfo } from "reducers/signUpSlice";
import { SignUpService } from "services/signUp/SignUpService";
import { RootState } from "stores/store";
import SignUpTab2Modal from "./SignUpTab2Modal";

export default function SignUpTab2() {

  const navigate = useNavigate();
  const signUp = useSelector((state: RootState) => state.signUp);
  const emksMbrsClCd = signUp.signUpInfo.emksMbrsClCd;
  const mktgJoinAgreYn = signUp.signUpInfo.mktgJoinAgreYn;
  const tchgrEmailAddr = signUp.signUpInfo.tchgrEmailAddr;

  const [form, setForm] = useState<UserMemberModel>(signUp.signUpInfo);
  const [isFormValid, setIsFormValid] = useState<any>(true);
  const dispatch = useDispatch();


  // db에있는 file 저장
  let newFileInfos: string | any[] = [];

  // signUp, signUp.signUpInfo, 그리고 signUp.signUpInfo.fileInfos가 존재하는지 확인
  if (signUp?.signUpInfo?.fileInfos?.length > 0) {
    newFileInfos = signUp.signUpInfo.fileInfos.map((fileInfo: {
      fileNm: any;
      fileCpct: any;
      fileFextNm: any;
      atflId: any;
      pthNm: any;
      rlFileNm: any;
    }) => ({
      name: fileInfo.rlFileNm,
      size: fileInfo.fileCpct,
      type: fileInfo.fileFextNm,
      fileId: fileInfo.atflId,
      path: fileInfo.pthNm,
      lastModified: new Date().getTime() // 임시로 현재 날짜를 사용
    }));
  }

  // form에서 file 추출
  const [fileList, setFileList] = useState(newFileInfos.length > 0 ? [newFileInfos[0]] : []);

  useEffect(() => {
  }, [form]);

  // 주소찾기
  const src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(src);

  const tgtRadioOption: RadioButtonDataModel[] = VariablesConstant.MB;

  const EmksEntClCdSelect = [
    { label: "선택", value: "" },
    { label: "대기업", value: "ECOM001" },
    { label: "중견기업", value: "ECOM002" },
    { label: "중소기업", value: "ECOM003" },
  ];

  function handleClickFileAdd(files: File[]) {
    const deletedFile = fileList.find((f: any) => !files.includes(f));
    if (deletedFile) {
      if (deletedFile?.fileId === form.fileInfos[0]?.atflId) { // 삭제누른 파일과 기존에 db에 저장되어있는 fileId일치 시

        setForm({
          ...form,
          isExistFileDelete: true
        });
      }
      setFileList([]);
    } else {
      setForm({
        ...form,
        file: {
          ...form.file,
          files: files,
        },
      });
    }
  }

  useEffect(() => {
    setForm({
      ...form,
      mktgJoinAgreYn: mktgJoinAgreYn
    }
    )
  }, [mktgJoinAgreYn])

  useEffect(() => {
    if (form.comRegNumFi && form.comRegNumSe && form.comRegNumTh) {
      const regNo = form.comRegNumFi + form.comRegNumSe.toString() + form.comRegNumTh.toString();
      setForm({
        ...form,
        bizrRegNo: regNo
      })
    }
    if (form.repNum1 && form.repNum2 && form.repNum3) {
      const repNo = form.repNum1 + "-" + form.repNum2.toString() + "-" + form.repNum3.toString();
      setForm({
        ...form,
        coRepNo: repNo
      })
    }

    if (form.conPerChar1 && form.conPerChar2 && form.conPerChar3) {
      const conPer = form.conPerChar1 + "-" + form.conPerChar2.toString() + "-" + form.conPerChar3.toString();
      setForm({
        ...form,
        tchgrCplcNo: conPer
      })
    }

  }, [form.comRegNumFi, form.comRegNumSe, form.comRegNumTh, form.repNum1, form.repNum2, form.repNum3, form.conPerChar1, form.conPerChar2, form.conPerChar3])


  function handleCheckId() {

    SignUpService.getInstance()
      .checkUser(form.userId ?? "")
      .then((response) => {
        if (response.data.data) {
          if (response.data.data === "Y") {
            toast.error("중복된 아이디입니다.");
          } else {
            toast.success("사용이 가능한 아이디입니다.");
          }
        } else {
          toast.error(t("common.message.error.system"));
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  function handleCheckRegNo() {
    if (form.comRegNumFi && form.comRegNumSe && form.comRegNumTh) {
      const regNo = form.comRegNumFi + form.comRegNumSe.toString() + form.comRegNumTh.toString();
      SignUpService.getInstance()
        .checkCompanyNo(regNo.toString() ?? "")
        .then((response) => {
          if (response.data.data) {
            if (response.data.data === "Y") {
              toast.error("이미 가입된 사업자 등록번호 입니다.");
            }
            if (form.opbizYmd != null || form.opbizYmd != undefined || form.reprNm != null || form.reprNm != undefined) {

              let value = new UserMemberModel;
              value.bizrRegNo = regNo;
              value.opbizYmd = form.opbizYmd;
              value.reprNm = form.reprNm;

              SignUpService.getInstance()
                .checkBusiness(value)
                .then((response) => {
                  if (response?.resultCode == "Y") {
                    if (response?.data?.data[0]?.valid == "01") {
                      toast.success(t("진위여부 확인에 성공하였습니다."));
                    } else {
                      toast.error(t(response?.data?.data[0]?.valid_msg));
                    }
                  } else {
                    toast.error(t("사업자 등록 정보 지위 확인에 실패하였습니다."));
                  }
                })
                .catch((error) => {
                  toast.error(t("사업자 등록 정보 지위 확인에 실패하였습니다."));
                });
            } else {
              toast.error(t("사업자등록번호, 개업일자, 대표자성명을 입력하시기 바랍니다."));
            }

          } else {
            toast.error(t("common.message.error.system"));
          }
        })
        .catch((error) => {
          toast.error(t("common.message.error.system"));
        });
    }

  }


  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setForm({
      ...form,
      bizrAddr: fullAddress
    })

  };

  const handleChangeInput = (name: string, value: any) => {
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleChangeDatePicker = (result: ArcDatePickerResult) => {


    setForm((prevState: any) => {
      return {
        ...prevState,
        opbizYmd: result.fromDateString
      };
    });
  }

  useEffect(() => {
    if (!isFormValid) { // 회원가입
      saveCreate();
      setIsFormValid(true); // Reset after calling saveForm
    }
  }, [form, isFormValid]);

  async function saveCreate() { // 회원가입

    let checkId = false;
    let checkRegNo = false;
    let checkBusiness = false;
    // 기존 data가있으면 아이디 중복 체크 안함
    let result = await isExistData(form);
    if (result != null && result != "error") {
    } else if (result == "error") {
      checkId = true;
      toast.error("아이디 중복체크를 불러오지 못했습니다.");
    } else if (result == null && form.userId) {
      SignUpService.getInstance()
        .checkUser(form.userId ?? "")
        .then((response) => {
          if (response.data.data) {
            if (response.data.data === "Y") {
              checkId = true;
              toast.error("중복 아이디");
            } else {
              checkId = false;
            }
          } else {
            toast.error(t("아이디 중복체크를 불러오지 못했습니다."));
          }
        })
        .catch((error) => {
          toast.error(t("아이디 중복체크를 불러오지 못했습니다."));
        });
    } else {
      toast.error("아이디를 입력해주시기 바랍니다.");
      checkId = true;
    }

    if (form.comRegNumFi && form.comRegNumSe && form.comRegNumTh) {
      const regNo = form.comRegNumFi + form.comRegNumSe.toString() + form.comRegNumTh.toString();

      if (!signUp.signUpInfo?.bizrRegNo) { // 이전에 임시저장한 사업자번호가 존재하지않으면 체크, 존재하면 확인 넘기기
        SignUpService.getInstance()
          .checkCompanyNo(regNo.toString() ?? "")
          .then((response) => {
            if (response.data.data) {
              if (response.data.data === "Y") {
                checkRegNo = true;
                toast.error("중복된 사업자 등록번호");
              } else {
                checkRegNo = false
              }
            } else {
              toast.error(t("사업자 등록번호 중복체크를 불러오지 못했습니다."));
            }
          })
          .catch((error) => {
            toast.error(t("사업자 등록번호 중복체크를 불러오지 못했습니다."));
          });
      } else {
        checkRegNo = false;
      }
    } else {
      checkRegNo = true;
      toast.error("사업자번호를 모두 입력해주시기 바랍니다.")
    }

    if (!checkId && !checkRegNo && !checkBusiness) {
      form.userTmpSavYn = 'N';
      form.emksMbrsClCd = emksMbrsClCd;
      form.tchgrEmailAddr = tchgrEmailAddr;
      debugger;
      const result = await isDraftData(form);
      if (!result) {
        return toast.error("시스템 오류가 발생하였습니다.");
      } else {
        SignUpService.getInstance()
          .insertSignUp(form)
          .then((response) => {
            navigate(`/sign-up/5`);

            if (response?.data) {
              toast.success(t('회원가입을 성공하였습니다.'));
              navigate(`/sign-up/5`);
            } else {
              toast.error(t("회원가입을 실패하였습니다."));
              navigate(`/sign-up`)
            }
          })
          .catch((error) => {
            toast.error(t("시스템 오류가 발생하였습니다."));
          });
      }
    } else {
      toast.error(t("아이디 중복확인, 사업자번호 중복확인을 맞춰주시기 바랍니다."))
    }
  }

  const handleChangeRadio = (e: any) => {
    setForm((prevState: any) => {
      return {
        ...prevState,
        emksRe100JoinClCd: e.target.value
      }
    })
  }

  function selectEmksEntClCd(data: any) {
    setForm((prevState: any) => {
      return {
        ...prevState,
        emksEntClCd: data.target.value
      };
    });
  }

  function clickBack() {
    navigate(`/`);
  }


  async function clickNextBtn() {
    const isValid = validateForm(form);
    let checkId = false;
    let checkRegNo = false;
    let checkBusiness = false;

    if (form.userTmpSavYn !== "Y") {
      try {
        const response = await SignUpService.getInstance().checkUser(form.userId ?? "");
        if (response.data.data) {
          if (response.data.data === "Y") {
            toast.error("중복 아이디");
            checkId = true;
          }
        } else {
          toast.error(t("common.message.error.system"));
          checkId = true;
        }
      } catch (error) {
        toast.error(t("common.message.error.system"));
        checkId = true;
      }
    }

    if (checkId) return;

    if (form.comRegNumFi && form.comRegNumSe && form.comRegNumTh) {
      const regNo = form.comRegNumFi + form.comRegNumSe + form.comRegNumTh;

      if (signUp?.signUpInfo?.bizrRegNo && signUp?.signUpInfo?.bizrRegNo !== regNo) {
        try {
          const response = await SignUpService.getInstance().checkCompanyNo(regNo);
          if (response.data.data) {
            if (response.data.data === "Y") {
              toast.error("중복된 사업자 등록번호");
              checkRegNo = true;
            }
          } else {
            toast.error(t("common.message.error.system"));
            checkRegNo = true;
          }
        } catch (error) {
          toast.error(t("common.message.error.system"));
          checkRegNo = true;
        }
      }
    } else {
      toast.error(t("사업자 등록번호를 모두 명시해주시기 바랍니다."));
      checkRegNo = true;
    }

    if (checkRegNo) return;

    if (form.comRegNumFi && form.comRegNumSe && form.comRegNumTh && form.opbizYmd && form.reprNm) {
      const regNo = form.comRegNumFi + form.comRegNumSe + form.comRegNumTh;

      const value = new UserMemberModel();
      value.bizrRegNo = regNo;
      value.opbizYmd = form.opbizYmd;
      value.reprNm = form.reprNm;

      try {
        const response = await SignUpService.getInstance().checkBusiness(value);
        if (response?.resultCode == "Y") {
          if (response?.data?.data[0]?.valid !== "01") {
            toast.error(t(response?.data?.data[0]?.valid_msg));
            checkBusiness = true;
          }
        } else {
          toast.error(t("사업자 등록 정보 지위 확인에 실패하였습니다."));
          checkBusiness = true;
        }
      } catch (error) {
        toast.error(t("사업자 등록 정보 지위 확인에 실패하였습니다."));
        checkBusiness = true;
      }
    } else {
      toast.error(t("사업자등록번호, 개업일자, 대표자성명을 입력하시기 바랍니다."));
      checkBusiness = true;
    }

    if (checkBusiness) return;

    if (!checkId && !checkRegNo && !checkBusiness && !isValid) {
      const result = await isDraftData(form);
      if (!result) {
        toast.error("시스템 오류가 발생하였습니다.");
      } else {
        reduceSave(form); // reduce에 data 저장
        navigate(`/sign-up/3`);
      }
    }
  }


  function reduceSave(newForm: any) {
    newForm.emksMbrsClCd = emksMbrsClCd;
    newForm.mktgJoinAgreYn = mktgJoinAgreYn;
    newForm.tchgrEmailAddr = tchgrEmailAddr;
    newForm.staIndClaCerId = signUp?.signUpInfo?.staIndClaCerId;
    newForm.staIndClaCer1 = signUp?.signUpInfo?.staIndClaCer1;
    newForm.staIndClaCer2 = signUp?.signUpInfo?.staIndClaCer2;
    dispatch(setSignUpInfo(newForm));
  }

  async function clickSaveDraw() {
    let checkId = false;
    let checkPwd = false;

    // 기존 data가있으면 아이디 중복 체크 안함
    let result = await isExistData(form);
    if (result != null && result != "error") {
    } else if (result == "error") {
      checkId = true;
      toast.error("아이디 중복체크를 불러오지 못했습니다.");
    } else {
      SignUpService.getInstance()
        .checkUser(form.userId ?? "")
        .then((response) => {
          if (response.data.data) {
            if (response.data.data === "Y") {
              toast.error("중복된 아이디입니다.");
              checkId = true;
            } else {
              checkId = false;
              // 비밀번호 패턴일치, 확인 체크
              if (form.userPwdEncVal != null && form.userPwdEncVal != undefined && form.conFirmPw != null && form.conFirmPw != undefined) {
                if (form.userPwdEncVal == form.conFirmPw) {
                  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
                  if (!passwordPattern.test(form.userPwdEncVal)) {
                    toast.error(t("비밀번호가 패턴과 맞지않습니다."));
                    checkPwd = true;
                  } else {
                    checkPwd = false;
                  }
                } else {
                  toast.error(t("비밀번호 확인을 일치시켜야 합니다."));
                  checkPwd = true;
                }
              } else {
                toast.error(t("비밀번호와 비밀번호 확인을 입력해주시기 바랍니다."));
              }
            }
          } else {
            toast.error(t("common.message.error.system"));
          }
        })
        .catch((error) => {
          toast.error(t("common.message.error.system"));
        });
    }

    if (form.userId != null && form.userId != undefined && !checkId) {
      if (form.userPwdEncVal != null && form.userPwdEncVal != undefined && !checkPwd &&
        form.conFirmPw != null && form.conFirmPw != undefined) {


        let regNo: string | undefined = undefined;
        if (form.comRegNumFi && form.comRegNumSe && form.comRegNumTh) {
          regNo = form.comRegNumFi.toString() + form.comRegNumSe.toString() + form.comRegNumTh.toString();
        }

        setForm({
          ...form,
          userTmpSavYn: 'Y', // 임시저장
          emksMbrsClCd: emksMbrsClCd,
          mktgJoinAgreYn: mktgJoinAgreYn,
          tchgrEmailAddr: tchgrEmailAddr,
          staIndClaCerId: signUp?.signUpInfo?.staIndClaCerId,
          staIndClaCer1: signUp?.signUpInfo?.staIndClaCer1,
          staIndClaCer2: signUp?.signUpInfo?.staIndClaCer2,
          bizrRegNo: regNo
        });
        let tmpForm: UserMemberModel = form;
        tmpForm = {
          ...form,
          userTmpSavYn: 'Y', // 임시저장
          emksMbrsClCd: emksMbrsClCd,
          mktgJoinAgreYn: mktgJoinAgreYn,
          tchgrEmailAddr: tchgrEmailAddr,
          staIndClaCerId: signUp?.signUpInfo?.staIndClaCerId,
          staIndClaCer1: signUp?.signUpInfo?.staIndClaCer1,
          staIndClaCer2: signUp?.signUpInfo?.staIndClaCer2,
          bizrRegNo: regNo
        }

        // 임시저장 데이터 확인 (이전 데이터 있으면 삭제 api 요청)
        const result = await isDraftData(tmpForm);
        if (!result) {
          return toast.error("시스템 오류가 발생하였습니다.");
        } else {
          SignUpService.getInstance()
            .insertSignUp(tmpForm)
            .then((response) => {
              if (response?.data) {
                toast.success(t('common.message.success.save', { title: '' }));
              } else {
                toast.error(t("common.message.error.save"));
              }
            })
            .catch((error) => {
              toast.error(t("common.message.error.system"));
            });
        }
      } else {
        toast.error(t("비밀번호와 비밀번호 확인을 패턴에 맞게 입력하시기 바랍니다."));
      }

    } else {
      toast.error(t("회원 아이디를 입력 후 중복체크 해주시기 바랍니다."));
    }
  }

  async function isDraftData(tmpForm: any) {
    let check: boolean = false;
    let resultId = await isExistData(tmpForm);
    if (resultId == null) {
      check = true;
    } else if (resultId != "error") { //아이디가 반환됨
      const res = await SignUpService.getInstance().deleteDraft(resultId);
      if (res && res.data == true && res.resultCode == "Y") {
        check = true;
      }
    }
    return check;
  }

  async function isExistData(tmpForm: any) { // return = null (중복없음). result = error (오류발생). 그 외는 result = 아이디 반환 
    let check = null;
    const response = await SignUpService.getInstance().checkExistData(tmpForm.tchgrEmailAddr);
    if (response && response.data == null && response.resultCode == "Y") {
    } else if (response && response.data != null && response.resultCode == "Y") {
      let data: any = response.data;
      check = data.userId;
    } else {
      check = "error";
    }
    return check;
  }



  function validateForm(param: any) {
    let check: boolean = false;

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    if (!param.userId || !param.userPwdEncVal || !param.conFirmPw || !param.comRegNumFi || !param.comRegNumSe || !param.comRegNumTh || !param.bizrNm ||
      !param.reprNm || !param.repNum1 || !param.repNum2 || !param.repNum3 || !param.bizrAddr || !param.bizrDtlsAddr || !param.tchgrNm ||
      !param.conPerChar1 || !param.conPerChar2 || !param.conPerChar3 || !param.opbizYmd ||
      param.conPerChar1 == "" || param.emksEntClCd == "" || param.repNum1 == ""
    ) {
      toast.error("필수값을 입력해 주세요!");
      check = true;
    } else if (param.userPwdEncVal === param.conFirmPw) {
      if (!passwordPattern.test(param.userPwdEncVal)) {
        toast.error(t("비밀번호가 패턴과 맞지않습니다."));
        check = true;
      } else {
        check = false;
      }
    } else {
      toast.error(t("비밀번호 확인을 일치시켜야 합니다."));
      check = true;
    }

    if (!check) {
      setForm((prevState: any) => {
        return {
          ...prevState
        };
      });
    }

    return check;

  }

  function re100ValidateForm(param: any) {
    let check: boolean = false;
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;

    if (!param.userId || !param.userPwdEncVal || !param.conFirmPw || !param.comRegNumFi || !param.comRegNumSe || !param.comRegNumTh || !param.bizrNm ||
      !param.reprNm || !param.repNum1 || !param.repNum2 || !param.repNum3 || !param.bizrAddr || !param.bizrDtlsAddr || !param.tchgrNm ||
      !param.conPerChar1 || !param.conPerChar2 || !param.conPerChar3 || !param.opbizYmd ||
      param.conPerChar1 == "" || param.emksEntClCd == "" || param.repNum1 == ""
      // type1 인 경우 추가 컬럼
      || !param.emksRe100JoinClCd || !param.emksEntClCd || !signUp?.signUpInfo?.staIndClaCer1 || !signUp?.signUpInfo?.staIndClaCer2 || !signUp?.signUpInfo?.staIndClaCerId
    ) {
      toast.error("필수값을 입력해 주세요!");
      check = true;
    } else if (param.userPwdEncVal === param.conFirmPw) {
      if (!passwordPattern.test(param.userPwdEncVal)) {
        toast.error(t("비밀번호가 패턴과 맞지않습니다."));
        check = true;
      } else {
        check = false;
      }
    } else {
      toast.error(t("비밀번호 확인을 일치시켜야 합니다."));
      check = true;
    }

    if (!check) {
      setForm((prevState: any) => {
        return {
          ...prevState
        };
      });
    }
    return check;
  }

  function clickCreate() {
    if (emksMbrsClCd == "2" || emksMbrsClCd == "3") {
      const isValid = validateForm(form);
      setIsFormValid(isValid);
    } else { // re100 유형일 경우
      const isValid = re100ValidateForm(form);
      setIsFormValid(isValid);
    }
  }

  function handleClickModal() {
    dispatch(
      openModal({
        path: "/sign-up/2/modal",
        target: SignUpTab2Modal,
        modalProps: {
          title: t("선택"),
          data: form,
          onClose: closeFormDialog,
        },
      })
    );
  }

  const closeFormDialog = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
  }, [signUp])

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont member-h">
            <div className="tit-step">
              <h1 className="titT2">회원가입</h1>
              {/* 산단관리는 탭 4개 */}
              {emksMbrsClCd == "1" ?
                <ul className="tab-order-st1">
                  <li>
                    <div><strong>01</strong><span>본인인증</span></div>
                  </li>
                  <li className="on">
                    <div><strong>02</strong><span>기본정보</span></div>
                  </li>
                  <li>
                    <div><strong>03</strong><span>고객정보</span></div>
                  </li>
                  <li>
                    <div><strong>04</strong><span>가입완료</span></div>
                  </li>
                </ul>
                :
                <ul className="tab-order-st1">
                  <li>
                    <div><strong>01</strong><span>본인인증</span></div>
                  </li>
                  <li className="on">
                    <div><strong>02</strong><span>정보입력</span></div>
                  </li>
                  <li>
                    <div><strong>03</strong><span>가입완료</span></div>
                  </li>
                </ul>
              }
            </div>
            {emksMbrsClCd == "1" ?
              <div className="join-box">
                <h2>RE100 참여여부</h2>
                <div className="inp-flex mgt10">
                  <span className="bu-est" title={"*필수입력 여부선택"}>여부선택</span>
                  <ArcRadioButton
                    selectedValue={form.emksRe100JoinClCd}
                    dataSource={tgtRadioOption}
                    nameGroup={"tgtRadioOption"}
                    onChange={handleChangeRadio}
                    isAlt={true}
                  />
                </div>

              </div>
              : ''}

            <div className="join-box">
              <div>
                <h2>로그인 정보</h2>
                <div className="flexWrap mg0">
                  <p className="desc">아이디 및 비밀번호를 입력해주세요.</p>
                  <span className="bu-est"><strong>은 필수 항목입니다.</strong></span>
                </div>
                <div className="inp-flex">
                  <label htmlFor={"input-1"} className="bu-est" title={"*필수입력 아이디"}>아이디</label>
                  <ArcInput
                    isRequired={true}
                    value={form.userId ?? ''}
                    inputType="text"
                    type={"w20p"}
                    placeholder={"아이디 입력"}
                    prefix={""}
                    title={"아이디 입력"}
                    readonly={(signUp?.signUpInfo?.userId)} // 가입이력 x라면 입력 가능
                    disabled={(signUp?.signUpInfo?.userId)}
                    id={"input-1"}
                    name={"input-1"}
                    maxLength={30}
                    onChange={(value, isValid) => handleChangeInput("userId", value)}
                  />
                  {
                    !signUp?.signUpInfo?.userId ? <ArcButton
                      type={ButtonType.custom}
                      hasIcon={false}
                      color='btn-style3'
                      text={"중복확인"}
                      onClick={handleCheckId} /> : <></>
                  }

                </div>
                <div className="inp-flex">
                  <label htmlFor={"input-2"} className="bu-est" title={"*필수입력 비밀번호"}>비밀번호</label>
                  <ArcInput
                    isRequired={true}
                    value={form.userPwdEncVal ?? ''}
                    inputType="text"
                    type={"w20p"}
                    placeholder="비밀번호 입력"
                    prefix={""}
                    id={"input-2"}
                    name={"input-2"}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$"
                    onChange={(value, isValid) => handleChangeInput("userPwdEncVal", value)}
                  />
                </div>
                <div className="inp-flex">
                  <label htmlFor={"input-3"} className="bu-est" title={"*필수입력 비밀번호 확인"}>비밀번호 확인</label>
                  <ArcInput
                    isRequired={true}
                    value={form.conFirmPw ?? ""}
                    type={"w20p"}
                    placeholder="비밀번호 확인"
                    prefix={""}
                    id={"input-3"}
                    name={"input-3"}
                    onChange={(value, isValid) => handleChangeInput("conFirmPw", value)}
                  />
                  <p className="bu-atte">8자 이상, 16자 이하의 대소문자, 숫자 및 특수문자를 조합하여 사용해야
                    합니다.</p>
                </div>
              </div>
              <div className="">
                <div className="mgt15">
                  <h2>기본정보 입력</h2>
                  <div className="flexWrap">
                    <p className="desc">사업자 정보 내용을 입력해주세요.</p>
                    <span className="bu-est"><strong>은 필수 항목입니다.</strong></span>
                  </div>

                  <div className="inp-flex hid">
                    <span className="bu-est">비즈니스 구분</span>
                    <span className="checkbox-radio-type02">
                      <label htmlFor="checkbox2-1"><input
                        type="checkbox" id="checkbox2-1"
                        name="checkbox2-1" />일반(E-market)</label>
                    </span>
                    <span className="checkbox-radio-type02">
                      <label htmlFor="checkbox2-1"><input
                        type="checkbox" id="checkbox2-1"
                        name="checkbox2-1" />유연자원(MG플랫폼)</label>
                    </span>
                  </div>


                  <div className="inp-flex">
                    <label htmlFor={"input-4"} className="bu-est" title={"*필수입력 회사명"}>회사명</label>
                    <ArcInput
                      isRequired={true}
                      value={form.bizrNm ?? ""}
                      title={"회사명 입력"}
                      validateMessage={"회사명은 필수 입력 사항입니다."}
                      type={"w20p"}
                      placeholder="회사명"
                      disabled={false}
                      readonly={false}
                      maxLength={30}
                      prefix={""}
                      id={"input-4"}
                      name={"input-4"}
                      onChange={(value, isValid) => handleChangeInput("bizrNm", value)}
                    />
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-2"} className="bu-est" title={"*필수입력 개업일자"}>개업일자</label>
                    <ArcDatePicker
                      onChange={handleChangeDatePicker}
                      title={"개업일자"}
                      id={"input-2"}
                      name={"input-2"}
                      viewType={"date"}
                      fromDate={form.opbizYmd ?? ""}
                    />
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-5"} className="no-est bu-est" title={"*필수입력 대표자명"}>대표자명</label>
                    <ArcInput
                      isRequired={true}
                      value={form.reprNm ?? ""}
                      validateMessage={"대표자명은 필수 입력 사항입니다."}
                      title={"대표자명 입력"}
                      type={"w20p"}
                      disabled={false}
                      readonly={false}
                      placeholder={"대표자명"}
                      maxLength={20}
                      prefix={""}
                      id={"input-5"}
                      name={"input-5"}
                      onChange={(value, isValid) => handleChangeInput("reprNm", value)}
                    />
                  </div>
                  <div className="inp-flex">
                    <label htmlFor="input-6" className="bu-est" title={"*필수입력 사업자 등록번호"}>사업자 등록번호</label>
                    <div>
                      <ArcInput
                        isRequired={true}
                        value={form.comRegNumFi ?? ''}
                        title={"사업자 등록번호 첫 번째 입력"}
                        inputType={"tel"}
                        maxLength={3}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"000"}
                        prefix={""}
                        id={"input-6"}
                        name={"input-6"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("comRegNumFi", value)}
                      />
                      <span className="input-util mgl10 mgr10">-</span>
                      <ArcInput
                        isRequired={true}
                        value={form.comRegNumSe ?? ''}
                        title={"사업자 등록번호 두 번째 입력"}
                        inputType={"tel"}
                        maxLength={2}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"00"}
                        prefix={""}
                        id={"input-7"}
                        name={"input-7"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("comRegNumSe", value)}
                      />
                      <span className="input-util mgl10 mgr10">-</span>
                      <ArcInput
                        isRequired={true}
                        value={form.comRegNumTh ?? ""}
                        inputType={"tel"}
                        maxLength={5}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"00000"}
                        prefix={""}
                        id={"input-8"}
                        name={"input-8"}
                        className=" disIb"
                        onChange={(value) => handleChangeInput("comRegNumTh", value)}
                      />
                    </div>
                    <ArcButton
                      type={ButtonType.custom}
                      hasIcon={false}
                      color='btn-style3'
                      text={"중복확인"}
                      onClick={handleCheckRegNo} />
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-9"} className="bu-est" title={"*필수입력 대표 번호"}>대표 번호</label>
                    <div>
                      <ArcInput
                        isRequired={true}
                        value={form.repNum1 != undefined ? form.repNum1.toString() : ""}
                        title={"대표번호 첫 번째 입력"}
                        inputType={"tel"}
                        maxLength={3}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"000"}
                        prefix={""}
                        id={"input-9"}
                        name={"input-9"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("repNum1", value)}
                      />
                      <span className="input-util mgl10 mgr10">-</span>
                      <ArcInput
                        isRequired={true}
                        value={form.repNum2 != undefined ? form.repNum2.toString() : ""}
                        title={"대표번호 두 번째 입력"}
                        inputType={"tel"}
                        maxLength={4}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"0000"}
                        prefix={""}
                        id={"input-8"}
                        name={"input-8"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("repNum2", value)}
                      />
                      <span className="input-util mgl10 mgr10">-</span>
                      <ArcInput
                        isRequired={true}
                        value={form.repNum3 != undefined ? form.repNum3.toString() : ""}
                        title={"대표번호 세 번째 입력"}
                        inputType={"tel"}
                        maxLength={4}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"0000"}
                        prefix={""}
                        id={"input-9"}
                        name={"input-9"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("repNum3", value)}
                      />
                    </div>
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-10"} className="bu-est" title={"*필수입력 회사 주소"}>회사 주소</label>
                    <ArcInput
                      isRequired={true}
                      value={form.bizrAddr ?? ""}
                      placeholder=""
                      type={"w60p"}
                      disabled={true}
                      maxLength={500}
                      readonly={true}
                      prefix={""}
                      id={"input-10"}
                      name={"input-10"}
                      onChange={() => {
                      }}
                    />
                    <ArcButton
                      type={ButtonType.custom}
                      hasIcon={false}
                      color='btn-style1'
                      text={"주소 검색"}
                      title={"새창"}
                      onClick={handleClick} />

                  </div>
                  <div className="inp-flex mgt5">
                    <label htmlFor={"input-11"} className="bu-est" title={"*필수입력 회사 나머지 주소"}>회사 나머지 주소</label>
                    <ArcInput
                      isRequired={true}
                      value={form.bizrDtlsAddr ?? ""}
                      title={"회사 나머지 주소 입력"}
                      validateMessage={"회사 주소는 필수 입력 사항입니다."}
                      type={"w60p"}
                      disabled={false}
                      maxLength={200}
                      readonly={false}
                      placeholder={"나머지 주소 입력"}
                      prefix={""}
                      id={"input-11"}
                      name={"input-11"}
                      onChange={(value, isValid) => handleChangeInput("bizrDtlsAddr", value)}
                    />
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-12"} className="bu-est" title={"*필수입력 담당자명"}>담당자명</label>
                    <ArcInput
                      isRequired={true}
                      value={form.tchgrNm ?? ""}
                      title={"담당자명 입력"}
                      validateMessage={"담당자명은 필수 입력 사항입니다."}
                      type={"w20p"}
                      disabled={false}
                      readonly={false}
                      placeholder="담당자명"
                      prefix={""}
                      maxLength={20}
                      id={"input-12"}
                      name={"input-12"}
                      onChange={(value, isValid) => handleChangeInput("tchgrNm", value)}
                    />
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-13"} className="bu-est" title={"*필수입력 담당자 연락처"}>담당자 연락처</label>
                    <div>
                      <ArcInput
                        value={form.conPerChar1 != undefined ? form.conPerChar1.toString() : ""}
                        title={"담당자 연락처 첫 번째 입력"}
                        isRequired={true}
                        inputType={"tel"}
                        maxLength={3}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"000"}
                        prefix={""}
                        id={"input-13"}
                        name={"input-13"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("conPerChar1", value)}
                      />
                      <span className="input-util mgl10 mgr10">-</span>
                      <ArcInput
                        isRequired={true}
                        value={form.conPerChar2 != undefined ? form.conPerChar2.toString() : ""}
                        title={"담당자 연락처 두 번째 입력"}
                        inputType={"tel"}
                        maxLength={4}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"0000"}
                        prefix={""}
                        id={"input-14"}
                        name={"input-14"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("conPerChar2", value)}
                      />
                      <span className="input-util mgl10 mgr10">-</span>
                      <ArcInput
                        isRequired={true}
                        value={form.conPerChar3 != undefined ? form.conPerChar3.toString() : ""}
                        title={"담당자 연락처 세 번째 입력"}
                        inputType={"tel"}
                        maxLength={4}
                        type={"w100"}
                        disabled={false}
                        readonly={false}
                        placeholder={"0000"}
                        prefix={""}
                        id={"input-15"}
                        name={"input-15"}
                        className="disIb"
                        onChange={(value, isValid) => handleChangeInput("conPerChar3", value)}
                      />
                    </div>
                  </div>
                  <div className="inp-flex">
                    <label htmlFor={"input-16"} className="bu-est" title={"*필수입력 담당자 이메일"}>담당자 이메일</label>
                    <ArcInput
                      isRequired={true}
                      value={tchgrEmailAddr}
                      title={"담당자 이메일 계정 입력"}
                      validateMessage={"이메일 계정을 입력하세요."}
                      placeholder="담당자 이메일"
                      type={"w40p"}
                      disabled={true}
                      readonly={true}
                      prefix={""}
                      id={"input-16"}
                      name={"input-16"}
                      onChange={() => { }}
                    />
                  </div>

                  {emksMbrsClCd == "1" ?
                    <>
                      <div className="inp-flex">
                        <label className="bu-est" title={"* 기업구분"}>기업구분</label>
                        <ArcSelectbox name={"comClass"}
                          isRequired={true}
                          selectedValue={form.emksEntClCd}
                          onChange={data => selectEmksEntClCd(data)}
                          options={EmksEntClCdSelect}
                          size={SelectboxSize.w20}
                        />
                      </div>
                      <div className="inp-flex">
                        <label htmlFor={"button-1"} className="bu-est" title={"*필수입력 표준산업중분류"}>표준산업분류중분류</label>
                        <div>
                          <ArcInput
                            value={signUp?.signUpInfo?.staIndClaCer1 ?? ""}
                            isRequired={true}
                            type={"w100"}
                            disabled={true}
                            readonly={true}
                            placeholder="코드"
                            prefix={""}
                            id={"input-17"}
                            name={"input-17"}
                            className="disIb"
                            onChange={(value, isValid) => handleChangeInput("staIndClaCer1", value)}
                          />
                          <span className="input-util mgl10 mgr10">/</span>
                          <ArcInput
                            value={signUp?.signUpInfo?.staIndClaCer2 ?? ""}
                            isRequired={true}
                            type={"w100"}
                            disabled={true}
                            readonly={true}
                            placeholder="업종"
                            prefix={""}
                            id={"input-18"}
                            name={"input-18"}
                            className=" disIb"
                            onChange={(value, isValid) => handleChangeInput("staIndClaCer2", value)}
                          />
                        </div>
                        <ArcButton
                          type={ButtonType.custom}
                          hasIcon={false}
                          color='btn-style3'
                          text={"추가"}
                          title={"새창"}
                          onClick={handleClickModal} />
                      </div>

                    </>
                    : ''}
                  {/* <div className="inp-flex">
                                                    <span className="bu-est">사업자등록증 첨부</span>
                                                    <div className="InpSel-w60">
                                                        <ArcFileUploader
                                                            maxFileNum={1}
                                                            maxFileSizeMb={90}
                                                            dbFileList={fileList}
                                                            dbUpload={handleExistFile}
                                                            onUpload={handleClickFileAdd}
                                                            key={fileList.length}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="inp-flex mgt5">
                                                    <span className="bu-est hid"></span>
                                                    <p className="bu-atte">파일첨부 제한:doc, docx, xlsx, ppt, pptx, hwp, txt,
                                                        pdf, jpg, jpeg, png만 첨부 가능합니다.</p>
                                                </div> */}
                </div>
              </div>
            </div>

            <div className="btns">
              <ArcButton type={"custom"} color={ButtonColor.btn5} text={"취소"}
                shape={ButtonShape.normal} size={ButtonSize.large}
                onClick={clickBack} />
              <ArcButton hasIcon={true}
                icon={ButtonIcon.save} type={"custom"} color={ButtonColor.btn1}
                text={"임시저장"} shape={ButtonShape.normal}
                size={ButtonSize.large} onClick={clickSaveDraw} />

              {emksMbrsClCd == "1" ?
                <ArcButton type={"custom"} color={ButtonColor.btn2} text={"다음"}
                  shape={ButtonShape.normal} size={ButtonSize.large}
                  onClick={clickNextBtn} />

                :
                <ArcButton type={"custom"} color={ButtonColor.btn2} text={"회원가입"}
                  shape={ButtonShape.normal} size={ButtonSize.large}
                  onClick={clickCreate} />
              }
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}