import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch } from "stores/hook";
import { useTranslation } from "react-i18next";
import { closeModal, ConfirmProps } from "reducers/modalSlice";
import { DefaultTFuncReturn } from "i18next";
import ArcButton, { ButtonType } from "components/arc/ArcButton";

export default function AppAlert(props: ConfirmProps) {
  const {
    type= 'alert',
    title,
    message,
    onConfirm,
    onClose,
  } = props;

  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const [alertTitle, setAlertTitle] =
    useState<string|DefaultTFuncReturn|undefined>(undefined);
  const [alertMsg, setAlertMsg] = useState<ReactNode>(undefined);

  useEffect(() => {
    if('delete' === type) {
      setAlertTitle(title?title:t('confirm.label.message.delete'));
      // setAlertMsg(message??t('confirm.label.message.deleteConfirm'))
    } else if ('save' === type) {
      setAlertTitle(title ?? t('confirm.label.message.save'));
      // setAlertMsg(message??t('confirm.label.message.saveConfirm'))
    } else if ('status' === type) {
      setAlertTitle(title ?? t('confirm.label.message.statusChange'));
      setAlertMsg(message ?? t('confirm.label.message.statusChangeConfirm'))
    } else {
      setAlertTitle(title??'');
      setAlertMsg(message??'');
    }
  }, []);

  const handleClickConfirm = () => {
    dispatch(closeModal());
    if(onConfirm) {
      onConfirm();
    }
  }

  const handleClickClose = () => {
    dispatch(closeModal());
    if(onClose) {
      onClose();
    }
  }

  return (
    <>
      <h3 className="titT1">{alertTitle}</h3>
      <div className="layer-cont" style={{overflowY: "visible"}}>
        {alertMsg}
      </div>
      <div className="btns ac" style={{margin: "1rem 0 0rem"}}>
        <ArcButton type={ButtonType.cancel} onClick={handleClickClose} />
        <ArcButton type={ButtonType.confirm} onClick={handleClickConfirm} />
      </div>
    </>
  )

}