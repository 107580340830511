import React, {lazy} from "react";
import CntrCompleteReport from "pages/rp/cntr/CntrCompleteReport";

const Portfolio = lazy(() => import("pages/rp/pfl/Portfolio"))
const Roadmap = lazy(() => import("pages/rp/rdmp/Rdmp"))
const Matching = lazy(() => import("pages/rp/MatchingTarget"))
const MatchingSearchList = lazy(() => import("pages/rp/matchingTarget/MatchingSearchList"))
const MatchingSearchDetail = lazy(() => import("pages/rp/matchingTarget/MatchingSearchDetail"))
const CntrList = lazy(() => import("pages/rp/cntr/CntrList"))
const TrdNegoDetail = lazy(() => import("pages/rp/cntr/TrdNegoDetail"))
const CntrWriteForm = lazy(() => import("pages/rp/cntr/CntrWriteForm"))
const InstitutionReg = lazy(() => import("pages/rp/cntr/InstitutionReg"))
const CntrComplete = lazy(() => import("pages/rp/cntr/CntrComplete"))

const CntrReport = lazy(() => import("pages/rp/cntr/CntrReport"));

const BillList = lazy(() => import("pages/cb/bill/billList/BillListBase"));
const BillForm = lazy(() => import("pages/cb/bill/billForm/BillFormBase"));
const BillDetail = lazy(() => import("pages/cb/bill/billDetail/BillDetailBase"));

const SmcnCertSelfPGList = lazy(() => import("pages/rp/smcnCert/SmcnCertSelfPGList"))
const SmcnCertSelfPGInsert = lazy(() => import("pages/rp/smcnCert/SmcnCertSelfPGInsert"))
const SmcnCertSelfPDetail = lazy(() => import("pages/rp/smcnCert/SmcnCertSelfPGDetail"))
const SmcnCertPPAList = lazy(() => import("pages/rp/smcnCert/SmcnCertPPAList"))
const SmcnCertPPAInsert = lazy(() => import("pages/rp/smcnCert/SmcnCertPPAInsert"))
const SmcnCertPPADetail = lazy(() => import("pages/rp/smcnCert/SmcnCertPPADetail"))
const SmcnCertRECList = lazy(() => import("pages/rp/smcnCert/SmcnCertRECList"))
const SmcnCertRECInsert = lazy(() => import("pages/rp/smcnCert/SmcnCertRECInsert"))
const SmcnCertRECDetail = lazy(() => import("pages/rp/smcnCert/SmcnCertRECDetail"))
const SmcnCertGrprList = lazy(() => import("pages/rp/smcnCert/SmcnCertGrprList"))
const SmcnCertGrprInsert = lazy(() => import("pages/rp/smcnCert/SmcnCertGrprInsert"))
const SmcnCertGrprDetail = lazy(() => import("pages/rp/smcnCert/SmcnCertGrprDetail"))
const UseConfirmationList = lazy(() => import("pages/rp/useConfirmation/UseConfirmationList"))
const UseConfirmationInsert = lazy(() => import("pages/rp/useConfirmation/UseConfirmationInsert"))
const UseConfirmationDetail = lazy(() => import("pages/rp/useConfirmation/UseConfirmationDetail"))
const RlibrList = lazy(() => import("pages/rp/rlibr/RlibrList"))
const RlibrDetail = lazy(() => import("pages/rp/rlibr/RlibrDetailPage"))
const RlibrInsert = lazy(() => import("pages/rp/rlibr/RlibrInsert"))
const ImplWayAnalysis = lazy(() => import("pages/rp/implWayAnalysis/ImplWayAnalysis"))
const EntreInfoPowerList = lazy(() => import("pages/rp/entrepreneurInfo/PwStationList"))
const EntrInfoPowerInsert = lazy(() => import("pages/rp/entrepreneurInfo/PwStationInsert"))
const EntrInfoPowerDetail = lazy(() => import("pages/rp/entrepreneurInfo/PwStationDetail"))
const ElecUserInsert = lazy(() => import("pages/rp/entrepreneurInfo/ElectricUserInsert"))
const ElecUserDetail = lazy(() => import("pages/rp/entrepreneurInfo/ElectricUserDetail"))
const ElecUserList = lazy(() => import("pages/rp/entrepreneurInfo/ElectricUserList"))
const RdmpGoalSetting = lazy(() => import("pages/rp/rdmp/RdmpGoalSetting"))
const Rdmp = lazy(() => import("pages/rp/rdmp/Rdmp"))
const ScenarioDetail = lazy(() => import("pages/rp/scnro/ScenarioDetail"))

export const rpRouter: any = [
  {path: "/rp/portfolio/:menuActiveTab?", element: <Portfolio/>,},
  {path: "/rp/roadmap", element: <Roadmap/>,},

  {path: "/rp/matchingTarget", element: <Matching/>,},
  {path: "/rp/matchingSearchList", element: <MatchingSearchList/>,},
  {path: "/rp/matchingSearchDetail/:pwplId", element: <MatchingSearchDetail/>,},

  {path: "/rp/cntrlist", element: <CntrList/>,},
  {path: "/rp/trdnegodetail", element: <TrdNegoDetail/>,},
  {path: "/rp/cntrwriteform", element: <CntrWriteForm/>,},
  {path: "/rp/institutionreg", element: <InstitutionReg/>,},
  {path: "/rp/cntrcomplete", element: <CntrComplete/>,},
  {path: "/rp/cntrreport", element: <CntrReport />},
  {path: "/rp/cntr/complete/report", element: <CntrCompleteReport />},

  // PPA 정산관리
  {path: "/rp/ppa/bills", element: <BillList emksReqdCd={1} />},
  {path: "/rp/ppa/bills/form", element: <BillForm emksReqdCd={1} />},
  {path: "/rp/ppa/bills/detail", element: <BillDetail emksReqdCd={1} />},

  // {path: "/rp/smcnCert", element: <SmcnCertSelfPGList/>},
  {path: "/rp/smcnCertSelfPGList", element: <SmcnCertSelfPGList/>},
  {path: "/rp/smcnCertSelfPGInsert", element: <SmcnCertSelfPGInsert/>},
  {path: "/rp/smcnCertSelfPGDetail/:smcnCertId", element: <SmcnCertSelfPDetail/>},
  {path: "/rp/smcnCertPPAList", element: <SmcnCertPPAList/>},
  {path: "/rp/smcnCertPPAInsert", element: <SmcnCertPPAInsert/>},
  {path: "/rp/smcnCertPPADetail/:smcnCertId", element: <SmcnCertPPADetail/>},
  {path: "/rp/smcnCertRECList", element: <SmcnCertRECList/>},
  {path: "/rp/smcnCertRECInsert", element: <SmcnCertRECInsert/>},
  {path: "/rp/smcnCertRECDetail/:smcnCertRecId", element: <SmcnCertRECDetail/>},
  {path: "/rp/smcnCertGrprList", element: <SmcnCertGrprList/>},
  {path: "/rp/smcnCertGrprInsert", element: <SmcnCertGrprInsert/>},
  {path: "/rp/smcnCertGrprDetail/:smcnCertId", element: <SmcnCertGrprDetail/>},
  {path: "/rp/useConfirmationList", element: <UseConfirmationList />},
  {path: "/rp/useConfirmationInsert", element: <UseConfirmationInsert />},
  {path: "/rp/useConfirmationDetail/:nrnwEnrgUseCfmdId", element: <UseConfirmationDetail />},

  {path: "/rp/rlibr/:menuActiveTab?", element: <RlibrList/>},
  {path: "/rp/rlibrDetail/:menuActiveTab?/:rlibrId", element: <RlibrDetail/>},
  {path: "/rp/rlibrInsert/:menuActiveTab?", element: <RlibrInsert/>},

  {path: "/rp/impleWayAnalysis/:menuActiveTab?", element: <ImplWayAnalysis/>},

  {path: "/rp/entre", element: <EntreInfoPowerList/>},
  {path: "/rp/pwstationlist", element: <EntreInfoPowerList/>},
  {path: "/rp/pwStationInsert", element: <EntrInfoPowerInsert/>},
  {path: "/rp/pwStationDetail/:pwplId", element: <EntrInfoPowerDetail/>},
  {path: "/rp/electricUserList", element: <ElecUserList/>},
  {path: "/rp/electricUserInsert", element: <ElecUserInsert/>},
  {path: "/rp/electricUserDetail/:elecUserId", element: <ElecUserDetail/>},

  {path: "rp/rdmpGoalSetting", element: <RdmpGoalSetting/>},
  {path: "rp/rdmp", element: <Rdmp/>},

  {path: "rp/ScenarioDetail/:scnroId", element: <ScenarioDetail/>},
];