import {ApiUrlUtil} from "../../utils/apiUrlUtil";
import {HeadersUtil} from "../../utils/headersUtil";
import axios, {AxiosResponse} from "axios";
import {PortfolioModel} from "../../model/rp/PortfolioModel";
import * as process from "process";
import Api from "../common/Api";
import {RpCalcModel} from "../../model/rp/RpCalcModel";
import {RlibrModel} from "../../model/rp/RlibrModel";
import {PflInqCntModel} from "../../model/rp/PflInqCntModel";

export class PortfolioService extends Api {
  private static service: PortfolioService;
  private static GET_URL: string = process.env['REACT_APP_URL_RP'] + '/pfl/detail';
  private static POST_URL: string = process.env['REACT_APP_URL_RP'] + '/pfl/insert';
  private static PUT_URL: string = process.env['REACT_APP_URL_RP'] + '/pfl/update';

  private static RECOSCNRO_URL: string = process.env.REACT_APP_URL_RS + '/rpcalc';

  // 조회수
  private static GET_INQ_CNT: string = process.env['REACT_APP_URL_RP'] + '/pfl/detailInqCntCount';
  private static POST_INQ_CNT: string = process.env['REACT_APP_URL_RP'] + '/pfl/insertInqCntCount';

  private static GET_MONTHLY_CNT: string = process.env['REACT_APP_URL_RP'] + '/pfl/monthlyInqCntCount';

  public static getInstance(): PortfolioService {
    if (!PortfolioService.service) {
      PortfolioService.service = new PortfolioService();
    }
    return PortfolioService.service;
  }

  public async getPortfolio() {
    return await super.get<PortfolioModel>(PortfolioService.GET_URL)
  }

  public async insertPortfolio (form: PortfolioModel) {
    // form.append('_method', 'POST')
    return await super.post<PortfolioModel>(PortfolioService.POST_URL, form)
  }

  public async updatePortfolio(form: PortfolioModel) {
    return await super.put<PortfolioModel>(PortfolioService.PUT_URL, form)
  }

  // 추천시나리오 계산
  public async getRpCalc() {
    return (await axios.get(PortfolioService.RECOSCNRO_URL, {
      headers: HeadersUtil.getHeaders()
    })).data;
  }

  public async insertRpCalc(form: RpCalcModel) {
    // return await super.post<RpCalcModel>(PortfolioService.RECOSCNRO_URL, form)
  return (await axios.post(PortfolioService.RECOSCNRO_URL,form,
      {
    headers: HeadersUtil.getHeaders()
  })).data;
  }

  // Admin 대시보드
  // re100 시뮬레이션 이용 건수
  public async getInqCntCount() {
    return await super.get<any>(PortfolioService.GET_INQ_CNT)
  }

  public async saveInqCntCount() {
    return await super.get<PflInqCntModel>(PortfolioService.POST_INQ_CNT)
  }

  // 이번 달, 지난 달 re100 시뮬레이션 이용 건수
  public async getMonthlyInqCntCount() {
    return await super.get<any>(PortfolioService.GET_MONTHLY_CNT)
  }

}