

export class UserMemberPostAdminModel {
    userId?: string;
    emksRe100JoinClCd?:string;
    emksEntClCd?:string;
    emksMbrsClCd ?: string;

    bizrRegNo?: string
    bizrNm?: string;       
    reprNm?: string;   
    coRepNo?:string;
    bizrAddr?:string;
    bizrDtlsAddr?:string;

    tchgrNm?:string;
    tchgrCplcNo?: string;
    tchgrEmailAddr?: string;

    userTmpSavYn?: string;
    repAtflId?: string;
    mktgJoinAgreYn?:string;

    eltrSgntCtt?: any;
    coOfcslAtflId?: string;
    coLogoAtflId?: string;
    userCoNm?: any;
    userCoAddr?: any;
    userCoDtlsAddr?: any;
    bizrRegcAtflId?: any;
    bizrRegcAtflNm?: any;
    aprYn?: any;
    aprDt?: any;
    useYn?: any;

    opbizYmd?: string;
  }