import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import Grid from "@toast-ui/react-grid";
import CountUp from "react-countup";
import ArcGrid, { GridApi } from "components/arc/ArcGrid";

HighchartsMore(Highcharts);

export interface data {
  facCap: number | 0; // 설비용량
  conPowQuan: number | 0; // 계약 전력량
  cases: number | 0; // 건
  traSacAmo: number | 0; // 거래대금
  powSup: number | 0; // 전력공급
}

export default function DbAdminTransaction() {

  // 직접 PPA On-site
  const [ppaOnSite, setPpaOnSite] = useState<data>();

  //직접 PPA Off-site
  const [ppaOffSite, setPpaOffSite] = useState<data>();

  //제3자 PPA
  const [ppaThiPar, setPpaThiPar] = useState<data>();

  let gridRef: any = useRef<HTMLDivElement>();

  useEffect(() => {
    setPpaOnSite({
      'facCap': 10,
      'conPowQuan': 4000,
      'cases': 15,
      'traSacAmo': 50,
      'powSup': 50,
    })
    setPpaOffSite({
      'facCap': 5,
      'conPowQuan': 2000,
      'cases': 11,
      'traSacAmo': 100,
      'powSup': 100,
    })
    setPpaThiPar({
      'facCap': 2,
      'conPowQuan': 6000,
      'cases': 7,
      'traSacAmo': 100,
      'powSup': 100,
    })
  }, [])

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/sub.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("admin");

    return () => {
      document.body.classList.remove("admin");
    };
  }, []);

  const sampleData1 = [
    {
      num: '1',
      value1: 'A태양광',
      value2: '유일',
      date: '2022.2.10 2032.2.09',
      value3: '태양광',
      value4: '200 kWh',
    }, {
      num: '2',
      value1: 'A풍력',
      value2: '제닉스',
      date: '2022.3.11 2033.3.10',
      value3: '풍력',
      value4: '300 kWh',
    }, {
      num: '3',
      value1: 'A바이오',
      value2: '다온',
      date: '2022.5.16 2031.5.15',
      value3: '바이오',
      value4: '50 kWh',
    }, {
      num: '4',
      value1: 'B태양광',
      value2: '인탑스',
      date: '2023.6.10 2033.6.09',
      value3: '태양광',
      value4: '100 kWh',
    }, {
      num: '5',
      value1: 'A태양광',
      value2: '유일',
      date: '2022.2.10 2032.2.09',
      value3: '태양광',
      value4: '200 kWh',
    }
  ];

  const columns1: any = [
    // {
    //   header: 'No',
    //   name: 'num',
    //   align: "center",
    //   width: 50,
    //   whiteSpace: 'normal',
    //   formatter: function (e: any) {
    //     return e.value
    //   },
    // },
    {
      header: '공급기업',
      name: 'value1',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '수요기업',
      name: 'value2',
      width: 80,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '계약기간',
      name: 'date',
      width: 100,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '에너지원',
      name: 'value3',
      width: 70,
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '계약용량',
      name: 'value4',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    }
  ];

  const sampleData2 = [
    {
      num: '1',
      value1: 'C태양광',
      value2: '레몬',
      date: '2020.11.10 2030.11.09',
      value3: '태양광',
      value4: '400 kWh',
    }, {
      num: '2',
      value1: 'A소수력',
      value2: '톱텍',
      date: '2023.7.06 2033.7.05',
      value3: '소수력',
      value4: '100 kWh',
    }, {
      num: '3',
      value1: 'B바이오',
      value2: '이레테크',
      date: '2023.8.14 2033.8.13',
      value3: '바이오',
      value4: '80 kWh',
    }, {
      num: '4',
      value1: 'B풍력',
      value2: '서로공정',
      date: '2023.4.21 2033.4.20',
      value3: '풍력',
      value4: '500 kWh',
    }, {
      num: '5',
      value1: 'A태양광',
      value2: '유일',
      date: '2022.2.10 2032.2.09',
      value3: '태양광',
      value4: '200 kWh',
    }
  ];

  const columns2: any = [
    // {
    //   header: 'No',
    //   name: 'num',
    //   align: "center",
    //   width: 50,
    //   whiteSpace: 'normal',
    //   formatter: function (e: any) {
    //     return e.value
    //   },
    // },
    {
      header: '공급기업',
      name: 'value1',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '수요기업',
      name: 'value2',
      align: "center",
      width: 80,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '계약기간',
      name: 'date',
      align: "center",
      width: 100,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '에너지원',
      name: 'value3',
      align: "center",
      width: 70,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '계약용량',
      name: 'value4',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    }
  ];

  const sampleData3 = [
    {
      num: '1',
      value1: 'D태양광',
      value2: '원진',
      date: '2022.12.10 2032.12.09',
      value3: '태양광',
      value4: '100 kWh',
    }, {
      num: '2',
      value1: 'E태양광',
      value2: '서린',
      date: '2022.10.05 2032.10.04',
      value3: '태양광',
      value4: '200 kWh',
    }, {
      num: '3',
      value1: 'C바이오',
      value2: '엠시스',
      date: '2023.3.13 2033.3.12',
      value3: '바이오',
      value4: '70 kWh',
    }, {
      num: '4',
      value1: 'F태양광',
      value2: '노블금속',
      date: '2022.6.22 2032.6.21',
      value3: '태양광',
      value4: '300 kWh',
    }, {
      num: '5',
      value1: 'A태양광',
      value2: '유일',
      date: '2022.2.10 2032.2.09',
      value3: '태양광',
      value4: '200 kWh',
    }
  ];

  const columns3: any = [
    // {
    //   header: 'No',
    //   name: 'num',
    //   align: "center",
    //   width: 50,
    //   whiteSpace: 'normal',
    //   formatter: function (e: any) {
    //     return e.value
    //   },
    // },
    {
      header: '공급기업',
      name: 'value1',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '수요기업',
      name: 'value2',
      align: "center",
      width: 80,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '계약기간',
      name: 'date',
      align: "center",
      width: 101,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '에너지원',
      name: 'value3',
      align: "center",
      width: 70,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '계약용량',
      name: 'value4',
      align: "center",
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    }
  ]

  const gridDatasource1: GridApi = {
    readData: { url: '/api/grid/data1', method: 'GET' },
    rootUrl: 'http://localhost:5000'
  }

  const gridDatasource2: GridApi = {
    readData: { url: '/api/grid/data2', method: 'GET' },
    rootUrl: 'http://localhost:5000'
  }

  const gridDatasource3: GridApi = {
    readData: { url: '/api/grid/data3', method: 'GET' },
    rootUrl: 'http://localhost:5000'
  }

  function duration(data: any) {
    // if (data < 10) {
    //   return 2;
    // } else {
    //   return 1;
    // }
    return 0.5;
  }

  return (
    <>
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="col-wrap">
                <div className="col-md-8 pdr30">
                  <h3 className="titT1">재생E 계약 현황</h3>
                  <div className="icon-box-wrap11">
                    <div className="cnt-tit">
                      <strong>직접 PPA On-site</strong>
                      <ul>
                        <li className="volume">
                          <span>설비용량</span>
                          <strong>
                            <CountUp end={Number(ppaOnSite?.facCap) ?? 0} duration={duration(ppaOnSite?.facCap)} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li className="power">
                          <span>계약 전력량</span>
                          <strong>
                            {/* <em data-count="4000" className="change"> </em> */}
                            <CountUp end={Number(ppaOnSite?.conPowQuan) ?? 0} duration={duration(ppaOnSite?.conPowQuan)} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li className="number">
                          <em>
                            {/* <strong data-count="15" className="change">  </strong> */}
                            <CountUp end={Number(ppaOnSite?.cases) ?? 0} duration={duration(ppaOnSite?.cases)} >
                              {({ countUpRef }) => <strong ref={countUpRef} />}
                            </CountUp>
                            건
                          </em>
                        </li>
                      </ul>
                    </div>

                    <div className="cnt-ani">
                      <div className="tg1">
                        <strong>재생E 전기사용자</strong>
                      </div>
                      <div className="tg-ani">
                        <span className="ani1 r-ani count-vertical">
                          <em style={{ width: `${ppaOnSite?.traSacAmo}%` }} className='finish'>
                            <span>거래대금</span>
                          </em>
                        </span>
                        <span className="ani1 l-ani count-vertical">
                          <em style={{ width: `${ppaOnSite?.powSup}%` }} className='finish'>
                            <span>전력공급</span>
                          </em>
                        </span>
                      </div>
                      <div className="tg2">
                        <strong>재생E 발전사업자</strong>
                      </div>
                    </div>
                  </div>

                  <div className="icon-box-wrap11">
                    <div className="cnt-tit">
                      <strong>직접 PPA Off-site</strong>
                      <ul>
                        <li className="volume">
                          <span>설비용량</span>
                          <strong>
                            {/* <em data-count="5" className="change"> </em> */}
                            <CountUp end={Number(ppaOffSite?.facCap) ?? 0} duration={duration(ppaOffSite?.facCap)} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li className="power">
                          <span>계약 전력량</span>
                          <strong>
                            {/* <em data-count="2000" className="change">  </em> */}
                            <CountUp end={Number(ppaOffSite?.conPowQuan) ?? 0} duration={duration(ppaOffSite?.conPowQuan)} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li className="number">
                          <em>
                            {/* <strong data-count="11" className="change"> </strong> */}
                            <CountUp end={Number(ppaOffSite?.cases) ?? 0} duration={duration(ppaOffSite?.cases)} >
                              {({ countUpRef }) => <strong ref={countUpRef} />}
                            </CountUp>
                            건
                          </em>
                        </li>
                      </ul>
                    </div>
                    <div className="cnt-ani">
                      <div className="tg1">
                        <strong>재생E 전기사용자</strong>
                      </div>
                      <div className="tg-ani tg-ani-ct">
                        <span className="ani1 r-ani count-vertical">
                          <em style={{ width: `${ppaOffSite?.traSacAmo}%` }} className='finish'>
                            <span>거래대금</span>
                          </em>
                        </span>
                        <span className="ani1 l-ani count-vertical">
                          <em style={{ width: `${ppaOffSite?.powSup}%` }} className='finish'>
                            <span>전력공급</span>
                          </em>
                        </span>
                        <span className="ani2">
                          <img
                            src={require("../../assets/images/sub/icon_box_wrap_icon27.png")}
                            alt=""
                          />
                          <em>한전 송배전망</em>
                        </span>
                      </div>
                      <div className="tg2">
                        <strong>재생E 발전사업자</strong>
                      </div>
                    </div>
                  </div>

                  <div className="icon-box-wrap11">
                    <div className="cnt-tit">
                      <strong>제3자 PPA</strong>
                      <ul>
                        <li className="volume">
                          <span>설비용량</span>
                          <strong>
                            {/* <em data-count="2" className="change">    </em> */}
                            <CountUp end={Number(ppaThiPar?.facCap) ?? 0} duration={duration(ppaThiPar?.facCap)} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li className="power">
                          <span>계약 전력량</span>
                          <strong>
                            {/* <em data-count="6000" className="change"> </em> */}
                            <CountUp end={Number(ppaThiPar?.conPowQuan) ?? 0} duration={duration(ppaThiPar?.conPowQuan)} >
                              {({ countUpRef }) => <em ref={countUpRef} />}
                            </CountUp>
                            MW
                          </strong>
                        </li>
                        <li className="number">
                          <em>
                            {/* <strong data-count="7" className="change">    </strong> */}
                            <CountUp end={Number(ppaThiPar?.cases) ?? 0} duration={duration(ppaThiPar?.cases)} >
                              {({ countUpRef }) => <strong ref={countUpRef} />}
                            </CountUp>
                            건
                          </em>
                        </li>
                      </ul>
                    </div>
                    <div className="cnt-ani">
                      <div className="tg1">
                        <strong>재생E 전기사용자</strong>
                      </div>
                      <div className="tg-ani tg-ani-ct">
                        <span className="ani1 r-ani count-vertical">
                          <em style={{ width: `${ppaThiPar?.traSacAmo}%` }} className='finish'>
                            <span>거래대금</span>
                          </em>
                        </span>
                        <span className="ani1 l-ani count-vertical">
                          <em style={{ width: `${ppaThiPar?.powSup}%` }} className='finish'>
                            <span>전력공급</span>
                          </em>
                        </span>
                        <span className="ani2">
                          <img
                            src={require("../../assets/images/sub/icon_box_wrap_icon28.png")}
                            alt=""
                          />
                          <em>한전 에너지마켓 플레이스</em>
                        </span>
                      </div>
                      <div className="tg2">
                        <strong>재생E 발전사업자</strong>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <h3 className="titT1">기업 계약 목록</h3>
                  <div className="box-style5 mgt0">
                    <div id="gridTbl1">
                      {/* <Grid
                        data={sampleData1}
                        columns={columns1}
                        rowHeight={"auto"}
                        bodyHeight={232}
                        scrollX={false}
                        scrollY={true}
                        draggable={false}
                      /> */}
                      <ArcGrid gridRef={gridRef}
                        datasource={gridDatasource1}
                        columns={columns1}
                        useGridInfoHeader={true}
                        isTest={true}
                      />
                    </div>
                  </div>
                  <div className="box-style5 mgt10">
                    <div id="gridTbl2">
                      {/* <Grid
                        data={sampleData2}
                        columns={columns2}
                        rowHeight={"auto"}
                        bodyHeight={232}
                        scrollX={false}
                        scrollY={true}
                        draggable={false}
                      /> */}
                      <ArcGrid gridRef={gridRef}
                        datasource={gridDatasource2}
                        columns={columns2}
                        useGridInfoHeader={true}
                        isTest={true}
                      />
                    </div>
                  </div>
                  <div className="box-style5 mgt10">
                    <div id="gridTbl3">
                      {/* <Grid
                        data={sampleData3}
                        columns={columns3}
                        rowHeight={"auto"}
                        bodyHeight={232}
                        scrollX={false}
                        scrollY={true}
                        draggable={false}
                      /> */}
                      <ArcGrid gridRef={gridRef}
                        datasource={gridDatasource3}
                        columns={columns3}
                        useGridInfoHeader={true}
                        isTest={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
