import React from "react";
import i18n from "../../config/translation/i18n";
import {DefaultTFuncReturn} from "i18next";

interface Type {
  text: TypeDetail;
  search: TypeDetail;
  reset: TypeDetail
  save: TypeDetail;
  regist: TypeDetail;
  submit: TypeDetail;
  detail: TypeDetail;
  modify: TypeDetail;
  delete: TypeDetail;
  cancel: TypeDetail;
  confirm: TypeDetail;
  download: TypeDetail;
  print: TypeDetail;
  calendar: TypeDetail;
  setting: TypeDetail;
  list: TypeDetail;
  next: TypeDetail;
  prev: TypeDetail;
  payment: TypeDetail;
  custom: TypeDetail;
}

interface TypeDetail {
  color: string | null,
  text: string | null,
  icon: string | null,
}

function isTypeDetail(object: any): object is TypeDetail {
  return typeof object === "object" && 'color' in object && 'text' in object && 'icon' in object;
}

type ButtonClass = {
  [key in keyof Type]: TypeDetail;
}

export const ButtonColor = {
  btn1: 'btn-style1',
  btn2: 'btn-style2',
  btn3: 'btn-style3',
  btn4: 'btn-style4',
  btn5: 'btn-style5',
  btn6: 'btn-style6',
  btn7: 'btn-style7',
  btn8: 'btn-style8',
  btn9: 'btn-style9',
  btn10: 'btn-style10',
  btn11: 'btn-style11',
} as const;


export const ButtonType: ButtonClass = {
  text: {
    color: ButtonColor.btn1,
    text: null,
    icon: 'xi-plus',
  },
  search: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.search'),
    icon: 'las la-search',
  },
  reset: {
    color: ButtonColor.btn1,
    text: i18n.t('common.button.reset'),
    icon: 'las la-redo-alt',
  },
  save: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.save'),
    icon: 'las la-save'
  },
  regist: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.regist'),
    icon: 'las la-pen',
  },
  submit: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.save'),
    icon: 'las la-save',
  },
  detail: {
    color: ButtonColor.btn1,
    text: i18n.t('common.button.detail'),
    icon: 'las la-share',
  },
  modify: {
    color: ButtonColor.btn3,
    text: i18n.t('common.button.modify'),
    icon: 'las la-edit',
  },
  delete: {
    color: ButtonColor.btn4,
    text: i18n.t('common.button.delete'),
    icon: 'las la-trash-alt',
  },
  cancel: {
    color: ButtonColor.btn5,
    text: i18n.t('common.button.cancel'),
    icon: 'las la-times',
  },
  confirm: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.confirm'),
    icon: 'las la-check',
  },
  download: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.download'),
    icon: 'las la-download',
  },
  print: {
    color: ButtonColor.btn6,
    text: i18n.t('common.button.print'),
    icon: 'las la-print',
  },
  calendar: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.calendar'),
    icon: 'las la-calendar',
  },
  setting: {
    color: ButtonColor.btn6,
    text: i18n.t('common.button.setting'),
    icon: 'las la-cog',
  },
  list: {
    color: ButtonColor.btn1,
    text: i18n.t('common.button.list'),
    icon: 'las la-list'
  },
  next: {
    color: ButtonColor.btn2,
    text: i18n.t('common.button.next'),
    icon: 'las la-angle-right',
  },
  prev: {
    color: ButtonColor.btn5,
    text: i18n.t('common.button.prev'),
    icon: 'las la-angle-left',
  },
  payment: {
    color: null,
    text: i18n.t('common.button.billPayment'),
    icon: 'las la-clipboard',
  },
  custom: {
    color: null, text: null, icon: null,
  }
} as const;

export const ButtonShape = {
  normal: '',
  round: 'btn-round',
  circleText: 'circle-btn-text',
  circleIcon: 'circle-btn-icon',
} as const;

export const ButtonSize = {
  small: 'btn-small',
  regular: '',
  large: 'btn-lage'
} as const;

export enum ButtonIcon {
  plus = 'xi-plus',
  search = 'xi-search',
  check = 'xi-check',
  close = 'xi-close',
  delete = 'las la-trash-alt',
  regist = 'las la-pen',
  modify = 'las la-edit',
  calendar = 'las la-calendar',
  reset = 'las la-redo-alt',
  enter = 'las la-share',
  save = 'las la-save',
  setting = 'xi-cog',
  download = 'las la-download',
  pencil = "las la-pen",
  tag = "las la-tags",
  print = "las la-print",
  clipboard = "las la-clipboard",
  list = "las la-list",
  next = "las la-angle-right",
  prev = "las la-angle-left",
  downArrow = "las la-angle-down",
  prevAlt= "xi-angle-left",
  nextAlt= "xi-angle-right",
  paperclip = "las la-paperclip",
  upload = "xi-file-add"
}

type ButtonTypeKey = keyof typeof ButtonType;
type ButtonType = typeof ButtonType[keyof typeof ButtonType];
type ButtonColor = typeof ButtonColor[keyof typeof ButtonColor];
type ButtonShape = typeof ButtonShape[keyof typeof ButtonShape];
type ButtonSize = typeof ButtonSize[keyof typeof ButtonSize];

export interface ButtonProps {
  type?: ButtonType | ButtonTypeKey;
  className?: string;
  onClick?: (data?: any) => void;
  color?: ButtonColor;
  size?: ButtonSize;
  hasIcon?: boolean;
  text?: string | DefaultTFuncReturn;
  isDisabled?: boolean;
  shape?: ButtonShape;
  icon?: ButtonIcon;
  title?: string;
}

export default function ArcButton(props: ButtonProps) {
  const {
    type = "custom",
    className,
    onClick,
    color,
    size,
    hasIcon,
    text,
    isDisabled,
    shape,
    icon,
  } = props

  const buttonType = isTypeDetail(type) ? type : ButtonType[type];
  const buttonColor = color ?? buttonType['color'];
  const buttonClassNames: string = makeButtonClassName();

  function makeButtonClassName() {
    let buttonClass = '';
    buttonClass += buttonColor ? buttonColor + ' ' : ''
    buttonClass += className ? className + ' ' : '';
    buttonClass += shape ? shape + ' ' : '';
    buttonClass += size ? size + ' ' : '';
    buttonClass += isDisabled ? 'btn-style-disable' + ' ' : '';
    return buttonClass;
  }

  let buttonIcon = '';
  if (hasIcon) {
    buttonIcon = buttonType['icon'] ?? '';
  }

  const handleClickButton = (data?: any) => {
    data.preventDefault();
    if (!isDisabled && onClick) {
      onClick();
    }
  }

  return (
      <a href="#" className={buttonClassNames} onClick={handleClickButton} title={props.title ?? text ?? buttonType['text'] ?? ''}>
        <span>
          {hasIcon ? (<i className={icon ?? buttonIcon} aria-hidden={true}></i>) : <></>}
          {text ?? buttonType['text'] ?? ''}
        </span>
      </a>
  )
}