import React from 'react'

export default function ServerError() {
  const moveToBack = () => history.back();

  return (
    <div id="wrap">
      <div className="error">
        <div className="error-type2">
          <img src={require('assets/images/sub/error500.png')} alt="server error"/>
          <div className="error-tit">
            <strong>요청 처리 중 오류가 발생했습니다.</strong>
            <span><a href={'javascript:;'} onClick={moveToBack}>이전 페이지로 되돌아가기</a></span>
          </div>
        </div>
      </div>
    </div>
  )
}
