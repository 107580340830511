import { createSlice } from "@reduxjs/toolkit";
import { CSSProperties, ReactNode } from "react";
import { toast } from "react-toastify";
import { DefaultTFuncReturn } from "i18next";

type ModalActionType = {
  type: string;
  payload: ModalType | undefined;
};

export interface ModalProps {
  title?: string | DefaultTFuncReturn | undefined;
  data?: any;
  onModify?: (data: any) => void;
  onClose: (...args: any[]) => void;
}

export type ConfirmType = "confirm" | "alert" | "delete" | "save" | "status";
export interface ConfirmProps {
  type: ConfirmType;
  title?: string | DefaultTFuncReturn | undefined;
  message?: ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
}

export interface ModalType {
  path: string;
  target: (props: any) => JSX.Element;
  wrapStyle?: CSSProperties;
  modalProps?: ModalProps;
  confirmProps?: ConfirmProps;
}

const positionStyle = (
  position: any,
  top: any,
  bottom: any,
  left: any,
  right: any,
  transform: any
) => ({
  position: position,
  top: top,
  bottom: bottom,
  left: left,
  right: right,
  transform: transform,
});

export const center = positionStyle("", "", "", "", "", "");
export const topLeft = positionStyle(
  "fixed",
  "0",
  "",
  "0",
  "",
  "translate(0%, -0%)"
);
export const topMiddle = positionStyle(
  "fixed",
  "0",
  "",
  "50%",
  "",
  "translate(-50%, 0%)"
);
export const topRight = positionStyle(
  "fixed",
  "0",
  "",
  "100%",
  "",
  "translate(-100%, 0%)"
);
export const middleLeft = positionStyle(
  "fixed",
  "50%",
  "",
  "0",
  "",
  "translate(0%, -50%)"
);
export const middleRight = positionStyle(
  "fixed",
  "50%",
  "",
  "100%",
  "",
  "translate(-100%, -50%)"
);
export const bottomLeft = positionStyle(
  "fixed",
  "100%",
  "",
  "0",
  "",
  "translate(0%, -100%)"
);
export const bottomMiddle = positionStyle(
  "fixed",
  "100%",
  "",
  "50%",
  "",
  "translate(-50%, -100%)"
);
export const bottomRight = positionStyle(
  "fixed",
  "100%",
  "",
  "100%",
  "",
  "translate(-100%, -100%)"
);

const initialState: any = {
  modals: [],
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action: ModalActionType) => {
      if (action.payload) {
        const { path, target, wrapStyle, modalProps, confirmProps }: ModalType =
          action.payload;
        state.modals = state.modals.concat({
          path,
          target,
          wrapStyle,
          modalProps,
          confirmProps,
        });
      } else {
        toast.error("모달 정보가 없습니다.");
      }
    },
    closeModal: (state) => {
      if (state.modals.length > 1) {
        state.modals.pop();
      } else {
        state.modals = [];
      }
    },
    closeAllModal: (state) => {
      state.modals = [];
    },
    reloadGrid: (state) => {
      state.reloadGrid = !state.reloadGrid; // Toggle a boolean to trigger a re-render
    },
  },
});

export const { openModal, closeModal, closeAllModal, reloadGrid } =
  modalSlice.actions;
export default modalSlice.reducer;
