import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { EmissionService } from "../../../services/cp/EmissionService";
import ArcButton, { ButtonColor, ButtonIcon, ButtonType } from "../../../components/arc/ArcButton";
import Board from "./Board";
import { toast } from "react-toastify";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";
import { ResponseResultCode } from "model/response-api";

interface EmissionData {
    mm1EmsnQnt: number;
    mm2EmsnQnt: number;
    mm3EmsnQnt: number;
    mm4EmsnQnt: number;
    mm5EmsnQnt: number;
    mm6EmsnQnt: number;
    mm7EmsnQnt: number;
    mm8EmsnQnt: number;
    mm9EmsnQnt: number;
    mm10EmsnQnt: number;
    mm11EmsnQnt: number;
    mm12EmsnQnt: number;
    totalQnt: number;
}

interface EmissionResponse {
    thisYear: EmissionData;
    lastResult: EmissionData;
    yearBeforeLastResult: EmissionData;
}

export default function YearEmissions() {

    const toDayYear = new Date().getFullYear();
    const lastYear = new Date().getFullYear() - 1;
    const yearBeforeLast = new Date().getFullYear() - 2;
    // 초기 셋팅
    const [chartOptionsC, setChartOptionsC] = useState<Highcharts.Options>({
        colors: ["#2970CD"],
        chart: {
            type: "column",
            height: 190,
            marginLeft: 55,
            spacingTop: 0,
            spacingBottom: 0,
            zooming: {
                type: undefined,
                mouseWheel: {
                    enabled: false
                }
            }
        },
        title: {
            text: "",
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointWidth: 90,
                borderRadius: 0,
                borderWidth: 0,
            },
        },
        tooltip: {
            headerFormat: "{point.key}<br/>",
            split: false,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            enabled: true,
            symbolHeight: 10,
            symbolRadius: 0,
            align: "right",
            verticalAlign: "top",
            itemStyle: {
                color: "#505050",
                fontSize: "14px",
            },
            itemHoverStyle: {
                color: "#000",
                fontSize: "14px",
            },
        },
        xAxis: {
            categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            crosshair: true,
            lineColor: "#E1E1E1",
            title: { text: "" },
            labels: {
                style: {
                    color: '#555',
                    fontSize: '14px'
                }
            },
            type: "category"
        },
    });

    const [chartOptionsD, setChartOptionsD] = useState<Highcharts.Options>({
        colors: ["#24ACC5"],
        chart: {
            type: "column",
            height: 190,
            marginLeft: 55,
            spacingTop: 0,
            spacingBottom: 0,
            zooming: {
                type: undefined,
                mouseWheel: {
                    enabled: false
                }
            }
        },
        title: {
            text: "",
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointWidth: 90,
                borderRadius: 0,
                borderWidth: 0,
            },
        },
        tooltip: {
            headerFormat: "{point.key}<br/>",
            split: false,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            enabled: true,
            symbolHeight: 10,
            symbolRadius: 0,
            align: "right",
            verticalAlign: "top",
            itemStyle: {
                color: "#505050",
                fontSize: "14px",
            },
            itemHoverStyle: {
                color: "#000",
                fontSize: "14px",
            },
        },
        xAxis: {
            categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            crosshair: true,
            lineColor: "#E1E1E1",
            title: { text: "" },
            labels: {
                style: {
                    color: '#555',
                    fontSize: '14px'
                }
            },
            type: "category"
        },
    });

    const [chartOptionsE, setChartOptionsE] = useState<Highcharts.Options>({
        colors: ["#00BB9F"],
        chart: {
            type: "column",
            height: 190,
            marginLeft: 55,
            spacingTop: 0,
            spacingBottom: 0,
            zooming: {
                type: undefined,
                mouseWheel: {
                    enabled: false
                }
            }
        },
        title: {
            text: "",
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointWidth: 90,
                borderRadius: 0,
                borderWidth: 0,
            },
        },
        tooltip: {
            headerFormat: "{point.key}<br/>",
            split: false,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            enabled: true,
            symbolHeight: 10,
            symbolRadius: 0,
            align: "right",
            verticalAlign: "top",
            itemStyle: {
                color: "#505050",
                fontSize: "14px",
            },
            itemHoverStyle: {
                color: "#000",
                fontSize: "14px",
            },
        },
        xAxis: {
            categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            crosshair: true,
            lineColor: "#E1E1E1",
            title: { text: "" },
            labels: {
                style: {
                    color: '#555',
                    fontSize: '14px'
                }
            },
            type: "category"
        },
    });

    const [thisData, setThisData] = useState<number[]>([]);
    const [lastData, setLastData] = useState<number[]>([]);
    const [yearBeforeLastData, setYearBeforeLastData] = useState<number[]>([]);

    const [thisQnt, setThisQnt] = useState(0);
    const [lastQnt, setLastQnt] = useState(0);
    const [yearBeforeLastQnt, setYearBeforeLastQnt] = useState(0);

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        try {
            const response = await EmissionService.getInstance().getYearEmissiopnStatus();
            if (response && response.resultCode === ResponseResultCode.Y && response.data) {
                const { thisYear, lastResult, yearBeforeLastResult } = response.data as EmissionResponse;

                // 데이터 추출
                const thisYearData = Object.values(thisYear).slice(0, 12) as number[];
                const lastYearData = Object.values(lastResult).slice(0, 12) as number[];
                const yearBeforeLastData = Object.values(yearBeforeLastResult).slice(0, 12) as number[];

                // 합계
                setThisQnt(thisYear.totalQnt);
                setLastQnt(lastResult.totalQnt);
                setYearBeforeLastQnt(yearBeforeLastResult.totalQnt);

                setThisData(thisYearData);
                setLastData(lastYearData);
                setYearBeforeLastData(yearBeforeLastData);

                // 차트
                setChartOptionsC(getChartOptionsC(thisYearData, toDayYear.toString()));
                setChartOptionsD(getChartOptionsD(lastYearData, lastYear.toString()));
                setChartOptionsE(getChartOptionsE(yearBeforeLastData, yearBeforeLast.toString()));
            } else {
                toast.error("시스템 오류가 발생하였습니다.");
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            toast.error("시스템 오류가 발생하였습니다.");
        }
    }

    const getChartOptionsC = (data: number[], seriesName: string): Highcharts.Options => ({
        colors: ["#2970CD"],
        chart: {
            type: "column",
            height: 190,
            marginLeft: 55,
            spacingTop: 0,
            spacingBottom: 0,
            zooming: {
                type: undefined,
                mouseWheel: {
                    enabled: false
                }
            }
        },
        title: {
            text: "",
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointWidth: 90,
                borderRadius: 0,
                borderWidth: 0,
            },
            series: {
                stacking: "normal",
                dataLabels: {
                    inside: true,
                    enabled: true,
                    shadow: true,
                    style: {
                        color: '#fff',
                        textOutline: 'none'
                    },
                    formatter: function () {
                        return `<span><strong>${this.y && this.y !== 0 ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`;
                    },
                },
            },
        },
        tooltip: {
            headerFormat: "{point.key}<br/>",
            split: false,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            enabled: true,
            symbolHeight: 10,
            symbolRadius: 0,
            align: "right",
            verticalAlign: "top",
            itemStyle: {
                color: "#505050",
                fontSize: "14px",
            },
            itemHoverStyle: {
                color: "#000",
                fontSize: "14px",
            },
        },
        xAxis: {
            categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            crosshair: true,
            lineColor: "#E1E1E1",
            title: { text: "" },
            labels: {
                style: {
                    color: '#555',
                    fontSize: '14px'
                }
            }
        },
        yAxis: {
            opposite: false,
            showLastLabel: true,
            offset: 0,
            min: 0,
            softMax: 10,
            lineColor: "#E1E1E1",
            title: {
                text: '(tCO2eq)',
                style: {
                    color: '#555',
                    fontSize: '12px'
                },
                align: 'high',
                rotation: 0,
                offset: 0,
                y: -25,
                x: -5
            },
            labels: {
                y: 3,
                distance: 7,
                style: {
                    color: '#555',
                    fontSize: '14px',
                },
                formatter: function (this: any) {
                    return parseNumberWithCommaDecimal(this.value);
                },
            },
        },
        series: [{
            type: "column",
            name: seriesName,
            data: data.map((v: any) => Number(v.toFixed(2)))
        }]
    });

    const getChartOptionsD = (data: number[], seriesName: string): Highcharts.Options => ({
        colors: ["#24ACC5"],
        chart: {
            type: "column",
            height: 190,
            marginLeft: 55,
            spacingTop: 0,
            spacingBottom: 0,
            zooming: {
                type: undefined,
                mouseWheel: {
                    enabled: false
                }
            }
        },
        title: {
            text: "",
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointWidth: 90,
                borderRadius: 0,
                borderWidth: 0,
            },
            series: {
                stacking: "normal",
                dataLabels: {
                    inside: true,
                    enabled: true,
                    shadow: true,
                    style: {
                        color: '#fff',
                        textOutline: 'none'
                    },
                    formatter: function () {
                        return `<span><strong>${this.y && this.y !== 0 ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`;
                    },
                },
            },
        },
        tooltip: {
            headerFormat: "{point.key}<br/>",
            split: false,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            enabled: true,
            symbolHeight: 10,
            symbolRadius: 0,
            align: "right",
            verticalAlign: "top",
            itemStyle: {
                color: "#505050",
                fontSize: "14px",
            },
            itemHoverStyle: {
                color: "#000",
                fontSize: "14px",
            },
        },
        xAxis: {
            categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            crosshair: true,
            lineColor: "#E1E1E1",
            title: { text: "" },
            labels: {
                style: {
                    color: '#555',
                    fontSize: '14px'
                }
            }
        },
        yAxis: {
            opposite: false,
            showLastLabel: true,
            offset: 0,
            min: 0,
            softMax: 10,
            lineColor: "#E1E1E1",
            title: {
                text: '(tCO2eq)',
                style: {
                    color: '#555',
                    fontSize: '12px'
                },
                align: 'high',
                rotation: 0,
                offset: 0,
                y: -25,
                x: -5
            },
            labels: {
                y: 3,
                distance: 7,
                style: {
                    color: '#555',
                    fontSize: '14px',
                },
                formatter: function (this: any) {
                    return parseNumberWithCommaDecimal(this.value);
                },
            },
        },
        series: [{
            type: "column",
            name: seriesName,
            data: data.map((v: any) => Number(v.toFixed(2)))
        }]
    });

    const getChartOptionsE = (data: number[], seriesName: string): Highcharts.Options => ({
        colors: ["#00BB9F "],
        chart: {
            type: "column",
            height: 190,
            marginLeft: 55,
            spacingTop: 0,
            spacingBottom: 0,
            zooming: {
                type: undefined,
                mouseWheel: {
                    enabled: false
                }
            }
        },
        title: {
            text: "",
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        navigator: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointWidth: 90,
                borderRadius: 0,
                borderWidth: 0,
            },
            series: {
                stacking: "normal",
                dataLabels: {
                    inside: true,
                    enabled: true,
                    shadow: true,
                    style: {
                        color: '#fff',
                        textOutline: 'none'
                    },
                    formatter: function () {
                        return `<span><strong>${this.y && this.y !== 0 ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`;
                    },
                },
            },
        },
        tooltip: {
            headerFormat: "{point.key}<br/>",
            split: false,
            style: {
                fontSize: '14px'
            }
        },
        legend: {
            enabled: true,
            symbolHeight: 10,
            symbolRadius: 0,
            align: "right",
            verticalAlign: "top",
            itemStyle: {
                color: "#505050",
                fontSize: "14px",
            },
            itemHoverStyle: {
                color: "#000",
                fontSize: "14px",
            },
        },
        xAxis: {
            categories: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
            crosshair: true,
            lineColor: "#E1E1E1",
            title: { text: "" },
            labels: {
                style: {
                    color: '#555',
                    fontSize: '14px'
                }
            }
        },
        yAxis: {
            opposite: false,
            showLastLabel: true,
            offset: 0,
            min: 0,
            softMax: 10,
            lineColor: "#E1E1E1",
            title: {
                text: '(tCO2eq)',
                style: {
                    color: '#555',
                    fontSize: '12px'
                },
                align: 'high',
                rotation: 0,
                offset: 0,
                y: -25,
                x: -5
            },
            labels: {
                y: 3,
                distance: 7,
                style: {
                    color: '#555',
                    fontSize: '14px',
                },
                formatter: function (this: any) {
                    return parseNumberWithCommaDecimal(this.value);
                },
            },
        },
        series: [{
            type: "column",
            name: seriesName,
            data: data.map((v: any) => Number(v.toFixed(2)))
        }]
    });

    const [isDetail, setIsDetail] = useState(false);
    const clickBackBtn = () => {
        setIsDetail(true);
    };
    return (
        <>
            {!isDetail ? (
                <div className="container">
                    <section className="section active">
                        <div style={{ display: "block", float: "right" }}>
                            <ArcButton
                                type={ButtonType.custom}
                                text={"뒤로가기"}
                                hasIcon={true}
                                color={ButtonColor.btn1}
                                icon={ButtonIcon.reset}
                                onClick={clickBackBtn}
                            />
                        </div>
                        <br />
                        <br />

                        <div className="col-wrap">
                            <div className="col-md-12">
                                <div className="cont">
                                    <div className="tit-btns-wrap">
                                        <h3 className="titT1">연도별 배출량 (3개년)</h3>
                                    </div>

                                    <div className="box-graph5 no-scroll pdb20">
                                        <ul>
                                            <li>
                                                <div className="scope" style={{ width: "200px" }}>
                                                    <span>{toDayYear}</span>
                                                    <div className="cnt">
                                                        <strong>
                                                            <em data-count={thisQnt}
                                                                className="change">{parseFloat(thisQnt.toFixed(2)).toLocaleString() ?? ""}</em>tCO2eq
                                                        </strong>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="chart">
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={chartOptionsC}
                                                        constructorType={"stockChart"}
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="scope" style={{ width: "200px" }}>
                                                    <span>{lastYear}</span>
                                                    <div className="cnt">
                                                        <strong>
                                                            <em data-count={lastQnt}
                                                                className="change">{parseFloat(lastQnt.toFixed(2)).toLocaleString() ?? ""}</em>tCO2eq
                                                        </strong>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="chart">
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={chartOptionsD}
                                                        constructorType={"stockChart"}
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="scope" style={{ width: "200px" }}>
                                                    <span>{yearBeforeLast}</span>
                                                    <div className="cnt">
                                                        <strong>
                                                            <em data-count={yearBeforeLastQnt}
                                                                className="change">{parseFloat(yearBeforeLastQnt.toFixed(2)).toLocaleString() ?? ""}</em>tCO2eq
                                                        </strong>
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <div className="chart">
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={chartOptionsE}
                                                        constructorType={"stockChart"}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ) : (
                <Board />
            )}
        </>
    );
}