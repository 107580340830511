
import {Fragment, useEffect, useState} from "react"
import ArcButton, {ButtonColor, ButtonShape, ButtonSize, ButtonType} from "../../components/arc/ArcButton";
import CheckboxSample from "../arc/ArcCheckboxExample";
import RadioboxSample from "../arc/ArcRadioButtonExample";
import TableSample from "../arc/ArcTableExample";
import ArcInputExample from "../arc/ArcInputExample";
import ArcDatePickerExample from "../arc/ArcDatePickerExample";
// import CircleUtilities from "highcharts/dashboards/es-modules/Core/Geometry/CircleUtilities";
// import round = CircleUtilities.round;
import ArcSelectbox , {SelectboxSize} from "../../components/arc/ArcSelectbox";
import ArcTextAreaExample from "../arc/ArcTextAreaExample";
import ArcDownload, {dataSourceProps} from "../../components/arc/ArcDownload";


export default function SamplePage() {


    useEffect(() => {


    }, [])


  const [example, setExample] = useState([
      {label:"option1", value:"value1"},
      {label: "option2", value: "value2"},
      {label: "option3", value: "value3"},
      {label: "option4", value: "value4"},
  ]);

    const files: dataSourceProps[] = [{
        saveName: 'file-1708908858587.txt',
        realName: 'file-1708908858587.txt'
    }];
  const handleChangeSelect = (event: any) => {
  }

    function handleDownload() {

    }

    return (
        <>
            <div>
                <h3>Button</h3>
                <ArcButton type={ButtonType.custom} color={ButtonColor.btn11} text={"커스텀"} shape={ButtonShape.round}
                           size={ButtonSize.regular}/>
                <ArcButton type={"confirm"} hasIcon={true}/>
                <ArcButton type={ButtonType.cancel} hasIcon={true}/>
                <ArcButton type={"search"} hasIcon={true}/>
                <ArcButton type={"reset"} hasIcon={true}/>
                <ArcButton type={"regist"} hasIcon={true}/>
                <ArcButton type={"submit"} isDisabled={true} hasIcon={true}/>
                <ArcButton type={"detail"} hasIcon={true}/>
                <ArcButton type={"modify"} hasIcon={true}/>
                <ArcButton type={"delete"} hasIcon={true}/>
                <ArcButton type={"download"} hasIcon={true}/>
                <ArcButton type={"print"} hasIcon={true}/>
                <ArcButton type={"calendar"} hasIcon={true}/>
                <ArcButton type={"setting"} isDisabled={true}/>
                <ArcButton type={"text"} hasIcon={true}/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "10px 0 50px"}}>
            </div>


            <div>
                <h3>Check Box</h3>
                <CheckboxSample/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Radio Box</h3>
                <RadioboxSample/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Input</h3>
                <ArcInputExample/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Select Box</h3>
                <ArcSelectbox name={"이름"}
                              options={example}
                              onChange={handleChangeSelect}
                              useDefaultOption={true}
                              useDefaultOptionName={"ex"}
                              size={SelectboxSize.px100}
                              />
                <ArcSelectbox name={"이름"}
                              options={example}
                              onChange={handleChangeSelect}
                              size={SelectboxSize.px100}
                              disabled={true}/>
                <hr style={{margin: '0.5rem 0', display: 'block'}}/>
                <ArcSelectbox name={"이름"}
                              options={example}
                              onChange={handleChangeSelect}
                              useDefaultOption={true}
                              size={SelectboxSize.px300}/>
                <hr style={{margin: '0.5rem 0', display: 'block'}}/>
                <ArcSelectbox name={"이름"}
                              options={example}
                              onChange={handleChangeSelect}
                              size={SelectboxSize.w50}
                              useDefaultOption={true}
                              useDefaultOptionName={"예시"}/>

            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Text Area</h3>
                <ArcTextAreaExample/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Date Picker</h3>
                <ArcDatePickerExample/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Table</h3>
                <TableSample/>
            </div>
            <div style={{width: "100%", borderBottom: "1px solid #d1d1d1", margin: "0 0 50px"}}>
            </div>

            <div>
                <h3>Download</h3>
                <ArcDownload dataSource={files} onDownload={handleDownload}/>
            </div>

        </>
    )
}