import ArcButton, {ButtonIcon, ButtonType} from "components/arc/ArcButton";
import {useNavigate} from "react-router-dom";
import {CSSProperties, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "stores/hook";
import {showAndHideFullMenu} from "reducers/fullMenuSlice";

export default function PopFullMenu() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fullMenuOpen = useAppSelector(state => state.fullMenu.open);
  const userMenu = useAppSelector(state => state.userMenu.userMenu);

  const hideFullMenuStyle: CSSProperties = {display: 'none'};
  const [fullMenuStyle, setFullMenuStyle] = useState<CSSProperties>(hideFullMenuStyle);
  useEffect(() => {
    if(fullMenuOpen) {
      setFullMenuStyle({display: 'block'});
    } else {
      setFullMenuStyle(hideFullMenuStyle);
    }
  }, [fullMenuOpen]);

  const [menu, setMenu] = useState<any[]>([]);
  useEffect(() => {
    setMenu(userMenu.length > 0 ? userMenu : []);
  }, [userMenu]);

  function menuWrapper(list: any[], depth: number) {
    const depthClassName: string = `depth0${depth}`;
    return <div className={depthClassName}>
      <ul>
        {list.map(item => menuTemplate(item, depth))}
      </ul>
    </div>
  }

  function menuTemplate(item: any, depth: number) {
    const menuUrl = (item.childMenus && item.childMenus.length > 0) ? '' : item.url;
    return <li key={item.menuId} className="dep">
      <ArcButton onClick={() => handleClickMenu(menuUrl)}
                 text={item.menuName} />
      {item.childMenus && item.childMenus.length > 0 && menuWrapper(item.childMenus, depth + 1)}
    </li>
  }

  function handleClickMenu(path: string) {
    if (path) {
      dispatch(showAndHideFullMenu(false));
      navigate(path);
    }
  }

  return (
    <div className="popFullmenu" style={fullMenuStyle}>
      <div id="fullMenu">
        <h3 className="hid">전체메뉴</h3>
        <div className="fullMenu-container">
          <div className="nav">
            <div className="gnb-wrap">
              <div id="gnb">
                {menuWrapper(menu, 1)}
                {/*<div className="depth01">*/}
                {/*  <ul>*/}
                {/*    <li>*/}
                {/*      <a href="#" role="button">*/}
                {/*        <span>대시보드</span>*/}
                {/*      </a>*/}
                {/*    </li>*/}
                {/*    <li className="dep">*/}
                {/*      <ArcButton onClick={() => ""} text="RE100 플래너" />*/}
                {/*      <div className="depth02" style={{ height: "auto" }}>*/}
                {/*        <ul>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => ""} text="계획수립" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("")} text="이행수단 분석" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("")} text="자료실" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/rp/roadmap")} text="RE100 로드맵" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/rp/portfolio")} text="포트폴리오" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => ""} text="PPA" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("")} text="자원관리" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/rp/matchingTarget")} text="매칭대상" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/rp/ppa/contracts")} text="계약관리" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/rp/ppa/bills")} text="정산관리" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => ""} text="간편인증" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/rp/smcnCert")} text="RE100 간편인증 관리" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*    </li>*/}
                {/*    <li className="dep">*/}
                {/*      <ArcButton onClick={() => ""} text="탄소 플래너" />*/}
                {/*      <div className="depth02" style={{ height: "auto" }}>*/}
                {/*        <ul>*/}
                {/*          <li>*/}
                {/*            <ArcButton onClick={() => handleClickMenu("")} text="온실가스 모니터링" />*/}
                {/*          </li>*/}
                {/*          <li>*/}
                {/*            <ArcButton onClick={() => handleClickMenu("")} text="인벤토리 관리" />*/}
                {/*          </li>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => handleClickMenu("")} text="로드맵" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton*/}
                {/*                    onClick={() => handleClickMenu("/cp/roadmap/reduceGoal")}*/}
                {/*                    text="감축목표 관리"*/}
                {/*                  />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("")} text="종합 현황" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => ""} text="배출권" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("")} text="시세분석" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/cp/emiss/management")} text="배출권 관리" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => ""} text="분석" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton*/}
                {/*                    onClick={() => handleClickMenu("/cp/analysis/multiEmission")}*/}
                {/*                    text="다배출원 분석"*/}
                {/*                  />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton*/}
                {/*                    onClick={() => handleClickMenu("/cp/analysis/simulation")}*/}
                {/*                    text="탄소중립 시뮬레이션"*/}
                {/*                  />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("")} text="에너지분석 보고서" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => ""} text="온실가스 감축사업" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/cp/reduce/history")} text="이력 관리" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton*/}
                {/*                    onClick={() => handleClickMenu("/cp/reduce/methodology")}*/}
                {/*                    text="방법론 현황"*/}
                {/*                  />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*    </li>*/}
                {/*    <li className="dep">*/}
                {/*      <ArcButton onClick={() => handleClickMenu("")} text="에너지 마켓" />*/}
                {/*      <div className="depth02" style={{ height: "auto" }}>*/}
                {/*        <ul>*/}
                {/*          <li className="dep">*/}
                {/*            <ArcButton onClick={() => handleClickMenu("")} text="장외시장" />*/}
                {/*            <div className="depth03">*/}
                {/*              <ul>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/ot/trade/reports")} text="시세정보" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/ot/trade/trades")} text="거래 게시판" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/ot/trade/contracts")} text="계약관리" />*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                  <ArcButton onClick={() => handleClickMenu("/ot/trade/bills")} text="정산관리" />*/}
                {/*                </li>*/}
                {/*              </ul>*/}
                {/*            </div>*/}
                {/*          </li>*/}
                {/*        </ul>*/}
                {/*      </div>*/}
                {/*    </li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <ArcButton type={ButtonType.custom}
                     onClick={() => dispatch(showAndHideFullMenu(false))}
                     hasIcon={true}
                     icon={ButtonIcon.close}
                     className="fullmenuBtn" />
          {/*<a onClick={(e) => dispatch(showAndHideFullMenu(false))}*/}
          {/*   id="fullMenuClose"*/}
          {/*   className="fullmenuBtn">*/}
          {/*  <span className="hid">전체메뉴 닫기</span>*/}
          {/*  <em></em>*/}
          {/*</a>*/}
        </div>
      </div>
    </div>);
}
