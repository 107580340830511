import SamplePage from "../pages/sample/SamplePage";
import ArcButtonExample from "../pages/arc/ArcButtonExample";
import ArcCheckboxExample from "../pages/arc/ArcCheckboxExample";
import ArcRadioButtonExample from "../pages/arc/ArcRadioButtonExample";
import ArcTableExample from "../pages/arc/ArcTableExample";
import ArcModalContainerExample from "../pages/arc/ArcModalContainerExample";
import ArcSelectboxExample from "../pages/arc/ArcSelectboxExample";
import ArcInputExample from "../pages/arc/ArcInputExample";
import ArcTextAreaExample from "../pages/arc/ArcTextAreaExample";
import ArcDatePickerExample from "../pages/arc/ArcDatePickerExample";
import ArcFileUploaderExample from "../pages/arc/ArcFileUploaderExample";
import ArcHighChartExample from "../pages/arc/ArcHighChartExample";
import ArcGridExample from "../pages/arc/ArcGridExample";
import ArcDownloadExample from "../pages/arc/ArcDownloadExample";


export const spRouter: any = [
  {path: "/sp/SamplePage", element: <SamplePage />, },
  {path: "/sp/ArcButtonExample", element: <ArcButtonExample />, },
  {path: "/sp/ArcCheckExample", element: <ArcCheckboxExample />, },
  {path: "/sp/ArcRadioButtonExample", element: <ArcRadioButtonExample />, },
  {path: "/sp/ArcTableExample", element: <ArcTableExample />, },
  {path: "/sp/ArcModalExample", element: <ArcModalContainerExample />, },
  {path: "/sp/ArcSelectboxExample", element: <ArcSelectboxExample />, },
  {path: "/sp/ArcInputExample", element: <ArcInputExample />, },
  {path: "/sp/ArcTextAreaExample", element: <ArcTextAreaExample />, },
  {path: "/sp/ArcDatePickerExample", element: <ArcDatePickerExample />, },
  {path: "/sp/ArcFileUploaderExample", element: <ArcFileUploaderExample />, },
  {path: "/sp/ArcDownloadExample", element: <ArcDownloadExample />, },
  {path: "/sp/ArcGridExample", element: <ArcGridExample />, },
  {path: "/sp/ArcHighChartExample", element: <ArcHighChartExample/>, },
];