import ArcButton from "components/arc/ArcButton";
import ArcDownload, { dataSourceProps } from "components/arc/ArcDownload";
import AppAlert from "components/common/AppAlert";
import { FileInfoModel } from "model/common/fileInfoModel";
import { DocuBizPlnd } from "model/cp/DocuBizPlnd";
import { ResponseResultCode } from "model/response-api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { openModal } from "reducers/modalSlice";
import { showAndHideSpinner } from "reducers/spinnerSlice";
import { DocuEvdnService } from "services/cp/DocuEvdnService";
import { MhdlgService } from "services/cp/MhdlgService";
import { RootState } from "stores/store";

export default function BusinessDetail() {

  const [docuBizPlndDetail, setDocuBizPlndDetail] = useState<DocuBizPlnd | null>(null);
  const { docId } = useParams<{ docId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cpSlice = useSelector((state: RootState) => state.cp);

  const file = docuBizPlndDetail?.file ? docuBizPlndDetail.file : null;
  const fileList: dataSourceProps[] = file ? (file.files ?? []).map((m: FileInfoModel) => {
    return { realName: m.realNm, saveName: m.fileNm } as dataSourceProps;
  }) : [];

  useEffect(() => {
    async function fetchDocuBizPlndDetail() {
      try {
        if (docId) {
          const response = await MhdlgService.getInstance().getDocuBizPlndDetail(docId);
          if (response && response.resultCode === ResponseResultCode.Y && response.data) {
            setDocuBizPlndDetail(response.data);
          }
        }
      } catch (error) {
        toast.error(t("common.message.error.system"));
      }
    }

    fetchDocuBizPlndDetail();
    return () => { };
  }, [docId]);

  function handleClickDelete() {
    dispatch(
      openModal({
        path: "ot/confirm/apply/delete/confirm",
        target: AppAlert,
        wrapStyle: { width: "20rem" },
        confirmProps: {
          type: "delete",
          title: t("confirm.label.message.delete"),
          onConfirm: deleteDocuBizPlnd,
        },
      })
    );
  }

  const deleteDocuBizPlnd = async () => {
    try {
      const response = await MhdlgService.getInstance().deleteDocuBizPlnd(docuBizPlndDetail!);
      if (response) {
        if (ResponseResultCode.Y === response.resultCode) {
          navigate(`/cp/reduce/history`);
          toast.success(t("common.message.success.delete", { title: t("완료") }));
        } else {
          toast.error(t("common.message.error.data"));
        }
      } else {
        toast.error(t("common.message.error.system"));
      }
    } catch (error) {
      toast.error(t("common.message.error.system"));
    }
  };

  async function handleClickDownload(selectFile: dataSourceProps) {
    if (file) {
      const selectedFile = (file.files ?? []).find(f => f.fileNm === selectFile.saveName);
      if (selectedFile) {
        dispatch(showAndHideSpinner(true));
        await DocuEvdnService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  const handleCancel = () => {
    navigate(`/cp/reduce/history`);
  };

  return (
    <div className="container">
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">사업계획서 상세</h3>
              <div className="tbl-st2 mgt5">
                <table>
                  <caption></caption>
                  <colgroup>
                    <col style={{ width: "15%" }} className="tbl-bg-gray" />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "15%" }} className="tbl-bg-gray" />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "15%" }} className="tbl-bg-gray" />
                    <col style={{ width: "15%" }} />
                  </colgroup>
                  <tbody className="al">
                    <tr>
                      <td>사업계획서 명	</td>
                      <td colSpan={5} className="al">
                        {docuBizPlndDetail?.rlvnDataNm}
                      </td>
                    </tr>
                    <tr>
                      <td>적용 방법론</td>
                      <td colSpan={5} className="al">

                        {docuBizPlndDetail?.mhdlgNm}
                      </td>
                    </tr>
                    <tr>
                      <td>사업계획서</td>
                      <td colSpan={5} className="al">
                        <ArcDownload dataSource={fileList} onDownload={handleClickDownload} />
                      </td>
                    </tr>
                    <tr>
                      <td>설명</td>
                      <td colSpan={5} className="al">
                        {docuBizPlndDetail?.explCtt}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="btns ar">
                <ArcButton type="delete" hasIcon={true} onClick={handleClickDelete} />
                <ArcButton
                  type="modify"
                  hasIcon={true}
                  onClick={() => {
                    navigate(`/cp/reduce/history/business/insert`, { state: JSON.stringify(docuBizPlndDetail) });
                  }}
                />
                <ArcButton type="text" text={"목록"} onClick={handleCancel} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}