import React, { useState } from "react";
import YyRdcGoalYear from "pages/cp/roadmap/YyRdcGoalYear";
import YyRdcGoalType from "pages/cp/roadmap/YyRdcGoalType";
import YyRdcGoalSetting from "pages/cp/roadmap/YyRdcGoalSetting";
import ReduceGoalContextProvider from "pages/cp/roadmap/YyRdcGoalContext";

export const ReduceGoalPageTypeEnum = {
  standard: "STANDARD",
  goalType: "GOALTYPE",
  goal: "GOAL",
} as const;
export type ReduceGoalPageType = (typeof ReduceGoalPageTypeEnum)[keyof typeof ReduceGoalPageTypeEnum];

export interface ReduceGoalPageProps {
  onNext: () => void;
  onBefore?: () => void;
}

export default function YyRdcGoalBase() {
  const [activePage, setActivePage] = useState<ReduceGoalPageType>(ReduceGoalPageTypeEnum.standard);

  return (
    <ReduceGoalContextProvider>
      {ReduceGoalPageTypeEnum.standard === activePage && (
        <YyRdcGoalYear onNext={() => setActivePage(ReduceGoalPageTypeEnum.goalType)} />
      )}
      {ReduceGoalPageTypeEnum.goalType === activePage && (
        <YyRdcGoalType
          onNext={() => setActivePage(ReduceGoalPageTypeEnum.goal)}
          onBefore={() => setActivePage(ReduceGoalPageTypeEnum.standard)}
        />
      )}
      {ReduceGoalPageTypeEnum.goal === activePage && (
        <YyRdcGoalSetting onNext={() => ""} onBefore={() => setActivePage(ReduceGoalPageTypeEnum.goalType)} />
      )}
    </ReduceGoalContextProvider>
  );
}
