import React, {useEffect} from 'react'
import {Constant} from "constants/constant";
import {DefaultTFuncReturn} from "i18next";

export interface ArcTextAreaProps {
    value: string;// defined in Google Sheet
    type?: TextAreaType;
    className?: string;
    cols?: number;// defined in Google Sheet
    rows?: number;//
    disabled?: boolean;
    readonly?: boolean;
    isRequired: boolean;
    validateMessage: string | DefaultTFuncReturn;
    placeholder?: string | DefaultTFuncReturn;
    prefix: string; // optional
    maxLength: number;
    id: string;
    name: string;
    onChange: (text: any, isValid: any) => void// needed
    title?:string;
}

interface Type {
    w5p: TypeDetail;
    w10p: TypeDetail;
    w15p: TypeDetail;

    w20p: TypeDetail;
    w30p: TypeDetail;
    w40p: TypeDetail;
    w50p: TypeDetail;
    w60p: TypeDetail;
    w70p: TypeDetail;
    w80p: TypeDetail;

    w90p: TypeDetail;
    w100p: TypeDetail;
    w200: TypeDetail;
    w250: TypeDetail;
    w300: TypeDetail;
    w400: TypeDetail;
    w450: TypeDetail;
    w500: TypeDetail;
    custom: TypeDetail
}

interface TypeDetail {
    className: string | null,

}

type TextAreaType = keyof Type;

type TextAreaClass = {
    [key in TextAreaType]: TypeDetail;
}

export const TextAreaWidth = {
    w5p: 'InpSel-w5',
    w10p: 'InpSel-w10',
    w15p: 'InpSel-w15',
    w20p: 'InpSel-w20',
    w30p: 'InpSel-w30',
    w40p: 'InpSel-w40',
    w50p: 'InpSel-w50',
    w60p: 'InpSel-w60',
    w70p: 'InpSel-w70',
    w80p: 'InpSel-w80',
    w90p: 'InpSel-w90',
    w100p: 'InpSel-w100',
    w200: 'InpSel-200',
    w250: 'InpSel-250',
    w300: 'InpSel-300',
    w400: 'InpSel-400',
    w450: 'InpSel-450',
    w500: 'InpSel-500',
} as const;

export const enumTextAreaType: TextAreaClass = {
    w5p: {
        className: TextAreaWidth.w5p,
    },
    w10p: {
        className: TextAreaWidth.w10p,

    },
    w15p: {
        className: TextAreaWidth.w15p,

    },

    w20p: {
        className: TextAreaWidth.w20p,

    },
    w30p: {
        className: TextAreaWidth.w30p,

    },
    w40p: {
        className: TextAreaWidth.w40p,

    },
    w50p: {
        className: TextAreaWidth.w50p,

    },
    w60p: {
        className: TextAreaWidth.w60p,

    },
    w70p: {
        className: TextAreaWidth.w70p,

    },
    w80p: {
        className: TextAreaWidth.w80p,

    },

    w90p: {
        className: TextAreaWidth.w90p,

    },
    w100p: {
        className: TextAreaWidth.w100p,

    },
    w200: {
        className: TextAreaWidth.w200,

    },
    w250: {
        className: TextAreaWidth.w250,

    },
    w300: {
        className: TextAreaWidth.w300,

    },
    w400: {
        className: TextAreaWidth.w400,

    },
    w450: {
        className: TextAreaWidth.w450,

    },
    w500: {
        className: TextAreaWidth.w500,

    },
    custom: {
        className: null
    }
} as const;

export default function ArcTextArea(props: ArcTextAreaProps) {
    const {
        value = "",
        type = "w100p",
        className = "",
        disabled = false,
        readonly = false,
        isRequired = true,
        validateMessage = "error",
        placeholder = "내용을 입력해주세요",
        prefix = "",
        maxLength = "",
        id = "",
        name = ""

        // shape = ButtonShape.normal,
        // size = ButtonSize.regular,

    } = props;

    const [valueTxt, setValue] = React.useState<string>(props.value);
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const textAreaAttr = enumTextAreaType[type]['className'];
    const TextClassNames: string = makeTextAreaClassName();


    function makeTextAreaClassName() {
        let buttonClass = '';
        buttonClass += props.className ? props.className + ' ' : '';
        return buttonClass;
    }

    function checkValue() {
        const isValidInput = valueTxt.trim() !== "";
        setIsValid(isValidInput);
    }


    useEffect(() => {
        // checkValue();
        setValue(props.value);
    }, [props.value]);


    const lang = localStorage.getItem(Constant.LANGUAGE) || Constant.SOUTH_KOREA

    return (
        <>
            <textarea rows={props.rows} cols={props.cols} value={valueTxt} placeholder={props.placeholder?.toString()}
                      className={isValid ? `${textAreaAttr} ${TextClassNames}` : `${textAreaAttr} ${props.isRequired ? 'InpSel-wrong' : ''} ${TextClassNames}`}
                      onChange={(e) => {
                          setValue(e.target.value);
                          const value = e.target.value;
                          const isValidInput = value.trim() !== "";
                          setIsValid(isValidInput);
                          props.onChange(value, isValidInput);
                      }} disabled={props.disabled} readOnly={props.readonly} id={props.id} name={props.name}
                      title={props.title}
                      maxLength={props.maxLength}></textarea>
            {props.isRequired ? <>{isValid ? <></> :
                <em className="input-wrong-txt">{props.validateMessage}</em>}</> : <></>}
            {/* {isValid ? <><p>yes</p></>:<><p>no</p></>} */}
        </>
    )
}

