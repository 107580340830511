import { useEffect, useState } from "react";
import AppChart from "components/common/AppChart";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AnalysisService } from "services/cp/AnalysisService";
import { EmissionService } from "services/cp/EmissionService";
import CountUp from "react-countup";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";

export default function Simulation() {
  const { t } = useTranslation();

  const [thisQnt, setThisQnt] = useState(0);
  const [lastQnt, setLastQnt] = useState(0);
  const [goalQnt, setGoalQnt] = useState(0);
  const [expectQnt, setExpectQnt] = useState(0);

  // chartData를 상태 변수로 선언
  const [chartData, setChartData] = useState<{
    type: string;
    name: string;
    data: number[];
    color: string
  }[]>([
    {
      type: "column",
      name: "예상배출량",
      data: [],
      color: "#2970CD",
    },
    {
      type: "column",
      name: "측정배출량",
      data: [],
      color: "#00BB9F",
    },
  ]);

  useEffect(() => {
    getData();
  }, []);

  // 예측 구매량 계산
  useEffect(() => {
    if (goalQnt !== 0) setExpectQnt(goalQnt - thisQnt);
  }, [goalQnt]);

  function getData() {
    // chart 데이터 조회
    AnalysisService.getInstance()
      .getSimulationData()
      .then((response: any) => {
        if (response?.data) {
          let newThisData: number[] = [];
          let newLastData: number[] = [];
          for (let i = 1; i <= 12; i++) {
            newThisData.push(Number(Number(response.data[0]["mm" + i + "EmsnQnt"]).toFixed(2)));
            newLastData.push(Number(Number(response.data[1]["mm" + i + "EmsnQnt"]).toFixed(2)));
          }

          // thisQnt와 lastQnt에 totalQnt 값 저장
          setThisQnt(Number(response.data[0].totalQnt));
          setLastQnt(Number(response.data[1].totalQnt));

          // chartData 업데이트
          setChartData([
            {
              type: "column",
              name: "예상배출량",
              data: newLastData,
              color: "#2970CD",
            },
            {
              type: "column",
              name: "측정배출량",
              data: newThisData,
              color: "#00BB9F",
            },
          ]);
        } else {
          toast.error(t("common.message.error.system"))
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
    // 목표량 조회
    EmissionService.getInstance()
      .getGoalEmissions()
      .then((response: any) => {
        if (response?.data?.data?.total) {
          setGoalQnt(response.data.data.total);
        } else {
          setGoalQnt(0);
        }
      })
      .catch(() => toast.error(t('common.message.error.system')));
  }

  const monthForCategory = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

  const drawChartInfo = {
    title: { text: "" },
    exporting: { enabled: false },
    credits: { enabled: false },
    chart: { type: "column", height: 400 },
    xAxis: {
      title: "",
      categories: monthForCategory,
      crosshair: true,
      labels: {
        style: {
          color: '#555',
          fontSize: '14px'
        }
      }
    },
    yAxis: {
      offset: 0,
      min: 0,
      title: {
        text: '(tCO2eq)',
        style: {
          color: '#555',
          fontSize: '12px'
        },
        align: 'high',
        rotation: 0,
        offset: 0,
        y: -25,
        x: -5
      },
      labels: {
        y: 3,
        distance: 7,
        style: {
          color: '#555',
          fontSize: '14px'
        },
        formatter: function (this: any) {
          return parseNumberWithCommaDecimal(this.value);
        },
      },
    },
    tooltip: {
      headerFormat: "{point.key}<br/>",
      split: false,
      style: {
        fontSize: '14px'
      }
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        pointPadding: 0,
        borderRadius: 0,
        borderWidth: 0,
        pointWidth: 50,
        dataLabels: {
          enabled: true,
          verticalAlign: "top",
          y: -30,
          crop: false,
          style: {
            textOutline: 'none',

          },
          formatter: function (this: any) {
            if (this.y && this.y !== 0) {
              return `<span class="bar-graph-color-num" style="color: ${this.color};">${parseNumberWithCommaDecimal(this.y)}</span>`;
            } else {
              return '';
            }
          },
        },
      },
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      symbolRadius: 0,
      itemStyle: {
        color: "#505050",
        fontSize: "14px",
      },
      itemHoverStyle: {
        color: "#000",
        fontSize: "14px",
      },
    },
  };

  return (
    <>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <h3 className="titT1">탄소배출 현황</h3>
            <div id="graph-typeA" className="chart">
              <AppChart chartOption={drawChartInfo} chartDatas={chartData} />
            </div>
          </div>
        </div>
      </div>

      <div className="col-wrap">
        <div className="col-md-3">
          <div className="cont">
            <h3 className="titT1">탄소중립 모의예측</h3>
            <div className="box-text-style1">
              <ul>
                <li>
                  <strong>연간 배출 목표량</strong>
                  <span>
                    <strong>
                      <CountUp end={parseFloat(goalQnt.toFixed(2)) ?? 0} duration={0.5}
                        decimals={2}>
                        {({ countUpRef }) => <em ref={countUpRef} />}
                      </CountUp>
                    </strong>
                    <em>tCO2eq</em>
                  </span>
                </li>
                <li>
                  <strong>배출 예상치</strong>
                  <span>
                    <strong style={{ color: "red" }}>
                      <CountUp end={parseFloat(lastQnt.toFixed(2))} duration={0.5} decimals={2}>
                        {({ countUpRef }) => <em ref={countUpRef} />}
                      </CountUp>
                    </strong>
                    <em>tCO2eq</em>
                  </span>
                </li>
                <li>
                  <strong>누적 배출량</strong>
                  <span>
                    <strong>
                      <CountUp end={parseFloat(thisQnt.toFixed(2))} duration={0.5} decimals={2}>
                        {({ countUpRef }) => <em ref={countUpRef} />}
                      </CountUp>
                    </strong>
                    <em>tCO2eq</em>
                  </span>
                </li>
              </ul>
              <p className="bu-atte">목표치:연간 배출 가능한 탄소 총량</p>
              <p className="bu-atte">
                예상치:누적 배출량으로 예측한 연간 탄소 배출 예상치 누적배출량:1월1일부터 현재까지 배출한 탄소 배출량
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="cont">
            <h3 className="titT1">
              예측 결과&nbsp;
              <span className="titT3 mgl10">
                <CountUp end={expectQnt ? Number(expectQnt.toFixed(2)) : 0} duration={0.5} decimals={2}>
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
                <em className="fs16">&nbsp;tCO2eq</em> 감축 필요!
                <span className="pc-green f-weightB mgl15">배출권&nbsp;</span>
                <CountUp end={expectQnt ? parseFloat(expectQnt.toFixed(2)) : 0} duration={0.5} decimals={2}>
                  {({ countUpRef }) => <span className="pc-green f-weightB" ref={countUpRef} />}
                </CountUp>
                <em className="pc-green f-weightB"><em className="fs16">&nbsp;ton</em> 구매 필요!</em>
              </span>
            </h3>
            <div className="round-table">
              <table>
                <caption>예측 결과 : 배출권 구매</caption>
                <colgroup>
                  <col className="width:100%;" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col" className="rd-tbl-c3">
                      배출권 구매
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="rd-tbl-b1">
                      <ul className="rd-tbl-bul1">
                        <li className="f-weightB">탄소배출권 구매를 통해 배출량 확보</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className="rst-td rd-tbl-b1">
                      1톤당 요금
                      <ul className="mgt5">
                        <li>- KAU=10,600원 KOC=11,000원 KCU=10,800원</li>
                        <li>KAU23 기준으로 계산하였음</li>
                      </ul>
                      <div className="rst rd-tbl-c4">
                        <span>장외거래</span>
                        <Link to="/ot/trade/trades">실행</Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
