import React, {ReactElement, useCallback, useEffect, useState} from "react";
import BillListSearch from "pages/cb/bill/billList/BillListSearch";
import {BillListProps, BillSearchModel, CustomGridRenderer} from "pages/cb/bill/billList/BillListBase";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ArcGrid, {GridColumn} from "components/arc/ArcGrid";
import moment from "moment/moment";
import {parseNumberWithComma} from "utils/stringUtil";
import {BillModel} from "model/cb/billModel";
import {CodeUtil} from "utils/codeUtil";

export default function BillListPchsr(props: BillListProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const initSearch = new BillSearchModel(props.emksReqdCd, 'p');
  const [grid, setGrid] = useState<ReactElement|null>();
  const [search, setSearch] = useState<BillSearchModel>(initSearch);
  function getSearchCondition(conditions: BillSearchModel) {
    setSearch(conditions);
  }
  const searchBar = useCallback(() => {
   return (
      <BillListSearch type='p'
                      emksReqdCd={props.emksReqdCd}
                      search={getSearchCondition} />);
  }, [props.emksReqdCd]);

  const makeColumn = () => {
    let column: GridColumn[] = [];
    column.push({
      header: '결제상태',
      width: 160,
      align: 'center',
      renderer: {
        type: CustomGridRenderer,
        options: {type: 'status'}
      }
    });
    if(0 === props.emksReqdCd) {
      column.push({
        header: '거래구분',
        width: 150,
        align: 'center',
        name: 'reqdCd',
        formatter: (e: any) => CodeUtil.billReqdCdConvert(e.value)
      });
    }
    column.push({
      header: '발신자',
      width: 300,
      align: 'left',
      name: 'caller',
      formatter: (e: any) => {
        if(e.row) {
          return 3 === e.row.reqdCd ? e.row.contract?.salerNm : e.row.regrNm;
        } else {
          return '-';
        }
      }
    });
    if(1 === props.emksReqdCd || 3 === props.emksReqdCd) {
      column.push({
        header: 3 === props.emksReqdCd ? '배출권' : '에너지원',
        width: 150,
        align: 'center',
        name: 'contract',
        formatter: (e: any) => e.value?.cntrRsrcNm
      });
    }
    column.push({
      header: '청구서명',
      align: 'left',
      name: 'title',
      formatter: (e: any) => {
        return `<button>
      ${moment(e.row?.reqYmd).subtract(1, 'M').format('YYYY년 MM월')} 청구서
    </button>`;
      }
    });
    column.push({
      header: '청구금액',
      width: 200,
      align: 'right',
      name: 'reqAmt',
      formatter: (e: any) => parseNumberWithComma(e.value) + t('common.unit.won')
    });
    column.push({
      header: '청구일자',
      width: 120,
      align: 'center',
      name: 'reqYmd',
      formatter: (e: any) => moment(e.value).format('YYYY-MM-DD')
    });
    column.push({
      header: '납부기한',
      width: 120,
      align: 'center',
      name: 'payYmd',
      formatter: (e: any) => moment(e.value).format('YYYY-MM-DD')
    });
    return column;
  };

  function handleClickRow(e: any) {
    const rowData = e.instance.getRow(e.rowKey) as BillModel;
    if(rowData && rowData.reqId) {
      rowData.pageType = 'p';

      let path;
      switch (rowData.reqdCd) {
        case 1: path = "/rp/ppa/bills/detail"; break;
        case 2: path = "/ot/money/bills/dc/detail"; break;
        case 3: path = "/ot/trade/bills/detail"; break;
        case 4: path = "/ot/money/bills/hd/detail"; break;
      }

      if(path) {
        navigate(path, {state: JSON.stringify(rowData)});
      }
    }
  }

  useEffect(() => {
    setGrid(<ArcGrid columns={makeColumn()}
                     datasource={{
                       readData: {url: '/cb/bill/bills', method: 'GET', initParams: search},
                     }}
                     onClickRow={handleClickRow}
                     useGridInfoHeader={true} />);
  }, [search]);

  return (<section className="section active">
    {props.children}
    <div className="col-wrap">
      <div className="col-md-12">
        {searchBar()}
      </div>
    </div>
    <div className="col-wrap">
      <div className="col-md-12">
        <div className="cont">
          <h3 className="titT1">{t('cb.bill.bill')}</h3>
          {grid}
        </div>
      </div>
    </div>
  </section>);
}