import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { ComCdService } from "services/cp/ComCdService";
import ArcGrid, {
  ArcGridCheckResult,
  CustomSelectCommonCodeRenderer,
  GridApi,
  GridEditor
} from "components/arc/ArcGrid";
import ArcDatePicker from "components/arc/ArcDatePicker";
import ArcCheckbox from "components/arc/ArcCheckbox";
import { CodeUtil } from "utils/codeUtil";
import { UserInfoUtil } from "utils/userInfoUtil";
import { AuthConstant } from "constants/authConstant";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useAppSelector } from "stores/hook";

export default function Formula() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const today: Date = new Date();
  const { t } = useTranslation();

  const [scopCd, setScopCd] = useState<any[]>([]);
  const [aplyYy, setAplyYy] = useState(today.getFullYear());
  const [isGroup, setIsGroup] = useState(true);

  const onChangeAplyYy = (event: any) => {
    setAplyYy(event.fromDateString.substring(0, 4));
  };

  // select로 보여질 단위 조회
  useEffect(() => {
    getScopCd();
  }, []);

  function getScopCd() {
    ComCdService.getInstance().getComCdExpl("EMKS_SCOP_KND_CD")
      .then(response => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setScopCd(cdOptions);
        } else {
          setScopCd([]);
        }
      })
      .catch(error => {
        toast.error(t('common.message.error.system'));
      });
  }

  // 그리드 헤더
  const gridColumns: any[] = [
    {
      header: 'mngId',
      name: 'mngId',
      hidden: true,
    },
    {
      header: '구분',
      name: 'scopCd',
      align: 'center',
      width: 180,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: '입력구분',
      name: 'clCtt',
      align: 'center',
      width: 180,
      whiteSpace: 'normal',
      formatter: function (e: any) {
        return e.value
      },
    },
    {
      header: '산정식',
      name: 'settCtt',
      align: 'left',
      whiteSpace: 'normal',
      formatter: function (e: { value: any; }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value;
        }
        return '';
      }
    }
  ];

  //그리드 수정
  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: {
      aplyYy: (aplyYy ?? "").toString(),
      isGroup: isGroup,
    },
    editableColumns: [
      {
        columnName: 'scopCd',
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          comCd: scopCd,
          validation: {
            validatorFn: (value: any, row: any, columnNm: any) => {
              return (
                value !== null
                && value?.comCdExpl !== undefined
              );
            },
          }
        }
      },
      {
        columnName: 'clCtt',
        editorType: 'text',
        options: {
          validation: {
            dataType: 'string',
            required: true,
            unique: true,
          }
        }
      },
      {
        columnName: 'settCtt',
        editorType: 'text',
        options: {
          validation: {
            dataType: 'string',
            required: true
          }
        }
      },
    ]
  }

  // 그리드. 체크박스
  const gridRef: any = useRef<typeof ArcGrid>()
  const checkedRow = (data: ArcGridCheckResult) => {
  }

  const handleIsGroup = (isChecked: any) => {
    setIsGroup(isChecked)
  }

  //  데이터 전송
  const adminGridApi: GridApi = {
    readData: {
      url: '/cp/mngCalfm', method: 'GET', initParams: {
        aplyYy: aplyYy,
        isGroup: isGroup,
      },
    },
    createData: { url: '/cp/mngCalfm', method: 'POST' },
    updateData: { url: '/cp/mngCalfm', method: 'PUT' },
    deleteData: { url: '/cp/mngCalfm', method: 'DELETE' }
  }

  const gridApi: GridApi = {
    readData: {
      url: '/cp/mngCalfm', method: 'GET', initParams: {
        aplyYy: aplyYy,
        isGroup: isGroup,
      },
    },
  }

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">산정식 관리</h3>
            <ArcDatePicker
                onChange={onChangeAplyYy}
                fromDate={String(aplyYy)}
                id={"year"}
                name={"year"}
                viewType={"year"}
                placeholders={{ fromDate: "YYYY" }} />
          </div>
          <span className="checkbox-radio-type02">
            <label htmlFor="checkbox2">
              <ArcCheckbox
                onChecked={handleIsGroup}
                text={"그룹"}
                id={"그룹"}
                name={"그룹"}
                checked={isGroup} isAlt={true} />
            </label>
          </span>
          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                gridRef={gridRef}
                key={scopCd.length}
                datasource={adminGridApi}
                columns={gridColumns}
                useGridInfoHeader={true}
                usePagination={false}
                editor={gridEditor}
                rowHeaders={['checkbox']}
                onCheckRow={checkedRow}
                isTest={true} />
              :
              <ArcGrid
                gridRef={gridRef}
                datasource={gridApi}
                columns={gridColumns}
                useGridInfoHeader={true}
                usePagination={false}
                onCheckRow={checkedRow} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}