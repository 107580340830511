import {Outlet, useLocation} from "react-router-dom";
import Breadcrumbs from "./comp/Breadcrumbs";
import {MouseEvent, useEffect, useRef, useState} from "react"
import { sideBarShowReducer, sideBarShowReducerSmall } from "reducers/layoutSlice";
import { useAppDispatch, useAppSelector } from "stores/hook";
import Header from "components/layout/Header";
import {getUserMenu} from "reducers/userMenuSlice";
import { AuthConstant } from "constants/authConstant";
import {getCommonCode, getCpCodes} from "reducers/codeSlice";
import PopFullMenu from "components/layout/comp/PopFullMenu";
import { UserInfoUtil } from "utils/userInfoUtil";
import {HashLink} from "react-router-hash-link";

export default function Layout() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const spinner = useAppSelector(state => state.spinner.loading);
  const userInfo = useAppSelector(state => state.userInfo.userInfo);
  const [width, ] = useState(window.innerWidth);
  const [showSlideBar, setShowSlideBar] = useState(false);
  const [showBread, setShowBread] = useState(true);
  const [isSignUpPage, setIsSingUpPage] = useState(false);

  useEffect(() => {
    dispatch(getCpCodes());
    dispatch(getCommonCode());

    if (width > 768 && width < 1200) {
      dispatch(sideBarShowReducer(false));
    } else if (width < 768) {
      dispatch(sideBarShowReducerSmall(true));
    }

    const resizeListener = () => {
      // change width from the state object
      const innerWidth = window.innerWidth;
      if (innerWidth < 768) {
        dispatch(sideBarShowReducer(true));
      } else if (innerWidth < 1200) {
        dispatch(sideBarShowReducer(false));
      } else {
        dispatch(sideBarShowReducer(true));
      }

      if (innerWidth < 768) {
        dispatch(sideBarShowReducerSmall(true));
      } else {
        dispatch(sideBarShowReducerSmall(false));
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  useEffect(() => {
    dispatch(getUserMenu());
    if(UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
      || UserInfoUtil.hasRole(userInfo, AuthConstant.R001)
      || UserInfoUtil.hasRole(userInfo, AuthConstant.R002)
      || UserInfoUtil.hasRole(userInfo, AuthConstant.R004)) {
      setShowSlideBar(true);
    } else {
      setShowSlideBar(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (location.pathname.includes('/sign-up')) {
      setShowBread(false);
      setShowSlideBar(false);
      setIsSingUpPage(true);
    } else {
      setShowBread(true);
    }
  }, [location, userInfo]);

  return (
    <>
      {
        spinner && (
          <div className="progress-spinner text-center">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )
      }
      <div id="skipArea">
        <HashLink to="#contents">본문으로 바로가기</HashLink>
      </div>
      <div id="wrap">
        <Header/>
        <div id="container">
          {isSignUpPage
          ?
            <div id="sub_container-member">
              <div id="contents">
                <div className="container">
                  <Outlet />
                </div>
              </div>
            </div>
          :
            <>
              <div id="sub_container" style={showSlideBar ? {} : {width: "100%", marginLeft: "0"}}>
                <div className="ht_until">
                  {showBread && (<Breadcrumbs/>)}
                </div>
                <div id="contents">
                  <div className="container">
                    <Outlet/>
                  </div>
                </div>
              </div>
              {UserInfoUtil.isAdmin(userInfo) && (<PopFullMenu/>)}
            </>
          }
        </div>
      </div>
    </>
  )
}