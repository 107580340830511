import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from 'stores/store'
import {useEffect, useState} from "react";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()

export function useJSScript(src: string) {
  const [isLoad, setIsLoad] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleLoad = () => setIsLoad(true);
    const handleError = (error: any) => setError(error);

    let script: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
    if(!script) {
      script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true;
    }

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    document.head.appendChild(script);

    return () => {
      if(script) {
        script.removeEventListener('load', handleLoad);
        script.removeEventListener('error', handleError);
        document.head.removeChild(script);
      }
    }
  }, [src]);

  return [isLoad, error];
}