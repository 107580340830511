import ArcButton, {ButtonColor, ButtonShape, ButtonSize} from "components/arc/ArcButton";
import {useLocation, useNavigate} from "react-router-dom";

export default function SignUpSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state;

  function clickBtn() {
    navigate(`/`);
  }

  return (
      <div id="container">
        <div id="sub_container-member">
          <div id="contents">
            <div className="container">
              <section className="section active">
                <div className="col-wrap">
                  <div className="col-md-12">
                    <div className="cont member-h">
                      <div className="tit-step">
                        <h1 className="titT2">회원가입</h1>
                        {/* 산단관리는 탭 4개 */}
                        {type == 1 ? <>
                              <ul className="tab-order-st1">
                                <li>
                                  <div><strong>01</strong><span>본인인증</span></div>
                                </li>
                                <li>
                                  <div><strong>02</strong><span>정보입력</span></div>
                                </li>
                                <li>
                                  <div><strong>03</strong><span>유연자원 등록(선택)</span></div>
                                </li>
                                <li className="on">
                                  <div><strong>04</strong><span>가입완료</span></div>
                                </li>
                              </ul>
                            </>
                            :
                            <>
                              <ul className="tab-order-st1">
                                <li>
                                  <div><strong>01</strong><span>본인인증</span></div>
                                </li>
                                <li>
                                  <div><strong>02</strong><span>정보입력</span></div>
                                </li>
                                <li className="on">
                                  <div><strong>03</strong><span>가입완료</span></div>
                                </li>
                              </ul>
                            </>}
                      </div>
                      <div className="join-box">
                                                <span className="icon-con">
                                                    <img src="/img/sub/complete_icon.png" alt=""/>
                                                </span>
                        <h2 className="ac">회원가입 신청이 완료 되었습니다.</h2>
                        <p className="desc ac">
                          등록하신 아이디는 <strong className="pc-green f-weightB">담당자의 심사를 거쳐 가입이
                          완료</strong>됩니다.<br/>심사에는<strong className="pc-green f-weightB">최대
                          7일</strong>이 소요될 수 있으며, 심사가 완료되면 등록하신 이메일로 통보해드립니다.<br/>심사가 완료된 후 플랫폼을
                          정상적으로 이용하실 수 있습니다.
                        </p>
                        <div>
                        </div>
                      </div>
                      <ArcButton type={"custom"} color={ButtonColor.btn2} text={"메인으로 이동하기"}
                                 shape={ButtonShape.normal} size={ButtonSize.large}
                                 onClick={clickBtn}/>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div>
  )
}