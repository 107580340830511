import {HttpStatusCode} from "axios";

export enum ResponseResultCode {
  Y= 'Y',
  N= 'N',
  E= 'E'
}

export class ResponseApi<T> {
  statusCode: HttpStatusCode;
  resultCode: ResponseResultCode;
  data: T;
  errorMessage: string;

  constructor(statusCode: number, statusMessage: string, resultCode: String, errorMessage: string, data: T) {
    this.statusCode = statusCode;
    this.resultCode = ResponseResultCode[resultCode as keyof typeof ResponseResultCode]
    this.errorMessage = errorMessage;
    this.data = data;
  }
}
