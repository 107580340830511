import Api from "../common/Api";
import {ApiUrlUtil} from "utils/apiUrlUtil";
import {YyRdcGoalModel} from "model/cp/yyRdcGoal";

export class RoadMapService extends Api {
  private static service: RoadMapService;
  private static reduceGoalUrl: string = process.env.REACT_APP_URL_CP + "/roadMap/reduceGoal";

  public static getInstance(): RoadMapService {
    if (!RoadMapService.service) {
      RoadMapService.service = new RoadMapService();
    }
    return RoadMapService.service;
  }

  public async getUserLastYyRdcGoal() {
    const url = ApiUrlUtil.buildQueryString(RoadMapService.reduceGoalUrl);
    return await super.get<YyRdcGoalModel>(url);
  }

  public async saveYyRdcGoal(data: any) {
    const url = ApiUrlUtil.buildQueryString(RoadMapService.reduceGoalUrl);
    return await super.post<any>(url, data);
  }

  public getRoadmap() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/comprehenStatus/getRoadmap");
    return super.get(url);
  }

  // rp 누적배출량 조회
  public getRpEmsnQnt() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_RP + "/smcnCert/smcnCertSumAllGenQnt");
    return super.get(url);
  }
}
