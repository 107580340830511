import {useEffect, useState} from "react";
import {messaging, requestPermission} from "services/Firebase";
import {UserInfoModel} from "model/ua/UserInfoModel";
import {useAppDispatch, useAppSelector} from "stores/hook";
import {AuthenticationService} from "services/ua/AuthenticationService";
import {HttpStatusCode} from "axios";
import {clearUserInfo, setUserInfo} from "reducers/userInfoSlice";
import {useNavigate} from "react-router-dom";
import moment, {now} from "moment";
import {onMessage} from "firebase/messaging";
import {FirebaseService} from "services/common/FirebaseService";
import {updateAlarmMessages} from "reducers/alarmSlice";
import {AppDispatch} from "stores/store";
import {toast} from "react-toastify";

export async function getMessages(dispatch: AppDispatch, newUserInfo: any) {
  try {
    const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss');

    const response = await FirebaseService.getInstance().getMessage({
      uid: newUserInfo.userId,
      fromDt: "2024-07-26 12:15:00"
    });

    if (response && response.data) {
      dispatch(updateAlarmMessages(response.data.reverse()));
    }
  } catch (error) {
    console.error("메시지를 가져오는 데 실패하였습니다.", error);
  }
}

export default function AuthGuard(props: any) {
  const dispatch = useAppDispatch();
  const userInfo: UserInfoModel = useAppSelector((state) => state.userInfo.userInfo);
  const navigate = useNavigate();
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fetchToken = async () => {
    const token = await requestPermission();
    const response = await FirebaseService.getInstance().saveDeviceToken({
      uid: userInfo.userId,
      token: token
    });
    getMessages(dispatch, userInfo);
  };

  useEffect(() => {
    if (userInfo) {
      fetchToken();
    }
  }, []);

  useEffect(() => {
    checkAuthentication();
  }, [navigate]);

  onMessage(messaging, (payload) => {
    toast.success('새로운 알림이 도착했습니다!');
    getMessages(dispatch, userInfo);
  });


  // function getMessages(newUserInfo: any) {
  //   const resp = FirebaseService.getInstance().getMessage({
  //     uid: newUserInfo.userId,
  //     fromDt: "2024-07-26 12:15:00"
  //   }).then(value => {
  //     if (value) {
  //       dispatch(updateAlarmMessages(value.data));
  //     }
  //   });
  // }

  const checkAuthentication = () => {
    if (userInfo?.userId && !isExpiredAccessToken()) {
      setIsAuthenticated(true);
    } else {
      let newUserInfo: UserInfoModel | null = null;

      try {
        AuthenticationService.getInstance().getUserInfo()
        .then((response: any) => {
          if (response?.data && response?.statusCode === HttpStatusCode.Ok) {
            newUserInfo = new UserInfoModel(response.data);
          } else {
            newUserInfo = null;
          }
        })
        .catch(() => {
          newUserInfo = null;
        })
        .finally(() => {
          if (newUserInfo) {
            dispatch(setUserInfo(newUserInfo));
            setIsAuthenticated(true);
          } else {
            dispatch(clearUserInfo());
            AuthenticationService.redirectToSignIn();
          }
        });
      } catch {
        dispatch(clearUserInfo());
        AuthenticationService.redirectToSignIn();
      }
    }
  }

  const isExpiredAccessToken = () => {
    if (userInfo?.accessTokenExpiresAt) {
      return moment().utc().isAfter(userInfo.accessTokenExpiresAt);
    }

    return true;
  }

  return isAuthenticated && props.children;
}