import ArcButton from "components/arc/ArcButton";
import React, {useEffect, useState} from "react";
import {billPageType} from "pages/cb/bill/billList/BillListBase";
import {useTranslation} from "react-i18next";
import BillListSaler from "pages/cb/bill/billList/BillListSaler";
import BillListHeader from "pages/cb/bill/billList/BillListHeader";
import BillListPchsr from "pages/cb/bill/billList/BillListPchsr";

const OT_ACTIVE = 'billListOtActiveTab';
export default function BillListOt() {
  const {t} = useTranslation();

  const initActive = () => {
    const active = localStorage.getItem(OT_ACTIVE) as billPageType|null;
    return active??'s';
  }
  const [active, setActive] = useState<billPageType>(initActive);

  useEffect(() => {
    localStorage.setItem(OT_ACTIVE, active);
  }, [active]);

  return (<section className="section active">
    <div className="col-wrap">
      <div className="col-md-12">

        <div className="tab-st-box1">
          <ul className="tab-st1">
            <li className={active === 's' ? "on" : ""}>
              <ArcButton onClick={() => setActive('s')}
                         text={t('cb.bill.sale')} />
            </li>
            <li className={active === 'p' ? "on" : ""}>
              <ArcButton onClick={() => setActive('p')}
                         text={t('cb.bill.purchase')} />
            </li>
          </ul>
        </div>

        {'s' === active && (
          <BillListSaler emksReqdCd={3}>
            <BillListHeader type='s' emksReqdCd={3} />
          </BillListSaler>)}
        {'p' === active && (
          <BillListPchsr emksReqdCd={3}>
            <BillListHeader type='p' emksReqdCd={3} />
          </BillListPchsr>)}
      </div>
    </div>
    </section>);
}