import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ResponseResultCode } from "model/response-api";
import ArcButton from "components/arc/ArcButton";
import { useAppSelector } from "stores/hook";
import ArcInput from "components/arc/ArcInput";
import { useNavigate } from "react-router-dom";
import { openModal } from "reducers/modalSlice";
import AppAlert from "components/common/AppAlert";
import { MhdlgService } from "services/cp/MhdlgService";
import { UserInfoUtil } from "utils/userInfoUtil";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { JsxElementConstant } from "constants/jsxElementConstant";
import ArcGrid, {CustomTitleRenderer, GridColumn} from "components/arc/ArcGrid";

export default function list() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef: any = useRef<typeof ArcGrid>();
  const keyword = ["연료", "발전", "교체", "절감", "생산", "열", "풍력", "효율"];
  const [kwrdNm,] = useState("");
  const [search, setSearch] = useState("");

  const columns: GridColumn[] = [
    {
      header: "방법론명",
      name: "mhdlgNm",
      align: "left",
      whiteSpace: "normal",
      renderer:{
        type:CustomTitleRenderer
      },
    },
    {
      header: "분야명",
      name: "fareaNm",
      align: "center",
      width: 300,
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value;
      },
    },
    {
      header: "유효시작일자",
      name: "vldStrtYmd",
      align: "center",
      width: 200,
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value != "" && e.value != null) {
          return e.value.slice(0, 4) + '-' + e.value.slice(4, 6) + '-' + e.value.slice(6, 8);
        } else {
          return e.value;
        }
      },
    },
  ];

  const checkedRow = () => { };

  function handleClickDetail(event: any) {
    const rowData = gridRef.current.getInstance().getRow(event.rowKey);
    if (rowData) {
      const id = rowData.mhdlgId;
      navigate(`/cp/reduce/methodology/detail/${id}`);
    }
  }

  const handleClickRegist = () => {
    navigate(`/cp/reduce/methodology/insert`);
  };

  async function handleDel() {
    var chkArray = gridRef.current.getInstance().getCheckedRows();
    if (chkArray.length > 0) {
      gridRef.current.getInstance().removeCheckedRows();
      try {
        const response = await MhdlgService.getInstance().deleteMhdlgs(chkArray);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t("common.message.success.delete", { title: `${chkArray.length}개의 방법론이` }));
          } else {
            toast.error(t("common.message.error.delete", { title: '방법론' }));
          }
        } else {
          toast.error(t('common.message.error.duringProcessing'));
        }
      } catch (error) {
        toast.error(t("common.message.error.system"));
      }
    }
  }

  const handleClickDelete = () => {
    const checkedNum = gridRef.current.getInstance().getCheckedRows().length;
    if (checkedNum > 0) {
      dispatch(
        openModal({
          path: "ot/confirm/apply/delete/confirm",
          target: AppAlert,
          wrapStyle: { width: "20rem" },
          confirmProps: {
            type: "delete",
            title: `선택된 ${checkedNum}개의 방법론을 ${t("confirm.label.message.delete")}`,
            onConfirm: handleDel,
          },
        })
      );
    } else {
      toast.warning(t('common.message.warn.noDeleteTarget'));
    }
  };

  const [datasource, setDatasource] = useState({
    readData: {
      url: "/cp/mhdlg/list",
      method: "GET",
      initParams: { mhdlgNm: search, kwrdNm: kwrdNm },
    },
  });

  async function handleClickSearch(param: any) {
    let data = "";
    if (param) {
      data = param;
    } else {
      data = search;
    }
    setDatasource({
      readData: {
        url: "/cp/mhdlg/list",
        method: "GET",
        initParams: {
          mhdlgNm: data,
          kwrdNm: kwrdNm
        },
      },
    });
  }

  function handleClickKeyword(i: any) {
    setSearch(i);
    handleClickSearch(i);
  }

  function changeInput(value: any) {
    setSearch(value);
  }

  return (
    <>
      <div className="srh-filter4 pbb0">
        <ArcInput
          key={'search'}
          inputType={"text"}
          id={"search"}
          value={search}
          className={"InpSel"}
          type={"custom"}
          placeholder={t('common.label.inputSearchDefault')}
          onChange={changeInput}
          onKeyUp={e => JsxElementConstant.OnEnterKeyUp(e, handleClickSearch)}
        />
        <ArcButton type={"search"} hasIcon={true} onClick={handleClickSearch} />
      </div>
      <div className="srh-filter pdt0">
        <div className="ac-src-frm-wrap">
          <div className="keyword pdb10">
            <span>검색어 추천</span>
            <ul>
              {keyword.map((i) => {
                return (
                  <li key={i} style={{ cursor: "pointer" }}>
                    <a href={JsxElementConstant.hrefIsNull}
                      style={{ borderColor: (i === search ? "#65A1D3" : "") }}
                      onClick={() => handleClickKeyword(i)}>
                      {i}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <ArcGrid
              gridRef={gridRef}
              datasource={datasource}
              columns={columns}
              rowHeaders={UserInfoUtil.isAdmin(userInfo) ? ['checkbox', 'rowNum'] : ['rowNum']}
              useGridInfoHeader={true}
              usePagination={true}
              onCheckRow={checkedRow}
              onClickRow={handleClickDetail}
            />
            {UserInfoUtil.isAdmin(userInfo)
              ?
              <div className="ar">
                <ArcButton type={'delete'} hasIcon={true} onClick={handleClickDelete} />
                <ArcButton type={'regist'} hasIcon={true} onClick={handleClickRegist} />
              </div>
              : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
}
