import React from "react";
import SignUpType from "pages/auth/signUp/SignUpType";
import SignUpTab1 from "pages/auth/signUp/SignUpTab1";
import SignUpTab2 from "pages/auth/signUp/SignUpTab2";
import SignUpTab3 from "pages/auth/signUp/SignUpTab3";
import SignUpSuccess from "pages/auth/signUp/SignUpSuccess";
import NotPermission from "pages/error/NotPermission";
import NotFound from "pages/error/NotFound";
import ServerError from "pages/error/ServerError";
import Layout from "components/layout/Layout";

export const publicRouter: any = {
  path: "/",
  element: (
    <Layout />
  ),
  children: [
    { path: "/sign-up", element: <SignUpType /> },
    { path: "/sign-up/1", element: <SignUpTab1 /> },
    { path: "/sign-up/2", element: <SignUpTab2 /> },
    { path: "/sign-up/3", element: <SignUpTab3 /> },
    { path: "/sign-up/5", element: <SignUpSuccess /> },
    { path: "not-permission", element: <NotPermission /> },
    { path: "err-network", element: <NotFound /> },
    { path: "err-page-not-found", element: <NotFound /> },
    { path: "err-server-error", element: <ServerError /> },
    { path: "*", element: <NotFound /> }
  ],
};
