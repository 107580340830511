import React, { ReactNode, useState } from "react";

export interface IArcTooltip {
  tooltipContent: ReactNode;
}
export default function ArcTooltip(props: IArcTooltip) {
  const [isActive, setIsActive] = useState(false);
  const [aClassName, setAClassName] = useState('');

  const handleClickTooltip = (event?: any) => {
    event.preventDefault();
    if(isActive) {
      setAClassName('');
      setIsActive(false)
    } else {
      setAClassName('active');
      setIsActive(true);
    }
  }

  return (
    <a href="#" className={'tit-info ' + aClassName} onClick={handleClickTooltip}>
      <i className="xi-help" aria-hidden="true"></i>
      <span className="hid"></span>
      <div className="tit-info-cont">
        {props.tooltipContent}
      </div>
    </a>
  );
}