import { AuditModel } from "model/common/auditModel";
import { FileModel } from "model/common/fileModel";

export class DocuEvdn extends AuditModel {
    docId?: string;
    regDate?: Date;
    regrId?: string;
    chgDate?: Date;
    chgrId?: string;
    
    usePrdStrtYmd?: string;
    usePrdEndYmd?: string;

    rlvnDataNm?: string;
    emsnFcltNm?: string;
    itmNm?: string;
    aplyYmd?: string;

    file?: FileModel;
    fileRespons?: any;
    repAtflId?: string;

}