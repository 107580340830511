import React, { useState } from 'react';
import ArcSelectbox, { SelectboxSize, SelectDataModel } from "components/arc/ArcSelectbox";
import ArcCheckbox from "components/arc/ArcCheckbox";
import ArcInput from "components/arc/ArcInput";

export interface InventoryGridComCdProps {
  name: string
  comCds: SelectDataModel[]
  isNewCode: boolean
  selectedValue?: any
  onChange: (data: InventoryGridComCdResult) => void
}

export interface InventoryGridComCdResult {
  isNewCode: boolean
  value: any
  id?: any
}

export default function InventoryGridComCd(props: InventoryGridComCdProps) {
  const [isNew, setIsNew] = useState(props.isNewCode);

  function handleChangeInput(data: any) {
    props.onChange({
      isNewCode: true,
      value: data
    })
  }

  function handleChangeSelect(event: any) {
    props.onChange({
      isNewCode: false,
      value: event.target.selectedOptions[0].text,
      id: event.target.selectedOptions[0].value
    })
  }

  return (
    <div className="flexWrap">
      {
        isNew ? (
          <ArcInput value={props.selectedValue}
            id={props.name}
            className="mgr10"
            onChange={handleChangeInput} />

        ) : (
          <ArcSelectbox name={props.name}
            selectedValue={props.selectedValue}
            size={SelectboxSize.w100}
            className="mgr10"
            onChange={handleChangeSelect}
            useDefaultOption={true}
            isRequired={true}
            options={props.comCds} />
        )
      }
      <ArcCheckbox onChecked={setIsNew} checked={props.isNewCode} />
      <span className="srh-tit fs12">신규</span>
    </div>
  )
}