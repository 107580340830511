import ArcButton from "components/arc/ArcButton";
import React, {useEffect, useState} from "react";
import {billPageType} from "pages/cb/bill/billList/BillListBase";
import {useTranslation} from "react-i18next";
import BillListSaler from "pages/cb/bill/billList/BillListSaler";
import BillListHeader from "pages/cb/bill/billList/BillListHeader";
import BillListPchsr from "pages/cb/bill/billList/BillListPchsr";
import {useAppSelector} from "stores/hook";
import {AuthConstant} from "constants/authConstant";
import {UserInfoUtil} from "utils/userInfoUtil";

const BILL_DC_ACTIVE = 'billListDCActiveTab';
export default function BillListDC() {
  const {t} = useTranslation();
  const userInfo = useAppSelector(state => state.userInfo.userInfo);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [active, setActive] = useState<billPageType|undefined>();

  useEffect(() => {
    if(userInfo?.userId) {
      if(UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)) {
        setIsAdmin(true);
        if(localStorage.getItem(BILL_DC_ACTIVE) !== null) {
          setActive(localStorage.getItem(BILL_DC_ACTIVE) as billPageType);
        } else {
          localStorage.setItem(BILL_DC_ACTIVE, 's');
          setActive('s');
        }
      } else {
        localStorage.removeItem(BILL_DC_ACTIVE);
        setIsAdmin(false);
        if(UserInfoUtil.hasRole(userInfo, AuthConstant.R004)) {
          setActive('s');
        }
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN) && active) {
      localStorage.setItem(BILL_DC_ACTIVE, active);
    }
  }, [active, userInfo]);

  return (<section className="section active">
    <div className="col-wrap">
      <div className="col-md-12">
        {isAdmin && (
          <div className="tab-st-box1">
            <ul className="tab-st1">
              <li className={active === 's' ? "on" : ""}>
                <ArcButton onClick={() => setActive('s')} text={t('cb.bill.sale')} />
              </li>
              <li className={active === 'p' ? "on" : ""}>
                <ArcButton onClick={() => setActive('p')} text={t('cb.bill.purchase')} />
              </li>
            </ul>
          </div>
        )}

        {'s' === active && (
          <BillListSaler emksReqdCd={2}>
            <BillListHeader type='s' emksReqdCd={2} />
          </BillListSaler>)}
        {'p' === active && (
          <BillListPchsr emksReqdCd={2}>
            <BillListHeader type='p' emksReqdCd={2} />
          </BillListPchsr>)}
      </div>
    </div>
  </section>);
}
