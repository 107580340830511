import { AuditModel } from "model/common/auditModel";
import { FileModel } from "model/common/fileModel";

export class DocuMoni extends AuditModel {
  docId?: string;
  regDate?: Date;
  regrId?: string;
  chgDate?: Date;
  chgrId?: string;

  bizId?: string; //적용한 사업계획서 id
  bizNm?: string; // 적용한 사업계획서 명
  rlvnDataNm?: string; // 모니터링명 (제목)
  explCtt?: string | null; // 설명
  repAtflId?: string | null; // 첨부파일

  fileRespons: any;
  file?: FileModel;
}
