import moment from "moment/moment";
import ArcSelectbox from "components/arc/ArcSelectbox";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ChartUtil} from "utils/chartUtil";
import {BillListHeaderProps, BillSearchModel} from "pages/cb/bill/billList/BillListBase";
import {BillService} from "services/cb/BillService";
import {ResponseResultCode} from "model/response-api";
import CountUp from "react-countup";

export class BillListHeaderDataModel {
  reqCnt: number = 0;
  reqSum: number = 0;
  setlCnt: number = 0;
  setlSum: number = 0;
  nonSetlCnt: number = 0;
  nonSetlSum: number = 0;
  allNonSetlSum: number = 0;
}

export default function BillListHeader(props: BillListHeaderProps) {
  const initSearch = new BillSearchModel(props.emksReqdCd, props.type);
  const {t} = useTranslation();

  const searchMonthArray = ChartUtil.getMonthForCategories().map(m => {
    const regex = /[^0-9]/g;
    return {label: m, value: Number(m.replace(regex, ""))};
  });

  const [search, setSearch] = useState<BillSearchModel>(initSearch);
  const [data, setData] = useState<BillListHeaderDataModel>(new BillListHeaderDataModel());

  useEffect(() => {
    setSearch(initSearch);
    setData(new BillListHeaderDataModel());
  }, [props.emksReqdCd, props.type]);

  useEffect(() => {
    BillService.getInstance().findListHeaderData(search)
    .then(response => {
      if(response && ResponseResultCode.Y === response.resultCode) {
        if(response.data) {
          setData(response.data);
          return;
        }
      }
      setData(new BillListHeaderDataModel());
    }).catch(() => {
      setData(new BillListHeaderDataModel());
    })
  }, [search]);

  function handleChangeSelectMonth(e: any) {
    const convertValue = Number(e.target.value) - 1;
    const selectMonth = moment().set('month', convertValue).format('YYYYMM');
    setSearch({
      ...search,
      selectMonth: selectMonth
    });
  }

  return (
    <div className="col-wrap">
      <div className="col-md-12">
        <div className="cont">
          <h3 className="titT1">{t('cb.bill.monthlyReqdCondition')}</h3>
          <div className="icon-box-wrap6">
            <div className="year-tit">
              <span>{moment().format('YYYY')}{t('common.unit.year')}</span>
              <ArcSelectbox name="selectMonth"
                            selectedValue={moment(search.selectMonth).format('M')}
                            onChange={handleChangeSelectMonth}
                            options={searchMonthArray} />
            </div>
            <div className="cont">
              <ul>
                <li>
                  <div className="c1">
                    <span className="img">
                      <img src={require('assets/images/sub/bill_icon_img2.png')} alt=""/>
                    </span>
                    <span className="txt">
                      <span>{t('cb.bill.billPrice')}
                        <em data-count={data.reqCnt}>
                        <CountUp end={data.reqCnt} duration={0.5}>
                          {({countUpRef}) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>{t('common.unit.case')}</span>
                      <strong><em data-count={data.reqSum}>
                        <CountUp end={data.reqSum} duration={0.5}>
                          {({countUpRef}) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>{t('common.unit.won')}</strong>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="c1">
                    <span className="img">
                      <img src={require('assets/images/sub/count_icon_img1.png')} alt=""/>
                    </span>
                    <span className="txt">
                      <span>{'s' === props.type
                        ? t('cb.bill.header.collectAmt')
                        : t('cb.bill.header.payAmt')}
                        <em data-count={data.setlCnt}>
                          <CountUp end={data.setlCnt} duration={0.5}>
                            {({countUpRef}) => <em ref={countUpRef} />}
                          </CountUp>
                        </em>{t('common.unit.case')}</span>
                      <strong><em data-count={data.setlSum}>
                        <CountUp end={data.setlSum} duration={0.5}>
                          {({countUpRef}) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>{t('common.unit.won')}</strong>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="c2">
                    <span className="img">
                      <img src={require('assets/images/sub/count_icon_img2.png')} alt=""/>
                    </span>
                    <span className="txt">
                      <span>{'s' === props.type
                        ? t('cb.bill.header.nonCollectAmt')
                        : t('cb.bill.header.nonPayAmt')}
                        <em data-count={data.nonSetlCnt}>
                          <CountUp end={data.nonSetlCnt} duration={0.5}>
                            {({countUpRef}) => <em ref={countUpRef} />}
                          </CountUp>
                        </em>{t('common.unit.case')}</span>
                      <strong><em data-count={data.nonSetlSum}>
                        <CountUp end={data.nonSetlSum} duration={0.5}>
                          {({countUpRef}) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>{t('common.unit.won')}</strong>
                    </span>
                  </div>
                </li>
                <li>
                  <div className="c2">
                    <span className="img">
                      <img src={require('assets/images/sub/bill_icon_img3.png')} alt=""/>
                    </span>
                    <span className="txt">
                      <span>{'s' === props.type
                        ? t('cb.bill.header.allNonCollectAmt')
                        : t('cb.bill.header.allNonPayAmt')}</span>
                      <strong><em data-count={data.allNonSetlSum}>
                        <CountUp end={data.allNonSetlSum} duration={0.5}>
                          {({countUpRef}) => <em ref={countUpRef} />}
                        </CountUp>
                      </em>{t('common.unit.won')}</strong>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>);
}