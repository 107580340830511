import React, {useCallback, useEffect, useState} from "react";
import {BillModel} from "model/cb/billModel";
import {useTranslation} from "react-i18next";
import {parseNumberWithComma} from "utils/stringUtil";
import {useNavigate} from "react-router-dom";
import {BillListProps, BillSearchModel, CustomGridRenderer} from "pages/cb/bill/billList/BillListBase";
import ArcGrid, {GridColumn} from "components/arc/ArcGrid";
import moment from "moment";
import BillListSearch from "pages/cb/bill/billList/BillListSearch";
import ArcButton from "components/arc/ArcButton";
import {useAppDispatch} from "stores/hook";
import {closeModal, openModal} from "reducers/modalSlice";
import BillSelectTarget from "pages/cb/bill/billForm/BillSelectTarget";
import {CodeUtil} from "utils/codeUtil";
import ObjectUtil from "utils/objectUtil";
import {formatDCHDId} from "constants/constant";

export default function BillListSaler(props: BillListProps) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [column, setColumn] = useState<GridColumn[]>([]);
  const initSearch = new BillSearchModel(props.emksReqdCd, 's');
  const [search, setSearch] = useState<BillSearchModel>(initSearch);
  function getSearchCondition(conditions: BillSearchModel) {
    setSearch(conditions);
  }

  const searchBar = useCallback(() => {
    return (
      <BillListSearch type='s'
                      emksReqdCd={props.emksReqdCd}
                      search={getSearchCondition} />);
  }, [props.emksReqdCd]);

  useEffect(() => {
    let column: GridColumn[] = [];
    column.push({
      header: '결제상태',
      width: 160,
      align: 'center',
      renderer: {
        type: CustomGridRenderer,
        options: {type: 'status'}
      }
    });
    if(0 === props.emksReqdCd) {
      column.push({
        header: '거래구분',
        width: 150,
        align: 'center',
        name: 'reqdCd',
        formatter: (e: any) => CodeUtil.billReqdCdConvert(e.value)
      });
    }
    column.push({
      header: '수신자',
      width: 300,
      align: 'left',
      name: 'tchgrNm',
      formatter: (e: any) => {
        return ObjectUtil.isEmpty(e.value) ? formatDCHDId(e.row.tchgrId) : e.value;
      }
    });
    if(1 === props.emksReqdCd || 3 === props.emksReqdCd) {
      column.push({
        header: 3 === props.emksReqdCd ? '배출권' : '에너지원',
        width: 150,
        align: 'center',
        name: 'contract',
        formatter: (e: any) => e.value?.cntrRsrcNm
      });
    }
    column.push({
      header: '청구서명',
      align: 'left',
      name: 'title',
      formatter: (e: any) => {
        if (4 === e.row.reqdCd) {
          return `<button>
      ${moment(e.row.reqYmd).subtract(1, 'M').format('YYYY년 MM월')} 청구서 - ${CodeUtil.billReqdLdspTrdCdConvert(e.row.reqdInfo.ldspTrdCd)}
    </button>`;
        } else {
          return `<button>
      ${moment(e.row.reqYmd).subtract(1, 'M').format('YYYY년 MM월')} 청구서
    </button>`;
        }
      },
    });
    column.push({
      header: '청구금액',
      width: 200,
      align: 'right',
      name: 'reqAmt',
      formatter: (e: any) => parseNumberWithComma(e.value) + t('common.unit.won')
    });
    column.push({
      header: '청구일자',
      width: 120,
      align: 'center',
      name: 'reqYmd',
      formatter: (e: any) => moment(e.value).format('YYYY-MM-DD')
    });
    column.push({
      header: '납부기한',
      width: 120,
      align: 'center',
      name: 'payYmd',
      formatter: (e: any) => moment(e.value).format('YYYY-MM-DD')
    });
    column.push({
      header: '청구서 상태',
      width: 150,
      align: 'center',
      name: 'pblsYn',
      formatter: (e: any) => 'Y' === e.value ? '발행완료' : '임시저장'
    });

    column.push({
      header: '세금계산서 상태',
      width: 150,
      align: 'center',
      name: 'txblYn',
      formatter: (e: any) => 'Y' === e.value ? '발행완료' : '미발행'
    });
    setColumn(column);
  }, [props.emksReqdCd]);

  function handleClickPopupRegist(reqdCd: number) {
    if(1 === reqdCd) {
      dispatch(openModal({
        path: '/rp/ppa/bill/select/target',
        target: BillSelectTarget,
        wrapStyle: {minWidth: '80rem'},
        modalProps: {
          data: {emksReqdCd: 1},
          onClose: () => dispatch(closeModal()),
        }
      }));
    } else if(2 === reqdCd) {
      dispatch(openModal({
        path: '/ot/money/bills/dc/select/target',
        target: BillSelectTarget,
        wrapStyle: {minWidth: '20rem'},
        modalProps: {
          data: {emksReqdCd: 2},
          onClose: () => dispatch(closeModal()),
        }
      }));
    } else if(4 === reqdCd) {
      dispatch(openModal({
        path: '/ot/money/bill/hd/select/target',
        target: BillSelectTarget,
        wrapStyle: {minWidth: '22rem'},
        modalProps: {
          data: {emksReqdCd: 4},
          onClose: () => dispatch(closeModal()),
        }
      }));
    }
  }

  function handleClickRow(e: any) {
    const rowData = e.instance.getRow(e.rowKey) as BillModel;
    if(rowData && rowData.reqId) {
      rowData.pageType = 's';

      let path;
      switch (rowData.reqdCd) {
        case 1: path = "/rp/ppa/bills/detail"; break;
        case 2: path = "/ot/money/bills/dc/detail"; break;
        case 3: path = "/ot/trade/bills/detail"; break;
        case 4: path = "/ot/money/bills/hd/detail"; break;
      }

      if(path) {
        navigate(path, {state: JSON.stringify(rowData)});
      }
    }
  }

  return (
    <section className="section active">
      {props.children}
      <div className="col-wrap">
        <div className="col-md-12">
          {searchBar()}
        </div>
      </div>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <div className="tit-btns-wrap">
              <h3 className="titT1">{t('cb.bill.bill')}</h3>
            </div>
            {column.length > 0 && (
              <ArcGrid
                datasource={{readData: {url: '/cb/bill/bills', method: 'GET', initParams: search}}}
                columns={column}
                onClickRow={handleClickRow}
                useGridInfoHeader={true} />
            )}
            {(3 !== props.emksReqdCd && 0 !== props.emksReqdCd) && (
              <div className="btns ar">
                <ArcButton type="regist"
                           hasIcon={true}
                           onClick={() => handleClickPopupRegist(props.emksReqdCd)}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>);
}