import {
  BusinessFormContext,
  BusinessFormPageType,
  BusinessFormProvider
} from "pages/cp/reductionProject/history/BusinessForm/BusinessFormContext";
import {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {DocuBizPlnd} from "model/cp/DocuBizPlnd";
import BusinessFormInfo from "pages/cp/reductionProject/history/BusinessForm/BusinessFormInfo";
import BusinessFormExcel from "pages/cp/reductionProject/history/BusinessForm/BusinessFormExcel";
import {MhdlgService} from "services/cp/MhdlgService";
import {ResponseResultCode} from "model/response-api";
import {Mhdlg} from "model/cp/Mhdlg";
import {SelectDataModel} from "components/arc/ArcSelectbox";

export default function BusinessFormBase() {
  const context = useContext(BusinessFormContext);
  const location = useLocation();
  const [active, setActive] = useState<BusinessFormPageType>(BusinessFormPageType.INFO);
  const [mhdlgOption, setMhdlgOption] = useState<SelectDataModel[]>([]);

  let state: InstanceType<typeof DocuBizPlnd>;
  try {
    state = JSON.parse(location.state);
  } catch {
    state = location.state;
  }

  async function fetchMhdlgList() {
    const response = await MhdlgService.getInstance().getMhdlgListAll();
    if(response && ResponseResultCode.Y === response.resultCode) {
      return response.data?.map((m: Mhdlg) => {
        return {value: m.mhdlgId, label: m.mhdlgNm} as SelectDataModel;
      });
    }
    return [];
  }

  useEffect(() => {
    fetchMhdlgList().then(options => {
      setMhdlgOption(options);
    });
  }, []);

  return (<>
    <BusinessFormProvider>
      {(BusinessFormPageType.INFO === active && context && context.data) && (
        <BusinessFormInfo form={state}
                          mhdlgOptions={mhdlgOption}
                          onNext={() => setActive(BusinessFormPageType.EXCEL)}/>
      )}
      {BusinessFormPageType.EXCEL === active && (
        <BusinessFormExcel onBefore={() => setActive(BusinessFormPageType.INFO)} />
      )}
    </BusinessFormProvider>
    </>);
}