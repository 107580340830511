import {lazy} from "react";

const Bill = lazy(() => import("pages/cb/bill/billList/BillListBase"));
const BillResult = lazy(() => import("pages/cb/bill/billDetail/BillResult"));

const BillTaxAdmin = lazy(() => import("pages/cb/bill/billList/BillListAdminTax"));
const BillUnpaidAdmin = lazy(() => import("pages/cb/bill/billList/BillListAdminUnpaid"));

const VaBase = lazy(() => import("pages/cb/vAccount/VaBase"));
export const cbRouter: any = [
  { path: "/bill/total", element: <Bill emksReqdCd={0} />},
  { path: "/bill/result", element: <BillResult />},
  { path: "/bill/tax", element: <BillTaxAdmin />},
  { path: "/bill/unpaid", element: <BillUnpaidAdmin />},

  { path: "/cb/va", element: <VaBase />},
];
