import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useCallback, useEffect, useRef, useState} from "react";
import {ResponseResultCode} from "../../../model/response-api";
import {ZIndexUtils} from "primereact/utils";
import {DbAdminService} from "services/db/DbAdminService";

export default function Re100ParticipationStatusChart2(props: any) {
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [re100Bzknd, setRe100Bzknd] = useState<any>([]);
  const [options, setOptions] = useState<Highcharts.Options>({
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      plotBackgroundColor: undefined,
      plotBorderWidth: undefined,
      plotShadow: false,
      height: 350,
      margin: [0, 250, 0, -140],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: "",
    },
    legend: {
      layout: "horizontal",
      verticalAlign: "middle",
      align: "right",
      symbolHeight: 0,
      symbolWidth: 0,
      width: 480,
      x: 100,
      itemWidth: 200,
      squareSymbol: false,
      useHTML: true,
      labelFormatter: function () {
        const point: any = this;
        return (
            `<span style="display: flex; width:170px; justify-content: space-between; align-items: center; padding: 8px 0; border-bottom: 1px dashed #CCCCCC; font-size:.95rem;">
            <span style="width: 40%; text-align:left; color: #555555; font-size: 18px;">
              <em style="display:inline-block; width:20px; height:20px; border-radius: 50%; border: 5px solid ` +
            this.color +
            `;clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);">
              </em>
              ${this.name}
            </span>
            <span style="width:58%; text-align: right; color: #474747, font-size: 20px;">` +
            point.percentage?.toFixed(0) +
            `%</span></span>`
        );
      },
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    tooltip: {
      formatter: function(this:any):any {
        return `<div>
                  <span style="color:${this.color}">●</span>
                  <span style='font-weight:700;'>${this.point.name}</span>: 
                  <span style='font-weight:500;'>${this.percentage.toFixed(2)}</span> %
                </div>`;
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
        borderWidth: 0,
        innerSize: "65%",
        size: '90%',
        dataLabels: {
          useHTML: true,
          enabled: true,
          distance: -5,
          formatter() {
            return (
                `<div style="text-align:center;">
                    <span class="bar-graph-color-num" style="position:relative;display:inline-block;margin-top:5px;padding:2px 5px;font-weight:400;border-radius:15px;overflow:hidden;background:` +
                this.color +
                `">
                      <span style="position:relative;display:block;z-index:1;color:#fff;font-size:.45rem;font-weight:600;">` +
                Math.round(this.percentage * 100) / 100 +
                `%</span>
                      <em style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);">
                      </em>                        
                    </span>
              </div>`
            );
          },
        },
      },
      series: {},
    },
    colors: [
      "#563AA5", "#43C6DA", "#A4EBFF", "#647E9F",
      "#264267", "#212935",
      "#4B96F9", "#A4CCFF", "#859DB9", "#264267",
      "#2C3645",
    ],
    series: [
      {
        type: "pie",
        data: [],
      },
    ],
  });

  const callback = useCallback((HighchartsChart: Highcharts.Chart | null) => {
    setChart(HighchartsChart);
  }, []);

  useEffect(() => {
    getRe100BzkndList("RE100");
  }, []);

  useEffect(() => {
    const data = re100Bzknd.map((item: any) => ({
      name: item.bzkndNm,
      y: item.percent * item.count,
    }));
    setOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          type: "pie",
          data,
        },
      ],
    }));
  }, [re100Bzknd]);

  async function getRe100BzkndList(RE100: string) {
    const response = await DbAdminService.getInstance().getRe100BzkndList(RE100);

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setRe100Bzknd(response.data);
      } else {
        setRe100Bzknd([]);
      }
    }
  }

  return (
      <>
        {options && (
            <>
              <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  callback={callback}
                  ref={chartRef}
              />
            </>
        )}
      </>
  );
}