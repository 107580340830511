import React, { useState } from 'react';
import ArcFileUploader from "../../components/arc/ArcFileUploader";

export default function ArcFileUploaderExample () {

  const [uploadedDbFiles, setUploadedDbFiles] = useState<{ name: string, size: number }[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  // DB에서 가져온 파일 정보
  const dbFiles = [
    { name: 'file1.txt', size: 1024 , fileId:'123456'},
    { name: 'file2.jpg', size: 2048 , fileId:'84848484'},
  ];

  // 수정 된 dbFiles 불러오는 함수
  const handleDbUploaded = (dbFiles: { name: string, size: number }[]) => {
    setUploadedDbFiles(dbFiles);
  }

 // 업로드 된 파일 목록 받아오는 함수
  const handleUpload = (files: File[]) => {
    setUploadedFiles(files);
  };


  return (
    <div>
      <h1>File Uploader</h1>
      <ArcFileUploader dbFileList={dbFiles}
                       dbUpload={handleDbUploaded}
                       onUpload={handleUpload}
                       maxFileNum={5}
                       maxFileSizeMb={90} />
    </div>
  );
};