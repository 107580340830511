import DbAdminRe100Status from "pages/db/DbAdminRe100Status";
import DbUserRe100PartCompService
  from "pages/db/DbUserRe100PartCompService/DbUserRe100PartCompService";
import DbAdminSettlementStatus from "pages/db/DbAdminSettlementStatus";
import DbAdminResources from "pages/db/DbAdminResources";
import DbAdminTransaction from "pages/db/DbAdminTransaction";
import DbAdminRe100Planner from "pages/db/DbAdminRe100Planner";
import DbAdminRe100Participation from "pages/db/AdminRe100Participation/DbAdminRe100Participation";
import DbCarbonPlanner from "pages/db/DbCarbonPlanner";
import DbEnergyMarket from "pages/db/DbEnergyMarket";
import DbUserSettlementStatus from "pages/db/DbUserSettlementStatus";

export const dbRouter: any = [
  {path: "/db/re100/part-comp-service", element: <DbUserRe100PartCompService/>}, // admin1.x
  {path: "/db/re100Status", element: <DbAdminRe100Status/>}, // admin1
  {path: "/db/re100Participation", element: <DbAdminRe100Participation/>}, // admin2.x
  {path: "/db/settlementStatus", element: <DbAdminSettlementStatus/>}, // admin3
  {path: "/db/ppa/settlementStatus", element: <DbUserSettlementStatus />}, // role R002 dashboard
  {path: "/db/resources", element: <DbAdminResources/>}, // admin4
  {path: "/db/transaction", element: <DbAdminTransaction/>}, // admin5
  {path: "/db/re100Planner", element: <DbAdminRe100Planner/>}, // admin6
  {path: "/db/carbonPlanner", element: <DbCarbonPlanner/>}, // admin7
  {path: "/db/energyMarket", element: <DbEnergyMarket/>} // admin8
];

export const dbName: any = [
  {path: "/db/re100/part-comp-service", pageNm: ""}, // admin1.x
  {path: "/db/re100Status", pageNm: "산단 RE100 현황 대시보드"}, // admin1
  {path: "/db/re100Participation", pageNm: "산단 RE100 참여 현황"}, // admin2.x
  {path: "/db/settlementStatus", pageNm: "정산금 현황(재생E공급자)"}, // admin3
  {path: "/db/ppa/settlementStatus", pageNm: "정산금 현황"},
  {path: "/db/resources", pageNm: "공급자원"}, // admin4
  {path: "/db/transaction", pageNm: "전력구매계약(PPA) 현황"}, // admin5
  {path: "/db/re100Planner", pageNm: "RE100 플래너"}, // admin6
  {path: "/db/carbonPlanner", pageNm: "탄소 플래너"}, // admin7
  {path: "/db/energyMarket", pageNm: "에너지 마켓"} // admin8
]