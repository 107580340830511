import React, {ReactElement, useEffect} from 'react';

import TableUI, {TableUIProps} from "../common/sample/SampleUl";
import ArcCheckbox from "./ArcCheckbox";

export interface TableColumnProps {

  type: 'th' | 'td' | 'ul' | 'ol';
  showCheck?: boolean;
  checked?: boolean;
  width?: string;
  height?: string;
  content?: string | ReactElement;
  rowSpan?: number;
  colSpan?: number;
  classStr?: string;
  sampleData?: any;
  inputData?: any;
  olSpanValue?: string;
  olDivClass?: string;
  olClass?: string;
  scope?: string;
  onChecked?: (isChecked: boolean, data: any) => void;
}

export default function TableColumn(props: TableColumnProps) {
  const [tableColumn, setTableColumn] = React.useState(props);
  // const {
  // } = props;


  useEffect(() => {
    setTableColumn(props);
  }, [props]);

  const handleChange = (isChecked: any) => {

    props.onChecked!(isChecked, props);
  }

  if ('th' === tableColumn.type) {
    return (

        <th className={tableColumn.classStr} style={{width: tableColumn.width, height: tableColumn.height}}
            rowSpan={tableColumn.rowSpan}
            colSpan={tableColumn.colSpan}
        scope={tableColumn.scope}
        >
          {tableColumn.showCheck ?
              <ArcCheckbox checked={tableColumn.checked} onChecked={handleChange}></ArcCheckbox> : <></>}
          {tableColumn.content}</th>
    )
  } else if ('ul' === tableColumn.type) {
    return (
        <td className={tableColumn.classStr} width={tableColumn.width} height={tableColumn.height}
            rowSpan={tableColumn.rowSpan}
            colSpan={tableColumn.colSpan} scope={tableColumn.scope}>
          <TableUI dataSource={tableColumn.sampleData.sourceData} type={tableColumn.type}
                   olClass={tableColumn.sampleData.olClass}
                   ulClass={tableColumn.sampleData.ulClass}></TableUI>
        </td>
    )
  } else if ('ol' === tableColumn.type) {
    return (
        <td className={tableColumn.classStr} width={tableColumn.width} height={tableColumn.height}
            rowSpan={tableColumn.rowSpan}
            colSpan={tableColumn.colSpan} scope={tableColumn.scope}>
          <TableUI dataSource={tableColumn.sampleData} olClass={tableColumn.olClass}
                   type={tableColumn.type}></TableUI>
          <div className={tableColumn.olDivClass}>
            <span>{tableColumn.olSpanValue}</span>
          </div>
        </td>
    )
  } else {
    return (
        <td className={tableColumn.classStr} width={tableColumn.width} height={tableColumn.height}
            rowSpan={tableColumn.rowSpan}
            colSpan={tableColumn.colSpan} scope={tableColumn.scope}>{tableColumn.content}</td>
    )
  }

}