export class AuthConstant {
  public static readonly TOKEN_TYPE_KEY = "Bearer ";
  public static readonly ACCESS_TOKEN = "access_token";
  public static readonly REMEMBER_LOGIN = "remember";
  public static readonly EXPIRES_REMEMBER = 365; //day
  public static readonly EXPIRES_TOKEN = 1;
  public static readonly _TOKEN = "accessToken";
  public static readonly PUBLIC_KEY = "publicKey";

  public static readonly USER_UID_HAS_ROLE_R000 = "admin";
  public static readonly USER_UID_HAS_ROLE_R001 = "test_user1";
  public static readonly USER_UID_HAS_ROLE_R001_2 = "test_user2";
  public static readonly USER_UID_HAS_ROLE_R002 = "test_broker";
  public static readonly USER_UID_HAS_ROLE_R003 = "test_trade";
  public static readonly USER_UID_HAS_ROLE_R004 = "test_dc";

  // 임시
  public static readonly USER = "USER";
  public static readonly ADMIN = "ADMIN"; // 관리자
  public static readonly R001 = "R001";    // 일반사용자(산단기업)
  public static readonly R002 = "R002";    // 중개업자(전력판매)
  public static readonly R003 = "R003";    // 장외시장 사용자
  public static readonly R004 = "R004";    // 그리드운영자(급전시장)
  public static readonly R005 = "R005";    // 한전
}