import Api from "../common/Api";

export class AuthenticationService extends Api {
  private static service: AuthenticationService;

  public static getInstance(): AuthenticationService{
    if (!AuthenticationService.service) {
      AuthenticationService.service = new AuthenticationService();
    }
    return AuthenticationService.service;
  }

  /**
   * When user authentication has been lost or expired,
   * the user is redirected to the login page of the authorization server.
   * The current URL is stored in the session storage for use upon return after authentication.
   */
  public static redirectToSignIn() {
    const returnUrl = window.location.href;
    sessionStorage.setItem('returnUrl', returnUrl);
    window.location.href = process.env.REACT_APP_URL_SIGN_IN + '';
  }

  public static redirectReturnUrl() {
    const returnUrl = sessionStorage.getItem('returnUrl');
    sessionStorage.removeItem('returnUrl');
    window.location.href = returnUrl ?? '/';
  }

  public async getUserInfo() {
    return await super.get(process.env.REACT_APP_URL_USER_INFO + '');
  }

  public async getMenuPreview(params:any) {
    return await super.get<any>(process.env.REACT_APP_URL_CO + '/menu/preview', params);
  }
}