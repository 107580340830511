import React, {useEffect, useState} from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {RdmpCondModel} from "model/rp/RdmpCondModel";
import {RdmpCondService} from "services/rp/RdmpCondService";
import {ResponseResultCode} from "model/response-api";
import {useAppSelector} from "stores/hook";
import {ScnroInfoModel, ScnroModel} from "model/rp/ScnroModel";
import {SmcnCertService} from "services/rp/SmcnCertService";
import {PortfolioService} from "services/rp/PortfolioService";
import {PortfolioModel} from "model/rp/PortfolioModel";

export default function DbUserRe100PartCompServiceTab1() {

  const [, setRdmpCond] = useState<RdmpCondModel>({achvRt: 0});
  const [portfolio, setPortfolio] = useState<PortfolioModel>({});
  const [qntInfo, setQntInfo] = useState<any>({});
  const [latestScnro, setLatestScnro] = useState<ScnroModel | undefined>();
  const userInfo = useAppSelector(state => state.userInfo.userInfo);
  const [sumSmcnCert, setSumSmcnCert] = useState<any>({"1": 0, "2": 0, "3": 0, "4": 0, "5": 0});
  const [sumTotSmcnCert, setSumTotSmcnCert] = useState<any>(0);
  const [sumREC, setSumREC] = useState<any>({2: "0"});
  const [sumTotalRecPercent, setSumTotalRecPer] = useState<any>(0);
  const [sumTotalCertPercent, setSumTotalCertPer] = useState<any>(0);
  const [isLoading, setIsLoading] = useState<any>(true);

  const [drawChart, setDrawChart] = useState<any>({
        chart: {
          height: 385,
          backgroundColor: 'transparent',
        },
        colors: ['#545CC1', '#4C96F9', '#44C5DB', '#9EFFDE', '#85A0ED', '#3A588A'],
        title: {text: ''},
        exporting: {enabled: false},
        credits: {enabled: false},
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          symbolHeight: 15,
          borderWidth: 0,
          itemMarginTop: 10,
          useHTML: true,
          itemStyle: {
            fontSize: '14px',
          }
        },
        tooltip: {
          useHTML: true,
          formatter: function (this: any) {
            return `<div><span style="color: ${this.point.color}">● </span><span style="font-size: 14px;">${this.point.name}<br/>
              ${(this.point.y.toLocaleString()).length > 7 ? (Math.floor(this.point.y * 0.0001)).toLocaleString() + ' MWh' : this.point.y.toLocaleString() + ' kWh'}</div>`;
          }
        },
        plotOptions: {
          series: {
            showInLegend: true,
            colorByPoint: true,
            borderWidth: 0,
            type: 'pie',
            size: '100%',
            innerSize: '60%',
            dataLabels: {
              enabled: true,
              distance: '-22%',
              allowOverlap: false,
              useHTML: true,
              formatter: function (this: any) {
                return `<div class="pie-cont-bg"><strong class="pie-cont">${Math.round(this.point.percentage * 100) / 100} %</strong></div>`;
              }
            },
          }
        },
        series: []
      }
  );


  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/sub.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [sumSmcnCert, sumREC, sumTotalRecPercent, sumTotalCertPercent, sumTotSmcnCert]);


  useEffect(() => {
    if (userInfo.userId) {
      setIsLoading(true);

      getQntInfo()
      .then(() => {
        return Promise.allSettled([
          getLatestScnro(),
          getRdmpCond(),
          getAllSumSmcnCert(),
          getSumEach(),
          getPortfolio(),
        ]);
      })
      .then(() => {
        setIsLoading(false);
      });
    }
  }, [userInfo.userId]);


  useEffect(() => {
    if (latestScnro?.scnroInfoList) {
      let totalRec = 0;
      let totalCert = 0;
      let value6: number = 0;
      latestScnro.scnroInfoList?.forEach((value, index) => {
        if (value.emksFuflMeansCd == 3) {
          totalCert += getSumPercent(value);
        } else if (value.emksFuflMeansCd == 2 || value.emksFuflMeansCd == 1) {
          totalRec += getSumPercent(value);
        } else if (value.emksFuflMeansCd == 6) {
          value6 = index;
        }
      });

      let rec6 = {...latestScnro.scnroInfoList[value6], emksFuflMeansCd: 5};
      let cert6 = {...latestScnro.scnroInfoList[value6], emksFuflMeansCd: 4};
      let cert6Percent = getSumPercent(cert6);
      let rec6Percent = getSumPercent(rec6);
      totalCert += cert6Percent;
      totalRec += rec6Percent;
      setSumTotalRecPer(totalRec);
      setSumTotalCertPer(totalCert);
    }
  }, [latestScnro?.scnroInfoList, sumREC, sumSmcnCert]);

  useEffect(() => {
  }, [sumTotalCertPercent, sumTotalRecPercent]);


  function getGoalQnt(data: any, emksFuflMeansCd: string) {
    let result = 0;
    if (data?.scnroInfoList) {
      data.scnroInfoList!.map((value: any) => {
        if (value.emksFuflMeansCd == emksFuflMeansCd) {
          result = value.fuflMeansGoalQnt;
        }
      })
    }
    return result;
  }

  function checkRdmpCode() {
    let sum = 0;
    if (latestScnro?.scnroInfoList) {
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "2"))) {
        sum += sumREC[2] ?? 0;
      }
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "1"))) {
        sum += sumSmcnCert[1] ?? 0;
      }
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "3"))) {
        sum += sumSmcnCert[3] ?? 0;
      }
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "6"))) {
        sum += sumSmcnCert[4] ?? 0;
        sum += sumSmcnCert[5] ?? 0;
      }
    }
    return sum;
  }

  function checkRdmpCodeByList(inputNumb: number[]) {
    let sum = 0;

    if (latestScnro?.scnroInfoList) {
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "2"))) {
        if (inputNumb.includes(2)) {
          sum += sumREC[2] ?? 0;
        }
      }
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "1"))) {
        if (inputNumb.includes(1)) {
          sum += sumSmcnCert[1] ?? 0;
        }
      }
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "3"))) {
        if (inputNumb.includes(3)) {
          sum += sumSmcnCert[3] ?? 0;
        }
      }
      if ((latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "6"))) {
        if (inputNumb.includes(4)) {
          sum += sumSmcnCert[4] ?? 0;
        }
        if (inputNumb.includes(5)) {
          sum += sumSmcnCert[5] ?? 0;
        }
      }
    }
    return sum;
  }


  async function getLatestScnro() {
    const response = await RdmpCondService.getInstance().getLatestScnro()
    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setLatestScnro(response.data);
        setDrawChart((prevState: any) => {
          return {
            ...prevState,
            series: [{
              name: "",
              type: 'pie',
              data: [{
                name: '자가발전',
                y: getGoalQnt(response.data, '1')
              }, {
                name: 'PPA',
                y: getGoalQnt(response.data, '6')
              }, {
                name: '녹색프리미엄',
                y: getGoalQnt(response.data, '3')
              }, {
                name: 'REC',
                y: getGoalQnt(response.data, '2')
              }]
            }]
          }
        });
      } else {
        setLatestScnro({});
      }
    }
  }


  async function getRdmpCond() {
    const response = await RdmpCondService.getInstance().getRdmpCond();
    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setRdmpCond(response.data[0]);
      } else {
        setRdmpCond({});
      }
    }
  }

  async function getPortfolio() {
    const response = await PortfolioService.getInstance().getPortfolio();
    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setPortfolio(response.data)
      } else {
        setPortfolio({});
      }
    }
  }

  async function getQntInfo() {
    const response = await RdmpCondService.getInstance().getQntDetail();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setQntInfo(response.data);
      } else {
        setQntInfo({});
      }
    }
  }

  function getAllSumSmcnCert() {
    let currentDate = new Date();
    SmcnCertService.getInstance().getSumEach(currentDate.getFullYear().toString())
    .then(response => {
      if (response) if (ResponseResultCode.Y === response.resultCode) {
        let sum = 0;
        setSumSmcnCert(response.data);
        for (let key in response.data) {
          if (response.data.hasOwnProperty(key)) {
            sum += response.data[key];
          }
        }
        setSumTotSmcnCert(sum);
      }
    });
  }


  function getSumEach() {
    let currentDate = new Date();
    SmcnCertService.getInstance().getSumEach(currentDate.getFullYear().toString())
    .then(response => {
      if (response && ResponseResultCode.Y === response.resultCode) {
        setSumREC(response.data);
      }
    })
  }

  function getSumPercent(value: ScnroInfoModel) {
    let resultPercent: number;
    if (value.emksFuflMeansCd == 2) {
      resultPercent = Math.round((((sumREC[value.emksFuflMeansCd!] ?? 0)) / value.fuflMeansGoalQnt!) * 100)
    } else {
      resultPercent = Math.round(((sumSmcnCert[value.emksFuflMeansCd!] ?? 0) / value.fuflMeansGoalQnt!) * 100)
    }
    return resultPercent;
  }


  // 한전 에너지마켓플레이스
  function getCode(type: number): string {
    switch (type) {
      case 1:
        return "자가발전";
      case 2:
        return "REC";
      case 3:
        return "녹색 프리미엄";
      case 4:
        return "직접 PPA";
      case 5:
        return "제 3자 PPA";
      case 6:
        return "제 3자 PPA";
      default:
        return "합계";
    }
  }

  // E-market
  function getCodeEmarket(type: number): string {
    switch (type) {
      case 1:
        return "자가발전";
      case 2:
        return "REC";
      case 3:
        return "녹색 프리미엄";
      case 4:
        return "직접 PPA";
      case 5:
        return "제 3자 PPA";
      case 6:
        return "직접 PPA";
      default:
        return "합계";
    }
  }

  const listScnroInfo = () => {

    if (latestScnro?.scnroInfoList) {
      return <>{latestScnro?.scnroInfoList.map((value: any) => {
        if (value.emksFuflMeansCd === 6 || value.emksFuflMeansCd === 3) {
          return (
              <li key={`scnroInfo-${value.emksFuflMeansCd}`}>
                <div>
                  <strong className="box-graph-tit">{getCode(value.emksFuflMeansCd)}</strong>
                  <ul>
                    <li>
                      <span className="tit">재생에너지 사용량</span>
                      <span className="num">
                      <strong
                          data-count={value.emksFuflMeansCd === 6 ? (sumSmcnCert[5] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0)}
                          className="change"></strong><em> kWh</em>
                    </span>
                    </li>
                    <li>
                      <span className="tit">온실가스 감축량</span>
                      <span className="num">
                      <strong
                          data-count={value.emksFuflMeansCd === 3 ? 0 : ((portfolio.adjPwrEmsnCoef ? portfolio?.adjPwrEmsnCoef : 0) * 0.001 * (value.emksFuflMeansCd === 6 ? (sumSmcnCert[5] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0))).toFixed(2)}
                          className="change"></strong> <em> tCO2</em>
                    </span>
                    </li>
                  </ul>
                  <div className="graph-count-horizontal count-horizontal">
                  <span className="fill">
                    <em data-count={Math.round(((value.emksFuflMeansCd === 6 ? (sumSmcnCert[5] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0)) / value.fuflMeansGoalQnt) * 100) + "%"}>
                      <span>이행현황
                        <strong
                            data-count={Math.round(((value.emksFuflMeansCd === 6 ? (sumSmcnCert[5] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0)) / value.fuflMeansGoalQnt) * 100)}
                            className="change"> 0</strong>
                      </span>
                    </em>
                  </span>
                  </div>
                </div>
              </li>
          )
        }
      })}</>
    } else {
      return <></>
    }
  }

  const listScnroInfo2 = () => {
    if (latestScnro?.scnroInfoList) {
      return <> {
        latestScnro?.scnroInfoList!.map((value: any) => {
          if (value.emksFuflMeansCd === 1 || value.emksFuflMeansCd === 6) {

            return <li key={`scnroInfo2-${value.emksFuflMeansCd}`}>
              <div>
                <strong className="box-graph-tit">{getCodeEmarket(value.emksFuflMeansCd)}</strong>
                <ul>

                  <li><span className="tit">재생에너지 사용량</span><span className="num">
                          <strong
                              data-count={value.emksFuflMeansCd === 6 ? (sumSmcnCert[4] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0)}
                              className="change"></strong><em> kWh</em> </span>
                  </li>
                  <li><span className="tit">온실가스 감축량</span> <span className="num">
                                <strong
                                    data-count={((portfolio.adjPwrEmsnCoef ? portfolio?.adjPwrEmsnCoef : 0) * 0.001 * (value.emksFuflMeansCd === 6 ? (sumSmcnCert[4] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0))).toFixed(2)}
                                    className="change"></strong> <em> tCO2</em> </span>
                  </li>
                </ul>
                <div className="graph-count-horizontal count-horizontal">
                          <span className="fill">
                                <em data-count={Math.round(((value.emksFuflMeansCd === 6 ? (sumSmcnCert[4] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0)) / value.fuflMeansGoalQnt) * 100) + "%"}>
                                  <span> 이행현황 <strong
                                      data-count={Math.round(((value.emksFuflMeansCd === 6 ? (sumSmcnCert[4] ?? 0) : (sumSmcnCert[value.emksFuflMeansCd] ?? 0)) / value.fuflMeansGoalQnt) * 100)}
                                      className="change"> 0</strong> </span>
                                </em>
                              </span>
                </div>
              </div>
            </li>
          } else if (value.emksFuflMeansCd === 2) {
            return <li key={`scnroInfo2-${value.emksFuflMeansCd}`}>
              <div>
                <strong
                    className="box-graph-tit">{getCodeEmarket(value.emksFuflMeansCd)}</strong>
                <ul>

                  <li><span className="tit">재생에너지 사용량</span><span className="num">
                          <strong
                              data-count={(sumREC[value.emksFuflMeansCd] ?? 0)}
                              className="change"></strong><em> kWh</em> </span>
                  </li>
                  <li><span className="tit">온실가스 감축량</span> <span className="num">
                                <strong
                                    data-count={((portfolio.adjPwrEmsnCoef ? portfolio?.adjPwrEmsnCoef : 0) * 0.001 * ((sumREC[value.emksFuflMeansCd] ?? 0))).toFixed(2)}
                                    className="change"></strong> <em> tCO2</em> </span>
                  </li>
                </ul>
                <div className="graph-count-horizontal count-horizontal">
                          <span className="fill">
                                <em data-count={Math.round((((sumREC[value.emksFuflMeansCd] ?? 0)) / value.fuflMeansGoalQnt) * 100) + "%"}>
                                  <span> 이행현황 <strong
                                      data-count={Math.round((((sumREC[value.emksFuflMeansCd] ?? 0)) / value.fuflMeansGoalQnt) * 100)}
                                      className="change"> 0</strong> </span>
                                </em>
                              </span>
                </div>
              </div>
            </li>
          }
        })
      }
      </>
    }
  }

  return (
      <>
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="icon-box-wrap7 mgt5">
              <ul className="icon-box-flex">
                <li>
                  <div className="c1">
                    <strong>연간 누적 전력사용량 <em className="update">전일기준</em></strong>
                    <div>
                      <span><em
                          data-count={Math.floor(qntInfo?.totalQnt ?? 0)}
                          className="change">{Math.floor(qntInfo?.totalQnt ?? 0)}</em>kWh</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c2">
                    <strong>금월 한전 전력사용량</strong>
                    <div>
                      <span><em data-count={Math.floor(qntInfo?.monthlyPwrQnt ?? 0)} className="change">{Math.floor(qntInfo?.monthlyPwrQnt ?? 0)}</em>kWh</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c2">
                    <strong>금월 재생에너지 사용량</strong>
                    <div>
                      <span><em data-count={Math.floor(qntInfo?.monthlyUseQnt ?? 0)}
                                className="change">{Math.floor(qntInfo?.monthlyUseQnt ?? 0)}</em>kWh</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="c2">
                    <strong>누적 온실가스 감축량</strong>
                    <div>
                      <span><em
                          data-count={(qntInfo?.reduction ?? 0).toFixed(2)}
                          className="change">{(qntInfo?.reduction ?? 0).toFixed(2)}</em>tCO2</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h5 className="titT1">이행현황 <em className="update">전일기준</em></h5>
              <div className="col-wrap">
                <div className="col-md-7">
                  <div className="col-wrap">
                    <div className="col-md-4 pd0 ac">
                      <div className="cylinder-graph-wrap">
                        <div className="cylinder-tit">
                          <span>현재 RE</span>
                          <strong><em
                              data-count={latestScnro?.emksSimlCgCd ? Math.floor((checkRdmpCodeByList([3, 5]) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100)
                                  + Math.floor(((checkRdmpCodeByList([1])
                                      + (latestScnro?.scnroInfoList ? (latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "2") ? sumREC[2] : 0) : 0) + checkRdmpCodeByList([4])) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0 + "%"}
                              className="change"></em>%</strong>
                        </div>
                        <div className="cylinder-graph">
                          <div className="cylinder-wrap">
                            <div className="cylinder">
                              <div className="cylinder-top"></div>
                            </div>
                            <div className="water"
                                 data-count={latestScnro?.emksSimlCgCd ? Math.round((checkRdmpCode() / Math.floor(qntInfo?.totalQntForRe ?? 0)) * 100) : 0}></div>
                          </div>
                          <div className="cylinder-bottom"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 pd0">
                      <div className="box-style3 pdt40 pdb40 mgt5">
                        <h3 className="titT1 tit">한전 에너지마켓플레이스</h3>
                        <div className="graph-list-type5">
                          <div className="graph-list-gauge">
                            <div className="txt">
                              <span></span>

                              <strong
                                  data-count={latestScnro?.emksSimlCgCd ? Math.floor((checkRdmpCodeByList([3, 5]) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0}
                                  className="change">{latestScnro?.emksSimlCgCd ? Math.floor(((checkRdmpCodeByList([3, 5])) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0}</strong><em>%</em>
                            </div>
                            <div className="graph-count-vertical count-vertical">
                              <em data-count={latestScnro?.emksSimlCgCd ? Math.floor((checkRdmpCodeByList([3, 5]) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0 + "%"}></em>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="box-style3 pdt40 pdb40 mgt5">
                        <h3 className="titT1 tit">E-Market</h3>
                        <div className="graph-list-type5">
                          <div className="graph-list-gauge">
                            <div className="txt">
                              <span>현재 RE</span>
                              <strong
                                  data-count={latestScnro?.emksSimlCgCd ? Math.floor(((checkRdmpCodeByList([1])
                                      + (latestScnro?.scnroInfoList ? (latestScnro?.scnroInfoList!.some(item => item.emksFuflMeansCd!.toString() === "2") ? sumREC[2] : 0) : 0) + checkRdmpCodeByList([4])) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0}
                                  className="change">{latestScnro?.emksSimlCgCd ? Math.floor(((checkRdmpCodeByList([1, 4])) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0}</strong><em>%</em>
                            </div>
                            <div className="graph-count-vertical count-vertical">
                              <em data-count={latestScnro?.emksSimlCgCd ? Math.floor(((checkRdmpCodeByList([1, 4])) / (Math.floor(qntInfo?.totalQntForRe ?? 0))) * 100) : 0 + "%"}></em>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="box-style3 pdt10 pdb10 mgt5">
                    <h3 className="titT1 tit">이행방법별 비율</h3>
                    <div id="graph-typeA" className="chart">
                      <HighchartsReact highcharts={Highcharts} options={drawChart}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1 tit-bg-type tit-bg-type3 ac mgb20">한전 에너지마켓플레이스</h3>
              <div className="box-graph-fixed-scroll">
                <ul className="box-graph box-graph-fixed">
                  {
                    listScnroInfo()
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>


        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1 tit-bg-type tit-bg-type4 ac mgb20">E-Market</h3>
              <div className="box-graph-fixed-scroll">
                <ul className="box-graph box-graph-fixed">
                  {
                    listScnroInfo2()
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
