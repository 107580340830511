import {lazy} from "react";

const MyPage = lazy(() => import("pages/mypage/MyPage"));
const MyPageInfo = lazy(() => import("pages/mypage/MyPageBoard"));
const MyPageService = lazy(() => import("pages/mypage/MyPageService"));
export const mpRouter: any[] = [
  { path: "mypage", element: <MyPage />},
  { path: "mypage/info/:type?", element: <MyPageInfo />},
  { path: "mypage/service", element: <MyPageService />},
];

export const myPageName: any = [
  {path: "/mypage", pageNm: "내 정보 관리"}, // admin1.x
  {path: "/mypage/info/myInfo", pageNm: "내 정보 관리"},
  {path: "/mypage/info/myKepco", pageNm: "내 정보 관리"},
  {path: "/mypage/service", pageNm: "서비스 가입 내역"},
];
