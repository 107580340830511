import ArcButton from "components/arc/ArcButton";
import ArcDatePicker, { ArcDatePickerResult } from "components/arc/ArcDatePicker";
import ArcGrid, {CustomTitleRenderer} from "components/arc/ArcGrid";
import AppAlert from "components/common/AppAlert";
import { t } from "i18next";
import { ResponseResultCode } from "model/response-api";
import moment from "moment";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openModal } from "reducers/modalSlice";
import { DocuEvdnService } from "services/cp/DocuEvdnService";

export default function EvdnList() {
  const [startDate, setStartDate] = useState(moment().format('YYYY0101'));
  const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const addMonthToDate = (month: number) => {
    if (!endDate) {
      setEndDate(moment.utc().local().format('YYYYMMDD'));
      const newDate = moment().subtract(month, 'months').toString();
      setStartDate(moment.utc(newDate).local().format('YYYYMMDD'));
    } else {
      const newDate = moment(endDate, 'YYYYMMDD').subtract(month, 'months').toString();
      setStartDate(moment.utc(newDate).local().format('YYYYMMDD'));
    }
  }

  function insertBtn() {
    navigate(`/cp/reduce/docu/evdnList/insert`);
  }

  function handleClickDetail(event: any) {
    const rowData = gridRef.current.getInstance().getRow(event.rowKey);
    if (rowData) {
      const id = rowData.docId;
      navigate(`/cp/reduce/docu/evdnList/detail/${id}`);
    }
  }

  const gridRef: any = useRef<typeof ArcGrid>();

  function formatRegDt(dateTimeString: any) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function formatAplyYmd(value: any): string {
    const year = value.substring(0, 4);
    const month = value.substring(4, 6);
    const day = value.substring(6, 8);
    return `${year}-${month}-${day}`;
  }


  const handleClickDelete = () => {
    const checkedNum = gridRef.current.getInstance().getCheckedRows().length;
    if (checkedNum > 0) {
      dispatch(
        openModal({
          path: "cp/confirm/apply/delete/confirm",
          target: AppAlert,
          wrapStyle: { width: "20rem" },
          confirmProps: {
            type: "delete",
            title: `선택된 ${checkedNum}개의 방법론을 ${t("confirm.label.message.delete")}`,
            onConfirm: handleDel,
          },
        })
      );
    } else {
      toast.warning(t('common.message.warn.noDeleteTarget'));
    }
  };

  async function handleDel() {
    let chkArray = gridRef.current.getInstance().getCheckedRows();
    if (chkArray.length > 0) {
      gridRef.current.getInstance().removeCheckedRows();
      try {
        const response = await DocuEvdnService.getInstance().deleteDocuEvdns(chkArray);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t("common.message.success.delete", { title: `${chkArray.length}개의 방법론이` }));
          } else {
            toast.error(t("common.message.error.delete", { title: '방법론' }));
          }
        } else {
          toast.error(t('common.message.error.duringProcessing'));
        }
      } catch (error) {
        toast.error(t("common.message.error.system"));
      }
    }
  }

  const gridColumns: any[] = [
    {
      header: "docId",
      name: "docId",
      hidden: true,
    },
    {
      header: "구분",
      name: "itmNm",
      align: "left",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value;
        }
        return "";
      },
    },
    {
      header: "배출시설",
      name: "emsnFcltNm",
      align: "left",
      whiteSpace: "normal",
      renderer:{
        type:CustomTitleRenderer
      },
    },
    {
      header: "증명자료명",
      name: "rlvnDataNm",
      align: "left",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value;
        }
        return "";
      },
    },
    {
      header: "적용일",
      name: "aplyYmd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {

        if (e.value !== null && e.value !== undefined) {
          return formatAplyYmd(e.value);
        }
        return '';
      },
    },
    {
      header: "등록일",
      name: "regDate",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return formatRegDt(e.value);
        }
        return "";
      },
    },
  ];

  const handleChangeDate = (result: ArcDatePickerResult) => {
    setStartDate(result.fromDateString ?? "");
    setEndDate(result.toDateString ?? "");
  };

  return (
    <>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <h3 className="titT1">내역 조회</h3>
            <div className="srh-filter2 mgb20">
              <ul>
                <li>
                  <span className="titT3 mgb0">조회기간</span>
                </li>
                <li>
                  <div className="calendar-picker">
                    <ArcDatePicker fromDate={startDate}
                      toDate={endDate}
                      selectionMode={"range"}
                      onChange={handleChangeDate}
                      id={""}
                      name={""} />

                  </div>
                  <div className="dateBtn">
                    <ArcButton type={"text"} text={"1개월"} onClick={() => addMonthToDate(1)} />
                    <ArcButton type={"text"} text={"3개월"} onClick={() => addMonthToDate(3)} />
                    <ArcButton type={"text"} text={"6개월"} onClick={() => addMonthToDate(6)} />
                    <ArcButton type={"text"} text={"1년"} onClick={() => addMonthToDate(12)} />
                  </div>
                </li>
              </ul>
            </div>
            <div id="gridTbl1">
              <ArcGrid
                gridRef={gridRef}
                datasource={{
                  readData: {
                    url: "/cp/docuEvdn/list", method: "GET", initParams: {
                      usePrdStrtYmd: startDate,
                      usePrdEndYmd: endDate
                    }
                  }
                }}
                columns={gridColumns}
                rowHeaders={["checkbox", "rowNum"]}
                useGridInfoHeader={true}
                usePagination={true}
                onCheckRow={() => { }}
                onClickRow={handleClickDetail}
              />

            </div>

            <div className="btns ar">
              <ArcButton type={"delete"} hasIcon={true} onClick={handleClickDelete} />
              <ArcButton type={"regist"} hasIcon={true} onClick={insertBtn} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}