import i18n from "config/translation/i18n";
import {PrstsCdType} from "model/common/code";

export class CodeUtil {

  public static trdKndCdConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.trdKndCd.${code}`);
    } else {
      return "";
    }
  }
  public static rpdcCodeConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.rpdcCd.${code}`);
    } else {
      return "";
    }
  }

  public static prstsCodeConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.prstsCd.${code}`);
    } else {
      return "";
    }
  }

  public static prstsCodeWPConvert(code: PrstsCdType): string {
    if(code) {
      return i18n.t(`common.code.prstsCdWP.${code}`);
    }
    return "";
  }

  public static trdAplStsCodeConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.trdAplStsCd.${code}`);
    } else {
      return "";
    }
  }

  public static cntrPrgsStsCodeConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.cntrPrgsStsCd.${code}`);
    } else {
      return "";
    }
  }

  public static cntrOtranYnConvert(code: string): string {
    if(code) {
      return i18n.t(`cb.cntr.otranYn.${code}`);
    }
    return "";
  }

  public static reqdCdCodeConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.emksReqdCd.${code}`);
    }
    return "";
  }

  public static makeCpCommonCodeOptions(data: any[]) {
    return data.map((item: any) => ({
      label: item.comCdExpl,
      value: item.comCdId,
    }))
  }

  public static makeCpUnitOptions(data: any[]) {
    return data.map((item: any) => ({
      label: item.unitNm,
      value: item.unitId,
      cnvrUnitNm: item.cnvrUnitNm,
      enrgUnitNm: item.enrgUnitNm,
    }))
  }

  public static cpReduceGoalCategoryConvert(code: number) {
    if(code) {
      return i18n.t(`cp.roadMap.reduceGoal.goalType.${code}`);
    } else {
      return "";
    }
  }

  public static billReqdCdConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.emksReqdCd.${code}`);
    } else {
      return "";
    }
  }

  public static billReqdLdspTrdCdConvert(code: number): string {
    if(code) {
      return i18n.t(`common.code.emksLdspTrdClCd.${code}`);
    } else {
      return "";
    }
  }

}