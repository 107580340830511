import React, {CSSProperties, useEffect, useState} from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ChartColorProps {
  linearGradient: any;
  stops: [number, CSSProperties['color']][];
}

export interface ChartDataProps {
  name: string;
  data: number | number[];
  color?: CSSProperties['color'] | ChartColorProps | undefined;
  pointPlacement?: number | undefined;
}

export const chartDefaultData: ChartDataProps = {
  name: 'default',
  data: [0],
}

export interface AppChartProps {
  chartOption: Object;
  chartDatas: ChartDataProps[] | ChartDataProps;
  divClass?: string;
}


export default function AppChart(props: AppChartProps) {
  const {
    chartOption, chartDatas, divClass
  } = props;

  Highcharts.setOptions({
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
    exporting:{enabled:false},
    credits:{enabled:false},
    tooltip:{shared:true,headerFormat: ''},
  })

  const [options, setOptions] = useState(chartOption);

  const makeSeries = (item: ChartDataProps) => {
    const series = {
      name: item.name,
      pointPlacement: item.pointPlacement??0,
      color: item.color
    };

    if('number' === typeof item.data) {
      return {...series, y: item.data};
    } else {
      return {...series, data: item.data};
    }
  }

  const setDatasIntoOption = () => {
    const datas = chartDatas;

    let tempSeries: any[] = [];
    if(datas) {
      if(Array.isArray(datas)) {
        datas.forEach(item => {
          tempSeries.push(makeSeries(item));
        })
      } else {
        tempSeries.push(makeSeries(datas));
      }
    } else {
      tempSeries.push({});
    }

    setOptions({
      ...chartOption,
      series: tempSeries,
    });
  }

  useEffect(() => {
    setDatasIntoOption();
  }, [chartDatas]);

  return (
    <div className={divClass ?? "chart"}>
      <HighchartsReact highcharts={Highcharts} options={options}/>
    </div>
  )
}