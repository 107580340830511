import React, {useEffect} from 'react';
import TableColumn, {TableColumnProps} from "./ArcTableColumn";

export interface TableRowProps {
  row: TableColumnProps[];
  trClassName?: string;
  onRowChecked?: (data: any) => void; // Add callback for row checked event
}

export default function TableRow(props: Readonly<TableRowProps>) {
  const [trClassName, setTrClassName] = React.useState(props.trClassName);
  const [tableRow, setTableRow] = React.useState(props);
  useEffect(() => {
    setTableRow(props);
  }, [props]);

  const handleColumnChecked = (isChecked: boolean, columnIndex: number) => {
    let updatedRow = [...props.row]; // Create a copy of the row
    updatedRow[columnIndex] = {...updatedRow[columnIndex], checked: isChecked}; // Update the checked state of the column

    if (props.onRowChecked) {
      props.onRowChecked(updatedRow);

    }
  }

  const column = (item: TableColumnProps, index: number) => {
    return (
      <TableColumn key={crypto.randomUUID()} checked={item.checked} inputData={item.inputData}
                   showCheck={item.showCheck}
                   onChecked={(isChecked: boolean) => handleColumnChecked(isChecked, index)} // Pass columnIndex to handleColumnChecked
                   olDivClass={item.olDivClass}
                   olSpanValue={item.olSpanValue} olClass={item.olClass}
                   sampleData={item.sampleData} content={item.content} classStr={item.classStr}
                   colSpan={item.colSpan} rowSpan={item.rowSpan} height={item.height}
                   width={item.width}
                   type={item.type} scope={item.scope} />
    )
  }

  return (
    <tr className={trClassName}>
      {tableRow.row.map((item: TableColumnProps, index: number) => column(item, index))}
    </tr>
  );
}
