export interface ResponseFcm {
  messageId: string | null;
  result: boolean;
  errorCode: FcmErrorType | null;
}

export const FcmErrorCode = {
  UNKNOWN_UID: "UNKNOWN_UID",
  UNREGISTERED: "UNREGISTERED",
} as const;
export type FcmErrorType = typeof FcmErrorCode[keyof typeof FcmErrorCode];