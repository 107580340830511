
import React, {Fragment, useEffect} from "react"
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import ArcChart from "../../components/arc/ArcChart";



export default function ArcHighChartExample() {

    useEffect(() => {
    }, [])


    const graphTypeA = {

        colors: ['#3c92ff', '#2bd7f6'],
        chart: {
            type: 'column',
            backgroundColor: 'transparent',
            height: 450
        },
        title: {
            text: ''
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        plotOptions: {
            series: {
                borderRadius: 0,
                borderWidth: 0,
                dataLabels: {
                    useHTML: true,
                    inside: true,
                    enabled: true,
                    crop: false,
                    y: -25,
                    verticalAlign: 'top',
                    formatter(this: any) {
                        return '<span class="bar-graph-color-num">' + this.y + '</span>';
                    }
                }
            }
        },
        tooltip: {
            headerFormat: ''
        },
        legend: {
            symbolRadius: 0,
            symbolHeight: 10,
            align: 'right',
            verticalAlign: 'top',
            itemStyle: { 'color': '#fff' }
        },
        xAxis: {
            categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'], min: 0, title: { text: '' }, gridLineColor: '#666b78', tickColor: '#666b78', labels: { style: { color: '#d1d7e4' } }
        },
        yAxis: {
            title: { text: '(MWh)', style: { color: '#d1d7e4' } }, gridLineColor: '#666b78', tickColor: '#666b78', lineColor: '#666b78', labels: {
                style: { color: '#d1d7e4' }
            }
        },
        series: [
            {
                name: '다배출원 분석',
                data: [15, 5, 7, 4, 4, 6, 7, 8, 10, 13, 8, 15]
            }, {
                name: '탄소중립 시뮬레이션',
                data: [20, 30, 17, 21, 20, 25, 28, 30, 25, 20, 21, 20]
            }
        ]
    }

    const graphTypeA2 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 190,
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            events: {
                render: function () {
                    var chart: any = this,
                        sum = 0;
                    if (chart.textGroup) {
                        chart.textGroup.destroy()
                        chart.textGroup = undefined;
                    }
                    chart.textGroup = chart.renderer.g('c').add().toFront()
                    chart.series[0].data.forEach(function (value: any) {
                        sum += value.y;
                    })
                    var customText = chart.myCustomText = chart.renderer.html(
                        '<div class="donut-total">' + sum + '<em>건</em></div>', chart.plotWidth / 1.95, chart.plotHeight / 1.9
                    )
                        .add(chart.textGroup);
                    chart.textGroup.translate(-chart.myCustomText.getBBox().width / 2);
                }
            }
        },
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
            series: {
                showInLegend: true,
                borderWidth: 0,
                colorByPoint: true,
                type: 'pie',
                size: '100%',
                innerSize: '60%',
                dataLabels: {
                    enabled: true,
                    distance: '-22%',
                    allowOverlap: false,
                    useHTML: true,
                    format: '<div class="pie-cont-bg"><strong class="pie-cont">{point.name}</strong></div>',
                },
            }
        },
        colors: ['#3240FF', '#48E3FF'],
        series: [{
            name: '에너지거래 현황',
            type: 'pie',
            data: [{
                name: '공용 ESS',
                y: 80,
                sliced: false,
                useHTML: true,
                selected: true
            }, {
                name: '공용 태양광',
                y: 20
            }]
        }]
    }


    const graphTypeB = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            height: 350,
            margin: [30, 0, 0, 350],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 250,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        legend: {
            layout: 'vertical',
            verticalAlign: 'middle',
            align: 'left',
            symbolRadius: 2,
            useHTML: true,
            navigation: {
                activeColor: '#3E576F',
                animation: true,
                arrowSize: 8,
                inactiveColor: '#CCC',

                style: {
                    fontWeight: 'bold',
                    color: '#fff',
                    fontSize: '12px',
                    align: 'middle',
                }
            },
            labelFormatter: function (this: any) {
                if (this.name === '구분') {
                    return `<span class="tbl-legend" style="width:300px;display:inline-block;padding:10px 0;border-bottom:1px solid #7a7f91;border-top:1px solid #7a7f91;background:#434750;font-size:.95rem;"><span style="float:left;width:70%;text-align:center;">${this.name}</span><span style="float:left;width:30%;text-align:center;">비율(%)</span></span>`
                }
                return `<span style="width:300px;display:inline-block;padding:8px 0;border-bottom:1px solid #4c525f;font-size:.95rem;"><span style="float:left;width:70%;text-align:left;" ><em style="display:inline-block;width:.75rem;height:.75rem;margin-right:.25rem;vertical-align:middle;border-radius:50%;border:4px solid ` + this.color + `"></em>${this.name}</span><span style="float:left;width:30%;text-align:center;"><em style="color:` + this.color + `;">${this.y}</em> %</span></span>`
            },
        },
        exporting: { enabled: false },
        credits: { enabled: false },
        tooltip: {
            valueSuffix: "%",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true
            },
            series: {
                showInLegend: true,
                borderWidth: 0,
                colorByPoint: true,
                innerSize: '60%',
                //size:'80%',
                allowPointSelect: true,
                dataLabels: {
                    useHTML: true,
                    enabled: true,
                    distance: 10,
                    format:
                        '<div style="text-align:center;">' +
                        '<span class="bar-graph-name" style="display:block;font-size:.75rem;font-weight:400;color:#fff;">{point.name}</span>' +
                        '<span class="bar-graph-color-num" style="position:relative;display:inline-block;margin-top:5px;padding:2px 5px;font-size:.5rem;font-weight:400;border-radius:15px;overflow:hidden;background:{point.color}">' +
                        '<span style="position:relative;z-index:1;color:#fff;font-size:.8rem;">{point.y}%</span><em style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);"></em></span>' +
                        '</div>'
                },
            },
        },

        colors: ['#545cc2', '#4b96f9', '#44c5dc', '#9effdf', '#85a1ed', '#3a588a', '#5faca6'],
        series: [{
            data: [{
                name: '구분',
                y: null,
            },]
        }, {
            name: '업종별 한국전력 사용량',
            colorByPoint: true,
            data: [{
                name: '기계',
                y: 44,
            }, {
                name: '전기전자',
                y: 30
            }, {
                name: '자제건설(자가발전)',
                y: 22.4
            }, {
                name: '석유화학',
                y: 11
            }, {
                name: '섬유의복',
                y: 4
            }, {
                name: '비제조',
                y: 3
            }, {
                name: '목재종이',
                y: 2
            }, {
                name: '비금속',
                y: 2
            }, {
                name: '기타',
                y: 2
            }, {
                name: '운송장비',
                y: 1
            }, {
                name: '철강',
                y: 1
            }, {
                name: '음식료',
                y: 1
            },
            ],
        },
        ],
    };


    const chartStyle = `
     .highcharts-legend-item rect {
      display: none;
    }

    .highcharts-legend-item:last-of-type>span>span {
      border-bottom: 2px solid #7a7f91 !important;
    }
  `;



    return (

        <div style={{backgroundColor:"black"}}>



            <div></div>
            <div></div>
            <div></div>
            <div id="graph-typeB" className="chart">
            <HighchartsReact style={chartStyle} highcharts={Highcharts} options={graphTypeA} />
            {/*<HighchartsReact style={chartStyle} highcharts={Highcharts} options={graphTypeB} />*/}

            </div>




        </div>
    )
}