import ArcCheckbox from "components/arc/ArcCheckbox";
import ArcDatePicker from "components/arc/ArcDatePicker";
import ArcGrid, {
  ArcGridCheckResult,
  CustomSelectCommonCodeRenderer,
  GridApi,
  GridColumn,
  GridEditor
} from "components/arc/ArcGrid";
import { AuthConstant } from "constants/authConstant";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ComCdService } from "services/cp/ComCdService";
import { useAppSelector } from "stores/hook";
import { CodeUtil } from "utils/codeUtil";
import { UserInfoUtil } from "utils/userInfoUtil";

export default function LoadTime() {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const { t } = useTranslation();
  const [tmzCd, setTmzCd] = useState<any[]>([]);
  const today: Date = new Date();

  const [aplyYy, setAplyYy] = useState(today.getFullYear());
  const [isGroup, setIsGroup] = useState(true);

  const onChangeAplyYy = (event: any) => {
    setAplyYy(event.fromDateString.substring(0, 4));
  };

  // select로 보여질 단위 조회
  useEffect(() => {
    getTmzCd();
  }, []);

  function getTmzCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_LD_TMZ_CL_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setTmzCd(cdOptions);
        } else {
          setTmzCd([]);
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  // 그리드. 체크박스
  const gridRef: any = useRef<typeof ArcGrid>();
  const checkedRow = (data: ArcGridCheckResult) => {
  };

  function formatTime(value: string): string {
    if (value && value.length === 8) {
      const firstPart = value.substr(0, 2);
      const secondPart = value.substr(2, 2);
      const thirdPart = value.substr(4, 2);
      const fourthPart = value.substr(6, 2);

      return `${firstPart}:${secondPart}~${thirdPart}:${fourthPart}`;
    }

    return value;
  }

  // 그리드 컬럼
  const gridColumns: GridColumn[] = [
    {
      header: "mngId",
      name: "mngId",
      hidden: true,
    },
    {
      header: "시간대별",
      name: "tmzCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "여름철 (6월 1일~8월 31일)",
      name: "smrTime",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return formatTime(e.value);
        }
        return "";
      },
    },
    {
      header: "봄(3월 1일~5월 31일), 가을철(9월 1일~ 10월 31일)",
      name: "sprngTime",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return formatTime(e.value);
        }
        return "";
      },
    },
    {
      header: "겨울철 (11월 1일~익년 2월 말일)",
      name: "wntrTime",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        if (e.value !== null && e.value !== undefined) {
          return formatTime(e.value);
        }
        return "";
      },
    },
  ];

  //그리드 수정
  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: {
      aplyYy: (aplyYy ?? "").toString(),
      isGroup: isGroup,
    },
    editableColumns: [
      {
        columnName: "tmzCd",
        editorType: CustomSelectCommonCodeRenderer,
        options: {
          onChange: handleChangeEditor,
          comCd: tmzCd,
          validation: {
            validatorFn: (value: any) => {
              return value !== null && value?.comCdExpl !== undefined;
            }
          }
        },
      },
      {
        columnName: "smrTime",
        editorType: "time",
        options: {
          onChange: handleChangeEditor,
          validation: {
            dataType: "string",
          },
        },
      },
      {
        columnName: "sprngTime",
        editorType: "time",
        options: {
          onChange: handleChangeEditor,
          validation: {
            dataType: "string",
          },
        },
      },
      {
        columnName: "wntrTime",
        editorType: "time",
        options: {
          onChange: handleChangeEditor,
          validation: {
            dataType: "string",
          },
        },
      },
    ],
  };

  // 그리드 수정함수
  function handleChangeEditor(e: any) {
  }

  //  데이터 전송
  const adminGridApi: GridApi = {
    readData: {
      url: "/cp/mngLdTmz",
      method: "GET",
      initParams: {
        aplyYy: aplyYy,
        isGroup: isGroup
      },
    },
    createData: { url: "/cp/mngLdTmz", method: "POST" },
    updateData: { url: "/cp/mngLdTmz", method: "PUT" },
    deleteData: { url: "/cp/mngLdTmz", method: "DELETE" },
  };

  const gridApi: GridApi = {
    readData: {
      url: "/cp/mngLdTmz",
      method: "GET",
      initParams: {
        aplyYy: aplyYy,
        isGroup: isGroup
      },
    },
  };

  const handleIsGroup = (isChecked: any) => {
    setIsGroup(isChecked)
  }

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <div>
              <h3 className="titT1">계절별 부하시간대</h3>
              <p className="font-FM">적용 요금제 : 일반용전력(갑)Ⅱ, 산업용전력(갑)Ⅱ, 일반용전력(을), 산업용전력(을), 교육용전력(을)</p>
            </div>
            <ArcDatePicker
              onChange={onChangeAplyYy}
              fromDate={String(aplyYy)}
              id={"year"}
              name={"year"}
              viewType={"year"}
              placeholders={{ fromDate: "YYYY" }} />
          </div>
          <span className="checkbox-radio-type02">
            <label htmlFor="checkbox2">
              <ArcCheckbox onChecked={handleIsGroup} text={"그룹"} id={"그룹"} name={"그룹"}
                checked={isGroup} isAlt={true} />
            </label>
          </span>
          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                gridRef={gridRef}
                key={tmzCd.length}
                datasource={adminGridApi}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                usePagination={false}
                useGridInfoHeader={true}
                editor={gridEditor}
                onCheckRow={checkedRow}
                isTest={true}
              />
              :
              <ArcGrid
                gridRef={gridRef}
                datasource={gridApi}
                columns={gridColumns}
                usePagination={false}
                useGridInfoHeader={true}
                onCheckRow={checkedRow}
              />
            }
          </div>
          <div className="box-style2">
            <h5 className="titT3">참고</h5>
            <ol className="list-ol1">
              <li>
                <span className="num">01</span>공휴일의 최대수요전력 및 사용전력량은 경부하시간대로 계량하고, 공휴일이
                아닌 토요일 최대부하시간대의 사용전력량은 중간부하시간대로 계량합니다.
              </li>
              <li>
                <span className="num">02</span>요금적용전력은 중간부하시간대와 최대부하시간대의 최대수요전력 중 큰것을
                대상으로 하여 제68조(요금적용전력의 결정)에 따라 산정합니다.
              </li>
              <li>
                <span className="num">03</span>제1호의 공휴일이라 함은 "관공서의 공휴일에 관한 규정"에 정한 공휴일을
                말합니다. 이 경우 정부에서 수시로 지정하는 임시공휴일은 제외합니다.
              </li>
              <li>
                <span className="num">04</span>제주특별자치도에서 전력을 사용하는 고객의 시간대 구분은 모든 계절에
                경부하시간대 22:00~08:00, 중간부하시간대 08:00~16:00, 최대부하시간대 16:00~22:00를 적용합니다.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
