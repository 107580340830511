import { useEffect, useState } from "react";
import backImg1 from "../../../assets/images/sub/example/doc_ex.png";
import { useAppDispatch } from "stores/hook";
import { closeModal, openModal } from "reducers/modalSlice";
import { useTranslation } from "react-i18next";
import MultiEmissionSourceModal from "./MultiEmissionModal";
import { EmissionService } from "../../../services/cp/EmissionService";
import ArcButton, { ButtonType } from "../../../components/arc/ArcButton";
import ArcSelectbox from "../../../components/arc/ArcSelectbox";
import ArcDatePicker from "../../../components/arc/ArcDatePicker";
import moment from "moment";
import { MhdlgService } from "services/cp/MhdlgService";
import { toast } from "react-toastify";
import { Mhdlg } from "model/cp/Mhdlg";
import { JsxElementConstant } from "../../../constants/jsxElementConstant";
import CountUp from "react-countup";
import { CountUpUtil } from "../../../utils/countUpUtil";
import { VariablesConstant } from "../../../constants/variables";

export default function MultiEmissionBoard() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultFromMonth = moment().format('YYYY01');
  const defaultToMonTh = moment().format('YYYYMM');
  const [scope, setScope] = useState<string>('all');
  const [scopeName, setScopeName] = useState<string>('전체');
  const [fromMonth, setFromMonth] = useState<any>(defaultFromMonth);
  const [toMonth, setToMonth] = useState<any>(defaultToMonTh);
  const [tableData, setTableData] = useState<any>({});
  const [list, setList] = useState<Mhdlg[]>([]);

  const search = () => {
    EmissionService.getInstance()
      .getScope(scope, fromMonth, toMonth)
      .then((res: any) => {
        setTableData(res.data.data);
      }).catch(() => toast.error(t('common.message.error.system')));

    // 방법론 검색 list 조회 (전체인 경우, scope를 선택했을 경우)
    if (scope === "all") {
      MhdlgService.getInstance()
        .getMhdlgListAll()
        .then((response: any) => {
          if (response.data) {
            setList(response.data);
          } else {
            toast.error(t("common.message.error.system"));
          }
        })
        .catch((error) => {
          toast.error(t("common.message.error.system"));
        });
    } else {
      MhdlgService.getInstance()
        .getScopeMhdlgList(scope)
        .then((response: any) => {
          if (response.data) {
            setList(response.data);
          } else {
            toast.error(t("common.message.error.system"));
          }
        })
        .catch((error) => {
          toast.error(t("common.message.error.system"));
        });
    }
  }
  useEffect(() => {
    search();
  }, [scope, fromMonth, toMonth]);

  const handleClickReset = () => {
    setScope('all');
    setFromMonth(defaultFromMonth);
    setToMonth(defaultToMonTh);
  };

  const handleChangeScope = (e: any) => {
    setScope(e.target.value);
    setScopeName(VariablesConstant.SCOPE.find((scope: any) => scope.value === e.target.value)?.label ?? '');
  };

  function clickScopeDetail(e: any, item: any) {
    e.preventDefault();
    dispatch(
      openModal({
        path: "cp/MultiEmissionSourceModal",
        target: MultiEmissionSourceModal,
        modalProps: {
          title: t("방법론 상세모달"),
          data: item,
          onClose: closeFormDialog,
        },
      })
    );
  }

  const closeFormDialog = () => {
    dispatch(closeModal());
  };

  const addMonthToDate = (month: number) => {
    if (!toMonth) {
      setToMonth(moment.utc().local().format("YYYYMM"));
      const newDate = moment().subtract(month - 1, "months").toString();
      setFromMonth(moment.utc(newDate).local().format("YYYYMM"));
    } else {
      const newDate = moment(toMonth, "YYYYMM").subtract(month - 1, "months").toString();
      setFromMonth(moment.utc(newDate).local().format("YYYYMM"));
    }
  };

  return (
    <>
      <section>
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <h3 className="titT1">다배출원 분석</h3>
              <div className="icon-box-wrap9">
                <ul>
                  <li>
                    <div>
                      <span className="txt">
                        <span>
                          <em>1</em>위
                        </span>
                      </span>
                      <div>
                        <ul className="list-stT1">
                          <li>
                            <span className="tit">배출시설</span>
                            <span>{tableData.length > 0 ? tableData[0].emsnFcltNm : ""}</span>
                          </li>
                          <li>
                            <span className="tit">배출량(tCO2eq)</span>
                            {tableData.length > 0
                              ? <CountUp end={Number(tableData[0].emisQnt.toFixed(2))}
                                duration={0.5}
                                decimals={CountUpUtil.getDecimal(Number(tableData[0].emisQnt.toFixed(2)))} />
                              : ''
                            }
                          </li>
                          <li>
                            <span className="tit">배출원</span>
                            <span>{tableData.length > 0 ? tableData[0].scopeId : ""}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="txt">
                        <span>
                          <em>2</em>위
                        </span>
                      </span>
                      <ul className="list-stT1">
                        <li>
                          <span className="tit">배출시설</span>
                          <span>{tableData.length > 1 ? tableData[1].emsnFcltNm : ""}</span>
                        </li>
                        <li>
                          <span className="tit">배출량(tCO2eq)</span>
                          {tableData.length > 1
                            ? <CountUp end={Number(tableData[1].emisQnt.toFixed(2))}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal(Number(tableData[1].emisQnt.toFixed(2)))} />
                            : ''
                          }
                        </li>
                        <li>
                          <span className="tit">배출원</span>
                          <span>{tableData.length > 1 ? tableData[1].scopeId : ""}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <span className="txt">
                        <span>
                          <em>3</em>위
                        </span>
                      </span>
                      <ul className="list-stT1">
                        <li>
                          <span className="tit">배출시설</span>
                          <span>{tableData.length > 2 ? tableData[2].emsnFcltNm : ""}</span>
                        </li>
                        <li>
                          <span className="tit">배출량(tCO2eq)</span>
                          {tableData.length > 2
                            ? <CountUp end={Number(tableData[2].emisQnt.toFixed(2))}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal(Number(tableData[2].emisQnt.toFixed(2)))} />
                            : ''
                          }
                        </li>
                        <li>
                          <span className="tit">배출원</span>
                          <span>{tableData.length > 2 ? tableData[2].scopeId : ""}</span>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="col-wrap mgt5">
        <div className="col-md-12">
          <div className="srh-filter">
            <ul>
              <li>
                <span className="srh-tit">구분</span>
                <ArcSelectbox
                  name={"scope"}
                  onChange={handleChangeScope}
                  options={VariablesConstant.SCOPE}
                  className={"select-group InpSel-200"} />
              </li>
              <li className="mgl20">
                <span className="srh-tit">기간</span>
                <ArcDatePicker
                  onChange={(e) => {
                    setFromMonth(e.fromDate ? moment(e.fromDate).format('YYYYMM') : moment(e.toDate).format('YYYY') + '01');
                    setToMonth(moment(e.toDate).format('YYYYMM'));
                  }}
                  fromDate={fromMonth}
                  toDate={toMonth}
                  id={"startDate"}
                  name={"startDate"}
                  selectionMode={"range"}
                  viewType={"month"}
                  showClearButton={false} />
                <div className="dateBtn mgl10">
                  <ArcButton type={"text"} text={"1개월"} onClick={() => addMonthToDate(1)} />
                  <ArcButton type={"text"} text={"3개월"} onClick={() => addMonthToDate(3)} />
                  <ArcButton type={"text"} text={"6개월"} onClick={() => addMonthToDate(6)} />
                  <ArcButton type={"text"} text={"1년"} onClick={() => addMonthToDate(12)} />
                </div>
              </li>
            </ul>
            <ArcButton type={ButtonType.reset} hasIcon={true} onClick={handleClickReset} />
          </div>
        </div>
      </div>
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <h3 className="titT1">{scopeName} 온실가스 감축 방안</h3>
            <ul className="doc-list2">
              {list.map((item, index) => {
                return (
                  <li key={item.mhdlgId}>
                    <a href={JsxElementConstant.hrefIsNull} className="btn-layer" title={item.mhdlgNm} onClick={(e) => clickScopeDetail(e, item)}>
                      <span className="img" style={{ backgroundColor: "rgba(0,0,0,0", marginTop: "10px" }}>
                        <img src={backImg1} alt="" style={{ width: "100%", objectFit: "fill" }} />
                      </span>
                      <span className="txt">{item.mhdlgNm}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
