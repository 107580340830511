import React from 'react';
import ArcDownload, {dataSourceProps} from "../../components/arc/ArcDownload";

export default function ArcDownloadExample () {
    const files: dataSourceProps[] = [{
        saveName: 'file-1708908858587.txt',
        realName: 'file-1708908858587.txt'
    }];

    async function handleDownload(filename: dataSourceProps) {
    }

    return (
        <div>
            <h1>File Downloader</h1>
            <ArcDownload dataSource={files} onDownload={handleDownload}/>
        </div>
    );
};

