import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {Calendar, CalendarDateTemplateEvent} from "primereact/calendar";
import ArcSelectbox from './ArcSelectbox';
import moment, { Moment } from 'moment';
import {toast} from "react-toastify";
import {useAppSelector} from "../../stores/hook";

let tmpFromDate: Date | undefined;
let tmpToDate: Date | undefined;
let tmpView: 'year' | 'month' | 'date' = 'date';
let tmpDateFormat:string = 'yy-mm-dd';
let tmpPlaceholder:string = 'YYYY-MM-DD';
let tmpShowDatePicker = true;
let tmpShowTimePicker = false;
let tmpMinDateForFromDate: Date | undefined;
let tmpMaxDateForFromDate: Date | undefined;
let tmpMinDateForToDate: Date | undefined;
let tmpMaxDateForToDate: Date | undefined;

export interface ArcDatePickerProps {
  onChange: (result: ArcDatePickerResult) => void
  id: string;
  name: string;
  fromDate?: Date | string;
  toDate?: Date | string;
  minDate?: Date | string;
  maxDate?: Date | string;
  minMaxDateMode?: 'auto' | 'force';
  style?: React.CSSProperties;
  selectionMode?: 'normal' | 'range';
  viewType?: 'year' | 'month' | 'date' | 'time' | 'timeOnly';
  showButtonBar?: boolean;
  showViewSelect?: boolean;
  showClearButton?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholders?: ArcDatePickerPlaceholder;
  title?: string;
}

export interface ArcDatePickerPlaceholder {
  fromDate: string;
  fromTime?: string
  toDate?: string;
  toTime?: string;
}

export interface ArcDatePickerResult {
  fromDate: Date | undefined;
  fromDateTimeString: string | undefined;
  fromDateString: string | undefined;
  fromTimeString: string | undefined;
  fromDateUtc: Date | undefined;
  fromDateTimeUtcString: string | undefined;
  fromDateUtcString: string | undefined;
  fromTimeUtcString: string | undefined;
  toDate: Date | undefined;
  toDateTimeString: string | undefined;
  toDateString: string | undefined;
  toTimeString: string | undefined;
  toDateUtc: Date | undefined;
  toDateTimeUtcString: string | undefined;
  toDateUtcString: string | undefined;
  toTimeUtcString: string | undefined;
}

const viewSelectOptions = [
  {
    label: '년',
    value: 'year'
  },
  {
    label: '월',
    value: 'month'
  },
  {
    label: '일',
    value: 'date'
  },
  {
    label: '시간',
    value: 'time'
  },
  {
    label: '시간만',
    value: 'timeOnly'
  }
];

const parseDateStringToDate = (dateString: string) => {
  let resultDate: Moment | undefined;
  // replace all separators([space], /, -, :).
  const tmpDateString: string = (dateString).replaceAll(/\s|\/|-|:/g, '');

  // The minimum scale date is year. so the length of fromDateString GTE then 4.
  if (tmpDateString && tmpDateString.length >= 4) {
    switch (tmpDateString.length) {
      case 4:
        resultDate = moment(tmpDateString, 'YYYY');
        break;
      case 6:
        resultDate = moment(tmpDateString, 'YYYYMM');
        break;
      case 8:
        resultDate = moment(tmpDateString, 'YYYYMMDD');
        break;
      case 10:
        resultDate = moment(tmpDateString, 'YYYYMMDDHH');
        break;
      case 12:
        resultDate = moment(tmpDateString, 'YYYYMMDDHHmm');
        break;
      case 14:
        resultDate = moment(tmpDateString, 'YYYYMMDDHHmmss');
        break;
    }
  }

  return resultDate;
};

const generateDatePickerResult = (fromDate: Date | undefined, toDate: Date | undefined) => {
  const fromDateMoment: Moment | undefined = fromDate ? moment(fromDate) : undefined;
  const toDateMoment: Moment | undefined = toDate ? moment(toDate) : undefined;
  const result: ArcDatePickerResult = {
    fromDate: fromDateMoment?.toDate(),
    fromDateTimeString: fromDateMoment?.format('YYYYMMDDHHmmss'),
    fromDateString: fromDateMoment?.format('YYYYMMDD'),
    fromTimeString: fromDateMoment?.format('HHmm'),
    fromDateUtc: fromDateMoment?.utc().toDate(),
    fromDateTimeUtcString: fromDateMoment?.utc().format('YYYYMMDDHHmmss'),
    fromDateUtcString: fromDateMoment?.utc().format('YYYYMMDD'),
    fromTimeUtcString: fromDateMoment?.utc().format('HHmmss'),
    toDate: toDateMoment?.toDate(),
    toDateTimeString: toDateMoment?.format('YYYYMMDDHHmmss'),
    toDateString: toDateMoment?.format('YYYYMMDD'),
    toTimeString: toDateMoment?.format('HHmm'),
    toDateUtc: toDateMoment?.utc().toDate(),
    toDateTimeUtcString: toDateMoment?.utc().format('YYYYMMDDHHmmss'),
    toDateUtcString: toDateMoment?.utc().format('YYYYMMDD'),
    toTimeUtcString: toDateMoment?.utc().format('HHmmss'),
  }

  return result;
};

const setDatepickerViewVariables = (viewType: 'year' | 'month' | 'date' | 'time' | 'timeOnly') => {
  tmpShowDatePicker = true;
  tmpShowTimePicker = false;

  if (viewType === 'year') {
    tmpView = 'year';
    tmpDateFormat = 'yy';
    tmpPlaceholder = 'YYYY';
  } else if (viewType === 'month') {
    tmpView = 'month';
    tmpDateFormat = 'yy-mm';
    tmpPlaceholder = 'YYYY-MM';
  } else {
    tmpView = 'date';
    tmpDateFormat = 'yy-mm-dd';
    tmpPlaceholder = 'YYYY-MM-DD';

    if (viewType === 'time') {
      tmpShowTimePicker = true;
    }

    if (viewType === 'timeOnly') {
      tmpShowDatePicker = false;
      tmpShowTimePicker = true;
    }
  }
};

export default function ArcDatePicker(props: Readonly<ArcDatePickerProps>) {
  const holidays = useAppSelector((state) => state.holidays.holidays);
  const title = props.title ?? '검색기간';
  let isRangeSelection = props.selectionMode === 'range';

  setDatepickerViewVariables(props.viewType??'date');

  useEffect(() => {
    if (!props.fromDate) {
      setFromDate(undefined);
    }

    if (!props.toDate) {
      setToDate(undefined);
    }

    if (props.fromDate && typeof(props.fromDate) === 'string') {
      let parsedFromDate = parseDateStringToDate(props.fromDate as string);
      tmpFromDate = parsedFromDate?.toDate();
      setFromDate(tmpFromDate);
    }

    if (props.toDate && typeof(props.toDate) === 'string') {
      let parsedToDate = parseDateStringToDate(props.toDate as string);
      tmpToDate = parsedToDate?.toDate();
      setToDate(tmpToDate);
    }

    if (props.minDate || props.maxDate) {
      let tmpMinDate: Date | undefined;
      let tmpMaxDate: Date | undefined;
      
      if (typeof(props.minDate) === 'string') {
        tmpMinDate = parseDateStringToDate(props.minDate as string)?.toDate();
      } else {
        tmpMinDate = props.minDate;
      }

      if (typeof(props.maxDate) === 'string') {
        tmpMaxDate = parseDateStringToDate(props.maxDate as string)?.toDate();
      } else {
        tmpMaxDate = props.maxDate;
      }

      if (isRangeSelection) {
        if (props.minMaxDateMode === 'force') {
          tmpMinDateForFromDate = tmpMinDate;
          tmpMaxDateForFromDate = tmpMaxDate;
          tmpMinDateForToDate = tmpMinDate;
          tmpMaxDateForToDate = tmpMaxDate;
          setMinDateForFromDate(tmpMinDateForFromDate);
          setMaxDateForFromDate(tmpMaxDateForFromDate);
          setMinDateForToDate(tmpMinDateForToDate);
          setMaxDateForToDate(tmpMaxDateForToDate);
        }
      } else {
        tmpMinDateForFromDate = tmpMinDate;
        tmpMaxDateForFromDate = tmpMaxDate;
        setMinDateForFromDate(tmpMinDateForFromDate);
        setMaxDateForFromDate(tmpMaxDateForFromDate);
      }
    }
  }, [props.fromDate, props.toDate, props.minDate, props.maxDate]);
  
  const [view, setView] = useState<'year' | 'month' | 'date'>(tmpView);
  const [dateFormat, setDateFormat] = useState(tmpDateFormat);
  const [placeholder, setPlaceholder] = useState(tmpPlaceholder);
  const [showDatePicker, setShowDatePicker] = useState(tmpShowDatePicker);
  const [showTimePicker, setShowTimePicker] = useState(tmpShowTimePicker);
  const [fromDate, setFromDate] = useState(tmpFromDate);
  const [toDate, setToDate] = useState(tmpToDate);
  const [minDateForFromDate, setMinDateForFromDate] = useState(tmpMinDateForFromDate);
  const [maxDateForFromDate, setMaxDateForFromDate] = useState(tmpMaxDateForFromDate);
  const [minDateForToDate, setMinDateForToDate] = useState(tmpMinDateForToDate);
  const [maxDateForToDate, setMaxDateForToDate] = useState(tmpMaxDateForToDate);

  const handleChangeFromDate = (event: any) => {
    tmpFromDate = event.value;

    if (tmpFromDate) {
      tmpFromDate = new Date(tmpFromDate);

      if (fromDate && (fromDate.getFullYear() != tmpFromDate.getFullYear() || fromDate.getMonth() != tmpFromDate.getMonth() || fromDate.getDate() != tmpFromDate.getDate())) {
        tmpFromDate.setHours(fromDate.getHours());
        tmpFromDate.setMinutes(fromDate.getMinutes());
        tmpFromDate.setSeconds(fromDate.getSeconds());
      }

      if (minDateForFromDate && tmpFromDate < minDateForFromDate) {
        tmpFromDate = minDateForFromDate;
      }

      if (maxDateForFromDate && tmpFromDate > maxDateForFromDate) {
        tmpFromDate = maxDateForFromDate;
      }

      if (isRangeSelection) {
        if (toDate && tmpFromDate > toDate) {
          tmpToDate = undefined;
          toast.error("조회 종료일자를 조회 시작일자 이후로 선택하세요.");
          setToDate(tmpToDate);
        }
      }
    }

    setFromDate(tmpFromDate);
    props.onChange(generateDatePickerResult(tmpFromDate, tmpToDate));
  }

  const handleChangeToDate = (event: any) => {
    tmpToDate = event.value;

    if (tmpToDate) {
      tmpToDate = new Date(tmpToDate);

      if (toDate && (toDate.getFullYear() != tmpToDate.getFullYear() || toDate.getMonth() != tmpToDate.getMonth() || toDate.getDate() != tmpToDate.getDate())) {
        tmpToDate.setHours(toDate.getHours());
        tmpToDate.setMinutes(toDate.getMinutes());
        tmpToDate.setSeconds(toDate.getSeconds());
      }

      if (minDateForToDate && tmpToDate < minDateForToDate) {
        tmpToDate = minDateForToDate;
      }

      if (maxDateForToDate && tmpToDate > maxDateForToDate) {
        tmpToDate = maxDateForToDate;
      }

      if (isRangeSelection) {
        if (fromDate && tmpToDate < fromDate) {
          tmpFromDate = undefined;
          toast.error("조회 시작일자를 조회 종료일자 이전으로 선택하세요.");
          setFromDate(tmpFromDate);
        }
      }
    }

    setToDate(tmpToDate);
    props.onChange(generateDatePickerResult(tmpFromDate, tmpToDate));
  }

  const handleChangeView = (event: any) => {
    setDatepickerViewVariables(event.target.value);
    setView(tmpView);
    setDateFormat(tmpDateFormat);
    setPlaceholder(tmpPlaceholder);
    setShowDatePicker(tmpShowDatePicker);
    setShowTimePicker(tmpShowTimePicker);
  };

  // force set input style class
  const inputRefDateFrom: any = useRef<typeof Calendar>();
  const inputRefTimeFrom: any = useRef<typeof Calendar>();
  const inputRefDateTo: any = useRef<typeof Calendar>();
  const inputRefTimeTo: any = useRef<typeof Calendar>();
  useEffect(() => {
    if(showDatePicker) {
      inputRefDateFrom.current?.classList.add('InpSel-w100');
      inputRefDateFrom.current?.classList.add('icon-rtl');
      if(isRangeSelection) {
        inputRefDateTo.current?.classList.add('InpSel-w100');
        inputRefDateTo.current?.classList.add('icon-rtl');
      }
    }
    if(showTimePicker) {
      inputRefTimeFrom.current?.classList.add('InpSel-w100');
      inputRefTimeFrom.current?.classList.add('icon-rtl');
      if(isRangeSelection) {
        inputRefTimeTo.current?.classList.add('InpSel-w100');
        inputRefTimeTo.current?.classList.add('icon-rtl');
      }
    }
  })

  const handleClickFromDateIcon = () => {
    inputRefDateFrom.current?.focus();
  }

  const handleClickFromTimeIcon = () => {
    inputRefTimeFrom.current?.focus();
  }

  const handleClickToDateIcon = () => {
    inputRefDateTo.current?.focus();
  }

  const handleClickToTimeIcon = () => {
    inputRefTimeTo.current?.focus();
  }

  const handleDateTemplate = (e: CalendarDateTemplateEvent) => {
    const year = e.year;
    let month = (e.month + 1) + '';
    const day = e.day < 10 ? '0' + e.day : '' + e.day;
    month = Number(month) < 10 ? '0' + month : '' + month;
    const date = `${year}${month}${day}`;
    const weekday = moment(date).isoWeekday();
    let fontColor = '';

    if (weekday === 7) {
      fontColor = '#F36363';
    } else if (weekday === 6) {
      fontColor = '#2970CD';
    }

    let template: ReactNode = <span style={{color: fontColor}} title={year + '.' + month + '.' + day}>{e.day}</span>;

    if (holidays != null) {
      const holiday = holidays.find((d: any) => d.crtrYmd === date);
      if (holiday) {
        fontColor = holiday.hdayYn === 'Y' ? '#F36363' : fontColor;
        template = <span style={{color: fontColor}} title={holiday.hdayNm}>{e.day}</span>;
      }
    }

    return template;
  }

  const getClockIcon = () => <i className="pi pi-clock" />;

  return (
    <div className="calendar-picker">
      {
        props.showViewSelect ?? false
        ?
        <ArcSelectbox name={""} onChange={handleChangeView} options={viewSelectOptions} style={{marginRight: "5px"}} title={`${title} 구분 선택`} />
        :
        <></>
      }
      {
        showDatePicker
        ?
        <div className="calendar-input">
          <Calendar
            inputRef={inputRefDateFrom}
            style={props.style}
            dateFormat={dateFormat}
            dateTemplate={handleDateTemplate}
            id={props.id + '_start_date'}
            name={props.name + '_start_date'}
            ariaLabel={`${title} 조회 시작일자 선택`}
            placeholder={props.placeholders?.fromDate ?? placeholder}
            value={fromDate}
            minDate={minDateForFromDate}
            maxDate={maxDateForFromDate}
            readOnlyInput={props.readonly}
            disabled={props.disabled}
            onChange={handleChangeFromDate}
            showButtonBar={props.showButtonBar ?? true}
            clearButtonClassName={props.showClearButton ?? false ? '' : 'hidden'}
            view={view} />
          <button className="calendar-picker-btn" onClick={handleClickFromDateIcon}></button>
        </div>
        :
        <></>
      }
      {
        showTimePicker
        ?
        <div className="calendar-input time">
          <Calendar
            inputRef={inputRefTimeFrom}
            style={{ marginLeft: "5px" }}
            inputClassName={"icon-rtl InpSel-w100"}
            dateFormat={dateFormat}
            placeholder={props.placeholders?.fromTime ?? "00:00"}
            value={fromDate}
            id={props.id + "_start_time"}
            name={props.name + "_state_time"}
            ariaLabel={`${title} 조회 시작시간 선택`}
            minDate={minDateForFromDate}
            maxDate={maxDateForFromDate}
            icon={getClockIcon}
            readOnlyInput={props.readonly}
            disabled={props.disabled}
            timeOnly
            onChange={handleChangeFromDate}
            keepInvalid={true}
            showButtonBar={props.showButtonBar??true}
            clearButtonClassName={props.showClearButton ?? false ? '' : 'hidden'} />
          <button className="calendar-picker-btn" onClick={handleClickFromTimeIcon}></button>
        </div>
        :
        <></>
      }
      {
        isRangeSelection
        ?
        <>
          <em className="mgl5 mgr5">~</em>
          {
            showDatePicker
            ?
            <div className="calendar-input">
              <Calendar
                inputRef={inputRefDateTo}
                id={props.id + "_end_date"}
                name={props.name + "_end_date"}
                ariaLabel={`${title} 조회 종료일자 선택`}
                style={props.style}
                inputClassName={"icon-rtl InpSel-w100"}
                dateFormat={dateFormat}
                dateTemplate={handleDateTemplate}
                placeholder={props.placeholders?.toDate ?? placeholder}
                value={toDate}
                minDate={minDateForToDate}
                maxDate={maxDateForToDate}
                readOnlyInput={props.readonly}
                disabled={props.disabled}
                onChange={handleChangeToDate}
                showButtonBar={props.showButtonBar ?? true}
                view={view}
                clearButtonClassName={props.showClearButton ?? false ? '' : 'hidden'} />
              <button className="calendar-picker-btn" onClick={handleClickToDateIcon}></button>
            </div>
            :
            <></>
          }
          {
            showTimePicker
            ?
            <div className="calendar-input time">
              <Calendar
                inputRef={inputRefTimeTo}
                style={{marginLeft: "5px"}}
                id={props.id + '_end_time'}
                name={props.name + '_end_time'}
                ariaLabel={`${title} 조회 종료시간 선택`}
                inputClassName={"icon-rtl InpSel-w100"}
                placeholder={props.placeholders?.toTime ?? "00:00"}
                value={toDate}
                minDate={minDateForToDate}
                maxDate={maxDateForToDate}
                icon={getClockIcon}
                readOnlyInput={props.readonly}
                disabled={props.disabled}
                timeOnly
                onChange={handleChangeToDate}
                showButtonBar={props.showButtonBar ?? true}
                clearButtonClassName={props.showClearButton ?? false ? '' : 'hidden'} />
              <button className="calendar-picker-btn" onClick={handleClickToTimeIcon}></button>
            </div>
            :
            <></>
          }
        </>
        :
        <></>
      }
    </div>
  )
}