import Api from "../common/Api";
import * as process from "process";
import {SmcnCertModel} from "../../model/rp/SmcnCertModel";
import {RlibrModel} from "../../model/rp/RlibrModel";
import {SmcnCertRECModel} from "../../model/rp/SmcnCertRECModel";

export class SmcnCertService extends Api {

  private static service: SmcnCertService;
  private static INSERT_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertInsert';
  private static UPDATE_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertUpdate';
  private static DELETE_DETAIL_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertDetailDelete';
  private static DELETE_LIST_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertListDelete';
  private static DETAIL_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertDetail';
  private static REC_INSERT_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertRecInsert';
  private static REC_UPDATE_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertRecUpdate';
  private static REC_DETAIL_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertRecDetail';
  private static REC_DELETE_DETAIL_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertRecDetailDelete';
  private static REC_DELETE_LIST_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertRecListDelete';
  private static SUM_EACH: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/qntBySmcnCertByUser';
  private static GEN_CSV: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/download';
  private static SUM_ALL_COST_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertSumAllCost';
  private static COUNT_SMCN_CERT_All_URL: string = process.env['REACT_APP_URL_RP'] + '/smcnCert/smcnCertCountAll';

  public static getInstance(): SmcnCertService {
    if (!SmcnCertService.service) {
      SmcnCertService.service = new SmcnCertService();
    }
    return SmcnCertService.service;
  }

  public async insertSmcnCert(smcnCert: SmcnCertModel) {
    return await super.post<SmcnCertModel>(SmcnCertService.INSERT_URL, smcnCert)
  }

  public async updateSmcnCert(smcnCert: SmcnCertModel) {
    return await super.put<SmcnCertModel>(SmcnCertService.UPDATE_URL, smcnCert)
  }

  public async deleteSmcnCert(smcnCertId: string) {
    return await super.delete<SmcnCertModel>(SmcnCertService.DELETE_DETAIL_URL, {smcnCertId: smcnCertId})
  }

  public async deleteSmcnCerts(smcnCerts: SmcnCertModel[]) {
    return await super.post<SmcnCertModel[]>(SmcnCertService.DELETE_LIST_URL, {deleteRows: smcnCerts})
  }

  public async detailSmcnCert(smcnCertId: string) {
    return await super.get<SmcnCertModel>(SmcnCertService.DETAIL_URL, {smcnCertId: smcnCertId})
  }

  public async insertSmcnCertREC(smcnCertRec: SmcnCertModel) {
    return await super.post<SmcnCertRECModel>(SmcnCertService.REC_INSERT_URL, smcnCertRec)
  }

  public async updateSmcnCertREC(smcnCertRec: SmcnCertRECModel) {
    return await super.put<SmcnCertRECModel>(SmcnCertService.REC_UPDATE_URL, smcnCertRec)
  }

  public async detailSmcnCertREC(smcnCertRecId: string) {
    return await super.get<SmcnCertRECModel>(SmcnCertService.REC_DETAIL_URL, {smcnCertRecId: smcnCertRecId})
  }

  public async deleteSmcnCertREC(smcnCertRecId: string) {
    return await super.delete<SmcnCertRECModel>(SmcnCertService.REC_DELETE_DETAIL_URL, {smcnCertRecId: smcnCertRecId})
  }

  public async deleteSmcnCertRECs(smcnCertRec: SmcnCertRECModel[]) {
    return await super.post<SmcnCertRECModel>(SmcnCertService.REC_DELETE_LIST_URL, {deleteRows: smcnCertRec})
  }

  public async getSumEach(requestYear: String) {
    return await super.get<any>(SmcnCertService.SUM_EACH, {year: requestYear});
  }

  // 이행비용 합계
  public async getAllSumSmcnCertCost(requestYear: String) {
    return await super.get<any>(SmcnCertService.SUM_ALL_COST_URL, {requestYear: requestYear})
  }

  // Admin 대시보드: 간편인증 이용건수
  public async getSmcnCertSumAll() {
    return await super.get<any>(SmcnCertService.COUNT_SMCN_CERT_All_URL)
  }

  public async getCsvFile() {
    return await super.get<any>(SmcnCertService.GEN_CSV)
  }

}