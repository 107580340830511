import { useState } from "react"
import ArcCheckbox from "components/arc/ArcCheckbox";

export default function ArcCheckboxExample() {
  const[checkAll, setCheckAll] = useState(false)
  const[check1, setCheck1] = useState(false)
  const[check2, setCheck2] = useState(false)

  const handleChange =(isChecked : any) => {
  }
  const handleChange2 =(isChecked : any) => {
  }

  const handleCheckAll = (isChecked : any) =>{
    setCheck1(isChecked)
    setCheck2(isChecked)
  };

  return (<>
    <div></div>

    <h5 className="titT3">체크버튼 : 전체 선택 시</h5>
    <ArcCheckbox onChecked={handleCheckAll} text={"전체 체크"} id={"1"} name={"1"}
                 checked={checkAll} isAlt={true}></ArcCheckbox>
    <ArcCheckbox onChecked={handleChange} text={"체크1"} id={"1"} name={"1"}
                 checked={check1} readOnly={true} isAlt={false}></ArcCheckbox>
    <ArcCheckbox onChecked={handleChange} text={"체크2"} id={"2"} name={"2"}
                 checked={check2} disabled={true}></ArcCheckbox>

    <h5 className="titT3">체크버튼 : 버튼만 존재 시</h5>
    <div>
      <ArcCheckbox onChecked={handleChange2} value={""} id={"3"} name={"3"}></ArcCheckbox>
      <ArcCheckbox onChecked={handleChange2} isAlt={true} value={""} id={"4"} name={"4"} readOnly={false}
      ></ArcCheckbox>
    </div>

    <h5 className="titT3">체크버튼 : 텍스트 존재 시</h5>
    <div>
      <ArcCheckbox onChecked={handleChange} text={"내용이 표시되는 영역"}></ArcCheckbox>
      <ArcCheckbox onChecked={handleChange}
                   text={"내용이 표시되는 영역"}></ArcCheckbox>
    </div>
    <div></div>
  </>);
}