import Api from "services/common/Api";
import {BillModel} from "model/cb/billModel";
import {BillListHeaderDataModel} from "pages/cb/bill/billList/BillListHeader";
import ContractModel from "model/cb/contractModel";
import {BillDashboardDataModel} from "pages/db/DbUserSettlementStatus";

export class BillService extends Api {
  private static service: BillService;

  private BILLS: string = process.env.REACT_APP_URL_CB + "/bill/bills";
  private CRUD: string = process.env.REACT_APP_URL_CB + "/bill/bill";

  public static getInstance(): BillService {
    if(!BillService.service) {
      BillService.service = new BillService();
    }
    return BillService.service;
  }

  public async findList(data: any) {
    return await super.get<BillModel[]>(this.BILLS, data);
  }

  public async findListHeaderData(data: any) {
    return await super.get<BillListHeaderDataModel>(this.BILLS + "/header", data);
  }
  public async findPPADashboardData(data: any) {
    return await super.get<BillDashboardDataModel>(this.BILLS + "/dashboard", data);
  }

  public async insert(data: BillModel) {
    return await super.post<BillModel>(this.CRUD, data);
  }

  public async updateTaxCheckStatus(data: BillModel) {
    return await super.put<BillModel>(this.CRUD + "/taxCheck", data);
  }

  public async checkDuplicateTchgr(tchgrId: string) {
    return await super.get<boolean>(this.BILLS + "/" + tchgrId);
  }

  public async checkDuplicateTchgrPPA(contractData: ContractModel) {
    return await super.post<boolean>(this.BILLS + "/checkIsReqd", contractData);
  }

  public async checkDuplicateTchgrHD(data: any) {
    return await super.get<boolean>(this.BILLS + "/checkIsReqd", data);
  }
}