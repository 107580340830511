import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { EmissionService } from "../../../services/cp/EmissionService";
import ArcDatePicker from "../../../components/arc/ArcDatePicker";
import ArcButton, { ButtonColor, ButtonIcon, ButtonType } from "../../../components/arc/ArcButton";
import Board from "./Board";
import { useTranslation } from "react-i18next";
import { ActvScopService } from "services/cp/ActvScopService";
import { toast } from "react-toastify";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";
import { HighchartsOptionsUtil } from "../../../utils/highchartsOptionsUtil";

export default function ScopeEmissions() {

  const toDayYear = new Date().getFullYear();

  const [chartOptionsA, setChartOptionsA] = useState<any>();
  const [chartOptionsB, setChartOptionsB] = useState<any>();
  const [chartOptionsC, setChartOptionsC] = useState<any>();
  const [chartOptionsD, setChartOptionsD] = useState<any>();
  const [chartOptionsE, setChartOptionsE] = useState<any>();

  const [scope1Total, setScope1Total] = useState<any>();
  const [scope2Total, setScope2Total] = useState<any>();
  const [scope3Total, setScope3Total] = useState<any>();

  const [yearOne, setYearOne] = useState(toDayYear);
  const [yearTwo, setYearTwo] = useState(toDayYear);
  const [nowYear, setNowYear] = useState(toDayYear);
  const [rerender, setRerender] = useState(true);

  const { t } = useTranslation();

  const [scopUse, setScopUse] = useState<any>();

  useEffect(() => {
    getCheckScope();
  }, []);

  function getCheckScope() {
    ActvScopService.getInstance()
      .getCheckScope()
      .then((response: any) => {
        if (response && response.data) {
          setScopUse(response.data);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.system"));
      });
  }

  const [resultOne, setResultOne] = useState<any>({
    scope1: { scopeNm: "" },
    scope2: { scopeNm: "" },
    scope3: { scopeNm: "" },
  });

  const [resultTwo, setResultTwo] = useState<any>({
    scope1: { scopeNm: "" },
    scope2: { scopeNm: "" },
    scope3: { scopeNm: "" },
  });

  const [resultThree, setResultThree] = useState<any>({
    scope1: { scopeNm: "" },
    scope2: { scopeNm: "" },
    scope3: { scopeNm: "" },
  });

  const [isDetail, setIsDetail] = useState(false);

  const clickBackBtn = () => {
    setIsDetail(true);
  };
  const roundToFourDecimals = (num: any) => {
    return parseFloat(num.toFixed(2));
  };

  function fetch() {
    EmissionService.getInstance()
      .getEmissiopnStatus(toDayYear.toString())
      .then(function (res: any) {
        const resultMap = res.data.reduce(
          (acc: any, item: any) => {
            let {
              scopId,
              mm1EmsnQnt,
              mm2EmsnQnt,
              mm3EmsnQnt,
              mm4EmsnQnt,
              mm5EmsnQnt,
              mm6EmsnQnt,
              mm7EmsnQnt,
              mm8EmsnQnt,
              mm9EmsnQnt,
              mm10EmsnQnt,
              mm11EmsnQnt,
              mm12EmsnQnt,
            } = item;

            if (scopId === "연료사용" || scopId === "폐기물소각" || scopId === "공정배출") {
              scopId = "scope1";
            } else if (scopId === "전력 사용시설" || scopId === "열/스팀 사용시설") {
              scopId = "scope2";
            } else {
              scopId = "scope3";
            }

            acc[scopId].mm1EmsnQnt = roundToFourDecimals(acc[scopId].mm1EmsnQnt + mm1EmsnQnt);
            acc[scopId].mm2EmsnQnt = roundToFourDecimals(acc[scopId].mm2EmsnQnt + mm2EmsnQnt);
            acc[scopId].mm3EmsnQnt = roundToFourDecimals(acc[scopId].mm3EmsnQnt + mm3EmsnQnt);
            acc[scopId].mm4EmsnQnt = roundToFourDecimals(acc[scopId].mm4EmsnQnt + mm4EmsnQnt);
            acc[scopId].mm5EmsnQnt = roundToFourDecimals(acc[scopId].mm5EmsnQnt + mm5EmsnQnt);
            acc[scopId].mm6EmsnQnt = roundToFourDecimals(acc[scopId].mm6EmsnQnt + mm6EmsnQnt);
            acc[scopId].mm7EmsnQnt = roundToFourDecimals(acc[scopId].mm7EmsnQnt + mm7EmsnQnt);
            acc[scopId].mm8EmsnQnt = roundToFourDecimals(acc[scopId].mm8EmsnQnt + mm8EmsnQnt);
            acc[scopId].mm9EmsnQnt = roundToFourDecimals(acc[scopId].mm9EmsnQnt + mm9EmsnQnt);
            acc[scopId].mm10EmsnQnt = roundToFourDecimals(acc[scopId].mm10EmsnQnt + mm10EmsnQnt);
            acc[scopId].mm11EmsnQnt = roundToFourDecimals(acc[scopId].mm11EmsnQnt + mm11EmsnQnt);
            acc[scopId].mm12EmsnQnt = roundToFourDecimals(acc[scopId].mm12EmsnQnt + mm12EmsnQnt);
            acc[scopId].total = roundToFourDecimals(acc[scopId].total +
              mm1EmsnQnt +
              mm2EmsnQnt +
              mm3EmsnQnt +
              mm4EmsnQnt +
              mm5EmsnQnt +
              mm6EmsnQnt +
              mm7EmsnQnt +
              mm8EmsnQnt +
              mm9EmsnQnt +
              mm10EmsnQnt +
              mm11EmsnQnt +
              mm12EmsnQnt);

            return acc;
          },
          {
            scope1: {
              scopeNm: "scope1",
              total: 0,
            },
            scope2: {
              scopeNm: "scope2",
              total: 0,
            },
            scope3: {
              scopeNm: "scope3",
              total: 0,
            },
          }
        );
        Object.keys(resultMap).forEach((key: string) => {
          resultMap[key].total = parseFloat(resultMap[key].total.toFixed(2));
        });
        setResultOne(resultMap);
      })
      .catch(() => {
        toast.error(t("common.message.error.system"));
      });

    EmissionService.getInstance()
      .getEmissiopnStatus(yearOne)
      .then(function (res: any) {
        const resultMap = res.data.reduce(
          (acc: any, item: any) => {
            let {

              scopId,
              mm1EmsnQnt,
              mm2EmsnQnt,
              mm3EmsnQnt,
              mm4EmsnQnt,
              mm5EmsnQnt,
              mm6EmsnQnt,
              mm7EmsnQnt,
              mm8EmsnQnt,
              mm9EmsnQnt,
              mm10EmsnQnt,
              mm11EmsnQnt,
              mm12EmsnQnt,
            } = item;

            if (scopId === "연료사용" || scopId === "폐기물소각" || scopId === "공정배출") {
              scopId = "scope1";
            } else if (scopId === "전력 사용시설" || scopId === "열/스팀 사용시설") {
              scopId = "scope2";
            } else {
              scopId = "scope3";
            }

            acc[scopId].mm1EmsnQnt = roundToFourDecimals(acc[scopId].mm1EmsnQnt + mm1EmsnQnt);
            acc[scopId].mm2EmsnQnt = roundToFourDecimals(acc[scopId].mm2EmsnQnt + mm2EmsnQnt);
            acc[scopId].mm3EmsnQnt = roundToFourDecimals(acc[scopId].mm3EmsnQnt + mm3EmsnQnt);
            acc[scopId].mm4EmsnQnt = roundToFourDecimals(acc[scopId].mm4EmsnQnt + mm4EmsnQnt);
            acc[scopId].mm5EmsnQnt = roundToFourDecimals(acc[scopId].mm5EmsnQnt + mm5EmsnQnt);
            acc[scopId].mm6EmsnQnt = roundToFourDecimals(acc[scopId].mm6EmsnQnt + mm6EmsnQnt);
            acc[scopId].mm7EmsnQnt = roundToFourDecimals(acc[scopId].mm7EmsnQnt + mm7EmsnQnt);
            acc[scopId].mm8EmsnQnt = roundToFourDecimals(acc[scopId].mm8EmsnQnt + mm8EmsnQnt);
            acc[scopId].mm9EmsnQnt = roundToFourDecimals(acc[scopId].mm9EmsnQnt + mm9EmsnQnt);
            acc[scopId].mm10EmsnQnt = roundToFourDecimals(acc[scopId].mm10EmsnQnt + mm10EmsnQnt);
            acc[scopId].mm11EmsnQnt = roundToFourDecimals(acc[scopId].mm11EmsnQnt + mm11EmsnQnt);
            acc[scopId].mm12EmsnQnt = roundToFourDecimals(acc[scopId].mm12EmsnQnt + mm12EmsnQnt);
            acc[scopId].total = roundToFourDecimals(acc[scopId].total +
              mm1EmsnQnt +
              mm2EmsnQnt +
              mm3EmsnQnt +
              mm4EmsnQnt +
              mm5EmsnQnt +
              mm6EmsnQnt +
              mm7EmsnQnt +
              mm8EmsnQnt +
              mm9EmsnQnt +
              mm10EmsnQnt +
              mm11EmsnQnt +
              mm12EmsnQnt);
            return acc;
          },
          {
            scope1: {
              scopeNm: "scope1",
              mm1EmsnQnt: 0,
              mm2EmsnQnt: 0,
              mm3EmsnQnt: 0,
              mm4EmsnQnt: 0,
              mm5EmsnQnt: 0,
              mm6EmsnQnt: 0,
              mm7EmsnQnt: 0,
              mm8EmsnQnt: 0,
              mm9EmsnQnt: 0,
              mm10EmsnQnt: 0,
              mm11EmsnQnt: 0,
              mm12EmsnQnt: 0,
              total: 0,
            },
            scope2: {
              scopeNm: "scope2",
              mm1EmsnQnt: 0,
              mm2EmsnQnt: 0,
              mm3EmsnQnt: 0,
              mm4EmsnQnt: 0,
              mm5EmsnQnt: 0,
              mm6EmsnQnt: 0,
              mm7EmsnQnt: 0,
              mm8EmsnQnt: 0,
              mm9EmsnQnt: 0,
              mm10EmsnQnt: 0,
              mm11EmsnQnt: 0,
              mm12EmsnQnt: 0,
              total: 0,
            },
            scope3: {
              scopeNm: "scope3",
              mm1EmsnQnt: 0,
              mm2EmsnQnt: 0,
              mm3EmsnQnt: 0,
              mm4EmsnQnt: 0,
              mm5EmsnQnt: 0,
              mm6EmsnQnt: 0,
              mm7EmsnQnt: 0,
              mm8EmsnQnt: 0,
              mm9EmsnQnt: 0,
              mm10EmsnQnt: 0,
              mm11EmsnQnt: 0,
              mm12EmsnQnt: 0,
              total: 0,
            },
          }
        );
        setResultTwo(resultMap);
      })
      .catch(() => {
        toast.error(t("common.message.error.system"));
      });

    EmissionService.getInstance()
      .getEmissiopnStatus(yearTwo)
      .then(function (res: any) {
        let data: object = res.data.reduce(
          (acc: any, e: any) => {
            let key: string;
            if (["열/스팀 사용시설", "전력 사용시설"].includes(e.scopId)) {
              key = "scope2";
            } else if (["기타 간접배출"].includes(e.scopId)) {
              key = "scope3";
            } else {
              key = "scope1";
            }

            if (acc[key][e.scopId] === undefined) {
              acc[key][e.scopId] = Object.fromEntries(
                Object.entries(e)
                  .filter(([k]) => {
                    return ![
                      "chgDate",
                      "chgrId",
                      "emsnCoefId",
                      "emsnFcltNm",
                      "emsnFcltNmPrev",
                      "mngId",
                      "regDate",
                      "regrId",
                      "scopId",
                      "dataId",
                    ].includes(k);
                  })
                  .map(([k, v]) => [k, v === null ? 0 : v])
              );
            } else {
              Object.entries(e)
                .filter(([k]) => {
                  return ![
                    "chgDate",
                    "chgrId",
                    "emsnCoefId",
                    "emsnFcltNm",
                    "emsnFcltNmPrev",

                    "mngId",
                    "regDate",
                    "regrId",
                    "scopId",
                    "dataId",
                  ].includes(k);
                })
                .forEach(([k, v]) => {
                  acc[key][e.scopId][k] += v;
                });
            }
            return acc;
          },
          {
            scope1: {
              "연료사용": {
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
              },
              "폐기물소각": {
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
              },
              "공정배출": {
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
              },
            },
            scope2: {
              "전력 사용시설": {
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
              },
              "열/스팀 사용시설": {
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
              },
            },
            scope3: {
              "기타 간접배출": {
                mm1EmsnQnt: 0,
                mm2EmsnQnt: 0,
                mm3EmsnQnt: 0,
                mm4EmsnQnt: 0,
                mm5EmsnQnt: 0,
                mm6EmsnQnt: 0,
                mm7EmsnQnt: 0,
                mm8EmsnQnt: 0,
                mm9EmsnQnt: 0,
                mm10EmsnQnt: 0,
                mm11EmsnQnt: 0,
                mm12EmsnQnt: 0,
              },
            },
          }
        );
        setResultThree(data);
      }).catch(() => {
        toast.error(t("common.message.error.system"));
      });
  }

  useEffect(() => {
    fetch();
  }, [rerender]);


  //첫번째 차트 이펙트
  useEffect(() => {
    const getSeriesData1 = () => {
      const series1 = [];
      if (scopUse && scopUse.scopN3UseYn === "Y") {
        series1.push({
          type: "bar",
          name: resultOne.scope3.scopeNm,
          data: [resultOne.scope3.total],
        });
      }
      if (scopUse && scopUse.scopN2UseYn === "Y") {
        series1.push({
          type: "bar",
          name: resultOne.scope2.scopeNm,
          data: [resultOne.scope2.total],
        });
      }
      if (scopUse && scopUse.scopN1UseYn === "Y") {
        series1.push({
          type: "bar",
          name: resultOne.scope1.scopeNm,
          data: [resultOne.scope1.total],
        });
      }
      return series1;
    };

    const graphTypeA: Highcharts.Options = {
      colors: ["#00BB9F", "#24ACC5", "#2970CD"],
      chart: {
        type: "bar",
        height: 200,
        margin: 0,
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: { text: "" },
      tooltip: {
        shared: false,
        headerFormat: "",
        split: true,
        style: {
          fontSize: '14px'
        },
        positioner: function (labelWidth, _, point) {
          const plotX = point.plotX;
          const chartWidth = this.chart.chartWidth;
          return {
            x: plotX + labelWidth + 30 > chartWidth ? plotX - labelWidth : plotX,
            y: 40,
          };
        },
      },
      scrollbar: { enabled: false },
      exporting: { enabled: false },
      credits: { enabled: false },
      navigator: { enabled: false },
      rangeSelector: { enabled: false },
      plotOptions: {
        series: {
          shadow: false,
          borderWidth: 0,
          stacking: 'normal',
          dataLabels: {
            inside: true,
            enabled: true,
            useHTML: true,
            formatter: function () {
              return (
                '<span class="datalebel-over">' +
                '<strong style="padding:.25rem .75rem;box-shadow:0 .05rem .25rem rgba(0,0,0,.15);background:rgba(0,0,0,.25);border-radius:1rem;">' +
                (this.y ? Number(this.y.toFixed(2)).toLocaleString() : "0") +
                "</strong>" +
                "<span style=position:absolute;top:190%;left:50%;font-weight:700;font-size:1rem;color:" +
                this.color +
                ';transform:translate(-50%);">' +
                Math.round(this.percentage * 100) / 100 +
                " %</span>" +
                "</span>"
              );
            },
          },
        },
      },
      legend: {
        enabled: true,
        reversed: true,
        symbolHeight: 10,
        symbolRadius: 0,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        title: { text: '' },
        visible: false,
        crosshair: false
      },
      yAxis: {
        title: { text: '' },
        categories: [''],
        min: 0,
        visible: false,
      },
      series: getSeriesData1() as Highcharts.SeriesOptionsType[],
    };

    setChartOptionsA(graphTypeA);


  }, [resultOne]);

  //두번째 차트 이펙트
  useEffect(() => {
    const getSeriesData2 = () => {
      const series2 = [];

      if (scopUse && scopUse.scopN1UseYn === "Y") {
        series2.push({
          type: "column",
          name: resultTwo.scope1.scopeNm,
          data: [
            resultTwo.scope1.mm1EmsnQnt,
            resultTwo.scope1.mm2EmsnQnt,
            resultTwo.scope1.mm3EmsnQnt,
            resultTwo.scope1.mm4EmsnQnt,
            resultTwo.scope1.mm5EmsnQnt,
            resultTwo.scope1.mm6EmsnQnt,
            resultTwo.scope1.mm7EmsnQnt,
            resultTwo.scope1.mm8EmsnQnt,
            resultTwo.scope1.mm9EmsnQnt,
            resultTwo.scope1.mm10EmsnQnt,
            resultTwo.scope1.mm11EmsnQnt,
            resultTwo.scope1.mm12EmsnQnt,
          ],
        });
      }
      if (scopUse && scopUse.scopN2UseYn === "Y") {
        series2.push({
          type: "column",
          name: resultTwo.scope2.scopeNm,
          data: [
            resultTwo.scope2.mm1EmsnQnt,
            resultTwo.scope2.mm2EmsnQnt,
            resultTwo.scope2.mm3EmsnQnt,
            resultTwo.scope2.mm4EmsnQnt,
            resultTwo.scope2.mm5EmsnQnt,
            resultTwo.scope2.mm6EmsnQnt,
            resultTwo.scope2.mm7EmsnQnt,
            resultTwo.scope2.mm8EmsnQnt,
            resultTwo.scope2.mm9EmsnQnt,
            resultTwo.scope2.mm10EmsnQnt,
            resultTwo.scope2.mm11EmsnQnt,
            resultTwo.scope2.mm12EmsnQnt,
          ],
        });
      }
      if (scopUse && scopUse.scopN3UseYn === "Y") {
        series2.push({
          type: "column",
          name: resultTwo.scope3.scopeNm,
          data: [
            resultTwo.scope3.mm1EmsnQnt,
            resultTwo.scope3.mm2EmsnQnt,
            resultTwo.scope3.mm3EmsnQnt,
            resultTwo.scope3.mm4EmsnQnt,
            resultTwo.scope3.mm5EmsnQnt,
            resultTwo.scope3.mm6EmsnQnt,
            resultTwo.scope3.mm7EmsnQnt,
            resultTwo.scope3.mm8EmsnQnt,
            resultTwo.scope3.mm9EmsnQnt,
            resultTwo.scope3.mm10EmsnQnt,
            resultTwo.scope3.mm11EmsnQnt,
            resultTwo.scope3.mm12EmsnQnt,
          ],
        });
      }
      return series2.reverse();
    };

    const graphTypeB: Highcharts.Options = {
      colors: ["#00BB9F", "#24ACC5", "#2970CD"],
      chart: {
        type: "column",
        height: 200,
        spacingTop: 10,
        spacingBottom: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: {
        text: "",
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      scrollbar: { enabled: false },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 45,
          borderWidth: 0,
          borderRadius: 0,
        },
        series: {
          stacking: "normal",
        },
      },
      tooltip: {
        shared: true,
        headerFormat: "{point.key}<br/>",
        split: false,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        reversed: true,
        enabled: true,
        symbolHeight: 10,
        symbolRadius: 0,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: HighchartsOptionsUtil.locale.kr.categoryType.month,
        crosshair: true,
        lineColor: "#E1E1E1",
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        offset: 0,
        min: 0,
        softMax: 10,
        lineColor: "#E1E1E1",
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
        stackLabels: {
          enabled: true,
          style: {
            fontSize: '11px'
          },
          formatter: function (this: any) {
            return (
              `${this.total ? Number(this.total.toFixed(2)).toLocaleString() : ""}`
            );
          },
        },
      },
      series: getSeriesData2() as Highcharts.SeriesOptionsType[],
    };
    setChartOptionsB(graphTypeB);

  }, [resultTwo]);

  //세번째 차트 이펙트
  useEffect(() => {
    const graphTypeC: Highcharts.Options = {
      colors: ["#8CB9D9", "#6CE9FF", "#4F9CFF", "#7E87FE"],
      chart: {
        type: "column",
        height: 190,
        marginLeft: 55,
        spacingTop: 0,
        spacingBottom: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: {
        text: "",
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      scrollbar: { enabled: false },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 90,
          borderRadius: 0,
          borderWidth: 0,
        },
        series: {
          stacking: "normal",
          dataLabels: {
            inside: true,
            enabled: true,
            shadow: true,
            style: {
              color: '#fff',
              textOutline: 'none'
            },
            formatter: function (this: any) {
              return (
                `<span><strong>${this.y ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`
              );
            },
          },
        },
      },
      tooltip: {
        headerFormat: "{point.key}<br/>",
        split: false,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        enabled: true,
        symbolHeight: 10,
        symbolRadius: 0,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: HighchartsOptionsUtil.locale.kr.categoryType.month,
        crosshair: true,
        lineColor: "#E1E1E1",
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        offset: 0,
        min: 0,
        softMax: 10,
        lineColor: "#E1E1E1",
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      series: Object.entries(resultThree.scope1).reduce((acc: any, [k, v]: any) => {
        acc.push({
          type: "column",
          name: k,
          data: Object.values(v).map((k: any) => Number(k.toFixed(2)))
        });
        return acc;
      }, []),
    };

    const graphTypeD: Highcharts.Options = {
      colors: ["#8CB9D9", "#6CE9FF", "#4F9CFF", "#7E87FE"],
      chart: {
        type: "column",
        height: 190,
        marginLeft: 55,
        spacingTop: 0,
        spacingBottom: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: {
        text: "",
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      scrollbar: { enabled: false },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 90,
          borderRadius: 0,
          borderWidth: 0
        },
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            inside: true,
            shadow: true,
            style: {
              color: '#fff',
              textOutline: 'none'
            },
            formatter: function (this: any) {
              return (
                `<span><strong>${this.y ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`
              );
            },
          },
        },
      },
      tooltip: {
        headerFormat: "{point.key}<br/>",
        split: false,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        enabled: true,
        symbolHeight: 10,
        symbolRadius: 0,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: HighchartsOptionsUtil.locale.kr.categoryType.month,
        crosshair: true,
        lineColor: "#E1E1E1",
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        offset: 0,
        min: 0,
        softMax: 10,
        lineColor: "#E1E1E1",
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      series: Object.entries(resultThree.scope2).reduce((acc: any, [k, v]: any) => {
        acc.push({
          type: "column",
          name: k,
          data: Object.values(v).map((k: any) => Number(k.toFixed(2))),
        });
        return acc;
      }, []),
    };

    const graphTypeE: Highcharts.Options = {
      colors: ["#8CB9D9", "#6CE9FF", "#4F9CFF", "#7E87FE"],
      chart: {
        type: "column",
        height: 190,
        marginLeft: 55,
        spacingTop: 0,
        spacingBottom: 0,
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: {
        text: "",
      },
      exporting: { enabled: false },
      credits: { enabled: false },
      scrollbar: { enabled: false },
      navigator: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 90,
          borderRadius: 0,
          borderWidth: 0
        },
        series: {
          stacking: "normal",
          dataLabels: {
            inside: true,
            enabled: true,
            shadow: true,
            style: {
              color: '#fff',
              textOutline: 'none'
            },
            formatter: function (this: any) {
              return (
                `<span><strong>${this.y ? Number(this.y.toFixed(2)).toLocaleString() : ""}</strong></span>`
              );
            },
          },
        },
      },
      tooltip: {
        headerFormat: "{point.key}<br/>",
        split: false,
        style: {
          fontSize: '14px'
        }
      },
      legend: {
        enabled: true,
        symbolHeight: 10,
        symbolRadius: 0,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      xAxis: {
        categories: HighchartsOptionsUtil.locale.kr.categoryType.month,
        crosshair: true,
        lineColor: "#E1E1E1",
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        offset: 0,
        min: 0,
        softMax: 10,
        lineColor: "#E1E1E1",
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      series: Object.entries(resultThree.scope3).reduce((acc: any, [k, v]: any) => {
        acc.push({
          type: "column",
          name: k,
          data: Object.values(v).map((k: any) => Number(k.toFixed(2)))
        });
        return acc;
      }, []),
    };

    const scope1 = resultData(resultThree.scope1);
    const scope2 = resultData(resultThree.scope2);
    const scope3 = resultData(resultThree.scope3);

    setScope1Total(scope1.toFixed(2));
    setScope2Total(scope2.toFixed(2));
    setScope3Total(scope3.toFixed(2));


    setChartOptionsC(graphTypeC);
    setChartOptionsD(graphTypeD);
    setChartOptionsE(graphTypeE);
  }, [resultThree]);

  function resultData(scope: any): number {
    const result = Object.values(scope).reduce((acc: number, e: any) => {
      const total = Object.values(e).reduce((acc2: number, e2: any) => {
        return acc2 + e2;
      }, 0);
      return acc + total;
    }, 0);
    return parseFloat(result.toFixed(2));
  }

  const handleDate = (e: any, setting: any) => {
    setting(e.fromDateString.toString().substring(0, 4));
  };

  return (
    <>
      {!isDetail ? (
        <div className="container">
          <section className="section active">
            <div style={{ display: "block", float: "right" }}>
              <ArcButton
                type={ButtonType.custom}
                text={"뒤로가기"}
                hasIcon={true}
                color={ButtonColor.btn1}
                icon={ButtonIcon.reset}
                onClick={clickBackBtn}
              />
            </div>
            <br />
            <br />
            <div className="col-wrap">
              <div className="col-md-6">
                <div className="cont">
                  <div className="titFlex">
                    <h3 className="titT1">Scope별 총 배출량 비율</h3>
                  </div>
                  <div className="chart mgt30">
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsA}
                      constructorType={"stockChart"} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="cont">
                  <div className="titFlex">
                    <h3 className="titT1">월간 Scope별 배출량</h3>
                    <div className="btns mg0">
                      <div className="calendar-input year disIb">
                        <ArcDatePicker
                          onChange={(e) => {
                            handleDate(e, setYearOne);
                          }}
                          id={"조회년도"}
                          viewType={"year"}
                          name={"yearOne"}
                          placeholders={{ fromDate: toDayYear.toString() }}
                        />
                      </div>
                      <ArcButton
                        onClick={() => {
                          setRerender(!rerender);
                        }}
                        type={"search"}
                        hasIcon={true}
                      />
                    </div>
                  </div>
                  <div className="chart">
                    <HighchartsReact highcharts={Highcharts} options={chartOptionsB}
                      constructorType={"stockChart"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wrap">
              <div className="col-md-12">
                <div className="cont">
                  <div className="tit-btns-wrap">
                    <h3 className="titT1">연도별 배출량</h3>
                    <div className="btns mgb10">
                      <div className="calendar-input disIb">
                        <ArcDatePicker
                          onChange={(e) => {
                            handleDate(e, setYearTwo);
                          }}
                          id={"조회년도"}
                          viewType={"year"}
                          name={"yearOne"}
                          placeholders={{ fromDate: toDayYear.toString() }}
                        />
                      </div>
                      <ArcButton
                        onClick={() => {
                          setRerender(!rerender);
                          setNowYear(yearTwo);
                        }}
                        type={"search"}
                        hasIcon={true}
                      />
                    </div>
                  </div>

                  <div className="box-graph5 no-scroll pdb20">
                    <ul>
                      {scopUse && scopUse.scopN1UseYn === "Y" ? (
                        <li>
                          <div className="scope" style={{ width: "200px" }}>
                            <span>Scope1</span>
                            <div className="cnt">
                              <strong>
                                <em data-count="140"
                                  className="change">{scope1Total !== undefined ? Number(scope1Total).toLocaleString() : ""}</em>tCO2eq
                              </strong>
                              <span>{nowYear}년</span>
                            </div>
                          </div>
                          <div className="chart">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={chartOptionsC}
                              constructorType={"stockChart"}
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {scopUse && scopUse.scopN2UseYn === "Y" ? (
                        <li>
                          <div className="scope" style={{ width: "200px" }}>
                            <span>Scope2</span>
                            <div className="cnt">
                              <strong>
                                <em data-count="100"
                                  className="change">{scope2Total !== undefined ? Number(scope2Total).toLocaleString() : ""}</em>tCO2eq
                              </strong>
                              <span>{nowYear}년</span>
                            </div>
                          </div>
                          <div className="chart">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={chartOptionsD}
                              constructorType={"stockChart"}
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {scopUse && scopUse.scopN3UseYn === "Y" ? (
                        <li>
                          <div className="scope" style={{ width: "200px" }}>
                            <span>Scope3</span>
                            <div className="cnt">
                              <strong>
                                <em data-count="100"
                                  className="change">{scope3Total !== undefined ? Number(scope3Total).toLocaleString() : ""}</em>tCO2eq
                              </strong>
                              <span>{nowYear}년</span>
                            </div>
                          </div>
                          <div className="chart">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={chartOptionsE}
                              constructorType={"stockChart"}
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <Board />
      )}
    </>
  );
}
