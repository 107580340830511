import {lazy} from "react";

const Notice = lazy(() => import("pages/info/notice/Notice"));
const NoticeWrite = lazy(() => import("pages/info/notice/NoticeWrite"));
const NoticeView = lazy(() => import("pages/info/notice/NoticeView"));

const Faq = lazy(() => import("pages/info/faq/Faq"));
const FaqWrite = lazy(() => import("pages/info/faq/FaqWrite"));

const Qna = lazy(() => import("pages/info/qna/Qna"));
const QnaWrite = lazy(() => import("pages/info/qna/QnaWrite"));
const QnaView = lazy(() => import("pages/info/qna/QnaView"));
const QnaWriteAsk = lazy(() => import("pages/info/qna/QnaWriteAsk"));
export const infoRouter: any = [
  {path: "/info/notice", element: <Notice />},
  {path: "/info/notice/write", element: <NoticeWrite />},
  {path: "/info/notice/view", element: <NoticeView />},

  {path: "/info/faq", element: <Faq />},
  {path: "/info/faq/write", element: <FaqWrite />},

  {path: "/info/qna", element: <Qna />},
  {path: "/info/qna/write", element: <QnaWrite />},
  {path: "/info/qna/view", element: <QnaView />},
  {path: "/info/qna/write-ask", element: <QnaWriteAsk />},
]