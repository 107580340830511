import {ApiUrlUtil} from "utils/apiUrlUtil";
import Api from "services/common/Api";

export class AnalysisService extends Api {
  private static _boardService: AnalysisService;
  static readonly deleteRow: any;

  // instance 생성
  public static getInstance(): AnalysisService {
    if (!AnalysisService._boardService) {
      AnalysisService._boardService = new AnalysisService();
    }
    return AnalysisService._boardService;
  }

  // 탄소중립시뮬레이션 데이터 조회
  public async getSimulationData() {
    const url = ApiUrlUtil.buildQueryString(process.env.REACT_APP_URL_CP + "/simulation");
    return await super.get<any>(url);
  }
}
