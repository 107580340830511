import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18n from "config/translation/i18n";
import store, {persistor} from "stores/store";
import App from "App";
import ArcModalContainer from "components/arc/ArcModalContainer";
import {PersistGate} from "redux-persist/integration/react"
import {HelmetProvider} from "react-helmet-async";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
          <ArcModalContainer />
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </HashRouter>
);
