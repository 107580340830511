import Api from "services/common/Api";
import {DbEnergyMarketModel} from "pages/db/DbEnergyMarket";

export class OtDashboardService extends Api {
  private static service: OtDashboardService;

  public static getInstance(): OtDashboardService {
    if(!OtDashboardService.service) {
      OtDashboardService.service = new OtDashboardService();
    }
    return OtDashboardService.service;
  }

  private OTDB: string = process.env.REACT_APP_URL_OT + "/db";

  public async getEmarketAdminDb() {
    return await super.get<DbEnergyMarketModel>(this.OTDB + "/emarket/admin");
  }

}
