import ArcRadioButton, { RadioButtonDataModel } from "../../components/arc/ArcRadioButton";

export default function ArcRadioButtonExample() {

  const dataSource: RadioButtonDataModel[] = [
    { label: '옵션 1', value: '1' },
  ];
  const dataSource2: RadioButtonDataModel[] = [
    { label: '옵션 2', value: '2'},
  ];
  const dataSourcetext: RadioButtonDataModel[] = [
    { label: '옵션one', value: 'one' },
  ];
  const dataSourcetext2: RadioButtonDataModel[] = [
    { label: '옵션two', value: 'two'},
  ];

  const RadioChange=() => {}

  return (
    <>
      <h5 className="titT3">라디오버튼 : 버트만 존재 시</h5>
      <ArcRadioButton dataSource={dataSource} nameGroup={"number"} readOnly={true} onChange={RadioChange}/>
      <ArcRadioButton dataSource={dataSource2} nameGroup={"number2"} disabled={true} onChange={RadioChange} />

      <h5 className="titT3">라디오버튼 : 텍스트 존재 시</h5>
      <ArcRadioButton dataSource={dataSourcetext} nameGroup={"number3"} onChange={RadioChange} />
      <ArcRadioButton dataSource={dataSourcetext2} nameGroup={"number4"} onChange={RadioChange} isAlt={true}/>
    </>
  )
}