export class SmpModel {
    trdYmd?: string;
    trdHh?: string;
    areaCd?: string;
    smp?: number;
    hgSmp?: number;
    lwSmp?: number;
    avgSmp?: number;

    constructor(data?: Partial<SmpModel>) {
        Object.assign(this, data);
    }

    toMap(): Record<string, any> {
        return {
            trdYmd: this.trdYmd,
            trdHh: this.trdHh,
            areaCd: this.areaCd,
            smp: this.smp ?? 0,
            hgSmp: this.hgSmp,
            lwSmp: this.lwSmp,
            avgSmp: this.avgSmp
        };
    }

    static fromMap(data: Record<string, any>): SmpModel {
        return new SmpModel(data);
    }
}