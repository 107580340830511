import React, {useRef, useState} from "react";
import ArcGrid, {
  ArcGridCheckResult,
  GridApi, GridColumn,
  GridComplexHeader, GridEditor
} from "../../components/arc/ArcGrid";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../stores/hook";
import {CodeUtil} from "utils/codeUtil";
import moment from "moment";
import ArcButton, {ButtonType} from "components/arc/ArcButton";

export default function ArcGridExample() {

  const {t} = useTranslation()
  const dispatch = useAppDispatch();
  const gridRef: any = useRef<typeof ArcGrid>()
  const gridRef2: any = useRef<typeof ArcGrid>()
  const gridRef3: any = useRef<typeof ArcGrid>()

  const [activeTab, setActiveTab] = useState<number>(0)
  const [checkedRowCount, setCheckedRowCount] = useState<number>(0)

  class CustomGridCellRenderer {
    private div: HTMLDivElement
    private el: HTMLSpanElement

    constructor(props: any) {
      this.div = document.createElement('div')
      this.div.className = 'tui-grid-cell-content'
      this.div.style.whiteSpace = 'normal'

      this.el = document.createElement('span')

      if('titlNm' === props.columnInfo.name) {
        this.titleRender(props)
      } else if('prstsCd' === props.columnInfo.name) {
        this.prstsRender(props)
      }

      this.div.appendChild(this.el)
    }

    getElement() {
      return this.div
    }

    titleRender(props: any) {
      const {rowKey, value} = props
      const rowData = gridRef.current?.getInstance().getRow(rowKey)
      this.div.classList.add('unlocked')

      if('Y' === rowData.anymYn) {
        this.div.classList.add('locked')
      }

      this.el.innerText = String(value)
    }

    prstsRender(props: any) {
      this.div.classList.add('status')

      switch(Number(props.value)) {
        case 1: this.div.classList.add('pending'); break;
        case 2: this.div.classList.add('approved'); break;
        case 3: this.div.classList.add('completed'); break;
        case 4: this.div.classList.add('rejected'); break;
      }

      this.el.innerText = CodeUtil.prstsCodeConvert(Number(props.value))
    }
  }

  const gridDatasource: GridApi = {
    readData: {url: '/api/grid/data1', method: 'GET'},
    rootUrl: 'http://localhost:5000'
  }

  const gridColumns: GridColumn[] = [
    {
      header: t('ot.rpdc.label.rpdcCd'),
      name: 'rpdcCd',
      align: 'center',
      width: 150,
      whiteSpace: 'normal',
      formatter: function(e: any) {
        return CodeUtil.rpdcCodeConvert(e.value)
      }
    },
    {
      header: t('ot.rpdc.label.rpdcTitle'),
      name: 'titlNm',
      align: 'left',
      renderer: {
        type: CustomGridCellRenderer
      },
    },
    {
      header: t('common.label.insName'),
      name: 'wrtrId',
      align: 'center',
      width: 150,
      whiteSpace: 'normal',
    },
    {
      header: t('common.label.insDt'),
      name: 'regDt',
      align: 'center',
      width: 170,
      whiteSpace: 'normal',
      formatter: function(e: any) {
        const utcDate = moment.utc(e.value).toDate()
        return moment.utc(e.value).local().format('YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      header: t('ot.rpdc.label.prstsCd'),
      name: 'prstsCd',
      align: 'center',
      width: 120,
      renderer: {
        type: CustomGridCellRenderer
      },
    }
  ];

  const complexHeader: GridComplexHeader = {
    height: 90,
    complexColumns: [
      {header: '신고', name: '신고', childNames: ['rpdcCd', 'titlNm']}
    ]
  }


  const gridColumns2: GridColumn[] = [
    {
      header: t('ot.rpdc.label.rpdcCd'),
      name: 'rpdcCd',
      align: 'center',
      width: 150,
      whiteSpace: 'normal',
      formatter: function(e: any) {
        return CodeUtil.rpdcCodeConvert(e.value)
      }
    },
    {
      header: t('ot.rpdc.label.rpdcTitle'),
      name: 'titlNm',
      align: 'left',
    },
    {
      header: t('common.label.insName'),
      name: 'wrtrId',
      align: 'center',
      width: 150,
      whiteSpace: 'normal',
    },
    {
      header: t('common.label.insDt'),
      name: 'regDt',
      align: 'center',
      width: 170,
      whiteSpace: 'normal',
      formatter: function(e: any) {
        const utcDate = moment.utc(e.value).toDate()
        return moment.utc(e.value).local().format('YYYY-MM-DD HH:mm:ss')
      }
    },
  ];

  const checkedRow = (data: ArcGridCheckResult) => {
    setCheckedRowCount(data.checkedRows.length)
  }

  const gridColumns3: GridColumn[] = [
    {
      header: t('ot.rpdc.label.rpdcCd'),
      name: 'rpdcCd',
      align: 'center',
      width: 150,
      whiteSpace: 'normal',
      formatter: function(e: any) {
        return CodeUtil.rpdcCodeConvert(e.value)
      }
    },
    {
      header: t('ot.rpdc.label.rpdcTitle'),
      name: 'titlNm',
      align: 'left',
    },
    {
      header: t('common.label.insName'),
      name: 'wrtrId',
      align: 'center',
      width: 200,
      whiteSpace: 'normal',
    },
  ];

  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    editableColumns: [
      {
        columnName: 'rpdcCd',
        editorType: 'select',
        options: {
          onChange: handleChangeEditor,
          listItems: [{text: '계약위반', value: 1}, {text: '대금미납', value: 2}, {text: '기타', value: 3}],
          validation: {
            dataType: 'number',
            required: true
          }
        }
      },
      {
        columnName: 'titlNm',
        editorType: 'text',
        options: {
          onChange: handleChangeEditor,
          validation: {
            dataType: "string",
            required: true,
            max: 100

          }
        }
      },
      {
        columnName: 'wrtrId',
        editorType: 'text',
        options: {
          onChange: handleChangeEditor,
          validation: {
            dataType: 'number',
            required: true,
            regExp:  /^\d{0,11}(\.\d{0,4})?$/,
            regExpMessage: '전체 길이는 15자리, 소수점은 4자리까지 가능합니다.',

          }
        }
      }
    ]
  }

  function handleChangeEditor(data: any) {

  }

  return (
    <div className="container">
      <section className="section active">
        <div className="tab-st-box1">
          <ul className="tab-st1">
            <li className={activeTab === 0 ? "on" : ""}>
              <ArcButton type={ButtonType.custom}
                         text="Read Me"
                         onClick={() => setActiveTab(0)} />
            </li>
            <li className={activeTab === 1 ? "on" : ""}>
              <ArcButton type={ButtonType.custom}
                         text="Example 1"
                         onClick={() => setActiveTab(1)} />
            </li>
            <li className={activeTab === 2 ? "on" : ""}>
              <ArcButton type={ButtonType.custom}
                         text="Example 2"
                         onClick={() => setActiveTab(2)} />
            </li>
            <li className={activeTab === 3 ? "on" : ""}>
              <ArcButton type={ButtonType.custom}
                         text="Example 3"
                         onClick={() => setActiveTab(3)} />
            </li>
          </ul>
        </div>
        {
          activeTab === 0 && (
            <>
              <h1> 설명 </h1>
              * 데이터 보고 싶으면 upload-download test server 켜야됨
              <br />
              <br />

              [Example 1] <br />
              - complexHeader 및 cell renderer 사용 <br />
              - pagination 적용 (근데 제대로 동작은 안함 - )

              <br />
              <br />

              [Example 2] <br />
              - checkbox rowHeader 및 grid data 다운로드 적용 <br />
              - checkbox 체크 시 <br />
              &nbsp;&nbsp; - checkedRows: any[]   = 체크된 모든 row에 대한 list <br />
              &nbsp;&nbsp; - isChecked: boolean   = 현재 클릭한 체크 박스 혹은 전체 체크 상태 <br />
              &nbsp;&nbsp; - checkedRowData?: any = 현재 체크한 row 정보 (allCheck에서는 없음) <br />

              <br />
              <br />
            </>

          )
        }

        {
          activeTab === 1 && (
            <ArcGrid gridRef={gridRef}
                     datasource={gridDatasource}
                     columns={gridColumns}
                     complexHeader={complexHeader}
                     useGridInfoHeader={true}
                     isTest={true}
            />

          )
        }

        {
          activeTab === 2 && (
            <>
              <span>체크된 항목 갯수: {checkedRowCount}</span>
              <ArcGrid gridRef={gridRef2}
                       datasource={gridDatasource}
                       columns={gridColumns2}
                       rowHeaders={["checkbox"]}
                       useGridInfoHeader={false}
                       usePagination={false}
                       downloadType={{exportType: "xlsx", fileName: '123'}}
                       // downloadType='xlsx'
                       onCheckRow={checkedRow}
                       isTest={true}
              />
            </>
          )
        }

        {
          activeTab === 3 && (
            <>
              <ArcGrid gridRef={gridRef3}
                       datasource={gridDatasource}
                       columns={gridColumns3}
                       editor={gridEditor}
                       usePagination={false}
                       isTest={true}
              />
            </>
          )
        }

      </section>
    </div>
  )
}