import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import CountUp from "react-countup";
import { ScnroService } from "services/rp/ScnroService";
import { ResponseResultCode } from "model/response-api";
import { PortfolioService } from "services/rp/PortfolioService";
import { MatchingTargetService } from "services/rp/MatchingTargetService";

HighchartsMore(Highcharts);

export default function DbAdminRe100Planner() {

  const [monthlyScnroCount, setMonthlyScnroCount] = useState({ currentScnro: 0, previousScnro: 0 });
  const [monthlyInqCntCount, setMonthlyInqCntCount] = useState({ currentInqCnt: 0, previousInqCnt: 0 });
  const [monthlyPpaRqs, setMonthlyPpaRqs] = useState(
    { currentPpaRqsCnt: 0, previousPpaRqsCnt: 0, currentPpaRqsCpct: 0, previousPpaRqsCpct: 0 })
  const [percentScnro, setPercentScnro] = useState(
    { coScnro: 0, ecScnro: 0, ggScnro: 0 });
  const [graphA, setGraphA] = useState({
    chart: {
      backgroundColor: 'transparent',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      height: 450,
      margin: [20, 0, 20, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0

    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    legend: {
      enabled: false
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    tooltip: {
      valueSuffix: '%'
    },
    plotOptions: {
      series: {
        showInLegend: true,
        borderWidth: 0,
        borderRadius: 0,
        colorByPoint: true,
        type: 'pie',
        innerSize: '40%',
        allowPointSelect: true,
        dataLabels: {
          useHTML: true,
          enabled: true,
          distance: 50,
          formatter(this: any) {
            return '<div style="text-align:center;"><span class="bar-graph-name" style="display:block;font-size:.9rem;font-weight:400;color:#fff;">' + this.point.name + '</span><span class="bar-graph-color-num" style="position:relative;display:inline-block;margin-top:5px;padding:2px 5px;font-size:.9rem;line-height:1;font-weight:400;border-radius:15px;overflow:hidden;background:' + this.color + '"><span style="position:relative;display:inline-block;z-index:1;color:#fff;line-height:1;font-size:.8rem;">' + this.y + '%</span><em style="position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,.3);"></em></span></div>';
          }
        },
      }
    },
    colors: ['#4fd5a9', '#44c5dc', '#4b96f9', '#545cc2'],
    series: [{
      name: '포트폴리오 등록 분석',
      type: 'pie',
      data: [{
        name: '온실가스 감축 인정',
        y: 0
      }, {
        name: '경제적',
        y: 0
      }, {
        name: '편의성 중심',
        y: 0
      }]
    }]
  });
  const [graphB, setGraphB] = useState({
    colors: ['#3c92ff', '#2bd7f6', '#16e4c6'],
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: 450
    },
    title: {
      text: ''
    },
    exporting: { enabled: false },
    credits: { enabled: false },
    plotOptions: {
      series: {
        borderRadius: 0,
        borderWidth: 0,
        stacking: true,
      }
    },
    tooltip: {
      headerFormat: ''
    },
    legend: {
      symbolRadius: 0,
      symbolHeight: 10,
      align: 'right',
      verticalAlign: 'top',
      itemStyle: { 'color': '#fff' }
    },
    xAxis: {
      categories: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
      min: 0,
      title: { text: '' },
      gridLineColor: '#666b78',
      tickColor: '#666b78',
      labels: { style: { color: '#d1d7e4' } }
    },
    yAxis: {
      title: { text: '(MWh)', style: { color: '#d1d7e4' } },
      gridLineColor: '#666b78',
      tickColor: '#666b78',
      lineColor: '#666b78',
      labels: {
        style: { color: '#d1d7e4' }
      },
      stackLabels: {
        enabled: true,
        useHTML: true,
        formatter: function (this: any) {
          return '<span class="datalebel-total"><em style="color:#fff;">' + Highcharts.numberFormat(this.total, 0, '.', ',') + '</em></span>';
        }
      }
    },
    series: [
      {
        name: '직접PPA on-site',
        data: []
      }, {
        name: '직접PPA off-site',
        data: []
      }, {
        name: '제3자PPA',
        data: []
      }
    ]
  });


  useEffect(() => {
    getMonthlyScnroCount();
    getMonthlyInqCntCount();
    getMonthlyPpaRqsCntCpct();
    getScnroRgAn();
    getPpaRqsTransition();
  }, [])

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'mainscript';
    script.src = "/assets/js/sub.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [monthlyScnroCount, monthlyInqCntCount, monthlyPpaRqs, percentScnro]);

  useEffect(() => {
    document.body.classList.add("admin");

    return () => {
      document.body.classList.remove("admin");
    };
  }, []);


  // 포트폴리오 등록 건수
  async function getMonthlyScnroCount() {
    const response = await ScnroService.getInstance().getMonthlyScnroCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setMonthlyScnroCount({
          ...monthlyScnroCount,
          currentScnro: response.data.currentMonthScnro,
          previousScnro: response.data.previousMonthScnro
        });
      } else {
        setMonthlyScnroCount({ currentScnro: 0, previousScnro: 0 })
      }
    }
  }

  async function getMonthlyInqCntCount() {
    const response = await PortfolioService.getInstance().getMonthlyInqCntCount();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setMonthlyInqCntCount({
          ...monthlyInqCntCount,
          currentInqCnt: response.data.currentMonthInqCnt,
          previousInqCnt: response.data.previousMonthInqCnt
        });
      } else {
        setMonthlyInqCntCount({ currentInqCnt: 0, previousInqCnt: 0 });
      }
    }
  }

  async function getMonthlyPpaRqsCntCpct() {

    const response = await MatchingTargetService.getInstance().getMonthlyPpaRqsCntCpct();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setMonthlyPpaRqs({
          ...monthlyPpaRqs,
          currentPpaRqsCnt: response.data.ctPpaRqsCount,
          previousPpaRqsCnt: response.data.pvPpaRqsCount,
          currentPpaRqsCpct: response.data.ctPpaRqsCpct,
          previousPpaRqsCpct: response.data.pvPpaRqsCpct
        })
      } else {
        setMonthlyPpaRqs({ currentPpaRqsCnt: 0, previousPpaRqsCnt: 0, currentPpaRqsCpct: 0, previousPpaRqsCpct: 0 })
      }
    }
  }

  async function getScnroRgAn() {

    const response = await ScnroService.getInstance().getScnroRgAn();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        setGraphA({
          ...graphA,
          series: [{
            name: '포트폴리오 등록 분석',
            type: 'pie',
            data: [{
              name: '온실가스 감축 인정',
              y: response.data.co
            }, {
              name: '경제적',
              y: response.data.ec
            }, {
              name: '편의성 중심',
              y: response.data.gg
            }]
          }]
        });
      } else {
        setPercentScnro({ coScnro: 0, ecScnro: 0, ggScnro: 0 });
      }
    }

  }

  async function getPpaRqsTransition() {
    const response = await MatchingTargetService.getInstance().getPpaRqsTransition();

    if (response) {
      if (ResponseResultCode.Y === response.resultCode) {
        const drcOnArray: any = [];
        const thdArray: any = [];
        const drcOffArray: any = [];

        for (let i = 1; i <= 12; i++) {
          const key = i.toString();
          drcOnArray.push(response.data.monthSumsDrcOn[key]);
          thdArray.push(response.data.monthSumsThd[key]);
          drcOffArray.push(response.data.monthSumsDrcOff[key]);
        }

        setGraphB({
          ...graphB,
          series: [
            {
              name: '직접PPA on-site',
              data: drcOnArray
            }, {
              name: '직접PPA off-site',
              data: thdArray
            }, {
              name: '제3자PPA',
              data: drcOffArray
            }
          ]
        })
      } else {

      }
    }
  }

  function duration(data: any) {
    if (data < 10) {
      return 4;
    } else {
      return 2;
    }
  }

  return (
    <>
      <section className="section">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont pdb5">
              <div className="icon-box-wrap7">
                <ul className="icon-box-flex">
                  <li>
                    <div className="c1">
                      <strong>이번달 re100 시뮬레이션 이용 건수</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyInqCntCount.currentInqCnt ?? 0} className="change l-length"></em> */}
                          <CountUp end={monthlyInqCntCount.currentInqCnt ?? 0} duration={duration(monthlyInqCntCount.currentInqCnt)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          건</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>지난달 re100 시뮬레이션 이용 건수</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyInqCntCount.previousInqCnt ?? 0} className="change l-length"></em> */}
                          <CountUp end={monthlyInqCntCount.previousInqCnt ?? 0} duration={duration(monthlyInqCntCount.previousInqCnt)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          건</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>이번달 re100 로드맵 등록 건수</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyScnroCount.currentScnro ?? 0} className="change l-length"></em> */}
                          <CountUp end={monthlyScnroCount.currentScnro ?? 0} duration={duration(monthlyScnroCount.currentScnro)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          건</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>지난달 re100 로드맵 등록 건수</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyScnroCount.previousScnro ?? 0} className="change l-length"> </em> */}
                          <CountUp end={monthlyScnroCount.previousScnro ?? 0} duration={duration(monthlyScnroCount.previousScnro)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          건</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="icon-box-wrap7">
                <ul className="icon-box-flex">
                  <li>
                    <div className="c1">
                      <strong>이번달 PPA 매칭 건수</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyPpaRqs.currentPpaRqsCnt ?? 0} className="change l-length"></em> */}
                          <CountUp end={monthlyPpaRqs.currentPpaRqsCnt ?? 0} duration={duration(monthlyPpaRqs.currentPpaRqsCnt)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          건</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>지난달 PPA 매칭 건수</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyPpaRqs.previousPpaRqsCnt ?? 0} className="change l-length"> </em> */}
                          <CountUp end={monthlyPpaRqs.previousPpaRqsCnt ?? 0} duration={duration(monthlyPpaRqs.previousPpaRqsCnt)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          건</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>이번달 PPA 거래 용량</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyPpaRqs.currentPpaRqsCpct ?? 0} className="change l-length"></em> */}
                          <CountUp end={monthlyPpaRqs.currentPpaRqsCpct ?? 0} duration={duration(monthlyPpaRqs.currentPpaRqsCpct)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          MWh</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="c1">
                      <strong>지난달 PPA 거래 용량</strong>
                      <div className="ac">
                        <span>
                          {/* <em data-count={monthlyPpaRqs.previousPpaRqsCpct ?? 0} className="change l-length"></em> */}
                          <CountUp end={monthlyPpaRqs.previousPpaRqsCpct ?? 0} duration={duration(monthlyPpaRqs.previousPpaRqsCpct)}>
                            {({ countUpRef }) => <em ref={countUpRef} />}
                          </CountUp>
                          MWh</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="icon-box-wrap7">
                <ul className="icon-box-flex">
                  <li className="fix-w40">
                    <div className="c1">
                      <strong>포트폴리오 등록 분석</strong>
                      <div className="graph">
                        <div id="graph-typeA" className="chart">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={graphA}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="fix-w60">
                    <div className="c1">
                      <strong>PPA 계약 용량 추이</strong>
                      <div className="graph">
                        <div id="graph-typeB" className="chart">
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={graphB}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
