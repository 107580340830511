import {useState} from "react"
import ArcSelectbox, {SelectboxSize} from "components/arc/ArcSelectbox";

export default function ArcSelectboxExample() {
  const [example,] = useState([
    {label:"option1", value:"value1"},
    {label: "option2", value: "value2"},
    {label: "option3", value: "value3"},
    {label: "option4", value: "value4"},
  ]);

  const handleChangeSelect = () => {
  };

  return (
    <div>
      <h3>Select Box</h3>
      <ArcSelectbox name={"이름"}
                    options={example}
                    onChange={handleChangeSelect}
                    useDefaultOption={true}
                    useDefaultOptionName={"ex"}
                    size={SelectboxSize.px100}
                    title={"제목"}
      />
      <ArcSelectbox name={"이름"}
                    options={example}
                    onChange={handleChangeSelect}
                    size={SelectboxSize.px100}
                    disabled={true}
      />
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <ArcSelectbox name={"이름"}
                    options={example}
                    onChange={handleChangeSelect}
                    useDefaultOption={true}
                    size={SelectboxSize.px300}
      />
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>
      <ArcSelectbox name={"이름"}
                    options={example}
                    onChange={handleChangeSelect}
                    size={SelectboxSize.w50}
                    useDefaultOption={true}
                    useDefaultOptionName={"예시"}
                    isRequired={true}
      />

    </div>
  )
}