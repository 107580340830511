import React, {useContext} from "react";
import {ReduceGoalPageProps} from "pages/cp/roadmap/YyRdcGoalBase";
import ArcRadioButton, {RadioButtonDataModel} from "components/arc/ArcRadioButton";
import ArcButton, {ButtonColor, ButtonType} from "components/arc/ArcButton";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {ReduceGoalContext} from "pages/cp/roadmap/YyRdcGoalContext";

export default function YyRdcGoalType(props: ReduceGoalPageProps) {

  const {t} = useTranslation();
  const reduceGoalContext = useContext(ReduceGoalContext);

  const dataSource: RadioButtonDataModel[] = [
    {label: '정량적 목표', value: '1'},
    {label: '자발적 목표', value: '2'},
  ];

  const handleChangeRadio = (e: any) => {
    reduceGoalContext.setTotal({
      ...reduceGoalContext.total,
      goalType: Number(e.target.value),
    });
  }

  const handleClickNextPage = () => {
    const radios = Array.from(document.getElementsByName('type') as NodeListOf<HTMLInputElement>);
    if(!radios.some(s => s.checked)) {
      toast.error(`유형을 선택하세요.`);
    } else {
      props.onNext();
    }
  };

  return (
    <div className="contents">
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont">
              <div className="titFlex">
                <ul className="tab-order-st1">
                  <li>
                    <div><strong>01</strong><span>기준연도 설정</span></div>
                  </li>
                  <li className="on">
                    <div><strong>02</strong><span>목표강도 유형 설정</span></div>
                  </li>
                  <li>
                    <div><strong>03</strong><span>목표강도 설정</span></div>
                  </li>
                </ul>
                <div className="btns mgb0 ar">
                  <ArcButton type={ButtonType.text} text={t('cp.roadMap.reduceGoal.before')} onClick={props.onBefore}/>
                  <ArcButton type={ButtonType.custom}
                             color={ButtonColor.btn2}
                             text={t('cp.roadMap.reduceGoal.after')}
                             onClick={handleClickNextPage} />
                </div>
              </div>
              <hr className="line3"/>
              <div className="box-style10 mgb20">
                <div className="box10radio">
                  <h4 className="fs20 font-FM">유형설정</h4>
                  <div className="font-FR">
                    <ArcRadioButton dataSource={dataSource}
                                    selectedValue={reduceGoalContext.total.goalType.toString()}
                                    nameGroup="type"
                                    isAlt={true}
                                    onChange={handleChangeRadio} />
                  </div>
                </div>
              </div>
              <div className="col-wrap">
                <div className="col-md-12">
                  <div className="box-style1">
                    <div className="box-text-style4">
                      <p>
                        <span>기준연도 설정을 완료했다면, 감축경로의 종점이 되는 목표연도를 설정해야 합니다.</span>
                        <span>목표연도는 탄소중립을 달성하는 최종 목표와 감축 경로 선 위의 한 지점인 중간목표로 구분할 수 있습니다.</span>
                        <span>기업은 <strong>자율적으로 목표를 설정하거나 글로벌 이니셔티브 등을 참고해 정량적 목표를 설정할 수 있습니다.</strong></span>
                      </p>
                    </div>
                    <div>
                      <hr className="line2"/>
                      <div className="box-text-style5">
                        <ul>
                          <li>
                            <h4 className="titT5">정량적 목표</h4>
                            <p className="bu-st1">중소기업 탄소중립 목표 설정에 참고할 수 있는 글로벌 이니셔티브는 SBTi, SME
                              Climate
                              Hub등이 있습니다.
                              현재 많은 기업이 사용하는 방법은 SBTi의가이드라인입니다. SBTi는 Science BasedTargets
                              Initiative(과학기반목표이니셔티브)의 약자로써 기업·단체 등의 탄소 감축에 대해 과학적 방법과 실행을 요구하는
                              방법을
                              말합니다. 2021년 발간된 기후 변화에 관한 정부 간 협의체인 IPCC 6차 보고서에서 지구 온도를 1.5℃ 상승
                              제한을 목표로
                              하였고, 이를 기반으로 하여 온실가스 배출량 감축 목표를 설정합니다.</p>
                          </li>
                          <li className="mgt10">
                            <h4 className="titT5">자발적 목표</h4>
                            <p className="bu-st1">SBTi 및 SME Climate Hub 등의 국제적인 감축목표 방법에 의존하지
                              않고, 산정한
                              배출량 데이터를 기반으로 기업의 예산, 기술 현황 등을 고려해 감축량을 예측해 보고, 예측 결과를 기반으로 온실가스
                              배출량 감축
                              목표를 설정하는 방법입니다. 중간 및 목표연도와 목표감축량을
                              자유롭게 설정할 수 있고, 기업의 환경에 적합한 감축 전략을 수립할 수 있습니다. </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}