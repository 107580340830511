import React, {useEffect, useState} from 'react';
import TableRow, {TableRowProps} from "./ArcTableRow";

export interface TableProps {
  showCheck?: boolean;
  tableClass?: string;
  title?: string;
  width?: string;
  height?: number;
  tHeader?: TableRowProps[];
  tBody: TableRowProps[];
  tFooter?: TableRowProps[];
  onChecked?: (data: any) => void;
}

const defaultTableProps: TableProps = {
  showCheck: false,
  tableClass: '',
  title: '',
  width: '',
  tHeader: [],
  tBody: [],
  tFooter: [],
};

export default function ArcTable(props: TableProps = defaultTableProps) {
  const [tableData, setTableData] = useState<TableProps>(props);

  useEffect(() => {
    setTableData(props);
  }, [props]);

  const row = (item: TableRowProps) => {
    return (
      <TableRow
        key={crypto.randomUUID()}
        onRowChecked={(data) => {
          if (props.onChecked) {
            props.onChecked(data);
          }
        }}
        row={item.row}
        trClassName={item.trClassName}
      />
    )
  }

  return (
    <div className={tableData.tableClass}>
      <table className={tableData.title}>
        <caption>테이블</caption>
        <thead>
          {tableData?.tHeader?.map((item: TableRowProps) => row(item))}
        </thead>
        <tbody>
          {tableData?.tBody?.map((item: TableRowProps) => row(item))}
        </tbody>
        <tfoot>
          {tableData?.tFooter?.map((item: TableRowProps) => row(item))}
        </tfoot>
      </table>
    </div>
  )
}
