import React, { useEffect, useRef, useState } from "react";
import ArcGrid, {
  ArcGridCheckResult,
  CustomCommonCodeRenderer,
  GridApi,
  GridEditor
} from "components/arc/ArcGrid";
import ArcDatePicker from "../../../../components/arc/ArcDatePicker";
import { ComCdService } from "services/cp/ComCdService";
import { toast } from "react-toastify";
import { t } from "i18next";
import { CodeUtil } from "utils/codeUtil";
import { UserInfoModel } from "model/ua/UserInfoModel";
import { useAppSelector } from "stores/hook";
import { UserInfoUtil } from "utils/userInfoUtil";
import { AuthConstant } from "constants/authConstant";

export default function GlobalWarming(props: any) {
  const userInfo: UserInfoModel = useAppSelector(state => state.userInfo.userInfo);
  const toYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(toYear);
  const [gasCd, setGasCd] = useState<any[]>([]);

  useEffect(() => {
    getTmzCd();
  }, []);

  function getTmzCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_GHGS_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = CodeUtil.makeCpCommonCodeOptions(response.data.data);
          setGasCd(cdOptions);
        } else {
          setGasCd([]);
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  const gridRef: any = useRef<typeof ArcGrid>();
  const checkedRow = (data: ArcGridCheckResult) => {
  };

  // 그리드 컬럼
  const gridColumns: any[] = [
    {
      header: "emsnCoefId",
      name: "emsnCoefId",
      hidden: true,
    },
    {
      header: "aplyYy",
      name: "aplyYy",
      hidden: true,
    },
    {
      header: "구분",
      name: "ghgsCd",
      align: "center",
      whiteSpace: "normal",
      formatter: function (e: any) {
        return e.value?.comCdExpl;
      },
    },
    {
      header: "계수",
      name: "gasCoef",
      align: "right",
      whiteSpace: "normal",
      formatter: function (e: { value: any }) {
        if (e.value !== null && e.value !== undefined) {
          return e.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return "";
      },
    },
  ];

  const gridEditor: GridEditor = {
    isDataHotSwap: false,
    defaultRowDataSet: {
      aplyYy: (year ?? "").toString(),
    },
    editableColumns: [
      {
        columnName: "ghgsCd",
        editorType: CustomCommonCodeRenderer,
        options: {
          comCd: gasCd,
          validation: {
            validatorFn: (value: any) => {

              if (value === null || value === undefined) return false;
              if (value?.comCdExpl === undefined) return false;
              const gridInstance = gridRef.current?.getInstance();
              if (!gridInstance) return false;
              const data = gridInstance.getData();
              if (!Array.isArray(data) || data.length === 0) return false;
              return data.filter((e: any) => e?.ghgsCd?.comCdExpl === value.comCdExpl).length === 1;
            }
          },
        },
      },
      {
        columnName: "gasCoef",
        editorType: "text",
        options: {
          onChange: handleChangeEditor,
          type: "number",
          validation: {
            dataType: "number",
            regExp: /^\d{0,11}(\.\d{0,9})?$/,
            regExpMessage: "전체 길이는 20자리, 소수점은 9자리까지 가능합니다.",
          },
        },
      },
    ],
  };

  // 그리드 수정함수
  function handleChangeEditor(e: any) {
  }

  //  데이터 전송
  const adminGridApi: GridApi = {
    readData: { url: "/cp/mngGhgsGbwrmCoef", method: "GET", initParams: { aplyYy: year } },
    createData: { url: "/cp/mngGhgsGbwrmCoef", method: "POST" },
    updateData: { url: "/cp/mngGhgsGbwrmCoef", method: "PUT" },
    deleteData: { url: "/cp/mngGhgsGbwrmCoef", method: "DELETE" },
  };

  const gridApi: GridApi = {
    readData: { url: "/cp/mngGhgsGbwrmCoef", method: "GET", initParams: { aplyYy: year } },
  };

  return (
    <div className="col-wrap">
      <div className="col-md-12 min-h100-type1">
        <div className="cont">
          <div className="tit-btns-wrap">
            <h3 className="titT1">온실가스별 지구온난화 계수</h3>
            <ArcDatePicker
              onChange={(e) => {
                setYear("" + e.fromDateString?.slice(0, 4));
              }}
              fromDate={year}
              id={"year"}
              name={"year"}
              viewType={"year"}
              placeholders={{ fromDate: "YYYY" }} />
          </div>
          <div className="fs14">
            {UserInfoUtil.hasRole(userInfo, AuthConstant.ADMIN)
              ?
              <ArcGrid
                gridRef={gridRef}
                key={gasCd.length}
                datasource={adminGridApi}
                columns={gridColumns}
                rowHeaders={["checkbox"]}
                useGridInfoHeader={true}
                usePagination={false}
                editor={gridEditor}
                onDataLoaded={event => {
                  gridRef.current.getInstance().disableRow(gridRef.current.getInstance().getData().length-3, true);
                  gridRef.current.getInstance().disableRow(gridRef.current.getInstance().getData().length-2, true);
                  gridRef.current.getInstance().disableRow(gridRef.current.getInstance().getData().length-1, true);
                }}
                onCheckRow={checkedRow} />
              :
              <ArcGrid
                gridRef={gridRef}
                key={gasCd.length}
                datasource={gridApi}
                rowHeaders={["rowNum"]}
                columns={gridColumns}
                useGridInfoHeader={true}
                usePagination={false}

                onCheckRow={checkedRow} />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
