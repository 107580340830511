import "assets/css/ozreport/ContractFormReport.css"
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ArcButton, {ButtonType} from "components/arc/ArcButton";
import ArcDownload, {dataSourceProps} from "components/arc/ArcDownload";
import {FileInfoModel} from "model/common/fileInfoModel";
import {useAppDispatch, useAppSelector} from "stores/hook";
import {showAndHideSpinner} from "reducers/spinnerSlice";
import {ContractService} from "services/cb/ContractService";
import ContractModel from "model/cb/contractModel";
import {ContractUtil} from "utils/contractUtil";

export default function CntrCompleteReport() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.userInfo.userInfo);

  const initData = () => {
    try {
      return JSON.parse(location.state);
    } catch {
      return location.state;
    }
  };
  const [data, ] = useState<any>(initData);
  const [files, setFiles] = useState<dataSourceProps[]>([]);

  useEffect(() => {
    if(data && data.cntrFile && data.cntrFile.files && data.cntrFile.files.length > 0) {
      const cntrFiles: dataSourceProps[] = [];
      data.cntrFile.files.forEach((f: any) => {
        cntrFiles.push({realName: f.realNm, saveName: f.fileNm});
      });
      setFiles(cntrFiles);
    } else {
      setFiles([]);
    }
  }, [data]);

  useEffect(() => {
    const script = document.createElement('script');
    if(data && data.contractPPA && data.contractPPA.cntrCtt) {
      const sign: string = makePpaSignData(data);
      const cntrNo: string = JSON.stringify({cntrNo: data.cntrNo});
      script.type = 'text/javascript';
      script.id = 'cntr_ppa_contract_complete_detail_viewer';
      script.innerHTML = `
        function SetOZParamters_OZViewer() {
          let oz;
          oz = document.getElementById("OZViewer");
          oz.sendToActionScript("connection.servlet", "${process.env.REACT_APP_URL_REPORT}/server");
          oz.sendToActionScript("connection.reportname", "/contract_ppa.ozr");
          oz.sendToActionScript("connection.pcount", "3");
          oz.sendToActionScript("connection.args1", 'data=` + data.contractPPA.cntrCtt + `');
          oz.sendToActionScript("connection.args2", 'sign=` + sign + `');
          oz.sendToActionScript("connection.args3", 'complete=` + cntrNo + `');
          return true;
        }
        start_ozjs("OZViewer", "${process.env.REACT_APP_URL_REPORT}/ozhviewer/");
      `;
      document.body.appendChild(script);
    }
    return () => {
      if(document.getElementById('cntr_ppa_contract_complete_detail_viewer')) {
        document.body.removeChild(script);
      }
    }
  }, [data]);

  function makePpaSignData(data: ContractModel) {
    const signFile = data.contractPPA?.signFile;
    if(signFile && signFile.files && signFile.files.length > 0) {
      const pchsrFile = signFile.files.find(f => f.realNm?.includes('pchsr'));
      const brokerFile = signFile.files.find(f => f.realNm?.includes('saler'));

      const sign = {
        pchsrSign: ContractUtil.makeSignUrl(pchsrFile),
        brokerSign: ContractUtil.makeSignUrl(brokerFile),
        isDetail: true,
        isHistory: false,
        isUserPchsr: userInfo.userId === data.pchsrId,
      };
      return JSON.stringify(sign);
    } else {
      return JSON.stringify({
        pchsrSign: "", brokerSign: "", isDetail: true, isHistory: true, isUserPchsr: "",
      });
    }
  }

  async function handleClickDownloadFile(file: dataSourceProps) {
    if(data && data.cntrFile && data.cntrFile.files && data.cntrFile.files.length > 0) {
      const selectedFile = data.cntrFile.files
        .find((f: FileInfoModel) => f.fileNm === file.saveName);
      if(selectedFile) {
        dispatch(showAndHideSpinner(true));
        await ContractService.getInstance().downloadFile(selectedFile);
        dispatch(showAndHideSpinner(false));
      }
    }
  }

  return (<>
    <div className="container">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">

            <h3 className="titT1">PPA계약 절차</h3>
            <ul className="tab-order-st3">
              <li>
                <div>
                  <div className="circle"></div>
                  <strong>01</strong>
                  <span>거래협의</span>
                </div>
              </li>
              <li>
                <div>
                  <div className="circle"></div>
                  <strong>02</strong>
                  <span>계약서 작성</span>
                </div>
              </li>
              <li>
                <div>
                  <div className="circle"></div>
                  <strong>03</strong>
                  <span>기관등록
                <p>제3자 PPA_한전엔터,<br/>직접PPA_KPX 플랫폼</p>
              </span>
                </div>
              </li>
              <li className="on">
                <div>
                  <div className="circle"></div>
                  <strong>04</strong>
                  <span>계약완료</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="section active">
        <div className="col-wrap">
          <div className="col-md-12">
            <div className="cont" style={{height: "100%"}}>
              <div className="col-wrap">
                <div className="col-md-9">
                  <div className="veiwer-box">
                    <div id="OZViewer" className="OZViewer" style={{height: "90%", width: "100%"}}></div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="icon-box-wrap1-3 bw">
                    <div className="tit-btns-wrap">
                      <h5 className="titT3 mgt15">첨부파일</h5>
                      <div className="btns">
                        <ArcButton type={ButtonType.list}
                                   onClick={() => navigate("/rp/cntrlist")}/>
                      </div>
                    </div>
                    {(files && files.length > 0) && (
                      <ArcDownload dataSource={files}
                                   isCntr={true}
                                   onDownload={handleClickDownloadFile} />
                    )}
                    {files.length === 0 && (
                      <p>첨부된 파일이 없습니다.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>);
}