import React, { useContext, useEffect, useState } from "react";
import ArcButton, { ButtonColor, ButtonIcon, ButtonType } from "../../../components/arc/ArcButton";
import ArcInput from "../../../components/arc/ArcInput";
import ArcSelectbox from "../../../components/arc/ArcSelectbox";
import Highcharts from "highcharts";
import { HighchartsOptionsUtil } from "utils/highchartsOptionsUtil";
import { ReduceGoalPageProps } from "pages/cp/roadmap/YyRdcGoalBase";
import { useTranslation } from "react-i18next";
import { CodeUtil } from "utils/codeUtil";
import HighchartsReact from "highcharts-react-official";
import { RoadMapService } from "services/cp/RoadMapService";
import { ResponseResultCode } from "model/response-api";
import { toast } from "react-toastify";
import { ReduceGoalContext } from "pages/cp/roadmap/YyRdcGoalContext";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";
import CountUp from "react-countup";
import { CountUpUtil } from "../../../utils/countUpUtil";
import { useDispatch } from "react-redux";
import { openModal } from "reducers/modalSlice";
import AppAlert from "components/common/AppAlert";
import { JsxElementConstant } from "constants/jsxElementConstant";

Highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
});

Highcharts.setOptions(HighchartsOptionsUtil.locale.kr.globalOptions);

export default function YyRdcGoalSetting(props: ReduceGoalPageProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reduceGoalContext = useContext(ReduceGoalContext);
  const standardYear = reduceGoalContext.total.years[reduceGoalContext.total.years.length - 1];
  const standardEmsn = reduceGoalContext.total.emsn;

  const initialMidYear = (): number => {
    if (1 === reduceGoalContext.total.goalType) return standardYear + 10;
    else {
      const midYear = reduceGoalContext.total.midYear;
      const userId = reduceGoalContext.total.userId;
      return (midYear && userId) ? midYear : standardYear + 1
    }
  };
  const initialMidPercent = () => {
    if (1 === reduceGoalContext.total.goalType) return 50;
    else return reduceGoalContext.total.midPercent ?? 0;
  };
  const [midYear, setMidYear] = useState<number>(initialMidYear);
  const [midPercent, setMidPercent] = useState<number>(initialMidPercent);

  const initialGoalYear = (): number => {
    if (1 === reduceGoalContext.total.goalType) return 2050;
    else {
      const goalYear = reduceGoalContext.total.goalYear;
      const userId = reduceGoalContext.total.userId;
      return (goalYear && userId) ? goalYear : (midYear ? midYear + 1 : standardYear + 2);

    }
  };
  const initialGoalPercent = () => {
    if (1 === reduceGoalContext.total.goalType) return 100;
    else return reduceGoalContext.total.goalPercent ?? 0;
  };
  const [goalYear, setGoalYear] = useState<number>(initialGoalYear);
  const [goalPercent, setGoalPercent] = useState<number>(initialGoalPercent);

  function handleClickGoalTypeInfo(e: any) {
    e.preventDefault();
    const element = document.getElementsByClassName("tit-info");
    if (element) {
      element[0].classList.toggle("active");
    }
  }

  const makeYearOption = (baseYear: number) => {
    baseYear = baseYear + 1;
    const years = Array.from({ length: 2050 - baseYear + 1 }, (_, index) => baseYear + index);
    return years.map((m) => {
      return { label: m + t("common.unit.year"), value: m };
    });
  };

  const defaultChartOption = (yearCategory: number[]) => {
    return {
      chart: {
        type: "column",
        height: 300,
      },
      title: { text: "" },
      exporting: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        series: {
          pointWidth: 25,
          borderWidth: 0,
          borderRadius: 0,
          events: {
            legendItemClick: () => false
          }
        },
      },
      legend: {
        symbolRadius: 0,
        symbolHeight: 10,
        align: "right",
        verticalAlign: "top",
        itemStyle: {
          color: "#505050",
          fontSize: "14px",
        },
        itemHoverStyle: {
          color: "#000",
          fontSize: "14px",
        },
      },
      yAxis: {
        min: 0,
        softMax: 10,
        offset: 0,
        title: {
          text: `배출량(${standardEmsn >= 10000 ? "tCO2eq/천" : "tCO2eq"})`,
          style: {
            color: '#555',
            fontSize: '12px'
          },
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      xAxis: {
        categories: yearCategory ?? [],
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      tooltip: {
        headerFormat: '{point.key}년<br/>',
        split: false,
        shared: true,
        style: {
          fontSize: '14px'
        }
      },
    };
  };

  const [chartOption, setChartOption] = useState<any>(defaultChartOption);

  useEffect(() => {
    const lastChartYear = goalYear ?? midYear ?? standardYear;
    const midChartYear = midYear ?? standardYear;
    const years = Array.from({ length: lastChartYear - standardYear + 1 }, (_, index) => standardYear + index);
    const midYearIndex = years.indexOf(midChartYear);
    const goalYearIndex = years.indexOf(lastChartYear);
    const baseEmsn = standardEmsn >= 10000 ? standardEmsn / 1000 : standardEmsn;
    const midEmsn = (baseEmsn / 100) * (100 - midPercent);
    const goalEmsn = (baseEmsn / 100) * (100 - goalPercent);
    const values = [];
    const splineValues = [];
    values.push(Number(baseEmsn.toFixed(2)));
    splineValues.push(Number(baseEmsn.toFixed(2)));

    const midDecreaseStep = (baseEmsn - midEmsn) / midYearIndex;
    for (let i = 0; i < midYearIndex - 1; i++) {
      const newValue = parseFloat((values[values.length - 1] - midDecreaseStep).toFixed(2));
      values.push(newValue);
      splineValues.push({
        y: newValue,
        marker: {
          symbol: "",
          radius: 4,
          fillColor: "black",
        },
      });
    }

    if (midYear) {
      const midEmsnValue = Number(midEmsn.toFixed(2));
      values.push(midEmsnValue);
      splineValues.push({
        y: midEmsnValue,
        marker: {
          symbol: "diamond",
          radius: 8,
          fillColor: "red",
        },
      });
    }

    const endDecreaseStep = (midEmsn - goalEmsn) / (goalYearIndex - midYearIndex);
    for (let i = midYearIndex + 1; i < goalYearIndex; i++) {
      const newValue: any = Number(parseFloat((values[values.length - 1] - endDecreaseStep).toFixed(2)));
      values.push(newValue);
      splineValues.push({
        y: newValue,
        marker: {
          symbol: "",
          radius: 4,
          fillColor: "black",
        },
      });
    }

    if (goalYear) {
      const goalEmsnValue = Number(goalEmsn.toFixed(2));
      values.push(goalEmsnValue);
      splineValues.push({
        y: goalEmsnValue,
        marker: {
          symbol: "diamond",
          radius: 8,
          fillColor: "blue",
        },
      });
    }

    setChartOption({
      ...defaultChartOption(years),
      series: [
        {
          name: "연도별 절대적 목표",
          type: "column",
          data: values,
          color: "#00BB9F",
        },
        {
          name: "",
          type: "spline",
          marker: {
            symbol: "dash"
          },
          dashStyle: "ShortDash",
          data: splineValues,
          lineWidth: 1,
          color: "#2A0A29",
          showInLegend: false,
          enableMouseTracking: false,
        },
      ],
    });
  }, [midYear, midPercent, goalYear, goalPercent]);

  function handleChangeMidYear(e: any) {
    if (goalYear) {
      if (e.target.value > goalYear) {
        setGoalYear(Number(e.target.value) + 1);
      }
    }
    setMidYear(Number(e.target.value));
  }

  function handleChangeGoalYear(e: any) {
    setGoalYear(Number(e.target.value));
  }

  function handleChangeMidInput(e: any) {
    if ((e ?? 0) > goalPercent) {
      setGoalPercent(Number(e ?? 0));
    }
    setMidPercent(Number(e ?? 0));
  }

  function handleChangeGoalInput(e: any) {
    setGoalPercent(Number(e ?? midPercent ?? 0));
  }

  function handleClickSave() {
    dispatch(openModal({
      path: 'cp/confirm/roadmap/save',
      target: AppAlert,
      wrapStyle: { width: "20rem" },
      confirmProps: {
        type: 'save',
        title: `선택된 감축목표를 저장하시겠습니까?`,
        onConfirm: saveData,
      }
    }));
  }

  function saveData() {
    const param = {
      mtrmYy: midYear,
      midGoalRt: midPercent,
      goalYy: goalYear,
      goalRt: goalPercent,
      rdcGoalCdId: reduceGoalContext.total.goalType,
      strtYy: reduceGoalContext.total.years[0],
      endYy: reduceGoalContext.total.years[reduceGoalContext.total.years.length - 1],
    };

    RoadMapService.getInstance()
      .saveYyRdcGoal(param)
      .then((response: any) => {
        if (ResponseResultCode.Y === response.resultCode) {
          toast.success(t("common.message.success.save", { title: "" }));
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.save"));
      });
  }

  return (
    <section className="section active">
      <div className="col-wrap">
        <div className="col-md-12">
          <div className="cont">
            <div className="titFlex">
              <ul className="tab-order-st1">
                <li>
                  <div>
                    <strong>01</strong>
                    <span>{t("cp.roadMap.reduceGoal.index.1")}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <strong>02</strong>
                    <span>{t("cp.roadMap.reduceGoal.index.2")}</span>
                  </div>
                </li>
                <li className="on">
                  <div>
                    <strong>03</strong>
                    <span>{t("cp.roadMap.reduceGoal.index.3")}</span>
                  </div>
                </li>
              </ul>
              <div className="btns mgb0 ar">
                <ArcButton type={ButtonType.text} text={t("cp.roadMap.reduceGoal.before")} onClick={props.onBefore} />
                <ArcButton
                  type={"custom"}
                  hasIcon={true}
                  color={ButtonColor.btn2}
                  text={"저장"}
                  icon={ButtonIcon.save}
                  onClick={handleClickSave}
                />
              </div>
            </div>
            <hr className="line3" />
            <div className="cont hAuto">
              <h3 className="titT1">
                {t("cp.roadMap.reduceGoal.category")}:{" "}
                {CodeUtil.cpReduceGoalCategoryConvert(reduceGoalContext.total.goalType)}
              </h3>
              <div className="srh-filter2">
                <div className="col-wrap w100">
                  <div className="col-md-4">
                    <ul>
                      <li className="w100">
                        <span className="srh-tit srh-tit-w85">
                          <strong>기준년도</strong>
                        </span>
                        <div className="srh-cont">
                          <ArcInput
                            id="reduceGoalYears"
                            value={
                              reduceGoalContext.total.years.length === 1
                                ? `${reduceGoalContext.total.years[0]}${t("common.unit.year")}`
                                : reduceGoalContext.total.years.length > 0
                                  ? `~ ${reduceGoalContext.total.years[reduceGoalContext.total.years.length - 1]}${t(
                                    "common.unit.year"
                                  )}`
                                  : ""
                            }
                            onChange={() => ""}
                            className="InpSel-150"
                            disabled={true}
                          />
                        </div>
                      </li>
                      <li className="w100 mgt5">
                        <span className="srh-tit srh-tit-w85">
                          <strong>
                            목표 유형&nbsp;
                            <a
                              href={JsxElementConstant.hrefIsNull}
                              className="tit-info" onClick={handleClickGoalTypeInfo}>
                              <i className="xi-help" aria-hidden="true"></i>
                              <div className="tit-info-cont">
                                <ul className="list-st1">
                                  <li>
                                    {CodeUtil.cpReduceGoalCategoryConvert(reduceGoalContext.total.goalType)}
                                    &nbsp;{t("cp.roadMap.reduceGoal.goalCategory")}
                                    <br />
                                    {reduceGoalContext.total.goalType === 1 ? (
                                      <>정량적 목표 설명</>
                                    ) : (
                                      <>자발적 목표 설명</>
                                    )}
                                    {/*적용 기준연도 대비 온실가스 배출량의 감축을 설정하는 방식으로, <br />*/}
                                    {/*이행 관리가 투명하고 신뢰도가 높아 가장 선호되는 방식*/}
                                  </li>
                                </ul>
                              </div>
                            </a>
                          </strong>
                        </span>
                        <div className="srh-cont">
                          <ArcInput
                            id="reduceGoalType"
                            value={`${CodeUtil.cpReduceGoalCategoryConvert(reduceGoalContext.total.goalType)} ${t(
                              "cp.roadMap.reduceGoal.goalCategory"
                            )}`}
                            onChange={() => ""}
                            className="InpSel-200"
                            disabled={true}
                          />
                        </div>
                      </li>

                      <li className="w100">
                        <div className="srh-cont mgr10">
                          <span className="srh-tit srh-tit-w85">
                            <strong>중간 목표</strong>
                            <em>(기준년도 대비)</em>
                          </span>
                        </div>
                        {1 === reduceGoalContext.total.goalType && (
                          <>
                            <div className="srh-cont mgr5">
                              <ArcInput
                                id="midYearInput"
                                title={"중간목표년도"}
                                value={midYear.toString()}
                                onChange={() => ""}
                                className="InpSel-100"
                                disabled={true}
                              />
                              <em> {t("common.unit.year")}</em>
                            </div>
                            <div className="srh-cont">
                              <ArcInput
                                id="midPercentInput"
                                title={"중간목표(%)"}
                                value={midPercent.toString()}
                                onChange={() => ""}
                                className="InpSel-100"
                                disabled={true}
                              />
                              <em> %</em>
                            </div>
                          </>
                        )}
                        {2 === reduceGoalContext.total.goalType && (
                          <>
                            <ArcSelectbox
                              title={"중간목표 년도 선택"}
                              name="midYearSelect"
                              onChange={handleChangeMidYear}
                              selectedValue={midYear}
                              options={[...makeYearOption(standardYear)]}
                            />
                            <div className="srh-cont">
                              <ArcInput
                                title={"중간목표(%) 입력"}
                                id="midPercentInput"
                                value={midPercent.toString()}
                                onChange={handleChangeMidInput}
                                minValue={0}
                                inputType="number"
                                className="InpSel-100"
                              />
                              <em> %</em>
                            </div>
                          </>
                        )}
                      </li>
                      <li>
                        <div className="srh-cont mgr10">
                          <span className="srh-tit srh-tit-w85">
                            <strong>목표 연도</strong>
                            <em>(기준년도 대비)</em>
                          </span>
                        </div>
                        {1 === reduceGoalContext.total.goalType && (
                          <>
                            <div className="srh-cont mgr5">
                              <ArcInput
                                id="goalYearInput"
                                title={"목표년도 입력"}
                                value={goalYear.toString()}
                                onChange={() => ""}
                                className="InpSel-100"
                                disabled={true}
                              />
                              <em> {t("common.unit.year")}</em>
                            </div>
                            <div className="srh-cont">
                              <ArcInput
                                id="goalPercentInput"
                                title={"목표(%)"}
                                value={goalPercent.toString()}
                                onChange={() => ""}
                                className="InpSel-100"
                                disabled={true}
                              />
                              <em> %</em>
                            </div>
                          </>
                        )}
                        {2 === reduceGoalContext.total.goalType && (
                          <>
                            <ArcSelectbox
                              name="goalYearSelect"
                              title={"목표년도 선택"}
                              onChange={handleChangeGoalYear}
                              selectedValue={goalYear}
                              options={[...makeYearOption(midYear ?? standardYear)]}
                            />
                            <div className="srh-cont">
                              <ArcInput
                                id="goalPercentInput"
                                title={"목표(%) 입력"}
                                value={goalPercent.toString()}
                                onChange={handleChangeGoalInput}
                                minValue={midPercent || 0}
                                maxValue={100}
                                inputType="number"
                                className="InpSel-100"
                              />
                              <em> %</em>
                            </div>
                          </>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <div className="box-text-style3 h100">
                      <ul className="h100 pdt10 pdb10">
                        <li className="bg-c1">
                          <div>
                            <strong>기준년도</strong>
                            <span>(tCO2eq)</span>
                          </div>
                          <strong data-count={reduceGoalContext.total.emsn} className="change l-length">
                            <CountUp end={Number(reduceGoalContext.total.emsn.toFixed(2))}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal(Number(reduceGoalContext.total.emsn.toFixed(2)))}
                            />
                          </strong>
                        </li>
                        <li className="bg-c2">
                          <div>
                            <strong>
                              중간목표 연도&nbsp;
                              <em>{midPercent}%<i className="xi-long-arrow-down" aria-hidden="true"></i></em>
                            </strong>
                            <span>(tCO2eq)</span>
                          </div>
                          <strong className="change l-length">
                            <CountUp end={Number(((reduceGoalContext.total.emsn / 100) * (100 - midPercent)).toFixed(2))}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal(Number(((reduceGoalContext.total.emsn / 100) * (100 - midPercent)).toFixed(2)))}
                            />
                          </strong>
                        </li>
                        <li className="bg-c3">
                          <div>
                            <strong>
                              목표 연도&nbsp;
                              <em>
                                {goalPercent}%<i className="xi-long-arrow-down" aria-hidden="true"></i>
                              </em>
                            </strong>
                            <span>(tCO2eq)</span>
                          </div>
                          <strong data-count="0.0000" className="change l-length">
                            {Number(((reduceGoalContext.total.emsn / 100) * (100 - goalPercent)).toFixed(2)).toLocaleString()}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <h3 className="titT1 mgt20">절대적 목표 방식</h3>
              <div id="graph-typeA" className="chart">
                <HighchartsReact highcharts={Highcharts} options={chartOption} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
