import React from "react";
import { useAppDispatch } from "stores/hook";
import { closeModal } from "reducers/modalSlice";
import {Link} from "react-router-dom";
import AppButton, {ButtonColor} from "components/common/AppButton";

const ArcModalContentExample = (props: any) => {
    const dispatch = useAppDispatch();



    const closeFormDialog = (data?: any, formState?: string) => {
        dispatch(closeModal());
    };

    return (
        <div>
            <h3 className="titT1">{props.title}</h3>

            <div>{props.data}</div>


                <div className="btns ac">
                    <Link to="/" onClick={closeFormDialog}>
                    <button  className="btn-style2">
                    <span>
                        메인화면
                    </span>
                    </button>
                    </Link>
                    <AppButton color={ButtonColor.btn1} text="닫기" onClick={closeFormDialog}/>
                </div>

        </div>
    );
};

export default ArcModalContentExample;
