import {Fragment, useEffect} from "react"
import {useAppDispatch} from "stores/hook";
import {
    bottomLeft,
    bottomMiddle,
    bottomRight, center,
    closeModal, middleLeft,
    middleRight,
    openModal,
    topLeft, topMiddle,
    topRight
} from "reducers/modalSlice";
import MatchingTargetForm from "../rp/MatchingTargetForm";
import {PortfolioModel} from "../../model/rp/PortfolioModel";
import ArcModalContentExample from "./ArcModalContentExample";
import ArcButton from "components/arc/ArcButton";
import {useTranslation} from "react-i18next";



export default function ArcModalContainerExample() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch();
    const closeFormDialog = (data?: any, formState?: string) => {
        dispatch(closeModal());
    }
    const SampleData:any = "샘플데이터"

    const modal=()=>{

        dispatch(openModal({
            path: 'sample/modalSample',
            target: ArcModalContentExample,
            modalProps: {
                title: t('sys0401.title.search.status.all'),
                data: SampleData,
                onClose: closeFormDialog,
            },
            wrapStyle: center
        }));



    }

    useEffect(() => {

    }, [])


    return (
        <>
            <ArcButton type={"confirm"} onClick={modal} text={"모달"}/>

        </>
    )
}