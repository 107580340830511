import React from "react";
import ArcDatePicker, {ArcDatePickerResult} from "components/arc/ArcDatePicker";

export default function ArcDatePickerExample() {
  const handleChangeDate2 = (result: ArcDatePickerResult) => {
  };

  return (
    <>
      <ArcDatePicker
        onChange={handleChangeDate2}
        id={""}
        name={""}
        // selectionMode="range"
        // showButtonBar={false}
        // showViewSelect={true}
        // minDate={'2024-03-09 13'}
        // maxDate={'2024033020'}
        // minMaxDateMode="force"
        // fromDate={'202403091531'}
        // toDate={'202404052359'}
        viewType={"time"}
        // placeholders={placeholders}
      />
      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-input time">
        <label className="hid">처음시간</label>
        <input type="text" title="처음시간설정" placeholder="00:00" className="datepicker InpSel-w100 hasDatepicker"
               readOnly={false} id="dp1706252249987"/>
        <button className="calendar-picker-btn"></button>
      </div>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-input time">
        <label className="hid">처음시간</label>
        <input type="text" title="처음시간설정" placeholder="00:00" className="datepicker InpSel-w100 hasDatepicker"
               readOnly={false} disabled={false} id="dp1706252249988"/>
        <button className="calendar-picker-btn"></button>
      </div>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-picker">
        <div className="calendar-input">
          <label className="hid">시작일</label>
          <input type="text" title="시작일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} id="dp1706252249974"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <em>~</em>
        <div className="calendar-input">
          <label className="hid">종료일</label>
          <input type="text" title="종료일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} id="dp1706252249975"/>
          <button className="calendar-picker-btn"></button>
        </div>
      </div>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-picker">
        <div className="calendar-input">
          <label className="hid">시작일</label>
          <input type="text" title="시작일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} disabled={false}
                 id="dp1706252249976"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <em>~</em>
        <div className="calendar-input">
          <label className="hid">종료일</label>
          <input type="text" title="종료일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} disabled={false}
                 id="dp1706252249977"/>
          <button className="calendar-picker-btn"></button>
        </div>
      </div>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-picker">
        <div className="calendar-input">
          <label className="hid">시작일</label>
          <input type="text" title="시작일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} id="dp1706252249978"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <div className="calendar-input time">
          <label className="hid">처음시간</label>
          <input type="text" title="처음시간설정" placeholder="00:00"
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} id="dp1706252249989"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <em>~</em>
        <div className="calendar-input">
          <label className="hid">종료일</label>
          <input type="text" title="종료일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} id="dp1706252249979"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <div className="calendar-input time">
          <label className="hid">마지막시간</label>
          <input type="text" title="마지막시간설정" placeholder="00:00"
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} id="dp1706252249990"/>
          <button className="calendar-picker-btn"></button>
        </div>
      </div>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-picker">
        <div className="calendar-input">
          <label className="hid">시작일</label>
          <input type="text" title="시작일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} disabled={false}
                 id="dp1706252249980"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <div className="calendar-input time">
          <label className="hid">처음시간</label>
          <input type="text" title="처음시간설정" placeholder="00:00"
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} disabled={false}
                 id="dp1706252249991"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <em>~</em>
        <div className="calendar-input">
          <label className="hid">종료일</label>
          <input type="text" title="종료일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} disabled={false}
                 id="dp1706252249981"/>
          <button className="calendar-picker-btn"></button>
        </div>
        <div className="calendar-input time">
          <label className="hid">마지막시간</label>
          <input type="text" title="마지막시간설정" placeholder="00:00"
                 className="datepicker InpSel-w100 hasDatepicker" readOnly={false} disabled={false}
                 id="dp1706252249992"/>
          <button className="calendar-picker-btn"></button>
        </div>
      </div>

      <hr style={{margin: '0.5rem 0', display: 'block'}}/>

      <div className="calendar-picker">
        <div className="calendar-input">
          <label className="hid">시작일</label>
          <input type="text" title="시작일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" id="date1" readOnly={false}/>
          <button className="calendar-picker-btn"></button>
        </div>
        <em>~</em>
        <div className="calendar-input">
          <label className="hid">종료일</label>
          <input type="text" title="종료일 설정" placeholder="YYYY-MM-DD" value=""
                 className="datepicker InpSel-w100 hasDatepicker" id="date2" readOnly={false}/>
          <button className="calendar-picker-btn"></button>
        </div>
      </div>
      {/*<div className="dateBtn">*/}
      {/*              <span className="chkbox2">*/}
      {/*                <input type="button" name="dateType" id="dateType1" className="btn-style1"*/}
      {/*                       onClick={()=>{setSearchDate('1m')}} value="1일"/>*/}
      {/*              </span>*/}
      {/*    <span className="chkbox2">*/}
      {/*                <input type="button" name="dateType" id="dateType4" className="btn-style1"*/}
      {/*                       onClick={()=>{setSearchDate('1m')}} value="1개월"/>*/}
      {/*              </span>*/}
      {/*    <span className="chkbox2">*/}
      {/*                <input type="button" name="dateType" id="dateType6" className="btn-style1"*/}
      {/*                       onClick={()=>{setSearchDate('1m')}} value="6개월"/>*/}
      {/*              </span>*/}
      {/*    <span className="chkbox2">*/}
      {/*                <input type="button" name="dateType" id="dateType7" className="btn-style1"*/}
      {/*                       onClick={()=>{setSearchDate('1m')}} value="1년"/>*/}
      {/*              </span>*/}
      {/*</div>*/}


    </>
  )
}