import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useEffect, useState } from "react";
import ScopeEmissions from "./ScopeEmissions";
import FacilityEmissions from "./FacilityEmissions";
import { EmissionService } from "services/cp/EmissionService";
import { toast } from "react-toastify";
import { t } from "i18next";
import CountUp from "react-countup";
import { CountUpUtil } from "../../../utils/countUpUtil";
import { parseNumberWithCommaDecimal } from "../../../utils/stringUtil";
import YearEmissions from "./YearEmissions";

export default function Board() {

  //Scope별 배출량
  const [scope1, setScope1] = useState(0);
  const [scope2, setScope2] = useState(0);
  const [scope3, setScope3] = useState(0);

  //시설별 배출량
  const [firstPlaceVa, setFirstPlaceVa] = useState(0);
  const [secondPlaceVa, setSecondPlaceVa] = useState(0);
  const [thirdPlaceVa, setThirdPlaceVa] = useState(0);

  const [firstPlaceNm, setFirstPlaceNm] = useState<String>("");
  const [secondPlaceNm, setSecondPlaceNm] = useState<String>("");
  const [thirdPlaceNm, setThirdPlaceNm] = useState<String>("");

  //총 배출량
  const [emisTotal, setEmisTotal] = useState<number>(0);

  const [category, setCategory] = useState();
  const [yearEmis, setYearEmis] = useState();

  const [roadmapOption, setRoadmapOption] = useState<any>();
  const [selectedComponent, setSelectedComponent] = useState(null);

  const [scopUse, setScopUse] = useState<any>();

  useEffect(() => {
    getBoardScope();
  }, []);

  useEffect(() => {
    const drawChartRoadmap = {
      chart: {
        marginTop: 50,
        type: "line",
        zooming: {
          type: undefined,
          mouseWheel: {
            enabled: false
          }
        }
      },
      title: { text: "" },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: {
        categories: category,
        crosshair: true,
        title: { text: "" },
        labels: {
          style: {
            color: '#555',
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        opposite: false,
        showLastLabel: true,
        offset: 0,
        min: 0,
        title: {
          text: '(tCO2eq)',
          style: {
            color: '#555',
            fontSize: '12px'
          },
          align: 'high',
          rotation: 0,
          offset: 0,
          y: -25,
          x: -5
        },
        labels: {
          y: 3,
          distance: 7,
          style: {
            color: '#555',
            fontSize: '14px'
          },
          formatter: function (this: any) {
            return parseNumberWithCommaDecimal(this.value);
          },
        },
      },
      tooltip: {
        headerFormat: "{point.key}<br/>",
        split: false,
        style: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          pointPadding: 0,
          borderRadius: 0,
        },
      },
      legend: {
        enabled: false,
      },
      series: [
        {
          name: "연도별 배출량",
          data: yearEmis,
          color: {
            linearGradient: {
              x1: 0,
              x2: 0,
              y1: 0,
              y2: 1,
            },
            stops: [
              [0, "#7EC7AF"],
              [1, "#68A4D4"],
            ],
          },
          lineWidth: 2,
          marker: {
            radius: 5,
            lineWidth: 3,
            fillColor: "#fff",
            lineColor: {
              linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
              },
              stops: [
                [0, "#7EC7AF"],
                [1, "#68A4D4"],
              ],
            },
          },
        },
      ],
    };
    setRoadmapOption(drawChartRoadmap);
  }, [yearEmis, category]);

  function getBoardScope() {
    EmissionService.getInstance()
      .getBoardScope()
      .then((response: any) => {
        if (response.data.statusCode) {

          const data = response.data.data;
          let current = data.current;
          const category = data.category;
          const scopUse = data.scopUse;


          setCategory(category);
          setYearEmis(current);
          setScopUse(scopUse);

          let scope1 = 0;
          let scope2 = 0;
          let scope3 = 0;

          const listScope1 = data.listScope1;
          const listScope2 = data.listScope2;
          const listScope3 = data.listScope3;

          const mergedArray = [...listScope1, ...listScope2];

          const sumByName: any = {};

          mergedArray.forEach((item) => {
            if (item.emsnFcltNm !== null) { // Check if emsnFcltNm is not null
              if (sumByName[item.emsnFcltNm]) {
                sumByName[item.emsnFcltNm] += item.sumScope;
              } else {
                sumByName[item.emsnFcltNm] = item.sumScope;
              }
            }
          });

          const newArray = Object.keys(sumByName).map((emsnFcltNm) => ({
            emsnFcltNm,
            sumScope: sumByName[emsnFcltNm],
          }));

          const listFactory = [...newArray].sort((a, b) => b.sumScope - a.sumScope);

          setFirstPlaceVa(listFactory[0] ? listFactory[0]["sumScope"] : 0);
          setSecondPlaceVa(listFactory[1] ? listFactory[1]["sumScope"] : 0);
          setThirdPlaceVa(listFactory[2] ? listFactory[2]["sumScope"] : 0);

          setFirstPlaceNm(listFactory[0] ? listFactory[0]["emsnFcltNm"] : "");
          setSecondPlaceNm(listFactory[1] ? listFactory[1]["emsnFcltNm"] : "");
          setThirdPlaceNm(listFactory[2] ? listFactory[2]["emsnFcltNm"] : "");

          for (let i = 0; i < listScope1.length; i++) {
            scope1 += listScope1[i]["sumScope"];
          }

          for (let i = 0; i < listScope2.length; i++) {
            scope2 += listScope2[i]["sumScope"];
          }

          for (let i = 0; i < listScope3.length; i++) {
            scope3 += listScope3[i]["sumScope"];
          }

          setScope1(scope1);
          setScope2(scope2);
          setScope3(scope3);

          let emisTotal = 0;

          emisTotal = scope1 + scope2 + scope3;

          setEmisTotal(emisTotal);
        } else {
          toast.error("데이터를 가져오는데 실패하였습니다.")
        }
      })
      .catch((error) => {
        toast.error(t("common.message.error.system"));
      });
  }

  const handleChangeButton = (component: any) => {
    setSelectedComponent(component);
  };

  return (
    <>
      {!selectedComponent ? (
        <>
          <div className="col-wrap">
            <div className="col-md-12">
              <div className="cont">
                <div className="total-count-box total-count-box2 hAuto">
                  <ul className="box-graph3 box-graph3-2">
                    <li>
                      <div className="center">
                        <strong className="fs24">총 배출량</strong>
                        <div className="data-total mgl30">
                          <span>
                            <CountUp
                              end={(emisTotal ? Number(emisTotal.toFixed(2)) : 0)}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal((emisTotal ? Number(emisTotal.toFixed(2)) : 0))} />
                          </span>
                          <em>tCO2eq</em>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-wrap">
            <div className={(firstPlaceVa ? "col-md-6" : "col-md-12")}>
              <div className="cont">
                <div className="titFlex">
                  <h3 className="titT1">Scope별 배출량</h3>
                  <button className="more-btn" onClick={() => handleChangeButton(<ScopeEmissions />)}>
                    <i aria-hidden="true" className="xi-plus"></i>
                    <span className="hid">더보기</span>
                  </button>
                </div>
                <div className="icon-box-wrap9 icon-box-wrap9-2">
                  <ul>
                    <li className="n-bg" style={{ opacity: scopUse?.scopN1UseYn === "N" ? "0.5" : "" }}>
                      <div>
                        <span className="txt">
                          <span>Scope 1</span>
                        </span>
                        <span className="img">
                          <img src={require("./../../../assets/images/sub/icon_box_wrap_icon13.png")} alt="" />
                        </span>
                        <div className="data-enter">
                          <span>
                            <CountUp
                              end={(scope1 ? Number(scope1.toFixed(2)) : 0)}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal((scope1 ? Number(scope1.toFixed(2)) : 0))} />
                          </span>
                          <em>tCO2eq</em>
                        </div>
                      </div>
                    </li>
                    <li style={{ opacity: scopUse?.scopN2UseYn === "N" ? "0.5" : "" }}>
                      <div>
                        <span className="txt">
                          <span>Scope 2</span>
                        </span>
                        <span className="img">
                          <img src={require("./../../../assets/images/sub/icon_box_wrap_icon31.png")} alt="" />
                        </span>
                        <div className="data-enter">
                          <span>
                            <CountUp
                              end={(scope2 ? Number(scope2.toFixed(2)) : 0)}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal((scope2 ? Number(scope2.toFixed(2)) : 0))} />
                          </span>
                          <em>tCO2eq</em>
                        </div>
                      </div>
                    </li>
                    <li style={{ opacity: scopUse?.scopN3UseYn === "N" ? "0.5" : "" }}>
                      <div>
                        <span className="txt">
                          <span>Scope 3</span>
                        </span>
                        <span className="img">
                          <img src={require("./../../../assets/images/sub/icon_box_wrap_icon32.png")} alt="" />
                        </span>
                        <div className="data-enter">
                          <span>
                            <CountUp
                              end={(scope3 ? Number(scope3.toFixed(2)) : 0)}
                              duration={0.5}
                              decimals={CountUpUtil.getDecimal((scope3 ? Number(scope3.toFixed(2)) : 0))} />
                          </span>
                          <em>tCO2eq</em>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6" style={{ display: firstPlaceVa ? 'block' : 'none' }}>
              <div className="cont">
                <div className="titFlex">
                  <h3 className="titT1">시설별 배출량</h3>
                  <button className="more-btn" onClick={() => handleChangeButton(<FacilityEmissions />)}>
                    <i aria-hidden="true" className="xi-plus"></i>
                    <span className="hid">더보기</span>
                  </button>
                </div>
                <div className="icon-box-wrap9 icon-box-wrap9-2">
                  <ul>
                    {firstPlaceVa ?
                      <li className="n-bg">
                        <div>
                          <span className="txt">
                            <span>{firstPlaceNm}</span>
                          </span>
                          <span className="img">
                            <img src={require("./../../../assets/images/sub/icon_box_wrap_icon33.png")} alt="" />
                          </span>
                          <div className="data-enter">
                            <span>
                              <CountUp
                                end={(firstPlaceVa ? Number(firstPlaceVa.toFixed(2)) : 0)}
                                duration={0.5}
                                decimals={CountUpUtil.getDecimal((firstPlaceVa ? Number(firstPlaceVa.toFixed(2)) : 0))} />
                            </span>
                            <em>tCO2eq</em>
                          </div>
                        </div>
                      </li>
                      : <></>
                    }
                    {secondPlaceVa ?
                      <li>
                        <div>
                          <span className="txt">
                            <span>{secondPlaceNm}</span>
                          </span>
                          <span className="img">
                            <img src={require("./../../../assets/images/sub/icon_box_wrap_icon14.png")} alt="" />
                          </span>
                          <div className="data-enter">
                            <span>
                              <CountUp
                                end={(secondPlaceVa ? Number(secondPlaceVa.toFixed(2)) : 0)}
                                duration={0.5}
                                decimals={CountUpUtil.getDecimal((secondPlaceVa ? Number(secondPlaceVa.toFixed(2)) : 0))} />
                            </span>
                            <em>tCO2eq</em>
                          </div>
                        </div>
                      </li>
                      : <></>
                    }
                    {thirdPlaceVa ?
                      <li>
                        <div>
                          <span className="txt">
                            <span>{thirdPlaceNm}</span>
                          </span>
                          <span className="img">
                            <img src={require("./../../../assets/images/sub/icon_box_wrap_icon15.png")} alt="" />
                          </span>
                          <div className="data-enter">
                            <span>
                              <CountUp
                                end={(thirdPlaceVa ? Number(thirdPlaceVa.toFixed(2)) : 0)}
                                duration={0.5}
                                decimals={CountUpUtil.getDecimal((thirdPlaceVa ? Number(thirdPlaceVa.toFixed(2)) : 0))} />
                            </span>
                            <em>tCO2eq</em>
                          </div>
                        </div>
                      </li>
                      : <></>
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-wrap">
            <div className="col-md-12">
              <div className="cont">
                <div className="titFlex">
                  <h3 className="titT1">연도별 배출량</h3>
                  <button className="more-btn" onClick={() => handleChangeButton(<YearEmissions />)}>
                    <i aria-hidden="true" className="xi-plus"></i>
                    <span className="hid">더보기</span>
                  </button>
                </div>
                <HighchartsReact highcharts={Highcharts} options={roadmapOption}></HighchartsReact>
              </div>
            </div>
          </div>
        </>
      ) : (
        selectedComponent
      )}
    </>
  );
}
