import {createSlice} from "@reduxjs/toolkit";

const initialState: any = {
  portTableTab: 'economical',
  portfolioDetail: {},
  pwplList: [],
}

const rpSlice = createSlice({
  name: 'rp',
  initialState,
  reducers: {
    updatePortTableTab: (state, action) => {
      state.portTableTab = action.payload;
    },
    updatePortfolioDetail: (state, action) => {
      state.portfolioDetail = action.payload;
    },
    updatePwplList: (state, action) => {
      state.pwplList = action.payload;
    },
  },
});

export default rpSlice.reducer;
export const {
  updatePortTableTab,
  updatePortfolioDetail,
  updatePwplList} = rpSlice.actions;