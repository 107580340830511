import ArcGrid, { ArcGridCheckResult, CustomSelectScopRenderer, GridColumn, GridEditor, GridEditorInfo } from "components/arc/ArcGrid";
import { kn } from "date-fns/locale";
import { ResponseResultCode } from "model/response-api";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ActvScopService } from "services/cp/ActvScopService";
import { BasicInfoService } from "services/cp/BasicInfoService";
import { ComCdService } from "services/cp/ComCdService";

export default function Scope1WasteGrid(props: any) {

  const { t } = useTranslation();

  const [stsCd, setStsCd] = useState<any[]>([]);
  const [kndCd, setKndCd] = useState<any[]>([]);
  const [lwpoKndCd, setLwpoKndCd] = useState<any[]>([]);
  const gridRef: any = useRef<typeof ArcGrid>();

  const checkedRow = (data: ArcGridCheckResult) => {
    setDataDelete(data.checkedRows)
  };
  const [wstAndUnit, setWstAndUnit] = useState<any>();

  const [wstCd, setWstCd] = useState<any[]>([]);

  const [gridCanDraw, setGridCanDraw] = useState<boolean>(false);

  const [dataDelete, setDataDelete] = useState<any>([]);

  const [, setRowKey] = useState<any>(props.data.row);

  const [isDelete, setIsDelete] = useState(false);

  const month = [
    { regMm: "1", name: "1월" },
    { regMm: "2", name: "2월" },
    { regMm: "3", name: "3월" },
    { regMm: "4", name: "4월" },
    { regMm: "5", name: "5월" },
    { regMm: "6", name: "6월" },
    { regMm: "7", name: "7월" },
    { regMm: "8", name: "8월" },
    { regMm: "9", name: "9월" },
    { regMm: "10", name: "10월" },
    { regMm: "11", name: "11월" },
    { regMm: "12", name: "12월" },
  ];

  const [data, setData] = useState(props.data.data);


  useEffect(() => {
    getStsCd();
    getKndCd();
    getLwpoKndCd();
  }, []);

  useEffect(() => {
    if (stsCd.length > 0 && kndCd.length > 0 && wstCd.length > 0) {

      if (props.data.data.length > 0) {
        setData(props.data.data);
      } else {
        getData();
        setRowKey([]);
      }
    }
  }, [props.fuflYy, props.data.data, props.scopId, stsCd, kndCd, wstCd]);

  function getData() {
    ActvScopService.getInstance()
      .getScop1(Number(props.fuflYy), props.scopId)
      .then((res) => {
        if (res.data.data.contents) {
          setData(res.data.data.contents);
          if (res.data.data.contents.length === 0) {
            gridDraw();
          }
        } else {
          setData([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  useEffect(() => {
    BasicInfoService.getInstance().getIncnrByYear(props.fuflYy)
      .then((response) => {
        if (response.data.data.length > 0) {
          setWstAndUnit(response.data.data);
        } else {
          setWstAndUnit([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }, [props.fuflYy])

  useEffect(() => {

    if (wstAndUnit) {
      const fulnamesInFulAndUnit = new Set(wstAndUnit.map((item: any) => item.wstLwpoKndName));

      const filteredFul = lwpoKndCd.filter(item => fulnamesInFulAndUnit.has(item.label));
      setWstCd(filteredFul);
    }

  }, [wstAndUnit, lwpoKndCd])

  function getStsCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_WST_STS_CL_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setStsCd(cdOptions);
        } else {
          setStsCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function getKndCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_WST_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setKndCd(cdOptions);
        } else {
          setKndCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function getLwpoKndCd() {
    ComCdService.getInstance()
      .getComCdExpl("EMKS_WST_LWPO_KND_CD")
      .then((response) => {
        if (response.data.data.length > 0) {
          const cdOptions = makeCommonCodeOptions(response.data.data);
          setLwpoKndCd(cdOptions);
        } else {
          setLwpoKndCd([]);
        }
      })
      .catch(() => {
        toast.error(t("common.message.error.data"));
      });
  }

  function makeCommonCodeOptions(data: any[]) {
    return data.map((item: any) => ({
      label: item.comCdExpl,
      value: item.comCdId,
      uppoCdId: item.uppoCdId,
    }));
  }

  function addComma(value: any) {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }


  const dataMapNumber = (e: any, name: string) => {

    let result = "";
    if (e.row.dataMap !== undefined) {
      e.row.dataMap.forEach((item: any) => {
        if (item[name]) {
          result = item[name];
        }
      })
      if (e.row.dataMap) {
        if (e.value !== null && /^[0-9.]+$/.test(e.value)) {
          return e.value !== "" ? addComma(e.value) : addComma(result);

        } else {
          return addComma(result);
        }
      }
    }
    return e.value != null ? addComma(e.value) : result;
  }

  const numberEditorEmission = (length: number, decimal: number): Partial<GridEditorInfo> => {

    return {
      options: {
        onChange: handleChangeEditor,
        type: "number",
        validation: {
          validatorFn: (value: any) => {
            return (
              (gridRef.current
                && (gridRef.current.getInstance().getData().length > 0)
              ) || value != null || value === "" || value == null
            )
          },
          regExp:
            new RegExp(`^\\d{0,${length - decimal}}(\\.\\d{0,${decimal}})?$`),
          regExpMessage: t('common.message.valid.input.numberLengthWithDecimal', {
            len: length,
            dec: decimal
          })
        }
      },
    }
  }

  // 그리드 컬럼
  const defaultGridColumns = (): GridColumn[] => [{
    header: "mngId",
    name: "mngId",
    hidden: true,
  },
  {
    header: "소각로명",
    name: "emsnFcltNm",
    align: "center",
    whiteSpace: "normal",
    onAfterChange: (ev: any) => {
      setRowKey((prevRowKey: any) => {
        const newRowKeys = [...prevRowKey, ev.rowKey];
        const updatedData = gridRef.current?.getInstance().getData();
        props.onData({
          data: updatedData,
          row: newRowKeys
        });
        return newRowKeys;
      });
    },
    formatter: function (e: any) {
      return e.value;
    },
  },
  {
    header: "성상(고상/액상/기상)",
    name: "wstStsClCd",
    align: "center",
    whiteSpace: "normal",
    onAfterChange: (ev: any) => {
      setRowKey((prevRowKey: any) => {
        const newRowKeys = [...prevRowKey, ev.rowKey];
        const updatedData = gridRef.current?.getInstance().getData();
        props.onData({
          data: updatedData,
          row: newRowKeys
        });
        return newRowKeys;
      });
    },
    formatter: function (e: any) {
      if (e.value !== null && e.value !== undefined) {
        return e.value?.comCdExpl;
      }
      return "";
    },
  },
  {
    header: "폐기물 분류",
    name: "wstKndCd",
    align: "center",
    whiteSpace: "normal",
    onAfterChange: (ev: any) => {
      setRowKey((prevRowKey: any) => {
        const newRowKeys = [...prevRowKey, ev.rowKey];
        const updatedData = gridRef.current?.getInstance().getData();
        props.onData({
          data: updatedData,
          row: newRowKeys
        });
        return newRowKeys;
      });
    },
    formatter: function (e: any) {
      if (e.value !== null && e.value !== undefined) {
        return e.value?.comCdExpl;
      }
      return "";
    },
  },
  {
    header: "폐기물 세부 구분",
    name: "wstLwpoKndCd",
    align: "center",
    whiteSpace: "normal",
    onAfterChange: (ev: any) => {
      setRowKey((prevRowKey: any) => {
        const newRowKeys = [...prevRowKey, ev.rowKey];
        const updatedData = gridRef.current?.getInstance().getData();
        props.onData({
          data: updatedData,
          row: newRowKeys
        });
        return newRowKeys;
      });
    },
    formatter: function (e: any) {
      if (e.value !== null && e.value !== undefined) {
        return e.value?.comCdExpl;
      }
      return "";
    },
  },
  {
    header: "단위",
    name: "wstIncnrUnitCd",
    align: "center",
    whiteSpace: "normal",
    formatter: function (e: any) {

      const row = e.row;
      const fuelName = row.wstLwpoKndCd?.comCdExpl;

      let unitName = "";
      // fuelName 값에 따라 unitName 값을 변경
      if (wstAndUnit) {
        const index = wstAndUnit.findIndex((item: any) => item.wstLwpoKndName === fuelName);

        if (index !== -1) {
          unitName = wstAndUnit[index].unitName;
        }
      }
      return unitName;
    }
  },

  ];

  //그리드 수정
  const defaultGridEditor = (): GridEditor => ({
    requestBtn: props?.scopUse === "N" ? [] : ['ADD', 'DELETE'],

    isDataHotSwap: false,
    defaultRowDataSet: {
      fuflYy: (props.fuflYy ?? "").toString(),
      scopId: (props.scopId ?? "").toString(),
    },
    editableColumns:
      props?.scopUse === "N" ?

        [] :
        [
          {
            columnName: "emsnFcltNm",
            editorType: "text",
            options: {
              onChange: handleChangeEditor,
              validation: {
                required: true,
                validatorFn: (value: any, row: any, columnNm: any) => {

                  // Check if value is null
                  if (value === null) return false;

                  // If row.wstLwpoKndCd is defined, perform additional validation
                  if (row.wstLwpoKndCd) {

                    let dataGrid = [];

                    dataGrid = data

                    const gridInstance = gridRef.current?.getInstance();
                    if (gridInstance) dataGrid = gridInstance.getData();

                    if (!Array.isArray(dataGrid) || dataGrid.length === 0) return false;

                    // Check for the specific conditions in the data
                    const matchingItems = dataGrid.filter((d: any) =>
                      d.rowKey !== row.rowKey &&
                      d.wstLwpoKndCd?.comCdId === row.wstLwpoKndCd?.comCdId && d.emsnFcltNm === row.emsnFcltNm
                    );
                    return matchingItems.length < 1;
                  }

                  // If row.wstLwpoKndCd is not defined, return true if value is not null
                  return value !== null;
                }
              },
            },
          },
          {
            columnName: "wstStsClCd",
            editorType: CustomSelectScopRenderer,
            options: {
              onChange: handleChangeEditor,
              comCd: stsCd,
              validation: {
                required: true,
                validatorFn: (value: any) => {
                  return value !== null && value?.comCdExpl !== undefined;
                }
              },
            },
          },
          {
            columnName: "wstKndCd",
            editorType: CustomSelectScopRenderer,
            options: {
              onChange: handleChangeEditor,
              comCd: kndCd,
              validation: {
                required: true,
                validatorFn: (value: any) => {
                  return value !== null && value?.comCdExpl !== undefined;
                }
              },
            },
          },
          {
            columnName: "wstLwpoKndCd",
            editorType: CustomSelectScopRenderer,
            options: {
              onChange: handleChangeEditor,
              comCd: wstCd,
              validation: {
                required: true,
                validatorFn: (value: any, row: any, columnNm: any) => {

                  if (value === null || value === undefined) return false;

                  if (value?.comCdExpl === undefined) return false;

                  let dataGrid = [];

                  dataGrid = data

                  const gridInstance = gridRef.current?.getInstance();
                  if (gridInstance) dataGrid = gridInstance.getData();


                  if (!Array.isArray(dataGrid) || dataGrid.length === 0) return false;

                  return dataGrid.filter((e: any) => e.rowKey !== row.rowKey && e.wstLwpoKndCd?.comCdId === value?.comCdId && e.emsnFcltNm === row.emsnFcltNm).length < 1;
                }
              },
            },
          },

        ],
  });

  const [gridEditor, setGridEditor] = useState<GridEditor>(defaultGridEditor);
  const [gridColumns, setGridColumns] = useState<GridColumn[]>(defaultGridColumns);

  useEffect(() => {
    if (data.length > 0 && kndCd.length > 0 && wstCd.length > 0 && stsCd.length > 0) {
      gridDraw();
    }
  }, [props.fuflYy, wstCd, kndCd, stsCd, data, props?.scopUse]);

  const gridDraw = () => {
    const columns = defaultGridColumns();
    const gridEditor = defaultGridEditor();
    month.forEach(f => {
      columns.push({
        header: f.name,
        name: `mm${f.regMm}EmsnQnt`,
        align: "right",
        whiteSpace: "normal",
        onAfterChange: (ev: any) => {
          setRowKey((prevRowKey: any) => {
            const newRowKeys = [...prevRowKey, ev.rowKey];
            const updatedData = gridRef.current?.getInstance().getData();
            props.onData({
              data: updatedData,
              row: newRowKeys
            });
            return newRowKeys;
          });
        },
        formatter: (e: any) => {
          return dataMapNumber(e, `mm${f.regMm}EmsnQnt`)
        },
      })
    });

    if (props?.scopUse !== "N") {
      month.forEach((f, i) => {
        gridEditor.editableColumns.push({
          columnName: `mm${f.regMm}EmsnQnt`,
          editorType: "text",
          ...numberEditorEmission(15, 4)
        });
      })
    }

    setGridColumns(columns);
    setGridEditor(gridEditor);
    setGridCanDraw(true);
  }

  // 그리드 수정함수
  function handleChangeEditor(e: any) {
  }

  const handleDataFromChild = (event: any) => {
    setIsDelete(event);
  };

  useEffect(() => {
    if (isDelete) {
      let checkedNum = dataDelete.length;
      if (checkedNum > 0) {
        handleDel()
      } else {
        toast.error("체크된 항목이 없습니다.")
      }
    }
    return () => {
      setIsDelete(false);
    };
  }, [isDelete])

  async function handleDel() {
    const dataDel = dataDelete.filter((item: any) => item.mngId !== null);
    if (dataDel.length > 0) {
      try {
        const response = await ActvScopService.getInstance().deleteActvScopList(dataDel);
        if (response) {
          if (ResponseResultCode.Y === response.resultCode) {
            toast.success(t('common.message.success.delete', { title: t('') }));
            props.onData({ row: [], data: [] })
          } else {
            toast.error(t('common.message.error.data'));
          }
        } else {
          toast.error(t('common.message.error.system'));
        }
      } catch (error) {
        toast.error(t('common.message.error.system'));
      }
    }
  }

  const makeGrid = useCallback(() => {
    if (gridCanDraw) {
      return (
        <ArcGrid
          key={`${stsCd.length}-${kndCd.length}-${wstCd.length}`}
          gridRef={gridRef}
          data={data}
          useStaticData={true}
          columns={gridColumns}
          rowHeaders={["checkbox"]}
          usePagination={false}
          editor={gridEditor}
          onCheckRow={checkedRow}
          complexHeader={{ complexColumns: [], height: 70 }}
          isTest={true}
          onDelete={handleDataFromChild}
        />
      );
    }
  }, [gridCanDraw, gridColumns, data, kndCd, wstCd, stsCd]);

  return (
    <div className="fs14">
      {makeGrid()}
    </div>
  );
}
