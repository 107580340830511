import {ParamUtil, RequestParam} from "utils/paramUtil";

export class ApiUrlUtil {

    public static buildQueryString(path: string, params?: any): string {
        let requestParams: RequestParam[] = [];
        if(params) {
            requestParams = ParamUtil.toRequestParams(params);
        }

        let url = '';
        url += path;

        if (requestParams && requestParams.length > 0) {
            for (let i = 0; i < requestParams.length; i++) {
                if (i === 0) {
                    url += '?';
                } else {
                    url += '&';
                }
                url += requestParams[i].name;
                url += '=';
                url += requestParams[i].value;
            }
        }
        return url;
    }
    
}