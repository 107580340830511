import * as process from "process";
import {ScnroModel} from "../../model/rp/ScnroModel";
import Api from "../common/Api";

export class ScnroService extends Api {

  private static service: ScnroService;
  private static LIST_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/list";
  private static DETAIL_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/detail";
  private static DELETE_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/delete";
  private static INSERT_SCNRO_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/insert";
  private static GET_GOAL_RT_URL : string = process.env['REACT_APP_URL_RP'] + "/scnro/getGoalRt";

  private static SUM_COUNT_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/sumScnroCount";
  private static MONTHLY_COUNT: string = process.env['REACT_APP_URL_RP'] + "/scnro/monthlyScnroCount";
  private static PERCENT_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/scnroRgAn";
  private static FUFL_MEANS_URL: string = process.env['REACT_APP_URL_RP'] + "/scnro/sumFuflMeans";

  public static getInstance(): ScnroService {
    if (!ScnroService.service) {
      ScnroService.service = new ScnroService();
    }
    return ScnroService.service;
  }

  public async getDetail(scnroId: string) {
    return await super.get<any>(ScnroService.DETAIL_URL, {scnroId: scnroId})
  }

  public async deleteScnro(scnroIds: any) {
    return await super.deleteOnBody<any>(ScnroService.DELETE_URL, scnroIds)
  }

  public async insertScnro(scnro: ScnroModel) {
    return await super.post<ScnroModel>(ScnroService.INSERT_SCNRO_URL, scnro);
  }

  public async getGoalRt() {
    return await super.get<any>(ScnroService.GET_GOAL_RT_URL);
  }


  // Admin 대시보드
  // 포트폴리오 등록 건수
  public async getSumScnroCount() {
    return await super.get<any>(ScnroService.SUM_COUNT_URL);
  }

  // 이번 달, 저번 달 포트폴리오 등록 건수
  public async getMonthlyScnroCount() {
    return await super.get<any>(ScnroService.MONTHLY_COUNT);
  }

  // 포트폴리오 등록 분석
  public async getScnroRgAn() {
    return await super.get<any>(ScnroService.PERCENT_URL);
  }

  // 산단 이행수단
  public async getSumFulflMeans() {
    return await super.get<any>(ScnroService.FUFL_MEANS_URL);
  }


}